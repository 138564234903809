import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as hoseMakerReducer from "../../hoseMaker.reducer";
import {
  Grid,
  Search,
  Button,
  Message,
  Pagination,
  Icon
} from "semantic-ui-react";
import "./hoseSearch.css";
import HoseCategories from "./HoseCategories";
import ResultRenderer from "../../../../containers/Search/ResultRenderer";
import _ from "lodash";
import HoseDetailsCard from "./HoseDetailsCard";
import FilteredHoseResult from "./FilteredHoseResult";
import HoseDetailsLoaderCard from "./HoseDetailsLoaderCard";
import { showErrorToast } from "../../../../utils/toastUtils";

class HoseSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      level: "Hose - HYD",
      value: "",
      filter: "Hose - HYD",
      searchMessage: "",
      activePage: 1,
      group: "",
      selectedDiameter: "",
      isGettingDiameters: false,
      hoseType: false,
      hoseCategory: false,
      active: null
    };
  }

  componentDidMount() {
    this.props.actionResetSearchResult();
  }

  handleResultSelect = (e, { result }) => {
    const value = result.stockCode;
    this.setState({ value });
    this.callSearchApi(value, 1);
  };

  callSearchApi = async (value, activePage) => {
    this.setState({ isLoading: true });
    await this.props.actionSearchHoseProducts(value, activePage);
    this.setState({
      isLoading: false
    });
  };

  handleSearchChange = async (e, { value }) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.setState({ value, searchMessage: null });
    await this.props.actionHoseAutoComplete(value);
    this.setState({ isLoading: false });
  };

  handleSearchButtonClick = async e => {
    e.preventDefault();
    const { value } = this.state;
    this.setState({ isLoading: true });

    if (_.isEmpty(value)) {
      this.setState({
        searchMessage: "Please enter hose details to search",
        isLoading: false
      });

      return;
    }

    this.onSubmit(e);
    this.setState({
      searchMessage: null,
      isLoading: false
    });
  };

  onSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    const { value, filter } = this.state;
    this.callSearchApi(value, filter, 1);
  };

  onClickCategory = async (e, node) => {
    e.preventDefault();
    this.setState({
      isGettingDiameters: true,
      group: node,
      selectedDiameter: "",
      hoseCategory: true
    });
    await this.props.actionSetHoseDiameters(node);
    this.setState({ isGettingDiameters: false });
  };

  handlePaginationChange = (...params) => {
    const { searchResult } = this.props;
    const { totalPages } = searchResult;

    if (totalPages === 1) {
      return;
    }

    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;
    const { value } = this.state;

    this.setState({
      activePage
    });

    this.callSearchApi(value, activePage);
  };
  clearFilters = e => {
    e.preventDefault();
    this.setState({
      activePage: 1,
      totalPages: 1,
      value: "",
      selectDiameter: "",
      group: ""
    });
    this.props.actionResetSearchResult();
    return;
  };

  onBoarSelect = (e, hose) => {
    e.preventDefault();
    const { pricingPolicy } = hose;
    const { price } = pricingPolicy;
    if (!price) {
      showErrorToast("This product is not available, please contact BOA");
    } else {
      this.props.boarChangeHandler(hose);
    }
  };

  selectDiameter = async (e, diameter) => {
    e.preventDefault();
    const { group } = this.state;
    this.setState({ isLoading: true, selectedDiameter: diameter });
    await this.props.actionSearchHoseByFilters(group, diameter);
    this.setState({ isLoading: false });
  };

  setHoseType = async e => {
    this.setState({ isLoading: true, hoseType: true, active: e.target.value });
    await this.props.actionSetHoseCategories(e.target.value);
    this.setState({ isLoading: false, selectedDiameter: "" });
  };

  render() {
    const {
      categories,
      autoCompleteResult,
      hoseDiameters,
      filteredHoses
    } = this.props;
    const {
      isLoading,
      searchMessage,
      activePage,
      value,
      group,
      selectedDiameter,
      isGettingDiameters,
      hoseType,
      hoseCategory,
      active
    } = this.state;
    const { result = [], totalPages = 1 } = this.props.searchResult;
    const searchResult = result;
    return (
      <React.Fragment>
        <Grid className="hose-search">
          <Grid.Column width={16}>
            <form onSubmit={this.onSubmit}>
              <Grid>
                <Grid.Column tablet={12} computer={12} mobile={16}>
                  <Search
                    className="search-box"
                    loading={isLoading}
                    placeholder="Search hose by code or description"
                    resultRenderer={ResultRenderer}
                    onResultSelect={this.handleResultSelect}
                    onSearchChange={_.debounce(this.handleSearchChange, 500, {
                      leading: true
                    })}
                    noResultsMessage="No direct matches found."
                    results={autoCompleteResult}
                    value={value}
                    {...this.props}
                  />
                </Grid.Column>
                <Grid.Column tablet={8} computer={2} mobile={8}>
                  {isLoading ? (
                    <Button loading>Search</Button>
                  ) : (
                    <Button
                      color="green"
                      onClick={_.debounce(this.handleSearchButtonClick, 500, {
                        leading: true
                      })}
                    >
                      Search
                    </Button>
                  )}
                </Grid.Column>

                <Grid.Column tablet={8} computer={2} mobile={8}>
                  <Button type="button" onClick={e => this.clearFilters(e)}>
                    Clear
                  </Button>
                </Grid.Column>
              </Grid>
            </form>
            {searchMessage && (
              <Message info>
                <Message.Header>{searchMessage}</Message.Header>
              </Message>
            )}
          </Grid.Column>

          {!searchResult.length && (
            <Grid.Column width={16}>
              <Grid>
                <Grid.Column width={16}>
                  <h4>1. Select hose type</h4>
                  <Grid.Column width={13}>
                    <Button
                      color={active === "1Wire" ? "green" : ""}
                      value="1Wire"
                      onClick={e => this.setHoseType(e)}
                    >
                      1 Wire <br /> (1SN, R1AT, 1SC, etc)
                    </Button>
                    <Button
                      color={active === "2Wire" ? "green" : ""}
                      value="2Wire"
                      onClick={e => this.setHoseType(e)}
                    >
                      2 Wire <br /> (2SN, R2AT, 2SC, etc)
                    </Button>
                    <Button
                      color={active === "multiThread" ? "green" : ""}
                      value="multiThread"
                      onClick={e => this.setHoseType(e)}
                    >
                      Multi-Spiral <br /> (ISO18752, 4SH, 4SP, R13, etc)
                    </Button>
                    <Button
                      color={active === "other" ? "green" : ""}
                      value="other"
                      onClick={e => this.setHoseType(e)}
                    >
                      Other <br /> &nbsp;
                    </Button>
                    <Button
                      color={active === "" ? "green" : ""}
                      value=""
                      onClick={e => this.setHoseType(e)}
                    >
                      Show All <br /> &nbsp;
                    </Button>
                  </Grid.Column>
                </Grid.Column>
                <Grid.Column width={16}>
                  {hoseType && (
                    <HoseCategories
                      onClickHandler={this.onClickCategory}
                      selected={group}
                      categories={categories}
                    />
                  )}
                </Grid.Column>
                <Grid.Column width={16}>
                  <Grid>
                    <Grid.Column width={3}>
                      {hoseCategory && <h4>3. Select hose diameter</h4>}
                    </Grid.Column>
                    <Grid.Column width={13} mobile={16}>
                      {!this.state.group && hoseDiameters.length === 0 && (
                        <Message>
                          Please select the correct hose type first.
                        </Message>
                      )}
                      {isGettingDiameters && (
                        <Message>
                          Loading hose diameters, please wait{" "}
                          <Icon loading name="spinner" />
                        </Message>
                      )}
                      {this.state.group && hoseDiameters.length === 0 ? (
                        <Message>
                          Hose diameter not available for selected hose group.
                          Please Contact BOA.
                        </Message>
                      ) : null}

                      {!isGettingDiameters && hoseDiameters.length > 0 && (
                        <div>
                          {hoseDiameters.map(diameter => {
                            return (
                              <Button
                                circular
                                color="green"
                                key={diameter}
                                type="button"
                                onClick={e => this.selectDiameter(e, diameter)}
                                active={
                                  diameter === selectedDiameter ? true : false
                                }
                              >
                                {" "}
                                {diameter}{" "}
                              </Button>
                            );
                          })}
                        </div>
                      )}
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Grid.Column>
          )}
          <Grid.Column width={16}>
            {searchResult.length > 0 && (
              <HoseDetailsCard
                hoseResult={searchResult}
                onBoarSelect={this.onBoarSelect}
              />
            )}
          </Grid.Column>
          <Grid.Column width={16}>
            {isLoading && <HoseDetailsLoaderCard />}
            {(filteredHoses.result && filteredHoses.result.length) > 0 && (
              <b>
                {" "}
                {`Found ${filteredHoses.result.length} hose of diameter ${selectedDiameter}`}
              </b>
            )}
            {!isLoading && (
              <FilteredHoseResult
                result={filteredHoses.result}
                onBoarSelect={this.onBoarSelect}
                isLoading={isLoading}
              />
            )}
          </Grid.Column>
          <Grid.Column>
            {searchResult.length ? (
              <Pagination
                activePage={activePage}
                boundaryRange={1}
                onPageChange={this.handlePaginationChange}
                size="mini"
                totalPages={totalPages}
                ellipsisItem={true}
                firstItem={null}
                lastItem={null}
              />
            ) : null}
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.hoseMaker.categories,
  autoCompleteResult: state.hoseMaker.autoCompleteResult,
  searchResult: state.hoseMaker.searchResult,
  hoseDiameters: state.hoseMaker.hoseDiameters,
  filteredHoses: state.hoseMaker.filteredHoses
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(hoseMakerReducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HoseSearch);
