import React from "react";
import { Grid, Segment, Form } from "semantic-ui-react";
import PropTypes from "prop-types";
import NumericInputBox from "../../../../components/NumericInputBox";

const AdditionalProductInfo = ({ products, removeProduct, changeQuantityHandler }) => {
  return (
    <React.Fragment>
      {products && products.length > 0 && (
        <div>
          <Segment.Group>
            {products.map((item, key) => {
              const {
                stockCode,
                stockDescription,
                secondaryGrpDisplayName,
                quantity,
                pack
              } = item;
              return (
                <Segment key={key}>
                  <Grid columns="equal" width={16}>
                    <Grid.Column  computer={3} mobile={8}>{secondaryGrpDisplayName}</Grid.Column>
                    <Grid.Column  computer={3} mobile={8}>{stockCode}</Grid.Column>
                    <Grid.Column  computer={3} mobile={8}>{stockDescription} </Grid.Column>
                    <Grid.Column  computer={4} mobile={8}>
                      <div className="quantity-form">
                        <Form>
                          <label>Quantity</label>
                          <Form.Field>
                            <NumericInputBox
                              value={quantity}
                              onChangeHandler={value =>
                                changeQuantityHandler(value, stockCode, pack)
                              }
                            />
                          </Form.Field>
                        </Form>
                      </div>
                    </Grid.Column>
                    <Grid.Column computer={3} mobile={8}>
                      <div
                        className="remove-fitter"
                        onClick={e => removeProduct(stockCode)}
                      >
                        x remove
                      </div>
                    </Grid.Column>
                  </Grid>
                </Segment>
              );
            })}
          </Segment.Group>
        </div>
      )}
    </React.Fragment>
  );
};

AdditionalProductInfo.propTypes = {
  products: PropTypes.array,
  removeProduct: PropTypes.func,
  changeQuantityHandler: PropTypes.func
};

export default AdditionalProductInfo;
