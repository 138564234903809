import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authReducer from "../auth.reducer";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/Loader";
import { Grid, Button } from "semantic-ui-react";
import polyglot from "../../../i18n";
import "./verifyEmail.css";

class VerifyEmail extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      sendEmail: false,
      errorMessage: "",
      sendEmailText: "",
      isActive: false,
    };
  }

  componentDidMount = async () => {
    const { code } = this.props.match.params;
    if (code) {
      this.setState({ loading: true });
      await this.props.actionVerifyEmailCode(code);
      this.setState({ loading: false });
    } else {
      this.props.history.push("/");
    }
  };

  goHome = () => {
    this.props.history.push("/");
  };
  resendVerifyAccountEmailHandler = async () => {
    this.setState({
      loading: true,
      sendEmail: true,
    });
    const { email } = this.props.match.params;
    await this.props.actionResendAccountVerificationEmail(email);
    const { response } = this.props.auth;
    this.setState({
      loading: false,
    });
    if (response) {
      if (response.status !== 200) {
        this.setState({ errorMessage: "Something went wrong try again" });
        return;
      }
      if (response.status === 200) {
        this.setState({
          errorMessage: polyglot.t("createAccountPage.success.message2"),
          sendEmailText: "Email sent",
        });
      }
    }
  };

  render() {
    let text = "";
    let responseJSX = "";
    let loader = "";
    const { response } = this.props;
    const { errorMessage, sendEmail, sendEmailText } = this.state;
    if (response) {
      if (response.data?.token && response.data?.token !== null) {
        this.props.clearAuth();
        this.props.history.push(
          `/create-password/${response.data?.token}/${response.data?.message}`
        );
      }
      let { status, data } = this.props.response;
      if (status === 404) {
        responseJSX = (
          <div>
            {polyglot.t("verifyEmailPage.linkNotFound")}
            <br />
            <br />
            <Button
              secondary
              size="small"
              onClick={this.resendVerifyAccountEmailHandler}
            >
              {polyglot.t("loginPage.resendVerificationEmail")}
            </Button>
          </div>
        );
      } else if (status === 400) {
        // this.props.history("/");
        responseJSX = (
          <div>
            <span>Invite cancelled</span>
          </div>
        );
      } else if (status === 500) {
        // this.props.history("/");
        responseJSX = (
          <div style={{ paddingLeft: 30, paddingRight: 30 }}>
            <div>
              <span>
                The invite link has been accepted. If you have already set a
                password, log into the BOAhub <a href="/">here</a>.
              </span>
            </div>
            <div style={{ paddingTop: 20 }}>
              <span>
                If you have not yet set a password, reset your password{" "}
                <a href="/forgotPassword">here</a>.
              </span>
            </div>
          </div>
        );
      } else if (status === 406) {
        responseJSX = (
          <div>
            <span>Your Account is Active. Try Loging In.</span>
          </div>
        );
      } else if (status === 200) {
        const { customerType, isActive } = data;
        text = <span className="success">Email Verified</span>;
        if (isActive == true) {
          responseJSX = (
            <div>
              <p>Email Verified & Account Active</p>
              <p>
                Your email has been successfully verified & your account is
                ready to use.
              </p>
              <span>
                <p>
                  Login now -{" "}
                  <a href="" onClick={this.goHome}>
                    {" "}
                    CLICK HERE
                  </a>{" "}
                </p>
              </span>
              <p>If you have any questions Contact Us</p>
              <p>
                <a href="https://www.boa.co.nz/blog/">
                  <u>https://www.boa.co.nz/blog/</u>
                </a>{" "}
              </p>
            </div>
          );
        } else {
          responseJSX = (
            <div>
              <p>{polyglot.t("verifyEmailPage.enterprise.success1")} </p>
              <p>{polyglot.t("verifyEmailPage.enterprise.success2")} </p>
              <p>{polyglot.t("verifyEmailPage.enterprise.success3")} </p>
              <p>
                {" "}
                {polyglot.t("topMenu.number")} {" or "}{" "}
                {polyglot.t("topMenu.email")}{" "}
              </p>
              <p>{polyglot.t("verifyEmailPage.enterprise.success4")}</p>
              <p>
                <a href="https://www.boa.co.nz/blog/">
                  <u>https://www.boa.co.nz/blog/</u>
                </a>{" "}
              </p>
            </div>
          );
        }
      }
    }
    if (this.state.loading) {
      loader = <Loader text="Verifying code, please wait" />;
    }

    return (
      <Grid>
        <Grid.Row verticalAlign="middle">
          <a href="/" style={{ margin: "0px auto" }}>
            {" "}
          </a>
        </Grid.Row>
        <Grid.Row verticalAlign="middle" className="verify-ebox">
          {loader}
          <div className="msg">
            <p>
              <b>
                {!sendEmail && text}
                {sendEmail && sendEmailText}
              </b>
            </p>
            <p>
              <b>{!sendEmail && responseJSX}</b>
              <b>{sendEmail && errorMessage}</b>
            </p>

            <br />
            {this.props.isActive == true ? (
              <a href="" onClick={this.goHome}>
                Back
              </a>
            ) : null}
          </div>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    response: state.auth.response,
    verified: state.auth.verified,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(authReducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerifyEmail)
);
