import {
  apiGetInventories,
  apiAddInventory,
  apiUpdateInventory,
  apiTransferLocation,
  apiSellStock,
  apiConsumeStock,
  apiAttachStock,
  apiSearchStock,
  apiGetTypes
} from "./inventory.api";
import { actions } from "react-redux-form";

const ADD_STOCK_ITEM = "ADD_STOCK_ITEM";
const UPDATE_STOCK_ITEM = "UPDATE_STOCK_ITEM";
const SET_STOCK_ITEMS = "SET_STOCK_ITEMS";
const TOGGLE_MODAL = "TOGGLE_MODAL";
const TRANSFER_LOCATION = "TRANSFER_LOCATION";
const SELL_STOCK = "SELL_STOCK";
const CONSUME_STOCK = "CONSUME_STOCK";
const SET_STOCK_OBJECT = "SET_STOCK_OBJECT";
const ASSIGN_STOCK = "ASSIGN_STOCK";
const SEARCH_STOCK = "SEARCH_STOCK";
const STOCK_TYPES = "STOCK_TYPE";

const initialState = {
  allInventory: {},
  isModalOpen: false,
  searchSuggestion: [],
  response: {},
  stock: {},
  stockTypes: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_STOCK_ITEM: {
      return {
        ...state
      };
    }
    case UPDATE_STOCK_ITEM: {
      return {
        ...state
      };
    }
    case SET_STOCK_ITEMS: {
      return {
        ...state,
        allInventory: action.payload
      };
    }
    case TOGGLE_MODAL: {
      return {
        ...state,
        isModalOpen: action.payload
      };
    }

    case TRANSFER_LOCATION: {
      return {
        ...state,
        response: action.payload
      };
    }
    case SELL_STOCK: {
      return {
        ...state,
        response: action.payload
      };
    }
    case CONSUME_STOCK: {
      return {
        ...state,
        response: action.payload
      };
    }
    case ASSIGN_STOCK: {
      return {
        ...state,
        response: action.payload
      };
    }
    case SET_STOCK_OBJECT: {
      return {
        ...state,
        stock: action.payload
      };
    }
    case SEARCH_STOCK: {
      return {
        ...state,
        searchSuggestion: action.payload
      };
    }

    case STOCK_TYPES:
      return {
        ...state,
        stockTypes: action.payload
      };

    default:
      return { ...state };
  }
};

export const actionGetInventories = (
  limit,
  pageNumber,
  stockId,
  type,
  location,
  searchText
) => {
  return async dispatch => {
    try {
      const result = await apiGetInventories(
        limit,
        pageNumber,
        stockId,
        type,
        location,
        searchText
      );
      dispatch({ type: SET_STOCK_ITEMS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_STOCK_ITEMS, payload: e });
    }
  };
};

export const actionAddInventory = data => {
  return async dispatch => {
    try {
      const result = await apiAddInventory(data);
      dispatch({ type: ADD_STOCK_ITEM, payload: result });
    } catch (e) {
      dispatch({ type: ADD_STOCK_ITEM, payload: e });
    }
  };
};

export const actionUpdateInventory = data => {
  return async dispatch => {
    try {
      const result = await apiUpdateInventory(data);
      dispatch({ type: UPDATE_STOCK_ITEM, payload: result.data });
    } catch (e) {
      dispatch({ type: UPDATE_STOCK_ITEM, payload: e });
    }
  };
};

export const actionToggleModal = flag => {
  return async dispatch => {
    dispatch({ type: TOGGLE_MODAL, payload: flag });
  };
};

export const actionSetStock = stock => {
  return async dispatch => {
    dispatch({ type: SET_STOCK_OBJECT, payload: stock });
  };
};

export const actionTransferLocation = data => {
  return async dispatch => {
    try {
      const result = await apiTransferLocation(data);
      dispatch({ type: TRANSFER_LOCATION, payload: result });
    } catch (e) {
      dispatch({ type: TRANSFER_LOCATION, payload: e.response });
    }
  };
};

export const actionSellStock = data => {
  return async dispatch => {
    try {
      const result = await apiSellStock(data);
      dispatch({ type: SELL_STOCK, payload: result });
    } catch (e) {
      dispatch({ type: SELL_STOCK, payload: e.response });
    }
  };
};

export const actionConsumeStock = data => {
  return async dispatch => {
    try {
      const result = await apiConsumeStock(data);
      dispatch({ type: CONSUME_STOCK, payload: result });
    } catch (e) {
      dispatch({ type: CONSUME_STOCK, payload: e.response });
    }
  };
};

export const actionAssignStock = data => {
  return async dispatch => {
    try {
      const result = await apiAttachStock(data);
      dispatch({ type: ASSIGN_STOCK, payload: result });
    } catch (e) {
      dispatch({ type: ASSIGN_STOCK, payload: e.response });
    }
  };
};

export const actionSearchStock = text => {
  return async dispatch => {
    try {
      const result = await apiSearchStock(text);
      dispatch({ type: SEARCH_STOCK, payload: result.data });
    } catch (e) {
      dispatch({ type: SEARCH_STOCK, payload: e.response });
    }
  };
};

export const actionInventorySetFormField = (name, field) => {
  return dispatch => {
    dispatch(actions.change("forms." + name, field));
  };
};

export const actionSetQuantity = (name, field) => {
  return dispatch => {
    dispatch(actions.change("forms." + name, field));
  };
};

export const actionResetForm = name => {
  return dispatch => {
    dispatch(actions.reset("forms." + name));
  };
};

export const actionsGetTypes = text => {
  return async dispatch => {
    try {
      const result = await apiGetTypes(text);
      dispatch({ type: STOCK_TYPES, payload: result.data });
    } catch (e) {
      dispatch({ type: STOCK_TYPES, payload: e.response });
    }
  };
};
