import React from "react";
import { showSuccessToast } from "../../utils/toastUtils";

const addToCart = ({ stockCode, isPresent }) => {
  if (!_.isEmpty(isPresent)) {
    showSuccessToast(
      <div>
        <i className="shopping cart icon" />{" "}
        {`Product ${stockCode} is already in your cart - please check the quantity.`}
      </div>
    );
  } else {
    showSuccessToast(
      <div>
        <i className="shopping cart icon" />{" "}
        {`Added  product ${stockCode} to cart.`}
      </div>
    );
  }
};

export default addToCart;
