import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Dropdown } from "semantic-ui-react";

const ContactSearch = ({
  customerList,
  onSelectContact,
  customerId,
  contactName,
}) => {
  const [contactList, setContactList] = useState(false);
  const [assetLoading, setAssetLoading] = useState(false);

  useEffect(() => {
    if (customerId) {
      const customer = customerList.find((item) => item.id === customerId);
      if (customer) {
        customer.customerContacts.map((item) => {
          item.text = item.contactName;
          item.value = item.contactName;
        });
        setContactList(customer.customerContacts);
      }
    }
  }, [customerId]);

  function handleChange(value) {
    const contact = contactList.find((item) => item.contactName === value);
    if (contact) handleResultSelect(contact);
  }

  const handleResultSelect = (result) => {
    setAssetLoading(true);
    const data = {
      id: result.id,
      customerId: result.customerId,
      contactName: result.contactName,
      contactNumber: result.contactNumber,
      email: result.email,
    };
    onSelectContact(data);
    setTimeout(function () {
      setAssetLoading(false);
    }, 500);
  };

  return (
    <Dropdown
      noResultsMessage="No contacts found."
      placeholder={assetLoading ? "" : "Search Contact"}
      search
      selection
      options={contactList}
      value={contactName}
      onChange={(e, data) => handleChange(data.value)}
      style={{
        width: "100%",
      }}
    />
  );
};

export default ContactSearch;
