import Polyglot from "node-polyglot";

const ePhrases = require("./languages/en.json");
const hPhrases = require("./languages/hindi.json");

let locale = window.localStorage.getItem("locale");

if (!locale) {
  locale = "en";
  window.localStorage.setItem("locale", locale);
}

const phrases = locale === "hindi" ? hPhrases : ePhrases;

const polyglot = new Polyglot({ locale, phrases });
window.polyglot = polyglot;

export default polyglot;
