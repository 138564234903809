import axios from "axios";
import { MAINTENANCE_FORM, CHECK_POINT } from "../../constants/apiLink";

export const apiGetForms = async () => {
  const url = MAINTENANCE_FORM;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiGetCheckPoints = async templateName => {
  const url = MAINTENANCE_FORM + CHECK_POINT + "/" + templateName;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiAddForm = async data => {
  const url = MAINTENANCE_FORM;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiUpdateForm = async data => {
  const { _id } = data;
  const url = MAINTENANCE_FORM + _id;

  return axios.put(url, data).then(result => {
    return result;
  });
};

export const apiDeleteForm = async id => {
  const url = MAINTENANCE_FORM + id;
  return axios.delete(url).then(result => {
    return result;
  });
};

export const apiGetSingleForm = async id => {
  const url = MAINTENANCE_FORM + id;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiGetTemplateNames = async () => {
  const url = MAINTENANCE_FORM  + CHECK_POINT + "/" ;
  return axios.get(url).then(result => {
    return result;
  });
};
