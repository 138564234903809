import React, { useEffect, useMemo, useState } from "react";
import { Icon } from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
import { showErrorToast } from "../../utils/toastUtils";
import "./myAssets.css";
import { fileIsImage } from "../../utils/utils";

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function AssetChecklistUploader(props) {
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop: (acceptedFiles) => {
      const imageListCount = 1;

      if (acceptedFiles.length > imageListCount) {
        showErrorToast("Maximum of 1 file only");
      } else {
        setHasUploaded(true);
        setImagesUploaded(acceptedFiles);
      }
    },
  });

  const [hasUploaded, setHasUploaded] = useState(false);
  const [image1Src, setImage1Src] = useState("");

  const style = useMemo(
    () => ({
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  function base64ToFile(base64, filename) {
    var arr = base64.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  async function compressBase64Image(base64String, desiredSizeInKb) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        let quality = 1; // initial quality
        let compressedBase64 = canvas.toDataURL("image/jpg", quality);

        resolve(compressedBase64);
      };
      img.onerror = reject;
      img.src = base64String;
    });
  }

  function getBase64(file, index) {
    if (typeof file === "string" || file instanceof String) {
      file = base64ToFile(`data:image/jpg;base64,${file}`, "image.jpg");
    }
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      compressBase64Image(reader.result, 10).then((compressedBase64Image) => {
        var compressedBase64EncodedImage = compressedBase64Image.split(",")[1];
        if (index === 0) setImage1Src(compressedBase64EncodedImage);
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  useEffect(() => {
    props.setFileSrc(image1Src);
  }, [image1Src]);

  useEffect(() => {
    if (hasUploaded) {
      props.setFile(imagesUploaded[0]);
      imagesUploaded.map((file, index) => {
        if (fileIsImage(file)) getBase64(file, index);
      });
    }
  }, [JSON.stringify(imagesUploaded)]);

  return (
    <div disabled={props.disabled}>
      <input {...getInputProps()} />
      <div
        {...getRootProps({ style })}
        className="image-uploader"
        style={{ paddingTop: 5, display: "inline !important" }}
      >
        <label
          htmlFor="imageInput"
          className="file-upload-button"
          style={{ paddingTop: 10, color: "#afabab" }}
        >
          <Icon name="plus" size="big" />
        </label>
      </div>
    </div>
  );
}
