import React from "react";
import { Message, List } from "semantic-ui-react";
import _ from "lodash";
import "./cartProducts.css";
import CartProduct from "./CartProduct";

class CartProducts extends React.Component {
  constructor() {
    super();
    this.state = {
      updateProductKey: null,
      update: false,
      quantity: 1,
      length: 1,
    };
  }

  updateState = (state) => {
    this.setState(state);
  };

  selectProductHandler = (stockCode, refId, userCart, isSelected) => {
    let productData = {
      stockCode,
      userCart,
      refId,
    };
    this.props.setProductIsSelected(productData, isSelected);
  };

  quantityChangeHandler = (
    value = 1,
    stockCode,
    pack,
    userCart,
    refId,
    isManual
  ) => {
    this.addQuantityHandler(
      Number(value),
      stockCode,
      pack,
      userCart,
      refId,
      isManual
    );
  };

  addQuantityHandler = (
    quantity,
    stockCode,
    pack,
    userCart,
    refId,
    isManual
  ) => {
    let quantityData = {
      stockCode,
      quantity,
      pack,
      userCart,
      refId,
    };
    this.props.addQuantityToCart(quantityData, isManual);
  };

  updateDescriptionHandler = (item) => {
    this.props.updateDescriptionOfProduct(item);
  };

  goToSearchPage = () => {
    this.props.history.push("/search");
  };

  render() {
    const {
      cartProducts = [],
      userCart,
      removeFromCart,
      options,
      isLoading,
      updatingStockCode = "",
      showPrice = false,
    } = this.props;

    return (
      <div className="cart-products">
        {!cartProducts.length && (
          <Message
            icon="cart"
            header="Your cart is empty"
            content={
              <div>
                <span
                  className="cart-go-search-link"
                  onClick={this.goToSearchPage}
                >
                  Click here to add products
                </span>
              </div>
            }
          />
        )}

        <List celled>
          {cartProducts.length > 0 &&
            _.map(cartProducts, (item, key) => {
              return (
                <CartProduct
                  key={item.id}
                  item={item}
                  userCart={userCart}
                  removeFromCart={removeFromCart}
                  options={options}
                  isLoading={isLoading}
                  updatingStockCode={updatingStockCode}
                  quantityChangeHandler={this.quantityChangeHandler}
                  updateDescriptionHandler={this.updateDescriptionHandler}
                  selectProductHandler={this.selectProductHandler}
                  showPrice={showPrice}
                />
              );
            })}
        </List>
      </div>
    );
  }
}

export default CartProducts;
