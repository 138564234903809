import axios from "axios";
import {
  ASSETS,
  SCHEDULE,
  INVENTORY,
  HOSES_FOR_DROPDOWN,
  ATTACH_HOSE
} from "../../constants/apiLink";

export const apiGetSingleAsset = async id => {
  const url = ASSETS + id;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiGetSchedulesByAsset = async assetId => {
  const url = SCHEDULE + assetId;

  return axios.get(url).then(
    result => {
      return result;
    },
    error => {
      return error;
    }
  );
};

export const apiUpdateAsset = async asset => {
  const { _id } = asset;
  const url = ASSETS + _id;
  return axios.put(url, asset).then(result => {
    return result;
  });
};

export const apiDeleteAsset = async assetId => {
  const url = ASSETS + assetId;
  return axios.delete(url).then(result => {
    return result;
  });
};

export const apiDeleteSchedule = async scheduleName => {
  const url = SCHEDULE + scheduleName;

  return axios.delete(url).then(result => {
    return result;
  });
};

export const apiAddSchedule = async data => {
  const url = SCHEDULE;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiGetAllHoses = async data => {
  const url = INVENTORY + HOSES_FOR_DROPDOWN;
  return axios.get(url).then(result => {
    return result;
  });
};
export const apiAttachHoses = async data => {
  const url = ASSETS + ATTACH_HOSE;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiGetAttachHoses = async id => {
  const url = ASSETS + ATTACH_HOSE + "/" + id;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiUpdateAttachHose = async data => {
  const url = ASSETS + "updateAttachHose";
  return axios.put(url, data).then(result => {
    return result;
  });
};

export const apiGetPositionList = async () => {
  const url = ASSETS + "hosesAndAttachments/hosePositions";
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiSaveNewPosition = async position => {
  const url = ASSETS + "hosesAndAttachments/position";
  const data = {
    position: position
  };
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiGetHoseSuggestion = async data => {
  const { model, make, positionId } = data;
  const url =
    ASSETS +
    "hosesAndAttachments/hoseSuggestions?model=" +
    model +
    "&make=" +
    make +
    "&positionId=" +
    positionId;
  return axios.get(url).then(result => {
    return result;
  });
};
