import "./stockForm.css";
import polyglot from "../../i18n";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import NumericInput from "react-numeric-input";
import { Control, Errors } from "react-redux-form";
import * as stockReducer from "../StockReview/stock.reducer";
import LocationDropdown from "../../components/LocationDropdown";
import { showSuccessToast } from "../../utils/toastUtils";
import {
  Grid,
  Divider,
  Icon,
  Form,
  Button,
  Message,
  Radio,
  Search,
  Modal
} from "semantic-ui-react";
import ResultRenderer from "./ResultRenderer";
import { getErrorMessage } from "../../utils/error.utils";
import inventoryLocationReducer, * as locationReducer from "../InventoryLocation/inventoryLocation.reducer";
import { apiGetProductDetails } from "../HoseMaker/hoseMaker.api";
import _ from "lodash";
import Description from "../Search/Description";

const ID = "stockId";
const STOCK_CODE = "stockCode";
const DESCRIPTION = "stockDescription";
const PRICE = "price";

const STOCK_FORM = "stockForm";
class StockForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      errorMessage: "",
      value: "",
      filter: "",
      searchMessage: null,
      isBoaStock: true,
      updateNonBoaStock: true,
      isOpenForm: false
    };
  }
  componentWillUnmount = async () => {
    await this.props.actionResetStockForm();
  };

  componentDidMount = async () => {
    const urlParams = this.getUrlParams();
    if (urlParams) {
      this.setState({
        updateNonBoaStock: false
      });
      this.setInitialForm(urlParams);
      await this.props.actionResetStockForm();
      await this.props.actionGetAllLocationToDropdown();
      return;
    }
    await this.props.actionGetAllLocationToDropdown();
  };

  setInitialForm = async stock => {
    await this.props.actionResetStockForm();
    let { _id, stockCode, stockDescription, price } = stock;
    const data_to_set = {
      _id,
      stockCode,
      stockDescription,
      price,
      isBoaStock: false
    };
    this.setState({
      isBoaStock: false
    });
    this.props.actionSetInitialForm(data_to_set);
    await this.props.actionSetStockFormField("quantity", 1);
  };

  getUrlParams = () => {
    this.props.actionResetStockForm();
    let url = document.location.href;
    url = new URL(url);

    const { searchParams } = url;
    const _id = searchParams.get(ID);
    const stockCode = searchParams.get(STOCK_CODE);
    const stockDescription = searchParams.get(DESCRIPTION);

    const price = searchParams.get(PRICE);

    if (!!_id || !!stockCode || stockDescription || price) {
      return { _id, stockCode, stockDescription, price };
    }

    return null;
  };

  goBack = () => {
    this.props.history.push("/inventory");
  };

  setLocation = (e, { value }) => {
    this.setState({ isError: false });
    this.props.actionSetStockFormField("location", value);
  };

  submitStockForm = async e => {
    const { forms } = this.props;
    const { stockForm } = forms;
    const { location, quantity, price, stockCode } = stockForm;
    if (!location) {
      return this.setState({
        isError: true,
        errorMessage: "Location is required"
      });
    } else {
      this.clearErrorMessage();
    }
    if (quantity === 0) {
      this.setState({
        isError: true,
        errorMessage: "Quantity can not be 0"
      });
      return;
    } else {
      this.clearErrorMessage();
    }
    if (price === 0) {
      this.setState({
        isError: true,
        errorMessage: "Price can not be 0"
      });
      return;
    } else {
      this.clearErrorMessage();
    }

    this.setState({ isLoading: true });
    const { isBoaStock } = this.state;
    const dataToSend = { ...stockForm, isBoaStock };
    await this.props.actionAddStock(dataToSend);
    const { response } = this.props;
    const { status } = response;
    this.setState({ isLoading: false });
    if (status === 200) {
      showSuccessToast("Stock  added");
    } else {
      // const { data } = response;
      const { code } = response;
      let message = await getErrorMessage(code);
      return this.setState({
        isError: true,
        errorMessage: message
      });
    }
    this.goBack();
    this.clearErrorMessage();
  };

  textHandle = event => {
    const { target } = event;
    const { value } = target;
    let newValue = value;
    if (newValue.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  };

  quantityChangeHandler = (e, data) => {
    this.props.actionSetQuantity(`${STOCK_FORM}.quantity`, e);
  };

  priceChangeHandler = (e, data) => {
    this.props.actionSetQuantity(`${STOCK_FORM}.price`, e);
  };

  handleRadioChange = async (e, { value }) => {
    this.setState({ isBoaStock: value });
    this.props.actionSetRadioButtonField(`${STOCK_FORM}.isBoaStock`, value);
    await this.props.actionResetStockForm();
  };

  handleResultSelect = async (e, { result }) => {
    const { stockCode, stockDescription } = result;
    //const { filter } = this.state;
    this.setState({ value: stockCode, isLoading: true });
    const productDetails = await apiGetProductDetails(stockCode);
    const { data = {} } = productDetails;
    const { price } = data;
    await this.props.actionBOAStockSetFormField(
      `${STOCK_FORM}.stockCode`,
      stockCode
    );
    this.props.actionSetQuantity(`${STOCK_FORM}.price`, price);
    this.props.actionSetQuantity(
      `${STOCK_FORM}.stockDescription`,
      stockDescription
    );
    this.setState({ isLoading: false });

    //this.callSearchApi(value, filter, 1);
  };

  handleSearchChange = async (e, { value }) => {
    e.preventDefault();
    this.setState({ value, searchMessage: null, isLoading: true });
    const { filter } = this.state;
    await this.props.actionBoaStockAutoComplete(value, filter);
    this.setState({ isLoading: false });
  };

  clearErrorMessage = () => {
    this.setState({
      isError: false,
      errorMessage: ""
    });
  };

  closeForm = () => {
    this.setState({
      isOpenForm: false
    });
  };

  addLocation = async () => {
    this.setState({ isLoading: true });
    this.props.actionResetLocationForm();
    const { locationForm } = this.props.forms;

    const { _id, ...data_to_save } = locationForm;
    await this.props.actionAddLocation(data_to_save);
    const { locationResponse } = this.props;
    const { status, data } = locationResponse;
    if (status === 200) {
      this.props.actionGetAllLocationToDropdown();
      showSuccessToast("Location added");
      this.setState({ isLoading: false, isOpenForm: false });
    } else {
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
    this.clearErrorMessage();
    //this.setState({ isLoading: false });
  };

  openAddLocation = () => {
    this.clearErrorMessage();
    this.setState({ isOpenForm: true });
  };

  render() {
    const {
      isLoading,
      isError,
      errorMessage,
      isBoaStock,
      value,
      updateNonBoaStock,
      isOpenForm
      //filter,
      //searchMessage
    } = this.state;
    const { locations, forms, autoCompleteResult = [] } = this.props;
    const { stockForm } = forms;
    const { location, price, quantity, stockCode } = stockForm;

    const stockRadioButton = (
      <Form.Group inline>
        <Form.Field>
          <Radio
            label="Add BOA stock"
            name="Add BOA stock"
            value={true}
            checked={isBoaStock === true}
            onChange={this.handleRadioChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Add Non BOA stock"
            name="Add Non BOA Stock"
            value={false}
            checked={isBoaStock === false}
            onChange={this.handleRadioChange}
          />
        </Form.Field>
      </Form.Group>
    );

    const searchBoaStock = (
      <Form.Field required>
        <label>{polyglot.t("stockForm.code")}</label>
        <Search
          className="search-box"
          loading={isLoading}
          placeholder="Search products by stock code"
          resultRenderer={ResultRenderer}
          onResultSelect={this.handleResultSelect}
          onSearchChange={_.debounce(this.handleSearchChange, 500, {
            leading: true
          })}
          noResultsMessage="No direct matches found."
          results={autoCompleteResult}
          value={value}
          {...this.props}
        />
      </Form.Field>
    );

    const stockCodeJSX = (
      <Form.Field required>
        <label>{polyglot.t("stockForm.code")}</label>
        <Control
          type="text"
          model="forms.stockForm.stockCode"
          placeholder={polyglot.t("stockForm.code")}
          validateOn="change"
          required
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.stockForm.stockCode"
          show="touched"
          messages={{
            valueMissing: polyglot.t("stockForm.required.code")
          }}
        />
      </Form.Field>
    );
    const quantityJSX = (
      <Form.Field required>
        <label>{polyglot.t("stockForm.quantity")}</label>
        <NumericInput
          min={1}
          max={10000}
          maxLength={5}
          className="form-control"
          validateOn="change"
          placeholder="Quantity"
          required
          onChange={this.quantityChangeHandler}
          value={quantity}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.stockForm.quantity"
          show="touched"
          messages={{
            valueMissing: polyglot.t("stockForm.required.quantity")
          }}
        />
      </Form.Field>
    );

    const stockLocation = (
      <Form.Field required>
        <label>{polyglot.t("stockForm.location")}</label>
        <LocationDropdown
          setLocation={this.setLocation}
          locationId={location}
          locations={locations}
        />
        {_.isEmpty(locations) ? (
          <span style={{ color: "red" }}>
            No locations available,{" "}
            <a onClick={this.openAddLocation}>Create a New Stock Location! </a>
          </span>
        ) : null}
      </Form.Field>
    );

    const priceJSX = (
      <div>
        {isBoaStock ? (
          <Form.Field>
            <label>{polyglot.t("stockForm.price")}</label>
            {price}
          </Form.Field>
        ) : (
          <Form.Field required>
            <label>{polyglot.t("stockForm.price")}</label>
            <NumericInput
              min={1}
              max={10000}
              maxLength={5}
              className="form-control"
              precision={0}
              validateOn="change"
              placeholder="Price"
              required
              onChange={this.priceChangeHandler}
              value={price}
            />
            <Errors
              style={{ color: "red" }}
              model="forms.stockForm.price"
              show="touched"
              messages={{
                valueMissing: polyglot.t("stockForm.required.price")
              }}
            />
          </Form.Field>
        )}
      </div>
    );

    const description = (
      <Form.Field required>
        <label>{polyglot.t("stockForm.description")}</label>
        <Control.textarea
          model="forms.stockForm.stockDescription"
          placeholder={polyglot.t("stockForm.description")}
          validateOn="change"
          rows={3}
          required
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.stockForm.stockDescription"
          show="touched"
          messages={{
            valueMissing: polyglot.t("stockForm.required.description")
          }}
        />
      </Form.Field>
    );

    const AddLocation = (
      <Modal open={isOpenForm} onClose={() => this.closeForm()} closeIcon>
        <Modal.Header>Add location</Modal.Header>
        <Modal.Content className="add-location">
          <Form
            model="forms.locationForm"
            error={isError}
            onSubmit={() => this.addLocation()}
          >
            <Grid.Column>
              <Form.Field>
                <label>{polyglot.t("assetForm.location")}</label>
                <Control
                  type="text"
                  model="forms.locationForm.name"
                  placeholder={polyglot.t("assetForm.location")}
                  validateOn="change"
                  className="input-box"
                  onKeyPress={event => this.textHandle(event)}
                />
                <Errors
                  style={{ color: "red" }}
                  model="forms.locationForm.name"
                  show="touched"
                  messages={{
                    valueMissing: "location name is required"
                  }}
                />
                <Message error={true} content={errorMessage} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={16} className="button-panel" textAlign="right">
              <Button
                size="small"
                onClick={this.closeForm}
                className="button-float"
              >
                Close
              </Button>
              {!isLoading ? (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  type="submit"
                  content="Submit"
                />
              ) : (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  content="Submit"
                  loading
                />
              )}
            </Grid.Column>
          </Form>
        </Modal.Content>
      </Modal>
    );

    return (
      <div className="my-stock-form">
        <MetaData title="Stock Form" />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading,please wait</Loader>
          )}

          <Grid.Column width={16}>
            <Grid>
              <Grid.Column computer={10} mobile={16} width={10}>
                <h3>
                  <Icon name="archive" />
                  Stock Information
                </h3>
              </Grid.Column>
              <Grid.Column textAlign="right" width={6} mobile={16} computer={6}>
                <Button
                  size="tiny"
                  onClick={this.goBack}
                  icon="left arrow"
                  content="Go back"
                  labelPosition="left"
                  className="button-float"
                />
              </Grid.Column>
            </Grid>
            <Divider />
          </Grid.Column>
        </Grid>

        <Grid className="stock-component">
          <Form
            error={isError}
            model="form.profileInfo"
            onSubmit={this.submitStockForm}
            className="stock-form-box"
          >
            <Grid centered>
              <Grid.Column width={16} computer={16} mobile={16}>
                {updateNonBoaStock ? stockRadioButton : ""}
              </Grid.Column>
            </Grid>
            <Grid columns="equal">
              <Grid.Column width={8} computer={8} mobile={16}>
                {isBoaStock ? searchBoaStock : stockCodeJSX}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {stockLocation}
              </Grid.Column>
              <Grid.Column width={8} computer={8} mobile={16}>
                {quantityJSX}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {priceJSX}
              </Grid.Column>

              <Grid.Column width={16} computer={16} mobile={16}>
                {description}
              </Grid.Column>
              <Grid.Column width={16} computer={16} mobile={16}>
                <Divider />
              </Grid.Column>
            </Grid>
            <Message error={true} content={errorMessage} />
            <Grid.Column
              width={16}
              computer={16}
              mobile={16}
              style={{ textAlign: "right" }}
            >
              {!isLoading && (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  onSubmit={this.submitStockForm}
                >
                  Submit
                </Button>
              )}
              {isLoading && (
                <Button secondary size="small" className="button-float" loading>
                  {" "}
                  Submit{" "}
                </Button>
              )}
            </Grid.Column>
          </Form>
        </Grid>
        <Grid>{AddLocation}</Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    // searchSuggestion: state.inventory.searchSuggestion,
    autoCompleteResult: state.search.autoCompleteResult,
    locations: state.inventoryLocation.locations,
    response: state.stock.response,
    forms: state.forms,
    locationResponse: state.inventoryLocation.response
  };
};

const reducer = { ...locationReducer, ...stockReducer };

const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StockForm)
);
