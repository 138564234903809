import React from 'react';
import { Modal,  Button,} from 'semantic-ui-react'

class ExitGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            isLoading:false
        }
    }
    closeModal = () => {
        this.setState({ showModal: false })
    }
    openModal = () => {
        this.setState({ showModal:true })
    }

    handleExitGroup = () => {
        this.setState({isLoading:true})
        this.props.onExitChat();
        this.setState({isLoading:false})
        this.closeModal();
    }
    render() {
        const {
            showModal,
            isLoading
        } = this.state
        return (
            <div>
           
                <text onClick={this.openModal}>Exit group</text>
                <Modal className="custom_modal"  size='mini' onClose={this.closeModal} open={showModal} >
                 
                    <Modal.Content >
                        <Modal.Description>
                          <div className="modal_info text-center">
                              <h5>Are you sure you want to exit from this group? </h5>
                          </div>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions className="modal_footer">
                        <Button basic color='black' onClick={this.closeModal}>Cancel </Button>
                        <Button loading={isLoading} color='green' onClick={this.handleExitGroup}>Exit</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}
export default ExitGroupModal;