import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Icon, Image, Button } from "semantic-ui-react";
import * as hoseMakerReducer from "../hoseMaker.reducer";
import Loader from "../../../components/Loader";
import _ from "lodash";
import { showErrorToast } from "../../../utils/toastUtils";
import spiralG from "./assets/images/hose/spiralGuard.png";
import spiralHelpImage from "./assets/images/spriral-guard-help-image.jpg";

class SpiralGuard extends React.Component {

    constructor() {
        super();
        this.state = {
            isLoading: false
        };
    }

    componentDidMount() {
        this.getAllSpiralGuards();
    };

    getAllSpiralGuards = async () => {
        this.setState({ isLoading: true });
        await this.props.actionGetAllSpiralGuards();
        this.setState({ isLoading: false });
    }

    addSpiralHandler = async () => {
        await this.props.actionSetSpiralGuard();
        const { spiralGuard } = this.props;
        if (_.isEmpty(spiralGuard)) {
            showErrorToast("No spiral guard found");
        };
    };

    render() {
        const { spiralGuard = {} } = this.props;
        const { isLoading } = this.state;
        let addSpiralJSX = "";

        if (_.isEmpty(spiralGuard)) {
            addSpiralJSX =  (<Button color="green" fluid onClick={this.addSpiralHandler}> <Icon name="add" /> Add Spiral-Guard</Button>);
        }

        if (!_.isEmpty(spiralGuard)) {
            const { stockDescription } = spiralGuard;
            addSpiralJSX = (
                <div>
                    {stockDescription}
                    <Image src={spiralG} size="small" centered />
                </div>)  
        };

        return (<React.Fragment>
            {isLoading && <Loader text="Loading spiral Guards, please wait" />}
            <Grid>
                <Grid.Column width={16}>
                    {addSpiralJSX}
                    <div > 
                    <Image src={spiralHelpImage} size='tiny' floated='left' />
                        <span className="spiral-help">Help: Spiral guard protection will be added to the hose</span>
                    </div>
                </Grid.Column>
            </Grid>
        </React.Fragment>)
    }

};


const mapStateToProps = state => ({
    allSpiralGuard: state.hoseMaker.allSpiralGuards,
    spiralGuardDropDown: state.hoseMaker.spiralGuardDropDown,
    spiralGuard: state.hoseMaker.spiralGuard,
    hoseType: state.hoseMaker.hoseType
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(hoseMakerReducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SpiralGuard);
