import React from "react";
import { Message } from "semantic-ui-react";
import PropTypes from "prop-types";

const ShowErrorMessage = ({ isError, message }) => {
  if (message) {
   return <Message hidden={isError} error content={message} />
  } else {
    return "";
  }
};
ShowErrorMessage.propType = {
  isError: PropTypes.bool,
  message: PropTypes.string
};

export default ShowErrorMessage;
