import axios from "axios";
import { INVENTORY, AUDIT_TRAIL } from "../../constants/apiLink";
import { SEARCH } from "../../constants/routes";

export const apiGetAudits = async (
  limit,
  pageNumber,
  type,
  stockCode,
  fromDate,
  toDate,
  operator,
  assetName
) => {
  let url = INVENTORY + AUDIT_TRAIL;
  if (limit) {
    url = url + "?limit=" + limit;
  }
  if (pageNumber) {
    url = url + "&pageNumber=" + pageNumber;
  }
  if (type) {
    url = url + "&type=" + type;
  }
  if (stockCode) {
    url = url + "&stockCode=" + stockCode;
  }

  if (fromDate) {
    url = url + "&fromDate=" + fromDate;
  }
  if (toDate) {
    url = url + "&toDate=" + toDate;
  }
  if (operator) {
    url = url + "&operator=" + operator;
  }
  if (assetName) {
    url = url + "&assetName=" + assetName;
  }

  return axios.get(url).then(result => {
    return result;
  });
};

export const apiSearchStock = async text => {
  const url = INVENTORY + AUDIT_TRAIL + SEARCH +"?searchText=" + text;
  return axios.get(url).then(result => {
    return result;
  });
};