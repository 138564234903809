import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { Grid, Image } from "semantic-ui-react";

const ImageUploadInput = ({ selectedFile, onSelectFile }) => {
  return (
    <Grid>
      <Grid.Column width={8} mobile={8} computer={8} textAlign="center">
        <Dropzone
          style={{height: "20px !important", border: "2px dashed #555", borderRadius: "5px"}}
          accept="image/jpeg, image/png"
          multiple={false}
          maxSize={5000000}
          onDrop={onSelectFile}
        >
          <div className="dropzone">
            <p>Drop image here / Click here to upload image</p>
          </div>
        </Dropzone>
      </Grid.Column>
      <Grid.Column width={8}>
        {selectedFile ? (
          <Image src={selectedFile.link} size="small" style={{width:"80px", height: "80px"}} />
          ) : null}
          </Grid.Column>
    </Grid>
  );
};

export default ImageUploadInput;

ImageUploadInput.propTypes = {
  onSelectFile: PropTypes.func,
  selectedFile: PropTypes.object
};
