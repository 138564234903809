import React, { Component, PureComponent } from "react";
//import styles from "./MessageBubble.module.css";
import PropTypes from "prop-types";
import { Loader, Modal, Icon, List, Image } from "semantic-ui-react";
import WhatsappIcon from "./WhatsappIcon";
import ChatIcon from "./ChatIcon";
import '../../containers/Chat/chat.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as chatReducer from '../../containers/Chat/chat.reducer';
import ReactPlayer from "react-player";
import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from 'custom-error';


class MessageBubble extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMedia: this.props.message.type === "media",
      mediaDownloadFailed: false,
      mediaUrl: null
    };
  }

  componentDidMount = async () => {
    console.log(this.props.message)

    this.setState({
      ...this.state,
      type: (await this.props.message.getParticipant()).type
    });
    if (this.state.hasMedia) {
      this.props.message.media
        .getContentTemporaryUrl()
        .then((url) => {
          this.setState({ mediaUrl: url });
        })
        .catch((e) => this.setState({ mediaDownloadFailed: true }));
    }
    document
      .getElementById(this.props.message.sid)
      .scrollIntoView({ behavior: "smooth" });
    //this.props.getConversationDetails(this.props.message.sid)
    console.log("Mime :" + this.props.message
      // &&  this.props.message.state.media.state.contentType 
    )

  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    

    document
      .getElementById(this.props.message.sid)
      .scrollIntoView({ behavior: "smooth" });
  }

  render = () => {

    const { conversationDetails } = this.props;

    const m = this.props.message;


    const authorName = conversationDetails && conversationDetails.participantsDetails.map(author => {
      if (m.author === author.email) {
        return author.firstName, author.lastName


      }
    })


    const type = this.state.type;

    if (this.state.hasMedia) {
      //console.log("Message is media message");
      // log media properties
      //console.log("Media properties", m.media);
    }
    //console.log(m);
    return (

      <React.Fragment>
        <List id={m.sid}>

          {m.state.timestamp.toLocaleTimeString() !== m.state.timestamp.toLocaleTimeString() ? <List.Item className="seprator">
            <div className="seprator_date">
              <span className="msg_time">
                {m.state.timestamp.toLocaleTimeString()}
              </span>
            </div>
          </List.Item> : null


          }


          {this.props.direction === "incoming" ?
            <List.Item className="sent">
              {conversationDetails && conversationDetails.participantsDetails.map(author => {
                if (m.author === author.email) {
                  return <React.Fragment>

                    <div className="img_cont_msg">
                      <div className="contact_img_txt">

                        {author.firstName.charAt(0)}{author.lastName.charAt(0)}
                        {/* <span><i className="boa ba-single_user"></i></span> */}
                      </div>
                    </div>
                    <div className="msg_cotainer">
                      <h4 >

                        {author.firstName}&nbsp;{author.lastName}
                      </h4>



                      <br />
                      <div>
                        {this.state.hasMedia && (
                          <Media
                            hasFailed={this.state.mediaDownloadFailed}
                            url={this.state.mediaUrl}
                            message={m}
                          />
                        )}
                      </div>
                      <p>  {m.body} </p>
                      <span className="msg_time">
                        {m.state.timestamp.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}


                      </span>
                    </div>
                  </React.Fragment>


                  // <text>{author.firstName} &nbsp;{author.lastName}</text>
                }
              })}

            </List.Item>

            : <List.Item className="replies">

              <div className="msg_cotainer" >


                <br />
                <div>
                  {this.state.hasMedia && (
                    <Media
                      hasFailed={this.state.mediaDownloadFailed}
                      url={this.state.mediaUrl}
                      message={m}
                    />
                  )}
                </div>
                <p>  {m.body}



                </p>
                <span className="msg_time">
                  {m.state.timestamp.toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}
                </span>
              </div>
            </List.Item>
          }
        </List>

      </React.Fragment>


    );
  };
}

class Media extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showImage: false
    }
  }
  // componentDidUpdate(){
  //   alert(this.props.message.media.contentType)
  // }
  openModal = () => {
    this.setState({ showImage: true })
  }
  closeModal = () => {
    this.setState({ showImage: false })
  }

  render = () => {
    const { hasFailed, url } = this.props;
    const { showImage } = this.state;
    return (
      <div>
        <Modal closeIcon open={showImage} onClose={this.closeModal} size="tiny" >

          <div
            style={{ padding: "10px" }}
          >

            {this.props.message.media && this.props.message.media.contentType.slice(0, 2) === 'im' ?
              <div className="msg_imagecontainer">
                <div className="msg_imagewrapper">
                  <Image style={{ width: "100%", height: "100%" }} src={url} />
                </div></div>
              : (this.props.message.media && this.props.message.media.contentType.slice(0, 2) === 'vi' || 'au' ? <ReactPlayer url={url} width="100%" height="100%" controls={true} /> :null 
              
              // (this.props.message.media && (this.props.message.media.contentType.slice(0, 2) === 'ap' || 'te') ?<div>Documents
              //   <FileViewer
              //     fileType="pdf"
              //     filePath={url}
              //     errorComponent={CustomErrorComponent}
              //    // onError={this.onError}
              //      /></div>:null)
              
             
              
              )}
            {/* <img style={{ width: "100%", height: "100%" }} src={url} /> */}

          </div>

        </Modal>

        <div
          // className={`${styles.media}${!url ? " " + styles.placeholder : ""}`}
          onClick={this.openModal
          }
        >


          {/* <div className="msg_imagecontainer">
            <div className="msg_imagewrapper">
              <Image src='https://www.gstatic.com/webp/gallery/1.sm.jpg' rounded size="medium" /> </div>
            <div className="msg_cotainer">
              <p>Lorem ipsum is placeholder text commonly used in the graphic.</p>
              <span className="msg_time">8:40 AM, Today</span>
            </div>
          </div> */}
          {/* <img style={{ width: "100%", height: "100%" }} src={url} /> */}

          {

            this.props.message.media && this.props.message.media.contentType.slice(0, 2) === 'im' ? <div className="msg_imagecontainer">
              <div className="msg_imagewrapper">
                <Image style={{ width: "100%", height: "100%" }} src={url} />


              </div></div> : 
              
              (this.props.message.media && this.props.message.media.contentType.slice(0, 3) === 'vid' || 'aud' ? <div className="msg_imagecontainer">
                <div className="msg_imagewrapper">
                  
                  <ReactPlayer url={url} width="100%" height="100%" controls={true} />


                </div></div> :null
                // (this.props.message.media && this.props.message.media.contentType.slice(0, 2) === 'ap' ?  <div>Documents
                //   <FileViewer
                //     fileType="pdf"
                //     filePath={url}
                //     errorComponent={CustomErrorComponent}
                //    // onError={this.onError}
                //      />
                //      </div>:null)
                 )
          }


          {!url && !hasFailed && <Loader />}

          {hasFailed && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Icon name="warning" style={{ fontSize: "5em" }} />
              <p>Failed to load media</p>
            </div>
          )}

          {!hasFailed && url && (
            <div
            //className={styles.media_icon}
            >
              <div
              //style={{ zIndex: 123, position: "absolute" }}
              >
                {/* <Icon name="eye" style={{ fontSize: "5em", opacity: 0.3 }} /> */}
              </div>
              <div
              //className={styles.picture_preview}
              // style={{ backgroundImage: `url(${url})`, zIndex: 122 }}
              ></div>
            </div>
          )}
        </div>
      </div>
    );
  };
}

Media.propTypes = {
  hasFailed: PropTypes.bool.isRequired,
  url: PropTypes.string
};



const mapStateToProps = state => {
  return {

    conversationDetails: state.chatGroups.conversationDetails.data,
  }
}



const reducer = { ...chatReducer };

const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(MessageBubble);
