import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Label
} from "recharts";
import { Grid, Divider, Button } from "semantic-ui-react";
import * as home from "../../containers/Home/home.reducer";
import { bindActionCreators } from "redux";

const barChartStyle = {
  padding: '10px',
  width: '100%',
  height: '300px'
};

export class HomeBarChart extends React.Component {

  goToPage = path => {
    return this.props.history.push(`/${path}`);
  };

  componentWillMount = () => {
    this.getAccountDetails();
  };

  getAccountDetails = async () => {
    await this.props.actionGetAccountDetails();
    await this.props.actionGetTotalSavings();
  };

  render() {
    const { totalSavings = {} } = this.props;
    const { monthlySavings } = totalSavings;

    return (
      <Grid>
        <Grid.Column width={16} className=" no-padding">
          <Grid className="my-location-header">
            <Grid.Column computer={10} mobile={16} width={10}>
              <h3>
                Monthly Savings
                  </h3>
            </Grid.Column>
            <Grid.Column
              textAlign="right"
              width={6}
              mobile={16}
              computer={6}
            >
              <Button
                size="tiny"
                onClick={() => this.goToPage("home")}
                icon="left arrow"
                content="Go back"
                labelPosition="left"
                className="button-float"
              />
            </Grid.Column>
          </Grid>
          <Divider />
        </Grid.Column>



        <Grid.Column width={16} className="discount-section no-padding">
          {monthlySavings && monthlySavings.map((item) => {
            return <span>&nbsp;</span>
          })}
          <ResponsiveContainer>
            <BarChart
              style={barChartStyle}
              data={monthlySavings}
              margin={{ top: 5, right: 10, left: 10, bottom: 50 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="title" >
                <Label value="Months" offset={0} position="insideBottom" />
              </XAxis>
              <YAxis dataKey="total" >
                <Label value="Savings in NZD" angle={-90} position="insideLeft" />
              </YAxis>
              <Tooltip formatter= {(value, name, props) => ( <span> {Math.round(value)} NZD </span>)}/>
              {/* <Tooltip /> */}
              <Bar dataKey="total" fill="#21ba45" barSize={50} />
              {/* <Bar type="monotone" dataKey="uv" fill="#8884d8" barSize={30} /> */}
            </BarChart>
          </ResponsiveContainer>
        </Grid.Column>
      </Grid>
    );
  };
};



const mapStateToProps = state => {
  return {
    user: state.auth.user,
    account: state.home.account,
    totalSavings: state.home.totalSavings
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(home, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomeBarChart)
);
