import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import { Control, Errors } from "react-redux-form";
import * as locationReducer from "./assetLocation.reducer";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import { getErrorMessage } from "../../utils/error.utils";
import {
  Grid,
  Divider,
  Icon,
  Button,
  Modal,
  Form,
  Confirm,
  Message
} from "semantic-ui-react";
import "./assetLocation.css";
import polyglot from "../../i18n";

import LocationList from "../../components/LocationList";

class AssetLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isOpenForm: false,
      isEditForm: false,
      isDelete: false,
      isEdit: false,
      name: "",
      formId: null
    };
  }

  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.props.actionGetAllLocation();
    this.setState({ isLoading: false });
  };
  goToPage = path => {
    this.props.history.push(`/${path}`);
  };

  openAddLocation = () => {
    this.setState({ isOpenForm: true });
  };

  openEditForm = async data => {
    this.setState({ isEditForm: true, formId: data._id, name: data.name });
    await this.props.editAssetLocation("name", data.name);
  };

  openDeleteBox = name => {
    this.setState({ name, isDelete: true });
  };

  addLocation = async () => {
    this.setState({ isLoading: true });
    this.props.actionResetLocationForm();
    const { locationForm } = this.props.forms;
    const { _id, ...data_to_save } = locationForm;

    await this.props.actionAddLocation(data_to_save);
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      this.props.actionGetAllLocation();
      showSuccessToast("Location added");
      this.setState({ isLoading: false, isOpenForm: false });
    } else {
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
  };

  updateState = (isError, errorMessage) => {
    this.setState({ isError, errorMessage, isLoading: false });
  };

  deleteLocation = async () => {
    this.setState({ isLoading: true });
    const { name } = this.state;
    await this.props.actionDeleteLocation(name);
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      this.props.actionGetAllLocation();
      showSuccessToast("Location deleted");
    } else {
      const { code } = data;
      const message = getErrorMessage(code);
      showErrorToast(message);
    }
    this.closeDeleteBox();
    this.setState({ isLoading: false });
  };

  updateLocation = async () => {
    this.closeEditBox();
    this.setState({ isLoading: true });
    const { forms } = this.props;
    const { stockForm } = forms;
    const data = {
      name: stockForm.name
    };
    this.setState({ isEditForm: false });
    await this.props.actionUpdateLocation(this.state.formId, data);
    const { locationName } = this.props;
    await this.props.actionGetAllLocation(locationName);
    this.setState({ isLoading: false });
  };

  changeLocation = value => {
    const { forms } = this.props;
    const { stockForm } = forms;
    if (stockForm.name === undefined) {
      this.setState({ isEdit: false });
    } else {
      this.setState({ isEdit: true });
    }
  };

  closeDeleteBox = () => this.setState({ isDelete: false, name: "" });

  closeEditBox = () => this.setState({ isEdit: false, name: "" });

  closeForm = () => {
    this.setState({
      isOpenForm: false,
      isError: false,
      isLoading: false,
      isEditForm: false
    });
  };

  textHandle = event => {
    const { target } = event;
    const { value } = target;
    let newValue = value;
    if (newValue.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  };

  render() {
    const {
      isLoading,
      isOpenForm,
      isDelete,
      isEdit,
      isError,
      errorMessage
    } = this.state;
    const { allLocations } = this.props;

    const AddLocation = (
      <Modal open={isOpenForm} onClose={() => this.closeForm()} closeIcon>
        <Modal.Header>Add location</Modal.Header>
        <Modal.Content className="add-location">
          <Form
            model="forms.locationForm"
            error={isError}
            onSubmit={() => this.addLocation()}
          >
            <Grid.Column>
              <Form.Field>
                <label>{polyglot.t("assetForm.location")}</label>
                <Control
                  type="text"
                  model="forms.locationForm.name"
                  placeholder={polyglot.t("assetForm.location")}
                  validateOn="change"
                  className="input-box"
                  onKeyPress={event => this.textHandle(event)}
                />
                <Errors
                  style={{ color: "red" }}
                  model="forms.locationForm.name"
                  show="touched"
                  messages={{
                    valueMissing: "location name is required"
                  }}
                />
                <Message error={true} content={errorMessage} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={16} className="button-panel" textAlign="right">
              <Button
                size="small"
                onClick={this.closeForm}
                className="button-float"
              >
                Close
              </Button>
              {!isLoading ? (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  type="submit"
                  content="Submit"
                />
              ) : (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  content="Submit"
                  loading
                />
              )}
            </Grid.Column>
          </Form>
        </Modal.Content>
      </Modal>
    );

    const EditModel = (
      <Grid>
        {isLoading && (
          <Loader title="Loading, please wait">Loading,please wait</Loader>
        )}
        <Modal
          size={"small"}
          open={this.state.isEditForm}
          onClose={() => this.closeForm()}
          onSubmit={this.changeLocation}
        >
          <Modal.Header>Edit location</Modal.Header>
          <Modal.Content>
            <Form model="forms.stockForm" error={isError}>
              <Grid>
                <Grid.Column>
                  <Form.Field required>
                    <label>{polyglot.t("stockForm.location")}</label>
                    <Control
                      model="forms.stockForm.name"
                      placeholder={polyglot.t("stockForm.location")}
                      validateOn="change"
                      required
                      className="input-box"
                      onKeyPress={event => this.textHandle(event)}
                    />
                    <Errors
                      style={{ color: "red" }}
                      model="forms.stockForm.name"
                      show="touched"
                      messages={{
                        valueMissing: polyglot.t("stockForm.required.location")
                      }}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid>

              <Grid>
                <Grid.Column
                  width={16}
                  computer={16}
                  mobile={16}
                  style={{ textAlign: "right" }}
                >
                  <Button
                    size="small"
                    onClick={this.closeForm}
                    content=" Close"
                  />
                  {!isLoading && (
                    <Button
                      secondary
                      size="small"
                      content="Submit"
                      type="submit"
                    />
                  )}
                  {isLoading && (
                    <Button secondary size="small" content="Submit" loading />
                  )}
                </Grid.Column>
              </Grid>
            </Form>
          </Modal.Content>
        </Modal>
      </Grid>
    );

    return (
      <div className="my-asset">
        <Confirm
          open={isDelete}
          content="Are you sure you want to delete this location?"
          onCancel={this.closeDeleteBox}
          onConfirm={this.deleteLocation}
          confirmButton="Delete"
        />

        <Confirm
          open={isEdit}
          content="Are you sure you want to change this location?"
          //onCancel={() => this.setState({ isEdit: false })}
          onCancel={this.closeEditBox}
          onConfirm={this.updateLocation}
        />

        <MetaData title="Asset Location" />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading,please wait</Loader>
          )}
          <Grid.Row>
            <Grid.Column width={16}>
              <Grid className="my-location-header">
                <Grid.Column computer={10} mobile={16} width={10}>
                  <h3>
                    <Icon name="location arrow" />
                    Asset Locations
                  </h3>
                </Grid.Column>
                <Grid.Column
                  textAlign="right"
                  width={6}
                  mobile={16}
                  computer={6}
                >
                  <Button
                    size="tiny"
                    onClick={() => this.goToPage("asset")}
                    icon="left arrow"
                    content="Go back"
                    labelPosition="left"
                    className="button-float"
                  />
                  <Button
                    content="Add new"
                    onClick={this.openAddLocation}
                    icon="plus"
                    size="tiny"
                    color="black"
                  />
                </Grid.Column>
              </Grid>
              <Divider />
            </Grid.Column>

            <Grid.Column width={16}>
              <LocationList
                locations={allLocations}
                openDeleteBox={this.openDeleteBox}
                openAddLocation={this.openAddLocation}
                openEditForm={this.openEditForm}
                updateLocation={this.updateLocation}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid>{AddLocation}</Grid>
        <Grid>{EditModel}</Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allLocations: state.assetLocation.allLocations,
    response: state.assetLocation.response,
    forms: state.forms
  };
};

const reducer = { ...locationReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssetLocation)
);
