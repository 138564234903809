import {
  apiHoseCategories,
  apiGetAllSpiralGuards,
  apiGetProductDetails,
  apiGetHoseDiameters,
  apiSearchHoseWithFilter,
  apiSearchFittingForHose,
  apiAutocompleteFittings,
  apiGetFittingMeasurementByOrientation,
  apiAdditionalProductAutoComplete,
  apiAdditionalProductSearch,
  apiGetMakeAtBoaCharges,
  apiSaveHose,
  apiHoseAutoComplete,
  apiHoseSearchByAutoCompleteValue,
  apiGetCrimpSize,
  apiGetHoseData,
  apiGetFittingOrientationsByDashSize,
  apiGetHoseFerrule,
  apiFerruleSearchAutoComplete,
  apiSaveHoseRecipe
} from "./hoseMaker.api";
import _ from "lodash";

const SET_HOSE_TYPE = "SET_HOSE_TYPE";
const SET_LEFT_FITTING_TYPES = "SET_LEFT_FITTING_TYPES";
const SET_RIGHT_FITTING_TYPES = "SET_RIGHT_FITTING_TYPES";
const SET_HOSE_CATEGORIES = "SET_HOSE_CATEGORIES";
const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
const SET_AUTO_COMPLETE = "SET_AUTO_COMPLETE";
const RESET_SEARCH_RESULT = "RESET_SEARCH_RESULT";
const RESET_HOSE = "RESET_HOSE";

const SET_FITTING_ID = "SET_FITTING_ID";
const CLEAR_RIGHT_FITTING = "CLEAR_RIGHT_FITTING";
const CLEAR_LEFT_FITTING = "CLEAR_LEFT_FITTING";

const SET_LENGTH = "SET_LENGTH";
const SET_LENGTH_NOT_OAL = "SET_LENGTH_NOT_OAL";

const SET_RIGHT_ANGLE = "SET_RIGHT_ANGLE";
const SET_LEFT_ANGLE = "SET_LEFT_ANGLE";

const SET_ALL_SPIRAL_GUARDS = "SET_ALL_SPIRAL_GUARDS";
const SET_SPIRAL_GUARD = "SET_SPIRAL_GUARD";
const REMOVE_SPIRAL_GUARD = "REMOVE_SPIRAL_GUARD";

const SET_ADDITIONAL_PRODUCT_MODAL_VALUE = "SET_ADDITIONAL_PRODUCT_MODAL_VALUE";
const SET_ADDITIONAL_SEARCH_RESULT = "SET_ADDITIONAL_SEARCH_RESULT";

const SET_HOSE_DIAMETERS = "SET_HOSE_DIAMETERS";
const SET_FILTERED_HOSE_RESULT = "SET_FILTERED_HOSE_RESULT";
const SET_ORIENTATION = "SET_ORIENTATION";
const SET_MEASUREMENT = "SET_MEASUREMENT";
const CLONE_TO_RIGHT = "CLONE_TO_RIGHT";

const SET_DISTINCT_MEASUREMENT = "SET_DISTINCT_MEASUREMENT";
const CLEAR_FITTING_FILTER = "CLEAR_FITTING_FILTER";
const ADD_ADDITIONAL_PRODUCT = "ADD_ADDITIONAL_PRODUCT";
const REMOVE_ADDITIONAL_PRODUCT = "REMOVE_ADDITIONAL_PRODUCT";
const SET_MAKE_AT_BOA = "SET_MAKE_AT_BOA";
const SET_CHARGES = "SET_CHARGES";
const SET_HOSE_PRICE = "SET_HOSE_PRICE";
const SET_SAVE_HOSE_RESPONSE = "SET_SAVE_HOSE_RESPONSE";
const SET_HOSE_DESCRIPTION = "SET_HOSE_DESCRIPTION";
const CLEAR_ALL_VALUES = "CLEAR_ALL_VALUES";
const SET_CRIMP_SIZE = "SET_CRIMP_SIZE";
const SET_OVER_ALL_LENGTH = "SET_OVER_ALL_LENGTH";
const SET_HOSE_DATA_FROM_DB = "SET_HOSE_DATA_FROM_DB";
const SET_FITTING_ANGLES = "SET_FITTING_ANGLES";
const SET_SHOW_OAL_FLAG = "SET_SHOW_OAL_FLAG";
const CHANGE_ADDITIONAL_PRODUCT_QUANTITY = "CHANGE_ADDITIONAL_PRODUCT_QUANTITY";
const SET_HOSE_QUANTITY = "SET_HOSE_QUANTITY";
const SET_LEFT_FERRULE = "SET_LEFT_FERRULE";
const SET_RIGHT_FERRULE = "SET_RIGHT_FERRULE";
const SET_FERRULE_AUTO_COMPLETE_RESULT = "SET_FERRULE_AUTO_COMPLETE_RESULT";
const REMOVE_FERRULE = "REMOVE_FERRULE";
const SET_LEFT_SEAT_TYPE_IMAGE = "SET_LEFT_SEAT_TYPE_IMAGE";
const SET_RIGHT_SEAT_TYPE_IMAGE = "SET_RIGHT_SEAT_TYPE_IMAGE";
const SET_PATH_PARAMS = "SET_PATH_PARAMS";
const REMOVE_HOSE_CATEGORIES = "REMOVE_HOSE_CATEGORIES";
const SET_THREAD_TYPE_1 = "SET_THREAD_TYPE_1";
const SET_THREAD_TYPE_2 = "SET_THREAD_TYPE_2";
const SET_THREAD_SIZE_1 = "SET_THREAD_SIZE_1";
const SET_THREAD_SIZE_2 = "SET_THREAD_SIZE_2";
const SET_FLOW_FOR_HOSEMAKER = "SET_FLOW_FOR_HOSEMAKER";
const SAVE_HOSE_RECIPE = "SAVE_HOSE_RECIPE";

const initialState = {
  hoseType: null,
  leftFittingTypes: {},
  rightFittingTypes: {},
  categories: [],
  searchResult: {},
  autoCompleteResult: [],
  response: {},
  fittingId: "",
  hoseLength: 1000, //todo
  rightFittingAngle: 180,
  leftFittingAngle: 0,
  allSpiralGuards: [],
  spiralGuard: {},
  additionalProductCategories: [],
  addAdditionalProductModal: false,
  hoseDiameters: [],
  filteredHoses: {},
  orientation: "",
  measurement: "",
  distinctMeasurement: [],
  additionalProduct: [],
  makeAtBoa: true,
  charges: {},
  totalPrice: 0,
  hoseDescription: "",
  leftCrimpSize: "",
  rightCrimpSize: "",
  overAllLength: 0, //todo
  fittingOrientations: [],
  showOal: true,
  hoseQuantity: 1,
  leftFerrule: {},
  rightFerrule: {},
  leftSeatTypeImage: "",
  rightSeatTypeImage: "",
  editHose: false,
  assetId: "",
  model: "",
  subModal: "",
  position: "",
  createdBy: "",
  assetName: "",
  hoseId: "",
  selectedInventoryLocation: "",
  stockCode: "",
  flow: "",
  saveHoseRecipe: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_HOSE_TYPE: {
      const { payload } = action;
      return {
        ...state,
        hoseType: payload,
        leftFittingTypes: {},
        rightFittingTypes: {},
        hoseDiameters: [],
        filteredHoses: {},
        searchResult: {},
        orientation: "",
        measurement: "",
        distinctMeasurement: [],
        spiralGuard: {},
        leftCrimpSize: "",
        rightCrimpSize: "",
        fittingOrientations: [],
        leftFerrule: {},
        rightFerrule: {},
        leftSeatTypeImage: "",
        rightSeatTypeImage: ""
      };
    }
    case SET_LEFT_FITTING_TYPES: {
      const { payload } = action;
      const { productDimensions } = payload;
      let { lc = 0 } = productDimensions;
      const { rightFittingTypes = {} } = state;
      const { productDimensions: pdRight = {} } = rightFittingTypes;
      let { lc: lcRight = 0 } = pdRight;
      const { hoseLength } = state;
      if (lc === "#N/A") {
        lc = 0;
      }
      if (lcRight === "#N/A") {
        lcRight = 0;
      }
      return {
        ...state,
        leftFittingTypes: payload,
        searchResult: {},
        orientation: "",
        measurement: "",
        distinctMeasurement: [],
        overAllLength:
          lc && lcRight ? Number(hoseLength) + Number(lc) + Number(lcRight) : 0,
        fittingOrientations: [],
        showOal: lc && lcRight ? true : false,
        leftFerrule: {}
      };
    }
    case SET_RIGHT_FITTING_TYPES: {
      const { payload } = action;
      const { productDimensions } = payload;
      let { lc = 0 } = productDimensions;
      const { leftFittingTypes = {} } = state;
      const { productDimensions: pdLeft = {} } = leftFittingTypes;
      let { lc: lcLeft = 0 } = pdLeft;
      const { hoseLength } = state;
      if (lc === "#N/A") {
        lc = 0;
      }
      if (lcLeft === "#N/A") {
        lcLeft = 0;
      }
      let newOlLength = Number(lcLeft) + Number(lc);
      return {
        ...state,
        rightFittingTypes: payload,
        searchResult: {},
        orientation: "",
        measurement: "",
        distinctMeasurement: [],
        hoseLength: lc && lcLeft ? Number(hoseLength) : 0,
        overAllLength:
          lc && lcLeft ? Number(hoseLength) + Number(newOlLength) : 0,
        showOal: lc && lcLeft ? true : false,
        rightFerrule: {}
      };
    }
    case SET_HOSE_CATEGORIES: {
      const { data } = action.payload;
      return {
        ...state,
        categories: data,
        hoseDiameters: {}
      };
    }
    case REMOVE_HOSE_CATEGORIES: {
      return {
        ...state,
        categories: [],
        hoseDiameters: []
      };
    }
    case SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload
      };
    case SET_AUTO_COMPLETE: {
      return {
        ...state,
        autoCompleteResult: action.payload
      };
    }
    case RESET_SEARCH_RESULT: {
      return {
        ...state,
        autoCompleteResult: [],
        searchResult: {},
        hoseDiameters: [],
        filteredHoses: {}
      };
    }
    case RESET_HOSE: {
      return {
        ...state,
        hoseType: null,
        leftFittingTypes: {},
        rightFittingTypes: {},
        spiralGuard: {},
        hoseLength: 1000, //todo
        orientation: "",
        measurement: "",
        distinctMeasurement: [],
        hoseDescription: "",
        leftCrimpSize: "",
        rightCrimpSize: "",
        fittingOrientations: [],
        leftFerrule: {},
        rightFerrule: {},
        leftSeatTypeImage: "",
        rightSeatTypeImage: "",
        editHose: false,
        assetId: "",
        model: "",
        subModal: "",
        position: "",
        createdBy: "",
        assetName: "",
        stockCode: ""
      };
    }
    case SET_FITTING_ID: {
      return {
        ...state,
        fittingId: action.payload
      };
    }
    case CLEAR_RIGHT_FITTING: {
      return {
        ...state,
        rightFittingTypes: {},
        rightFerrule: {}
      };
    }
    case CLEAR_LEFT_FITTING: {
      return {
        ...state,
        leftFittingTypes: {},
        leftFerrule: {},
        rightFittingTypes: {},
        rightFerrule: {}
      };
    }
    case SET_LENGTH: {
      const { payload } = action;
      const { leftFittingTypes, rightFittingTypes } = state;
      const { productDimensions: leftPD = {} } = leftFittingTypes;
      const { productDimensions: rightPD = {} } = rightFittingTypes;
      const { lc: leftLength = 0 } = leftPD;
      const { lc: rightLength = 0 } = rightPD;
      const totalLength = Number(
        payload + Number(leftLength) + Number(rightLength)
      );
      return {
        ...state,
        hoseLength: leftLength == 0 || rightLength == 0 ? 0 : payload,
        overAllLength: leftLength == 0 || rightLength == 0 ? 0 : totalLength
      };
    }
    case SET_LENGTH_NOT_OAL: {
      const { payload } = action;
      const { leftFittingTypes, rightFittingTypes } = state;
      const { productDimensions: leftPD = {} } = leftFittingTypes;
      const { productDimensions: rightPD = {} } = rightFittingTypes;
      const { lc: leftLength = 0 } = leftPD;
      const { lc: rightLength = 0 } = rightPD;
      return {
        ...state,
        hoseLength: leftLength == 0 || rightLength == 0 ? 0 : payload
      };
    }
    case SET_RIGHT_ANGLE: {
      return {
        ...state,
        rightFittingAngle: action.payload
      };
    }
    case SET_LEFT_ANGLE: {
      return {
        ...state,
        leftFittingAngle: action.payload
      };
    }
    case SET_ALL_SPIRAL_GUARDS: {
      const { payload } = action;
      return {
        ...state,
        allSpiralGuards: payload
      };
    }
    case SET_SPIRAL_GUARD: {
      const { allSpiralGuards, hoseType } = state;
      const { productDimensions } = hoseType;
      const { hoseOuterDiameterMM: hoseOutDia } = productDimensions;
      let data = _.filter(allSpiralGuards, sg => {
        const { productDimensions } = sg;
        const {
          hoseInnerDiameterMM: spiralInDia,
          hoseOuterDiameterMM: spiralOutDia
        } = productDimensions;
        if (spiralInDia <= hoseOutDia && spiralOutDia >= hoseOutDia) {
          return sg;
        }
      });

      if (_.isEmpty(data[0])) {
        return {
          ...state,
          spiralGuard: {}
        };
      }

      return {
        ...state,
        spiralGuard: data[0]
      };
    }
    case REMOVE_SPIRAL_GUARD: {
      return {
        ...state,
        spiralGuard: {}
      };
    }
    case SET_ADDITIONAL_PRODUCT_MODAL_VALUE: {
      return {
        ...state,
        addAdditionalProductModal: action.payload
      };
    }
    case SET_ADDITIONAL_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload
      };
    case SET_HOSE_DIAMETERS:
      return {
        ...state,
        hoseDiameters: action.payload,
        filteredHoses: {}
      };
    case SET_FILTERED_HOSE_RESULT:
      return {
        ...state,
        filteredHoses: action.payload
      };
    case SET_ORIENTATION: {
      return {
        ...state,
        orientation: action.payload,
        distinctMeasurement: [],
        searchResult: {}
      };
    }
    case SET_MEASUREMENT: {
      const { measurement, threadType } = action.payload;
      return {
        ...state,
        measurement: measurement,
        threadType: threadType,
        searchResult: {}
      };
    }
    case CLONE_TO_RIGHT: {
      const {
        leftFittingTypes,
        rightFittingTypes,
        leftCrimpSize,
        hoseLength,
        leftFerrule,
        leftSeatTypeImage
      } = state;
      const clone = { ...leftFittingTypes };
      const ferrule = { ...leftFerrule };
      const { shortenedCode: leftCode, productDimensions } = clone;

      let { lc = 0 } = productDimensions;
      const { productDimensions: pdRight = {} } = rightFittingTypes;
      let { lc: lcRight = 0 } = pdRight;
      // let { lc = 0 } = productDimensions;
      const { shortenedCode: rightCode } = rightFittingTypes;
      if (lc === "#N/A") {
        lc = 0;
      }
      let totalLength = "";
      if (lc !== 0 && lcRight !== 0) {
        totalLength = 1000 + Number(lc) * 2;
      } else {
        totalLength = Number(hoseLength) + Number(lc) * 2;
      }
      // const totalLength = Number(hoseLength) + Number(lc) * 2;
      if (leftCode === rightCode) {
        return {
          ...state,
          hoseLength: lc ? Number(hoseLength) : 0
        };
      }
      return {
        ...state,
        rightFittingTypes: clone,
        rightCrimpSize: leftCrimpSize,
        overAllLength: lc ? totalLength : 0,
        showOal: lc ? true : false,
        // hoseLength: lc ? Number(hoseLength) : 0,
        hoseLength: lc ? 1000 : 0,
        rightFerrule: ferrule,
        rightSeatTypeImage: leftSeatTypeImage
      };
    }
    case SET_DISTINCT_MEASUREMENT: {
      const { payload } = action;
      const { data } = payload;
      // const dropDownObj = [];
      // _.forEach(data, (value, index) => {
      //   dropDownObj.push({
      //     key: index,
      //     text: value.name,
      //     value: value.value,
      //     image: { avatar: false, src: value.url }
      //   });
      // });
      return {
        ...state,
        distinctMeasurement: data,
        selectedSeatType: ""
      };
    }
    case CLEAR_FITTING_FILTER: {
      return {
        ...state,
        orientation: "",
        measurement: "",
        distinctMeasurement: [],
        autoCompleteResult: [],
        selectedSeatType: ""
      };
    }
    case ADD_ADDITIONAL_PRODUCT: {
      let { payload } = action;
      const { stockCode: productsStockCode } = payload;
      const { additionalProduct } = state;
      const index = _.findIndex(additionalProduct, {
        stockCode: productsStockCode
      });
      const product = additionalProduct[index];
      if (product) {
        product.quantity = product.quantity + 1;
        const newProductList = [...additionalProduct, ...product];
        return {
          ...state,
          additionalProduct: newProductList
        };
      }
      payload.quantity = 1;
      let allData = [...additionalProduct];
      allData.push(payload);
      return {
        ...state,
        additionalProduct: allData
      };
    }
    case REMOVE_ADDITIONAL_PRODUCT: {
      const { payload } = action;
      const { additionalProduct } = state;
      const products = [...additionalProduct];
      let index = _.findIndex(products, item => {
        return item.stockCode === payload;
      });
      if (index > -1) {
        products.splice(index, 1);
      }
      return {
        ...state,
        additionalProduct: products
      };
    }
    case SET_MAKE_AT_BOA: {
      return {
        ...state,
        makeAtBoa: action.payload
      };
    }
    case SET_CHARGES: {
      return {
        ...state,
        charges: action.payload
      };
    }
    case SET_HOSE_PRICE: {
      return {
        ...state,
        totalPrice: action.payload
      };
    }
    case SET_SAVE_HOSE_RESPONSE: {
      return {
        ...state,
        response: action.payload
      };
    }
    case SET_HOSE_DESCRIPTION: {
      return {
        ...state,
        hoseDescription: action.payload
      };
    }
    case CLEAR_ALL_VALUES: {
      return {
        ...state,
        hoseType: null,
        leftFittingTypes: {},
        rightFittingTypes: {},
        searchResult: {},
        autoCompleteResult: [],
        fittingId: "",
        hoseLength: 1000, //todo
        rightFittingAngle: 180,
        leftFittingAngle: 0,
        spiralGuard: {},
        addAdditionalProductModal: false,
        hoseDiameters: [],
        filteredHoses: {},
        orientation: "",
        measurement: "",
        threadType: "",
        additionalProduct: [],
        charges: {},
        totalPrice: 0,
        response: {},
        hoseDescription: "",
        leftCrimpSize: "",
        rightCrimpSize: "",
        overAllLength: 0, //todo
        fittingOrientations: [],
        leftFerrule: {},
        rightFerrule: {},
        leftSeatTypeImage: "",
        rightSeatTypeImage: "",
        editHose: false,
        assetId: "",
        model: "",
        subModal: "",
        position: "",
        createdBy: "",
        assetName: "",
        stockCode: "",
        flow: ""
      };
    }
    case SET_CRIMP_SIZE: {
      const { payload } = action;
      const { side, data } = payload;
      const { crimpSize } = data;
      if (side === "left") {
        return {
          ...state,
          leftCrimpSize: crimpSize ? crimpSize : null
        };
      } else {
        return {
          ...state,
          rightCrimpSize: crimpSize ? crimpSize : null
        };
      }
    }
    case SET_OVER_ALL_LENGTH: {
      const { payload } = action;
      const { fittingLength, overAll } = payload;
      //const defaultLength = Number(1000 + fittingLength);
      // if (overAll === 0) {
      //   return {
      //     ...state,
      //     overAllLength: defaultLength,
      //     hoseLength: 1000
      //   };
      // }
      return {
        ...state,
        overAllLength: overAll
      };
    }
    case SET_HOSE_DATA_FROM_DB: {
      const { payload } = action;
      const {
        hoseType,
        leftFittingTypes,
        rightFittingTypes,
        rightFittingAngle,
        leftFittingAngle,
        spiralGuard,
        additionalProduct,
        hoseDescription,
        leftCrimpSize,
        rightCrimpSize,
        overAllLength,
        hoseLength,
        leftFerrule,
        rightFerrule,
        assetId,
        model,
        subModal,
        position,
        createdBy,
        assetName,
        stockCode,
        quantity,
        leftSeatTypeImage,
        rightSeatTypeImage,
        uniqueCode,
        positionId
      } = payload;
      return {
        ...state,
        hoseType,
        leftFittingTypes,
        rightFittingTypes,
        rightFittingAngle,
        leftFittingAngle,
        spiralGuard,
        hoseDescription,
        leftCrimpSize,
        rightCrimpSize,
        overAllLength,
        hoseLength,
        leftFerrule,
        rightFerrule,
        additionalProduct,
        showOal: overAllLength ? true : false,
        editHose: true,
        assetId,
        model,
        subModal,
        position,
        createdBy,
        assetName,
        stockCode,
        hoseQuantity: quantity,
        leftSeatTypeImage,
        rightSeatTypeImage,
        uniqueCode,
        positionId
      };
    }
    case SET_FITTING_ANGLES: {
      return {
        ...state,
        fittingOrientations: action.payload
      };
    }
    case SET_SHOW_OAL_FLAG: {
      return {
        ...state,
        showOal: action.payload
      };
    }
    case CHANGE_ADDITIONAL_PRODUCT_QUANTITY: {
      const { payload } = action;
      const { stockCode: productStockCode, value } = payload;
      const { additionalProduct } = state;
      for (let i = 0; i < additionalProduct.length; i++) {
        const item = additionalProduct[i];
        const { stockCode } = item;
        if (stockCode === productStockCode) {
          additionalProduct[i].quantity = Number(value);
        }
      }
      return {
        ...state,
        additionalProduct: additionalProduct
      };
    }
    case SET_HOSE_QUANTITY: {
      const { payload } = action;
      return {
        ...state,
        hoseQuantity: payload
      };
    }
    case SET_LEFT_FERRULE: {
      return {
        ...state,
        leftFerrule: action.payload
      };
    }
    case SET_RIGHT_FERRULE: {
      return {
        ...state,
        rightFerrule: action.payload
      };
    }
    case SET_FERRULE_AUTO_COMPLETE_RESULT: {
      return {
        ...state,
        autoCompleteResult: action.payload
      };
    }
    case REMOVE_FERRULE: {
      const { payload } = action;
      if (payload === "left") {
        return {
          ...state,
          leftFerrule: {}
        };
      } else {
        return {
          ...state,
          rightFerrule: {}
        };
      }
    }
    case SET_LEFT_SEAT_TYPE_IMAGE: {
      return {
        ...state,
        leftSeatTypeImage: action.payload
      };
    }
    case SET_RIGHT_SEAT_TYPE_IMAGE: {
      return {
        ...state,
        rightSeatTypeImage: action.payload
      };
    }
    case SET_PATH_PARAMS: {
      return {
        ...state,
        ...action.payload
      };
    }
    case SET_THREAD_TYPE_1: {
      return {
        ...state,
        threadType1: action.payload
      };
    }
    case SET_THREAD_TYPE_2: {
      return {
        ...state,
        threadType2: action.payload
      };
    }
    case SET_THREAD_SIZE_1: {
      return {
        ...state,
        threadSize1: action.payload
      };
    }
    case SET_THREAD_SIZE_2: {
      return {
        ...state,
        threadSize2: action.payload
      };
    }
    case SET_FLOW_FOR_HOSEMAKER: {
      return {
        ...state,
        flow: action.payload
      };
    }
    case SAVE_HOSE_RECIPE: {
      return {
        ...state,
        saveHoseRecipe: action.payload
      };
    }
    default:
      return state;
  }
};

export const setHoseType = value => {
  return dispatch => {
    dispatch({ type: SET_HOSE_TYPE, payload: value });
  };
};

export const setLeftFittingTypes = value => {
  return async dispatch => {
    try {
      // const { stockCode } = value;
      // const result = await apiGetProductDetails(stockCode);
      dispatch({ type: SET_LEFT_FITTING_TYPES, payload: value });
    } catch (e) {
      dispatch({ type: SET_LEFT_FITTING_TYPES, payload: e });
    }
  };
};

export const actionSetFittingByFilterCall = (value, side) => {
  return dispatch => {
    if (side === "left") {
      dispatch({ type: SET_LEFT_FITTING_TYPES, payload: value });
    } else {
      dispatch({ type: SET_RIGHT_FITTING_TYPES, payload: value });
    }
  };
};

export const setRightFittingTypes = value => {
  return async dispatch => {
    try {
      // const { stockCode } = value;
      // const result = await apiGetProductDetails(stockCode);
      dispatch({ type: SET_RIGHT_FITTING_TYPES, payload: value });
    } catch (e) {
      dispatch({ type: SET_RIGHT_FITTING_TYPES, payload: e });
    }
  };
};

export const actionSetHoseCategories = type => {
  return async dispatch => {
    try {
      const result = await apiHoseCategories(type);
      dispatch({ type: SET_HOSE_CATEGORIES, payload: result });
    } catch (e) {
      dispatch({ type: SET_HOSE_CATEGORIES, payload: e });
    }
  };
};

export const actionResetHoseCategories = () => {
  return dispatch => {
    dispatch({ type: REMOVE_HOSE_CATEGORIES });
  };
};

export const actionSearchHoseProducts = (searchTerm, activePage) => {
  return async dispatch => {
    try {
      const result = await apiHoseSearchByAutoCompleteValue(
        searchTerm,
        activePage
      );
      dispatch({ type: SET_SEARCH_RESULT, payload: result.data });
      return result;
    } catch (e) {
      dispatch({ type: SET_SEARCH_RESULT, payload: e.msg });
    }
  };
};

export const actionHoseAutoComplete = searchTerm => {
  return async dispatch => {
    try {
      const result = await apiHoseAutoComplete(searchTerm);
      dispatch({ type: SET_AUTO_COMPLETE, payload: result.data.result });
    } catch (e) {
      dispatch({ type: SET_AUTO_COMPLETE, payload: e.msg });
    }
  };
};

export const actionFittingAutoComplete = (searchTerm, dashSize, stockCode) => {
  return async dispatch => {
    try {
      const result = await apiAutocompleteFittings(
        searchTerm,
        dashSize,
        stockCode
      );
      dispatch({ type: SET_AUTO_COMPLETE, payload: result.data.result });
    } catch (e) {
      dispatch({ type: SET_AUTO_COMPLETE, payload: e.msg });
    }
  };
};

export const actionResetSearchResult = () => {
  return dispatch => {
    dispatch({ type: RESET_SEARCH_RESULT, payload: [] });
  };
};

export const actionResetHose = () => {
  return dispatch => {
    dispatch({ type: RESET_HOSE, payload: {} });
  };
};

export const actionSetFittingId = value => {
  return dispatch => {
    dispatch({ type: SET_FITTING_ID, payload: value });
  };
};

export const actionClearLeftFitting = () => {
  return dispatch => {
    return dispatch({ type: CLEAR_LEFT_FITTING, payload: {} });
  };
};

export const actionClearRightFitting = () => {
  return dispatch => {
    return dispatch({ type: CLEAR_RIGHT_FITTING, payload: {} });
  };
};

export const actionChangeHoseLength = value => {
  return dispatch => {
    dispatch({ type: SET_LENGTH, payload: value });
  };
};

export const actionChangeHoseLengthNotOVAL = value => {
  return dispatch => {
    dispatch({ type: SET_LENGTH_NOT_OAL, payload: value });
  };
};

export const actionChangeRightFittingAngle = value => {
  return dispatch => {
    dispatch({ type: SET_RIGHT_ANGLE, payload: value });
  };
};

export const actionChangeLeftFittingAngle = value => {
  return dispatch => {
    dispatch({ type: SET_LEFT_ANGLE, payload: value });
  };
};

export const actionGetAllSpiralGuards = () => {
  return async dispatch => {
    try {
      const result = await apiGetAllSpiralGuards();
      dispatch({ type: SET_ALL_SPIRAL_GUARDS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_ALL_SPIRAL_GUARDS, payload: e });
    }
  };
};

export const actionSetSpiralGuard = () => {
  return dispatch => {
    dispatch({ type: SET_SPIRAL_GUARD, payload: {} });
  };
};

export const actionRemoveSpiralGuard = () => {
  return dispatch => {
    dispatch({ type: REMOVE_SPIRAL_GUARD, payload: {} });
  };
};

export const actionSetDistinctMeasurement = (
  dashSize,
  orientation,
  stockCode
) => {
  return async dispatch => {
    try {
      const result = await apiGetFittingMeasurementByOrientation(
        dashSize,
        orientation,
        stockCode
      );
      dispatch({ type: SET_DISTINCT_MEASUREMENT, payload: result });
    } catch (e) {
      dispatch({ type: SET_DISTINCT_MEASUREMENT, payload: e });
    }
  };
};

export const actionSetAdditionalProductsModalValue = value => {
  return dispatch => {
    dispatch({ type: SET_ADDITIONAL_PRODUCT_MODAL_VALUE, payload: value });
  };
};

export const actionSearchProducts = (
  searchTerm,
  level,
  activePage,
  threadType1,
  threadSize1,
  threadType2,
  threadSize2
) => {
  return async dispatch => {
    try {
      const result = await apiAdditionalProductSearch(
        searchTerm,
        level,
        activePage,
        threadType1,
        threadSize1,
        threadType2,
        threadSize2
      );
      dispatch({ type: SET_ADDITIONAL_SEARCH_RESULT, payload: result.data });
      return result;
    } catch (e) {
      dispatch({ type: SET_ADDITIONAL_SEARCH_RESULT, payload: e.msg });
    }
  };
};

export const actionSetHoseDiameters = group => {
  return async dispatch => {
    try {
      const result = await apiGetHoseDiameters(group);
      dispatch({ type: SET_HOSE_DIAMETERS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_HOSE_DIAMETERS, payload: [] });
    }
  };
};

export const actionSearchHoseByFilters = (group, diameter) => {
  return async dispatch => {
    try {
      const result = await apiSearchHoseWithFilter(group, diameter);
      dispatch({ type: SET_FILTERED_HOSE_RESULT, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_FILTERED_HOSE_RESULT, payload: e });
    }
  };
};

export const actionSearchFittingByFilter = (
  dashSize,
  orientation,
  measurement,
  hoseStockCode,
  threadType
) => {
  return async dispatch => {
    try {
      const result = await apiSearchFittingForHose(
        dashSize,
        orientation,
        measurement,
        hoseStockCode,
        threadType
      );
      dispatch({ type: SET_SEARCH_RESULT, payload: result.data });
      return result;
    } catch (e) {
      dispatch({ type: SET_SEARCH_RESULT, payload: e });
    }
  };
};

export const actionSetOrientation = value => {
  return dispatch => {
    dispatch({ type: SET_ORIENTATION, payload: value });
  };
};

export const actionSetMeasurement = value => {
  return dispatch => {
    dispatch({ type: SET_MEASUREMENT, payload: value });
  };
};

export const actionCloneToRight = () => {
  return dispatch => {
    dispatch({ type: CLONE_TO_RIGHT });
  };
};

export const actionClearFittingFilter = () => {
  return dispatch => {
    dispatch({ type: CLEAR_FITTING_FILTER, payload: "" });
  };
};

export const actionAdditionalProductAutoComplete = (searchTerm, category) => {
  return async dispatch => {
    try {
      const result = await apiAdditionalProductAutoComplete(
        searchTerm,
        category
      );
      dispatch({ type: SET_AUTO_COMPLETE, payload: result.data.result });
    } catch (e) {
      dispatch({ type: SET_AUTO_COMPLETE, payload: e });
    }
  };
};

export const actionAddAdditionalProducts = value => {
  return dispatch => {
    dispatch({ type: ADD_ADDITIONAL_PRODUCT, payload: value });
  };
};

export const actionRemoveAdditionalProduct = stockCode => {
  return dispatch => {
    dispatch({ type: REMOVE_ADDITIONAL_PRODUCT, payload: stockCode });
  };
};

// export const actionSetMakeAtBoa = value => {
//   return dispatch => {
//     dispatch({ type: SET_MAKE_AT_BOA, payload: value });
//   };
// };

export const actionSetCharges = (stockCode, spiral) => {
  return async dispatch => {
    try {
      const result = await apiGetMakeAtBoaCharges(stockCode, spiral);
      dispatch({ type: SET_CHARGES, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_CHARGES, payload: e });
    }
  };
};

export const actionSetHosePrice = value => {
  return dispatch => {
    dispatch({ type: SET_HOSE_PRICE, payload: value });
  };
};

export const actionSaveHoseToDB = hoseInfo => {
  return async dispatch => {
    try {
      const result = await apiSaveHose(hoseInfo);
      dispatch({ type: SET_SAVE_HOSE_RESPONSE, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_SAVE_HOSE_RESPONSE, payload: e });
    }
  };
};

export const actionHoseDescription = value => {
  return dispatch => {
    dispatch({ type: SET_HOSE_DESCRIPTION, payload: value });
  };
};

export const actionClearHoseData = () => {
  return dispatch => {
    dispatch({ type: CLEAR_ALL_VALUES, payload: {} });
  };
};

export const actionSetCrimpSize = (
  side,
  dashSize,
  productFamily,
  tertiaryGroup,
  ferruleProductFamily
) => {
  return async dispatch => {
    const result = await apiGetCrimpSize(
      dashSize,
      productFamily,
      tertiaryGroup,
      ferruleProductFamily
    );
    const { data } = result;
    dispatch({ type: SET_CRIMP_SIZE, payload: { side, data } });
  };
};

export const actionChangeOverAllLength = (value, totalFittingLength) => {
  return dispatch => {
    dispatch({
      type: SET_OVER_ALL_LENGTH,
      payload: { overAll: value, fittingLength: totalFittingLength }
    });
  };
};

export const actionGetHoseDataFromServer = hoseId => {
  return async dispatch => {
    try {
      const hoseResult = await apiGetHoseData(hoseId);
      dispatch({ type: SET_HOSE_DATA_FROM_DB, payload: hoseResult.data });
      return hoseResult.data;
    } catch (e) {
      dispatch({ type: SET_HOSE_DATA_FROM_DB, payload: e });
    }
  };
};

export const actionGetFittingOrientations = (dashSize, stockCode) => {
  return async dispatch => {
    try {
      const result = await apiGetFittingOrientationsByDashSize(
        dashSize,
        stockCode
      );
      dispatch({ type: SET_FITTING_ANGLES, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_FITTING_ANGLES, payload: e });
    }
  };
};

export const actionSetOalFlag = value => {
  return dispatch => {
    dispatch({ type: SET_SHOW_OAL_FLAG, payload: value });
  };
};

export const actionChangeAdditionalProductQuantity = (
  value,
  stockCode,
  pack
) => {
  return dispatch => {
    dispatch({
      type: CHANGE_ADDITIONAL_PRODUCT_QUANTITY,
      payload: { value, stockCode, pack }
    });
  };
};

export const actionSetHoseQuantity = value => {
  return dispatch => {
    dispatch({ type: SET_HOSE_QUANTITY, payload: value });
  };
};

export const actionGetFerruleLeft = stockCode => {
  return async dispatch => {
    try {
      const result = await apiGetHoseFerrule(stockCode);
      dispatch({ type: SET_LEFT_FERRULE, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_LEFT_FERRULE, payload: e });
    }
  };
};

export const actionGetFerruleRight = stockCode => {
  return async dispatch => {
    try {
      const result = await apiGetHoseFerrule(stockCode);
      dispatch({ type: SET_RIGHT_FERRULE, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_RIGHT_FERRULE, payload: e });
    }
  };
};

export const actionFerruleAutoCompleteCall = value => {
  return async dispatch => {
    try {
      const result = await apiFerruleSearchAutoComplete(value);
      dispatch({
        type: SET_FERRULE_AUTO_COMPLETE_RESULT,
        payload: result.data.result
      });
    } catch (e) {
      dispatch({ type: SET_FERRULE_AUTO_COMPLETE_RESULT, payload: e });
    }
  };
};

export const actionChangeFerrule = (value, type) => {
  return async dispatch => {
    try {
      const { stockCode } = value;
      const result = await apiGetProductDetails(stockCode);
      if (type === "left") {
        dispatch({ type: SET_LEFT_FERRULE, payload: result.data });
      } else {
        dispatch({ type: SET_RIGHT_FERRULE, payload: result.data });
      }
    } catch (e) {
      dispatch({ type: SET_LEFT_FERRULE, payload: e });
    }
  };
};

export const actionRemoveFerrule = type => {
  return dispatch => {
    dispatch({ type: REMOVE_FERRULE, payload: type });
  };
};

export const actionSetSeatTypeImage = (type, imageLink) => {
  return dispatch => {
    if (type === "left") {
      dispatch({ type: SET_LEFT_SEAT_TYPE_IMAGE, payload: imageLink });
    } else {
      dispatch({ type: SET_RIGHT_SEAT_TYPE_IMAGE, payload: imageLink });
    }
  };
};

export const actionSetPathParams = params => {
  return dispatch => {
    dispatch({ type: SET_PATH_PARAMS, payload: params });
  };
};

export const actionSetThreadType1 = value => {
  return dispatch => {
    dispatch({ type: SET_THREAD_TYPE_1, payload: value });
  };
};

export const actionSetThreadType2 = value => {
  return dispatch => {
    dispatch({ type: SET_THREAD_TYPE_2, payload: value });
  };
};

export const actionSetThreadSize1 = value => {
  return dispatch => {
    dispatch({ type: SET_THREAD_SIZE_1, payload: value });
  };
};

export const actionSetThreadSize2 = value => {
  return dispatch => {
    dispatch({ type: SET_THREAD_SIZE_2, payload: value });
  };
};

export const actionSetFlowForHoseMaker = value => {
  return dispatch => {
    dispatch({ type: SET_FLOW_FOR_HOSEMAKER, payload: value });
  };
};

export const actionSaveHoseRecipe = data => {
  return async dispatch => {
    try {
      const result = await apiSaveHoseRecipe(data);
      dispatch({ type: SAVE_HOSE_RECIPE, payload: result.data });
    } catch (e) {
      dispatch({ type: SAVE_HOSE_RECIPE, payload: e });
    }
  };
};
