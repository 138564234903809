import { apiGetUserInfo } from "../../Auth/auth.api";
import {
  apiCreateAccountDeliveryAddress,
  apiDeleteDeliveryAddress,
  apiUpdateDeliveryAddress,
  apiCreateContact,
  apiUpdateContact,
  apiSetDefaultDeliveryAddress,
} from "../DeliveryAddress/deliveryAddress.api";
import { actions } from "react-redux-form";

export const SET_DELIVERY_ADDRESS = "SET_DELIVERY_ADDRESS";
export const UPDATE_DELIVERY_ADDRESS = "UPDATE_DELIVERY_ADDRESS";
export const CREATE_RELATED_CONTACT = "CREATE_RELATED_CONTACT";
export const SET_CONTACT_EDIT_FLAG = "SET_PROFILE_EDIT_FLAG";
const SET_ACCOUNT_INFO_DATA = "SET_ACCOUNT_INFO_DATA";

const initialState = {
  response: {},
  deliveryAddress: [],
  editContactFlag: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveryAddress: action.payload,
      };
    case UPDATE_DELIVERY_ADDRESS:
      // this.actionGetAccountDetails();
      return {
        ...state,
        response: action.payload,
      };
    case CREATE_RELATED_CONTACT:
      return {
        ...state,
        response: action.payload,
      };
    case SET_CONTACT_EDIT_FLAG:
      return {
        ...state,
        editContactFlag: action.payload,
      };
    case SET_ACCOUNT_INFO_DATA:
      return {
        ...state,
        accountInfo: action.payload,
      };
    default:
      return state;
  }
};

export const actionGetAccountDetails2 = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetUserInfo();
      dispatch({
        type: SET_ACCOUNT_INFO_DATA,
        payload: { loading: false, ...result },
      });

      dispatch({
        type: SET_DELIVERY_ADDRESS,
        payload: result.contactsAndAddresses,
      });
    } catch (e) {
      dispatch({ type: SET_DELIVERY_ADDRESS, payload: e });
    }
  };
};

export const actionUpdateDeliveryAddress = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiSetDefaultDeliveryAddress(data);
      dispatch({ type: UPDATE_DELIVERY_ADDRESS, payload: result });
    } catch (e) {
      dispatch({ type: UPDATE_DELIVERY_ADDRESS, payload: e });
    }
  };
};

export const actionAddNewDeliveryAddress = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiCreateAccountDeliveryAddress(data);
      dispatch({ type: UPDATE_DELIVERY_ADDRESS, payload: result });
      return result;
    } catch (e) {
      dispatch({ type: UPDATE_DELIVERY_ADDRESS, payload: e });
    }
  };
};

export const actionDeleteDeliveryAddress = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiDeleteDeliveryAddress(data);
      dispatch({ type: UPDATE_DELIVERY_ADDRESS, payload: result });
      return result;
    } catch (e) {
      dispatch({ type: UPDATE_DELIVERY_ADDRESS, payload: e });
      return e;
    }
  };
};

export const actionSetFormValue = (data) => {
  return (dispatch) => {
    dispatch(actions.change("forms.createContactForm", data));
  };
};

export const actionResetState = (formName) => {
  return (dispatch) => {
    dispatch(actions.reset(formName));
    // dispatch(
    //   actions.change("forms.createContactForm", { country: "", state: "" })
    // );
  };
};

export const actionCreateContact = (newContact) => {
  return async (dispatch) => {
    try {
      const result = await apiCreateContact(newContact);
      dispatch({
        type: CREATE_RELATED_CONTACT,
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: CREATE_RELATED_CONTACT,
        payload: e.response,
      });
    }
  };
};

export const actionUpdateContact = (newContact) => {
  return async (dispatch) => {
    try {
      const result = await apiUpdateContact(newContact);
      dispatch({
        type: CREATE_RELATED_CONTACT,
        payload: result,
      });
    } catch (e) {
      dispatch({
        type: CREATE_RELATED_CONTACT,
        payload: e.response,
      });
    }
  };
};

export const actionSetEditContactFlag = (flag) => {
  return async (dispatch) => {
    dispatch({ type: SET_CONTACT_EDIT_FLAG, payload: flag });
  };
};

export const actionSetContactInfoForm = (form) => {
  return (dispatch) => {
    dispatch(actions.change("forms.createContactForm", form));
  };
};
