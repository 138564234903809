import axios from "axios";

export const apiGetMyResources = async () => {
  return axios.get(`/api/MyResources/get-resources`).then((result) => {
    if (result && result.data) {
      return result.data;
    }
    return false;
  });
};
