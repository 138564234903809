import React, { useState } from "react";
import { Button, Form, TextArea, Modal, Input } from "semantic-ui-react";
import { toast } from "react-toastify";
import { HISpostData } from "./hisAPIprovider";

const NewUserForm = ({ closeForm }) => {
  const [formData, setFormData] = useState({ email: "", active: false });
  const [isEmpty, setIsEmpty] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value.trim() !== "") {
      setIsEmpty(false);
    }
  };

  const onAddNewUser = async () => {
    if (formData.email.trim() === "") {
      setIsEmpty(true);
    } else {
      const response = await HISpostData("/his/new_user", formData);
      if (!response.ok) {
        toast.error("Failed to add new user");
        return;
      }
      closeForm();
      toast.success("New user added successfully");
    }
  };
  return (
    <Modal size="small" open={true} onClose={closeForm}>
      <Modal.Header>ADD NEW HIS USER</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field error={isEmpty}>
            <Input
              name="email"
              placeholder="Email"
              onChange={handleChange}
              style={{
                borderColor: isEmpty ? "red" : undefined,
              }}
            />
          </Form.Field>

          <div className="button-row">
            <Button
              style={{
                backgroundColor: "#FF1414",
                color: "#fff",
                flex: 1,
                marginRight: "10px",
              }}
              onClick={closeForm}
            >
              CLOSE
            </Button>
            <Button
              style={{
                backgroundColor: "rgb(102, 204, 35)",
                color: "#fff",
                flex: 1,
              }}
              onClick={onAddNewUser}
            >
              ADD USER
            </Button>
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default NewUserForm;
