import React, { useEffect, useState } from "react";
import { Grid, Button, Icon, Loader, Modal, Table } from "semantic-ui-react";
import MetaData from "../../../components/MetaData";
import _debounce from "lodash/debounce";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import {
  apiGetMinMaxReport,
  apiMinMaxReportAddToCart,
} from "./minMaxReport.api";

const MinMaxReport = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productList, setProductList] = useState([]);
  const [payloadData, setPayloadData] = useState([]);
  const [addToCartOpen, setAddToCartOpen] = useState(false);
  const [hasQty, setHasQty] = useState(false);

  useEffect(() => {
    setHasQty(false);
    const delayDebounceFn = setTimeout(() => {
      onGetMinMaxReport();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  const onGetMinMaxReport = async () => {
    setIsLoading(true);
    const result = await apiGetMinMaxReport(props.stockLocationId);
    if (result) {
      result?.map((product) => {
        if (product.addToCartQty > 0) setHasQty(true);
      });
      setProductList(result);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onImportBulkStockAdjustment = async () => {
    setIsLoading(true);
    try {
      const result = await apiMinMaxReportAddToCart(
        props.stockLocationId,
        productList
      );
      if (result) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Products Added to Cart Successfully
          </div>
        );
        setTimeout(() => {
          setIsLoading(false);
          props.setMinMaxReportOpen(false);
        }, 1000);
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const headerStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 1,
  };

  const headerStyleQty = {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 1,
    color: "#66CC23",
  };

  return (
    <React.Fragment>
      <MetaData title="Inventory" />
      <Modal size="mini" open={addToCartOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure <br /> you want to add to cart all products?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setAddToCartOpen(false)}>NO</Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onImportBulkStockAdjustment()}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      <div style={{ position: "absolute", top: 250, left: 625 }}>
        {isLoading ? (
          <Loader
            active
            className="workaround"
            size="large"
            inline="centered"
          />
        ) : null}
      </div>
      <div disabled={isLoading} style={{ opacity: isLoading ? 0.3 : 1 }}>
        <Grid style={{ margin: 20, marginTop: 10, marginBottom: 0 }}>
          <Grid.Column
            width={16}
            style={{
              minHeight: 400,
              maxHeight: 400,
              overflowY: "auto",
              paddingTop: 0,
            }}
          >
            <Table sortable celled fixed>
              <Table.Header style={{ fontSize: 15 }}>
                <Table.Row>
                  <Table.HeaderCell style={headerStyle} textAlign="center">
                    SKU
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    style={headerStyle}
                    textAlign="center"
                    width={5}
                  >
                    Description
                  </Table.HeaderCell>
                  <Table.HeaderCell style={headerStyle} textAlign="center">
                    UoM
                  </Table.HeaderCell>
                  <Table.HeaderCell style={headerStyle} textAlign="center">
                    Max
                  </Table.HeaderCell>
                  <Table.HeaderCell style={headerStyle} textAlign="center">
                    Balance
                  </Table.HeaderCell>
                  <Table.HeaderCell style={headerStyle} textAlign="center">
                    In Cart
                  </Table.HeaderCell>
                  <Table.HeaderCell style={headerStyleQty} textAlign="center">
                    Add to Cart Qty
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body style={{ fontSize: 13 }}>
                {productList.map((product) => (
                  <Table.Row key={product["Product ID"]}>
                    <Table.Cell textAlign="center">
                      {product.barCode}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {product.productName}
                    </Table.Cell>
                    <Table.Cell textAlign="center">{product.uom}</Table.Cell>
                    <Table.Cell textAlign="center">{product.max}</Table.Cell>
                    <Table.Cell textAlign="center">
                      {product.balance}
                    </Table.Cell>
                    <Table.Cell textAlign="center">
                      {product.inCartQty}
                    </Table.Cell>
                    <Table.Cell
                      textAlign="center"
                      style={{ fontSize: 16, fontWeight: "bolder" }}
                    >
                      {product.addToCartQty}
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {productList.length === 0 && (
              <div
                style={{
                  paddingTop: 120,
                  width: "100%",
                  textAlign: "center",
                  fontSize: 22,
                  color: "#AFABAB",
                }}
              >
                <span>
                  No products found in {props.stockLocationName} below the set
                  minimum level.
                </span>
              </div>
            )}
          </Grid.Column>
          <Grid.Column
            width={7}
            style={{ paddingRight: 5, paddingTop: 35 }}
          ></Grid.Column>
          <Grid.Column
            width={9}
            style={{ paddingLeft: 5, textAlign: "right", paddingTop: 35 }}
          >
            <Button
              disabled={productList.length === 0 || isLoading || !hasQty}
              size="small"
              style={{
                backgroundColor: "rgb(102, 204, 35)",
                color: "#fff",
                marginLeft: "auto",
              }}
              onClick={() => setAddToCartOpen(true)}
            >
              <Icon name="plus" /> Add to Cart
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default MinMaxReport;
