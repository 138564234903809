import React from "react";


const OverAllLengthHelpModal = () => {
  return (
    <a href="https://s3-ap-southeast-2.amazonaws.com/portal-static-images/OAL_vs_HL.jpg"
      rel="noopener noreferrer"
      target="_blank"> Help ?</a>
  );
};

export default OverAllLengthHelpModal;
