import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Header, Button, Dropdown, Message, Image } from "semantic-ui-react";
import * as hoseMakerReducer from "./../../hoseMaker.reducer";
import HoseDetailsCard from "../HoseSearch/HoseDetailsCard";
import HoseDetailsLoaderCard from "../HoseSearch/HoseDetailsLoaderCard";
import { FITTING_TERTIARY_GROUP_DISPLAY_NAME_FOR_FERRULE_CONDITION } from "./../hoseConstants";
import _ from "lodash";
import { showErrorToast } from "../../../../utils/toastUtils";
class SearchFitterByFilter extends React.Component {
  constructor() {
    super();
    this.state = {
      angle: "",
      isLoading: false,
      searchMessage: ""
    };
  }

  componentWillMount = () => {
    this.getOrientation();
  };

  getOrientation = async () => {
    const { hoseType } = this.props;
    const { productDimensions, stockCode } = hoseType;
    const { dashSize1 } = productDimensions;
    this.setState({ isLoading: true });
    await this.props.actionGetFittingOrientations(dashSize1, stockCode);
    this.setState({ isLoading: false });
  };

  componentDidMount = () => {
    this.resetFilterValues();
  };

  resetFilterValues = () => {
    this.props.actionSetOrientation();
    this.props.actionSetMeasurement({});
  };

  orientationSelectHandler = async (event, data) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    const { value } = data;
    await this.props.actionSetOrientation(value);
    const { hoseType } = this.props;
    const { productDimensions, stockCode } = hoseType;
    const { dashSize1 } = productDimensions;
    await this.props.actionSetDistinctMeasurement(dashSize1, value, stockCode);
    this.setState({ isLoading: false });
  };
  measurementSelectHandler = async (event, data) => {
    event.preventDefault();
    const { side } = this.props;
    this.setState({ isLoading: true });
    const { value, options } = data;
    let item = _.filter(options, (m) => { return m.value === value });
    item = _.head(item);
    const { text } = item;
    const { props: itemProps } = text;
    let { children = [] } = itemProps;
    children = _.head(children);
    const { props: childrenProps = {} } = children;
    const { src } = childrenProps; 
    this.props.actionSetSeatTypeImage(side, src);
    const splitResult = value.split("_");
    const measurement = splitResult[0];
    const threadType = splitResult[1];
    await this.props.actionSetMeasurement({ measurement, threadType });
    this.searchFittings();
    this.setState({ isLoading: false });
  };

  searchFittings = async () => {
    const { hoseType, orientation, measurement, threadType } = this.props;
    this.setState({ isLoading: true, searchMessage: "" });
    const { productDimensions, stockCode } = hoseType;
    const { dashSize1 } = productDimensions;
   
    const result = await this.props.actionSearchFittingByFilter(
      dashSize1,
      orientation,
      measurement,
      stockCode,
      threadType
    );
    const { data = {} } = result;
    if (!data.length) {
      this.setState({ searchMessage: "Fitting not found" });
    }
    this.setState({ isLoading: false });
  };

  fittingHandler = async (e, fitting) => {
    e.preventDefault();
    const { side } = this.props;
    const { hoseType } = this.props;
    const { productDimensions, productFamily, stockCode } = hoseType;
    const { dashSize1 } = productDimensions;
    const { tertiaryGrp, pricingPolicy } = fitting;
    const { price } = pricingPolicy;
    if (!price) {
      showErrorToast("Can not add this fitting, please contact boa for this fitting");
      return;
    }
    this.setState({ isLoading: true });
    await this.props.actionSetFittingByFilterCall(fitting, side);
    
    if (side === "left") {
      const { leftFittingTypes } = this.props;
      const { tertiaryGrpDisplayName } = leftFittingTypes;
      if (
        tertiaryGrpDisplayName ===
        FITTING_TERTIARY_GROUP_DISPLAY_NAME_FOR_FERRULE_CONDITION
      ) {
        await this.props.actionGetFerruleLeft(stockCode);
      }
      const { leftFerrule = {} } = this.props;
      const { productFamily: ferruleProductFamily = ""} = leftFerrule;
      await this.props.actionSetCrimpSize(
        side,
        dashSize1,
        productFamily,
        tertiaryGrp,
        ferruleProductFamily
      );
    } else {
      const { rightFittingTypes } = this.props;
      const { tertiaryGrpDisplayName } = rightFittingTypes;
      if (
        tertiaryGrpDisplayName ===
        FITTING_TERTIARY_GROUP_DISPLAY_NAME_FOR_FERRULE_CONDITION
      ) {
        await this.props.actionGetFerruleRight(stockCode);
      }
      const { rightFerrule = {} } = this.props;
      const { productFamily: ferruleProductFamily = ""} = rightFerrule;
      await this.props.actionSetCrimpSize(
        side,
        dashSize1,
        productFamily,
        tertiaryGrp,
        ferruleProductFamily
      );
    }
    this.props.closeModal();
    this.setState({ isLoading: false });
  };

  clearFitterFilter = () => {
    this.props.actionSetOrientation();
    this.props.actionClearFittingFilter();
  };

  render() {
    const {
      searchResult,
      distinctMeasurement,
      fittingOrientations
    } = this.props;
    const filteredMeasurements = [];
    if (!_.isEmpty(distinctMeasurement)) {
      _.forEach(distinctMeasurement, (value, index) => {
        filteredMeasurements.push({
          key: index,
          text: <div><Image style={{width: "75px"}}src={value.url} />{value.name}</div>,
          value: value.value
        });
      });
    }

    const { isLoading, searchMessage } = this.state;
    const angleJSX = (
      <Grid>
        <Grid.Column width={8}>
          <Header as="h4">1. Select Angle</Header>
          <Dropdown
            style={{ width: "30px" }}
            selection
            placeHolder="Select thread type"
            onChange={this.orientationSelectHandler}
            options={fittingOrientations}
          />
        </Grid.Column>
      </Grid>
    );

    const measurementJSX = (
      <Grid>
        <Grid.Column width={16}>
          <Header as="h4">2. Select Measurement</Header>
          <Dropdown
            style={{ width: "100%" }}
            selection
            placeHolder="Select measurement"
            onChange={this.measurementSelectHandler}
            options={filteredMeasurements}
          />
          <div>
            <i> (OD of Male Thread or ID of Female Thread in mm)</i>
          </div>
        </Grid.Column>
      </Grid>
    );

    return (
      <React.Fragment>
            <Button onClick={this.clearFitterFilter} floated={"right"} size="tiny"> Reset </Button>
        <Grid columns="equal">
          <Grid.Column>{angleJSX}</Grid.Column>
          <Grid.Column>{measurementJSX}</Grid.Column>
        </Grid>  
        <Grid>
          <Grid.Column width={16}>
            {searchResult.length > 0 && (
              <HoseDetailsCard
                hoseResult={searchResult}
                onBoarSelect={this.fittingHandler}
              />
            )}
            {searchMessage && <Message>{searchMessage}</Message>}
          </Grid.Column>
          <Grid.Column width={16}>
            {isLoading && <HoseDetailsLoaderCard />}
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  orientation: state.hoseMaker.orientation,
  measurement: state.hoseMaker.measurement,
  hoseType: state.hoseMaker.hoseType,
  searchResult: state.hoseMaker.searchResult,
  distinctMeasurement: state.hoseMaker.distinctMeasurement,
  selectedSeatType: state.hoseMaker.selectedSeatType,
  fittingOrientations: state.hoseMaker.fittingOrientations,
  leftFittingTypes: state.hoseMaker.leftFittingTypes,
  rightFittingTypes: state.hoseMaker.rightFittingTypes,
  leftFerrule: state.hoseMaker.leftFerrule,
  rightFerrule: state.hoseMaker.rightFerrule,
  threadType: state.hoseMaker.threadType
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(hoseMakerReducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFitterByFilter);
