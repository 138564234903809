import React from "react";
import { Grid, Image } from "semantic-ui-react";
import productDefaultImage from "../../assets/images/product_default.png";
import "./narrowFilters.css";

const NarrowFilter = ({ narrowFilters = [], onClickHandler }) => {
  const handleClick = (filter) => {
    const {
      secondaryGrpDisplayName = null,
      tertiaryGrpDisplayName = null,
      productFamilyDisplayName = null,
    } = filter;

    return onClickHandler(filter);

    // if (!productFamilyDisplayName && !tertiaryGrpDisplayName) {
    //   return onClickHandler(secondaryGrpDisplayName);
    // }

    // if (!productFamilyDisplayName) {
    //   return onClickHandler(
    //     secondaryGrpDisplayName + "," + tertiaryGrpDisplayName
    //   );
    // }

    // if (productFamilyDisplayName) {
    //   return onClickHandler(productFamilyDisplayName);
    // }
  };

  return (
    <div className="filter-box">
      <h4> Narrow your search</h4>
      <Grid columns="equal">
        {narrowFilters.map((filter, key) => {
          const {
            image128: stockImage1,
            // secondaryGrpDisplayName = null,
            // productFamilyDisplayName = null,
            // tertiaryGrpDisplayName = null,
            module: title,
            value,
          } = filter;

          // let title = productFamilyDisplayName;

          // if (!title) {
          //   title = tertiaryGrpDisplayName;
          // }

          // if (!title) {
          //   title = secondaryGrpDisplayName;
          // }

          if (!title || title === "-") {
            return null;
          }

          const redirectClickHandler = (e, value) => {
            e.preventDefault();
            if (e.ctrlKey) {
              e.stopPropagation();
              window.open(`/search?filter=${value}&value=`);
              return;
            }
          };

          return (
            <Grid.Column
              width={5}
              computer={5}
              mobile={16}
              key={title}
              className="filter-content"
              onClick={() => handleClick(filter)}
            >
              <Grid
                onClick={(event) => redirectClickHandler(event, value)}
                href={`/search?filter=${title}&value=`}
              >
                <Grid.Column width={5} className="filter-content-image">
                  <Image
                    src={
                      stockImage1
                        ? `data:image/jpeg;base64,${stockImage1}`
                        : productDefaultImage
                    }
                  />
                </Grid.Column>
                <Grid.Column width={11}>
                  {<span style={{ fontSize: "1.3em" }}>{`${title}`}</span>}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          );
        })}
      </Grid>
    </div>
  );
};

export default NarrowFilter;
