import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Loader from "../Loader";
import { getAccountTax } from "../../containers/OrderConfirmation/order.utils";
import { DEFAULT_TAX, AU_TAX } from "../../constants/appConstants";
import { Grid } from "semantic-ui-react";

const TotalPrice = ({
  cart,
  cartCount,
  isLoading = false,
  account,
  overallDiscount,
  subtotal,
}) => {
  let TAX = DEFAULT_TAX;
  let zeroPrice = false;
  if (account) {
    const { overrideGSTRate } = account;
    TAX = getAccountTax(overrideGSTRate);
  }

  let total = 0;
  let tax_total = 0;
  let currencyValue = "";
  let totalSave = 0;
  let overallDiscountCalculation = 0;
  if (_.isEmpty(cart)) {
    return <div> </div>;
  }

  if (!_.isEmpty(cart)) {
    _.forEach(cart, (item) => {
      const { pricingPolicy } = item;
      if (pricingPolicy) {
        const { countryCode, totalPrice, totalDiscount } = pricingPolicy;
        total = total + totalPrice;
        // totalSave = totalSave + totalDiscount + overallDiscountCalculation;
      }

      const userCountryCode = localStorage.getItem("userCountryCode");
      currencyValue = userCountryCode;
      TAX = currencyValue == "AU" ? AU_TAX : TAX;

      if (item.buyPrice === 0 || item.retailPrice === 0) zeroPrice = true;
    });
    overallDiscountCalculation =
      overallDiscountCalculation + (total / 100) * overallDiscount;
    total = total - overallDiscountCalculation;
  }

  if (subtotal) {
    tax_total = (TAX / 100) * subtotal;
    totalSave = subtotal + tax_total;
  }

  const userCountryCode = localStorage.getItem("userCountryCode");

  return (
    <div>
      {cart.length > 0 && (
        <div className="info">
          {isLoading && <Loader />}
          <Grid style={{ margin: 0 }}>
            {userCountryCode === "US" ? (
              <Grid.Row style={{ padding: 0, marginBottom: "1em" }}>
                <Grid.Column style={{ padding: 0 }}>
                  <p>
                    Sub total (tax excl.):{" "}
                    <p
                      style={{
                        float: "right",
                        color: "rgb(6, 6, 97)",
                        fontWeight: "bolder",
                      }}
                    >
                      {zeroPrice === false ? (
                        <>
                          {"BUY  "}&nbsp;&nbsp;
                          {currencyValue} ${subtotal.toFixed(2)}{" "}
                        </>
                      ) : (
                        <>{"CALL BOA"}</>
                      )}
                    </p>
                  </p>
                </Grid.Column>
              </Grid.Row>
            ) : (
              <Grid.Row style={{ padding: 0, marginBottom: "1em" }}>
                <Grid.Column style={{ padding: 0 }}>
                  <p>
                    Sub total ( {cartCount} ):{" "}
                    <p
                      style={{
                        float: "right",
                        color: "rgb(6, 6, 97)",
                        fontWeight: "bolder",
                      }}
                    >
                      {zeroPrice === false ? (
                        <>
                          {"BUY  "}&nbsp;&nbsp;
                          {currencyValue} ${subtotal.toFixed(2)}{" "}
                        </>
                      ) : (
                        <>{"CALL BOA"}</>
                      )}
                    </p>
                  </p>
                </Grid.Column>
              </Grid.Row>
            )}
            {userCountryCode === "US" ? null : (
              <>
                <Grid.Row style={{ padding: 0, marginBottom: "1em" }}>
                  <Grid.Column style={{ padding: 0 }}>
                    <p>
                      TOTAL ( {TAX}% GST) :{" "}
                      <p style={{ float: "right" }}>
                        {zeroPrice === false ? (
                          <>
                            {" "}
                            {currencyValue} ${tax_total.toFixed(2)}
                          </>
                        ) : (
                          <>{"CALL BOA"}</>
                        )}
                      </p>
                    </p>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column style={{ padding: 0 }}>
                    <p>
                      Total :{" "}
                      <p
                        style={{
                          float: "right",
                          color: "rgb(6, 6, 97)",
                          fontWeight: "bolder",
                        }}
                      >
                        {zeroPrice === false ? (
                          <>
                            {" "}
                            {currencyValue} ${totalSave.toFixed(2)}
                          </>
                        ) : (
                          <>{"CALL BOA"}</>
                        )}
                      </p>
                    </p>
                  </Grid.Column>{" "}
                </Grid.Row>
              </>
            )}
          </Grid>
          {/* {totalSave > 0 && (
            <p>
              Total savings :
              <i style={{ float: "right" }}>
                {" "}
                {currencyValue} {totalSave.toFixed(2)}{" "}
              </i>
            </p>
          )} */}
        </div>
      )}
    </div>
  );
};
TotalPrice.propTypes = {
  cart: PropTypes.array,
  cartCount: PropTypes.object,
  account: PropTypes.object,
  overallDiscount: PropTypes.number,
  showPrice: PropTypes.props,
};

export default TotalPrice;
