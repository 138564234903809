import React from "react";
import { Card , Label} from "semantic-ui-react";
import PropTypes from "prop-types";
import HoseDescription from "./HoseDescription";
import * as searchUtils from "../../hoseMaker.util";
const HoseDetailsCard = ({ hoseResult, onBoarSelect }) => {
    
    return (<React.Fragment>
        {hoseResult.map((hose, key) => {
            const { stockDescription, stockCode, price, currency } = hose;
            return (
                <Card
                    link
                    style={{ width: "100%" }}
                    key={stockCode + key}
                    className="product-card"
                >
                    <Card.Content textAlign="left">
                        <Card.Header>
                            <Label color="green" ribbon="right">
                                {currency || "NZD "} {searchUtils.precise_round(price, 2)}
                            </Label>
                            <p className="product-header">
                                <span className="product-header-stock">{stockCode}: </span>  {stockDescription}</p>
                        </Card.Header>
                        <Card.Description className="card-description">
                            <HoseDescription {...{ hose }} addToHose={onBoarSelect}/>
                        </Card.Description>
                    </Card.Content>
                </Card>
            );
        })
    }
    </React.Fragment>)
};

HoseDetailsCard.propTypes = {
    hoseResult: PropTypes.array,
    onBoarSelect: PropTypes.func
};

export default HoseDetailsCard;