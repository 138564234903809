import React from "react";
import { Grid, Icon, Image } from "semantic-ui-react";
import _ from "lodash";
import PropTypes from "prop-types";
import moment from "moment";
import { stock_operation } from "../../constants/appConstants";

const AuditDetail = ({ audit }) => {
  const {
    stockCode,
    description,
    quantity,
    type,
    createdAt,
    user,
    reviewData,
    soldData,
    attachmentData,
    consumedData,
    transferData,
    addData
  } = audit;

  const data =
    reviewData ||
    soldData ||
    attachmentData ||
    consumedData ||
    transferData ||
    addData;
  let dataArrays = [];
  Object.keys(data).forEach(function(key) {
    const startCaseKey = _.startCase(key);
    dataArrays.push({ key: [startCaseKey], value: data[key] });
  });

  return (
    <Grid columns="equal" className="details">
      <Grid.Column computer={4} mobile={16} tablet={8}>
        {stockCode ? (
          <p>
            <b>{stockCode}</b>
            {description}
          </p>
        ) : (
          <p>
            <b>Deleted Asset : </b>
            {attachmentData.assetName}
          </p>
        )}
      </Grid.Column>

      <Grid.Column computer={2} mobile={16} tablet={8}>
        {stock_operation.map(item => {
          return (
            <b key={item.value}>{item.value === type ? item.text : null}</b>
          );
        })}
        <p> {quantity}</p>
      </Grid.Column>

      {/* <Grid.Column computer={3} mobile={16} tablet={8}>
      <p><b>{type}</b>: {quantity}</p>
      </Grid.Column> */}

      <Grid.Column computer={6} mobile={16} tablet={8}>
        <ul>
          {dataArrays.map(item => {
            if (item.key !== "Asset Id") {
              if (
                item.key === "Image Link" ||
                item.key === "Right Fitting Vernier Caliper" ||
                item.key === "Left Fitting Vernier Caliper"
              ) {
                return (
                  <li key={item.key}>
                    {item.key} :{" "}
                    <p align="right">
                      <Image inline src={item.value} size="tiny" />
                    </p>
                  </li>
                );
              }

              return (
                <li key={item.key}>
                  {item.key} : {item.value}
                </li>
              );
            }
            return <div />;
          })}
        </ul>
      </Grid.Column>
      <Grid.Column computer={4} mobile={16} tablet={8}>
        <p>
          <Icon name="user" /> {user}
        </p>
        <p> {moment(new Date(createdAt)).format("LLL")}</p>
      </Grid.Column>
    </Grid>
  );
};

AuditDetail.propTypes = {
  audit: PropTypes.object
};

export default AuditDetail;
