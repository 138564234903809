import axios from "axios";
import productDefaultImage from "../../assets/images/product_default.png";
import { showErrorToast } from "../../utils/toastUtils";
import { formatTime } from "../../utils/utils";
import { apiGetCartItems } from "../Cart/cart.api";

const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const apiCreateJob = async (data, location, status) => {
  data.jobStatusId = status;
  data.locationLat = location.lat.toString();
  data.locationLng = location.lng.toString();
  if (data.otherAssetDetails === "") data.otherAssetDetails = null;
  if (data.otherAssetDetails !== null) {
    data.assetId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
  }
  return axios
    .post(`/api/Jobbing?tz=${timeZone}`, data)
    .then((result) => {
      if (result && result.data) {
        return result.data.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        const errorMessages = data.errors;

        // Count the total number of error messages
        const countTotalErrors = (errorMessages) => {
          return errorMessages.length;
        };

        // Get the total number of error messages
        const totalErrors = countTotalErrors(errorMessages);

        // Log the appropriate message
        if (totalErrors > 1) {
          showErrorToast("Please complete all required fields");
        } else if (totalErrors === 1) {
          // Log the first error message if there is only one error
          showErrorToast(errorMessages[0].errorDetails);
        } else {
          console.log("No errors found");
        }
        return false;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiUpdateJob = async (data, location, status) => {
  const userName = localStorage.getItem("user");
  data.jobStatusId = status;
  data.hoseMaker.map((item, i) => {
    if (item.step3_LFerrule === null) item.step3_LFerrule = 0;
    if (item.step5_RFerrule === null) item.step5_RFerrule = 0;
    if (item.positionId === null) item.positionId = 0;
    if (item.seqId === undefined) {
      const maxSeqId =
        data.hoseMaker.length > 1
          ? data.hoseMaker.reduce(
              (max, obj) => (obj.seqId > max ? obj.seqId : max),
              data.hoseMaker[0].seqId
            )
          : null;
      if (maxSeqId !== null) {
        item.seqId = maxSeqId + 1;
      } else {
        item.seqId = 0;
      }
    }
  });
  data.inventoryNonBOA.map((item, i) => {
    item.seqId = i;
  });
  if (data.dueDateTime) {
    var date = new Date(data.dueDateTime);
    const formattedDate = date.toISOString();
    data.dueDateTime = formattedDate;
  }
  if (data.otherAssetDetails === "") data.otherAssetDetails = null;
  if (data.otherAssetDetails !== null) {
    data.assetId = "3fa85f64-5717-4562-b3fc-2c963f66afa6";
  }
  const payload = {
    companyName: data.companyName,
    contactName: data.contactName,
    contactNumber: data.contactNumber,
    contactEmail: data.contactEmail,
    customerPurchaseOrderNumber: data.customerPurchaseOrderNumber,
    customerLocation: data.customerLocation,
    locationLat: location.lat.toString(),
    locationLng: location.lng.toString(),
    urgencyId: data.urgencyId,
    assetSerialNumber: data.assetSerialNumber,
    assetId: data.assetId,
    otherAssetDetails: data.otherAssetDetails,
    assetPropValue: data.assetPropValue,
    assetPropUnit: data.assetPropUnit,
    jobDesc: data.jobDesc,
    jobStatusId: status,
    distanceTravelled: data.distanceTravelled?.toString()
      ? Number(data.distanceTravelled)
      : null,
    hoursLabour: data.hoursLabour?.toString() ? Number(data.hoursLabour) : null,
    hoseMaker: data.hoseMaker,
    inventoryComponents: data.inventoryComponents,
    inventoryNonBOA: data.inventoryNonBOA,
    notes: data.notes,
    makeId: data.makeId,
    saveContactDetails: data.saveContactDetails ? true : false,
    customer: data.customer?.companyName
      ? data.customer
      : { id: null, companyName: data.companyName, userName: userName },
    customerContact: data.customerContact?.contactName
      ? data.customerContact
      : {
          id: null,
          customerId: null,
          contactName: data.contactName,
          contactNumber: data.contactNumber,
          email: data.contactEmail,
        },
    boahubLocationId: data.boahubLocationId,
    assetTextId: data.assetTextId,
    technicianEmail: data.technicianEmail,
    dueDateTime: data.dueDateTime,
  };
  if (data.selectCustomer && data.selectContact) {
    payload.saveContactDetails = false;
  }
  if (!data.selectCustomer) {
    payload.customer.id = null;
    payload.customerContact.id = null;
    payload.customerContact.customerId = null;
  }
  payload.hoseMaker.map((item, i) => {
    // Trim spaces for hose tags
    if (item.hoseIdTagging.length > 0) {
      item.hoseIdTagging.map((hoseTag, i) => {
        let trimmedStr = hoseTag.hoseId.replace(/\s/g, "");
        hoseTag.hoseId = trimmedStr;
        hoseTag.hoseName = item.hoseName;
        hoseTag.hoseOemNumber = item.hoseOemNumber;
        hoseTag.isHoseCleaned = item.isHoseCleaned;
        hoseTag.isVisuallyInspected = item.isVisuallyInspected;
        hoseTag.isDesignStandard = item.isDesignStandard;
      });
    }
  });
  return axios
    .put(`/api/Jobbing/${data.id}?tz=${timeZone}`, payload)
    .then((result) => {
      if (status === 3) {
        apiGetCartItems();
      }
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        const errorMessages = data.errors;

        // Count the total number of error messages
        const countTotalErrors = (errorMessages) => {
          return errorMessages.length;
        };

        // Get the total number of error messages
        const totalErrors = countTotalErrors(errorMessages);

        // Log the appropriate message
        if (totalErrors > 1) {
          showErrorToast("Please complete all required fields");
        } else if (totalErrors === 1) {
          // Log the first error message if there is only one error
          showErrorToast(errorMessages[0].errorDetails);
        } else {
          console.log("No errors found");
        }
        return false;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiArchiveJob = async (id) => {
  return axios.put(`/api/Jobbing/archive/${id}`).then((result) => {
    if (result) {
      return result;
    }
    return false;
  });
};

export const apiRestoreJob = async (id) => {
  return axios.put(`/api/Jobbing/restore/${id}`).then((result) => {
    if (result) {
      return result;
    }
    return false;
  });
};

export const apiDeleteJob = async (id) => {
  return axios.put(`/api/Jobbing/delete/${id}`).then((result) => {
    if (result) {
      return result;
    }
    return false;
  });
};

export const apiGetJobbingList = (
  keyword,
  searchType,
  status,
  technicianFilter = "All",
  limit = 1000
) => {
  keyword = keyword !== "" ? keyword : null;
  if (technicianFilter === "") technicianFilter = "All";
  const data = {
    PageNo: 1,
    Limit: limit,
    NodaTZ: timeZone,
    SearchParam: keyword,
    JobStatusId: status !== 6 ? status : null,
    SearchType: searchType,
  };
  return axios
    .get(`/api/Jobbing?TechnicianFilter=${technicianFilter}`, { params: data })
    .then((result) => {
      if (result) {
        if (result.data) {
          if (status === 5) return result.data.records;
          let filteredData = result.data.records.filter(
            (obj) => obj.jobStatusId !== 5
          );
          return filteredData;
        }
      }
      return false;
    });
};

export const apiGetJobbing = (id) => {
  const timeZoneURI = timeZone.toString().replace("/", "%2F");
  return axios.get(`/api/Jobbing/${id}/${timeZoneURI}`).then((result) => {
    if (result && result.data) {
      return result.data.data;
    }
    return false;
  });
};

export const apiAssetsSearch = (searchValue) => {
  return axios
    .get(`/api/Jobbing/assets?search=${searchValue}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.map((item) => {
            const fullDescription = item.displayName.split(" | ");
            item.title = fullDescription[2];
            item.description = fullDescription[1];
            item.price = "Make: " + fullDescription[0];
          });
        }
        return result.data;
      }
      return false;
    });
};

export const apiCustomerSearch = (searchValue) => {
  return axios
    .get(`/api/user/search-user-customers?txtSearch=${searchValue}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.sort((a, b) =>
            a.companyName.localeCompare(b.companyName)
          );
          result.data.map((item) => {
            item.title = item.companyName;
          });
        }
        return result.data;
      }
      return false;
    });
};

export const apiCustomerList = () => {
  return axios.get(`/api/user/user-customers-list`).then((result) => {
    if (result) {
      if (result.data) {
        result.data.customersWithContacts.sort((a, b) =>
          a.companyName.localeCompare(b.companyName)
        );
        result.data.customersWithContacts.map((item) => {
          item.text = item.companyName;
          item.value = item.companyName;
        });
      }
      return result.data.customersWithContacts;
    }
    return false;
  });
};

export const apiContactSearch = (customerId, searchValue) => {
  return axios
    .get(
      `/api/user/search-customer-contacts?customerId=${customerId}&txtSearch=${searchValue}`
    )
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.sort((a, b) =>
            a.contactName.localeCompare(b.contactName)
          );
          result.data.map((item) => {
            item.title = item.contactName;
            item.description = item.email;
          });
        }
        return result.data;
      }
      return false;
    });
};

export const apiGetUrgencies = () => {
  return axios.get(`/api/Jobbing/job-urgencies`).then((result) => {
    if (result) {
      if (result.data) {
        result.data.map((item) => {
          item.text = item.name;
          item.value = item.id;
        });
        return result.data;
      }
    }
    return false;
  });
};

export const apiGetAssetsMake = () => {
  return axios.get(`/api/Jobbing/assets/make`).then((result) => {
    if (result) {
      if (result.data) {
        result.data.map((item) => {
          item.text = item.name;
          item.value = item.id;
        });
        result.data.unshift({ text: "Other", value: 0 });
      }
      return result.data;
    }
    return false;
  });
};

export const apiGetEquipmentTypes = (makeId) => {
  return axios
    .get(`/api/Jobbing/assets/equipment-type?makeId=${makeId}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.map((item) => {
            item.text = item.name;
            item.value = item.id;
          });
        }
        return result.data;
      }
      return false;
    });
};

export const apiGetModels = (makeId, equipmentId) => {
  return axios
    .get(
      `/api/Jobbing/assets/models?makeId=${makeId}&equipmentId=${equipmentId}`
    )
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.map((item) => {
            item.text = item.model;
            item.value = item.id;
          });
          return result.data;
        }
      }
      return false;
    });
};

export const apiGetHosePositions = (equipmentTypeName) => {
  return axios
    .get(`/api/Jobbing/hose/positions/${equipmentTypeName}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          if (result.data.positions) {
            result.data.positions.map((item) => {
              item.text = item.name;
              item.value = item.id;
            });
            return result.data;
          }
        }
      }
      return false;
    });
};

export const apiGeneratePDF = (jobId, jobNumber, type) => {
  const payload = {
    tz: timeZone,
  };
  let config = {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
    },
    params: payload,
  };
  return axios
    .get(`/api/Jobbing/pdf/${jobId}/${type}`, config)
    .then((response) => {
      if (response) {
        if (response.data && response.status === 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", jobNumber + ".pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
          return true;
        }
      }
      return false;
    });
};

export const apiSetTechnicianFilter = async (technician) => {
  const data = {
    defaultTechnician: technician,
  };
  return axios
    .put(`/api/Jobbing/set-user-default-technician`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiSaveJobImage = async (data, id) => {
  const formData = new FormData();

  formData.append("file", data);
  return axios
    .post(`/api/Jobbing/upload-job-image?jobId=${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        showErrorToast(data?.message);
        if (data.statusCode === 400) return false;
        if (data.statusCode === 500) return false;
        return false;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiSaveJobAssetImage = async (data, id, index) => {
  const formData = new FormData();

  formData.append("file", data);
  return axios
    .post(
      `/api/Jobbing/upload-job-asset-image?jobId=${id}&seqId=${index}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        showErrorToast(data?.message);
        if (data.statusCode === 400) return false;
        if (data.statusCode === 500) return false;
        return false;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiRemoveJobImage = async (imageId, id) => {
  return axios
    .delete(`/api/Jobbing/delete-job-image?jobId=${id}&imageId=${imageId}`)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};

export const apiXeroCustomerList = () => {
  if (localStorage.getItem("isXeroConnected") !== "true") return false;
  return axios.get(`/api/user/xero-customers`).then((result) => {
    if (result) {
      if (result.data) {
        result.data.sort((a, b) => a.name.localeCompare(b.name));
        result.data.map((item) => {
          item.text = item.name;
          item.value = item.contactID;
        });
      }
      return result.data;
    }
    return false;
  });
};

export const apiXeroInvoiceList = (contactId) => {
  if (localStorage.getItem("isXeroConnected") !== "true") return false;
  return axios
    .get(`/api/user/xero-customer-invoices?contactId=${contactId}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.sort((a, b) =>
            a.invoiceNumber?.localeCompare(b.invoiceNumber)
          );
          result.data.map((item) => {
            var date = new Date(item.date);
            var day = date.getUTCDate();
            var month = date.getUTCMonth() + 1;
            var year = date.getUTCFullYear();
            var formattedDate =
              day + "/" + (month < 10 ? "0" : "") + month + "/" + year;

            item.text = item.invoiceNumber;
            item.value = item.invoiceID;
            item.description = formattedDate;
          });
          return result.data;
        }
        return false;
      }
      return false;
    });
};

export const apiGenerateXeroInvoice = async (
  jobId,
  xeroContactId,
  sendComplete
) => {
  return axios
    .post(
      `/api/Jobbing/generate-xero-invoice?jobId=${jobId}&xeroContactId=${xeroContactId}&sendComplete=${sendComplete}&tz=${timeZone}`
    )
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiAddtoExistingXeroInvoice = async (
  jobId,
  invoiceId,
  sendComplete
) => {
  return axios
    .post(
      `/api/Jobbing/add-existing-xero-invoice?jobId=${jobId}&invoiceId=${invoiceId}&sendComplete=${sendComplete}&tz=${timeZone}`
    )
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiGenerateXeroInvoicePDF = async (invoiceId) => {
  let config = {
    responseType: "arraybuffer",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/pdf",
    },
  };
  return axios
    .get(`/api/Jobbing/get-xero-invoice-pdf?invoiceId=${invoiceId}`, config)
    .then((result) => {
      if (result && result.data) {
        const blob = new Blob([result.data], { type: "application/pdf" });

        // Create a URL for the Blob
        const url = URL.createObjectURL(blob);

        // Open a new tab or window and set the URL to the created one
        window.open(url, "_blank");
        return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiDiscardXeroInvoice = async (jobId, invoiceId) => {
  return axios
    .put(
      `/api/Jobbing/discard-xero-invoice?jobId=${jobId}&invoiceId=${invoiceId}`
    )
    .then((result) => {
      if (result) {
        return result;
      }
      return false;
    });
};

export const apiResendXeroInvoice = async (invoiceId) => {
  return axios
    .post(`/api/Jobbing/send-invoice-email?invoiceId=${invoiceId}`)
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiGetJobTimeLogs = (jobId) => {
  return axios
    .get(`/api/Jobbing/job-time-logs?jobId=${jobId}&timeZone=${timeZone}`)
    .then((result) => {
      if (result) {
        if (result.data) {
          const { jobTimeLogs } = result.data;
          if (jobTimeLogs) {
            jobTimeLogs?.map((data) => {
              const date = new Date(data.createdDateTime);
              const dateEnd = new Date(data.updatedByDateTime);
              const options = {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              };

              // Format the time components in 12-hour format
              const formattedTime = formatTime(date);
              const formattedTimeEnd = formatTime(dateEnd);

              data.formattedDate = date.toLocaleDateString("en-GB", options);
              data.formattedDateStart = date.toLocaleDateString(
                "en-GB",
                options
              );
              data.formattedDateEnd = data.updatedByDateTime
                ? dateEnd.toLocaleDateString("en-GB", options)
                : "";
              data.formattedDateTimeStart = `${formattedTime}`;
              data.formattedDateTimeEnd = data.updatedByDateTime
                ? `${formattedTimeEnd}`
                : "";
            });
            return result.data;
          } else {
            return [];
          }
        }
      }
      return false;
    });
};

export const apiJobTimeLogStart = async (jobId = 0) => {
  const data = {
    id: 0,
    jobId: jobId,
    notes: "",
  };
  return axios
    .post(`/api/Jobbing/job-time-log-start`, data)
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiJobTimeLogStop = async (jobId, logId) => {
  const data = {
    id: logId,
    jobId: jobId,
    notes: "",
  };
  return axios
    .post(`/api/Jobbing/job-time-log-end`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiJobTimeLogManual = async (timeEntry, jobId) => {
  const data = {
    id: 0,
    jobId: jobId,
    notes: timeEntry.notes,
    timeSpanMinutes: timeEntry.timeSpanMinutes
      ? timeEntry.timeSpanMinutes * 60
      : null,
  };
  return axios
    .post(`/api/Jobbing/job-time-log-manual`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiGetCauseTypes = () => {
  return axios.get(`api/Jobbing/hose-maker-cause-types`).then((result) => {
    if (result) {
      if (result.data) {
        result.data.map((item) => {
          item.text = item.causeType;
          item.value = item.causeType;
        });
        return result.data;
      }
    }
    return false;
  });
};

export const apiGetDamageTypes = () => {
  return axios.get(`/api/Jobbing/hose-maker-damage-types`).then((result) => {
    if (result) {
      if (result.data) {
        result.data.map((item) => {
          item.text = item.damageType;
          item.value = item.damageType;
        });
        return result.data;
      }
    }
    return false;
  });
};

export const apiDeleteAllHoseMakerImages = async (jobId, hoseMakerSeqId) => {
  return axios
    .delete(
      `/api/Jobbing/delete-hosemaker-alluploads?jobId=${jobId}&hoseMakerSeqId=${hoseMakerSeqId}`
    )
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    });
};
