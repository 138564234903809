export const asset = {
  name: "",
  type: "",
  make: "",
  model: "",
  subModel: "",
  year: "",
  engineType: "",
  serialNumber: "",
  customerPlantNo: "",
  modifications: "",
  linkedERP: "",
  crew: "",
  location: "",
  operator: null,
  isLinkedAsset: false,
  maintenanceUnit: "",
  assetImage: [],
  hoseId: ""
};
