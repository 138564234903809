import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authReducer from "../auth.reducer";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/Loader";
import { Grid } from "semantic-ui-react";
import polyglot from "../../../i18n";
import "./verifyNewEmail.css";

class VerifyNewEmail extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      errorMessage: ""
    };
  }

  componentDidMount = async () => {
    const { code, email } = this.props.match.params;
    if (code && email) {
      this.setState({ loading: true });
      await this.props.actionVerifyNewEmailCode(code, email);
      this.setState({ loading: false });
    } else {
      this.props.history.push("/");
    }
  };

  goHome = () => {
    this.props.history.push("/");
  };

  render() {
    let text = "";
    let responseJSX = "";
    let loader = "";
    const { response } = this.props;
    const { errorMessage } = this.state;
    if (response) {
      let { status, data } = this.props.response;
      if (status === 404) {
        responseJSX = (
          <div>{polyglot.t("verifyNewEmailPage.linkNotFound")}</div>
        );
      } else if (status === 400) {
        this.props.history("/");
      } else if (status === 406) {
        responseJSX = (
          <div>
            <span>Your Email is verified. Try Login In.</span>
          </div>
        );
      } else if (status === 200) {
        const { customerType } = data;
        text = <span className="success">Email Verified</span>;
        responseJSX = (
          <div>
            <div className="verify-email-success">
              <p>{polyglot.t("verifyNewEmailPage.enterprise.success1")} </p>
            </div>
          </div>
        );
      }
    }
    if (this.state.loading) {
      loader = <Loader text="Verifying code, please wait" />;
    }

    return (
      <Grid>
        <Grid.Row verticalAlign="middle" className="verify-email-box">
          {loader}
          <div className="message">
            <p>
              <b>{text}</b>
            </p>
            <p>
              <b>{responseJSX}</b>
              <b>{errorMessage}</b>
            </p>
            <br />
            <a href="" onClick={this.goHome}>
              Back to home
            </a>
          </div>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
    response: state.auth.response
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(authReducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(VerifyNewEmail)
);
