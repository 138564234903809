import React from "react";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const isSessionValid = !!window.localStorage.getItem("sessionID");
  let path = "/";

  let isAllowed = true;

  let isValid = isSessionValid;

  if (!isValid) {
    window.localStorage.removeItem("intendedUrl");

    const intendedUrl = window.location.href;
    window.localStorage.setItem("intendedUrl", intendedUrl);
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
