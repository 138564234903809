import React from "react";
import _ from "lodash";
import { Button, Table } from "semantic-ui-react";

const NA_VALUES = "#N/A";

const ItemProperties = ({
  isShowMoreButton = false,
  onShowMore,
  onShowLess,
  isShowMore = false,
  stockImage2,
  ...item
}) => {
  const properties = _.pickBy(item, _.identity);
  const {
    productDimensions,
  } = properties;
  let nonEmptyItems = _.pickBy(productDimensions, _.identity);

  const listItems = _.map(nonEmptyItems, (key, value) => {
    value = value.replace(/_/g, " ");
    value = _.startCase(value);
    if (key === NA_VALUES) return null;

    return (
      <Table.Row key={value} >
        <Table.Cell>{value}</Table.Cell>
        <Table.Cell>{key}</Table.Cell>
      </Table.Row>
    );
  });

  const TableHeadJSX = (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell>Dimension</Table.HeaderCell>
        <Table.HeaderCell>Value</Table.HeaderCell>
      </Table.Row>
    </Table.Header>)

  return (
    <div className="show-specification">
      {isShowMore
        &&
        <div>
          <label>
            <b>Specifications: </b>
          </label>
          <Table celled>
            {TableHeadJSX}
            <Table.Body>
              {listItems}
            </Table.Body>
          </Table>
        </div>
      }
      <div>
        {!isShowMore && listItems.length > 0
          &&
          <div>
            <label className="more-specifications">
              {
                stockImage2 &&
                <span>1 more Image </span>
              }
            </label>
            <Button fluid onClick={onShowMore} className="more-button"
              basic color='blue'
              size='mini'
              content='Show specifications' />
          </div>
        }
        {isShowMore
          && <Button fluid onClick={onShowLess} className="more-button"
            basic color='teal' size='mini'
            content='Show less' />}
      </div>
    </div>
  );
};

export default ItemProperties;
