import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as orderHistoryReducer from "./orderHistory.reducer";
import * as cartReducer from "../Cart/cart.reducer";
import MetaData from "../../components/MetaData";
import OrderHistoryList from "../../components/OrderHistoryList";
import { Grid, Divider, Pagination, Button, Icon } from "semantic-ui-react";
import addToCart from "../Search/AddtoCart";
import DateRagePicker from "../../components/DateRangePicker";
import Loader from "../../components/Loader";
import AddToInventory from "./AddToInventory";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import _ from "lodash";
import "./orderHistory.css";

class OrderHistory extends React.Component {
  constructor() {
    super();
    this.state = {
      activePage: 1,
      loader: false,
      isError: false,
    };
  }

  componentDidMount = async () => {
    this.setState({ loader: true });
    await this.props.actionGetAllOrders(null, null, null, null, 8, 0);
    await this.props.actionGetAllLocation();

    this.setState({ loader: false });
  };

  addToCartHandler = async (item) => {
    const {
      stockCode,
      description,
      length = null,
      unit = "Metre",
      hose = false,
      hoseId,
    } = item;
    // const splitStockCode = stockCode.split("-");
    // const count = splitStockCode.length;
    // if (count >= 3) {
    //   if (hose && hoseId) {
    //     await this.addHoseToCart(stockCode, description, hose, hoseId, length);
    //     addToCart({ stockCode });
    //   } else {
    //     showErrorToast("Can not add this hose to cart from order history");
    //   }
    // } else {
    await this.addStockCodeToCart(
      stockCode,
      description,
      unit,
      length,
      hose,
      hoseId
    );
    addToCart({ stockCode });
    // }
  };

  addHoseToCart = async (
    stockCode,
    description,
    hose,
    hoseId,
    length,
    unit
  ) => {
    const hoseData = {
      stockCode: stockCode,
      quantity: 1,
      length,
      unit,
      stockDescription: description,
      hose: hose,
      hoseId: hoseId,
    };
    await this.props.actionAddHoseToCart(hoseData);
  };

  addStockCodeToCart = async (stockCode, description, unit, length) => {
    const data = {
      stockCode: stockCode,
      quantity: 1,
      length,
      unit,
      stockDescription: description,
      refId: null,
    };
    await this.props.actionAddToCart(data);
  };

  setFromDate = (date) => {
    this.props.actionSetFromDate(date);
  };
  setToDate = (date) => {
    this.props.actionSetToDate(date);
  };

  handlePaginationChange = (...params) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;
    const { totalPages } = this.props;

    if (totalPages === 1) {
      return;
    }

    this.setState({
      activePage,
    });
    const { fromDate, toDate } = this.props;

    this.props.actionGetAllOrders(
      null,
      null,
      fromDate,
      toDate,
      8,
      activePage - 1
    );
  };

  filterHandler = async () => {
    const { fromDate, toDate } = this.props;
    this.setState({ loader: true });
    await this.props.actionGetAllOrders(null, null, fromDate, toDate, 8, 0);
    this.setState({ loader: false });
  };

  clearFilter = () => {
    this.props.actionSetFromDate(null);
    this.props.actionSetToDate(null);
    this.props.actionGetAllOrders(null, null, null, null, 8, 0);

    this.setState({
      activePage: 1,
    });
  };

  setLocation = async (e, data) => {
    this.setState({ isError: false });
    const { value } = data;
    this.props.setLocationId(value);
  };

  setOrderNo = async (orderNo) => {
    this.props.setOrderNo(orderNo);
    this.props.toggleInventoryForm(true);
  };

  closeForm = () => {
    this.setState({ isError: false });
    this.props.toggleInventoryForm(false);
    this.props.setLocationId("");
    this.props.setOrderNo("");
  };

  addToInventory = async () => {
    this.setState({ loader: true, isError: false });
    const { orderNo, locationId } = this.props;
    if (!locationId) {
      this.setState({ loader: false, isError: true });
      return;
    }
    const data_to_send = {
      orderNo: orderNo,
      location: locationId,
    };
    await this.props.actionAddToInventory(data_to_send);
    const { response } = this.props;

    const { status, data } = response;
    if (status === 200) {
      showSuccessToast("Added in to Inventory.");
    } else {
      const { code } = data;
      this.showErrorMessage(code);
      return;
    }

    this.closeForm();
    this.props.actionGetAllOrders(null, null, null, null, 8, 0);
    this.setState({ loader: false });
  };

  showErrorMessage = (code) => {
    if (code === 1105) {
      showErrorToast("Invalid Order");
    } else if (code === 1106) {
      showErrorToast("Order Doesn't exist in the system");
    }
    this.setState({ loader: false });
  };

  render() {
    const {
      allOrders,
      toDate,
      fromDate,
      totalPages,
      locations,
      isAddInventory,
      locationId,
      user,
    } = this.props;
    const { account = {} } = user;
    const { showFacilities, showPrice } = account;
    const { activePage, loader, isError } = this.state;
    let locationList = [];

    if (locations) {
      _.map(locations, (location) => {
        const { name } = location;
        locationList.push({
          text: name,
          value: name,
        });
      });
    }

    const dateFilterJSX = (
      <Grid columns="equal">
        <Grid.Column
          computer={12}
          mobile={16}
          tablet={11}
          className="no-padding"
        >
          <DateRagePicker
            setStartDate={(date) => this.setFromDate(date)}
            setEndDate={(date) => this.setToDate(date)}
            startDate={fromDate}
            endDate={toDate}
          />
        </Grid.Column>
        <Grid.Column computer={4} mobile={16} tablet={5}>
          <Button
            type="button"
            className="search_button"
            color="green"
            onClick={this.filterHandler}
            loading={loader ? true : false}
          >
            Filter
          </Button>
          <Button
            type="button"
            className="clear-button"
            onClick={this.clearFilter}
          >
            Clear
          </Button>
        </Grid.Column>
      </Grid>
    );

    return (
      <React.Fragment>
        <MetaData title="Order History" />
        <Grid>
          <Grid.Column>
            <h3>
              <Icon name="history" /> Order history
              <Divider />
            </h3>

            <Grid.Row>
              <div> {dateFilterJSX} </div>
            </Grid.Row>
            {loader && <Loader text="Loading, please wait" />}
            <OrderHistoryList
              allOrders={allOrders}
              addToCartHandler={this.addToCartHandler}
              setOrderNo={this.setOrderNo}
              showFacilities={showFacilities}
              showPrice={showPrice}
            />
            <div>
              {allOrders.length ? (
                <Pagination
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={this.handlePaginationChange}
                  totalPages={totalPages}
                  firstItem={null}
                  lastItem={null}
                />
              ) : null}
            </div>
          </Grid.Column>
        </Grid>
        <AddToInventory
          locations={locationList}
          isAddInventory={isAddInventory}
          closeForm={this.closeForm}
          setLocation={this.setLocation}
          locationId={locationId}
          setOrderNo={this.setOrderNo}
          addToInventory={this.addToInventory}
          isLoading={loader}
          isError={isError}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    allOrders: state.orderHistory.allOrders,
    totalPages: state.orderHistory.totalPages,
    fromDate: state.orderHistory.fromDate,
    toDate: state.orderHistory.toDate,
    isAddInventory: state.orderHistory.isAddInventory,
    locations: state.orderHistory.locations,
    locationId: state.orderHistory.locationId,
    orderNo: state.orderHistory.orderNo,
    response: state.orderHistory.response,
    user: state.auth.user,
  };
};

const reducer = { ...orderHistoryReducer, ...cartReducer };

const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);
