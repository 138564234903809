import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  Dimmer,
  Form,
  Grid,
  Icon,
  Image,
  Input,
  Loader,
  Button,
  Modal,
  Pagination,
} from "semantic-ui-react";
import "./inventoryManagement.css";
import {
  apiGetProductById,
  apiGetStockLocationReport,
  apiGetStockProducts,
} from "./inventoryManagement.api";
import MetaData from "../../components/MetaData";
import { useDraggable } from "react-use-draggable-scroll";
import _debounce from "lodash/debounce";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import Skeleton from "react-loading-skeleton";
import {
  apiAddStockLocation,
  apiDeleteStockLocation,
  apiGetStockLocationList,
  apiUpdateStockLocation,
} from "../Settings/settings.api";
import boapod from "../../assets/images/pages/find-boapod.png";
import { AnimatePresence, motion } from "framer-motion/dist/framer-motion";
import productDefaultImage from "../../assets/images/product_default.png";
import cartReducer, {
  actionAddProductToCart,
  actionGetCart,
  actionLoadCart,
} from "../Cart/cart.reducer";
import addToCart from "../Search/AddtoCart";
import searchReducer from "../Search/search.reducer";
import NumericInput from "react-numeric-input";
import {
  apiGetCartItems,
  apiGetCartItemsByStockLocation,
} from "../Cart/cart.api";
import { apiFetchStockMove } from "../OrderHistoryNew/orderHistory.api";
import inventoryIcon from "../../assets/images/pages/inventory.png";
import NumericInputBox from "../../components/NumericInputBox";
import MinMax from "./MinMax";
import AdjustProductMinMax from "./AdjustProductMinMax";
import BulkAdjustment from "./BulkAdjustment";
import MinMaxReport from "./MinMaxReport";

const InventoryManagement = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedId, setSelectedId] = useState(0);
  const [stocklocationList, setStocklocationList] = useState([]);
  const [selectedStock, setSelectedStock] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [productList, setProductList] = useState([]);
  const [expandedCardIds, setExpandedCardIds] = useState([]);
  const [addStockLocationOpen, setAddStockLocationOpen] = useState(false);
  const [stockSaved, setStockSaved] = useState(false);
  const [stockFormData, setStockFormData] = useState({});
  const [deleteStockLocationOpen, setDeleteStockLocationOpen] = useState(false);
  const [deleteStockLocationErrorOpen, setDeleteStockLocationErrorOpen] =
    useState(false);
  const [deleteStockLocationError, setDeleteStockLocationError] = useState({});
  const [cart, setCart] = useState({});
  const [cartId, setCartId] = useState(0);
  const [page, setPage] = useState(1);
  const latestRequest = useRef(0);

  const [adjustMinMaxOpen, setAdjustMinMaxOpen] = useState(false);
  const [minMaxReportOpen, setMinMaxReportOpen] = useState(false);
  const [minMaxOpen, setMinMaxOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});

  const [bulkAdjustmentOpen, setBulkAdjustmentOpen] = useState(false);

  const ref = useRef();
  const { events } = useDraggable(ref);

  function handleStockLocationChange(dataType, value) {
    let newState = [];
    newState.push(stockFormData);
    let data = newState.map((item, i) => {
      if (i == 0) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    setStockFormData(data[0]);
  }

  const toggleExpand = (id) => {
    setExpandedCardIds((prev) =>
      prev.includes(id) ? prev.filter((cardId) => cardId !== id) : [...prev, id]
    );
  };

  const actionGetCart = async () => {
    try {
      const result = await apiGetCartItemsByStockLocation(selectedId);
      const { data } = result;
      const { userCartItems } = data;
      userCartItems.map((item, i) => {
        item.isSelected = true;
      });
      userCartItems.sort(
        (a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime)
      );
      setCartId(data.id);
      setCart(userCartItems);
    } catch (e) {
      console.error("Failed to load cart", e);
    }
  };

  const getStockLocationsData = async () => {
    setSearchLoading(true);
    const result = await apiGetStockLocationList();
    if (result) {
      // Step 1: Find the element with id === 1
      const firstElement = result.find(
        (data) => data?.id === Number(localStorage.getItem("boahubLocationId"))
      );

      if (firstElement) {
        setSelectedId(firstElement.id);
        setSelectedStock(firstElement);
        localStorage.setItem(
          "selectedStockLocationName",
          firstElement.stockLocationName
        );
      }

      // Step 2: Filter out the element with id === 1
      const filteredArray = result.filter(
        (data) => data?.id !== Number(localStorage.getItem("boahubLocationId"))
      );

      // Step 3: Sort the remaining elements alphabetically by name
      filteredArray.sort((a, b) =>
        a.stockLocationName.localeCompare(b.stockLocationName)
      );

      // Step 4: Rebuild the array with the first element at the start
      const resultArray = firstElement
        ? [firstElement, ...filteredArray]
        : filteredArray;

      setStocklocationList(resultArray);
      setSearchLoading(false);
    } else {
      setStocklocationList([]);
      setSearchLoading(false);
    }
  };

  const getStockProductsData = async (
    textSearch = "",
    page = 1,
    currentRequest = 0
  ) => {
    setDataLoading(true);
    const result = await apiGetStockProducts(selectedId, textSearch, page);
    if (currentRequest === latestRequest.current) {
      if (result) {
        setProductList(result);
      } else {
        showErrorToast("Something went wrong");
      }
      setDataLoading(false);
    }
  };

  const onGetStockLocationReport = async () => {
    setIsLoading(true);
    const result = await apiGetStockLocationReport(selectedId);
    if (result) {
      let productList = [];
      result.map((item, i) => {
        const product = {
          SKU: item.sku,
          Description: item.description,
          Quantity: item.quantity ? item.quantity : 0,
          Incoming: item.incoming ? item.incoming : 0,
          Outgoing: item.outgoing ? item.outgoing : 0,
          Available: item.available ? item.available : 0,
          Balance: item.balance ? item.balance : 0,
          "Stock Value": item.stockValue,
          Min: item.min ? item.min : 0,
          Max: item.max ? item.max : 0,
        };
        if (localStorage.getItem("boahubShowPurchasePrice") !== "true")
          delete product["Stock Value"];
        productList.push(product);
      });
      const totalStockValue = result.reduce((sum, item) => {
        const value = item?.stockValue
          ? parseFloat(item.stockValue.replace("$", ""))
          : 0;
        return sum + value;
      }, 0);
      const totalFormatted = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(totalStockValue);
      if (localStorage.getItem("boahubShowPurchasePrice") === "true")
        productList.push({
          SKU: "",
          Description: "",
          Quantity: "",
          Incoming: "",
          Outgoing: "",
          Available: "",
          Balance: "",
          "Stock Value": totalFormatted,
          Min: "",
          Max: "",
        });
      const csvData = convertToCSV(productList);
      downloadCSV(csvData, `stock-location-report-${selectedId}.csv`);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  // Convert JSON to CSV format
  function convertToCSV(data) {
    const headers = Object.keys(data[0]);
    const csvRows = [headers.join(",")]; // Create header row

    // Add each row's values
    data.forEach((row) => {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '""'); // Escape double quotes
        return `"${escaped}"`; // Wrap in double quotes
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n"); // Join rows with newlines
  }

  // Trigger CSV download
  function downloadCSV(csv, filename) {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const handlePaginationChange = (...params) => {
    // const { searchresult } = this.props;
    // const { totalPages } = searchresult;

    // if (totalPages === 1) {
    //   return;
    // }

    // document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;

    latestRequest.current += 1;
    const currentRequest = latestRequest.current;
    setPage(activePage);
    getStockProductsData(searchTerm, activePage, currentRequest);
  };

  const onDeleteStockLocation = async () => {
    setIsLoading(true);

    const stockData = await apiDeleteStockLocation(stockFormData);
    if (stockData) {
      if (stockData.errors) {
        setDeleteStockLocationError(stockData);
        setDeleteStockLocationErrorOpen(true);
        setDeleteStockLocationOpen(false);
        setIsLoading(false);
      }

      getStockLocationsData();
      setStockFormData({});
      showSuccessToast(
        <div>
          <i className="check icon" /> Stock Location Deleted Successfully
        </div>
      );
      setDeleteStockLocationOpen(false);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setDeleteStockLocationOpen(false);
      setIsLoading(false);
    }
  };

  const onUpdateStockLocation = async () => {
    setIsLoading(true);
    try {
      const stockData = await apiUpdateStockLocation(stockFormData);
      if (stockData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Stock Location Updated Successfully
          </div>
        );
        setStockFormData({});
        setAddStockLocationOpen(false);
        setIsLoading(false);
        setStockSaved(false);
        getStockLocationsData();
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onSubmitAddStockLocation = async () => {
    setIsLoading(true);
    setStockSaved(true);
    try {
      const stockData = await apiAddStockLocation(stockFormData);
      if (stockData) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Stock Location Added Successfully
          </div>
        );
        setStockFormData({});
        setAddStockLocationOpen(false);
        setIsLoading(false);
        setStockSaved(false);
        getStockLocationsData();
      } else {
        setStockFormData({});
        setAddStockLocationOpen(false);
        setIsLoading(false);
        setStockSaved(false);
        getStockLocationsData();
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  useEffect(() => {
    setPage(1);
    const delayDebounceFn = setTimeout(() => {
      latestRequest.current += 1;
      const currentRequest = latestRequest.current;
      getStockProductsData(searchTerm, 1, currentRequest);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (selectedId !== 0) {
      latestRequest.current += 1;
      const currentRequest = latestRequest.current;
      setPage(1);
      getStockProductsData(searchTerm, 1, currentRequest);
      localStorage.setItem("selectedStockLocationId", selectedId);
      localStorage.setItem(
        "selectedStockLocationName",
        selectedStock?.stockLocationName
      );
    } else {
      setSelectedData({});
      setStockFormData({});
    }
  }, [selectedId, adjustMinMaxOpen, minMaxOpen, bulkAdjustmentOpen]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      apiFetchStockMove();
      getStockLocationsData();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    if (selectedId) {
      actionGetCart();
    }
  }, [selectedId]);

  const handleQuantityChange = (value, index) => {
    console.log(value);
    let newState = productList?.records;

    let productsData = newState.map((item, i) => {
      if (i === index) {
        item.quantity = Number(value);
      }
      return item;
    });

    setProductList({ ...productList, records: productsData });
  };

  const addBoxRollHandler = (item, quantity) => {
    addToCartHandler(item, quantity);
  };

  const addToCartHandler = async (item, quantity, length = 1) => {
    const product = await apiGetProductById(item.id);
    if (product) {
      const {
        stockCode,
        stockDescription,
        pack,
        pricingPolicy,
        id,
        taxId,
        productUom,
      } = product;
      const { email } = props.userInfo;
      const { price, currencyId } = pricingPolicy;
      let data = {
        stockCode,
        quantity: quantity ? quantity : Number(item.quantity),
        length: length,
        pack,
        stockDescription,
        price,
        currencyId,
        id,
        email,
        taxId,
        productUom,
        refId: null,
      };
      const latestCart = await actionAddProductToCart(data, cart, selectedId);
      if (latestCart) {
        setTimeout(async () => {
          await actionGetCart();
        }, 1000);
      }
      const isPresent = _.filter(cart, (item) => {
        return stockCode === item.barcode;
      });
      addToCart({ stockCode, isPresent });
    }
  };

  const Quantity = ({ pack, quantity, index, uomId }) => {
    // const isEach = pack == "Each";
    const isMeter = pack === "Metre" || pack === "Feet";

    const quantityMessage = isMeter ? pack : "Quantity";

    return (
      <div className="quantity-form">
        <Grid columns="equal">
          <Grid.Column width={16}>
            <Form.Field>
              <label htmlFor="quantity">{quantityMessage}</label>
              <div style={{ marginBottom: 10 }}>
                <NumericInputBox
                  style={{
                    input: {
                      padding: 5,
                      textAlign: "center",
                    },
                  }}
                  onKeyDownHandler={(e) => {
                    if (e.key === "Enter") {
                      handleQuantityChange(e.target.value, index);
                    }
                  }}
                  onBlurHandler={(e) => {
                    handleQuantityChange(e.target.value, index);
                  }}
                  name="quantity"
                  min={1}
                  max={10000}
                  maxLength={6}
                  className="form-control"
                  precision={uomId === 8 ? 1 : 0}
                  step={1}
                  value={quantity}
                />
              </div>
            </Form.Field>
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  return (
    <React.Fragment>
      <MetaData title="Inventory" />
      <Modal
        className="hosemaker-modal"
        open={minMaxReportOpen}
        onClose={() => setMinMaxReportOpen(false)}
        closeIcon
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {selectedStock?.stockLocationName
            ? `Min/Max Report - ${selectedStock?.stockLocationName}`
            : "Min/Max Report"}
        </Modal.Header>
        <Modal.Content>
          <MinMaxReport
            stockLocationId={selectedId}
            stockLocationName={selectedStock?.stockLocationName}
            setMinMaxReportOpen={setMinMaxReportOpen}
          />
        </Modal.Content>
      </Modal>
      <Modal
        className="hosemaker-modal"
        open={minMaxOpen}
        onClose={() => setMinMaxOpen(false)}
        closeIcon
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {selectedStock?.stockLocationName
            ? `Set Min/Max - ${selectedStock?.stockLocationName}`
            : "Set Min/Max"}
        </Modal.Header>
        <Modal.Content>
          <MinMax
            stockLocationId={selectedId}
            stockLocationName={selectedStock?.stockLocationName}
            setMinMaxOpen={setMinMaxOpen}
          />
        </Modal.Content>
      </Modal>
      <Modal
        className="hosemaker-modal"
        open={bulkAdjustmentOpen}
        onClose={() => setBulkAdjustmentOpen(false)}
        closeIcon
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {selectedStock?.stockLocationName
            ? `Bulk Adjustment - ${selectedStock?.stockLocationName}`
            : "Bulk Adjustment"}
        </Modal.Header>
        <Modal.Content>
          <BulkAdjustment
            stockLocationId={selectedId}
            setBulkAdjustmentOpen={setBulkAdjustmentOpen}
          />
        </Modal.Content>
      </Modal>
      <Modal
        size="small"
        open={adjustMinMaxOpen}
        onClose={() => setAdjustMinMaxOpen(false)}
        closeIcon
        closeOnDimmerClick={false}
      >
        <Modal.Content style={{ padding: 0 }}>
          <AdjustProductMinMax
            stockLocationId={selectedId}
            selectedProduct={selectedProduct}
            setAdjustMinMaxOpen={setAdjustMinMaxOpen}
          />
        </Modal.Content>
      </Modal>
      <Modal
        size="mini"
        open={deleteStockLocationOpen}
        closeOnDimmerClick={false}
      >
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure <br /> you want to delete{" "}
            {stockFormData.stockLocationName}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDeleteStockLocationOpen(false)}>NO</Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onDeleteStockLocation(true)}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={deleteStockLocationErrorOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <div style={{ textAlign: "left", marginBottom: 10 }}>
            <p style={{ color: "red" }}>Error</p>
          </div>
          <div
            style={{
              textAlign: "left",
              marginBottom: 10,
              fontWeight: "bold",
            }}
          >
            <p>{deleteStockLocationError.message}</p>
          </div>
          {deleteStockLocationError.errors?.length > 0 && (
            <div style={{ textAlign: "left" }}>
              <p>{deleteStockLocationError.errors[0]}</p>
            </div>
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => setDeleteStockLocationErrorOpen(false)}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="tiny" open={addStockLocationOpen}>
        <Modal.Header>
          {stockFormData.id > 0 ? "EDIT" : "ADD"} STOCK LOCATION
        </Modal.Header>
        <div style={{ position: "absolute", bottom: 100, left: 250 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content>
          <Form size="tiny">
            <Form.Field>
              <label>Stock Location Name</label>
              <Input
                className={
                  stockSaved && !stockFormData?.stockLocationName ? "error" : ""
                }
                placeholder="Stock Location Name"
                value={stockFormData?.stockLocationName}
                onChange={(e, data) =>
                  handleStockLocationChange("stockLocationName", data.value)
                }
              />
            </Form.Field>
            <Grid style={{ marginTop: 20 }}>
              <Grid.Column width={8} style={{ paddingRight: 5 }}>
                <Button
                  size="tiny"
                  style={{
                    width: "100%",
                    marginLeft: "auto",
                  }}
                  onClick={() => {
                    setAddStockLocationOpen(false);
                    setStockFormData({});
                    setStockSaved(false);
                  }}
                >
                  CANCEL
                </Button>
              </Grid.Column>
              <Grid.Column width={8} style={{ paddingLeft: 5 }}>
                <Button
                  size="tiny"
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(102, 204, 35)",
                    color: "#fff",
                    marginLeft: "auto",
                  }}
                  onClick={() => {
                    if (stockFormData.id > 0) {
                      onUpdateStockLocation();
                    } else {
                      onSubmitAddStockLocation();
                    }
                  }}
                >
                  SAVE
                </Button>
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
      <Grid
        columns={3}
        divided
        className="order-management"
        style={{ marginTop: -30 }}
      >
        <Grid.Row style={{ paddingTop: 5, zIndex: 1 }}>
          <Grid.Column width={16} style={{ paddingRight: 0 }}>
            {selectedStock?.stockLocationName ? (
              <h3 style={{ marginLeft: 20 }}>
                <img
                  src={inventoryIcon}
                  style={{
                    width: 30,
                    height: 30,
                    marginTop: -5,
                    marginRight: 8,
                  }}
                />{" "}
                Inventory - {selectedStock?.stockLocationName}
              </h3>
            ) : (
              <h3 style={{ marginLeft: 20 }}>
                <img
                  src={inventoryIcon}
                  style={{
                    width: 30,
                    height: 30,
                    marginTop: -5,
                    marginRight: 8,
                  }}
                />{" "}
                Inventory
              </h3>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column
            width={4}
            className="inventory-list-section"
            style={{ paddingRight: 0 }}
          >
            <hr />
            <div
              className="order-status"
              style={{ paddingRight: "0px !important" }}
            >
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    style={{ paddingTop: 8, paddingBottom: 10 }}
                  >
                    <h4>Stock Locations</h4>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <div className="order-list">
              <div style={{ position: "absolute", top: 300, left: 80 }}>
                {searchLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  >
                    Loading Stock Locations...
                  </Loader>
                ) : null}
              </div>
              <div
                {...events}
                ref={ref}
                className="inventory-list-scroll"
                disabled={searchLoading}
                style={{ opacity: searchLoading ? 0.2 : 1, paddingTop: 5 }}
              >
                {stocklocationList?.map((data, index) => {
                  if (localStorage.getItem("userType") === "4" && index !== 0) {
                    return null;
                  }
                  return (
                    <Card
                      className={
                        selectedId === data?.id ? "job-card active" : "job-card"
                      }
                      style={{
                        cursor: "default",
                        minHeight: 65,
                        minWidth: 185,
                        borderRadius: 10,
                        marginBottom: "0px !important",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3)",
                        position: "relative", // Ensure relative positioning
                        cursor: "pointer",
                      }}
                    >
                      <Card.Content
                        style={{
                          minHeight: 60,
                          paddingTop: 30,
                          paddingBottom: 30,
                          paddingRight: 20,
                        }}
                        onClick={() => {
                          if (selectedId !== data?.id) {
                            setSearchTerm("");
                            setSelectedId(data?.id);
                            setSelectedStock(data);
                          } else {
                            setSearchTerm("");
                            setSelectedId(0);
                            setSelectedStock(null);
                          }
                        }}
                      >
                        <Grid>
                          <Grid.Column
                            width={11}
                            style={{
                              paddingTop: 15,
                              paddingBottom: 15,
                              paddingLeft: 20,
                              paddingRight: 0,
                            }}
                          >
                            <h5>{data?.stockLocationName}</h5>
                          </Grid.Column>
                          <Grid.Column
                            width={5}
                            style={{
                              paddingTop:
                                data?.stockLocationType?.toLowerCase() ===
                                "boapod"
                                  ? 16
                                  : 0,
                              paddingBottom: 10,
                              paddingLeft: 15,
                              paddingRight:
                                data?.stockLocationType?.toLowerCase() ===
                                "boapod"
                                  ? 10
                                  : 0,
                            }}
                          >
                            {" "}
                            {data?.stockLocationType?.toLowerCase() ===
                            "boapod" ? (
                              <div
                                style={{
                                  height: "100%",
                                  paddingTop: 5,
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <img
                                  src={boapod}
                                  style={{
                                    height: "auto",
                                    width: "100%",
                                  }}
                                />
                              </div>
                            ) : null}
                          </Grid.Column>
                        </Grid>
                      </Card.Content>
                      {data?.stockLocationType?.toLowerCase() === "boapod" ||
                      localStorage.getItem("userType") === "4" ? null : (
                        <div
                          style={{ position: "absolute", top: 10, right: 5 }}
                        >
                          <Icon
                            style={{ marginRight: 8 }}
                            link
                            name="pencil"
                            onClick={() => {
                              setStockFormData(data);
                              setTimeout(() => {
                                setAddStockLocationOpen(true);
                              }, 300);
                            }}
                          />
                          <Icon
                            style={{ marginRight: 8 }}
                            link
                            name="trash"
                            onClick={() => {
                              setStockFormData(data);
                              setTimeout(() => {
                                setDeleteStockLocationOpen(true);
                              }, 300);
                            }}
                          />
                        </div>
                      )}
                    </Card>
                  );
                })}
              </div>
              {localStorage.getItem("userType") === "4" ? (
                <Grid
                  style={{ marginTop: 25, paddingLeft: 20, paddingRight: 30 }}
                >
                  <Grid.Column
                    width={16}
                    style={{ paddingRight: 5, paddingTop: 0 }}
                  >
                    <Button
                      className="edit-status"
                      size="mini"
                      style={{
                        width: "100%",
                        fontSize: 14,
                        marginTop: 7,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      onClick={() => {
                        setTimeout(function () {
                          props.history.push("/stock-transfer");
                        }, 100);
                      }}
                    >
                      Stock Transfer
                    </Button>
                  </Grid.Column>
                </Grid>
              ) : (
                <Grid
                  style={{ marginTop: 25, paddingLeft: 15, paddingRight: 15 }}
                >
                  <Grid.Column
                    width={8}
                    style={{ paddingRight: 5, paddingTop: 0 }}
                  >
                    <Button
                      className="edit-status"
                      size="mini"
                      style={{
                        width: "100%",
                        fontSize: 14,
                        marginTop: 7,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      onClick={() => {
                        setTimeout(function () {
                          props.history.push("/stock-transfer");
                        }, 100);
                      }}
                    >
                      Stock Transfer
                    </Button>
                  </Grid.Column>
                  <Grid.Column
                    width={8}
                    style={{ paddingLeft: 5, paddingTop: 0 }}
                  >
                    <Button
                      className="active-status"
                      size="mini"
                      style={{
                        width: "100%",
                        fontSize: 14,
                        marginTop: 7,
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                      onClick={() => {
                        setStockFormData({});
                        setTimeout(() => {
                          setAddStockLocationOpen(true);
                        }, 300);
                      }}
                    >
                      Add Stock Location
                    </Button>
                  </Grid.Column>
                </Grid>
              )}
            </div>
          </Grid.Column>
          <Grid.Column width={12} style={{ paddingRight: 0 }}>
            <hr />
            <div className="inventory-form">
              <Grid style={{ paddingLeft: 9, paddingTop: 5 }}>
                <Grid.Column width={5}>
                  <Input
                    value={searchTerm}
                    icon="search"
                    placeholder="Search for your stock item"
                    style={{ width: "100%" }}
                    size="small"
                    onChange={handleSearchChange}
                  />
                </Grid.Column>
                <Grid.Column width={11} style={{ paddingLeft: 0 }}>
                  {localStorage.getItem("userType") === "4" ? (
                    <div className="job-actions">
                      <Button
                        disabled={selectedId === 0}
                        className="active-status"
                        style={{
                          minWidth: 100,
                          marginRight: 10,
                        }}
                        size="small"
                        onClick={() => {
                          setTimeout(function () {
                            props.history.push("/stock-adjustment");
                          }, 100);
                        }}
                      >
                        Stock Adjustment
                      </Button>
                    </div>
                  ) : (
                    <div className="job-actions">
                      <Button
                        disabled={selectedId === 0}
                        className="active-status"
                        style={{
                          minWidth: 100,
                          marginRight: 10,
                        }}
                        size="small"
                        onClick={() => {
                          setTimeout(function () {
                            props.history.push("/stock-adjustment");
                          }, 100);
                        }}
                      >
                        Stock Adjustment
                      </Button>
                      <Button
                        disabled={selectedId === 0}
                        className="default-status"
                        style={{
                          minWidth: 100,
                          marginRight: 10,
                        }}
                        size="small"
                        onClick={() => setBulkAdjustmentOpen(true)}
                      >
                        Bulk Adjustment
                      </Button>
                      <Button
                        disabled={selectedId === 0}
                        className="active-status"
                        style={{
                          minWidth: 100,
                        }}
                        size="small"
                        onClick={() => onGetStockLocationReport()}
                      >
                        Stock Report
                      </Button>
                    </div>
                  )}
                </Grid.Column>
              </Grid>
              {selectedStock?.id ? (
                <div>
                  <div style={{ position: "absolute", top: 330, left: 480 }}>
                    <Loader size="massive" active={dataLoading} />
                  </div>
                  <div
                    className="order-form-content"
                    style={{
                      paddingRight: "0px !important",
                      opacity: dataLoading ? 0.5 : 1,
                      height:
                        localStorage.getItem("userType") === "4" ? 620 : 500,
                    }}
                  >
                    <Grid
                      style={{
                        padding: 10,
                        paddingBottom: 50,
                      }}
                    >
                      {productList?.records?.map((card, index) => {
                        const isExpanded = expandedCardIds.includes(card.id);
                        return (
                          <Grid.Column
                            width={16}
                            style={{ paddingBottom: 0 }}
                            key={card.id}
                          >
                            <Card style={{ width: "100%" }}>
                              <Card.Content style={{ paddingBottom: 5 }}>
                                <Card.Header
                                  style={{ paddingBottom: 15, fontSize: 16 }}
                                >
                                  {card.barCode}
                                </Card.Header>
                                <Grid>
                                  <Grid.Column
                                    width={2}
                                    style={{ paddingBottom: 0 }}
                                  >
                                    <Image
                                      style={{
                                        width: 85,
                                        height: 85,
                                      }}
                                      src={
                                        card.image128
                                          ? `data:image/jpeg;base64,${card.image128}`
                                          : productDefaultImage
                                      }
                                    />
                                  </Grid.Column>
                                  <Grid.Column
                                    width={14}
                                    style={{ paddingBottom: 0 }}
                                  >
                                    <Grid>
                                      <Grid.Column
                                        width={6}
                                        style={{ paddingBottom: 0 }}
                                      >
                                        <span style={{ fontSize: 14 }}>
                                          {card.name}
                                        </span>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={10}
                                        style={{ paddingBottom: 0 }}
                                      ></Grid.Column>
                                      <Grid.Column
                                        width={3}
                                        style={{ paddingBottom: 0 }}
                                      >
                                        <span style={{ fontSize: 15 }}>
                                          Available:{" "}
                                        </span>
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color:
                                              card.inventoryData?.available > 0
                                                ? "#66CC23"
                                                : "#FF0000",
                                          }}
                                        >
                                          {card.inventoryData?.available
                                            ? card.inventoryData?.available
                                            : 0}
                                        </span>
                                      </Grid.Column>
                                      {!isExpanded ? (
                                        <Grid.Column
                                          width={13}
                                          style={{ paddingBottom: 0 }}
                                        ></Grid.Column>
                                      ) : (
                                        <>
                                          <Grid.Column
                                            width={4}
                                            style={{
                                              padding: 0,
                                              paddingTop: "1em",
                                            }}
                                          >
                                            <span style={{ fontSize: 15 }}>
                                              Incoming:{" "}
                                            </span>
                                            <span
                                              style={{
                                                color: "#66CC23",
                                              }}
                                            >
                                              {card.inventoryData?.incoming}
                                            </span>
                                            {card.inventoryData?.movementDetails?.map(
                                              (data, index) => {
                                                if (
                                                  data?.moveType?.toLowerCase() ===
                                                  "incoming"
                                                ) {
                                                  return (
                                                    <div
                                                      style={{
                                                        fontSize: 11,
                                                        paddingTop: 3,
                                                      }}
                                                    >
                                                      <span>
                                                        {data.moveName}:{" "}
                                                      </span>
                                                      <span
                                                        style={{
                                                          color: "#66CC23",
                                                        }}
                                                      >
                                                        {data?.qty
                                                          ? data?.qty
                                                          : 0}
                                                        {" - "}
                                                      </span>
                                                      <span>
                                                        {`(${
                                                          data?.typeId === 0
                                                            ? "ADJUSTMENT"
                                                            : data?.typeId === 1
                                                            ? "TRANSFER"
                                                            : data?.typeId === 2
                                                            ? "ORDER"
                                                            : data?.typeId === 3
                                                            ? "JOBBING"
                                                            : "RETURN"
                                                        })`}
                                                      </span>
                                                    </div>
                                                  );
                                                }
                                              }
                                            )}
                                          </Grid.Column>
                                          <Grid.Column
                                            width={4}
                                            style={{
                                              padding: 0,
                                              paddingTop: "1em",
                                            }}
                                          >
                                            <span style={{ fontSize: 15 }}>
                                              Outgoing:{" "}
                                            </span>
                                            <span
                                              style={{
                                                color: "#66CC23",
                                              }}
                                            >
                                              {card.inventoryData?.outgoing}
                                            </span>
                                            {card.inventoryData?.movementDetails?.map(
                                              (data, index) => {
                                                if (
                                                  data?.moveType?.toLowerCase() ===
                                                  "outgoing"
                                                ) {
                                                  return (
                                                    <div
                                                      style={{
                                                        fontSize: 11,
                                                        paddingTop: 3,
                                                      }}
                                                    >
                                                      <span>
                                                        {data.moveName}:{" "}
                                                      </span>
                                                      <span
                                                        style={{
                                                          color: "#66CC23",
                                                        }}
                                                      >
                                                        {data?.qty
                                                          ? data?.qty
                                                          : 0}
                                                        {" - "}
                                                      </span>
                                                      <span>
                                                        {`(${
                                                          data?.typeId === 0
                                                            ? "ADJUSTMENT"
                                                            : data?.typeId === 1
                                                            ? "TRANSFER"
                                                            : data?.typeId === 2
                                                            ? "ORDER"
                                                            : data?.typeId === 3
                                                            ? "JOBBING"
                                                            : "RETURN"
                                                        })`}
                                                      </span>
                                                    </div>
                                                  );
                                                }
                                              }
                                            )}
                                          </Grid.Column>
                                          <Grid.Column
                                            width={4}
                                            style={{
                                              paddingBottom: 0,
                                              paddingRight: 0,
                                            }}
                                          >
                                            <Grid>
                                              <Grid.Column
                                                width={8}
                                                style={{
                                                  paddingBottom: 0,
                                                  paddingRight: 0,
                                                }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  Min:{" "}
                                                </span>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "#66CC23",
                                                  }}
                                                >
                                                  {card.inventoryData?.min
                                                    ? card.inventoryData?.min
                                                    : 0}
                                                </span>
                                              </Grid.Column>
                                              <Grid.Column
                                                width={8}
                                                style={{
                                                  paddingBottom: 0,
                                                  paddingRight: 0,
                                                }}
                                              >
                                                <span style={{ fontSize: 15 }}>
                                                  Max:{" "}
                                                </span>
                                                <span
                                                  style={{
                                                    fontSize: 15,
                                                    color: "#66CC23",
                                                  }}
                                                >
                                                  {card.inventoryData?.max
                                                    ? card.inventoryData?.max
                                                    : 0}
                                                </span>
                                              </Grid.Column>
                                              <Grid.Column
                                                width={16}
                                                style={{
                                                  paddingBottom: 0,
                                                  paddingRight: 0,
                                                }}
                                              >
                                                {localStorage.getItem(
                                                  "userType"
                                                ) === "4" ? null : (
                                                  <Button
                                                    className="active-status"
                                                    size="mini"
                                                    style={{
                                                      width: "80%",
                                                      fontSize: 12,
                                                      marginTop: 7,
                                                      paddingLeft: 0,
                                                      paddingRight: 0,
                                                    }}
                                                    onClick={() => {
                                                      setAdjustMinMaxOpen(true);
                                                      setSelectedProduct(card);
                                                    }}
                                                  >
                                                    Adjust Min/Max
                                                  </Button>
                                                )}
                                              </Grid.Column>
                                            </Grid>
                                          </Grid.Column>
                                        </>
                                      )}
                                    </Grid>
                                  </Grid.Column>
                                </Grid>
                                <Grid style={{ paddingTop: 20 }}>
                                  <AnimatePresence>
                                    <motion.div
                                      initial={{ maxHeight: 0 }}
                                      animate={{
                                        maxHeight: isExpanded ? 500 : 50,
                                      }}
                                      exit={{ maxHeight: 50 }}
                                      transition={{ duration: 0.6 }}
                                      style={{
                                        overflow: "hidden",
                                        width: "100%",
                                      }}
                                    >
                                      {isExpanded ? (
                                        <Grid>
                                          {card.boahubFullCarton > 0 ? (
                                            <Grid.Column width={3}>
                                              <Form
                                                model=""
                                                style={{ textAlign: "center" }}
                                              >
                                                <div
                                                  style={{ paddingTop: 44.5 }}
                                                >
                                                  <label
                                                    style={{ fontSize: 12 }}
                                                  >
                                                    {card.productUom === 1
                                                      ? "Box"
                                                      : "Roll"}{" "}
                                                    size:{" "}
                                                    {card.boahubFullCarton}
                                                  </label>
                                                  <Button
                                                    fluid
                                                    size="small"
                                                    onClick={() =>
                                                      addBoxRollHandler(
                                                        card,
                                                        card.boahubFullCarton
                                                      )
                                                    }
                                                  >
                                                    <Button.Content visible>
                                                      ADD{" "}
                                                      {card.boahubFullCarton}
                                                    </Button.Content>
                                                  </Button>
                                                </div>
                                              </Form>
                                            </Grid.Column>
                                          ) : null}
                                          <Grid.Column width={3}>
                                            <Form
                                              model=""
                                              style={{ textAlign: "center" }}
                                            >
                                              <Quantity
                                                pack={null}
                                                quantity={card.quantity}
                                                index={index}
                                                uomId={card.uomData?.id}
                                              />
                                              <Button
                                                fluid
                                                animated="vertical"
                                                size="small"
                                                color="black"
                                                onClick={() =>
                                                  addToCartHandler(card)
                                                }
                                              >
                                                <Button.Content hidden>
                                                  <Icon name="cart" />
                                                </Button.Content>
                                                <Button.Content visible>
                                                  Add to cart
                                                </Button.Content>
                                              </Button>
                                            </Form>
                                          </Grid.Column>
                                          <Grid.Column
                                            width={16}
                                            style={{
                                              paddingTop: 5,
                                              minHeight: 100,
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 18,
                                                fontWeight: "bold",
                                              }}
                                            >
                                              Other Stock Locations
                                            </span>
                                            <Grid style={{ paddingTop: 10 }}>
                                              {card.inventoryPerStockLocation?.map(
                                                (data, index) => {
                                                  if (
                                                    selectedId !==
                                                    data.stockLocationId
                                                  ) {
                                                    const stockDetails =
                                                      stocklocationList.find(
                                                        (stock) =>
                                                          stock?.id ===
                                                          data.stockLocationId
                                                      );
                                                    if (stockDetails)
                                                      return (
                                                        <Grid.Column width={3}>
                                                          <div>
                                                            {
                                                              stockDetails?.stockLocationName
                                                            }
                                                          </div>
                                                          <div>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                              }}
                                                            >
                                                              Available:{" "}
                                                            </span>
                                                            <span
                                                              style={{
                                                                fontSize: 15,
                                                                color:
                                                                  data?.qty > 0
                                                                    ? "#66CC23"
                                                                    : "#FF0000",
                                                              }}
                                                            >
                                                              {data?.qty}
                                                            </span>
                                                          </div>
                                                          <div>
                                                            <Button
                                                              className="edit-status"
                                                              size="mini"
                                                              style={{
                                                                width: "100%",
                                                                fontSize: 14,
                                                                marginTop: 7,
                                                                paddingLeft: 0,
                                                                paddingRight: 0,
                                                              }}
                                                              onClick={() => {
                                                                setTimeout(
                                                                  function () {
                                                                    props.history.push(
                                                                      `/stock-transfer/${data.stockLocationId}/${selectedId}/${card.id}`
                                                                    );
                                                                  },
                                                                  100
                                                                );
                                                              }}
                                                            >
                                                              Create Transfer
                                                            </Button>
                                                          </div>
                                                        </Grid.Column>
                                                      );
                                                  }
                                                }
                                              )}
                                            </Grid>
                                          </Grid.Column>
                                        </Grid>
                                      ) : null}
                                    </motion.div>
                                  </AnimatePresence>
                                </Grid>
                                <div
                                  style={{ textAlign: "center", marginTop: 15 }}
                                >
                                  <span
                                    onClick={() => toggleExpand(card.id)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    {isExpanded ? "Show Less" : "More Info"}
                                  </span>
                                </div>
                              </Card.Content>
                            </Card>
                          </Grid.Column>
                        );
                      })}
                    </Grid>
                  </div>
                  <div>
                    {productList.length ? (
                      <Pagination
                        disabled={dataLoading}
                        style={{ paddingTop: 15 }}
                        activePage={page}
                        boundaryRange={1}
                        onPageChange={handlePaginationChange}
                        size="small"
                        totalPages={Math.ceil(productList.length / 10)}
                        ellipsisItem={true}
                        firstItem={null}
                        lastItem={null}
                      />
                    ) : null}
                  </div>
                  {localStorage.getItem("userType") === "4" ? null : (
                    <>
                      <hr />
                      <div style={{ paddingTop: 15 }}>
                        <h4 style={{ fontWeight: "bolder", lineHeight: 0.5 }}>
                          Min/Max
                        </h4>
                        <Grid
                          style={{
                            marginTop: 0,
                            paddingTop: 0,
                            paddingLeft: 15,
                            paddingRight: 15,
                          }}
                        >
                          <Grid.Column
                            width={3}
                            style={{ paddingRight: 5, paddingTop: 0 }}
                          >
                            <Button
                              className="active-status"
                              size="mini"
                              style={{
                                width: "100%",
                                fontSize: 14,
                                marginTop: 7,
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                              onClick={() => setMinMaxReportOpen(true)}
                            >
                              Min/Max Report
                            </Button>
                          </Grid.Column>
                          <Grid.Column
                            width={3}
                            style={{ paddingLeft: 5, paddingTop: 0 }}
                          >
                            <Button
                              className="default-status"
                              size="mini"
                              style={{
                                width: "100%",
                                fontSize: 14,
                                marginTop: 7,
                                paddingLeft: 0,
                                paddingRight: 0,
                              }}
                              onClick={() => setMinMaxOpen(true)}
                            >
                              Set Min/Max
                            </Button>
                          </Grid.Column>
                        </Grid>
                      </div>
                    </>
                  )}
                </div>
              ) : dataLoading ? (
                <Skeleton count={30} />
              ) : null}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    userInfo: state.auth.user,
  };
};

const reducer = { ...cartReducer, ...searchReducer };

const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryManagement);
