import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as home from "./home.reducer";
import MetaData from "../../components/MetaData";
import EnterpriseHome from "./Enterprise";
import "./home.css";

const Home = (props) => {
  const goToPage = (text) => {
    props.history.push(`/${text}`);
  };

  const account =
    Object.entries(props.user).length === 1 ? props.user.user : props.user;

  return (
    <div>
      <MetaData title="Home" />
      {/* <EnterpriseHome
        {...props}
        goToPage={(page) => goToPage(page)}
        account={account}
      /> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    discountStatistics: state.home.discountStatistics,
    user: state.auth.user,
    emailSent: state.home.emailSent,
    totalSavings: state.home.totalSavings,
  };
};

const reducer = { ...home };
const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
