import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { APP_NAME } from "../../constants/appConstants";

const MetaData = ({ title }) => {
  return (
    <Helmet>
      <title>
        {" "}
        {title} | {APP_NAME}{" "}
      </title>
    </Helmet>
  );
};
MetaData.propTypes = {
  title: PropTypes.string
};
export default MetaData;
