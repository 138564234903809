import React from "react";
import { Card, Image, Grid } from "semantic-ui-react";
import productDefaultImage from "../assets/images/product_default.png";
import placeHolderImage from "../assets/images/placeholderImage.png";

const hoseResult = [{ text: "loading" }];

const HoseDetailsLoaderCard = () => {
  return (
    <React.Fragment>
      {hoseResult.map((hose, key) => {
        return (
          <Card
            link
            style={{ width: "100%" }}
            key={key}
            className="product-card"
          >
            <Card.Content textAlign="left">
              <Card.Header>Loading Hose</Card.Header>
              <Card.Description className="card-description">
                <Grid>
                  <Grid.Column width={2}>
                    <Image src={productDefaultImage} />
                  </Grid.Column>
                  <Grid.Column width={14}>
                                <Image alt="placeholder" style={{ height: "80px", width:"100%" }} src={placeHolderImage} />
                  </Grid.Column>
                </Grid>
              </Card.Description>
            </Card.Content>
          </Card>
        );
      })}
    </React.Fragment>
  );
};

export default HoseDetailsLoaderCard;
