import { combineForms } from "react-redux-form";
import {
  login,
  createAccount,
  createContact,
  forgotPassword,
  resetPassword,
  profileInfo,
  changePassword,
} from "./containers/Auth/auth.options";
import { cafForm } from "./containers/CafForm/caf.options";
import { asset } from "./containers/AssetForm/asset.options";
import { location } from "./containers/AssetLocation/assetLocation.options";
import { crew, member } from "./containers/CrewManagement/crew.options";
import { stock } from "./containers/StockForm/stock.options";
import {
  maintenanceForm,
  checkPoint,
} from "./containers/MaintenanceForm/maintenanceForm.options";
import { schedule } from "./containers/Schedule/schedule.options";
import {
  transferLocation,
  sellStock,
  consumeStock,
  attachToAsset,
} from "./containers/Inventory/inventroy.options";

import {
  createChatGroup,
  createVoiceMessage,
} from "./containers/Chat/chat.options";

export const allForms = combineForms(
  {
    loginForm: login,
    createContactForm: createContact,
    createAccountForm: createAccount,
    forgotPasswordForm: forgotPassword,
    resetPasswordForm: resetPassword,
    profileInfoForm: profileInfo,
    cafForm: cafForm,
    changePasswordForm: changePassword,
    assetForm: asset,
    locationForm: location,
    crewForm: crew,
    memberForm: member,
    stockForm: stock,
    maintenanceForm: maintenanceForm,
    checkPointForm: checkPoint,
    scheduleForm: schedule,
    transferLocationForm: transferLocation,
    sellStockForm: sellStock,
    consumeStockForm: consumeStock,
    attachToAssetForm: attachToAsset,

    chatForm: createChatGroup,
    voiceData: createVoiceMessage,
  },
  "forms"
);
