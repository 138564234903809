import { actions } from "react-redux-form";
import {
  apiUpdateStock,
  apiAddStock,
  apiGetStock,
  apiBoaStockAutoComplete
} from "./stock.api";

const ADD_STOCK_ITEM = "ADD_STOCK_ITEM";
const UPDATE_STOCK_ITEM = "UPDATE_STOCK_ITEM";
const SET_LOCATION = "SET_LOCATION";
const CLEAR_ITEM_ARRAY = "CLEAR_ITEM_ARRAY";
const SET_STOCK_ITEMS = "SET_STOCK_ITEMS";
const SET_AUTO_COMPLETE = "SET_AUTO_COMPLETE";
const SET_CATEGORIES = "SET_CATEGORIES";

const initialState = {
  allStock: [],
  response: {},
  locationName: "",
  categories: [],
  autoCompleteResult: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STOCK_ITEM: {
      return { ...state };
    }
    case ADD_STOCK_ITEM: {
      return {
        ...state,
        response: action.payload
      };
    }
    case SET_STOCK_ITEMS: {
      return {
        ...state,
        allStock: action.payload
      };
    }
    case SET_LOCATION: {
      return {
        ...state,
        locationName: action.payload
      };
    }
    case CLEAR_ITEM_ARRAY: {
      return {
        ...state,
        allStock: []
      };
    }

    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload
      };
    }
    case SET_AUTO_COMPLETE: {
      return {
        ...state,
        autoCompleteResult: action.payload
      };
    }

    default:
      return { ...state };
  }
};

export const actionGetStock = (location, limit, pageNumber) => {
  return async dispatch => {
    try {
      const result = await apiGetStock(location, limit, pageNumber);
      dispatch({ type: SET_STOCK_ITEMS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_STOCK_ITEMS, payload: e });
    }
  };
};

export const actionAddStock = data => {
  return async dispatch => {
    try {
      const result = await apiAddStock(data);
      dispatch({ type: ADD_STOCK_ITEM, payload: result });
    } catch (e) {
      dispatch({ type: ADD_STOCK_ITEM, payload: e });
    }
  };
};

export const actionUpdateStock = data => {
  return async dispatch => {
    try {
      const result = await apiUpdateStock(data);
      dispatch({ type: UPDATE_STOCK_ITEM, payload: result.response });
    } catch (e) {
      dispatch({ type: UPDATE_STOCK_ITEM, payload: e.response });
    }
  };
};

export const actionSetInitialForm = form => {
  return dispatch => {
    dispatch(actions.change("forms.stockForm", form));
  };
};

export const actionSetQuantity = (name, field) => {
  return dispatch => {
    dispatch(actions.change("forms." + name, field));
  };
};

export const actionBOAStockSetFormField = (name, field) => {
  return dispatch => {
    dispatch(actions.change("forms." + name, field));
  };
};

export const actionResetStockForm = () => {
  return dispatch => {
    dispatch(actions.reset("forms.stockForm"));
  };
};

export const actionSetStockFormField = (field, value) => {
  return dispatch => {
    dispatch(actions.change("forms.stockForm." + field, value));
  };
};

export const actionLocationName = location => {
  return dispatch => {
    dispatch({ type: SET_LOCATION, payload: location });
  };
};

export const actionClearReducerItems = () => {
  return dispatch => {
    dispatch({ type: CLEAR_ITEM_ARRAY, payload: [] });
  };
};

export const actionSetRadioButtonField = (name, field) => {
  return dispatch => {
    dispatch(actions.change("forms." + name, field));
  };
};

export const actionBoaStockAutoComplete = (searchTerm, level) => {
  return async dispatch => {
    try {
      const result = await apiBoaStockAutoComplete(searchTerm, level);
      dispatch({ type: SET_AUTO_COMPLETE, payload: result.data.result });
    } catch (e) {
      dispatch({ type: SET_AUTO_COMPLETE, payload: e.msg });
    }
  };
};
