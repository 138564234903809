import React from "react";
import PropTypes from "prop-types";
import { Segment } from 'semantic-ui-react';

const ResultConversationRenderer = ({
  friendlyName,
  sid
}) => {
  return (
   
      <Segment key={sid}>
        <h4>Chat :  {friendlyName} </h4>
        {/* <h4>Role :  {userRole} </h4> */}
      </Segment>



  );
};

ResultConversationRenderer.propTypes = {
  friendlyName: PropTypes.string,
  sid: PropTypes.string,

};

export default ResultConversationRenderer;