import React from 'react';
import { Modal, Form,Image, Button} from 'semantic-ui-react'
import { Control, Errors } from "react-redux-form";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as chatReducer from "./chat.reducer";


const polyglot = window.polyglot;


class EditGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

            showModal: false,
            channelIcon: null,
            file: null,

            errorFlag: false,
            errorMessage: "",

            conversationId: '',
            chatTitle: '',
            isLoading: false

        }
    }
    closeModal = () => {
        this.setState({ showModal: false })
    }



    getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                //console.log("Called", reader);
                baseURL = reader.result;
                //console.log(baseURL);
                resolve(baseURL);
            };
            //console.log(fileInfo);
        });
    };

    onChangeImage = (e) => {
        this.setState({ isLoading: true })
        //console.log(e.target.files[0]);
        let { file } = this.state;

        file = e.target.files[0];

        this.getBase64(file)
            .then((result) => {
                file["base64"] = result;
               //console.log("File Is", file);
                this.setState({
                    base64URL: result,
                    file
                }, () => {
                    const formValue = {
                        ...this.props.forms.chatForm,
                        channelIcon: this.state.base64URL
                    };
                    this.props.actionSetFormValue(formValue);
                });
            })
            .catch((err) => {
                //console.log(err);
            });

        this.setState({
            file: e.target.files[0]
        });


        this.setState({ isLoading: false })
    };




    resetFile = (event) => {
        event.preventDefault();
        this.setState({ file: null });
    }



    //=============================================================

    openModal = () => {
        const { conversationDetails, forms } = this.props;
     
        this.setState({ showModal: true, });



        const formData = {
            ...forms.chatForm,
            chatTitle: conversationDetails.chatDetails.chatTitle,
            channelIcon: conversationDetails.chatDetails.channelIcon,
        }
        this.props.actionSetFormValue(formData)

    };



    handleUpdateGroup = async () => {

        const { conversationDetails } = this.props;

        this.setState({ isLoading: true });
        const { chatForm } = this.props.forms;

        if (chatForm.channelIcon == conversationDetails.chatDetails.channelIcon) {

            const data = {
                ...chatForm,
                channelIcon: null,
                conversationId: conversationDetails.sid,
            };

            await this.props.actionUpdateChatGroup(data);

        }
        else {

            const data = {
                ...chatForm,
                conversationId: conversationDetails.sid,
            };

            await this.props.actionUpdateChatGroup(data);

        }

        this.setState({ isLoading: false });


        //const { response } = this.props;
        this.props.getConversationDetails(conversationDetails.sid)
        // console.log("Chat group update status :" + JSON.stringify(response))
     

        // if (response.status === 200) {
        //     // window.scrollTo(0, 0);
        //     // this.updateState({
        //     //   success: true
        //     // });
        //     showSuccessToast(`Conversation updated successfully!!`);
        // }
        // else {
        //     showErrorToast("Something went wrong!!!")
        // }

      

        this.props.actionResetForm("chatForm")
        this.closeModal();

    };



    render() {
        const {

            showModal,
            isLoading
        } = this.state


        const { forms } = this.props;
        return (
            <div>
                <a href="#" className="edit_userinfo" onClick={this.openModal}><i class="boa ba-edit"></i></a>
                <Modal className="custom_modal" closeIcon size='tiny' onClose={this.closeModal} open={showModal} >
                    <Modal.Header>Edit Group</Modal.Header>
                    <Modal.Content >
                        <Modal.Description>
                            <div className="fileupload_wrap">
                                <div className="fileupload_input">
                                    <div className="upload_img"></div>
                                    <Button className="button_uplaod" ><i className="boa ba-camera3"></i> </Button>
                                    <input
                                        type="file"
                                        onChange={this.onChangeImage} accept="image/*" />
                              
                                </div>
                                <div className="fileupload_label">
                                    <h4>Upload Group Image</h4>
                                    <p>Upload valid image. Only PNG and JPEG are allowed.</p>
                                </div>
                                <div style={{ width: "100px", height: "100px", border: "2px solid grey", borderRadius: "50px" }}>
                                    <Image size='medium' src={forms.chatForm.channelIcon} style={{ width: "100px", height: "100px", border: "2px solid grey", borderRadius: "50px" }} />
                            
                                </div>
                            </div>
                            <div className="custom-form mt-2 mb-2">
                                <Form
                                    error={this.state.errorFlag}
                                    className="ui form"
                                    model="forms.chatForm"
                                >
          
                                    <Form.Field required>
                                        <label>{polyglot.t("chatForm.chatTitle")}</label>
                                        <Control
                                            type="text"
                                            model="forms.chatForm.chatTitle"
                                            placeholder={polyglot.t("chatForm.chatTitle")}
                                            required
                                            validateOn="change"
                                            className="input-box"
                                        />
                                        <Errors
                                            style={{ color: "red" }}
                                            model="forms.chatForm.chatTitle"
                                            show="touched"
                                            messages={{
                                                valueMissing: "Group subject is required",
                                                typeMismatch: "Invalid Group subject"
                                            }}
                                        />
                                    </Form.Field>

                                </Form>
                            </div>

                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions className="modal_footer">
                        <Button basic color='black' onClick={this.closeModal}>Back </Button>
                        <Button loading={isLoading} color='green' onClick={this.handleUpdateGroup}
                        >Update Group </Button>

                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}


const reducer = { ...chatReducer };


const mapStateToProps = state => {
    return {
        chatGroups: state.chatGroups,
        response: state.chatGroups.response,
        forms: state.forms,



    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(reducer, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(EditGroupModal)
);



