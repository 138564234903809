import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";

const SpiralInfo = ({ spiralInfo = {}, removeSpiral, hoseLength = 1 }) => {
  const { stockCode = "" } = spiralInfo;

  return (
    <React.Fragment>
      <Segment className="fitter-info">
        <Grid columns="equal">
          <Grid.Column>Spiral Guard</Grid.Column>
          <Grid.Column>{stockCode} </Grid.Column>
          <Grid.Column>Length : {Math.round(hoseLength)} mm</Grid.Column>
          <Grid.Column>
            <div className="remove-fitter" onClick={removeSpiral}>
              x Remove
            </div>
          </Grid.Column>
        </Grid>
      </Segment>
    </React.Fragment>
  );
};

SpiralInfo.propTypes = {
  spiralInfo: PropTypes.object,
  removeSpiral: PropTypes.func,
  hoseLength: PropTypes.number
};

export default SpiralInfo;
