import React from "react";
import PropTypes from "prop-types";
import { Segment } from 'semantic-ui-react';

const resultRenderer = ({
  firstName,
  
  lastName,
  _id
}) => {
  return (
   
      <Segment key={_id}>
        <h4>Name :  {firstName} {" "} {lastName}</h4>
      
      </Segment>



  );
};

resultRenderer.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  _id: PropTypes.string,
};

export default resultRenderer;