import React from "react";
import PropTypes from "prop-types";
import { Segment, Grid } from "semantic-ui-react";

const HoseInfo = ({ hoseInfo = {}, clearHose, hoseLength = 1 }) => {
  const { stockCode } = hoseInfo;
  return (
    <React.Fragment>
      <Segment className="hose-info">
        <Grid columns="equal" >
          <Grid.Column >Hose</Grid.Column>
          <Grid.Column >{stockCode} </Grid.Column>
          <Grid.Column >Length: {Math.round(hoseLength)} mm</Grid.Column>
          <Grid.Column onClick={clearHose} >
            <div className="clear-hose"> Clear hose </div>
          </Grid.Column>
        </Grid>
      </Segment>
    </React.Fragment>
  );
};

HoseInfo.prototype = {
  hoseInfo: PropTypes.object,
  clearHose: PropTypes.func,
  hoseLength: PropTypes.number
};

export default HoseInfo;
