import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import HISDropdown from "./hisDropdown";
import "./arrangement.css";
import HoseAssembly from "./hoseAssembly";
import { HISfetchDataCallback } from "./hisAPIprovider";

const Arrangement = () => {
  const [modelSearch, setModelSearch] = useState("");
  const [models, setModels] = useState(null);

  const [selectedModel, setSelectedModel] = useState(null);
  const [serNoPrefix, setSerNoPrefix] = useState({ data: [] });

  const [selectedSerNoPrefix, setSelectedSerNoPrefix] = useState(null);
  const [serNoRange, setSerNoRange] = useState({ data: [] });

  const [selectedSerNoRange, setSelectedSerNoRange] = useState(null);
  const [arrangements, setArrangements] = useState({ data: [] });

  const [selectedArrangement, setSelectedArrangement] = useState(null);
  const [arrangementAssemblies, setArrangementAssemblies] = useState({
    data: [],
  });

  const [selectedArrangementAssembly, setSelectedArrangementAssembly] =
    useState(null);

  const handleSearch = debounce((searchTerm) => {
    setModels(null);
    setSerNoPrefix({ data: [] });
    setSelectedSerNoPrefix(null);
    setSerNoRange({ data: [] });
    setSelectedSerNoRange(null);
    setArrangements({ data: [] });
    setSelectedArrangement(null);
    setArrangementAssemblies({ data: [] });
    setSelectedArrangementAssembly(null);
    if (searchTerm !== "") {
      setModelSearch(searchTerm);
    }
  }, 1000);

  const handleChange = (event) => {
    const searchTerm = event.target.value;
    handleSearch(searchTerm);
  };

  useEffect(() => {
    if (modelSearch !== "") {
      HISfetchDataCallback(
        "/his/model",
        { search_param: modelSearch },
        setModels
      );
    }
  }, [modelSearch]);

  useEffect(() => {
    if (selectedModel) {
      HISfetchDataCallback(
        "/his/ser_no_prefix",
        { model: selectedModel },
        setSerNoPrefix
      );
    }
  }, [selectedModel]);

  useEffect(() => {
    if (selectedSerNoPrefix) {
      HISfetchDataCallback(
        "/his/ser_no_range",
        { model: selectedModel, ser_no_prefix: selectedSerNoPrefix },
        setSerNoRange
      );
    }
  }, [selectedSerNoPrefix]);

  useEffect(() => {
    if (selectedSerNoRange) {
      HISfetchDataCallback(
        "/his/arrangement",
        {
          model: selectedModel,
          ser_no_prefix: selectedSerNoPrefix,
          ser_no_range: selectedSerNoRange,
        },
        setArrangements
      );
    }
  }, [selectedSerNoRange]);

  useEffect(() => {
    if (selectedArrangement) {
      HISfetchDataCallback(
        "/his/arrangement_assemblies",
        { arrangement_id: selectedArrangement.arrangement_id },
        setArrangementAssemblies
      );
    }
  }, [selectedArrangement]);

  const selectModel = (model) => {
    setSelectedModel(model);
    setSerNoPrefix({ data: [] });
    setSelectedSerNoPrefix(null);
    setSerNoRange({ data: [] });
    setSelectedSerNoRange(null);
    setArrangements({ data: [] });
    setSelectedArrangement(null);
    setArrangementAssemblies({ data: [] });
  };

  const selectSerNoPrefix = (prefix) => {
    setSelectedSerNoPrefix(prefix);
    setSerNoRange({ data: [] });
    setSelectedSerNoRange(null);
    setArrangements({ data: [] });
    setSelectedArrangement(null);
    setArrangementAssemblies({ data: [] });
  };

  const selectSerNoRange = (range) => {
    setSelectedSerNoRange(range);
    setArrangements({ data: [] });
    setSelectedArrangement(null);
    setArrangementAssemblies({ data: [] });
  };

  const selectArrangement = (arrangement) => {
    setSelectedArrangement(arrangement);
    setArrangementAssemblies({ data: [] });
  };

  return (
    <div style={{ display: "flex" }}>
      <div>
        <div style={{ marginBottom: "20px" }}>
          <input
            className="model_input"
            type="text"
            placeholder="Type caterpillar model to search..."
            onChange={handleChange}
          />
        </div>

        {models !== null && models.data.length === 0 && (
          <div className="no_result">No Model Found!</div>
        )}
        <HISDropdown
          title="Asset Model"
          dataList={models}
          onSelect={selectModel}
        />
        <HISDropdown
          title="Serial No Prefix"
          dataList={serNoPrefix}
          onSelect={selectSerNoPrefix}
        />
        <HISDropdown
          title="Serial No Range"
          dataList={serNoRange}
          onSelect={selectSerNoRange}
        />
      </div>

      {arrangements.data.length > 0 && (
        <div style={{ display: "flex" }}>
          <div class="vertical-divider"></div>
          <div>
            <div className="arrangement_label">
              Arrangements ({arrangements.data.length})
            </div>
            <div className="arrangement_list">
              {arrangements.data.map((item) => (
                <div
                  key={item.arrangement_id}
                  className={`arrangement_item ${
                    selectedArrangement &&
                    selectedArrangement.arrangement_id === item.arrangement_id
                      ? "selected_item"
                      : "normal_item"
                  }`}
                  onClick={() => selectArrangement(item)}
                >
                  {item.arrangement_id} - {item.arrangement_name}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {arrangementAssemblies.data.length > 0 && (
        <div style={{ display: "flex" }}>
          <div class="vertical-divider"></div>
          <div>
            <div className="arrangement_label">
              Hose Assemblies ({arrangementAssemblies.data.length})
            </div>
            <div className="assembly_list">
              {arrangementAssemblies.data.map((item) => (
                <HoseAssembly hose_assembly={item} />
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Arrangement;
