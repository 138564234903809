import React, { useState, useEffect } from "react";
import { Grid, Icon, Loader } from "semantic-ui-react";
import MetaData from "../../components/MetaData";
import TopicChoice from "./topicChoice";
import ContentViewList from "./contentCardList";
import { apiGetMyResources } from "./myResources.api";
import { showErrorToast } from "../../utils/toastUtils";

const MyResources = () => {
  const [resources, setResources] = useState([]);
  const [subTopics, setSubTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const getMyResources = async () => {
    try {
      const response = await apiGetMyResources();
      if (!response)
        throw new Error("Failed to get resources. Please try again.");
      setResources(response.resources);
      setSelectedTopic(response.resources[0].topicId);
      setSubTopics(response.resources[0].subtopics);
      setIsLoading(false);
    } catch (error) {
      showErrorToast("Failed to get resources. Please try again.");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getMyResources();
  }, []);

  const handleSubTopicsChange = (newSubTopics) => {
    setSubTopics(newSubTopics);
  };

  if (isLoading)
    return (
      <Loader active className="workaround" size="large" inline="centered">
        Loading My Resources...
      </Loader>
    );

  return (
    <React.Fragment>
      <MetaData title="My Resources" />
      <Grid columns={16} divided style={{ marginTop: -30 }}>
        <Grid.Row style={{ paddingTop: 0, zIndex: 1, paddingLeft: "50px" }}>
          <h3>
            <Icon name="file" /> My Resources
          </h3>
        </Grid.Row>
        {selectedTopic && (
          <TopicChoice
            currentTopic={selectedTopic}
            topics={resources}
            onSubTopicsChange={handleSubTopicsChange}
          />
        )}
        <ContentViewList selectedTopic={selectedTopic} subTopics={subTopics} />
      </Grid>
    </React.Fragment>
  );
};

export default MyResources;
