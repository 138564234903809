export const cafForm = {
    formName: "",
    owner: "",
    customerName: "",
    postalAddress: "",
    physicalAddress: "",
    dateBusinessStarted: "",
    registeredOfficeAddress: "",
    companyRegistrationNumber: "",
    typeOfBusiness: "",
    fax: "",
    phone: "",
    email: "",
    bankName: "",
    branchName: "",
    manager: "",
    managerEmail: "",
    accounts: "",
    accountsEmail: "",
    purchasing: "",
    purchasingEmail: "",
    reference1: "",
    reference2: "",
    reference3: "",
    reference1Phone: "",
    reference2Phone: "",
    reference3Phone: "",
}