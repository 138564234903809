export const schedule = {
  asset: "",
  form: "",
  dueDate: "",
  occurrence: "single",
  emailNotifications: [],
  assignedTo: "",
  kilometer: "",
  note: "",
  days: [],
  startDate: "",
  endDate: ""
};
