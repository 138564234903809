import React from "react";
import PropTypes from "prop-types";
import { Modal, Header, Grid, Form, TextArea, Button } from "semantic-ui-react";

const AddNote = ({
  isNote,
  noteInput,
  setNote,
  closeNotePage,
  addShortNote
}) => {
  return (
    <Modal open={isNote} size="small">
      <Header icon="pencil" content="Write note" />
      <Modal.Content>
        <Grid>
          <Grid.Column width={16} mobile={16} computer={16}>
            <Form>
              <div>
                <b>Notes</b>
              </div>
              <TextArea
                rows={3}
                onChange={setNote}
                placeholder="Tell us more"
                value={noteInput}
              />
            </Form>
          </Grid.Column>

          <Grid.Column width={16} textAlign="right">
            <Button
              default
              onClick={closeNotePage}
              icon="remove"
              content="close"
            />
            <Button
              color="black"
              onClick={addShortNote}
              icon="checkmark"
              content="Add"
            />
          </Grid.Column>
        </Grid>
      </Modal.Content>
    </Modal>
  );
};

AddNote.propTypes = {
  isNote: PropTypes.bool,
  setNote: PropTypes.func,
  noteInput: PropTypes.string,
  addShortNote: PropTypes.func,
  closeNotePage: PropTypes.func
};

export default AddNote;
