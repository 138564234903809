import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import {
  Grid,
  Divider,
  Icon,
  Form,
  Button,
  Message,
  Dropdown,
  Radio,
  Popup,
  Search,
  Modal
} from "semantic-ui-react";
import * as assetFormReducer from "./assetForm.reducer";
import assetLocationReducer, * as locationReducer from "../AssetLocation/assetLocation.reducer";
import * as crewReducer from "../CrewManagement/crew.reducer";
import { showSuccessToast } from "../../utils/toastUtils";
import { getErrorMessage } from "../../utils/error.utils";
import polyglot from "../../i18n";
import { Control, Errors } from "react-redux-form";
import NumericInput from "react-numeric-input";
import moment from "moment";
import LocationDropdown from "../../components/LocationDropdown";
import _ from "lodash";
import "./assetForm.css";
import AddNewCrewModal from "./AddNewCrewModal";
import ViewImage from "../../components/ViewImage";
import AddImage from "./AddImage";

const ASSET_FORM = "assetForm";
const ErrorMessage = "Linked asset name is required";
class AssetForm extends Component {
  constructor() {
    super();
    this.state = {
      isError: false,
      errorMessage: "",
      isLoading: false,
      submitted: false,
      selectedCrew: "",
      selectedLocation: "",
      assetTypeSearchValue: "",
      searchMakeValue: "",
      searchModelValue: "",
      searchSubModelValue: "",
      value: "",
      assetType: "",
      make: "",
      model: "",
      isOpenForm: false,
      isOpenCrewModal: false,
      isErrorYear: false,
      isErrorSpecialCharAsset: false,
      isErrorSpecialCharMake: false,
      isErrorSpecialCharModel: false,
      isErrorSpecialCharSubModel: false,
      isParentImage: false,
      isLinkedAssetRequired: true
    };
  }

  componentDidMount = async () => {
    this.props.actionGetAllCrew(true);
    this.props.actionGetAllLocation();
    this.props.getUserList();
    this.props.actionGetAssetsForDropdown();
    this.props.actionResetAssetFormInfo();
  };

  submitAssetForm = async () => {
    this.setState({ isError: false, errorMessage: "" });
    const { forms } = this.props;
    const { assetForm } = forms;
    // const { maintenanceUnit } = assetForm;
    const { isLinkedAsset, linkedAsset, maintenanceUnit } = assetForm;
    if (isLinkedAsset) {
      if (!linkedAsset) {
        return this.setState({
          isError: true,
          errorMessage: ErrorMessage
        });
      }
    }

    if (maintenanceUnit === 0) {
      this.setState({
        isError: true,
        errorMessage: "Kilometer/Engine Hours can not be 0"
      });
      return;
    } else {
      this.setState({
        isError: false,
        errorMessage: ""
      });
    }
    this.setState({ isLoading: true });
    await this.props.actionSubmitAssetForm(assetForm);
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      showSuccessToast("New Asset added.");
      this.setState({ isLoading: false });
      this.clearErrorMessage();
      this.goBack();
    } else {
      const { data } = response;
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
    this.clearErrorMessage();
  };

  updateState = (isError, errorMessage) => {
    this.setState({ isError, errorMessage, isLoading: false });
  };

  goBack = () => {
    this.props.actionResetAssetFormInfo();
    this.props.history.push("/asset");
  };

  allAssetType = async (e, data) => {
    const { target } = e;
    const { value } = target;
    await this.props.actionGetAssetTypeDetails(value);
  };

  setCrewName = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("crew", value);
  };

  setLocation = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("location", value);
  };
  setOperator = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("operator", value);
  };

  setLinkedAssetFlag = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("isLinkedAsset", value);
    if (!value) {
      this.props.actionSetFormField("linkedAsset", "");
      this.setState({
        isLinkedAssetRequired: true
      });
    } else {
      this.setState({
        isLinkedAssetRequired: false
      });
    }
  };

  setLinkedAsset = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("linkedAsset", value);
  };

  goToCrewForm = () => {
    this.setState({ isOpenCrewModal: true });
    // this.props.history.push("/crew");
  };

  updateCrewInDropDown = async () => {
    this.setState({ isLoading: true });
    await this.props.actionGetAllCrew(true);
    this.setState({ isOpenCrewModal: false, isLoading: false });
  };

  goToLocationForm = () => {
    this.props.history.push("/asset/location");
  };

  clearErrorMessage = () => {
    this.setState({
      isError: false,
      errorMessage: "",
      isErrorSpecialCharAsset: false,
      isErrorSpecialCharMake: false,
      isErrorSpecialCharModel: false,
      isErrorSpecialCharSubModel: false
    });
  };

  specialCharacterHandler = value => {
    const regexBack = new RegExp(/[!@#$%^&*(),.?":{}|<>\\\\\]\[]/g);
    if (regexBack.test(value)) {
      return true;
    }
    return false;
  };

  handleAssetTypeSearchChange = async (e, { value }) => {
    const isInValid = this.specialCharacterHandler(value);
    if (isInValid) {
      this.setState({
        isErrorSpecialCharAsset: true
      });
      return;
    }
    this.clearErrorMessage();
    if (value !== null) {
      const obj = {
        assetType: value
      };
      this.props.actionSetFormField("type", value);
      this.setState({ assetTypeSearchValue: value, isLoading: true });
      await this.props.actionGetAssetTypeDetails(obj, "assetType");
      this.setState({ isLoading: false });
    }
  };

  handleAssetTypeResultSelect = (e, { result, value }) => {
    this.props.actionSetFormField("type", result.title);
    this.setState({ assetTypeSearchValue: result.title });
  };

  handleMakeSearchChange = async (e, { value }) => {
    const isInValid = this.specialCharacterHandler(value);
    if (isInValid) {
      this.setState({
        isErrorSpecialCharMake: true
      });
      return;
    }
    this.clearErrorMessage();
    if (value !== null) {
      const obj = {
        assetType: this.state.assetTypeSearchValue,
        make: value
      };
      this.props.actionSetFormField("make", value);
      this.setState({ searchMakeValue: value, isLoading: true });
      await this.props.actionGetAssetTypeDetails(obj, "make");
      this.setState({ isLoading: false });
    }
  };

  handleMakeResultSelect = (e, { result }) => {
    this.props.actionSetFormField("make", result.title);
    this.setState({ searchMakeValue: result.title });
  };

  handleModelSearchChange = async (e, { value }) => {
    const isInValid = this.specialCharacterHandler(value);
    if (isInValid) {
      this.setState({
        isErrorSpecialCharModel: true
      });
      return;
    }
    this.clearErrorMessage();
    if (value !== null) {
      const obj = {
        assetType: this.state.assetTypeSearchValue,
        make: this.state.searchMakeValue,
        model: value
      };
      this.props.actionSetFormField("model", value);
      this.setState({ searchModelValue: value, isLoading: true });
      await this.props.actionGetAssetTypeDetails(obj, "model");
      this.setState({ isLoading: false });
    }
  };

  handleModelResultSelect = (e, { result }) => {
    this.props.actionSetFormField("model", result.title);
    this.setState({ searchModelValue: result.title });
  };

  handleSubModelSearchChange = async (e, { value }) => {
    const isInValid = this.specialCharacterHandler(value);
    if (isInValid) {
      this.setState({
        isErrorSpecialCharSubModel: true
      });
      return;
    }
    this.clearErrorMessage();
    if (value !== null) {
      const obj = {
        assetType: this.state.assetTypeSearchValue,
        make: this.state.searchMakeValue,
        model: this.state.searchModelValue,
        subModel: value
      };
      this.props.actionSetFormField("subModel", value);
      this.setState({ searchSubModelValue: value, isLoading: true });
      await this.props.actionGetAssetTypeDetails(obj, "subModel");
      this.setState({ isLoading: false });
    }
  };

  handleSubModelResultSelect = (e, { result }) => {
    this.props.actionSetFormField("subModel", result.title);
    this.setState({ searchSubModelValue: result.title });
  };

  handleAssetTypeChange = (evt, value) => {
    this.setState({ assetTypeSearchValue: evt.target.value, isLoading: true });
  };

  handleMakeChange = (evt, value) => {
    this.setState({ makeSearchResult: evt.target.value });
  };

  handleModelChange = (evt, value) => {
    this.setState({ modelSearchResult: evt.target.value });
  };

  handleSubmit = evt => {
    if (!this.canBeSubmitted()) {
      evt.preventDefault();
      return;
    }
  };

  canBeSubmitted() {
    const { assetTypeSearchValue } = this.state;
    return assetTypeSearchValue.length > 0;
  }

  handleSubmitModel = evt => {
    if (!this.canBeSubmittedModel()) {
      evt.preventDefault();
      return;
    }
  };

  canBeSubmittedModel() {
    const { searchMakeValue } = this.state;
    return searchMakeValue.length > 0;
  }

  handleSubmitSubModel = evt => {
    if (!this.canBeSubmittedSubModel()) {
      evt.preventDefault();
      return;
    }
  };

  canBeSubmittedSubModel() {
    const { searchModelValue } = this.state;
    return searchModelValue.length > 0;
  }

  textHandle = event => {
    const { target } = event;
    const { value } = target;
    let newValue = value;
    if (
      (newValue.length === 0 && event.which === 32) ||
      event.key === "Enter"
    ) {
      event.preventDefault();
    }
  };

  setKilometer = (e, data) => {
    this.props.actionSetFormField("maintenanceUnit", e);
  };

  openAddLocation = () => {
    this.clearErrorMessage();
    this.setState({ isOpenForm: true });
  };

  closeForm = () => {
    this.setState({
      isOpenForm: false,
      isError: false,
      errorMessage: ""
    });
  };

  addLocation = async () => {
    this.setState({ isLoading: true });
    this.props.actionResetLocationForm();
    const { locationForm } = this.props.forms;

    const { _id, ...data_to_save } = locationForm;
    await this.props.actionAddLocation(data_to_save);
    const { locationResponse } = this.props;
    const { status, data } = locationResponse;
    if (status === 200) {
      this.props.actionGetAllLocation();
      showSuccessToast("Location added");
      this.setState({ isLoading: false, isOpenForm: false });
    } else {
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
    this.clearErrorMessage();
    //this.setState({ isLoading: false });
  };

  yearChangeHandler = (e, data) => {
    this.setState({ isErrorYear: false });
    const year = moment().year();
    if (e > year) {
      this.setState({ isErrorYear: true });
    }
    this.props.actionSetQuantity(`${ASSET_FORM}.year`, e);
  };

  closeCrewModal = () => {
    this.setState({ isOpenCrewModal: false });
  };

  deleteParentImage = async imageObject => {
    this.setState({ isLoading: true });
    const { key: imageKey } = imageObject;
    await this.props.actionDeleteAssetImage(imageKey);
    const { forms } = this.props;
    const { assetForm } = forms;
    const { assetImage } = assetForm;
    const imageArray = [...assetImage];
    _.remove(imageArray, o => {
      return o.key === imageKey;
    });
    this.props.actionSetFormField("assetImage", imageArray);
    this.setState({ isLoading: false });
  };

  closeModal = () => {
    this.setState({
      isParentImage: false
    });
  };
  closeAddParentImageModal = () => {
    this.setState({ isParentImage: false });
  };

  uploadParentImage = async fileData => {
    const data = new FormData();
    data.append("file", fileData);
    await this.props.actionUploadAssetImage(data);

    const { assetImageResult, forms } = this.props;
    const { assetForm } = forms;
    const { assetImage } = assetForm;
    const imageArray = [...assetImage];

    imageArray.push(assetImageResult);
    this.props.actionSetFormField("assetImage", imageArray);
    this.closeAddParentImageModal();
  };

  render() {
    const {
      isError,
      errorMessage,
      isLoading,
      assetTypeSearchValue,
      searchMakeValue,
      searchModelValue,
      searchSubModelValue,
      isOpenForm,
      isOpenCrewModal,
      isErrorYear,
      isErrorSpecialCharAsset,
      isErrorSpecialCharMake,
      isErrorSpecialCharModel,
      isErrorSpecialCharSubModel,
      isParentImage,
      isLinkedAssetRequired
    } = this.state;

    const {
      allCrew,
      allLocations,
      forms,
      users,
      assetList,
      assetTypeDetail
    } = this.props;
    const { assetForm } = forms;
    const {
      crew,
      location,
      operator,
      isLinkedAsset,
      linkedAsset,
      assetImage = []
    } = assetForm;

    const isEnabled = this.canBeSubmitted();
    const isEnabledModel = this.canBeSubmittedModel();
    const isEnabledSubModel = this.canBeSubmittedSubModel();

    // const { isLoading, value, results } = this.state;
    let assetTypeSearchResult = [];
    let makeSearchResult = [];
    let modelSearchResult = [];
    let subModelSearchResult = [];
    const { data = [], type } = assetTypeDetail;
    if (data.length) {
      data.map((item, key) => {
        if (type === "assetType") {
          assetTypeSearchResult.push({ title: item.name, key });
        }
        if (type === "make") {
          makeSearchResult.push({ title: item.name, key });
        }
        if (type === "model") {
          modelSearchResult.push({ title: item.name, key });
        }
        if (type === "subModel") {
          subModelSearchResult.push({ title: item.name, key });
        }
      });
    }

    let crewList = [];
    if (allCrew && allCrew.length) {
      _.map(allCrew, crew => {
        crewList.push({
          text: crew.name,
          value: crew._id
        });
      });
    }

    let usersList = [];
    if (users.length) {
      _.map(users, user => {
        const { firstName, lastName, _id } = user;
        usersList.push({
          text: `${firstName} ${lastName}`,
          value: _id
        });
      });
    }

    let allAsset = [];
    if (assetList.length) {
      _.map(assetList, user => {
        const { _id, name } = user;
        allAsset.push({
          text: name,
          value: _id
        });
      });
    }

    const AddLocation = (
      <Modal open={isOpenForm} onClose={() => this.closeForm()} closeIcon>
        <Modal.Header>Add location</Modal.Header>
        <Modal.Content className="add-location">
          <Form
            model="forms.locationForm"
            error={isError}
            onSubmit={() => this.addLocation()}
          >
            <Grid.Column>
              <Form.Field>
                <label>{polyglot.t("assetForm.location")}</label>
                <Control
                  type="text"
                  model="forms.locationForm.name"
                  placeholder={polyglot.t("assetForm.location")}
                  validateOn="change"
                  className="input-box"
                  onKeyPress={event => this.textHandle(event)}
                />
                <Errors
                  style={{ color: "red" }}
                  model="forms.locationForm.name"
                  show="touched"
                  messages={{
                    valueMissing: "location name is required"
                  }}
                />
                <Message error={true} content={errorMessage} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={16} className="button-panel" textAlign="right">
              <Button
                size="small"
                onClick={this.closeForm}
                className="button-float"
              >
                Close
              </Button>
              {!isLoading ? (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  type="submit"
                  content="Submit"
                />
              ) : (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  content="Submit"
                  loading
                />
              )}
            </Grid.Column>
          </Form>
        </Modal.Content>
      </Modal>
    );

    const linkedOptions = (
      <div>
        <label>
          <b>{polyglot.t("assetForm.isLinkedToAsset")}</b>
        </label>
        <Popup
          trigger={<a style={{ marginLeft: "5%" }}>?</a>}
          content="Help text"
          basic
        />
        <Form.Group inline style={{ padding: "10px", marginBottom: "0px" }}>
          <Form.Field>
            <Radio
              label={polyglot.t("assetForm.no")}
              name={polyglot.t("assetForm.no")}
              value={false}
              checked={isLinkedAsset === false}
              onChange={this.setLinkedAssetFlag}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label={polyglot.t("assetForm.yes")}
              name={polyglot.t("assetForm.yes")}
              value={true}
              checked={isLinkedAsset === true}
              onChange={this.setLinkedAssetFlag}
            />
          </Form.Field>
        </Form.Group>
      </div>
    );

    const assetName = (
      <Form.Field required>
        <label>{polyglot.t("assetForm.name")}</label>
        <Control
          type="text"
          model="forms.assetForm.name"
          placeholder={polyglot.t("assetForm.name")}
          validateOn="change"
          required
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.name"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.name")
          }}
        />
      </Form.Field>
    );

    // const assetType = (
    //   <Form.Field required>
    //     <label>{polyglot.t("assetForm.type")}</label>
    //     <Control
    //       type="text"
    //       model="forms.assetForm.type"
    //       placeholder={polyglot.t("assetForm.type")}
    //       validateOn="change"
    //       required
    //       className="input-box"
    //       onChange={e => this.allAssetType(e)}
    //     />
    //     <Errors
    //       style={{ color: "red" }}
    //       model="forms.assetForm.type"
    //       show="touched"
    //       messages={{
    //         valueMissing: polyglot.t("assetForm.required.type")
    //       }}
    //     />
    //   </Form.Field>
    // );

    const assetType = (
      <Form.Field required>
        <label>
          {polyglot.t("assetForm.type")}
          <Popup
            trigger={<a style={{ marginLeft: "5%" }}>?</a>}
            content="Help text"
            basic
          />
          {isErrorSpecialCharAsset && (
            <span style={{ color: "red" }}> Special character not allowed</span>
          )}
        </label>

        <Search
          className="findSearchBar"
          type="text"
          placeholder={polyglot.t("assetForm.type")}
          loading={isLoading}
          onResultSelect={this.handleAssetTypeResultSelect}
          onSearchChange={_.debounce(this.handleAssetTypeSearchChange, 300, {
            leading: true
          })}
          results={assetTypeSearchResult}
          value={assetTypeSearchValue}
          //onTouch={this.handleAddition}
          // {...this.props}
          onChange={this.handleAssetTypeChange}
          onKeyPress={event => this.textHandle(event)}
        />
      </Form.Field>
    );

    const modifications = (
      <Form.Field>
        <label>{polyglot.t("assetForm.modifications")}</label>
        <Control
          type="text"
          model="forms.assetForm.modifications"
          placeholder={polyglot.t("assetForm.modifications")}
          validateOn="change"
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.modifications"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.modifications")
          }}
        />
      </Form.Field>
    );

    const linkedERP = (
      <Form.Field>
        <label>{polyglot.t("assetForm.linkedERP")}</label>
        <Control
          type="text"
          model="forms.assetForm.linkedERP"
          placeholder={polyglot.t("assetForm.linkedERP")}
          validateOn="change"
          className="input-box"
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.linkedERP"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.linkedERP")
          }}
        />
      </Form.Field>
    );

    const make = (
      <Form.Field required>
        <label>
          {polyglot.t("assetForm.make")}
          <Popup
            trigger={<a style={{ marginLeft: "5%" }}>?</a>}
            content="Help text"
            basic
          />
          {isErrorSpecialCharMake && (
            <span style={{ color: "red" }}> Special character not allowed</span>
          )}
        </label>
        <Search
          className="findSearchBar"
          placeholder={polyglot.t("assetForm.make")}
          loading={isLoading}
          onResultSelect={this.handleMakeResultSelect}
          onSearchChange={_.debounce(this.handleMakeSearchChange, 500, {
            leading: true
          })}
          results={makeSearchResult}
          value={searchMakeValue}
          // {...this.props}
          onChange={this.handleMakeChange}
          onTouch={this.handleSubmit}
          disabled={!isEnabled}
          onKeyPress={event => this.textHandle(event)}
        />
      </Form.Field>
    );

    const model = (
      <Form.Field required>
        <label>
          {polyglot.t("assetForm.model")}
          <Popup
            trigger={<a style={{ marginLeft: "5%" }}>?</a>}
            content="Help text"
            basic
          />
          {isErrorSpecialCharModel && (
            <span style={{ color: "red" }}> Special character not allowed</span>
          )}
        </label>
        <Search
          className="findSearchBar"
          placeholder={polyglot.t("assetForm.model")}
          loading={isLoading}
          onResultSelect={this.handleModelResultSelect}
          onSearchChange={_.debounce(this.handleModelSearchChange, 500, {
            leading: true
          })}
          results={modelSearchResult}
          value={searchModelValue}
          onChange={this.handleModelChange}
          onTouch={this.handleSubmitModel}
          disabled={!isEnabledModel}
          onKeyPress={event => this.textHandle(event)}
          // {...this.props}
        />
      </Form.Field>
    );

    const subModel = (
      <Form.Field>
        <label>
          {polyglot.t("assetForm.subModel")}
          <Popup
            trigger={<a style={{ marginLeft: "5%" }}>?</a>}
            content="Help text"
            basic
          />
          {isErrorSpecialCharSubModel && (
            <span style={{ color: "red" }}> Special character not allowed</span>
          )}
        </label>
        <Search
          className="findSearchBar"
          placeholder={polyglot.t("assetForm.subModel")}
          loading={isLoading}
          onResultSelect={this.handleSubModelResultSelect}
          onSearchChange={_.debounce(this.handleSubModelSearchChange, 500, {
            leading: true
          })}
          results={subModelSearchResult}
          value={searchSubModelValue}
          // {...this.props}
          onTouch={this.handleSubmitSubModel}
          disabled={!isEnabledSubModel}
          onKeyPress={event => this.textHandle(event)}
        />
      </Form.Field>
    );
    const year = (
      <Form.Field>
        <label>{polyglot.t("assetForm.year")}</label>

        <NumericInput
          min={1}
          max={10000}
          maxLength={4}
          className="form-control"
          validateOn="change"
          placeholder="Year"
          onChange={this.yearChangeHandler}
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.year"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.year")
          }}
        />
        {isErrorYear && (
          <span style={{ color: "red" }}>
            Year should not be greater than current year
          </span>
        )}
      </Form.Field>
    );

    const engineType = (
      <Form.Field>
        <label>{polyglot.t("assetForm.engineType")}</label>
        <Control
          type="text"
          model="forms.assetForm.engineType"
          placeholder={polyglot.t("assetForm.engineType")}
          validateOn="change"
          className="input-box"
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.engineType"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.engineType")
          }}
        />
      </Form.Field>
    );

    const serialNumber = (
      <Form.Field>
        <label>{polyglot.t("assetForm.serialNumber")}</label>
        <Control
          type="text"
          model="forms.assetForm.serialNumber"
          placeholder={polyglot.t("assetForm.serialNumber")}
          validateOn="change"
          className="input-box"
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.serialNumber"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.serialNumber")
          }}
        />
      </Form.Field>
    );

    const customerPlantNo = (
      <Form.Field>
        <label>{polyglot.t("assetForm.customerPlantNo")}</label>
        <Control
          type="text"
          model="forms.assetForm.customerPlantNo"
          placeholder={polyglot.t("assetForm.customerPlantNo")}
          validateOn="change"
          className="input-box"
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.customerPlantNo"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.customerPlantNo")
          }}
        />
      </Form.Field>
    );

    const crewName = (
      <Form.Field required>
        <label>
          {polyglot.t("assetForm.crewName")}
          <a
            onClick={this.goToCrewForm}
            className="linkToPage"
            style={{ float: "right" }}
          >
            Add new
          </a>
        </label>

        <Dropdown
          placeholder={polyglot.t("assetForm.crewName")}
          fluid
          value={crew}
          selection
          required
          onChange={this.setCrewName}
          options={crewList}
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
        {_.isEmpty(crewList) ? (
          <span style={{ color: "red" }}>
            No crews available, Create a new one!
          </span>
        ) : null}
      </Form.Field>
    );

    const operatorName = (
      <Form.Field required>
        <label>{polyglot.t("assetForm.operator")}</label>
        <Dropdown
          placeholder="Select Operator"
          fluid
          value={operator}
          selection
          required
          onChange={this.setOperator}
          options={usersList}
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
        {_.isEmpty(usersList) ? (
          <span style={{ color: "red" }}>
            No operators available, Create a new one!
          </span>
        ) : null}
      </Form.Field>
    );

    const locationName = (
      <Form.Field required>
        <label>
          {polyglot.t("assetForm.location")}
          <a
            onClick={this.openAddLocation}
            className="linkToPage"
            style={{ float: "right" }}
          >
            Add new
          </a>
        </label>

        <LocationDropdown
          setLocation={this.setLocation}
          locationId={location}
          locations={allLocations}
        />
        {_.isEmpty(allLocations) ? (
          <span style={{ color: "red" }}>
            No locations available, Create a new one!
          </span>
        ) : null}
      </Form.Field>
    );

    const assetsToLink = (
      <Form.Field required>
        <label>{polyglot.t("assetForm.LinkedAsset")}</label>
        <Dropdown
          placeholder="Select Asset"
          fluid
          value={linkedAsset}
          selection
          required
          onChange={this.setLinkedAsset}
          options={allAsset}
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
        {_.isEmpty(allAsset) ? (
          <span style={{ color: "red" }}>
            No assets available, Create a new one!
          </span>
        ) : null}
      </Form.Field>
    );

    const kilometerText = (
      // <Form.Field required={isLinkedAssetRequired}>
      <Form.Field required>
        <label>{polyglot.t("assetForm.kilometer")}</label>

        <NumericInput
          min={1}
          max={10000}
          maxLength={5}
          className="form-control"
          validateOn="change"
          placeholder="Kilometer/Engine Hours"
          onChange={this.setKilometer}
          required
          //required={isLinkedAssetRequired}
          onKeyPress={e => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.maintenanceUnit"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.kilometer")
          }}
        />
      </Form.Field>
    );

    return (
      <div className="my-asset-form">
        <MetaData title="Add/Edit Asset" />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading,please wait</Loader>
          )}
          <Grid.Row>
            <Grid.Column width={16}>
              <h3>
                <Icon name="star" />
                Manage Assets
                <Divider />
              </h3>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {isOpenCrewModal && (
          <AddNewCrewModal
            closeModal={this.closeCrewModal}
            updateCrewInDropDown={this.updateCrewInDropDown}
          />
        )}
        <Grid className="asset-component">
          <Form
            error={isError}
            model="form.profileInfo"
            onSubmit={this.submitAssetForm}
            className="asset-form-box"
          >
            <Grid columns="equal">
              <Grid.Column width={8} computer={8} mobile={16}>
                {linkedOptions}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {isLinkedAsset && assetsToLink}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {assetName}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {assetType}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {make}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {model}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {subModel}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {year}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {engineType}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {serialNumber}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {customerPlantNo}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {modifications}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {linkedERP}
              </Grid.Column>

              {!isLinkedAsset && (
                <Grid.Column width={8} computer={8} mobile={16}>
                  {crewName}
                </Grid.Column>
              )}

              {!isLinkedAsset && (
                <Grid.Column width={8} computer={8} mobile={16}>
                  {operatorName}
                </Grid.Column>
              )}

              {!isLinkedAsset && (
                <Grid.Column width={8} computer={8} mobile={16}>
                  {locationName}
                </Grid.Column>
              )}

              <Grid.Column width={8} computer={8} mobile={16}>
                {kilometerText}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                <Form.Field>
                  <label>Upload Asset Images</label>
                  {assetImage && (
                    <ViewImage
                      imageUrl={assetImage}
                      deleteImage={this.deleteParentImage}
                    />
                  )}
                  <Button
                    basic
                    color="blue"
                    content="Image"
                    size="mini"
                    type="button"
                    icon="plus"
                    textAlign="right"
                    disabled={assetImage.length >= 3}
                    onClick={() => this.setState({ isParentImage: true })}
                  />
                  <p style={{ fontStyle: "italic", fontSize: "12px" }}>
                    Note : Upto 3 images only
                  </p>
                  {assetImage.length >= 3 ? (
                    <p>You can add 3 images only.</p>
                  ) : null}
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={16} computer={16} mobile={16}>
                <Divider />
              </Grid.Column>
            </Grid>
            <Message error={true} content={errorMessage} />
            <Grid.Column
              width={16}
              computer={16}
              mobile={16}
              style={{ textAlign: "right" }}
            >
              <Button
                type="button"
                size="small"
                onClick={this.goBack}
                icon="left arrow"
                labelPosition="left"
                className="button-float"
                content=" Go back"
              />
              {!isLoading ? (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  // onSubmit={this.submitCafFormHandler}
                  content="Submit"
                />
              ) : (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  content="Submit"
                  loading
                />
              )}
            </Grid.Column>
            <Grid.Column>
              <AddImage
                isImage={isParentImage}
                closePage={this.closeModal}
                uploadImages={this.uploadParentImage}
              />
            </Grid.Column>
          </Form>
        </Grid>
        <Grid>{AddLocation}</Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allLocations: state.assetLocation.allLocations,
    assetList: state.assetForm.assetList,
    assetTypeDetail: state.assetForm.assetTypeDetail,
    response: state.assetForm.response,
    allCrew: state.crew.allCrew,
    assets: state.assets.assets,
    users: state.crew.users,
    forms: state.forms,
    locationResponse: state.assetLocation.response,
    assetImageResult: state.assetForm.assetImageUrl
  };
};

const reducer = {
  ...assetFormReducer,
  ...locationReducer,
  ...crewReducer,
  ...assetLocationReducer
};
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssetForm)
);
