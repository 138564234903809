import _ from "lodash";
import "./assetDetail.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import { showSuccessToast } from "../../utils/toastUtils";
import * as assetDetailReducer from "../AssetDetails/assetDetail.reducer";
import * as assetLocationReducer from "../AssetLocation/assetLocation.reducer";
import LocationDropdown from "../../components/LocationDropdown";
import * as inventoryReducer from "../Inventory/inventory.reducer";
import {
  Grid,
  Button,
  Dropdown,
  Form,
  Modal,
  Divider,
  List,
  Message
} from "semantic-ui-react";
import { Control, Errors } from "react-redux-form";
import {
  ASSET_ID_FOR_URL_PARAM,
  ASSET_MODEL_FOR_URL_PARAM,
  ASSET_SUB_MODEL_FOR_URL_PARAM,
  ASSET_POSITION_FOR_URL_PARAM,
  ASSET_NAME_FOR_URL_PARAM
} from "../../constants/appConstants";

const TYPE = "type";
const ASSET = "asset";
// let positionList = [];

const { ATTATCH_TO_ASSET_FORM } = "attachToAssetForm";
class AttachHoseForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isAttachHose: false,
      locations: [],
      isChange: false,
      isOpenForm: false,
      position: null,
      hosePosition: false,
      // positionList: [],
      errorMessageflag: false,
      temp: false,
      SingleRegularvalue: "",
      hoses: [],
      isHoseModalLoading: false
    };
  }

  componentDidMount = () => {
    this.getAttachedHoses();
  };

  componentWillUnmount = () => {
    this.props.actionCleatAssetDetailsReducer();
  };

  getAttachedHoses = async () => {
    this.setState({ isLoading: true });
    await this.props.actionGetHoseInventory();
    await this.props.actionGetPositionList();
    let params = this.props.match.params;

    await this.props.actionGetAttachedHoses(params.id);
    let list = [];
    const { attachedHoses } = this.props;
    if (attachedHoses && attachedHoses.length) {
      _.map(attachedHoses, (hose, key) => {
        list.push({
          text: hose.position,
          value: hose.position,
          key
        });
      });
      list = _.uniqBy(list, name => {
        return name.text;
      });
      this.setState({ positionList: list });
    }
    this.setState({ isLoading: false });
  };

  setSelectedHose = async hose => {
    const { position, stockCode, _id } = hose;
    this.props.actionSetAttachToAssetField("position", position);
    this.props.actionSetAttachToAssetField("_id", _id);
    await this.props.actionGetHoseInventory();
    const { inventoryHoses } = this.props;
    const selectedHose = _.find(inventoryHoses, { stockCode: stockCode });
    const locations = [];
    if (!_.isEmpty(selectedHose)) {
      const { locationQuantity } = selectedHose;
      Object.keys(locationQuantity).forEach(function(key) {
        if (locationQuantity[key] != null && locationQuantity[key] >= -1) {
          locations.push({ name: key, quantity: locationQuantity[key] });
        }
      });
      this.setState({ locations, isChange: true });
      this.AttachToAssetModal();
    } else {
      this.setState({ locations, isChange: true });
      this.AttachToAssetModal();
    }
  };

  setSelectedHoseForReplace = async hose => {
    const {
      position,
      stockCode,
      _id,
      hoseUniqueCode,
      hoseId,
      positionId
    } = hose;
    this.props.actionSetAttachToAssetField("position", position);
    this.props.actionSetAttachToAssetField("_id", _id);
    this.props.actionSetAttachToAssetField("hoseId", hoseId);
    this.props.actionSetAttachToAssetField("positionId", positionId);

    await this.props.actionGetHoseInventory();
    const { inventoryHoses } = this.props;
    const selectedHose = _.find(inventoryHoses, {
      hoseUniqueCode: hoseUniqueCode
    });
    const locations = [];
    if (!_.isEmpty(selectedHose)) {
      const { locationQuantity } = selectedHose;
      Object.keys(locationQuantity).forEach(function(key) {
        if (locationQuantity[key] != null && locationQuantity[key] >= -1) {
          locations.push({ name: key, quantity: locationQuantity[key] });
        }
      });
      this.setState({ locations, isChange: true });
      this.AttachToAssetModal();
    } else {
      this.setState({ locations, isChange: true });
      this.AttachToAssetModal();
    }
  };

  AttachToAssetModal = async () => {
    this.setState({ isHoseModalLoading: true });
    let params = this.props.match.params;
    this.props.actionSetAttachToAssetField("assetId", params.id);
    this.props.actionSetAttachToAssetField("quantity", 1);
    await this.props.actionGetInventories();
    await this.props.actionGetHoseInventory();
    this.setState({ isAttachHose: true, isHoseModalLoading: false });
  };

  closeModal = () => {
    this.setState({
      isAttachHose: false,
      locations: [],
      isChange: false,
      errorMessageflag: false,
      temp: false
    });
    this.props.actionResetForm("attachToAssetForm");
  };

  submitAttachHoseForm = async () => {
    const { isChange } = this.state;
    if (isChange) {
      this.changeAttachedHose();
    } else {
      this.attachNewHose();
    }
  };

  attachNewHose = async () => {
    const { attachToAssetForm } = this.props;
    if (
      attachToAssetForm.position === "" ||
      attachToAssetForm.stockCode === "" ||
      attachToAssetForm.location === ""
    ) {
      this.setState({
        errorMessageflag: true
      });
      return;
    }
    await this.props.actionAttachHose(attachToAssetForm);
    showSuccessToast("You have attached hose successfully ");
    this.setState({ isAttachHose: false, locations: [], temp: false });
    this.props.actionResetForm("attachToAssetForm");
    this.getAttachedHoses();
    this.clearErrorMessage();
  };

  changeAttachedHose = async () => {
    const { attachToAssetForm } = this.props;
    const { position } = attachToAssetForm;
    if (
      attachToAssetForm.position === "" ||
      attachToAssetForm.stockCode === "" ||
      attachToAssetForm.location === "" ||
      attachToAssetForm.customerType === ""
    ) {
      this.setState({
        errorMessageflag: true
      });
      return;
    }

    await this.props.actionUpdateAttachHose(attachToAssetForm);
    showSuccessToast(`You have changed hose on ${position} position`);
    this.setState({
      isAttachHose: false,
      locations: [],
      isChange: false
    });
    this.props.actionResetForm("attachToAssetForm");
    this.getAttachedHoses();
    await this.props.actionGetInventories();
    this.clearErrorMessage();
  };

  setLocation = (e, data) => {
    const { value } = data;
    this.props.actionSetAttachToAssetField("location", value);
    const { inventoryHoses } = this.props;
    const { isChange } = this.state;
    const hoses = [];
    if (inventoryHoses.length) {
      _.map(inventoryHoses, hose => {
        const {
          stockCode,
          description,
          locationQuantity,
          hoseUniqueCode,
          hoseId
        } = hose;
        Object.keys(locationQuantity).forEach((location, key) => {
          if (value === location) {
            hoses.push({
              text: stockCode,
              value: stockCode,
              description: description
            });
            this.props.actionSetAttachToAssetField(
              "hoseUniqueCode",
              hoseUniqueCode
            );
            if (hoseId) {
              this.props.actionSetAttachToAssetField("hoseId", hoseId);
            }
            if (isChange) {
              this.props.actionSetAttachToAssetField("stockCode", stockCode);
              this.props.actionSetAttachToAssetField(
                "description",
                description
              );
            }
          }
        });
      });
    }
    this.setState({ hoses, temp: true });
  };

  setHoseStockCode = (e, data) => {
    const { value } = data;
    this.props.actionSetAttachToAssetField("stockCode", value);
    const { inventoryHoses } = this.props;
    const selectedHose = _.find(inventoryHoses, { stockCode: value });
    const { description } = selectedHose;
    this.props.actionSetAttachToAssetField("description", description);
    // const locations = [];
    // Object.keys(locationQuantity).forEach(function (key) {
    //   if (locationQuantity[key] != null && locationQuantity[key] >= -1) {
    //     locations.push({ name: key, quantity: locationQuantity[key] });
    //   }
    // });
    this.setState({ temp: true });
  };

  viewAudits = () => {
    const { asset } = this.props;
    const { name } = asset;
    this.props.history.push({
      pathname: "/audit",
      search: `?${TYPE}=ATTACHED&${ASSET}=${name}`
    });
  };

  openNewHose = () => {
    this.setState({ isOpenForm: true });
  };

  goToHoseMaker = data => {
    const { asset, positionIdForm } = this.props;
    const { _id, model, subModel, name, make } = asset;
    const { value } = positionIdForm;
    if (!this.state.position) {
      this.setState({
        errorMessageflag: true
      });
    } else
      this.props.history.push({
        pathname: "/hoseMaker",
        search: `?${ASSET_NAME_FOR_URL_PARAM}=${name}&${ASSET_ID_FOR_URL_PARAM}=${_id}&${ASSET_MODEL_FOR_URL_PARAM}=${model}&${ASSET_SUB_MODEL_FOR_URL_PARAM}=${subModel}&${ASSET_POSITION_FOR_URL_PARAM}=${this.state.position}&positionId=${value}&make=${make}`
      });
    this.clearErrorMessage();
  };

  sendToHoseMaker = hoseData => {
    const { _id: hoseId, position } = hoseData;
    const { asset, positionIdForm } = this.props;
    const { _id, model, subModel, name, make } = asset;
    const { value } = positionIdForm;
    if (!this.state.position) {
      this.setState({
        errorMessageflag: true
      });
    } else
      this.props.history.push({
        pathname: "/hoseMaker",
        search: `?${ASSET_NAME_FOR_URL_PARAM}=${name}&${ASSET_ID_FOR_URL_PARAM}=${_id}&${ASSET_MODEL_FOR_URL_PARAM}=${model}&${ASSET_SUB_MODEL_FOR_URL_PARAM}=${subModel}&${ASSET_POSITION_FOR_URL_PARAM}=${position}&hoseId=${hoseId}&positionId=${value}&make=${make}`
      });
    this.clearErrorMessage();
  };

  handleChange = async (e, data) => {
    this.props.actionClearHoseSuggestions();
    const { value } = data;
    const { positionList } = this.props;
    const selectedPosition = _.find(positionList, val => {
      if (val.position === value) {
        return val._id;
      }
    });
    this.props.actionSetPositionField("position", value);
    if (selectedPosition) {
      await this.props.actionSetPositionField(
        "positionId",
        selectedPosition._id
      );
      const { asset, positionIdForm } = this.props;
      const { model, make } = asset;
      const { value: positionId } = positionIdForm;
      const queryForHoseSuggestions = {
        model,
        make,
        positionId
      };
      if (positionId) {
        this.setState({ isLoading: true });
        await this.props.actionGetHoseSuggestions(queryForHoseSuggestions);
        this.setState({ isLoading: false });
      }
    }
    this.setState({ position: value });
  };

  handleAddition = async (e, { value }) => {
    // const { positionList } = this.state;
    this.setState({ isLoading: true });
    await this.props.actionSaveNewPosition(value);
    this.setState({ isLoading: false });
    const { positionList } = this.props;
    const selectedPosition = _.find(positionList, val => {
      if (val.position === value) {
        return val._id;
      }
    });
    if (selectedPosition) {
      this.props.actionSetPositionField("positionId", selectedPosition._id);
    }
    this.setState({ position: value });
    // this.setState({
    //   positionList: [
    //     ...this.state.positionList,
    //     { text: value, value, key: positionList.length }
    //   ]
    // });
  };

  closeForm = () => {
    this.setState({
      isOpenForm: false,
      isError: false,
      isLoading: false,
      isEditForm: false,
      position: "",
      errorMessageflag: false
    });
    this.props.actionClearHoseSuggestions();
  };

  closeFormSet = () => {
    this.setState({
      position: "",
      isOpenForm: false
    });
    this.props.actionClearHoseSuggestions();
  };

  textHandle = event => {
    const { target } = event;
    const { value } = target;
    let newValue = value;
    if (newValue.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  };

  goToHoseMakerPage = () => {
    this.props.history.push("/hoseMaker");
  };

  goToHoseMakerPage = () => {
    this.props.history.push("/hoseMaker");
  };

  handleRadioChange = (e, data) => {
    this.props.actionSetFormField(`${ATTATCH_TO_ASSET_FORM}.customerType`, e);
  };

  clearErrorMessage = () => {
    this.setState({
      errorMessageflag: false
    });
  };

  goToHoseMakerForEditHose = data => {
    const { asset, attachToAssetForm } = this.props;
    const { hoseId, position, positionId } = attachToAssetForm;
    const { _id, model, subModel, name, make } = asset;
    if (!position) {
      this.setState({
        errorMessageflag: true
      });
    } else {
      const flow = true;
      this.props.history.push({
        pathname: "/hoseMaker",
        search: `?${ASSET_NAME_FOR_URL_PARAM}=${name}&${ASSET_ID_FOR_URL_PARAM}=${_id}&${ASSET_MODEL_FOR_URL_PARAM}=${model}&${ASSET_SUB_MODEL_FOR_URL_PARAM}=${subModel}&${ASSET_POSITION_FOR_URL_PARAM}=${position}&hoseId=${hoseId}&positionId=${positionId}&make=${make}`
      });
      this.clearErrorMessage();
    }
  };

  render() {
    const {
      inventoryHoses,
      attachToAssetForm,
      attachedHoses,
      positionList,
      hoseSuggestion
    } = this.props;
    const { location, stockCode } = attachToAssetForm;
    const {
      isLoading,
      isAttachHose,
      // locations,
      isOpenForm,
      errorMessageflag,
      isChange,
      position,
      temp,
      hoses,
      isHoseModalLoading,
      locations: replaceLocations
    } = this.state;
    // let { positionList } = this.state;
    const locations = [];
    const list = [];
    if (positionList.length) {
      _.map(positionList, (position, key) => {
        list.push({
          text: position.position,
          value: position.position,
          key,
          id: position._id
        });
      });
    }
    if (inventoryHoses.length && !isChange) {
      _.map(inventoryHoses, hose => {
        const { locationQuantity } = hose;
        Object.keys(locationQuantity).forEach(function(key) {
          if (locationQuantity[key] != null && locationQuantity[key] >= -1) {
            locations.push({ name: key, quantity: locationQuantity[key] });
          }
        });
      });
    }
    const HosePosition = (
      <Grid width={16}>
        <Grid.Column width={16} computer={16} mobile={16}>
          <Form.Field required>
            <label>
              <b>Select hose position</b>
            </label>
            <Grid.Column width={16}>
              <Dropdown
                fluid
                required
                allowAdditions
                selection
                placeholder="Hose position"
                options={list}
                onChange={this.handleChange}
                onAddItem={this.handleAddition}
                value={position}
                search
                onKeyPress={event => this.textHandle(event)}
              />
            </Grid.Column>
          </Form.Field>
        </Grid.Column>
      </Grid>
    );

    const HoseSuggestion = (
      <Grid>
        <Grid.Column width={16}>
          <h3>Hose Suggestions </h3>
        </Grid.Column>
        <Grid.Column width={16}>
          <List divided relaxed>
            {_.map(hoseSuggestion, (hose, index) => (
              <List.Item key={index}>
                <List.Content style={{ paddingBottom: "0px" }}>
                  <Grid>
                    <Grid.Column computer={12} tablet={12} mobile={16}>
                      <List.Description
                        as="a"
                        onClick={() => this.sendToHoseMaker(hose)}
                      >
                        <b>Stock Code : </b>
                        <span style={{ color: "blue" }}>{hose.stockCode}</span>
                      </List.Description>
                      <List.Description as="a">
                        <b>Description : </b>
                        {hose.stockDescription}
                      </List.Description>
                    </Grid.Column>
                  </Grid>
                </List.Content>
              </List.Item>
            ))}
          </List>
        </Grid.Column>
      </Grid>
    );

    const AttachedHoseList = (
      <List divided relaxed>
        {attachedHoses.length > 0 ? (
          _.map(attachedHoses, (hose, index) => (
            <List.Item key={index}>
              <List.Content style={{ paddingBottom: "0px" }}>
                <Grid>
                  <Grid.Column computer={12} tablet={12} mobile={16}>
                    <List.Header as="a">
                      {hose.serialNo ? hose.serialNo + " )" : null}{" "}
                      <b>Position :</b> {hose.position}
                    </List.Header>
                    {hose.position && (
                      <List.Description as="a">
                        <b>Stock Code : </b>
                        {hose.stockCode}
                      </List.Description>
                    )}
                    {hose.description && (
                      <List.Description as="a">
                        <b>Description : </b>
                        {hose.description}
                      </List.Description>
                    )}
                    {hose.preventiveCount || hose.failureCount ? (
                      <List.Description as="a">
                        <b>Hose change count :</b>{" "}
                        {hose.preventiveCount + hose.failureCount}
                        <p>
                          <i>
                            {" "}
                            ( Preventatively :{" "}
                            {hose.preventiveCount ? hose.preventiveCount : 0} ,
                            Failure :{" "}
                            {hose.failureCount ? hose.failureCount : 0} )
                          </i>
                        </p>
                      </List.Description>
                    ) : (
                      ""
                    )}
                  </Grid.Column>

                  <Grid.Column
                    computer={4}
                    tablet={4}
                    mobile={16}
                    textAlign="right"
                  >
                    <Button
                      icon="exchange"
                      size="mini"
                      color="green"
                      basic
                      onClick={() => {
                        if (isChange) {
                          this.setSelectedHose(hose);
                        } else {
                          this.setSelectedHoseForReplace(hose);
                        }
                      }}
                      content="Replace"
                    />
                  </Grid.Column>
                </Grid>
              </List.Content>
            </List.Item>
          ))
        ) : (
          <Message
            info
            header="Sorry, no hoses attached!"
            content={
              <div>
                <span
                  className="cart-go-search-link"
                  onClick={this.AttachToAssetModal}
                >
                  Click here to attach a new hose.
                </span>
              </div>
            }
          />
        )}
      </List>
    );

    const locationDetail = (
      <Grid>
        <Grid.Column>
          <Form.Field required>
            <label>Inventory Location</label>
            <LocationDropdown
              setLocation={this.setLocation}
              locationId={location}
              locations={
                !isChange
                  ? _.uniqBy(locations, item => {
                      return item.name;
                    })
                  : _.uniqBy(replaceLocations, item => {
                      return item.name;
                    })
              }
            />
          </Form.Field>
          {!isChange ? (
            <span>
              {_.isEmpty(locations) ? (
                <span style={{ cursor: "pointer", color: "red" }}>
                  No hoses available in Inventory for any location! &nbsp;
                  <a onClick={this.goToHoseMakerPage}>
                    Click here to attach a new hose.{" "}
                  </a>
                </span>
              ) : null}
            </span>
          ) : (
            <span>
              {_.isEmpty(replaceLocations) ? (
                <span style={{ color: "red" }}>
                  No identical hoses available in Inventory!
                </span>
              ) : null}
            </span>
          )}
        </Grid.Column>
      </Grid>
    );

    const positionText = (
      <Grid width={16}>
        <Grid.Column width={16}>
          <Form.Field required>
            <label>Position</label>
            <Control.text
              model="forms.attachToAssetForm.position"
              placeholder="Position"
              validateOn="change"
              required
              onKeyPress={event => this.textHandle(event)}
            />
            <Errors
              style={{ color: "red" }}
              model="forms.attachToAssetForm.quantity"
              show="touched"
              messages={{
                valueMissing: "quantity is required"
              }}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
    );

    const hoseName = (
      <Grid>
        <Grid.Column>
          <Form.Field required>
            <label>Hose</label>
            {!isChange ? (
              <Dropdown
                placeholder="Select Hose"
                fluid
                value={stockCode}
                selection
                required
                onChange={this.setHoseStockCode}
                options={hoses}
              />
            ) : (
              <span>{stockCode}</span>
            )}
            {_.isEmpty(hoses) && !isChange ? (
              <span style={{ cursor: "pointer", color: "red" }}>
                No hoses found in Inventory! &nbsp;
                <a onClick={this.goToHoseMakerPage}>
                  Click here to attach a new hose.{" "}
                </a>
              </span>
            ) : null}
          </Form.Field>
        </Grid.Column>
      </Grid>
    );

    const radioButton = (
      <Form.Field>
        <Grid>
          <Grid.Column width={4} mobile={16} computer={5}>
            <div className="ui radio checkbox">
              <div>
                <Control.radio
                  model="forms.attachToAssetForm.type"
                  value="Changed preventatively"
                  name="Changed preventatively"
                  id="Changed preventatively"
                />
                <label htmlFor="Changed preventatively" className="playerType">
                  Changed Preventatively
                </label>
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={5} mobile={16} computer={5}>
            <div className="ui radio checkbox">
              <div>
                <Control.radio
                  model="forms.attachToAssetForm.type"
                  value="Changed hose due to failure"
                  name="Changed hose due to failure"
                  id="Changed hose due to failure"
                />
                <label
                  htmlFor="Changed hose due to failure"
                  className="playerType"
                >
                  Changed hose due to failure
                </label>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Form.Field>
    );

    const actionButton = (
      <Grid width={16} mobile={8} computer={8}>
        <Grid.Column
          width={16}
          mobile={16}
          computer={16}
          className="button-panel"
          textAlign="right"
        >
          <span>
            <Button
              size="small"
              onClick={this.closeModal}
              className="button-float"
              content="Cancel"
            />
            {!isLoading ? (
              <Button
                type="submit"
                secondary
                size="small"
                className="button-float"
                onSubmit={this.submitAttachHoseForm}
                content="Submit"
                loading={isLoading}
              />
            ) : (
              <Button
                secondary
                size="small"
                className="button-float"
                loading
                content="Submit"
              />
            )}
          </span>
        </Grid.Column>
      </Grid>
    );

    const AttachToAssetModal = (
      <Modal open={isAttachHose} onClose={this.closeScheduleForm}>
        <Modal.Header>
          {" "}
          {isChange ? "Replace hose" : "Attach hose"}
        </Modal.Header>

        <Modal.Content className="add-location">
          <Grid>
            {isChange && (
              <Grid.Column width={16} textAlign="center">
                <h4>Make replacement hose</h4>
                <p>
                  {" "}
                  <Button color="green" onClick={this.goToHoseMakerForEditHose}>
                    Make Hose
                  </Button>
                </p>
                <h4>
                  {" "}
                  <Divider horizontal> OR </Divider>
                </h4>
                <h4> Replace hose from assembled inventory </h4>
              </Grid.Column>
            )}
            <Grid.Column width={16}>
              <Form
                model="form.attachToAssetForm"
                onSubmit={this.submitAttachHoseForm}
              >
                {!isChange && (
                  <Grid.Column width={16} computer={8} mobile={16}>
                    {positionText}
                  </Grid.Column>
                )}

                <Grid.Column width={8} computer={8} mobile={16}>
                  {locationDetail}
                </Grid.Column>

                {temp ? (
                  <Grid.Column width={16} computer={8} mobile={16}>
                    {hoseName}
                  </Grid.Column>
                ) : null}
                <Grid.Column
                  width={16}
                  computer={16}
                  mobile={16}
                  textAlign="right"
                >
                  {temp ? (
                    <Grid.Column width={16} computer={8} mobile={16}>
                      {radioButton}
                    </Grid.Column>
                  ) : null}

                  {errorMessageflag ? (
                    <Grid>
                      <Grid.Column>
                        <Message negative>All fields are required</Message>
                      </Grid.Column>
                    </Grid>
                  ) : null}
                  <Divider />

                  <Grid>
                    <Grid.Column
                      width={16}
                      className="button-panel"
                      textAlign="right"
                    >
                      {actionButton}
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Form>
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );

    const OpenHosePosition = (
      <Grid>
        {isLoading && (
          <Loader title="Loading, please wait">Loading,please wait</Loader>
        )}
        <Modal open={isOpenForm} onClose={() => this.closeForm()} closeIcon>
          <Modal.Header width={12}>
            Hose Position
            {/* <Modal
              onClose={() => this.closeForm()}
              closeIcon
              trigger={<a className="HelpText">Help ?</a>}
              centered={false}
            >
              <Modal.Header>
                <div>
                  <Image
                    style={{ width: "100%" }}
                    src="https://s3-ap-southeast-2.amazonaws.com/portal-static-images/HosePositionhelpImage.png"
                  />
                </div>
              </Modal.Header>

              <Modal.Content>
                <Modal.Description>
                  <p>
                    Name your hose position something meaningful that will be
                    easily recognized by other people. Suggested good practice
                    is to view left and right as if looking from the front of
                    the asset.
                  </p>
                </Modal.Description>
              </Modal.Content>
            </Modal> */}
          </Modal.Header>

          <Modal.Content className="add-location">
            <Grid.Column width={16} computer={16} mobile={16}>
              {HosePosition}
            </Grid.Column>
            {!_.isEmpty(hoseSuggestion) && (
              <Grid.Column>{HoseSuggestion}</Grid.Column>
            )}
            {errorMessageflag ? (
              <Grid>
                <Grid.Column>
                  <Message negative>All fields are required</Message>
                </Grid.Column>
              </Grid>
            ) : null}

            <Grid.Column width={16} className="button-panel" textAlign="right">
              <Button
                size="small"
                onClick={this.closeFormSet}
                className="button-float"
              >
                Close
              </Button>
              {!isLoading ? (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  type="submit"
                  content="Submit"
                  onClick={() => this.goToHoseMaker("position")}
                />
              ) : (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  content="Submit"
                  loading={isLoading}
                />
              )}
            </Grid.Column>
          </Modal.Content>
        </Modal>
      </Grid>
    );

    return (
      <Grid>
        {isLoading && (
          <Loader title="Loading, please wait">Loading,please wait</Loader>
        )}
        <Grid.Column
          computer={16}
          mobile={12}
          tablet={16}
          style={{ textAlign: "right" }}
        >
          <Button
            content="Create new Hose"
            onClick={this.openNewHose}
            size="mini"
            color="black"
            className="asset-Button"
          />

          <Button
            content="View audit"
            onClick={this.viewAudits}
            secondary
            size="mini"
            className="asset-Button"
          />

          {!isHoseModalLoading ? (
            <Button
              size="mini"
              content="Attach Hose"
              onClick={this.AttachToAssetModal}
              secondary
              className="asset-Button"
            />
          ) : (
            <Button loading size="mini" content="Attach Hose" secondary />
          )}
        </Grid.Column>
        {isAttachHose ? (
          <Grid.Column width={16} mobile={16} tablet={8}>
            {AttachToAssetModal}
          </Grid.Column>
        ) : null}
        <Grid.Column width={16} computer={16} mobile={16}>
          {!isLoading && AttachedHoseList}
        </Grid.Column>

        <Grid.Column width={16} computer={16} mobile={16}>
          {isOpenForm && OpenHosePosition}
        </Grid.Column>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    inventoryHoses: state.assetDetail.inventoryHoses,
    attachToAssetForm: state.forms.attachToAssetForm,
    attachedHoses: state.assetDetail.attachedHoses,
    asset: state.assetDetail.asset,
    allInventory: state.inventory.allInventory,
    positionList: state.assetDetail.positionList,
    positionIdForm: state.forms.forms.positionId,
    hoseSuggestion: state.assetDetail.hoseSuggestions
  };
};

const reducer = {
  ...assetDetailReducer,
  ...assetLocationReducer,
  ...inventoryReducer
};
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AttachHoseForm)
);
