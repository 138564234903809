import React from "react";
import { Grid, Card, Button, Icon } from "semantic-ui-react";

const SideNav = ({ goToHoseMaker }) => {
  const onClickHandler = (e) => {
    e.preventDefault();
    if (e.ctrlKey) {
      e.stopPropagation();
      window.open("/hoseMaker");
    }
  };
  return (
    <React.Fragment>
      <Grid
        className="side-nav-container"
        href="hoseMaker"
        onClick={(event) => onClickHandler(event)}
      >
        {/* <Grid.Column width={16} computer={16} mobile={16}>
          <Card>
            <Card.Content>
              <h3>
                <Icon name="wrench" /> Hose maker
              </h3>
              <div>Create your own customized hose.</div>
            </Card.Content>
            <Card.Content extra>
              <div className="ui two buttons">
                <Button basic color="green" onClick={goToHoseMaker}>
                  Customize my hose
                </Button>
              </div>
            </Card.Content>
          </Card>
        </Grid.Column> */}
        {/* <Grid.Column width={5} computer={5} mobile={15}>
            <Card>
                <Card.Content>
                    Buy products from your Order History
          </Card.Content>
                <Card.Content extra>
                    <div className="ui two buttons">
                        <Button basic color="green">
                            Order History
              </Button>
                    </div>
                </Card.Content>
            </Card>
                </Grid.Column> */}
      </Grid>
    </React.Fragment>
  );
};

export default SideNav;
