import React, { useState } from "react";
import { Button, Form, Input, Modal } from "semantic-ui-react";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";

const RS23SetPriceForm = ({
  hosetitle,
  hoseIndex,
  priceText,
  closeForm,
  jobData,
  setJobData,
}) => {
  if (priceText === null) {
    priceText = "0.00";
  } else {
    priceText = priceText.toFixed(2);
  }

  const [newPrice, setNewPrice] = useState(priceText.toString());
  const [isValid, setIsValid] = useState(true);

  const onChangePrice = (e) => {
    setIsValid(true);
    setNewPrice(e.target.value);
  };

  const onSavePrice = async () => {
    if (isNaN(parseFloat(newPrice)) || parseFloat(newPrice) < 0) {
      showErrorToast("Invalid input price");
      setIsValid(false);
      return;
    }
    if (newPrice.includes(".") && newPrice.trim().split(".")[1].length > 2) {
      showErrorToast("Maximum two decimal places");
      setIsValid(false);
      return;
    }
    const newJobData = { ...jobData };
    newJobData.hoseMaker[hoseIndex].rS23ComplianceRate = parseFloat(newPrice);
    setJobData(newJobData);
    closeForm();
  };

  const onCloseForm = () => {
    closeForm();
  };

  return (
    <Modal size="tiny" open={true} onClose={closeForm}>
      <Modal.Header>{hosetitle}</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field style={{ display: "flex", alignItems: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "80%",
                paddingRight: "10px",
              }}
            >
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                RS23 Compliant Unit Price:
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                }}
              >
                $
              </div>
            </div>

            <Input
              name="rs23price"
              placeholder={priceText}
              onChange={onChangePrice}
              error={!isValid}
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          </Form.Field>

          <div className="button-row">
            <Button
              style={{
                backgroundColor: "#AFABAB",
                color: "#fff",
                flex: 1,
                marginRight: "10px",
              }}
              onClick={onCloseForm}
            >
              CANCEL
            </Button>
            <Button
              style={{
                backgroundColor: "rgb(102, 204, 35)",
                color: "#fff",
                flex: 1,
              }}
              onClick={onSavePrice}
            >
              SAVE
            </Button>
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default RS23SetPriceForm;
