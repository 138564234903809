import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  Grid,
  Icon,
  Menu,
  Message,
  Modal,
  TextArea,
  Transition,
} from "semantic-ui-react";
import { Control, Errors } from "react-redux-form";
import Loader from "../../../components/Loader";
import MetaData from "../../../components/MetaData";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as authReducer from "../auth.reducer";
import { showSuccessToast, showErrorToast } from "../../../utils/toastUtils";
import "./login.css";
import { apiHealthCheck, apiSendContactForm } from "../auth.api";
import appleIcon from "../../../assets/images/login/app-store.png";
import googleIcon from "../../../assets/images/login/google-play.png";
import FeatureLeft from "../../../assets/images/login/feature-left.png";
import FeatureLeftMobile from "../../../assets/images/login/feature-left-mobile.png";
import FeatureRight from "../../../assets/images/login/feature-right.png";
import FeatureRightMobileAU from "../../../assets/images/login/feature-right-mobile-AU.png";
import FeatureRightMobileNZ from "../../../assets/images/login/feature-right-mobile-NZ.png";
import FeatureRightMobileNZTest from "../../../assets/images/login/feature-right-mobile-NZ.png";
import FeatureLeft2 from "../../../assets/images/login/feature-left-2.png";
import FeatureRight2 from "../../../assets/images/login/feature-right-2.png";
import FeatureLeft3 from "../../../assets/images/login/feature-left-3.png";
import FeatureLeft3Mobile from "../../../assets/images/login/feature-left-3-mobile.png";
import logoImage from "../../../assets/images/logo.png";

const polyglot = window.polyglot;

const Login = ({
  auth,
  forms,
  history,
  actionResetState,
  actionResendAccountVerificationEmail,
  actionlogIn,
}) => {
  const [countryCode, setCountryCode] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [hidden, setHidden] = useState(true);
  const [width, setWidth] = useState(0);
  const [openLogin, setOpenLogin] = useState(false);
  const [formSent, setFormSent] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    actionResetState("forms.loginForm");
    window.addEventListener("resize", updateDimensions);
    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  useEffect(() => {
    updateDimensions();
    const currentCountryCode = localStorage.getItem("countryCode");
    setCountryCode(currentCountryCode);
  }, []);

  useEffect(() => {
    console.log("Current country code: " + countryCode);
  }, [countryCode]);

  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const openCreateAccount = () => {
    history.push("/createAccount");
  };

  const forgotPassword = () => {
    history.push("/forgotPassword");
  };

  const resendVerifyAccountEmailHandler = async () => {
    setLoading(true);
    const { loginForm } = forms;
    await actionResendAccountVerificationEmail(loginForm.email);
    const { response } = auth;
    if (response) {
      if (response.status !== 200) {
        return showErrorToast("Something went wrong");
      }
      const successMessage =
        "We have sent you a new email with the verfication link. Please check your Inbox";

      showSuccessToast(successMessage, false);
      setLoading(false);
      setErrorFlag(true);
      setErrorMessage("");
    }
  };

  const Timer = (ms) => new Promise((res) => setTimeout(res, ms));

  const HealthCheck = async () => {
    let currentTry = 0;
    while (true) {
      const health = await apiHealthCheck();

      if (health) {
        return health;
      }
      if (currentTry < 2) {
        await Timer(3000);
        currentTry++;
      } else {
        return false;
      }
    }
  };

  function handleChange(dataType, value) {
    let newState = [];
    newState.push(formData);
    let jobData = newState.map((item, i) => {
      if (i == 0) {
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setFormData(jobData[0]);
  }

  const Login = async () => {
    const { loginForm } = forms;
    setLoading(true);

    const health = await HealthCheck();

    if (health) {
      const result = await actionlogIn(loginForm);
      if (result) {
        if (result.statusCode >= 400) {
          setLoading(false);
          setErrorFlag(true);
          setErrorMessage(result.description);
        }
      }
    } else {
      setLoading(false);
      setErrorFlag(true);
      setErrorMessage("Server is down. Please try again later");
    }
  };

  const ContactUs = async () => {
    if (
      formData.firstName === "" ||
      formData.lastName === "" ||
      formData.phoneNumber === "" ||
      formData.email === "" ||
      formData.message === ""
    ) {
      showErrorToast("Please fill in all fields");
      return false;
    }
    setLoading(true);
    try {
      const contactFormData = await apiSendContactForm(formData);
      if (contactFormData) {
        setFormSent(true);
        showSuccessToast(
          <div>
            <i className="check icon" /> Form Sent Successfully
          </div>
        );
      } else {
        setLoading(false);
        showErrorToast("Something went wrong");
      }
    } catch {
      setLoading(false);
      showErrorToast("Something went wrongs");
    }
  };

  const showPassword = () => {
    setHidden(!hidden);
  };

  const showLogin = (show) => {
    setOpenLogin(show);
  };

  const mobileScreen = () => {
    return (
      <>
        <div className="main-menu-public-mobile">
          <Menu inverted className="profile-menu">
            <Menu.Menu
              position="left"
              className="user-menu-public"
              style={{ position: "absolute", left: 20, top: -35 }}
            >
              <Menu.Item>
                <img
                  alt="BOA"
                  src={logoImage}
                  className="boa-logo-menu"
                  style={{ top: 0, width: 90, height: 45 }}
                />
              </Menu.Item>
            </Menu.Menu>
            <Menu.Menu
              position="right"
              className="user-menu-public"
              style={{ position: "absolute", right: 5, top: -30 }}
            >
              <Menu.Item>
                <Button
                  onClick={() => showLogin(true)}
                  content="SIGN IN"
                  style={{
                    width: 120,
                    borderRadius: 30,
                    backgroundColor: "transparent",
                    border: "3px solid #66cc23",
                    color: "#fff",
                    paddingTop: 10,
                    paddingBottom: 10,
                  }}
                ></Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
        <section
          className="feature-heading"
          style={{
            marginTop: -72,
          }}
        >
          <div className="content-max-width-mobile">
            <div className="feature-left-mobile">
              <div class="overlay">
                <img src={FeatureLeftMobile}></img>
              </div>
            </div>
            <div className="feature-center-mobile"></div>
            <div
              className="feature-right-mobile"
              style={{ height: countryCode === "NZ" ? 660 : 960 }}
            >
              <img
                style={{ height: countryCode === "NZ" ? 660 : 965 }}
                src={
                  countryCode === "NZ"
                    ? FeatureRightMobileNZ
                    : FeatureRightMobileAU
                }
              ></img>
            </div>
            <div className="feature-content">
              <div className="content-box-mobile">
                <div>
                  <span
                    style={{
                      color: "#fff",
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    LOOKING FOR THE NEAREST <br />
                    HYDRAULIC HOSE FITTER?
                  </span>
                </div>
                <div style={{ paddingTop: 20 }}>
                  <span
                    style={{
                      color: "#66cc23",
                      fontSize: 30,
                      fontWeight: "bold",
                      lineHeight: 1.2,
                    }}
                  >
                    MOBILE HYDRAULIC <br /> HOSE REPAIR <br />
                    NEAR YOU!
                  </span>
                </div>
                <div style={{ paddingTop: 30 }}>
                  <span style={{ color: "#fff", fontSize: 15 }}>
                    Choose and call. One of our network of <br />
                    trained hose mechanics will arrive <br />
                    with a fully stocked unit equipped to replace
                    <br />
                    hydraulic hoses onsite.
                  </span>
                </div>
                <div style={{ paddingTop: 30, paddingLeft: 10 }}>
                  <Button
                    onClick={openCreateAccount}
                    content="TRY FOR FREE"
                    style={{
                      width: 180,
                      borderRadius: 30,
                      backgroundColor: "#66cc23",
                      color: "#fff",
                    }}
                  />
                </div>
                <div style={{ paddingTop: 15, paddingLeft: 10 }}>
                  <a
                    href="https://www.youtube.com/playlist?list=PLVwFEq-7sG5T1GaTQk05opiBOvThcCUjD"
                    target="_blank"
                  >
                    <Button
                      style={{
                        width: 180,
                        borderRadius: 30,
                        backgroundColor: "transparent",
                        border: "3px solid #66cc23",
                        color: "#fff",
                        padding: "5px 0px",
                      }}
                    >
                      <Grid style={{ margin: 0, padding: 0 }}>
                        <Grid.Column
                          width={12}
                          style={{ margin: 0, padding: 0, paddingTop: 8 }}
                        >
                          <span>LEARN HOW</span>
                        </Grid.Column>
                        <Grid.Column
                          width={4}
                          style={{ margin: 0, padding: 0 }}
                        >
                          <Button
                            circular
                            icon="play"
                            size="mini"
                            style={{
                              backgroundColor: "#66cc23",
                              color: "#fff",
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="content-max-width-mobile2"
            style={{
              backgroundColor: "#fff",
              paddingLeft: 20,
              paddingRight: 20,
              zIndex: 10,
            }}
          >
            <div style={{ paddingTop: 70, width: "100%", textAlign: "center" }}>
              <span
                style={{
                  color: "#66cc23",
                  fontSize: 36,
                  fontWeight: "bold",
                  lineHeight: 1.2,
                }}
              >
                GET YOUR MACHINE <br /> UP & RUNNING
              </span>
            </div>
            <div
              style={{
                paddingTop: 10,
                width: "100%",
                textAlign: "center",
                paddingBottom: 20,
              }}
            >
              <span style={{ color: "#000", fontSize: 17 }}>
                Find the nearest hose mechanic for your <br />
                machine with the BOAhub today - just <br />
                choose and call.
              </span>
            </div>
            <div class="app-stores" style={{ textAlign: "center" }}>
              <a
                href="https://apps.apple.com/nz/app/boahub/id1439925780"
                target="_blank"
              >
                <img
                  data-pagespeed-url-hash="2388004890"
                  src={appleIcon}
                  onerror="onerror=null;pagespeed.lazyLoadImages.loadIfVisibleAndMaybeBeacon(this);"
                  class="icon"
                  style={{ width: 200, marginBottom: 20, marginTop: 20 }}
                />
              </a>{" "}
              <br />
              <a
                href="https://play.google.com/store/apps/details?id=com.mobileapp.boa"
                target="_blank"
              >
                <img
                  data-pagespeed-url-hash="1381415003"
                  src={googleIcon}
                  onerror="onerror=null;pagespeed.lazyLoadImages.loadIfVisibleAndMaybeBeacon(this);"
                  class="icon"
                  style={{ width: 200 }}
                />
              </a>
            </div>
          </div>
          <div
            className="content-max-width-mobile"
            style={{ paddingTop: 0, marginTop: -150, zIndex: 9 }}
          >
            <div
              className="feature-left-mobile"
              style={{ backgroundColor: "#fff" }}
            ></div>
            <div className="feature-center-mobile2"></div>
            <div className="feature-right-mobile2">
              <div class="overlay">
                <img src={FeatureRight2}></img>
              </div>
            </div>
            <div className="feature-content">
              <div className="content-box-mobile">
                <img
                  className="mobile"
                  src={FeatureLeft2}
                  style={{
                    opacity: 1,
                    objectFit: "contain",
                    width: "49vw",
                    height: 660,
                    zIndex: 10,
                    position: "absolute",
                    top: 150,
                    left: "26vw",
                    maxWidth: 300,
                  }}
                />
                <div
                  style={{
                    paddingTop: 680,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#66cc23",
                      fontSize: 40,
                      fontWeight: "bold",
                      lineHeight: 1.2,
                    }}
                  >
                    HERE'S A QUICK HOW TO
                  </span>
                </div>
                <div
                  style={{
                    width: "100%",
                    paddingTop: "3%",
                    paddingLeft: "37px",
                    paddingRight: "20px",
                    textAlign: "left",
                  }}
                >
                  <span style={{ color: "#fff", fontSize: 19 }}>
                    • Download the app for free or use the website portal
                  </span>
                  <br />
                  <br />
                  <span style={{ color: "#fff", fontSize: 19 }}>
                    • Click 'Find BOApod' Icon
                  </span>
                  <br />
                  <br />
                  <span style={{ color: "#fff", fontSize: 19 }}>
                    • The map will display your location and show the BOApods
                    near you
                  </span>
                  <br />
                  <br />
                  <span style={{ color: "#fff", fontSize: 19 }}>
                    • Click on a BOApod icon to view Operator details
                  </span>
                  <br />
                  <br />
                  <span style={{ color: "#fff", fontSize: 19 }}>
                    • Click phone icon to call BOApod Operator
                  </span>
                </div>
                <div
                  class="app-stores"
                  style={{
                    textAlign: "center",
                    paddingTop: 35,
                    zIndex: 9,
                  }}
                >
                  <Button
                    onClick={openCreateAccount}
                    content="TRY FOR FREE"
                    style={{
                      width: 180,
                      marginRight: 10,
                      borderRadius: 30,
                      backgroundColor: "#66cc23",
                      color: "#fff",
                    }}
                  />
                  <br />
                  <a
                    href="https://www.youtube.com/playlist?list=PLVwFEq-7sG5T1GaTQk05opiBOvThcCUjD"
                    target="_blank"
                  >
                    <Button
                      style={{
                        width: 180,
                        marginTop: 15,
                        borderRadius: 30,
                        backgroundColor: "transparent",
                        border: "3px solid #66cc23",
                        color: "#fff",
                        padding: "5px 0px",
                      }}
                    >
                      <Grid style={{ margin: 0, padding: 0 }}>
                        <Grid.Column
                          width={12}
                          style={{ margin: 0, padding: 0, paddingTop: 8 }}
                        >
                          <span>LEARN HOW</span>
                        </Grid.Column>
                        <Grid.Column
                          width={4}
                          style={{ margin: 0, padding: 0 }}
                        >
                          <Button
                            circular
                            icon="play"
                            size="mini"
                            style={{
                              backgroundColor: "#66cc23",
                              color: "#fff",
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </Button>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            className="content-max-width-mobile2"
            style={{
              backgroundColor: "#fff",
              paddingLeft: 20,
              paddingRight: 20,
              zIndex: 10,
            }}
          >
            <div style={{ paddingTop: 70, width: "100%", textAlign: "center" }}>
              <span
                style={{
                  color: "#66cc23",
                  fontSize: 36,
                  fontWeight: "bold",
                  lineHeight: 1.2,
                }}
              >
                WANT TO BE PART OF OUR NETWORK?
              </span>
            </div>
            <div
              style={{
                paddingTop: 20,
                width: "100%",
                textAlign: "center",
                paddingBottom: 20,
              }}
            >
              <span style={{ color: "#000", fontSize: 19 }}>
                Join a rapidly growing network of heavy diesel mechanics
                throughout Australia & New Zealand.
              </span>
              <br />
              <br />
              <span style={{ color: "#000", fontSize: 19 }}>
                We give you the key components required to repair and maintain
                hydraulic hoses, either for your own business or as another
                revenue stream.
              </span>
            </div>
            <div
              class="app-stores"
              style={{
                textAlign: "center",
                paddingBottom: 50,
                paddingTop: 20,
              }}
            >
              <a
                href="https://boahydraulics.com/contact-us"
                target="_blank"
                style={{ marginLeft: 10 }}
              >
                <Button
                  content="GET IN TOUCH"
                  style={{
                    width: 180,
                    marginLeft: 10,
                    borderRadius: 30,
                    backgroundColor: "transparent",
                    border: "3px solid #66cc23",
                    color: "#000",
                  }}
                ></Button>
              </a>
            </div>
          </div>
          <div
            className="content-max-width-mobile"
            style={{ paddingTop: 0, marginTop: -460, zIndex: 9 }}
          >
            <div
              className="feature-left-mobile"
              style={{ backgroundColor: "#fff" }}
            ></div>
            <div className="feature-center-mobile2"></div>
            <div className="feature-right-mobile2">
              <div class="overlay">
                <img src={FeatureLeft3Mobile}></img>
              </div>
            </div>
            <div className="feature-content">
              <div className="content-box-mobile">
                <div
                  style={{
                    paddingTop: 720,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#66cc23",
                      fontSize: 40,
                      fontWeight: "bold",
                      lineHeight: 1.2,
                    }}
                  >
                    LET’S GET IN TOUCH
                  </span>
                </div>
                <div
                  style={{ paddingTop: 30, textAlign: "left", paddingLeft: 70 }}
                >
                  <span style={{ color: "#fff", fontSize: 19 }}>
                    <b>NZ:</b> 0800 20 20 20 <br />
                    <b>AU:</b> 1800 40 30 40 <br />
                    <b>PHONE:</b> +64 9-579 2103
                  </span>
                  <br />
                  <br />
                  <span style={{ color: "#fff", fontSize: 19 }}>
                    <b>HEAD OFFICE:</b> <br />
                    7 DAVID MCCATHIE PLACE, <br />
                    SILVERDALE, AUCKLAND 0932
                  </span>
                  <br />
                  <br />
                  <span style={{ color: "#fff", fontSize: 19 }}>
                    <b>AUSTRALIA DISTRIBUTION:</b> <br />
                    13-15 MASSEY AVE, <br />
                    LEETON, NSW 2705
                  </span>
                  <br />
                  <br />
                  <span style={{ color: "#fff", fontSize: 19 }}>
                    <b>POSTAL ADDRESS:</b> <br />
                    PO BOX 180, <br />
                    SILVERDALE, 0932
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ backgroundColor: "#e6e6e6", height: 50, width: "100vw" }}
          ></div>
          <div
            style={{
              backgroundColor: "#fff",
              height: "100%",
              width: "100vw",
              padding: 30,
            }}
          >
            <div
              style={{
                width: "100%",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  color: "#000",
                  fontSize: 40,
                  fontWeight: "bold",
                  lineHeight: 1.2,
                }}
              >
                LET’S TALK
              </span>
            </div>
            <div style={{ paddingTop: 30, paddingLeft: "0px" }}>
              <Form onSubmit={ContactUs} disabled={loading || formSent}>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    id="form-subcomponent-shorthand-input-first-name"
                    placeholder="FIRST"
                    value={formData?.firstName}
                    onChange={(e, data) =>
                      handleChange("firstName", data.value)
                    }
                    disabled={loading || formSent}
                  />
                  <Form.Input
                    fluid
                    id="form-subcomponent-shorthand-input-last-name"
                    placeholder="LAST"
                    value={formData?.lastName}
                    onChange={(e, data) => handleChange("lastName", data.value)}
                    disabled={loading || formSent}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    id="form-subcomponent-shorthand-input-phone"
                    placeholder="PHONE"
                    value={formData?.phoneNumber}
                    onChange={(e, data) =>
                      handleChange("phoneNumber", data.value)
                    }
                    disabled={loading || formSent}
                  />
                  <Form.Input
                    fluid
                    id="form-subcomponent-shorthand-input-email"
                    placeholder="EMAIL"
                    value={formData?.email}
                    onChange={(e, data) => handleChange("email", data.value)}
                    disabled={loading || formSent}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Field
                    control={TextArea}
                    rows={10}
                    placeholder="MESSAGE"
                    value={formData?.message}
                    onChange={(e, data) => handleChange("message", data.value)}
                    disabled={loading || formSent}
                  />
                </Form.Group>
              </Form>
            </div>
            <div
              class="app-stores"
              style={{
                textAlign: "center",
                paddingTop: 10,
                zIndex: 9,
                paddingBottom: 70,
              }}
            >
              <Button
                onClick={() => ContactUs()}
                content={formSent ? "SENT" : loading ? "SENDING" : "SEND"}
                style={{
                  width: 180,
                  borderRadius: 30,
                  backgroundColor: "#66cc23",
                  color: "#fff",
                }}
                disabled={loading || formSent}
              />
            </div>
          </div>
        </section>
      </>
    );
  };

  return (
    <React.Fragment>
      <MetaData title="Login" />
      <Transition visible={openLogin} animation="scale" duration={500}>
        <Modal
          className="animated-modal"
          dimmer="blurring"
          size="tiny"
          style={{ width: 450, marginLeft: -220 }}
          onClose={() => showLogin(false)}
          onOpen={() => showLogin(true)}
          open={openLogin}
        >
          <Modal.Content style={{ padding: 0 }}>
            <Grid
              columns="equal"
              className="login-container"
              style={{ width: "100%" }}
            >
              <Grid.Column>
                <div
                  className="ui centered container login"
                  style={{ marginTop: 0 }}
                >
                  {loading && <Loader text="Logging in" />}
                  <div className="login-box">
                    <div className="ui">
                      <Form
                        error={errorFlag}
                        className="ui form"
                        model="forms.loginForm"
                        onSubmit={Login}
                      >
                        <Form.Field required>
                          <label>{polyglot.t("form.email")}</label>
                          <Control
                            type="email"
                            model="forms.loginForm.email"
                            placeholder={polyglot.t("form.email")}
                            required
                            validateOn="change"
                            className="input-box"
                          />
                          <Errors
                            style={{ color: "red" }}
                            model="forms.loginForm.email"
                            show="touched"
                            messages={{
                              valueMissing: "Email is required",
                              typeMismatch: "Invalid email address",
                            }}
                          />
                        </Form.Field>
                        <Form.Field required>
                          <label>{polyglot.t("form.password")}</label>
                          <Grid columns="equal" style={{ margin: 0 }}>
                            <Grid.Column width={15} style={{ padding: 0 }}>
                              <Control
                                type={hidden ? "password" : "text"}
                                model="forms.loginForm.password"
                                placeholder={polyglot.t("form.password")}
                                required
                                validateOn="blur"
                                className="input-box"
                                style={{ width: "92.5%" }}
                              />
                            </Grid.Column>
                            <Grid.Column
                              style={{
                                padding: 0,
                              }}
                            >
                              <Button
                                basic
                                icon
                                onClick={showPassword}
                                type="button"
                                style={{ marginLeft: -20 }}
                              >
                                {hidden ? (
                                  <Icon name="eye" />
                                ) : (
                                  <Icon name="eye slash" />
                                )}
                              </Button>
                            </Grid.Column>
                          </Grid>
                          <Errors
                            style={{ color: "red" }}
                            model="forms.loginForm.password"
                            show="touched"
                            messages={{
                              valueMissing: "Password is required",
                            }}
                          />
                        </Form.Field>
                        {errorFlag && (
                          <Message
                            error={true}
                            content={errorMessage}
                            className="error-message"
                          />
                        )}
                        <Grid.Row className="forgot-password-grid-margin">
                          <Grid.Column>
                            <div className="button-container">
                              <Button
                                secondary
                                size="small"
                                type="submit"
                                disabled={loading}
                              >
                                {loading ? "Logging in..." : "Login"}
                              </Button>
                            </div>
                          </Grid.Column>
                          <Grid.Column>
                            <span
                              className="forgot-password"
                              onClick={forgotPassword}
                            >
                              {polyglot.t("login.forgotPassword")}
                            </span>
                          </Grid.Column>
                        </Grid.Row>
                      </Form>
                    </div>
                  </div>
                </div>
                <Grid.Row
                  className="create-account-box"
                  style={{ marginTop: -15 }}
                >
                  <Grid.Column>
                    Don't have an account?
                    <span
                      className="create-account"
                      onClick={openCreateAccount}
                    >
                      {polyglot.t("login.createAccount")}
                    </span>
                  </Grid.Column>
                </Grid.Row>
              </Grid.Column>
            </Grid>
          </Modal.Content>
        </Modal>
      </Transition>
      {width > 800 ? (
        <>
          <div className="main-menu-public">
            <Menu inverted className="profile-menu">
              <Menu.Menu
                position="right"
                className="user-menu-public"
                style={{ position: "absolute", right: 0, top: -40 }}
              >
                <Menu.Item as="span" className="top-menu">
                  EXISTING BOAhub USER?
                </Menu.Item>
                <Menu.Item>
                  <Button
                    onClick={() => showLogin(true)}
                    content="SIGN IN HERE"
                    style={{
                      borderRadius: 30,
                      backgroundColor: "#66cc23",
                      color: "#fff",
                    }}
                  />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </div>
          <section
            className="feature-heading"
            style={{
              marginTop: -72,
              backgroundColor: "#8bc2f7",
            }}
          >
            <div className="content-max-width">
              <div className="feature-left">
                <div class="overlay">
                  <img src={FeatureLeft}></img>
                </div>
              </div>
              <div className="feature-center"></div>
              <div className="feature-right">
                <img src={FeatureRight}></img>
              </div>
              <div className="feature-content">
                <div className="content-box" style={{ paddingLeft: 200 }}>
                  <div>
                    <span
                      style={{
                        color: "#fff",
                        fontSize: 22,
                        fontWeight: "bold",
                      }}
                    >
                      LOOKING FOR THE NEAREST <br />
                      HYDRAULIC HOSE FITTER?
                    </span>
                  </div>
                  <div style={{ paddingTop: 30 }}>
                    <span
                      style={{
                        color: "#66cc23",
                        fontSize: 42,
                        fontWeight: "bold",
                        lineHeight: 1.2,
                      }}
                    >
                      MOBILE HYDRAULIC <br /> HOSE REPAIR NEAR <br />
                      YOU!
                    </span>
                  </div>
                  <div style={{ paddingTop: 30 }}>
                    <span style={{ color: "#fff", fontSize: 19 }}>
                      Choose and call. One of our network of <br />
                      trained hose mechanics will arrive with
                      <br />
                      a fully stocked unit equipped to replace
                      <br />
                      hydraulic hoses onsite.
                    </span>
                  </div>
                  <div style={{ paddingTop: 60 }}>
                    <Button
                      onClick={openCreateAccount}
                      content="TRY FOR FREE"
                      style={{
                        width: 180,
                        borderRadius: 30,
                        backgroundColor: "#66cc23",
                        color: "#fff",
                      }}
                    />
                  </div>
                  <div style={{ paddingTop: 20 }}>
                    <a
                      href="https://www.youtube.com/playlist?list=PLVwFEq-7sG5T1GaTQk05opiBOvThcCUjD"
                      target="_blank"
                    >
                      <Button
                        style={{
                          width: 180,
                          borderRadius: 30,
                          backgroundColor: "transparent",
                          border: "3px solid #66cc23",
                          color: "#fff",
                          padding: "5px 0px",
                        }}
                      >
                        <Grid style={{ margin: 0, padding: 0 }}>
                          <Grid.Column
                            width={12}
                            style={{ margin: 0, padding: 0, paddingTop: 8 }}
                          >
                            <span>LEARN HOW</span>
                          </Grid.Column>
                          <Grid.Column
                            width={4}
                            style={{ margin: 0, padding: 0 }}
                          >
                            <Button
                              circular
                              icon="play"
                              size="mini"
                              style={{
                                backgroundColor: "#66cc23",
                                color: "#fff",
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="content-max-width2"
              style={{ backgroundColor: "#fff" }}
            >
              <div
                style={{ paddingTop: 70, width: "100%", textAlign: "center" }}
              >
                <span
                  style={{
                    color: "#66cc23",
                    fontSize: 32,
                    fontWeight: "bold",
                    lineHeight: 1.2,
                  }}
                >
                  GET YOUR MACHINE UP & RUNNING
                </span>
              </div>
              <div
                style={{
                  paddingTop: 10,
                  width: "100%",
                  textAlign: "center",
                  paddingBottom: 20,
                }}
              >
                <span style={{ color: "#000", fontSize: 19 }}>
                  Find the nearest hose mechanic for your machine with the{" "}
                  <br />
                  BOAhub today - just choose and call.
                </span>
              </div>
              <div
                class="app-stores"
                style={{ textAlign: "center", paddingBottom: 80 }}
              >
                <a
                  href="https://apps.apple.com/nz/app/boahub/id1439925780"
                  target="_blank"
                  style={{ marginRight: 10 }}
                >
                  <img
                    data-pagespeed-url-hash="2388004890"
                    src={appleIcon}
                    onerror="onerror=null;pagespeed.lazyLoadImages.loadIfVisibleAndMaybeBeacon(this);"
                    class="icon"
                    style={{ width: 200, marginBottom: 20, marginTop: 20 }}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.mobileapp.boa"
                  target="_blank"
                  style={{ marginLeft: 10 }}
                >
                  <img
                    data-pagespeed-url-hash="1381415003"
                    src={googleIcon}
                    onerror="onerror=null;pagespeed.lazyLoadImages.loadIfVisibleAndMaybeBeacon(this);"
                    class="icon"
                    style={{ width: 200 }}
                  />
                </a>
              </div>
            </div>
            <div className="content-max-width">
              <div
                className="feature-left2"
                style={{ backgroundColor: "#fff" }}
              ></div>
              <div className="feature-center2"></div>
              <div className="feature-right2">
                <div class="overlay">
                  <img src={FeatureRight2}></img>
                </div>
              </div>
              <div className="feature-content">
                <div className="content-box2" style={{ paddingLeft: "800px" }}>
                  <img
                    className="mobile"
                    src={FeatureLeft2}
                    style={{
                      opacity: 1,
                      objectFit: "contain",
                      width: 300,
                      height: 660,
                      zIndex: 10,
                      position: "absolute",
                      top: 0,
                      left: 150,
                    }}
                  />
                  <div
                    style={{
                      paddingTop: 25,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#66cc23",
                        fontSize: 40,
                        fontWeight: "bold",
                        lineHeight: 1.2,
                      }}
                    >
                      HERE'S A QUICK HOW TO
                    </span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      paddingTop: "3%",
                      paddingLeft: "20px",
                    }}
                  >
                    <span style={{ color: "#fff", fontSize: 19 }}>
                      • Download the app for free or use the website portal
                    </span>
                    <br />
                    <br />
                    <span style={{ color: "#fff", fontSize: 19 }}>
                      • Click 'Find BOApod' Icon
                    </span>
                    <br />
                    <br />
                    <span style={{ color: "#fff", fontSize: 19 }}>
                      • The map will display your location and show the BOApods
                      near you
                    </span>
                    <br />
                    <br />
                    <span style={{ color: "#fff", fontSize: 19 }}>
                      • Click on a BOApod icon to view Operator details
                    </span>
                    <br />
                    <br />
                    <span style={{ color: "#fff", fontSize: 19 }}>
                      • Click phone icon to call BOApod Operator
                    </span>
                  </div>
                  <div
                    class="app-stores"
                    style={{
                      textAlign: "left",
                      paddingTop: 60,
                      zIndex: 9,
                      paddingLeft: "20px",
                    }}
                  >
                    <Button
                      onClick={openCreateAccount}
                      content="TRY FOR FREE"
                      style={{
                        width: 180,
                        marginRight: 10,
                        borderRadius: 30,
                        backgroundColor: "#66cc23",
                        color: "#fff",
                      }}
                    />
                    <a
                      href="https://www.youtube.com/playlist?list=PLVwFEq-7sG5T1GaTQk05opiBOvThcCUjD"
                      target="_blank"
                    >
                      <Button
                        style={{
                          width: 180,
                          marginLeft: 10,
                          borderRadius: 30,
                          backgroundColor: "transparent",
                          border: "3px solid #66cc23",
                          color: "#fff",
                          padding: "5px 0px",
                        }}
                      >
                        <Grid style={{ margin: 0, padding: 0 }}>
                          <Grid.Column
                            width={12}
                            style={{ margin: 0, padding: 0, paddingTop: 8 }}
                          >
                            <span>LEARN HOW</span>
                          </Grid.Column>
                          <Grid.Column
                            width={4}
                            style={{ margin: 0, padding: 0 }}
                          >
                            <Button
                              circular
                              icon="play"
                              size="mini"
                              style={{
                                backgroundColor: "#66cc23",
                                color: "#fff",
                              }}
                            />
                          </Grid.Column>
                        </Grid>
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="content-max-width2"
              style={{ backgroundColor: "#fff" }}
            >
              <div
                style={{ paddingTop: 150, width: "100%", textAlign: "center" }}
              >
                <span
                  style={{
                    color: "#66cc23",
                    fontSize: 32,
                    fontWeight: "bold",
                    lineHeight: 1.2,
                  }}
                >
                  WANT TO BE PART OF OUR NETWORK?
                </span>
              </div>
              <div
                style={{
                  paddingTop: 20,
                  width: "100%",
                  textAlign: "center",
                  paddingBottom: 20,
                }}
              >
                <span style={{ color: "#000", fontSize: 19 }}>
                  Join a rapidly growing network of heavy diesel mechanics
                  throughout Australia & New Zealand.
                </span>
                <br />
                <br />
                <span style={{ color: "#000", fontSize: 19 }}>
                  We give you the key components required to repair and maintain
                  hydraulic hoses, either for your <br />
                  own business or as another revenue stream.
                </span>
              </div>
              <div
                class="app-stores"
                style={{
                  textAlign: "center",
                  paddingBottom: 150,
                  paddingTop: 20,
                }}
              >
                <a
                  href="https://boahydraulics.com/contact-us"
                  target="_blank"
                  style={{ marginLeft: 10 }}
                >
                  <Button
                    content="GET IN TOUCH"
                    style={{
                      width: 180,
                      marginLeft: 10,
                      borderRadius: 30,
                      backgroundColor: "transparent",
                      border: "3px solid #66cc23",
                      color: "#000",
                    }}
                  ></Button>
                </a>
              </div>
            </div>
            <div className="content-max-width">
              <div className="feature-left">
                <div class="overlay">
                  <img src={FeatureLeft3}></img>
                </div>
              </div>
              <div className="feature-center3"></div>
              <div className="feature-right3">
                <div className="form-background"></div>
              </div>
              <div className="feature-content">
                <div
                  className="content-box"
                  style={{ paddingLeft: 200, paddingTop: 80 }}
                >
                  <div style={{ paddingTop: 30 }}>
                    <span
                      style={{
                        color: "#66cc23",
                        fontSize: 42,
                        fontWeight: "bold",
                        lineHeight: 1.2,
                      }}
                    >
                      LET’S GET IN TOUCH
                    </span>
                  </div>
                  <div style={{ paddingTop: 30 }}>
                    <span style={{ color: "#fff", fontSize: 19 }}>
                      <b>NZ:</b> 0800 20 20 20 <br />
                      <b>AU:</b> 1800 40 30 40 <br />
                      <b>PHONE:</b> +64 9-579 2103
                    </span>
                    <br />
                    <br />
                    <span style={{ color: "#fff", fontSize: 19 }}>
                      <b>HEAD OFFICE:</b> <br />
                      7 DAVID MCCATHIE PLACE, <br />
                      SILVERDALE, AUCKLAND 0932
                    </span>
                    <br />
                    <br />
                    <span style={{ color: "#fff", fontSize: 19 }}>
                      <b>AUSTRALIA DISTRIBUTION:</b> <br />
                      13-15 MASSEY AVE, <br />
                      LEETON, NSW 2705
                    </span>
                    <br />
                    <br />
                    <span style={{ color: "#fff", fontSize: 19 }}>
                      <b>POSTAL ADDRESS:</b> <br />
                      PO BOX 180, <br />
                      SILVERDALE, 0932
                    </span>
                  </div>
                </div>
              </div>
              <div className="feature-content">
                <div
                  className="content-box3"
                  style={{ paddingLeft: 900, marginTop: -80 }}
                >
                  <div
                    style={{
                      paddingTop: 70,
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <span
                      style={{
                        color: "#000",
                        fontSize: 40,
                        fontWeight: "bold",
                        lineHeight: 1.2,
                      }}
                    >
                      LET’S TALK
                    </span>
                  </div>
                  <div style={{ paddingTop: 30, paddingLeft: "0px" }}>
                    <Form onSubmit={ContactUs} disabled={loading || formSent}>
                      <Form.Group widths="equal">
                        <Form.Input
                          fluid
                          id="form-subcomponent-shorthand-input-first-name"
                          placeholder="FIRST"
                          value={formData?.firstName}
                          onChange={(e, data) =>
                            handleChange("firstName", data.value)
                          }
                          disabled={loading || formSent}
                        />
                        <Form.Input
                          fluid
                          id="form-subcomponent-shorthand-input-last-name"
                          placeholder="LAST"
                          value={formData?.lastName}
                          onChange={(e, data) =>
                            handleChange("lastName", data.value)
                          }
                          disabled={loading || formSent}
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Input
                          fluid
                          id="form-subcomponent-shorthand-input-phone"
                          placeholder="PHONE"
                          value={formData?.phoneNumber}
                          onChange={(e, data) =>
                            handleChange("phoneNumber", data.value)
                          }
                          disabled={loading || formSent}
                        />
                        <Form.Input
                          fluid
                          id="form-subcomponent-shorthand-input-email"
                          placeholder="EMAIL"
                          value={formData?.email}
                          onChange={(e, data) =>
                            handleChange("email", data.value)
                          }
                          disabled={loading || formSent}
                        />
                      </Form.Group>
                      <Form.Group widths="equal">
                        <Form.Field
                          control={TextArea}
                          rows={10}
                          placeholder="MESSAGE"
                          value={formData?.message}
                          onChange={(e, data) =>
                            handleChange("message", data.value)
                          }
                          disabled={loading || formSent}
                        />
                      </Form.Group>
                    </Form>
                  </div>
                  <div
                    class="app-stores"
                    style={{ textAlign: "center", paddingTop: 30, zIndex: 9 }}
                  >
                    <Button
                      onClick={() => ContactUs()}
                      content={formSent ? "SENT" : loading ? "SENDING" : "SEND"}
                      style={{
                        width: 180,
                        borderRadius: 30,
                        backgroundColor: "#66cc23",
                        color: "#fff",
                      }}
                      disabled={loading || formSent}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        mobileScreen()
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    forms: state.forms,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(authReducer, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
