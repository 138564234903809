import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import Loader from "../../components/Loader";
import { Control } from "react-redux-form";
import CheckList from "./checkList";
import "./reviewMaintenance.css";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import { getErrorMessage } from "../../utils/error.utils";
import _ from "lodash";
import polyglot from "../../i18n";
import * as reviewMaintenanceReducer from "./reviewMaintenance.reducer";
import AddNote from "./AddNote";
import AddImage from "./AddImage";
import {
  Form,
  Grid,
  Button,
  Divider,
  Icon,
  Message,
} from "semantic-ui-react";
import moment from "moment";
import ViewImage from "../../components/ViewImage";

class ReviewMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isNote: false,
      isImage: false,
      isParentImage: false,
      currentPoint: {},
      noteInput: "",
      isError: false,
      errorMessage: "",
      selectedFile: null
    };
  }
  componentDidMount = () => {
    this.props.actionResetForm("maintenanceForm");
    this.getUrlParam();
  };

  updateState = (isError, errorMessage) => {
    this.setState({ isError, errorMessage });
  };

  getUrlParam = async () => {
    const id = this.props.match.params.id;
    if (id) {
      await this.props.actionGetMaintenanceRecord(id);
      const { maintenanceRecord } = this.props;
      const { formData } = maintenanceRecord;

      await this.props.actionSetForm(formData);
      this.props.actionSetFormFields("_id", id);
    }
  };

  manageCheckPoints = async (dataObject, value) => {
    const { maintenanceForm } = this.props;
    const { data } = maintenanceForm;
    let all_points = [...data];
    const index = _.findIndex(data, dataObject);
    let data_to_update = { ...dataObject };
    data_to_update.result = value;
    all_points[index] = data_to_update;
    this.props.actionSetCheckPoints(all_points);
  };

  isFormValid = dataToUpdate => {
    const { formData } = dataToUpdate;
    const { data } = formData;
    // console.log("Form", data);
    const unFilledCheckPoints = _.filter(data, checkPoint => {
      const { result = undefined } = checkPoint;
      return _.isEmpty(result);
    });
    // console.log("unFilledCheckPoints", unFilledCheckPoints);

    return _.isEmpty(unFilledCheckPoints);
  };

  updateMaintenanceRecord = async statusText => {

    const { maintenanceForm } = this.props;
    const { data: noteData } = maintenanceForm;
    const isPresentNote = _.filter(noteData, item => {
      return item.result === "orange" && !item.note;
    });

    if (isPresentNote.length) {

      showErrorToast(
        "Please add Note for all orange check points.",
        5000
      );
      let message = await getErrorMessage(1125);
      return this.updateState(true, message);
    }


    const dataToUpdate = this.getFormDataToUpdate(maintenanceForm, statusText);

    // Check for completed forms
    if (statusText === "completed" && !this.isFormValid(dataToUpdate)) {
      showErrorToast(
        "Please fill all the check points before completing the Maintenance",
        5000
      );
      return;
    }

    await this.props.actionUpdateMaintenanceRecord(dataToUpdate);

    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      showSuccessToast("Scheduled Maintenance updated.");
      this.goToPage();
    } else {
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
  };

  getFormDataToUpdate = (record, statusText) => {
    const { _id, name, maintenanceUnit, data, note, imageUrl } = record;
    let status = statusText;
    const formData = { name, maintenanceUnit, data, note, imageUrl };
    const data_to_update = { _id, status, formData };
    return data_to_update;
  };
  goToPage = () => this.props.history.push("/schedule");

  addNotePage = (dataObject, key) => {
    const { note } = dataObject;

    this.props.setCheckpointKey(key);
    this.setState({ isNote: true, currentPoint: dataObject, noteInput: note });
  };

  addImagePage = (dataObject, key) => {
    this.props.setCheckpointKey(key);
    this.setState({ isImage: true, currentPoint: dataObject });
  };

  closeModal = () => {
    this.setState({
      isNote: false,
      isImage: false,
      isParentImage: false,
      noteInput: ""
    });
  };

  setNote = (e, { value }) => {
    this.setState({ noteInput: value });
  };

  addShortNote = () => {
    const { currentPoint, noteInput } = this.state;
    const { maintenanceForm } = this.props;
    const { data } = maintenanceForm;
    let all_points = [...data];
    const index = _.findIndex(all_points, currentPoint);
    let data_to_update = { ...currentPoint };
    data_to_update.note = noteInput;
    all_points[index] = data_to_update;
    this.props.actionSetCheckPoints(all_points);
    showSuccessToast("Note added");
    this.closeModal();
  };

  uploadImages = async fileData => {
    const data = new FormData();
    data.append("file", fileData);
    await this.props.actionUploadImage(data);
    const { imageResult } = this.props;
    if (imageResult) {
      this.addImage();
    }
  };

  addImage = () => {
    const { maintenanceForm, checkPointKey, imageResult } = this.props;
    const { data } = maintenanceForm;
    const checkPointArray = [...data];

    const checkPoint = { ...data[checkPointKey] };
    const imageArray = [...checkPoint.imageUrl];

    imageArray.push(imageResult);
    checkPoint.imageUrl = imageArray;
    checkPointArray[checkPointKey] = checkPoint;
    this.props.actionSetCheckPoints(checkPointArray);
    this.closeModal();
  };

  closeAddParentImageModal = () => {
    this.setState({ isParentImage: false });
  };

  uploadParentImage = async fileData => {
    const data = new FormData();
    data.append("file", fileData);
    await this.props.actionUploadImage(data);

    const { imageResult, maintenanceForm } = this.props;
    const { imageUrl } = maintenanceForm;
    const imageArray = [...imageUrl];

    imageArray.push(imageResult);
    this.props.actionSetFormFields("imageUrl", imageArray);
    this.closeAddParentImageModal();
  };

  deleteImage = async (imageObject, checkPoint) => {
    const { key: imageKey } = imageObject;
    await this.props.deleteImage(imageKey);

    const imageUrl = [...checkPoint.imageUrl];
    _.remove(imageUrl, o => {
      return o.key === imageKey;
    });
    const { maintenanceForm } = this.props;
    const { data } = maintenanceForm;
    const checkPointArray = [...data];
    const index = _.findIndex(checkPointArray, checkPoint);
    const checkPointObject = { ...checkPoint, imageUrl };
    checkPointArray[index] = checkPointObject;
    this.props.actionSetCheckPoints(checkPointArray);
  };

  deleteParentImage = async imageObject => {
    const { key: imageKey } = imageObject;
    await this.props.deleteImage(imageKey);
    const { maintenanceForm } = this.props;
    const { imageUrl } = maintenanceForm;
    const imageArray = [...imageUrl];
    _.remove(imageArray, o => {
      return o.key === imageKey;
    });
    this.props.actionSetFormFields("imageUrl", imageArray);
  };

  render() {
    const {
      isLoading,
      isNote,
      isError,
      errorMessage,
      isImage,
      noteInput,
      isParentImage
    } = this.state;
    const { maintenanceForm, maintenanceRecord } = this.props;
    const { data, imageUrl = "" } = maintenanceForm;
    const isPending = _.find(data, record => {
      return record.result === "red" || record.result === "orange";
    });

    const {
      assetName,
      assignedToName,
      formName,
      dueDate,
      note
    } = maintenanceRecord;

    const title = `${assetName} (${formName})`;
    const date = moment(dueDate).format("ddd, ll");
    const assignedTo = ` ${assignedToName}, Due: ${date}`;

    const maintenanceUnitText = (
      <Control
        type="number"
        min={0}
        model="forms.maintenanceForm.maintenanceUnit"
        placeholder={polyglot.t("maintenanceForm.label.Kilometers")}
        validateOn="change"
        required
        className="input-box"
      />
    );

    const commentText = (
      <Control.textarea
        model="forms.maintenanceForm.note"
        placeholder="Write short comment"
        validateOn="change"
        rows={3}
        className="input-box"
      />
    );

    const formTitle = (
      <Grid>
        <Grid.Column width={8}>
          <h3>
            <Icon name="clipboard" />
            Maintenance: {title}
          </h3>
          <p><b>Assigned to:</b>{assignedTo}</p>
        </Grid.Column>
        <Grid.Column width={8} textAlign="right">
          <Button
            size="tiny"
            onClick={() => this.goToPage()}
            icon="left arrow"
            content="Go back"
            labelPosition="left"
            className="button-float"
          />
        </Grid.Column>
      </Grid>
    );

    const checkPoints = (
      <CheckList
        maintenanceForm={maintenanceForm}
        manageCheckPoints={this.manageCheckPoints}
        addNotePage={this.addNotePage}
        addImagePage={this.addImagePage}
        deleteImage={this.deleteImage}
      />
    );

    const formActionButton = (
      <span>
        <Button
          size="small"
          onClick={this.goToPage}
          content="Back"
          className="button-float"
        />

        {!isLoading ? (
          <span>
            <Button
              secondary
              size="small"
              className="button-float"
              type="button"
              onClick={() => this.updateMaintenanceRecord("in progress")}
              content="Save"
            />
            <Button
              size="small"
              disabled={isPending}
              className="button-float"
              color="green"
              onClick={() => this.updateMaintenanceRecord("completed")}
              content="Complete"
            />
          </span>
        ) : (
            <Button
              primary
              size="small"
              className="button-float"
              loading
              content="Complete"
            />
          )}
      </span>
    );

    return (
      <div className="review-maintenance-form">
        <MetaData title={title} />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading,please wait</Loader>
          )}

          <Grid.Column width={16} style={{ paddingBottom: "0px" }}>
            {formTitle}
            <Divider />
          </Grid.Column>
          <Grid.Column
            width={16}
            style={{ paddingBottom: "0px" }}
            className="maintenance-component"
          >
            <Form
              className="maintenance-form-box"
              model="form.maintenanceForm"
              error={isError}
            >
              <Grid>
                <Grid.Column width={16} computer={16} mobile={16}>
                  <Form.Field style={{ display: "inline-flex" }}>
                    <label>Note: &nbsp;</label>
                    {note}
                  </Form.Field>
                </Grid.Column>
                <Grid.Column width={16} computer={8} mobile={16}>
                  <Form.Field>
                    <label>
                      {polyglot.t("maintenanceForm.label.Kilometers")}
                    </label>
                    {maintenanceUnitText}
                  </Form.Field>
                </Grid.Column>

                <Grid.Column width={16} computer={16} mobile={16}>
                  {checkPoints}
                </Grid.Column>

                <Grid.Column width={8} computer={8} mobile={16}>
                  <Form.Field>
                    <label>Comment</label>
                    {commentText}
                  </Form.Field>
                </Grid.Column>

                <Grid.Column width={8} computer={8} mobile={16}>
                  <Form.Field>
                    <label>Upload Images</label>
                    {imageUrl &&
                      <ViewImage
                        imageUrl={imageUrl}
                        deleteImage={this.deleteParentImage}
                      />}
                    <Button
                      basic
                      color="blue"
                      content="Image"
                      size="mini"
                      type="button"
                      icon="plus"
                      textAlign="right"
                      disabled={imageUrl.length >= 5}
                      onClick={() => this.setState({ isParentImage: true })}
                    />
                    <p style={{ fontStyle: "italic", fontSize: "12px" }}>Note : Upto 5 images only</p>
                    {imageUrl.length >= 5 ? (<p>You can add 5 images only.</p>) : null}
                  </Form.Field>
                </Grid.Column>

                <Grid.Column
                  width={16}
                  computer={16}
                  mobile={16}
                  style={{ textAlign: "right" }}
                >
                  <Message style={{ textAlign: "left" }} error={true} content={errorMessage} />

                  <Grid>
                    <Grid.Column width={8} mobile={16} computer={16}>
                      {formActionButton}
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            </Form>
          </Grid.Column>

          <Grid.Column width={16} mobile={16} computer={16}>
            <AddNote
              isNote={isNote}
              noteInput={noteInput}
              closeNotePage={this.closeModal}
              setNote={this.setNote}
              addShortNote={this.addShortNote}
            />

            <AddImage
              isImage={isImage}
              closePage={this.closeModal}
              uploadImages={this.uploadImages}
            />
            <AddImage
              isImage={isParentImage}
              closePage={this.closeModal}
              uploadImages={this.uploadParentImage}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceRecord: state.reviewMaintenance.maintenanceRecord,
    checkPointKey: state.reviewMaintenance.checkPointKey,
    maintenanceForm: state.forms.maintenanceForm,
    imageResult: state.reviewMaintenance.imageUrl,
    response: state.reviewMaintenance.response
  };
};

const reducer = { ...reviewMaintenanceReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReviewMaintenance)
);
