import React from "react";
import PropTypes from "prop-types";
import { Message, Grid, Icon, List, Button } from "semantic-ui-react";
import "./locationList.css";

const LocationList = ({ locations = [], openDeleteBox, openAddLocation, openEditForm }) => {
  return (
    <React.Fragment>
      {locations.length > 0 ? (
        <List celled>
          {locations.map((location, l_key) => {
            const { name , _id} = location;

            return (
              <List.Item key={l_key} className="my-location-card">
                <Grid columns="equal" verticalAlign="middle">
                  <Grid.Column
                    computer={10}
                    mobile={8}
                    tablet={8}
                    className="no-padding"
                  >
                    <Icon name="marker" />
                    <span style={{ textTransform: "capitalize" }}> {name}</span>
                  </Grid.Column>
                  <Grid.Column
                    textAlign="right"
                    width={6}
                    mobile={16}
                    tablet={8}
                    computer={6}
                  >
                   <Button
                      basic
                      content="Edit"
                      size="tiny"
                      icon="edit"
                      color="blue"
                      onClick={() => openEditForm({name, _id})}
                    />
                    <Button
                      basic
                      content="Remove"
                      size="tiny"
                      icon="delete"
                      color="red"
                      onClick={() => openDeleteBox(name)}
                    />
                  </Grid.Column>
                </Grid>
              </List.Item>
            );
          })}
        </List>
      ) : (
        <Message
          info
          header="Sorry, No locations found!"
          content={
            <div>
              <span className="cart-go-search-link" onClick={openAddLocation}>
                Click here to add new location.
              </span>
            </div>
          }
        />
      )}
    </React.Fragment>
  );
};

LocationList.propTypes = {
  locations: PropTypes.array,
  openDeleteBox: PropTypes.func,
  openAddLocation: PropTypes.func,
  openEditForm: PropTypes.func
};

export default LocationList;
