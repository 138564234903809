import _ from "lodash";
import { SEAT_TYPE_S3_IMAGE_URL } from "../../constants/appConstants";
export const hoseObjectToSend = (
  hose,
  leftFitting,
  rightFitting,
  hoseLength,
  rightAngle,
  leftAngle,
  hoseDescription,
  makeAtBoa,
  totalPrice,
  spiral,
  hoseAdditionalProducts,
  leftCrimpSize,
  rightCrimpSize,
  leftFerrule,
  rightFerrule,
  leftSeatTypeImage,
  rightSeatTypeImage,
  makeAtBoaCharges,
  isUpdate = false,
  uniqueCode,
  hoseId,
  overAllLength,
  showOal
) => {
  let allItems = [];
  let bore = getBoarObject(hose, hoseLength);
  allItems.push(bore);
  let leftFittingObj = getFittingObject(
    leftFitting,
    leftAngle,
    leftCrimpSize,
    "left"
  );
  leftFittingObj.leftSeatTypeImage = leftSeatTypeImage;
  allItems.push(leftFittingObj);
  let rightFittingObj = getFittingObject(
    rightFitting,
    rightAngle,
    rightCrimpSize,
    "right"
  );
  rightFittingObj.rightSeatTypeImage = rightSeatTypeImage;
  allItems.push(rightFittingObj);
  let spiralObj = {};
  if (!_.isEmpty(spiral)) {
    spiralObj = getSpiralInfo(spiral, hoseLength);
    allItems.push(spiralObj);
  }
  let additionProductsObj = [];
  let leftFerruleObject = {};
  let rightFerruleObject = {};
  if (!_.isEmpty(leftFerrule)) {
    leftFerruleObject = getFerruleObject(leftFerrule, "left");
    allItems.push(leftFerruleObject);
  }
  if (!_.isEmpty(rightFerrule)) {
    rightFerruleObject = getFerruleObject(rightFerrule, "right");
    allItems.push(rightFerruleObject);
  }
  if (hoseAdditionalProducts.length) {
    for (let i = 0; i < hoseAdditionalProducts.length; i++) {
      const {
        stockCode,
        stockDescription,
        pack,
        quantity,
        stockImage1
      } = hoseAdditionalProducts[i];
      let data = {
        stockCode,
        stockDescription,
        pack,
        quantity,
        imageLink: stockImage1
      };
      additionProductsObj.push(data);
    }
  }
  let hoseStockCode = getStockCodeString(
    hose,
    leftFitting,
    rightFitting,
    hoseLength,
    rightAngle,
    leftAngle,
    spiral,
    hoseAdditionalProducts
  );
  if (showOal == true) {
    overAllLength = getOverAllLength(hoseLength, leftFitting, rightFitting);
  }
  // const length = overAllLength ? overAllLength : hoseLength;
  const length =
    overAllLength >= hoseLength ? "OAL: " + overAllLength : "HL: " + hoseLength;
  const hoseDescriptionToUpend =
    hoseStockCode + " – " + rightAngle + "/" + length;
  const { hoseCharge = {}, spiralCharge = {} } = makeAtBoaCharges;
  const makeAtBoaWrap = [];
  const { stockCode: hoseChargeStockCode } = hoseCharge;
  const hoseChargeObject = {
    stockCode: hoseChargeStockCode,
    pack: "Each",
    quantity: 1
  };
  makeAtBoaWrap.push(hoseChargeObject);
  if (!_.isEmpty(spiral)) {
    const { stockCode: spiralChargeStockCode } = spiralCharge;
    const spiralChargeObject = {
      stockCode: spiralChargeStockCode,
      pack: "Metre",
      quantity: Number((hoseLength / 1000).toFixed(2))
    };
    makeAtBoaWrap.push(spiralChargeObject);
  }
  let hoseObject = {
    stockCode: hoseStockCode,
    quantity: 1,
    pack: "Each",
    stockDescription: hoseDescriptionToUpend + " : " + hoseDescription,
    hoseItems: allItems,
    hoseLength: hoseLength,
    additionalProducts: additionProductsObj,
    makeAtBoa,
    overAllLength,
    makeAtBoaWrap: makeAtBoaWrap,
    uniqueCode: uniqueCode,
    hoseId: hoseId
  };
  if (isUpdate) {
    hoseObject.stockDescription = hoseDescriptionToUpend;
  }
  return hoseObject;
};

const getBoarObject = (bore, hoseLength) => {
  const { stockCode, stockImage1 } = bore;
  return {
    type: "bore",
    length: hoseLength,
    stockCode: stockCode,
    position: "middle",
    pack: "Metre",
    imageLink: stockImage1
  };
};

const getFittingObject = (fitting, angle, crimpInfo, position) => {
  const { stockCode, productDimensions, stockImage1 } = fitting;
  const {
    lc,
    threadType1,
    nominalThreadSize1,
    physicalThreadSize1
  } = productDimensions;
  const seatType = `${physicalThreadSize1}mm 'D' ${nominalThreadSize1} ${threadType1}`;
  return {
    type: "Fitting",
    length: lc === "#N/A" || lc === undefined ? 0 : lc,
    stockCode,
    position,
    angle,
    pack: "Each",
    crimpSize: crimpInfo === "#N/A" || crimpInfo === undefined ? 0 : crimpInfo,
    seatType: seatType,
    imageLink: stockImage1
  };
};

const getSpiralInfo = (spiral, hoseLength) => {
  const { stockCode } = spiral;
  return {
    type: "spiral",
    stockCode,
    length: hoseLength,
    position: "spiral",
    pack: "Metre"
  };
};

const getLc = fitting => {
  const { productDimensions } = fitting;
  const { lc } = productDimensions;
  return lc;
};

const getOverAllLength = (hoseLength, left, right) => {
  let leftLength = getLc(left);
  let rightLength = getLc(right);
  let totalLength =
    Number(hoseLength) + Number(leftLength) + Number(rightLength);
  return Number(totalLength.toFixed(2));
};

const getStockCodeString = (
  hose,
  left,
  right,
  hoseLength,
  rightAngle,
  leftAngle,
  spiral,
  additionalProducts
) => {
  let hoseString = "";
  const { shortenedCode: hoseCode } = hose;
  const { shortenedCode: leftCode } = left;
  const { shortenedCode: rightCode } = right;
  //const { shortenedCode: spiralCode } = spiral;
  // hoseString = hoseString + `${hoseCode}_${leftCode}_${leftAngle}_${rightCode}_${rightAngle}_${overAllLength}`;
  hoseString = hoseString + `${hoseCode}-${leftCode}-${rightCode}`;
  // if (spiralCode) {
  //   hoseString = hoseString + `_${spiralCode}`;
  // }
  // if (!_.isEmpty(additionalProducts)) {
  //   _.forEach(additionalProducts, (item,key) => {
  //     const { stockCode } = item;
  //     if (key === 0) {
  //       hoseString = hoseString + `;${stockCode}`;
  //     } else {
  //       hoseString = hoseString + `_${stockCode}`;
  //     }
  //   });
  // }
  return hoseString;
};

export const validateHose = (hose, left, right) => {
  if (_.isEmpty(hose)) {
    return false;
  }
  if (_.isEmpty(left)) {
    return false;
  }
  if (_.isEmpty(right)) {
    return false;
  }
  return true;
};

const getFerruleObject = (ferrule, position) => {
  const { stockCode, stockImage1 } = ferrule;
  return {
    type: "ferrule",
    stockCode,
    position,
    pack: "Each",
    imageLink: stockImage1
  };
};

export const precise_round = (num, dec) => {
  if (num === 0) {
    return " --";
  }
  if (typeof num !== "number" || typeof dec !== "number") return false;
  let num_sign = num >= 0 ? 1 : -1;
  return (
    Math.round(num * Math.pow(10, dec) + num_sign * 0.0001) / Math.pow(10, dec)
  ).toFixed(dec);
};

export const getSeatTypeImage = seatType => {
  if (!seatType) {
    return "";
  }
  seatType = seatType.replace("°", "");
  seatType = seatType + ".png";
  return SEAT_TYPE_S3_IMAGE_URL + seatType;
};
