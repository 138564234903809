import React from "react";
import { Label } from "semantic-ui-react";
import PropTypes from "prop-types";
import UserRoleCheck from "../../components/UserRoleCheck";

const CART = "cart";
const SEARCH = "search";
const PricingPolicy = ({
  policyName,
  discount,
  currency,
  totalDiscount,
  type
}) => {
  switch (type) {
    case CART: {
      return (
        <div className="discount">
          {policyName && (
            <Label color="teal" size="tiny" tag>
              {policyName} policy - applied for this product{" "}
              <UserRoleCheck>
                {discount && <span> with {discount}% discount </span>}
              </UserRoleCheck>
            </Label>
          )}
          {discount > 0 && (
            <UserRoleCheck>
              <Label color="green" size="tiny" tag>
                You save - {currency} {totalDiscount}
              </Label>
            </UserRoleCheck>
          )}
        </div>
      );
    }
    case SEARCH: {
      return (
        <div className="discount">
          {policyName && (
            <Label color="teal" size="tiny" tag>
              {policyName} policy - available for this product{" "}
              <UserRoleCheck>
                {discount && <span> with {discount}% discount </span>}
              </UserRoleCheck>
            </Label>
          )}
          {discount > 0 && (
            <UserRoleCheck>
              <Label color="green" size="tiny" tag>
                You will save - {currency} {totalDiscount}
              </Label>
            </UserRoleCheck>
          )}
        </div>
      );
    }
    default:
      return "";
  }
};

PricingPolicy.propTypes = {
  policyName: PropTypes.string,
  discount: PropTypes.number,
  currency: PropTypes.string,
  totalDiscount: PropTypes.number,
  type: PropTypes.string
};

export default PricingPolicy;
