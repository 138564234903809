import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Button,
  Form,
  Modal,
  Header,
  Divider,
  List,
  Icon,
  Dropdown,
  Message
} from "semantic-ui-react";
import { Control, Errors } from "react-redux-form";
import Loader from "../../../components/Loader";
import * as hoseMechanicReducer from "../../HoseMechanic/hoseMechanic.reducer";
import * as inventoryLocationsReducer from "../../InventoryLocation/inventoryLocation.reducer";
import * as cartReducer from "../../Cart/cart.reducer";
import _ from "lodash";
import {
  ASSET_ID_FOR_URL_PARAM,
  HOSE_ID_FOR_URL_PARAM,
  ASSET_NAME_FOR_URL_PARAM,
  ASSET_MODEL_FOR_URL_PARAM,
  ASSET_SUB_MODEL_FOR_URL_PARAM,
  ASSET_POSITION_FOR_URL_PARAM
} from "../../../constants/appConstants";
import { showSuccessToast } from "../../../utils/toastUtils";
import polyglot from "../../../i18n";
import { getErrorMessage } from "../../../utils/error.utils";

class AddToInventoryModalJSX extends React.Component {
  constructor() {
    super();
    this.state = {
      selectedLocation: null,
      isModalLoading: false,
      isOpenForm: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentWillMount = () => {
    this.getLocations();
  };

  getLocations = async () => {
    await this.props.actionClearReducerItem();
    await this.props.actionGetAllLocationToDropdown();
  };
  componentWillUnmount = () => {
    this.props.actionClearReducerItem();
  };
  locationSelectHandler = async (e, data) => {
    e.preventDefault();
    this.setState({ isModalLoading: true, isError: false });
    this.props.actionClearReducerItem();
    const { value } = data;
    const { hoseId } = this.getParams();
    this.props.actionSetInventoryLocation(value);
    await this.props.actionCheckHoseItemsInInventory(hoseId, value);
    this.setState({ selectedLocation: value, isModalLoading: false });
  };

  getParams = () => {
    let url = document.location.href;
    url = new URL(url);
    const { searchParams } = url;
    const assetId = searchParams.get(ASSET_ID_FOR_URL_PARAM);
    const position = searchParams.get(ASSET_POSITION_FOR_URL_PARAM);
    const hoseId = searchParams.get(HOSE_ID_FOR_URL_PARAM);
    const modal = searchParams.get(ASSET_MODEL_FOR_URL_PARAM);
    const subModel = searchParams.get(ASSET_SUB_MODEL_FOR_URL_PARAM);
    const assetName = searchParams.get(ASSET_NAME_FOR_URL_PARAM);
    return { assetId, position, hoseId, modal, subModel, assetName };
  };

  addToCart = async (e, stockCode, pack, quantity) => {
    const product = {
      stockCode,
      pack,
      length: 1,
      quantity: quantity,
      hose: false,
      stockDescription: "From hose mechanic"
    };
    await this.props.actionAddToCart(product);
    showSuccessToast("Hose item added to cart : " + stockCode);
  };

  openAddLocation = () => {
    // this.props.history.push("/inventory/location");
    this.setState({ isOpenForm: true });
  };

  closeForm = () => {
    this.setState({
      isOpenForm: false
    });
  };

  clearErrorMessage = () => {
    this.setState({
      isError: false,
      errorMessage: ""
    });
  };

  addLocation = async () => {
    this.setState({ isLoading: true });
    this.props.actionResetLocationForm();
    const { locationForm } = this.props.forms;
    const { location } = locationForm;
    if (!location) {
      return this.setState({
        isError: true,
        errorMessage: "Location is required"
      });
    }

    const { _id, ...data_to_save } = locationForm;
    await this.props.actionAddLocation(data_to_save);
    const { locationResponse } = this.props;
    const { status, data } = locationResponse;
    if (status === 200) {
      this.props.actionGetAllLocationToDropdown();
      showSuccessToast("Location added");
      this.setState({ isLoading: false, isOpenForm: false });
    } else {
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
    this.clearErrorMessage();
    //this.setState({ isLoading: false });
  };

  setLocation = e => {
    this.setState({ isError: false });
    this.props.actionAddInventoryLocation("location", e.target.value);
  };

  render() {
    const {
      isModalOpen,
      handleClose,
      itemsNotAvailable,
      addToInventory,
      isLoading,
      locations,
      isModalLoading,
      assetId
    } = this.props;
    const { selectedLocation, isOpenForm, isError, errorMessage } = this.state;
    let allLocations = [];
    if (locations.length) {
      _.forEach(locations, (item, key) => {
        const { name } = item;
        allLocations.push({ key, text: name, value: name });
      });
    }

    const AssetButtonText = () => {
      if (itemsNotAvailable.length) {
        return "Add it to asset anyway";
      }
      return "Add to asset";
    };

    const InventoryButtonText = () => {
      if (itemsNotAvailable.length) {
        return "Add it to inventory anyway";
      }
      return "Add to inventory";
    };

    const AddLocation = (
      <Modal open={isOpenForm} onClose={this.closeForm} closeIcon>
        <Modal.Header>Add location</Modal.Header>
        <Modal.Content className="add-location">
          <Form
            model="forms.locationForm"
            error={isError}
            onSubmit={() => this.addLocation()}
          >
            <Grid.Column>
              <Form.Field>
                <label>{polyglot.t("assetForm.location")}</label>
                <Control
                  type="text"
                  model="forms.locationForm.name"
                  placeholder={polyglot.t("assetForm.location")}
                  validateOn="change"
                  className="input-box"
                  onChange={event => this.setLocation(event)}
                />
                <Errors
                  style={{ color: "red" }}
                  model="forms.locationForm.name"
                  show="touched"
                  messages={{
                    valueMissing: "location name is required"
                  }}
                />
                <Message error={true} content={errorMessage} />
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={16} className="button-panel" textAlign="right">
              <Button
                size="small"
                onClick={this.closeForm}
                className="button-float"
              >
                Close
              </Button>
              {!isLoading ? (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  type="submit"
                  content="Submit"
                />
              ) : (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  content="Submit"
                  loading
                />
              )}
            </Grid.Column>
          </Form>
        </Modal.Content>
      </Modal>
    );

    return (
      <React.Fragment>
        <Modal open={isModalOpen} onClose={handleClose} size="small" closeIcon>
          <Header>
            {" "}
            {assetId ? "Attach hose to asset" : "Add hose to inventory"}
          </Header>
          <Modal.Content>
            <Grid>
              <Grid.Column width={16}>
                {isLoading && (
                  <Loader text="Loading saved hoses, please wait" />
                )}
                {isModalLoading && (
                  <Loader text="Loading saved hoses, please wait" />
                )}
                <Form>
                  <Form.Field>
                    <label>Select inventory location </label>
                    {allLocations.length !== 0 ? (
                      <Dropdown
                        placeholder="Location"
                        selection
                        options={allLocations}
                        onChange={this.locationSelectHandler}
                      />
                    ) : (
                      <Message>
                        <label>
                          No inventory location found
                          <a
                            onClick={this.openAddLocation}
                            className="linkToPage"
                          >
                            Click here to add new inventory location
                          </a>
                        </label>
                      </Message>
                    )}
                  </Form.Field>
                </Form>
              </Grid.Column>
              <Grid.Column width={16}>
                {itemsNotAvailable.length > 1 && (
                  <div>
                    <h4>Hose items not available in inventory</h4>
                    <Divider />
                    <List divided verticalAlign="middle">
                      {itemsNotAvailable.map((hoseItem, key) => {
                        const { stockCode, pack, quantity } = hoseItem;
                        return (
                          <List.Item key={key}>
                            <List.Content>{stockCode}</List.Content>
                            <List.Content>
                              Quantity needed: {quantity}
                            </List.Content>
                            <List.Content floated="right">
                              <Button
                                onClick={e =>
                                  this.addToCart(e, stockCode, pack, quantity)
                                }
                              >
                                <Icon name="cart" /> Add to cart
                              </Button>
                            </List.Content>
                          </List.Item>
                        );
                      })}
                    </List>
                  </div>
                )}
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              color="green"
              loading={isLoading}
              onClick={addToInventory}
              disabled={!selectedLocation}
            >
              <Icon name="checkmark" />
              {assetId ? <AssetButtonText /> : <InventoryButtonText />}
            </Button>
            <Button secondary onClick={handleClose}>
              <Icon name="cancel" /> Cancel
            </Button>
          </Modal.Actions>
        </Modal>
        <Grid>{AddLocation}</Grid>
      </React.Fragment>
    );
  }
}

const reducer = {
  ...hoseMechanicReducer,
  ...inventoryLocationsReducer,
  ...cartReducer
};

const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

const mapStateToProps = state => ({
  itemsNotAvailable: state.hoseMechanic.itemsNotAvailable,
  locations: state.inventoryLocation.locations,
  locationResponse: state.inventoryLocation.response,
  forms: state.forms
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddToInventoryModalJSX)
);
