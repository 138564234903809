import axios from "axios";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const apiGetStockAdjustments = (status) => {
  const stockLocationId = Number(
    localStorage.getItem("selectedStockLocationId")
  );

  const data = {
    status: status,
    timeZone: timeZone,
    stockLocationId: stockLocationId,
  };
  return axios
    .get(`/api/InventoryManagement/stock-adjustments`, { params: data })
    .then((result) => {
      if (result && result.data) {
        const data = result.data;
        data.map((item) => {
          item.stockLocationId = item.moveTo;
          item.adjustmentReason = item.moveReason;
        });
        return data;
      }
      return false;
    });
};

export const apiGetStockAdjustmentDetails = (id) => {
  const data = {
    stockMoveId: id,
  };
  return axios
    .get(`/api/InventoryManagement/stock-adjustment-details`, { params: data })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiCreateStockAdjustment = (formData, products, isComplete) => {
  let data = formData;
  const stockLocationId = Number(
    localStorage.getItem("selectedStockLocationId")
  );

  // Validate formData for payload
  if (isComplete) data.isCompleted = true;
  if (data.isCompleted === null) data.isCompleted = false;
  data.stockLocationId = stockLocationId;
  let productList = [];
  products.map((item) => {
    let product = {};
    product.productId = item.productId;
    product.qty = Number(item.signedMoveQty);
    productList.push(product);
  });
  data.adjustDetails = productList;
  return axios
    .post("/api/InventoryManagement/stock-adjustment", data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiUpdateStockAdjustment = (formData, products, isComplete) => {
  let data = formData;
  const stockLocationId = Number(
    localStorage.getItem("selectedStockLocationId")
  );

  // Validate formData for payload
  if (isComplete) data.isCompleted = true;
  if (data.isCompleted === null) data.isCompleted = false;
  let productList = [];
  products.map((item) => {
    let product = {};
    product.productId = item.productId;
    product.qty = Number(item.signedMoveQty);
    productList.push(product);
  });
  const payload = {
    adjustDetails: productList,
    stockLocationId: stockLocationId,
    adjustmentReason: data.adjustmentReason,
    isCompleted: data.isCompleted,
  };

  return axios
    .put(
      `/api/InventoryManagement/update-stock-adjustment?adjustmentId=${formData.id}`,
      payload
    )
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiDeleteStockAdjustment = async (id) => {
  const payload = {
    adjustmentId: id,
  };
  return axios
    .delete(`/api/InventoryManagement/delete-stock-adjustment`, {
      params: payload,
    })
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};
