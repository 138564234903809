import React from "react";
import PropTypes from "prop-types";
import FittingInfo from "./FittingInfo";

const RightFittingInfo = ({ rightFittingInfo = {}, removeFitting, angle }) => {
  return (
    <React.Fragment>
      <FittingInfo fittingName="Right Fitting" fittingInfo={rightFittingInfo} type="right" removeFitting={removeFitting} angle={angle} />
    </React.Fragment>
  );
};

RightFittingInfo.prototype = {
  rightFittingInfo: PropTypes.object,
  removeFitting: PropTypes.func,
  angle: PropTypes.number
};

export default RightFittingInfo;
