import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Control, Errors } from "react-redux-form";
import { Grid, Divider, Form } from "semantic-ui-react";
import polyglot from "../../i18n";
import * as cafReducer from "../../containers/CafForm/caf.reducer";
import CustomInput from "../CustomInput";
import {
  referencesArray,
  referencesPhoneArray
} from "../../constants/formFields";

class CafReferenceForm extends React.Component {
  constructor() {
    super();
    this.state = {
      phoneErrorFlag: false,
      phoneErrorMessage: ""
    };
  }

  onChangePhoneHandler = event => {
    this.updateState({ phoneErrorFlag: false, phoneErrorMessage: "" });
    const placeholder = event.target.placeholder;
    
    switch (placeholder) {
      case polyglot.t("cafForm.reference1Phone"):
        return this.reference1PhoneHandler(event.target.value);

      case polyglot.t("cafForm.reference2Phone"):
        return this.reference2PhoneHandler(event.target.value);

      case polyglot.t("cafForm.reference3Phone"):
        return this.reference3PhoneHandler(event.target.value);

      default:
        this.updateState({ phoneErrorFlag: false, phoneErrorMessage: "" });
        return;
    }
  };

  reference1PhoneHandler = value => {
    if (window._.isEmpty(value)) {
      this.updateState({
        phoneErrorFlag: true,
        phoneErrorMessage: polyglot.t("cafForm.required.phone")
      });
    }
    if ((value.length && value.length < 6) || value.length > 12) {
      this.updateState({
        phoneErrorFlag: true,
        phoneErrorMessage: polyglot.t("cafForm.valid.phone")
      });
    }

    const formValue = {
      ...this.props.forms.cafForm,
      reference1Phone: value
    };
    this.setFormValue(formValue);
  };
  reference2PhoneHandler = value => {
    if (window._.isEmpty(value)) {
      this.updateState({
        phoneErrorFlag: true,
        phoneErrorMessage: polyglot.t("cafForm.required.phone")
      });
    }
    if ((value.length && value.length < 6) || value.length > 12) {
      this.updateState({
        phoneErrorFlag: true,
        phoneErrorMessage: polyglot.t("cafForm.valid.phone")
      });
    }

    const formValue = {
      ...this.props.forms.cafForm,
      reference2Phone: value
    };
    this.setFormValue(formValue);
  };
  reference3PhoneHandler = value => {
    if (window._.isEmpty(value)) {
      this.updateState({
        phoneErrorFlag: true,
        phoneErrorMessage: polyglot.t("cafForm.required.phone")
      });
    }

    if ((value.length && value.length < 6) || value.length > 12) {
      this.updateState({
        phoneErrorFlag: true,
        phoneErrorMessage: polyglot.t("cafForm.valid.phone")
      });
    }

    const formValue = {
      ...this.props.forms.cafForm,
      reference3Phone: value
    };
    this.setFormValue(formValue);
  };

  setFormValue = formValue => {
    this.props.actionSetCafFormValue(formValue);
  };

  updateState = state => {
    this.setState(state);
  };

  render() {
    const referredName = value => {
      return (
        <Form.Field required={value.required}>
          <label>{value.placeholder}</label>
          <Control
            type="text"
            model={value.model}
            placeholder={value.placeholder}
            required={value.required}
            validateOn="change"
            className="input-box"
            autoComplete="new-password"
          />
          <Errors
            style={{ color: "red" }}
            model={value.model}
            show="touched"
            messages={{
              valueMissing: polyglot.t("cafForm.required.reference1")
            }}
          />
        </Form.Field>
      );
    };

    const referredPhoneNo = value => {
      return (
        <Form.Field>
          <label>{value.placeholder}</label>
          <CustomInput
            placeholder={value.placeholder}
            options={{ phone: true, phoneRegionCode: "NZ" }}
            onChangeHandler={this.onChangePhoneHandler}
            autoComplete="new-password"
          />
        </Form.Field>
      );
    };

    return (
      <React.Fragment>
        <h3> {polyglot.t("cafForm.title.references")}</h3>
        <Divider />
        <Grid>
          {referencesArray.map((value, key) => {
            return (
              <Grid.Column computer={16} mobile={16} tablet={16} key={key}>
                <Grid>
                  <Grid.Column computer={8} mobile={16} tablet={8}>
                    {referredName(value)}
                  </Grid.Column>
                  <Grid.Column computer={8} mobile={16} tablet={8}>
                    {referredPhoneNo(referencesPhoneArray[key])}
                  </Grid.Column>
                </Grid>
              </Grid.Column>
            );
          })}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    forms: state.forms
  };
};
const mapDispatchToProps = dispatch => bindActionCreators(cafReducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CafReferenceForm);
