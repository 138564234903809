import React, { useState } from "react";
import "./assembly.css";
import { HISfetchData } from "./hisAPIprovider";
import { toast } from "react-toastify";
import DetailedAssembly from "./detailAssembly";

const AssemblyDisplay = ({ title, sectionData }) => {
  const [partData, setPartData] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPart, setSelectedPart] = useState(null);

  const handleClick = async (field_name, event) => {
    setSelectedPart(event.target.innerText);
    if (field_name === "armor_guard_part_no") {
      title = "bulk_armor_guard";
    }
    setPartData(null);
    const response = await HISfetchData("/his/component_detail", {
      category: title,
      part_no: event.target.innerText,
    });

    if (!response.ok) {
      toast.error("The component details could not be found");
      return;
    }
    const data = await response.json();
    setPartData(data.data);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };
  return (
    <div className="section_display">
      <div className="section_title">
        {title.replaceAll("_", " ") + " Info"}
      </div>
      {sectionData &&
        Object.entries(sectionData).map(
          ([key, value]) =>
            value !== "" &&
            value !== 0 && (
              <div key={key}>
                <div className="item_title">
                  {key.replaceAll("_", " ").replaceAll("mm", "(MM)")}
                </div>
                {key.includes("no") && key !== "assembly_part_no" ? (
                  <div
                    className="item_value_clickable"
                    onClick={(event) => handleClick(key, event)}
                  >
                    {value.toString()}
                  </div>
                ) : (
                  <div className="item_value">{value.toString()}</div>
                )}
              </div>
            )
        )}

      {showPopup && partData && (
        <DetailedAssembly
          title={"Details - " + selectedPart}
          details={partData}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default AssemblyDisplay;
