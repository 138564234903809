import React from "react";
import _ from "lodash";
import { Grid, Button, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import "./BuyByCoil.css";

const BuyByCoil = ({
  fullCoilSizeMetre,
  addBoxSize,
  pack = "",
  product = {}
}) => {
  let allSizes = [];
  if (!fullCoilSizeMetre) {
    return "";
  }
  if (_.isEqual(fullCoilSizeMetre, "0")) {
    return "";
  }
  if (fullCoilSizeMetre.toString().includes(",")) {
    allSizes = fullCoilSizeMetre.toString().split(",");
  } else {
    allSizes.push(fullCoilSizeMetre.toString());
  }

  const boxCoilJSX = allSizes.map(coilSize => {
    if (coilSize < 1) return null;
    return (
      <Button
        key={coilSize}
        size="mini"
        type="button"
        onClick={() => addBoxSize(coilSize)}
      >
        <Icon name="add" /> Add {coilSize}
      </Button>
    );
  });

  const StandardVarianceMessage = (
    <p>
      {" "}
      <i>* Standard variance of 10% may occur on full coil size.</i>
    </p>
  );

  return (
    <React.Fragment>
      <Grid className="box-coil-size">
        <Grid.Column>
          <b>
            {" "}
            {pack === "Metre" ? "Coil" : "Box"} size : {fullCoilSizeMetre}{" "}
          </b>{" "}
          {boxCoilJSX}
        </Grid.Column>
      </Grid>
      {pack === "Metre" ? StandardVarianceMessage : null}
    </React.Fragment>
  );
};

BuyByCoil.propTypes = {
  fullCoilSizeMetre: PropTypes.any,
  addBoxSize: PropTypes.func,
  pack: PropTypes.string,
  tertiaryGrpDisplayName: PropTypes.any
};

export default BuyByCoil;
