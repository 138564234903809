import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { restrictedUserRoles } from "../../constants/appConstants";

const price = "price";

class UserRoleCheck extends React.Component {
  render() {
    const { children, userInfo, type = "" } = this.props;
    const { userRole, authorityToPlaceOrders } = userInfo;
    const index = _.indexOf(restrictedUserRoles, userRole);
    if (index > -1 && !authorityToPlaceOrders) {
      switch (type) {
        case price: {
          return "--";
        }
        default: {
          return "";
        }
      }
    }
    return children;
  }
}

const mapStateToProps = state => {
  return {
    userInfo: state.auth.user
  };
};

export default connect(mapStateToProps, null)(UserRoleCheck);
