import { Grid } from "semantic-ui-react";
import notFoundIcon from "../../assets/images/not_found.svg";

const NotFound = () => {
  return (
    <Grid.Column
      width={16}
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          justifyContent: "center",
          height: "10vh",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          src={notFoundIcon}
          alt="Not Found"
          style={{ height: "40px", margin: "10px" }}
        />
        <span style={{ fontSize: 20 }}>No resources available.</span>
      </div>
    </Grid.Column>
  );
};

export default NotFound;
