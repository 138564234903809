import React, { Component } from "react";
import PropTypes from "prop-types";
import { Modal, Header, Grid, Button, Icon } from "semantic-ui-react";
import UploadImage from "../../components/UploadImage";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
const IMAGE_SIZE_WARNING = "Allowed image size limit is 5MB only";
class AddImage extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      selectedFile: null
    };
  }

  isValidImageSize = size => {
    const kbSize = size / 1000;
    const limit = 5000;
    if (kbSize > limit) {
      return false;
    }
    return true;
  };

  onSelectFile = files => {
    const { size } = files[0];
    if (!this.isValidImageSize(size)) {
      showErrorToast(IMAGE_SIZE_WARNING);
      return;
    }
    this.setState({
      selectedFile: files[0]
    });
  };

  uploadImage = async () => {
    if (!this.state.selectedFile) {
      return;
    }
    this.setState({ isLoading: true });

    await this.props.uploadImages(this.state.selectedFile);
    showSuccessToast(<div>Image uploaded</div>, 5000);
    this.setState({ isLoading: false, selectedFile: null });
  };

  closeModal = () => {
    this.setState({ selectedFile: null });
    this.props.closePage();
  };

  render() {
    const { isImage } = this.props;
    const { selectedFile, isLoading } = this.state;

    return (
      <Modal open={isImage} size="tiny">
        <Header icon="image" content="Add image" />
        <Modal.Content>
          <Grid width={16} mobile={16} computer={16}>
            <Grid.Column
              width={16}
              mobile={16}
              computer={16}
              textAlign="center"
            >
              <UploadImage
                className="upload-Image"
                onSelectFile={this.onSelectFile}
                selectedFile={selectedFile}
              />
            </Grid.Column>
            <Grid.Column width={16} mobile={16} textAlign="right">
              <Button default onClick={this.closeModal}>
                <Icon name="remove" /> Close
              </Button>

              {isLoading ? (
                <Button
                  className="uploadBtn"
                  content="Upload"
                  icon="upload"
                  color="black"
                  loading
                  disabled
                />
              ) : (
                <Button
                  className="uploadBtn"
                  color="black"
                  icon="upload"
                  onClick={this.uploadImage}
                  disabled={!selectedFile}
                  content="Upload"
                />
              )}
            </Grid.Column>
          </Grid>
        </Modal.Content>
      </Modal>
    );
  }
}

AddImage.propTypes = {
  isImage: PropTypes.bool,
  uploadImages: PropTypes.func,
  closePage: PropTypes.func
};

export default AddImage;
