import axios from "axios";
import {
  ASSETS,
  ALL,
  GET_ASSET_TYPE_DETAILS,
  ASSET_TYPE,
  SCHEDULE
} from "../../constants/apiLink";

export const apiAddAsset = async data => {
  const url = ASSETS;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiGetAssetForDropdown = async data => {
  const url = ASSETS + ALL;
  return axios.get(url, data).then(result => {
    return result;
  });
};

export const apiUploadAssetImage = async fileData => {
  const url = ASSETS + "upload";
  return axios.post(url, fileData).then(result => {
    return result;
  });
};


// export const apiAssetType = async data => {
//   const url = ASSETS;
//   return axios.get(url, data).then(result => {
//     return result;
//   });
// };

export const apiGetAssetTypeDetails = async (obj, searchFor) => {
  let url =
    ASSETS + GET_ASSET_TYPE_DETAILS + ASSET_TYPE + "?searchFor=" + searchFor;

  if (searchFor === "assetType") {
    url = url + "&assetType=" + obj.assetType;
  } else if (searchFor === "make") {
    url = url + "&assetType=" + obj.assetType + "&make=" + obj.make;
  } else if (searchFor === "model") {
    url =
      url +
      "&assetType=" +
      obj.assetType +
      "&make=" +
      obj.make +
      "&model=" +
      obj.model;
  } else if (searchFor === "subModel") {
    url =
      url +
      "&assetType=" +
      obj.assetType +
      "&make=" +
      obj.make +
      "&model=" +
      obj.model +
      "&subModel=" +
      obj.subModel;
  }
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiDeleteAssetImage = async imageKey => {
  const url = SCHEDULE + "?imageKey=" + imageKey;
  return axios.delete(url).then(result => {
    return result;
  });
};