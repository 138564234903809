export const transferLocation = {
  stockCode: "",
  fromLocation: "",
  toLocation: "",
  quantity: null
};

export const sellStock = {
  stockCode: "",
  location: "",
  quantity: 1,
  notes: "",
  price: null,
  soldTo: ""
};

export const consumeStock = {
  stockCode: "",
  location: "",
  quantity: null,
  notes: ""
};

export const attachToAsset = {
  stockCode: "",
  position: "",
  location: "",
  quantity: "",
  assetId: "",
  type: "",
  _id: "",
  hoseUniqueCode: ""
};
