import React from "react";
import { List, Message, Form, Button } from "semantic-ui-react";

class CartProductDetails extends React.Component {
  constructor() {
    super();

    this.state = {
      isUpdate: false,
      updateProductKey: null,
      isError: false,
      description: null,
    };
  }

  updateState = (state) => {
    this.setState(state);
  };

  editDescription = (e) => {
    const { stockCode } = this.props.details;
    const { stockDescription } = this.props;
    this.updateState({
      isUpdate: true,
      updateProductKey: stockCode,
      description: stockDescription,
    });
  };

  cancelUpdate = () => {
    this.updateState({
      isUpdate: false,
      updateProductKey: null,
      description: null,
      isError: false,
    });
  };

  updateDescriptionHandler = (e) => {
    e.preventDefault();
    const { isError, updateProductKey, description } = this.state;
    if (!isError) {
      let data = {
        stockCode: updateProductKey,
        stockDescription: description,
      };
      this.props.updateDescription(data);
      this.cancelUpdate({
        isUpdate: false,
        updateProductKey: null,
        description: null,
      });
    }
  };

  onChangeDescriptionHandler = (e) => {
    const { value } = e.target;
    this.updateState({ isError: false });
    if (!value) {
      this.updateState({ isError: true });
    }
    this.updateState({ description: value });
  };

  render() {
    const { barcode: stockCode, productName } = this.props.details;
    const { isError, isUpdate, description } = this.state;
    const { options } = this.props;
    const updateDescriptionJSX = (
      <div className="quantity-form">
        <Form onSubmit={this.updateDescriptionHandler}>
          <Form.Field required>
            <label>Stock description</label>
            <textarea
              className="input-box quantity-width"
              minLength={1}
              maxLength={255}
              name="description"
              rows={4}
              onChange={this.onChangeDescriptionHandler}
              value={description}
            />
            {isError && <Message>Description is required</Message>}
          </Form.Field>
          <Button
            onClick={this.updateDescriptionHandler}
            type="submit"
            size="small"
            secondary
          >
            {" "}
            Update
          </Button>
          <Button onClick={this.cancelUpdate} size="small">
            {" "}
            Cancel{" "}
          </Button>
        </Form>
      </div>
    );

    return (
      <List>
        <List.Item>
          <List.Header>{stockCode}</List.Header>
          {!isUpdate && (
            <div>
              {" "}
              <b>Stock Description : </b> {productName}
              {/* {options && (
                <a
                  className="cart-edit-description"
                  onClick={this.editDescription}
                >
                  {" "}
                  Edit{" "}
                </a>
              )} */}
            </div>
          )}
          {isUpdate && updateDescriptionJSX}
        </List.Item>
      </List>
    );
  }
}

export default CartProductDetails;
