import React, { useEffect } from "react";
import { Grid } from "semantic-ui-react";

const PageNotFound = ({ location }) => {
  useEffect(() => {
    console.log(location);
    const code = new URLSearchParams(location.search).get("code");
    const state = new URLSearchParams(location.search).get("state");
    if (code) window.localStorage.setItem("xeroCode", code);
    if (state) window.localStorage.setItem("xeroState", state);
    // window.close();
  }, []);

  return (
    <Grid>
      <Grid.Row verticalAlign="middle">
        <div
          style={{
            margin: "0px auto",
            minHeight: "220px",
            textAlign: "center",
          }}
        >
          <h1>404</h1>
          <h2>Page not found</h2>

          <p>The page you are looking for is not available.</p>
          <br />
          <a href="/"> Go Back </a>
        </div>
      </Grid.Row>
    </Grid>
  );
};

export default PageNotFound;
