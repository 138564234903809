import {
  apiGetForms,
  apiAddForm,
  apiUpdateForm,
  apiDeleteForm,
  apiGetSingleForm,
  apiGetCheckPoints,
  apiGetTemplateNames
} from "./maintenanceForm.api";
import { actions } from "react-redux-form";

const ADD_FORM = "ADD_FORM";
const SET_FORMS = "SET_FORM";
const DELETE_FORM = "DELETE_FORM";
const UPDATE_FORM = "UPDATE_FORM";
const SET_SINGLE_FORM = "SET_SINGLE_FORM";
const CHECK_POINT = "CHECK_POINT";
const GET_TEMPLATE_NAME = "GET_TEMPLATE_NAME"

const initialState = {
  allForms: [],
  singleForm: {},
  response: {},
  checkList: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_FORM: {
      return {
        ...state,
        response: action.payload
      };
    }
    case SET_FORMS: {
      return {
        ...state,
        allForms: action.payload
      };
    }
    case DELETE_FORM: {
      return {
        ...state,
        response: action.payload
      };
    }
    case UPDATE_FORM: {
      return {
        ...state,
        response: action.payload
      };
    }
    case SET_SINGLE_FORM: {
      return {
        ...state,
        singleForm: action.payload
      };
    }
    case CHECK_POINT: {
      return {
        ...state,
        checkList: action.payload
      };
    }
    case GET_TEMPLATE_NAME: {
      return {
        ...state,
        response: action.payload
      };
    }
    default:
      return state;
  }
};

export const actionSetCheckPoints = checkPoints => {
  return dispatch => {
    dispatch(actions.change("forms.maintenanceForm.data", checkPoints));
  };
};

export const actionSetForm = form => {
  return dispatch => {
    dispatch(actions.change("forms.maintenanceForm", form));
  };
};

export const actionSetMaintenanceForm = data => {
  return dispatch => {
    dispatch(actions.change("forms.maintenanceForm.data", data));
  };
};

export const actionResetForm = formName => {
  return dispatch => {
    dispatch(actions.reset("forms." + formName));
  };
};

export const actionSetCheckPointForm = section => {
  return dispatch => {
    dispatch(actions.change("forms.checkPointForm.section", section));
  };
};

export const actionSetEditedCheckpointName = data => {
  return dispatch => {
    dispatch(actions.change("forms.maintenanceForm.data", data));
  };
};

export const actionSetCheckPointFormField = (fieldName, field) => {
  return dispatch => {
    dispatch(actions.change("forms.checkPointForm." + fieldName, field));
  };
};

export const actionSetFormFocus = field => {
  return dispatch => {
    dispatch(actions.focus("forms.checkPointForm." + field));
  };
};

export const actionAddForm = data => {
  return async dispatch => {
    try {
      const result = await apiAddForm(data);

      dispatch({ type: ADD_FORM, payload: result });
    } catch (e) {
      dispatch({ type: ADD_FORM, payload: e.response });
    }
  };
};

export const actionGetAllForms = () => {
  return async dispatch => {
    try {
      const result = await apiGetForms();
      dispatch({ type: SET_FORMS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_FORMS, payload: e });
    }
  };
};

export const actionGetAllCheckPoints = templateName => {
  return async dispatch => {
    try {
      const result = await apiGetCheckPoints(templateName);
      dispatch({ type: CHECK_POINT, payload: result.data });
    } catch (e) {
      dispatch({ type: CHECK_POINT, payload: e });
    }
  };
};

export const actionUpdateCheckList = value => {
  return async dispatch => {
    dispatch({type: CHECK_POINT, payload: value});
  }
} 

export const actionUpdateForm = data => {
  return async dispatch => {
    try {
      const result = await apiUpdateForm(data);
      dispatch({ type: UPDATE_FORM, payload: result });
    } catch (e) {
      dispatch({ type: UPDATE_FORM, payload: e.response });
    }
  };
};

export const actionDeleteForm = id => {
  return async dispatch => {
    try {
      const result = await apiDeleteForm(id);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: DELETE_FORM, payload: result });
      } else {
        dispatch({ type: DELETE_FORM, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: DELETE_FORM, payload: e.response });
    }
  };
};

export const actionGetSingleForm = id => {
  return async dispatch => {
    try {
      const result = await apiGetSingleForm(id);
      dispatch({ type: SET_SINGLE_FORM, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_SINGLE_FORM, payload: e });
    }
  };
};

export const actionGetTemplateNames = () => {
  return async dispatch => {
    try {
      const result = await apiGetTemplateNames();
      dispatch({ type: GET_TEMPLATE_NAME, payload: result.data });
    } catch (e) {
      dispatch({ type: GET_TEMPLATE_NAME, payload: e });
    }
  };
};