import React, { useEffect, useRef, useState } from "react";
import { Dimmer, Form, Grid, Loader, Button } from "semantic-ui-react";
import MetaData from "../../../components/MetaData";
import _debounce from "lodash/debounce";
import NumericInputBox from "../../../components/NumericInputBox";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import { apiUpdateProductMinMax } from "./adjustProductMinMax.api";

const AdjustProductMinMax = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);

  const onUpdateProductMinMax = async () => {
    setIsLoading(true);
    try {
      const result = await apiUpdateProductMinMax(
        props.stockLocationId,
        props.selectedProduct?.id,
        minValue,
        maxValue
      );
      if (result) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Min/Max Adjusted Successfully
          </div>
        );
        setTimeout(() => {
          setIsLoading(false);
          props.setAdjustMinMaxOpen(false);
        }, 1000);
      } else {
        showErrorToast("Something went wrong");
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <MetaData title="Inventory" />
      <div style={{ position: "absolute", bottom: 100, left: 325 }}>
        {isLoading ? (
          <Loader
            active
            className="workaround"
            size="large"
            inline="centered"
          />
        ) : null}
      </div>
      <div disabled={isLoading} style={{ opacity: isLoading ? 0.5 : 1 }}>
        <Grid
          style={{
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <Grid.Column width={16} style={{ paddingBottom: 0, height: 20 }}>
            <span
              style={{ color: "#66CC23", fontSize: 20, fontWeight: "bolder" }}
            >
              Min / Max Adjustment
            </span>
          </Grid.Column>
          <Grid.Column width={16}>
            <Grid
              style={{
                paddingLeft: 40,
                paddingRight: 50,
                paddingTop: 30,
                paddingBottom: 15,
              }}
            >
              <Grid.Column width={5}>
                <div>
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    Stock Code
                  </span>
                </div>
                <div style={{ paddingTop: 43 }}>
                  <span style={{ fontSize: 18 }}>
                    {props.selectedProduct?.barCode}
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column width={5}>
                <div>
                  <span style={{ fontSize: 18, fontWeight: "bold" }}>
                    Current
                  </span>
                </div>
                <div style={{ paddingTop: 18 }}>
                  <span style={{ fontSize: 18, color: "#AFABAB" }}>
                    Min: {props.selectedProduct?.inventoryData?.min}
                  </span>
                </div>
                <div style={{ paddingTop: 28 }}>
                  <span style={{ fontSize: 18, color: "#AFABAB" }}>
                    Max: {props.selectedProduct?.inventoryData?.max}
                  </span>
                </div>
              </Grid.Column>
              <Grid.Column width={6}>
                <Form model="" style={{ textAlign: "left" }}>
                  <div>
                    <span style={{ fontSize: 18, fontWeight: "bold" }}>
                      New Min/Max
                    </span>
                  </div>
                  <Grid style={{ paddingTop: 10, margin: 0 }}>
                    <Grid.Column
                      width={6}
                      style={{ padding: 0, paddingTop: 8 }}
                    >
                      <span style={{ fontSize: 18, color: "#AFABAB" }}>
                        Min:
                      </span>
                    </Grid.Column>
                    <Grid.Column width={10} style={{ padding: 0 }}>
                      <NumericInputBox
                        style={{
                          input: {
                            padding: 5,
                            textAlign: "center",
                          },
                        }}
                        onKeyDownHandler={(e) => {
                          if (e.key === "Enter") {
                            setMinValue(e.target.value);
                          }
                        }}
                        onBlurHandler={(e) => {
                          setMinValue(e.target.value);
                        }}
                        name="quantity"
                        min={0}
                        max={10000}
                        maxLength={6}
                        className="form-control"
                        precision={
                          props.selectedProduct?.uomData?.id === 8 ? 1 : 0
                        }
                        step={1}
                        value={minValue}
                      />
                    </Grid.Column>
                  </Grid>
                  <Grid style={{ paddingTop: 10, margin: 0 }}>
                    <Grid.Column
                      width={6}
                      style={{ padding: 0, paddingTop: 8 }}
                    >
                      <span style={{ fontSize: 18, color: "#AFABAB" }}>
                        Max:
                      </span>
                    </Grid.Column>
                    <Grid.Column width={10} style={{ padding: 0 }}>
                      <NumericInputBox
                        style={{
                          input: {
                            padding: 5,
                            textAlign: "center",
                          },
                        }}
                        onKeyDownHandler={(e) => {
                          if (e.key === "Enter") {
                            setMaxValue(e.target.value);
                          }
                        }}
                        onBlurHandler={(e) => {
                          setMaxValue(e.target.value);
                        }}
                        name="quantity"
                        min={0}
                        max={10000}
                        maxLength={6}
                        className="form-control"
                        precision={
                          props.selectedProduct?.uomData?.id === 8 ? 1 : 0
                        }
                        step={1}
                        value={maxValue}
                      />
                    </Grid.Column>
                  </Grid>
                </Form>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column
            width={16}
            style={{ paddingLeft: 5, textAlign: "right" }}
          >
            <Button
              size="small"
              style={{
                backgroundColor: "#66CC23",
                color: "#FFF",
                marginRight: 10,
              }}
              onClick={() => onUpdateProductMinMax()}
            >
              Confirm
            </Button>
            <Button
              size="small"
              color="youtube"
              onClick={() => {
                props.setAdjustMinMaxOpen(false);
              }}
            >
              Discard
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default AdjustProductMinMax;
