import React from "react";
import { Grid, Image } from "semantic-ui-react";
import productDefaultImage from "../assets/images/product_default.png";
import "./additionalProductNarrowSearchResult.css";

const AdditionalProductNarrowSearch = ({ narrowFilters = [], onClickHandler, }) => {

  const handleClick = (filter) => {
    const {
      secondaryGrpDisplayName = null,
      tertiaryGrpDisplayName = null,
      productFamilyDisplayName = null,
    } = filter;

    if(!productFamilyDisplayName && !tertiaryGrpDisplayName) {
      return  onClickHandler(secondaryGrpDisplayName)
    }

    if(!productFamilyDisplayName) {
      return  onClickHandler(secondaryGrpDisplayName+","+ tertiaryGrpDisplayName)
    }

    if (productFamilyDisplayName) {
      return onClickHandler(secondaryGrpDisplayName +"," + productFamilyDisplayName);
    }
   
  }

  return (
    <div className="filter-box">
      <h4> Narrow your search</h4>
      <Grid columns="equal">
        {narrowFilters.map((filter, key) => {
          const {
            stockImage1 = null,
            secondaryGrpDisplayName= null,
            productFamilyDisplayName= null,
            tertiaryGrpDisplayName= null,
          } = filter;

          let title = productFamilyDisplayName;

          if (!title) {
            title = tertiaryGrpDisplayName;
          }

          if (!title) {
            title = secondaryGrpDisplayName;
          }


          return (
            <Grid.Column
              width={5}
              computer={5}
              mobile={16}
              key={title}
              className="filter-content"
              onClick={() => handleClick(filter)}
            >
              <Grid>
                <Grid.Column width={5} className="filter-content-image">
                  <Image src={stockImage1 || productDefaultImage} />
                </Grid.Column>
                <Grid.Column width={11}>
                  {
                    <span style={{fontSize: "1.3em"}}>{`${title}`}</span>
                  }
                </Grid.Column>
              </Grid>
            </Grid.Column>
          );
        })}
      </Grid>
    </div>
  );
};

export default AdditionalProductNarrowSearch;
