import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Grid, Divider, Modal, Image, Icon, Button } from "semantic-ui-react";
import AdditionalProductsSearch from "./AdditionalProductsSearch";
import * as hoseMakerReducer from "../../hoseMaker.reducer";
import AdditionalProductInfo from "./AdditionalProductInfo";
import { showErrorToast } from "../../../../utils/toastUtils";
import {
  CATEGORIES_IMAGES,
  hoseAdditionalProductsCategories
} from "./../hoseConstants";
import "./additionalHoseProducts.css";

class AdditionalProducts extends React.Component {
  constructor() {
    super();
    this.state = {
      category: ""
    };
  }
  modalOpenHandler = category => {
    this.setState({ category: category });
    this.props.actionSetAdditionalProductsModalValue(true);
  };
  modalCloseHandler = () => {
    this.setState({ category: "" });
    this.props.actionSetAdditionalProductsModalValue(false);
  };

  removeProductHandler = stockCode => {
    this.props.actionRemoveAdditionalProduct(stockCode);
    showErrorToast("Product removed");
  };

  changeQuantityHandler = (quantity, stockCode, pack) => {
    this.props.actionChangeAdditionalProductQuantity(quantity, stockCode, pack);
  };

  render() {
    const { addAdditionalProductModal, additionalProduct } = this.props;
    const { category } = this.state;
    const modalJsx = (
      <Modal
        open={addAdditionalProductModal}
        closeIcon
        closeOnEscape={false}
        closeOnDimmerClick={false}
        size={"large"}
        onClose={this.modalCloseHandler}
      >
        <Modal.Header>
          Search additional products: {category}
          <Button onClick={this.modalCloseHandler} floated="right">
            Close
          </Button>
        </Modal.Header>
        <Modal.Content>
          <AdditionalProductsSearch categoryValue={category} />
        </Modal.Content>
      </Modal>
    );

    return (
      <React.Fragment>
        <h4>
          <Icon name="list layout" /> Additional products categories <Divider />
        </h4>
        <Grid columns="equal" className="additional-product-search">
          {hoseAdditionalProductsCategories.map(cat => {
            return (
              <Grid.Column key={cat}>
                <Grid
                  onClick={() => this.modalOpenHandler(cat)}
                  className="additional-category-grid"
                >
                  <Grid.Column width={4}>
                    <Image src={CATEGORIES_IMAGES[cat]} size="tiny" alt={cat} />
                  </Grid.Column>
                  <Grid.Column width={12}>{cat}</Grid.Column>
                </Grid>
              </Grid.Column>
            );
          })}
          <Grid.Column>{modalJsx}</Grid.Column>
        </Grid>
        <Grid className="additional-product-info">
          <Grid.Column>
            <AdditionalProductInfo
              products={additionalProduct}
              removeProduct={this.removeProductHandler}
              changeQuantityHandler={this.changeQuantityHandler}
            />
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  addAdditionalProductModal: state.hoseMaker.addAdditionalProductModal,
  additionalProduct: state.hoseMaker.additionalProduct
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(hoseMakerReducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalProducts);
