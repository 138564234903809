import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import AllMaintenance from "../../components/MaintenanceList";
import { Grid, Icon, Divider, Button, Confirm } from "semantic-ui-react";
import * as maintenanceReducer from "../MaintenanceForm/maintenanceForm.reducer";
import { getErrorMessage } from "../../utils/error.utils";

class MaintenanceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDelete: false,
      formId: ""
    };
  }

  componentDidMount() {
    this.getFormList();
  }

  getFormList = () => this.props.actionGetAllForms();

  goToPage = path => {
    const url = `/${path}`;
    this.props.history.push(url);
  };

  openDeleteBox = form => {
    const { _id } = form;
    this.setState({ isDelete: true, formId: _id });
  };

  deleteForm = async () => {
    const { formId } = this.state;
    await this.props.actionDeleteForm(formId);
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      this.getFormList();
      showSuccessToast("Form deleted");
    } else {
      const { code } = data;
      const errorMessage = getErrorMessage(code);
      showErrorToast(errorMessage);
    }
    this.setState({ isDelete: false });
  };

  editForm = form => {
    const { _id } = form;
    this.props.history.push(`/editMaintenanceForm/${_id}`);
  };

  render() {
    const { isLoading, isDelete } = this.state;
    const { allForms } = this.props;

    return (
      <div className="my-maintenance-form">
        <MetaData title="Asset Location" />
        <Confirm
          open={isDelete}
          content="Do you want to delete this form?"
          onCancel={() => this.setState({ isDelete: false })}
          onConfirm={this.deleteForm}
        />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading,please wait</Loader>
          )}

          <Grid.Column width={16}>
            <Grid>
              <Grid.Column width={10}>
                <h3>
                  <Icon name="wpforms" />
                  Inspection forms
                </h3>
              </Grid.Column>
             
              <Grid.Column textAlign="right" width={6} mobile={16} computer={6}>
                <Button
                  size="tiny"
                  onClick={() => this.goToPage("home")}
                  icon="left arrow"
                  labelPosition="left"
                  className="button-float"
                  content="Go back"
                />
                {/* <Button
                  content="Add new"
                  onClick={() => this.goToPage("addMaintenanceForm")}
                  icon="plus"
                  size="tiny"
                  color="black"
                /> */}
              </Grid.Column>
            </Grid>
            <Divider />
          </Grid.Column>

          <Grid.Column
            width={16}
            textAlign="center"
            className="no-top-padded"
            //style={{ paddingBottom: "0px" }}
          >

            <h3 >Coming soon</h3>
          </Grid.Column>

          {/* <Grid.Column width={16}>
            <AllMaintenance
              allForms={allForms}
              goToPage={this.goToPage}
              editForm={this.editForm}
              openDeleteBox={this.openDeleteBox}
            />
          </Grid.Column> */}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allForms: state.maintenance.allForms,
    response: state.maintenance.response,
    forms: state.forms
  };
};

const reducer = { ...maintenanceReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MaintenanceForm)
);
