import React, { useEffect, useState } from "react";
import "./boapod.css";
import {
  Button,
  Card,
  Dimmer,
  Grid,
  Icon,
  Input,
  Label,
  Loader,
  Header,
} from "semantic-ui-react";
import MetaData from "../../components/MetaData";
import { useRef } from "react";
import Autocomplete from "react-google-autocomplete";
import GoogleMapReact from "google-map-react";
import { useDraggable } from "react-use-draggable-scroll";
import { apiGetBOApods, apiGetSelectedPod } from "./boapod.api";
import BOApodImage from "../../assets/images/pod-marker.png";
import MarkerImage from "../../assets/images/marker.png";
import { useWindowSize } from "../../utils/utils";

const BOApod = (props) => {
  const [data, setData] = useState([]);
  const [podList, setPodList] = useState([]);
  const [selectedPod, setSelectedPod] = useState({});
  const [podDetails, setPodDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [podLoading, setPodLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isList, setIsList] = useState(false);
  const [currentLocation, setCurrentLocation] = useState({
    lat: null,
    lng: null,
  });
  const [location, setLocation] = useState({
    lat: null,
    lng: null,
  });
  const [center, setCenter] = useState({
    lat: null,
    lng: null,
  });
  const [isVisible, setIsVisible] = useState(false);
  const [zoom, setZoom] = useState(10);
  const [mapBounds, setMapBounds] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    //Get country list
    const countryList = JSON.parse(localStorage.getItem("countries"));
    setCountries(countryList);
  }, []);

  const handleMapChange = ({ bounds, center, zoom }) => {
    setMapBounds(bounds);
    setCenter(center);
    setZoom(zoom);
  };

  const isVisibleOnMap = (marker) => {
    if (!mapBounds) return false;

    console.log(mapBounds);

    const { ne, sw } = mapBounds;
    const markerPosition = marker.position;

    return (
      markerPosition.latitude >= sw.lat &&
      markerPosition.latitude <= ne.lat &&
      markerPosition.longitude >= sw.lng &&
      markerPosition.longitude <= ne.lng
    );
  };

  const size = useWindowSize();
  const locationRef = useRef(null);
  const ref = useRef();
  const { events } = useDraggable(ref);

  useEffect(() => {
    if (size.width <= 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [size]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  useEffect(() => {
    if (currentLocation && currentLocation.lat && currentLocation.lng)
      getBOApodsData();
  }, [currentLocation]);

  useEffect(() => {
    getBOApodDetails();
  }, [selectedPod]);

  const getBOApodsData = async () => {
    setIsLoading(true);
    try {
      const result = await apiGetBOApods(currentLocation, 1000);
      if (result) {
        setIsLoading(false);
        setData(result.records);
        setPodList(result.records);
      }
    } catch {
      setIsLoading(false);
    }
  };

  const getBOApodDetails = async () => {
    setPodLoading(true);
    try {
      const result = await apiGetSelectedPod(currentLocation, selectedPod);
      if (result) {
        setPodDetails(result);
        setPodLoading(false);
      } else {
        setPodDetails(null);
        setPodLoading(false);
      }
    } catch {
      setPodDetails(null);
      setPodLoading(false);
    }
  };

  const getCurrentLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          setCurrentLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleSearch = (value) => {
    const filteredData = data.filter((item) =>
      item.name.toLowerCase().includes(value.toLowerCase())
    );
    setPodList(filteredData);
  };

  const onAddressSelection = (place) => {
    if (place && place.geometry) {
      const geolocation = {
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      };
      setLocation(geolocation);
      setCurrentLocation(geolocation);
    }
  };

  const Marker = ({ pod, zoom }) => {
    const markerSize = zoom * 8;

    return (
      <div
        className="boapod-container"
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <div
          class="popup"
          style={{
            visibility:
              pod?.id === selectedPod?.id && isVisible ? "visible" : "hidden",
            zIndex: 1002,
          }}
        >
          <Button
            circular
            icon="settings"
            style={{
              position: "absolute",
              right: -17,
              top: -110,
              cursor: "pointer",
              padding: "4px 9px 7px 9px",
              color: "#000",
            }}
            onClick={() => setIsVisible(false)}
          >
            x
          </Button>
          <div style={{ position: "absolute", top: -50, left: 147 }}>
            <Dimmer active={podLoading && pod?.id === selectedPod?.id} inverted>
              <Loader
                inverted
                active={podLoading && pod?.id === selectedPod?.id}
              >
                Loading
              </Loader>
            </Dimmer>
          </div>
          {podLoading && pod?.id === selectedPod?.id ? null : podDetails ===
            null ? (
            <div>
              <Header as="h3" style={{ marginTop: 40 }}>
                No details found
              </Header>
            </div>
          ) : (
            <Grid
              style={{
                width: "100%",
                margin: 0,
                padding: 0,
                paddingTop: 15,
              }}
            >
              <Grid.Column
                width={3}
                style={{
                  margin: 0,
                  padding: 0,
                  paddingLeft: 10,
                }}
              >
                <Icon
                  name="user circle"
                  style={{
                    color: "rgb(102, 204, 35)",
                    fontSize: 36,
                  }}
                />
              </Grid.Column>
              <Grid.Column
                width={13}
                style={{
                  margin: 0,
                  padding: 0,
                  paddingLeft: 5,
                  textAlign: "left",
                }}
              >
                <Header as="h5" style={{ margin: 0, fontSize: 15 }}>
                  {podDetails.companyName}
                </Header>
                <Header
                  as="h6"
                  style={{ margin: 0, marginTop: 2, fontSize: 15 }}
                >
                  {podDetails.driverName}
                </Header>
              </Grid.Column>
              <Grid.Column
                width={3}
                style={{
                  margin: 0,
                  padding: 0,
                  paddingTop: 20,
                  paddingLeft: 10,
                }}
              >
                <Icon
                  name="phone"
                  style={{
                    marginTop: -2,
                    color: "rgb(102, 204, 35)",
                    fontSize: 24,
                  }}
                />
              </Grid.Column>
              <Grid.Column
                width={13}
                style={{
                  margin: 0,
                  padding: 0,
                  paddingTop: 10,
                  paddingLeft: 5,
                  textAlign: "left",
                }}
              >
                <Header as="h5" style={{ margin: 0, fontSize: 15 }}>
                  Call me
                </Header>
                <Header
                  as="h6"
                  style={{ margin: 0, marginTop: 2, fontSize: 15 }}
                >
                  {podDetails.contactNumber}
                </Header>
              </Grid.Column>
            </Grid>
          )}
        </div>
        <img
          src={BOApodImage}
          alt="Marker"
          style={{ width: `${markerSize}px`, cursor: "pointer", zIndex: -1 }}
          onClick={() => {
            setSelectedPod(pod);
            setLocation({
              lat: pod?.position.latitude,
              lng: pod?.position.longitude,
            });
            setIsVisible(true);
          }}
        />
      </div>
    );
  };

  const UserMarker = ({ pod, zoom }) => {
    const markerSize = zoom * 6;

    return (
      <div
        style={{
          position: "absolute",
          transform: "translate(-50%, -50%)",
          textAlign: "center",
        }}
      >
        <img
          src={MarkerImage}
          alt="Marker"
          style={{ width: `${markerSize}px`, cursor: "pointer", zIndex: -1 }}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <MetaData title="Find BOApod" />
      <div>
        <div className="full-screen-div">
          <div style={{ height: isMobile ? "75vh" : "83vh" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyDpdQGecH_3u9-cCKx6oJcuegsLPSwIqKg",
              }}
              yesIWantToUseGoogleMapApiInternals
              options={{ gestureHandling: "cooperative" }}
              defaultCenter={location}
              defaultZoom={11}
              zoom={11}
              center={location}
              onChange={handleMapChange}
            >
              {data.map(
                (pod) =>
                  isVisibleOnMap(pod) && (
                    <Marker
                      lat={pod?.position?.latitude}
                      lng={pod?.position?.longitude}
                      pod={pod}
                      zoom={zoom}
                    />
                  )
              )}
              <UserMarker
                lat={currentLocation.lat}
                lng={currentLocation.lng}
                zoom={zoom}
              />
              {isVisible && selectedPod.id ? (
                <Marker
                  lat={selectedPod?.position?.latitude}
                  lng={selectedPod?.position?.longitude}
                  pod={selectedPod}
                  zoom={zoom}
                />
              ) : null}
            </GoogleMapReact>
          </div>
        </div>
        <div
          className="full-screen-div5"
          style={{ visibility: isList ? "visible" : "hidden" }}
        >
          <div
            width={5}
            style={{
              height: "100%",
              marginBottom: 0,
              padding: 0,
              paddingTop: 20,
              paddingBottom: 20,
            }}
          >
            <div
              style={{
                paddingLeft: "1rem",
                paddingRight: "1rem",
                paddingBottom: 15,
              }}
            >
              <Input
                disabled={isLoading}
                icon="search"
                placeholder="Search..."
                style={{ width: "100%" }}
                size="small"
                onChange={(e, data) => handleSearch(data.value)}
              />
            </div>
            <div
              {...events}
              ref={ref}
              className="boapod-list-scroll"
              style={{
                paddingTop: 5,
                height: "48vh",
              }}
            >
              {isLoading && !isMobile ? (
                <Dimmer active inverted>
                  <Loader size="large"></Loader>
                </Dimmer>
              ) : null}
              {podList.map((pod) => {
                return (
                  <Card
                    style={{
                      width: "100%",
                      minHeight: 60,
                      marginTop: 0,
                      marginBottom: 10,
                      cursor: "pointer",
                    }}
                    className={
                      selectedPod?.id === pod.id
                        ? "boapod-card active"
                        : "boapod-card"
                    }
                    onClick={() => {
                      setSelectedPod(pod);
                      setLocation({
                        lat: pod.position.latitude,
                        lng: pod.position.longitude,
                      });
                      setIsVisible(true);
                      setIsList(false);
                    }}
                  >
                    <Card.Content
                      style={{
                        paddingLeft: 10,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingRight: 10,
                      }}
                    >
                      <Grid
                        style={{
                          width: "100%",
                          margin: 0,
                          padding: 0,
                        }}
                      >
                        <Grid.Column
                          width={3}
                          style={{
                            margin: 0,
                            padding: 0,
                            paddingLeft: 10,
                          }}
                        >
                          <Icon
                            name="user circle"
                            style={{
                              marginTop: -2,
                              color: "rgb(102, 204, 35)",
                              fontSize: 36,
                            }}
                          />
                        </Grid.Column>
                        <Grid.Column
                          width={10}
                          style={{
                            margin: 0,
                            padding: 0,
                            paddingLeft: 5,
                            paddingTop: 5,
                          }}
                        >
                          <Header as="h5" style={{ margin: 0, fontSize: 14 }}>
                            {pod.name}
                          </Header>
                          <Header as="h6" style={{ margin: 0, marginTop: 2 }}>
                            {Math.round(pod?.position?.distance)}
                            km
                          </Header>
                        </Grid.Column>
                        <Grid.Column
                          width={3}
                          style={{
                            margin: 0,
                            padding: 0,
                            paddingRight: 10,
                            textAlign: "right",
                          }}
                        >
                          <Icon
                            name="phone"
                            style={{
                              marginTop: 5,
                              color: "rgb(102, 204, 35)",
                              fontSize: 24,
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </Card.Content>
                  </Card>
                );
              })}
            </div>
            <div style={{ height: "18.5vh", backgroundColor: "#fff" }}></div>
            <div style={{ height: "100%", backgroundColor: "#000" }}></div>
          </div>
        </div>
        <div
          className="full-screen-div4"
          style={{ visibility: !isMobile ? "hidden" : "visible" }}
        >
          <Button
            icon
            style={{
              color: "white",
              backgroundColor: "rgb(102, 204, 35)",
              padding: 8,
            }}
            onClick={() => setIsList(!isList)}
          >
            <Icon style={{ fontSize: 21 }} name={!isList ? "list ul" : "map"} />
          </Button>
        </div>
        <div
          className="full-screen-div3"
          style={{ visibility: !isMobile ? "hidden" : "visible" }}
        >
          <div
            style={{
              paddingLeft: "1rem",
              paddingRight: "1rem",
              paddingTop: 15,
              width: "100% !important",
            }}
          >
            <Grid style={{ margin: 0, padding: 0 }}>
              <Grid.Column width={2} style={{ margin: 0, padding: 0 }}>
                <Label
                  basic
                  style={{
                    width: "100%",
                    height: 40,
                    paddingLeft: 10,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                >
                  <Icon
                    name="map marker alternate"
                    style={{
                      color: "rgb(102, 204, 35)",
                      fontSize: 20,
                    }}
                  />
                </Label>
              </Grid.Column>
              <Grid.Column width={12} style={{ margin: 0, padding: 0 }}>
                <Input
                  labelPosition="right"
                  type="text"
                  placeholder="Your location"
                  style={{ width: "100%", height: 40, borderRadius: 0 }}
                >
                  <Autocomplete
                    style={{ width: "100%", borderRadius: 0 }}
                    ref={locationRef}
                    required
                    placeholder="Your location"
                    onPlaceSelected={(place, inputRef) => {
                      onAddressSelection(place);
                    }}
                    types={["geocode"]}
                    componentRestrictions={{
                      country: countries,
                    }}
                  />
                </Input>
              </Grid.Column>
              <Grid.Column width={2} style={{ margin: 0, padding: 0 }}>
                <Label
                  basic
                  style={{
                    width: "100%",
                    cursor: "pointer",
                    height: 40,
                    paddingLeft: 7,
                    paddingTop: 10,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                  onClick={() => getCurrentLocation()}
                >
                  <Icon
                    name="location crosshairs"
                    style={{
                      color: "rgb(102, 204, 35)",
                      fontSize: 20,
                    }}
                  />
                </Label>
              </Grid.Column>
            </Grid>
          </div>
        </div>
        <div
          className="full-screen-div2"
          style={{ visibility: isMobile ? "hidden" : "visible" }}
        >
          <Card
            style={{
              width: "22vw",
              minWidth: 320,
              maxWidth: 470,
              padding: 0,
              border: "1px solid black",
              backgroundColor: "#f1f2f2",
            }}
          >
            <Card.Content style={{ width: "100%", padding: 0 }}>
              <div
                width={5}
                style={{
                  height: "100%",
                  marginBottom: 0,
                  padding: 0,
                  paddingTop: 20,
                  paddingBottom: 20,
                }}
              >
                <div
                  style={{
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    paddingBottom: 15,
                  }}
                >
                  <Input
                    icon="search"
                    placeholder="Search..."
                    style={{ width: "100%" }}
                    size="small"
                    onChange={(e, data) => handleSearch(data.value)}
                  />
                </div>
                <div
                  {...events}
                  ref={ref}
                  className="boapod-list-scroll"
                  style={{
                    paddingTop: 5,
                    height: "49vh",
                  }}
                >
                  {isLoading && !isMobile ? (
                    <Dimmer active inverted>
                      <Loader size="large"></Loader>
                    </Dimmer>
                  ) : null}
                  {podList.map((pod) => {
                    return (
                      <Card
                        style={{
                          width: "100%",
                          minHeight: 60,
                          marginTop: 0,
                          marginBottom: 10,
                          cursor: "pointer",
                        }}
                        className={
                          selectedPod?.id === pod.id
                            ? "boapod-card active"
                            : "boapod-card"
                        }
                        onClick={() => {
                          setSelectedPod(pod);
                          setLocation({
                            lat: pod.position.latitude,
                            lng: pod.position.longitude,
                          });
                          setIsVisible(true);
                        }}
                      >
                        <Card.Content
                          style={{
                            paddingLeft: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingRight: 10,
                          }}
                        >
                          <Grid
                            style={{
                              width: "100%",
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            <Grid.Column
                              width={3}
                              style={{
                                margin: 0,
                                padding: 0,
                                paddingLeft: 10,
                              }}
                            >
                              <Icon
                                name="user circle"
                                style={{
                                  marginTop: -2,
                                  color: "rgb(102, 204, 35)",
                                  fontSize: 36,
                                }}
                              />
                            </Grid.Column>
                            <Grid.Column
                              width={10}
                              style={{
                                margin: 0,
                                padding: 0,
                                paddingLeft: 5,
                                paddingTop: 5,
                              }}
                            >
                              <Header
                                as="h5"
                                style={{ margin: 0, fontSize: 14 }}
                              >
                                {pod.driverName.split(" ")[0]} -{" "}
                                {pod.companyName}
                              </Header>
                              <Header
                                as="h6"
                                style={{ margin: 0, marginTop: 2 }}
                              >
                                {Math.round(pod?.position?.distance)}
                                km
                              </Header>
                            </Grid.Column>
                            <Grid.Column
                              width={3}
                              style={{
                                margin: 0,
                                padding: 0,
                                paddingRight: 10,
                                textAlign: "right",
                              }}
                            >
                              <Icon
                                name="phone"
                                style={{
                                  marginTop: 5,
                                  color: "rgb(102, 204, 35)",
                                  fontSize: 24,
                                }}
                              />
                            </Grid.Column>
                          </Grid>
                        </Card.Content>
                      </Card>
                    );
                  })}
                </div>
                <div
                  style={{
                    paddingLeft: "1rem",
                    paddingRight: "1rem",
                    paddingTop: 15,
                    width: "100% !important",
                  }}
                >
                  <Grid style={{ margin: 0, padding: 0 }}>
                    <Grid.Column width={2} style={{ margin: 0, padding: 0 }}>
                      <Label
                        basic
                        style={{
                          width: "100%",
                          height: 40,
                          paddingLeft: "0.8vw",
                          borderTopRightRadius: 0,
                          borderBottomRightRadius: 0,
                        }}
                      >
                        <Icon
                          name="map marker alternate"
                          style={{
                            color: "rgb(102, 204, 35)",
                            fontSize: 20,
                          }}
                        />
                      </Label>
                    </Grid.Column>
                    <Grid.Column width={12} style={{ margin: 0, padding: 0 }}>
                      <Input
                        labelPosition="right"
                        type="text"
                        placeholder="Your location"
                        style={{ width: "100%", height: 40, borderRadius: 0 }}
                      >
                        <Autocomplete
                          style={{ width: "100%", borderRadius: 0 }}
                          ref={locationRef}
                          required
                          placeholder="Your location"
                          onPlaceSelected={(place, inputRef) => {
                            onAddressSelection(place);
                          }}
                          types={["geocode"]}
                          componentRestrictions={{
                            country: countries,
                          }}
                        />
                      </Input>
                    </Grid.Column>
                    <Grid.Column width={2} style={{ margin: 0, padding: 0 }}>
                      <Label
                        basic
                        style={{
                          width: "100%",
                          cursor: "pointer",
                          height: 40,
                          paddingLeft: "0.65vw",
                          paddingTop: 10,
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                        onClick={() => getCurrentLocation()}
                      >
                        <Icon
                          name="location crosshairs"
                          style={{
                            color: "rgb(102, 204, 35)",
                            fontSize: 20,
                          }}
                        />
                      </Label>
                    </Grid.Column>
                  </Grid>
                </div>
              </div>
            </Card.Content>
          </Card>
        </div>
        <div>
          <Grid
            columns={1}
            className="boapod-container"
            style={{
              width: "2vw",
              marginTop: 30,
              zIndex: 11,
              position: "relative",
              height: "75.5vh",
            }}
          >
            <Grid.Row>
              <Grid.Column width={16} className="boapod-title-section">
                {/* <Header as="h3">Find BOApod</Header> */}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row style={{ padding: 2, paddingBottom: 100 }}>
              <Grid.Column
                width={16}
                className="boapod-main-section"
              ></Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BOApod;
