import polyglot from "../i18n";

export const referencesArray = [
  {
    name: "reference1",
    model: "forms.cafForm.reference1",
    placeholder: polyglot.t("cafForm.reference1"),
    required: true
  },
  {
    name: "reference2",
    model: "forms.cafForm.reference2",
    placeholder: polyglot.t("cafForm.reference2"),
    required: true
  },
  {
    name: "reference3",
    model: "forms.cafForm.reference3",
    placeholder: polyglot.t("cafForm.reference3"),
    required: false
  }
];

export const referencesPhoneArray = [
  {
    name: "referencePhone1",
    model: "forms.cafForm.reference1Phone",
    placeholder: polyglot.t("cafForm.reference1Phone"),
   
  },
  {
    name: "referencePhone2",
    model: "forms.cafForm.reference2Phone",
    placeholder: polyglot.t("cafForm.reference2Phone"),
   
     },
  {
    name: "referencePhone3",
    model: "forms.cafForm.reference3Phone",
    placeholder: polyglot.t("cafForm.reference3Phone"),
    
    
  }
];

export const typeOfBusinessOptions = [
  { key: "none", value: "", text: "Select type of business" },
  { key: "Sole Trader", value: "Sole trader", text: "Sole trader" },
  { key: "Partnership", value: "Partnership", text: "Partnership" },
  {
    key: "Limited Company",
    value: "Limited Company",
    text: "Limited Company"
  }
];
