import axios from "axios";
import { ASSET_LOCATION } from "../../constants/apiLink";

export const apiGetLocations = async () => {
  const url = ASSET_LOCATION;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiAddLocation = async data => {
  const url = ASSET_LOCATION;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiDeleteLocation = async name => {
  const url = ASSET_LOCATION + "/" + name;

  return axios.delete(url).then(result => {
    return result;
  });
};

export const apiUpdateLocation = async (_id,data) => {
  const url = ASSET_LOCATION + "/" + _id;
  return axios.put(url, data).then(result => {
    return result;
  });
}; 
