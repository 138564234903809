import React from "react";
import PropTypes from "prop-types";
import { Grid, Segment } from "semantic-ui-react";
import ChangeFerruleModal from "./ChangeFerruleModal";

const FerruleInfo = ({ ferruleInfo = {}, removeFerrule , heading ="", type = ""}) => {
    const { stockCode = "" } = ferruleInfo;

    return (
    <React.Fragment>
      <Segment className="fitter-info">
        <Grid columns="equal">
          <Grid.Column>{heading} <ChangeFerruleModal type={type}/></Grid.Column>
          <Grid.Column>{stockCode} </Grid.Column>
                    <Grid.Column>&nbsp;</Grid.Column>
          <Grid.Column>
            <div className="remove-fitter" onClick={ (e) => removeFerrule(type)}>
              x Remove
            </div>
          </Grid.Column>
        </Grid>
      </Segment>
    </React.Fragment>
  );
};

FerruleInfo.prototype = {
    ferruleInfo: PropTypes.object,
    removeFerrule: PropTypes.func,
    heading: PropTypes.string,
    type: PropTypes.string
};

export default FerruleInfo;
