import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authReducer from "../auth.reducer";
import { Control, Errors } from "react-redux-form";
import polyglot from "../../../i18n";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/Loader";
import { Button, Card, Form, Grid, Message } from "semantic-ui-react";
import "./forgotPassword.css";
import { FadeIn } from "react-animated-components";
import OtpInput from "react-otp-input";
import { useWindowSize } from "../../../utils/utils";
import { apiVerifyToken } from "../auth.api";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";

const ForgotPass = (props) => {
  const [state, setState] = useState({
    loading: false,
  });
  const [status, setStatus] = useState(null);
  const [token, setToken] = useState("");
  const [otp, setOtp] = useState("");
  const [otpVerifying, setOtpVerifying] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const size = useWindowSize();

  useEffect(() => {
    if (size.width <= 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [size]);

  const sendEmail = async () => {
    setState({ loading: true });
    await props.actionSendMail({
      email: props.forms.forgotPasswordForm.email,
    });
    setState({ loading: false });
  };

  const goHome = () => {
    props.history.push("/");
  };

  let responseJSX = null;
  let loader = "";

  useEffect(() => {
    let { status, data } = props.response;
    setStatus(status);

    if (status && data?.token) {
      setToken(data?.token);
    }
  }, [props.response]);

  useEffect(() => {
    if (otp.length === 6) {
      setOtpVerifying(true);
      verifyToken();
    }
  }, [otp]);

  const verifyToken = async () => {
    try {
      const payload = {
        otp: otp,
        token: token,
      };
      const account = await apiVerifyToken(payload);
      if (account) {
        showSuccessToast(
          <div>
            <i className="check icon" /> OTP verified successfully
          </div>
        );
        setTimeout(() => {
          setOtpVerifying(false);
          if (account.token && account.token !== null) {
            props.clearAuth();
            props.history.push(`/reset-password/${account?.token}`);
          }
        }, 2000);
      } else {
        setOtpVerifying(false);
        setOtp("");
        showErrorToast("Invalid verification code");
      }
    } catch {
      setOtpVerifying(false);
      setOtp("");
      showErrorToast("Something went wrong");
    }
  };

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  if (state.loading) {
    loader = <Loader text="Sending mail, please wait" />;
  }
  let formCode = (
    <Form
      error={state.errorflag}
      className="ui form"
      model="form.forgotPass"
      onSubmit={sendEmail}
    >
      <Form.Field required>
        <Control
          type="email"
          model="forms.forgotPasswordForm.email"
          placeholder={polyglot.t("forgotPass.email")}
          required
          validateOn="change"
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.forgotPasswordForm.email"
          show="touched"
          messages={{
            valueMissing: "Email is required",
            typeMismatch: "Invalid email address",
          }}
        />
      </Form.Field>
      <Message error={true} content={state.errorMessage} />
      <div className="button-container">
        <Button secondary size="small" type="submit">
          {polyglot.t("forgotPass.submit")}
        </Button>
      </div>
    </Form>
  );

  return (
    <Grid className="forgot-password-box">
      <Grid.Row>
        <div
          className="ui centered container forgot-password-container"
          style={{ marginTop: -20 }}
        >
          {loader}
          <div className="forgot-password-box">
            <div className="ui">
              <h3> {polyglot.t("forgotPass.title")}</h3>
              {status === 200 && token !== "" ? null : (
                <p> {polyglot.t("forgotPass.description")}</p>
              )}

              {status !== 200 && formCode}

              {status === 200 && token !== "" ? (
                <div>
                  <FadeIn fade durationMs={600}>
                    <Grid style={{ marginTop: 50 }}>
                      <Grid.Column
                        width={16}
                        style={{ textAlign: "center", padding: "30px 0px" }}
                      >
                        <Card
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            width: 600,
                            boxShadow:
                              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                          }}
                        >
                          <Card.Content style={{ padding: 0 }}>
                            <div
                              style={{
                                padding: "40px 120px",
                                opacity: otpVerifying ? 0.2 : 1,
                              }}
                            >
                              <span
                                style={{
                                  fontSize: isMobile ? "3vw" : 17,
                                  fontWeight: "bold",
                                }}
                              >
                                Enter verification code
                              </span>
                            </div>
                            <div
                              style={{
                                marginBottom: 50,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {otpVerifying ? (
                                <Loader
                                  size="large"
                                  active={otpVerifying}
                                ></Loader>
                              ) : null}
                              <OtpInput
                                containerStyle={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  opacity: otpVerifying ? 0.2 : 1,
                                }}
                                inputStyle={{
                                  width: 60,
                                  height: 60,
                                  margin: "0px 10px",
                                  fontSize: "26",
                                  borderRadius: "4px",
                                  border: "1px solid rgba(0, 0, 0, 0.3)",
                                }}
                                numInputs={6}
                                errorStyle="error"
                                separator={<span></span>}
                                shouldAutoFocus
                                value={otp}
                                onChange={handleOtpChange}
                              />
                            </div>
                          </Card.Content>
                        </Card>
                      </Grid.Column>
                    </Grid>
                  </FadeIn>
                </div>
              ) : null}

              <div className="home-page-link" style={{ marginTop: 50 }}>
                <a href="" onClick={goHome}>
                  Back to Login
                </a>
              </div>
            </div>
          </div>
        </div>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    forms: state.forms,
    mailSent: state.auth.mailSent,
    response: state.auth.response,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(authReducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPass)
);
