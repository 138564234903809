import React from "react";
import { Modal, Image, Header } from "semantic-ui-react";


const AngleHelpModal = () => {

  return (<React.Fragment>
    <Modal size="medium" trigger={<label className="angle-help">Help ?</label>} centered={false}>
      <Modal.Content image>
        <Image
          style={{ width: "490px" }}
          size="medium"
          src="https://s3-ap-southeast-2.amazonaws.com/portal-static-images/HoseMakerAngleHelpImage.png"
        />
        <Modal.Description>

          <Header>Angular Relationships</Header>
          <p>
            Hold the assembly so that you can look along the length of the hose and with the fitting furtherest away from you in the vertical position. Measure the angle between the vertical fitting and the one nearest to you in a clockwise direction. Relationship can be expressed from 0 degrees to 360 degrees
            </p>
          <p>
            By default the elbows are positioned at 180 degrees
            </p>
          <p>
            <div classname="wistia_responsive_padding" style={{ padding: '56.25% 0 0 0', position: 'relative' }}><div className="wistia_responsive_wrapper" style={{ height: '100%', left: 0, position: 'absolute', top: 0, width: '100%' }}><span className="wistia_embed wistia_async_hpg99mh38o popover=true popoverAnimateThumbnail=true videoFoam=true" style={{ display: 'inline-block', height: 200, position: 'relative', width: 300 }}>&nbsp;</span></div></div>
          </p>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  </React.Fragment>);
};


export default AngleHelpModal;