import React from "react";
import { Header, Modal } from "semantic-ui-react";
import "./creditInfo.css";

const CreditPopup = () => {
  return (
    <Modal
      trigger={<a className="linkToPage">( What is a BOA trade credit account? )</a>}
      closeIcon
    >
      <Modal.Content>
        <Modal.Description>
          <Header>What is a BOA trade credit account?</Header>
          <p>
            A trade credit account with BOA allows you to order products on preferential prices and pay later.
  The payment terms set, will be 7 days or 20th of the month following invoice.
          </p>
          <p>
            The process is extremely simple, following the below 3 (is this correct?) steps.
          </p>
          <ol>
            <li>
              Complete Company name, your name, address, email, phones numbers and trade references.
            </li>
            <li>
              You will receive a confirmation document to accept the BOA terms of trade and sign electronically.
            </li>
            <li>
              BOA finance will complete internal processors, set a credit limit and liven your credit account within 60 minutes. (Between 9am and 4pm weekdays NZT).
            </li>
          </ol>

          <p>
            You will receive a notification that you are set up and ready to buy.
          </p>

        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
export default CreditPopup;
