import React from "react";
import { slide as MobileMenu } from "react-burger-menu";
import { Menu } from "semantic-ui-react";
import "./burgerMenu.css";
import CommonMenu from "../CommonMenu";
import PropTypes from "prop-types";

const BurgerMenu = ({
  activeItem,
  getCartCount,
  handleItemClick,
  openMenu,
  isFacility,
  logout,
}) => {
  return (
    <span className="burger-menu">
      <MobileMenu isOpen={openMenu}>
        <Menu
          inverted
          vertical
          style={{ padding: "0px", backgroundColor: "#00001e" }}
        >
          <CommonMenu
            activeItem={activeItem}
            getCartCount={getCartCount}
            handleItemClick={handleItemClick}
            isFacility={isFacility}
            logout={logout}
          />
        </Menu>
      </MobileMenu>
    </span>
  );
};

BurgerMenu.propTypes = {
  activeItem: PropTypes.string,
  getCartCount: PropTypes.func,
  handleItemClick: PropTypes.func,
  openMenu: PropTypes.bool,
  isFacility: PropTypes.bool,
};
export default BurgerMenu;
