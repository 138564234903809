import React from "react";
import FamilyBreadCrumb from "../../containers/Search/FamilyBreadCrumb";
import PropTypes from "prop-types";

const QuickSearchResultRenderer = (props) => {
  const {
    secondaryGrpDisplayName,
    tertiaryGrpDisplayName,
    productFamilyDisplayName,
    name: stockDescription,
    barcode: stockCode,
  } = props;
  return (
    <div key={stockDescription}>
      <div>
        <b>({stockCode}) </b> {stockDescription}{" "}
      </div>
      <div>
        <FamilyBreadCrumb
          key={stockCode}
          {...{
            secondaryGrpDisplayName,
            tertiaryGrpDisplayName,
            productFamilyDisplayName,
          }}
        />{" "}
      </div>
    </div>
  );
};

QuickSearchResultRenderer.propTypes = {
  stockDescription: PropTypes.string,
  stockCode: PropTypes.string,
  secondaryGrp: PropTypes.string,
  tertiaryGrp: PropTypes.string,
  productFamily: PropTypes.string,
};

export default QuickSearchResultRenderer;
