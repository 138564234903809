import React, { useEffect } from "react";
import { Loader } from "semantic-ui-react";

const CallbackComponent = () => {
  useEffect(() => {
    console.log(window.location.href);
    const code = new URLSearchParams(location.search).get("code");
    const state = new URLSearchParams(location.search).get("state");
    if (code) window.localStorage.setItem("xeroCode", code);
    if (state) window.localStorage.setItem("xeroState", state);
    setTimeout(function () {
      window.close();
    }, 2000);
  }, []);

  return (
    <div style={{ width: "100%", textAlign: "right", paddingBottom: 300 }}>
      <Loader active size="large">
        Connecting to Xero...
      </Loader>
    </div>
  );
};

export default CallbackComponent;
