import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as hoseMakerReducer from "../hoseMaker.reducer";
import { Grid, Divider, Checkbox } from "semantic-ui-react";
import _ from "lodash";

class HosePriceCalculation extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false
    };
  }
  componentWillMount() {
    this.setCharges();
  }

  setCharges = async () => {
    const { spiralGuard, hoseType } = this.props;
    const isSpiral = !_.isEmpty(spiralGuard);
    const { stockCode } = hoseType;
    await this.props.actionSetCharges(stockCode, isSpiral);
  }

  makeAtBoaChangeHandler = (event, data) => {
    const { checked } = data;
    this.props.actionSetMakeAtBoa(checked);
  };

  render() {
    const {
      hoseType,
      leftFittingTypes,
      rightFittingTypes,
      spiralGuard,
      additionalProduct,
      hoseLength,
      makeAtBoa,
      charges,
      hoseQuantity = 1,
      leftFerrule,
      rightFerrule,
      overAllLength
    } = this.props;
    const getTotalPrice = () => {
      let allProducts = [];
      let totalHosePrice = 0;
      let additionalCharges = 0;
      let currency = "";

    let inMetre="";
      // let inMetre = Number(hoseLength / 1000);
    if(hoseLength!==0){
      inMetre = Number(hoseLength / 1000)
    }else{
      inMetre =Number(overAllLength/1000)
    }

      if (!_.isEmpty(hoseType)) {
        const { pricingPolicy } = hoseType;
        const { totalPrice, currency: cur } = pricingPolicy;
        currency = cur;
        totalHosePrice = Number((totalPrice * inMetre).toFixed(2));
      }
      if (!_.isEmpty(leftFittingTypes)) {
        allProducts.push(leftFittingTypes);
      }
      if (!_.isEmpty(rightFittingTypes)) {
        allProducts.push(rightFittingTypes);
      }
      if (!_.isEmpty(additionalProduct)) {
        allProducts.push(...additionalProduct);
      }
      if (!_.isEmpty(spiralGuard)) {
        allProducts.push(spiralGuard);
      }
      if (!_.isEmpty(leftFerrule)) {
        allProducts.push(leftFerrule)
      }
      if (!_.isEmpty(rightFerrule)) {
        allProducts.push(rightFerrule)
      }
      if (_.isEmpty(allProducts)) {
        return "";
      }
      _.forEach(allProducts, product => {
        const { pricingPolicy = {}, quantity = 1 } = product;
        const { totalPrice = "" } = pricingPolicy;
        totalHosePrice = Number(totalHosePrice + Number(totalPrice * quantity));
      });
      if (makeAtBoa) {
        const { hoseCharge = {}, spiralCharge = { } } = charges;
        const { price: hosePrice } = hoseCharge;
        const { price: spiralPrice = 0} = spiralCharge;
        additionalCharges = Number(hosePrice);
        if (!_.isEmpty(spiralGuard)) {
          additionalCharges = Number(additionalCharges + (spiralPrice * inMetre));
        }
        totalHosePrice = Number(totalHosePrice + additionalCharges);
      }
      totalHosePrice = totalHosePrice * hoseQuantity;
      this.props.actionSetHosePrice(totalHosePrice.toFixed(2));
      return (
        <div className="hose-price-info">
          <h4>
            Hose Price
            <Divider />
            <Checkbox
              disabled={true}
              checked={makeAtBoa}
              label="Make hose at BOA"
              onChange={this.makeAtBoaChangeHandler}
            />
          </h4>
           <i>(Added crimping, cleaning and sleaving charges.)</i>
          <b> Total Price :</b>{" "}
          <i> {totalHosePrice.toFixed(2) + " " + currency}</i>
        </div>
      );
    };

    return (
      <React.Fragment>
        <Grid>
          <Grid.Column width={16}>{getTotalPrice()}</Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  hoseType: state.hoseMaker.hoseType,
  leftFittingTypes: state.hoseMaker.leftFittingTypes,
  rightFittingTypes: state.hoseMaker.rightFittingTypes,
  hoseLength: state.hoseMaker.hoseLength,
  overAllLength:state.hoseMaker.overAllLength,
  spiralGuard: state.hoseMaker.spiralGuard,
  additionalProduct: state.hoseMaker.additionalProduct,
  makeAtBoa: state.hoseMaker.makeAtBoa,
  charges: state.hoseMaker.charges,
  hoseQuantity: state.hoseMaker.hoseQuantity,
  leftFerrule: state.hoseMaker.leftFerrule,
  rightFerrule: state.hoseMaker.rightFerrule
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(hoseMakerReducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HosePriceCalculation);
