import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Divider, Form, Dropdown } from "semantic-ui-react";
import { Control, Errors } from "react-redux-form";
import polyglot from "../../i18n";
import CustomInput from "../CustomInput";
import * as cafReducer from "../../containers/CafForm/caf.reducer";
import Autocomplete from "react-google-autocomplete";
import DatePicker from "../DatePicker";
import moment from "moment";
import { typeOfBusinessOptions } from "../../constants/formFields";

class CafInformationForm extends React.Component {
  constructor() {
    super();
    this.state = {
      typeOfBusinessErrorFlag: false,
      phoneErrorFlag: false,
      phoneErrorMessage: "",
    };
  }

  updateState = (data) => {
    this.setState(data);
  };

  typeOfBusinessChangeHandler = (e, data) => {
    this.updateState({ typeOfBusinessErrorFlag: false });
    const { value } = data;
    if (window._.isEmpty(value)) {
      this.updateState({ typeOfBusinessErrorFlag: true });
    }

    const formValue = {
      ...this.props.forms.cafForm,
      typeOfBusiness: value,
    };
    this.setFormValue(formValue);
  };

  onChangePhoneHandler = (event) => {
    this.updateState({ phoneErrorFlag: false });
    let value = event.target.value;

    if (window._.isEmpty(value)) {
      this.updateState({
        phoneErrorFlag: true,
        phoneErrorMessage: polyglot.t("cafForm.required.phone"),
      });
    }

    if ((value.length && value.length < 6) || value.length > 12) {
      this.updateState({
        phoneErrorFlag: true,
        phoneErrorMessage: polyglot.t("cafForm.valid.phone"),
      });
    }

    const formValue = {
      ...this.props.forms.cafForm,
      phone: value,
    };
    this.setFormValue(formValue);
  };

  setFormValue = (formValue) => {
    this.props.actionSetCafFormValue(formValue);
  };

  setDate = (date) => {
    const formValue = {
      ...this.props.forms.cafForm,
      dateBusinessStarted: date,
    };
    this.setFormValue(formValue);
  };

  onAddressSelection = (place) => {
    const address = this.getCitySuburb(place);
    this.setState({ address });
    this.props.actionSetAddressCafFormValue(place.formatted_address);
  };

  getCitySuburb = (place) => {
    let address = {
      street_number: "",
      route: "",
      subLocality_level_1: "",
      locality: "",
      administrative_area_level_1: "",
      country: "",
      postal_code: "",
    };

    place.address_components.forEach((component) => {
      const types = component.types;

      if (types.includes("street_number")) {
        address.street_number = component.long_name;
      } else if (types.includes("route")) {
        address.route = component.long_name;
      } else if (types.includes("sublocality_level_1")) {
        address.subLocality_level_1 = component.long_name;
      } else if (types.includes("locality")) {
        address.locality = component.short_name;
      } else if (types.includes("administrative_area_level_1")) {
        address.administrative_area_level_1 = component.long_name;
      } else if (types.includes("country")) {
        address.country = component.long_name;
      } else if (types.includes("postal_code")) {
        address.postal_code = component.long_name;
      }
    });

    if (address.subLocality_level_1 === "") {
      address.subLocality_level_1 = address.locality;
    }

    return {
      street: `${address.street_number} ${address.route} ${address.subLocality_level_1} ${address.administrative_area_level_1} ${address.country} ${address.postal_code}`,
    };
  };

  editPhysicalAddress = (e) => {
    this.props.actionSetAddressCafFormValue(e.target.value);
    this.setState({
      address: e.target.value,
    });
  };

  render() {
    const { typeOfBusinessErrorFlag, phoneErrorMessage, phoneErrorFlag } =
      this.state;
    const { cafForm } = this.props.forms;

    // const customAddressJSX = (
    //   <div>
    //     <Form>
    //       <Form.Field>
    //         <input
    //           type="text"
    //           name="address"
    //           placeholder="Add details"
    //           maxLength="40"
    //           value={customInput}
    //           onChange={this.customAddressOnChangeHandler}
    //           autoComplete="new-password"
    //         />
    //       </Form.Field>
    //     </Form>
    //   </div>
    // );

    const postalAddress = (
      <Form.Field>
        <label>{polyglot.t("cafForm.postalAddress")}</label>
        <Control
          type="text"
          model="forms.cafForm.postalAddress"
          placeholder={polyglot.t("cafForm.postalAddress")}
          validateOn="change"
          className="input-box"
          autoComplete="new-password"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.postalAddress"
          show="touched"
          messages={{
            valueMissing: polyglot.t("cafForm.required.postalAddress"),
          }}
        />
      </Form.Field>
    );

    const registeredOfficeAddress = (
      <Form.Field>
        <label>{polyglot.t("cafForm.registeredOfficeAddress")}</label>
        <Control
          type="text"
          model="forms.cafForm.registeredOfficeAddress"
          placeholder={polyglot.t("cafForm.registeredOfficeAddress")}
          validateOn="change"
          className="input-box"
          autoComplete="new-password"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.registeredOfficeAddress"
          show="touched"
          messages={{
            valueMissing: polyglot.t(
              "cafForm.required.registeredOfficeAddress"
            ),
          }}
        />
      </Form.Field>
    );

    const typeOfBusiness = (
      <Form.Field required>
        <label>{polyglot.t("cafForm.typeOfBusiness")}</label>
        <Dropdown
          placeholder={polyglot.t("cafForm.typeOfBusiness")}
          selection
          options={typeOfBusinessOptions}
          onChange={this.typeOfBusinessChangeHandler}
        />
        {typeOfBusinessErrorFlag && (
          <span className="error-message">
            {polyglot.t("cafForm.required.typeOfBusiness")}
          </span>
        )}
      </Form.Field>
    );
    const phone = (
      <Form.Field required>
        <label>{polyglot.t("cafForm.phone")}</label>
        <CustomInput
          placeholder={polyglot.t("cafForm.phone")}
          options={{ phone: true, phoneRegionCode: "NZ" }}
          onChangeHandler={this.onChangePhoneHandler}
          autoComplete="new-password"
        />
        {phoneErrorFlag && (
          <span className="error-message">{phoneErrorMessage}</span>
        )}
      </Form.Field>
    );

    const bankName = (
      <Form.Field required>
        <label>{polyglot.t("cafForm.bankName")}</label>
        <Control
          type="text"
          model="forms.cafForm.bankName"
          placeholder={polyglot.t("cafForm.bankName")}
          required
          validateOn="change"
          className="input-box"
          autoComplete="new-password"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.bankName"
          show="touched"
          messages={{
            valueMissing: polyglot.t("cafForm.required.bankName"),
          }}
        />
      </Form.Field>
    );

    const dateBusinessStarted = (
      <Form.Field>
        <label>{polyglot.t("cafForm.dateBusinessStarted")}</label>
        <DatePicker
          givenDate={cafForm.dateBusinessStarted}
          setDate={(date) => this.setDate(date)}
          maxDate={moment()}
        />
      </Form.Field>
    );
    const companyRegistrationNumber = (
      <Form.Field required>
        <label>{polyglot.t("cafForm.companyRegistrationNumber")}</label>
        <Control
          type="text"
          model="forms.cafForm.companyRegistrationNumber"
          placeholder={polyglot.t("cafForm.companyRegistrationNumber")}
          required
          validateOn="change"
          className="input-box"
          autoComplete="new-password"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.companyRegistrationNumber"
          show="touched"
          messages={{
            valueMissing: polyglot.t(
              "cafForm.required.companyRegistrationNumber"
            ),
          }}
        />
      </Form.Field>
    );

    const fax = (
      <Form.Field>
        <label>{polyglot.t("cafForm.fax")}</label>
        <Control
          type="text"
          model="forms.cafForm.fax"
          placeholder={polyglot.t("cafForm.fax")}
          validateOn="change"
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.fax"
          show="touched"
          messages={{
            valueMissing: polyglot.t("cafForm.required.fax"),
          }}
        />
      </Form.Field>
    );
    const email = (
      <Form.Field>
        <label>{polyglot.t("cafForm.email")}</label>
        <Control
          type="email"
          model="forms.cafForm.email"
          placeholder={polyglot.t("cafForm.email")}
          validateOn="change"
          className="input-box"
          value={this.props.auth.user.email}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.email"
          show="touched"
          messages={{
            valueMissing: polyglot.t("cafForm.required.email"),
            typeMismatch: polyglot.t("cafForm.valid.email"),
          }}
        />
      </Form.Field>
    );
    const branchName = (
      <Form.Field required>
        <label>{polyglot.t("cafForm.branchName")}</label>
        <Control
          type="text"
          model="forms.cafForm.branchName"
          placeholder={polyglot.t("cafForm.branchName")}
          required
          validateOn="change"
          className="input-box"
          autoComplete="new-password"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.branchName"
          show="touched"
          messages={{
            valueMissing: polyglot.t("cafForm.required.branchName"),
          }}
        />
      </Form.Field>
    );

    return (
      <React.Fragment>
        <h3>{polyglot.t("cafForm.title.information")}</h3>
        <Divider />
        <Grid columns="equal">
          <Grid.Column width={8} computer={8} mobile={16}>
            {postalAddress}
          </Grid.Column>
          <Grid.Column width={8} computer={8} mobile={16}>
            <Form.Field required>
              <label>{polyglot.t("cafForm.physicalAddress")}</label>
              <div
                className="google-autocomplete-container"
                autoComplete="new-password"
              >
                <Autocomplete
                  style={{ width: "100%" }}
                  placeholder="Start typing your address"
                  className="input-box"
                  onPlaceSelected={(place) => {
                    this.onAddressSelection(place);
                  }}
                  onChange={(e) => this.editPhysicalAddress(e)}
                  types={["geocode"]}
                  value={this.props.forms.cafForm.physicalAddress}
                  componentRestrictions={{ country: "nz" }}
                />{" "}
              </div>
            </Form.Field>
          </Grid.Column>
          <Grid.Column width={8} computer={8} mobile={16}>
            {dateBusinessStarted}
          </Grid.Column>
          <Grid.Column width={8} computer={8} mobile={16}>
            {registeredOfficeAddress}
          </Grid.Column>
          <Grid.Column width={8} computer={8} mobile={16}>
            {phone}
          </Grid.Column>
          <Grid.Column width={8} computer={8} mobile={16}>
            {typeOfBusiness}
          </Grid.Column>
          <Grid.Column width={8} computer={8} mobile={16}>
            {companyRegistrationNumber}
          </Grid.Column>
          <Grid.Column width={8} computer={8} mobile={16}>
            {fax}
          </Grid.Column>
          <Grid.Column width={8} computer={8} mobile={16}>
            {email}
          </Grid.Column>
          <Grid.Column width={8} computer={8} mobile={16}>
            {bankName}
          </Grid.Column>
          <Grid.Column width={8} computer={8} mobile={16}>
            {branchName}
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    forms: state.forms,
    auth: state.auth,
    deliveryAddress: state.deliveryAddress,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(cafReducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CafInformationForm);
