import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import * as scheduleReducer from "./schedule.reducer";
import * as assetDetailReducer from "../AssetDetails/assetDetail.reducer";
import ScheduleList from "../../components/ScheduleList";
import {
  Grid,
  Icon,
  Divider,
  Button,
  Pagination,
  Menu,
  Dropdown,
  Label
} from "semantic-ui-react";
import "./schedule.css";
import _ from "lodash";
import RangePicker from "../../components/DateRangePicker";

const paginationLimit = 12;
class ScheduleMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activePage: 1,
      fromDate: "",
      toDate: "",
      assignTo: "",
      assetId: "",
      assetName: "",
      windowWidth: window.innerWidth
    };
  }
  componentWillMount = async () => {
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.flag === true
    ) {
      this.handleItemClick("history");
      this.setState({ assetId: this.props.location.state.assetId });
    }
    let params = this.props.location.search;
    if (params.indexOf("tab=overDue") > -1) {
      await this.props.toggleHistory("overdue");
      await this.getSchedules(0);
      return;
    }
  };

  componentDidMount = async () => {
    this.props.actionGetUsersForDropdown();
    await this.getSchedules(0);
  };

  getSchedules = async pageNumber => {
    this.setState({ isLoading: true });
    const { isHistory } = this.props;
    const {
      assignTo = "",
      fromDate = "",
      toDate = "",
      assetId = ""
      // assetName = ""
    } = this.state;
    await this.props.actionGetSchedules(
      paginationLimit,
      pageNumber,
      isHistory,
      fromDate,
      toDate,
      assignTo,
      assetId
    );
    await this.props.actionGetScheduleCount(fromDate,toDate,assignTo,assetId);
    this.setState({ isLoading: false });
  };

  goToPage = path => {
    let url = `/${path}`;
    this.props.history.push(url);
  };

  reviewMaintenanceForm = async id => {
    const { isHistory } = this.props;

    if (isHistory === "history") {
      this.props.history.push(`maintenance/check/${id}`);
    } else {
      this.props.history.push(`/maintenance/review/${id}`);
    }
  };

  closeForm = () => this.setState({ isAddSchedule: false });

  handlePaginationChange = (...params) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;
    const { totalPages } = this.props;

    if (totalPages === 1) {
      return;
    }

    this.setState({
      activePage
    });
    this.getSchedules(activePage - 1);
  };

  handleItemClick = async name => {
    await this.props.toggleHistory(name);
    this.getSchedules(0);
  };

  setAssignedUser = (e, data) => {
  
    const { value } = data;
   
    this.setState({ assignTo: value });
    
  };

  setAssetName = (e, data) => {
    const { value } = data;
    this.setState({ assetId: value });
  };

  setFromDate = date => {
    this.setState({ fromDate: date });
  };
  setToDate = date => {
    this.setState({ toDate: date });
  };

  clearFilter = async () => {
    await this.setState({
      assignTo: "",
      fromDate: "",
      toDate: "",
      assetName: "",
      assetId: ""
    });
    this.getSchedules(0);
  };

  render() {
    const {
      isLoading,
      activePage,
      assignTo,
      fromDate,
      toDate,
      assetId
    } = this.state;
    const { schedules = {}, isHistory, users, counts } = this.props;
    const {
      completed: completedCount,
      pending: pendingCount,
      overdue: overdueCount,
      schedule: scheduleCount
    } = counts;
    const { totalPages, allSchedule = [] } = schedules;
    let assetNameList = [];
    let usersList = [];
    if (users.length) {
      _.map(users, user => {
        const { firstName, lastName, _id } = user;
        usersList.push({
          key: _id,
          text: `${firstName} ${lastName}`,
          value: _id
        });
      });
    }

    if (allSchedule.length) {
      _.map(allSchedule, schedule => {
        const { assetName, _id, asset } = schedule;
        assetNameList.push({
          key: _id,
          text: `${assetName}`,
          value: asset
        });
      });
      assetNameList = _.uniqBy(assetNameList, name => {
        return name.text;
      });
    }
    const ActionButton = () => (
      <Grid>
        <Grid.Column computer={16} mobile={16} tablet={16} textAlign="right">
          <Button
            size="tiny"
            onClick={() => this.goToPage("home")}
            icon="left arrow"
            content="Go back"
            labelPosition="left"
            className="button-float"
          />
        </Grid.Column>
      </Grid>
    );

    const actionButtons = (
      <span>
        <Button
          content="Clear"
          loading={isLoading}
          onClick={this.clearFilter}
        />
        <Button
          content="Search"
          loading={isLoading}
          onClick={() => this.getSchedules(0)}
          color="black"
        />
      </span>
    );

    const filters = (
      <Grid>
        {/* <Grid.Column width={3}>
          <Dropdown
            placeholder="Select type"
            fluid
            value={inventoryType}
            selection
            options={allStock}
            onChange={this.setType}
          />
        </Grid.Column> */}
        <Grid.Column width={8} mobile={16}>
          <RangePicker
            setStartDate={date => this.setFromDate(date)}
            setEndDate={date => this.setToDate(date)}
            startDate={fromDate}
            endDate={toDate}
          />
        </Grid.Column>
        <Grid.Column width={8} computer={8} mobile={16}>
          <b>
            <Icon name="user" />
            Assigned to
          </b>
          <Dropdown
            placeholder="Assigned To"
            fluid
            value={assignTo}
            selection
            required
            onChange={this.setAssignedUser}
            options={usersList}
          />
        </Grid.Column>
        <Grid.Column width={8} computer={8} mobile={16}>
          <b>
            <Icon name="zip" />
            Asset Name
          </b>
          <Dropdown
            placeholder="Asset Name"
            fluid
            value={assetId}
            selection
            required
            onChange={this.setAssetName}
            options={assetNameList}
          />
        </Grid.Column>
        <Grid.Column
          computer={16}
          mobile={16}
          tablet={8}
          textAlign="right"
          style={{ marginTop: "27px" }}
        >
          {actionButtons}
        </Grid.Column>
      </Grid>
    );

    return (
      <div className="schedule-container">
        <MetaData title="Asset Maintenances" />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading, please wait</Loader>
          )}

          <Grid.Column width={16} style={{ paddingBottom: "0px" }}>
            <Grid>
              <Grid.Column width={8}>
                <h3>
                  <Icon name="calendar times" />
                  Asset Maintenance
                </h3>
              </Grid.Column>
              <Grid.Column width={8}>
                <ActionButton />
              </Grid.Column>
            </Grid>
            <Divider />
          </Grid.Column>
          {/* <Grid.Column width={16}>{filters}</Grid.Column> */}
          <Grid.Column
            width={16}
            textAlign="center"
            className="no-top-padded"
            //style={{ paddingBottom: "0px" }}
          >

            <h3 >Coming soon</h3>
          </Grid.Column>
          {/* <Grid.Column width={16}>
            <Menu fluid pointing widths={4} className="section-menu">
              <Menu.Item
                className="upcoming"
                active={isHistory === "upcoming"}
                onClick={() => this.handleItemClick("upcoming")}
              >
               <Icon name="repeat" /> {this.state.windowWidth < 480 ? "" : "Upcoming"}
                <Label color="red">{scheduleCount}</Label>
              </Menu.Item>

              <Menu.Item
                className="overdue"
                active={isHistory === "overdue"}
                onClick={() => this.handleItemClick("overdue")}
              >
                <Icon name="calendar"/> Overdue
                <Label color="red">{overdueCount}</Label>
              </Menu.Item>
              <Menu.Item
                className="history"
                active={isHistory === "pending"}
                onClick={() => this.handleItemClick("pending")}
              >
               <Icon name="setting"/> Pending Repairs
                <Label color="red">{pendingCount}</Label>
              </Menu.Item>
              <Menu.Item
                className="history"
                active={isHistory === "history"}
                onClick={() => this.handleItemClick("history")}
              >
               <Icon name="history" /> Completed
                <Label color="red">{completedCount}</Label>
              </Menu.Item>
            </Menu>
          </Grid.Column>
          <Grid.Column width={16} className="show-category">
            <Icon name="time" />
            <span>
              Showing
              {isHistory === "upcoming"
                ? " upcoming "
                : isHistory === "overdue"
                ? " overdue "
                : isHistory === "pending"
                ? "pending repair"
                : " completed "}
              scheduled maintenances.
            </span>
          </Grid.Column>
          <Grid.Column
            width={16}
            className="no-top-padded"
            style={{ paddingTop: "0px" }}
          >
            <ScheduleList
              schedules={allSchedule}
              reviewMaintenanceForm={this.reviewMaintenanceForm}
              activeSection={isHistory}
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <div>
              {allSchedule.length > 0 ? (
                <Pagination
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={this.handlePaginationChange}
                  totalPages={totalPages}
                  firstItem={null}
                  lastItem={null}
                />
              ) : null}
            </div>
          </Grid.Column> */}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    schedules: state.schedule.schedules,
    isHistory: state.schedule.isHistory,
    users: state.assetDetail.users,
    counts: state.schedule.counts
  };
};

const reducer = { ...scheduleReducer, ...assetDetailReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScheduleMaintenance)
);
