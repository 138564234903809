export const createChatGroup = {
  participantsList: [],
  chatType: 1,
  chatTitle: "",
  channelIcon: null,

};

export const createVoiceMessage = {
  voiceMessages : ''
}
