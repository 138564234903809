import React, { Component } from 'react';
import './chat.css';
import {
    Button,
    Grid,
    Search,
    Dropdown,
    List,
    Checkbox,
    Loader,
    Image,
    Icon
} from "semantic-ui-react";
import AddGroupModal from './AddGroupModal';
import FilterModal from './FilterModal';
import DeleteChatModal from './DeleteChatModal';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from "lodash";
import * as chatReducer from './chat.reducer';
import { Client as ConversationsClient } from "@twilio/conversations";
import Conversation from "../../components/ChatModule/Conversation";
import GroupInfo from './GroupInfo';
import NewChatModal from './NewChatModal';
import { apiGetRefreshedToken } from './chat.api';
import RemoveMemberModal from './AddMemberModal';
import SearchUser from './SearchUser/SearchUser';
import ExitGroupModal from './ExitGroupModal';
import SearchConversation from './SearchUser/SearchConversation';
import firebase from 'firebase';

class ChatLandingPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            grouplist: [],
            isNewGroup: false,
            selectedval: '',
            participantsList: [],
            chatType: 0,
            createdBy: '',
            userErrorFlag: false,


            identity: localStorage.getItem("user"),
            token: null,
            statusString: null,
            conversationsReady: false,
            conversations: [],
            selectedConversationSid: null,
            selectedConversation: '',
            newMessage: "",
            status: '',
            showGroupInfo: false,
            loading: false,
            groupDetails: []
        }
    }
    //===================Sidebar=====================================================
    handleSingleChat = () => {


        this.setState({ isNewGroup: true, userErrorFlag: false })

        const formValue = {
            ...this.props.chatForm,

            chatType: 1
        };


        this.props.actionSetFormValue(formValue);


    };
    handleGroupChat = () => {


        this.setState({ isNewGroup: true, userErrorFlag: false })

        const formValue = {
            ...this.props.chatForm,

            chatType: 2
        };


        this.props.actionSetFormValue(formValue);

    };

    handleClose = () => {
        this.setState({ isNewGroup: false })
        this.props.actionResetForm("chatForm");
        this.props.actionClearSearch();
    }

    componentDidMount() {
        const { conversations } = this.state;

        this.getRefreshedToken();
       //this.fireBasePushNotification();

       // this.getToken();

        // if(!conversations){
        //     this.getRefreshedToken();
        // }
        this.props.getChatUserList();

        //console.log("Group list: " + conversations)

        this.props.actionGetConversationsList();

    }
    componentDidUpdate(prevProps, prevState) {
        const { conversations, selectedConversationSid } = this.state;
        const { conversationList } = this.props;

        if (!conversations) {
            this.getRefreshedToken();
        }

        //   if(prevProps.conversationList !== conversationList){
        //     this.props.actionGetConversationsList()

        //   }



    }


    updateState = data => {
        this.setState(data);
    };


    onSelectChange = (e, data) => {
        this.updateState({ userErrorFlag: false });
        const { value } = data;
        if (window._.isEmpty(value)) {
            this.updateState({ userErrorFlag: true });
        }

        const formValue = {
            ...this.props.chatForm,
            participantsList: value.split(",")
        };
        this.props.actionSetFormValue(formValue);
    };


    resetSelectedUsers = () => {
        this.props.actionResetForm("chatForm")
    }



    checkedUsers = value => {


        let { participantsList } = this.props.chatForm;
        let newParticipantsList = [...participantsList];
        let isAvailable = _.includes(participantsList, value);
        if (isAvailable) {
            _.remove(newParticipantsList, n => value === n);
        } else {
            newParticipantsList.push(value);
        }
        this.props.actionSetFormField("participantsList", newParticipantsList);

        // console.log("Checked user list :" + participantsList)

    };

    handleItemClick = (event, data, i) => {
        const { optionsArray } = this.state;
        optionsArray[i].show = !optionsArray[i].show;
        this.setState(() => {
            return optionsArray;
        });
    };






    getToken = async () => {


        const myToken = window.localStorage.getItem("chatAccessToken")
        // const myToken = await apiGetRefreshedToken();
        // const { data } = myToken;

        this.setState({ token: myToken }, this.initConversations);
    };

    getRefreshedToken = async () => {

        const newToken = await apiGetRefreshedToken();
        const { data } = newToken;

        this.setState({ token: data }, this.initConversations);
    };

    // fireBasePushNotification = () => {
    //     //============Push notification=============
       
      
    //     var config = {
    //         apiKey: "AIzaSyBxmvgfE3Veq8hSXwufuy4K-usZxYPwL_s",
    //         // authDomain: "...",
    //         // databaseURL: "...",
    //         projectId: "boamobileapp",
    //         // storageBucket: "...",
    //          messagingSenderId: "779152604336"
       
    //     };

      
    //         firebase.initializeApp(config);
          


    //     if (firebase && firebase.messaging()) {
    //         // requesting permission to use push notifications
    //         firebase.messaging().Messaging.requestPermission().then(() => {
    //             // getting FCM token
    //             firebase.messaging().getToken().then((fcmToken) => {
    //                 // continue with Step 7 here 
    //                 chatClientInstance.setPushRegistrationId('fcm', fcmToken);

    //                 // registering event listener on new message from firebase to pass it to the Chat SDK for parsing
    //                 firebase.messaging().onMessage(payload => {
    //                     chatClientInstance.handlePushNotification(payload);
    //                 });
    //             }).catch((err) => {
    //                 console.log(err);
    //             });
    //         }).catch((err) => {
    //             console.log(err);
    //             // can't request permission or permission hasn't been granted to the web app by the user
    //         });
    //     } else {
    //         console.log("no Firebase library imported or Firebase library wasn't correctly initialized");
    //         // no Firebase library imported or Firebase library wasn't correctly initialized
    //     }
    // }


    initConversations = async () => {
        window.conversationsClient = ConversationsClient;
        this.conversationsClient = await ConversationsClient.create(this.state.token);
        this.setState({ statusString: "Connecting to Twilio…" });



      //  ============================================
  
        // const config = {
        //     apiKey: "AIzaSyBxmvgfE3Veq8hSXwufuy4K-usZxYPwL_s",
        //     // authDomain: "...",
        //     // databaseURL: "...",
        //     projectId: "boamobileapp",
        //     // storageBucket: "...",
        //      messagingSenderId: "779152604336",
           
       
        // };

        var firebaseConfig = {
            apiKey: "AIzaSyBLgtxwo6jZARH1besX-5V4eTh2K8W0tWQ",
            authDomain: "webchatmodule-6f742.firebaseapp.com",
            projectId: "webchatmodule-6f742",
            storageBucket: "webchatmodule-6f742.appspot.com",
            messagingSenderId: "904767802716",
            appId: "1:904767802716:web:ede79a7cc11ed03f3ac599",
            measurementId: "G-NFHLZ77SJN"
          };
          // Initialize Firebase
         // firebase.initializeApp(firebaseConfig);
         //firebase.analytics();

        //  firebase.initializeApp(config);
        if (!firebase.apps.length) {
         
            firebase.initializeApp(firebaseConfig);
          }
          


        if (firebase && firebase.messaging()) {
            // requesting permission to use push notifications
            firebase.messaging().requestPermission().then(() => {
                // getting FCM token
                firebase.messaging().getToken().then((fcmToken) => {
                    // continue with Step 7 here 
                    this.conversationsClient.setPushRegistrationId('fcm', fcmToken);

                    // registering event listener on new message from firebase to pass it to the Chat SDK for parsing
                    firebase.messaging().onMessage(payload => {
                        this.conversationsClient.handlePushNotification(payload);
                    });
                }).catch((err) => {
                    console.log(err);
                });
            }).catch((err) => {
                console.log(err);
                // can't request permission or permission hasn't been granted to the web app by the user
            });
        } else {
            console.log("no Firebase library imported or Firebase library wasn't correctly initialized");
            // no Firebase library imported or Firebase library wasn't correctly initialized
        }
    




        this.conversationsClient.on("connectionStateChanged", (state) => {
            if (state === "connecting")
                this.setState({
                    statusString: "Connecting to Twilio…",
                    status: "default"
                });
            if (state === "connected") {
                this.setState({
                    statusString: "You are connected.",
                    status: "success"
                });
            }
            if (state === "disconnecting")
                this.setState({
                    statusString: "Disconnecting from Twilio…",
                    conversationsReady: false,
                    status: "default"
                });
            if (state === "disconnected")
                this.setState({
                    statusString: "Disconnected.",
                    conversationsReady: false,
                    status: "warning"
                });
            if (state === "denied")
                this.setState({
                    statusString: "Failed to connect.",
                    conversationsReady: false,
                    status: "error"
                });
        });

        this.conversationsClient.on("tokenAboutToExpire", async () => {

            const newToken = await apiGetRefreshedToken();
            const { data } = newToken;
            this.conversationsClient.updateToken(data);
        });

        this.conversationsClient.on("tokenExpired", async () => {
            const newToken = await apiGetRefreshedToken();
            const { data } = newToken;
            this.conversationsClient.updateToken(data);
        });


        this.conversationsClient.on("conversationJoined", (conversation) => {
            this.setState({ conversations: [...this.state.conversations, conversation] });
        });
        this.conversationsClient.on("conversationLeft", (thisConversation) => {
            this.setState({
                conversations: [...this.state.conversations.filter((it) => it !== thisConversation)]
            });
        });
    };

    onConversationClick = groups => {

        this.setState({ selectedConversationSid: groups.sid }, async () => {
            const result = await this.props.getConversationDetails(this.state.selectedConversationSid)

            this.setState({
                chatType: this.props.conversationDetails.chatDetails.chatType,
                createdBy: this.props.conversationDetails.chatDetails.createdBy
            })


        })



    }


    //==============================Group Info==========================================
    handleShowGroupInfo = () => {
        this.setState({ showGroupInfo: true })

    }
    handleCloseGroupInfo = () => {
        this.setState({ showGroupInfo: false })
    }



    handleDeleteGroupChat = async () => {
        this.setState({ loading: true });
        const { selectedConversationSid } = this.state;
        let conversationId = {
            conversationSID: selectedConversationSid.split(',')
        };


        await this.props.actionDeleteChat(conversationId);
        this.setState({ loading: false });
        this.props.actionGetConversationsList();


    };
    handleExitChatgroup = async () => {
        this.setState({ loading: true });
        const { selectedConversationSid } = this.state;
        let conversationId = {
            conversationSID: selectedConversationSid
        };


        await this.props.actionExitChatgroup(conversationId);


        this.setState({ loading: false });
        this.props.actionGetConversationsList();
    };

    //==============================Group Info==========================================

    render() {
        const loggedInUser = localStorage.getItem("user");
        const loggedInUserName = localStorage.getItem("userName");
        const { isNewGroup, showGroupInfo, isLoading } = this.state;
        const { chatUserList, chatForm, searchResult, conversationDetails, conversationList, searchConversationResult } = this.props;
        const { chatType, participantsList } = chatForm;

        let userList = [];
        if (searchResult && searchResult.length) {
            _.map(searchResult, user => {
                const { firstName, lastName, mobileNumber, email, _id } = user;
                userList.push({ name: firstName, value: email, id: _id, lastName: lastName, mobileNumber: mobileNumber });
            });
        }
        else {
            _.map(chatUserList, user => {
                const { firstName, email, _id, lastName, mobileNumber, } = user;
                userList.push({ name: firstName, value: email, id: _id, lastName: lastName, mobileNumber: mobileNumber });
            });
        }

        let allConversationsList = [];
        if (searchConversationResult && searchConversationResult.length) {
            _.map(searchConversationResult, chat => {
                const { friendlyName, chatDetails, sid, dateCreated } = chat;
                allConversationsList.push({ friendlyName: friendlyName, chatType: chatDetails.chatType, sid: sid, dateCreated: dateCreated, lastMessage: chatDetails.lastMessage });
            });
        }
        else {
            _.map(conversationList, chat => {
                const { friendlyName, chatDetails, sid, dateCreated } = chat;
                allConversationsList.push({ friendlyName: friendlyName, chatType: chatDetails.chatType, sid: sid, dateCreated: dateCreated, lastMessage: chatDetails.lastMessage });
            });
        }
        const filterdUsers = [...userList];
        let selectedUsers = filterdUsers.filter((filterdUsers) => participantsList.includes(filterdUsers.value))



        const { conversations, selectedConversationSid, status, identity, groupDetails } = this.state;
        const selectedConversation = conversations.find(
            it => {
                //console.log("new conv item :" + it)
                return it.sid === selectedConversationSid
            }
        );


        let conversationContent;
        if (selectedConversation) {
            conversationContent = (
                <Conversation
                    conversationProxy={selectedConversation}
                    myIdentity={identity}
                />
            );
        } else if (status !== "success") {
            conversationContent = <Loader active inline='centered' content={<h3>Loading your conversation...</h3>} />;

        } else {
            conversationContent = "";
        }




        return (
            <div>
                <div className="chat_wrapper">
                    <Grid>

                        <Grid.Column mobile={16} computer={16}>
                            <div className="chat_header">
                                <Grid>
                                    <Grid.Column mobile={8} tablet={12} computer={12} className="no-padding">
                                        <h3><i className="boa ba-chat_b"></i> Chat </h3>
                                    </Grid.Column>
                                    <Grid.Column mobile={8} tablet={4} computer={4}>
                                        <div className="action_tool">
                                            <a href="#"><i className="boa ba-product"></i></a>
                                        </div>
                                    </Grid.Column>
                                </Grid>

                            </div>
                            <div className="chat_body">
                                {!isNewGroup ?
                                    <div className="chat_left">
                                        <div className="chat_search">
                                            <SearchConversation />
                                        </div>

                                        <div className="userchat-list">
                                            <div className="userchat-btn">
                                                {/* <Button color="green" onClick={this.handleSingleChat}> <i className="boa ba-add_user"></i>New Chat</Button> */}
                                                <NewChatModal />

                                                <Button content='Group Chat' secondary onClick={this.handleGroupChat}> <i className="boa ba-add_group"></i>Group Chat </Button>
                                            </div>
                                            <div className="contacts-list">
                                                <List>
                                                    {allConversationsList && _.map(allConversationsList, groups => {
                                                        //console.log("Conversation details" + groups.sid)
                                                        return <List.Item className="contact" key={groups.sid} onClick={() => this.onConversationClick(groups)} >
                                                            <div className="wrap">
                                                                <div className="contact_img">
                                                                    <div className="contact_img_txt">
                                                                        {groups.chatType === 1 ? <i className="boa ba-single_user"></i> : <i className="boa ba-user_group"></i>}
                                                                        {/* {groups.channelState.attributes.chatType===1? <i className="boa ba-single_user"></i>:<i className="boa ba-user_group"></i>} */}
                                                                        {/* {groups.channelState.attributes.chatType===1?
                                                                        <div className="contact_img">
                                                                        <div className="contact_img_txt">
                                                                        <i className="boa ba-single_user"></i>
                                                                        </div></div>
                                                                        
                                                                        : <div className="contact_img_txt">
                                                                        <Image src={groups.channelState.attributes.channelIcon} avatar styles={{borderRadius:"100px",width:"100%"}}/>
                                                                        </div>
                                                                        } */}

                                                                        {/* <Image src={groups.channelIcon} /> */}

                                                                    </div>

                                                                </div>
                                                                <div className="meta">
                                                                    <div className="name">
                                                                        <div className="usertext">
                                                                            {/* {groups.channelState.attributes.chatType===1 &&groups.channelState.attributes.createdBy===loggedInUser?groups.friendlyName: } */}


                                                                            {groups.friendlyName || groups.sid}

                                                                            {/* {groups.friendlyName} */}
                                                                            <text style={{ float: "right" }}>
                                                                                {new Date(groups.dateCreated).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })}
                                                                            </text>



                                                                        </div>
                                                                        <div className="time">      </div>

                                                                    </div>
                                                                    <div className="preview">
                                                                        {/* {groups.dateCreated.toLocaleString('en-US', { hour12: false })} */}

                                                                        {/* { new Date(groups.dateCreated).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'})} */}
                                                                        {groups.lastMessage !== null ? groups.lastMessage && groups.lastMessage.body || groups.lastMessage.media && <text><Icon name="camera retro" content="Image" /> &nbsp; Image</text> : null}
                                                                        {/* {groups.lastMessage && groups.lastMessage.body || groups.lastMessage.media &&  <text><Icon name ="camera retro" content="Image"/> &nbsp; Image</text> } */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </List.Item>
                                                    })}
                                                </List>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="chat_left addGroup_wrapper">
                                        <div className="chatleft_header">
                                            <h3>Add New Group</h3>
                                            <a href="#"> <i className="boa ba-close" onClick={this.handleClose}></i></a>
                                        </div>
                                        <div className="chat_search">
                                            <div className="chat_innersearch">
                                                <SearchUser />
                                                <FilterModal />
                                                {/* <div className="filter_warp">
                                                <a href="#" className="filter_btn"> <i className="boa ba-filter"></i></a>
                                                <span className="filter_on"></span>
                                            </div> */}
                                            </div>
                                            <div className="filter_options">
                                                {_.map(selectedUsers, user => {
                                                    return <List key={user.id}>
                                                        <List.Item className="chips_item">
                                                            <div className="chip_uesr_info">
                                                                <div className="chip_user_img">
                                                                    {user.name.charAt(0) + user.lastName.charAt(0)}
                                                                </div>
                                                                <div className="chip_user_name">{user.name}&nbsp;{user.lastName}</div>
                                                            </div>
                                                            <div className="chip_action">
                                                                <a href="#" className="close"> <i className="boa ba-close_circle" onClick={() => this.checkedUsers(user.value)}></i></a>
                                                            </div>
                                                        </List.Item>
                                                    </List>
                                                })}

                                            </div>

                                        </div>
                                        <div className="userchat-list">
                                            <div className="contacts-list">
                                                <List>
                                                    {_.map(userList, users => {
                                                        const { name, lastName, mobileNumber, value } = users;
                                                        return <List.Item className="contact" key={users.id}>
                                                            <div className="wrap">
                                                                <div className="contact_img">
                                                                    <div className="contact_img_txt">
                                                                        {name.charAt(0) + lastName.charAt(0)}
                                                                    </div>
                                                                    {/* <span><i className="boa ba-add_user"></i></span> */}
                                                                </div>
                                                                <div className="meta">
                                                                    <div className="name">
                                                                        <div className="usertext">{name}&nbsp;{lastName}</div>

                                                                    </div>
                                                                    <div className="preview">{mobileNumber}</div>
                                                                </div>
                                                            </div>
                                                            <div className="custom_checkbox">
                                                                <Checkbox


                                                                    onChange={() => this.checkedUsers(value)}
                                                                    value={value}
                                                                    checked={_.includes(participantsList, value)}
                                                                />
                                                            </div>
                                                        </List.Item>

                                                    })}

                                                </List>
                                            </div>
                                            <div className="footer_action">
                                                <AddGroupModal selectedUsers={selectedUsers} onDeleteUser={(value) => this.checkedUsers(value)} disabled={!selectedUsers} />
                                            </div>
                                        </div>
                                    </div>

                                }
                                <div className="chat_right">
                                    <div className="contact-profile">
                                        <div className="contact-profile-img">
                                            {selectedConversation &&
                                                <div className="contact_img_txt">
                                                    {/* HS */}
                                                    {/* <span><i className="boa ba-user_group"></i></span> */}

                                                    {conversationDetails && conversationDetails.chatDetails.chatType === 1 ? <i className="boa ba-single_user"></i> : <i className="boa ba-user_group"></i>}

                                                </div>
                                            }
                                            <div className="contact-profile-name">
                                                {selectedConversation && (conversationDetails && conversationDetails.chatDetails.chatType === 2 ? conversationDetails && conversationDetails.friendlyName : conversationDetails && conversationDetails.participantsDetails.map(user => {
                                                    if (user.email !== loggedInUser) {
                                                        return <text>{user.firstName}&nbsp;{user.lastName}</text>
                                                    }
                                                }))}

                                                {/* {conversationDetails && conversationDetails.friendlyName} */}
                                                {/* {conversationDetails && conversationDetails.participantsDetails.map(user => {
                                                    return <text>{user.firstName} &nbsp;&nbsp; </text>
                                                })} */}

                                            </div>
                                        </div>
                                        {selectedConversation &&
                                            <div className="contact-profile-action">
                                                <Dropdown
                                                    //text='Filter'
                                                    icon='ellipsis vertical'
                                                    floating
                                                    labeled
                                                    button
                                                    className='icon dotmenu'
                                                >
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item text='Group Info' onClick={this.handleShowGroupInfo} />
                                                        {/* <Dropdown.Item text='Mute Notification' /> */}
                                                        {(this.state.chatType && this.state.createdBy === loggedInUser) ? <React.Fragment>
                                                            {this.state.chatType === 1 ? null
                                                                : <React.Fragment>
                                                                    <Dropdown.Item text={<RemoveMemberModal operation="remove" />} />
                                                                    <Dropdown.Item text={<DeleteChatModal onDeleteChat={this.handleDeleteGroupChat} />} />
                                                                </React.Fragment>}

                                                            {/* <Dropdown.Item text={<ExitGroupModal onExitChat={this.handleExitChatgroup} />} /> */}

                                                        </React.Fragment> : null}


                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        }
                                    </div>


                                    {conversationContent}



                                </div>
                                {showGroupInfo && <div className="groupinfo_wrapper">
                                    <GroupInfo
                                        closeGroupInfo={this.handleCloseGroupInfo}


                                    />
                                </div>
                                }
                            </div>
                        </Grid.Column>
                    </Grid>
                </div>



            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        chatUserList: state.chatGroups.chatuserList.data,
        conversationList: state.chatGroups.allGroups.data,
        chatForm: state.forms.chatForm,
        searchResult: state.chatGroups.searchResult.data,
        searchConversationResult: state.chatGroups.searchConversationResult.data,
        conversationDetails: state.chatGroups.conversationDetails.data,
    }
}



const reducer = { ...chatReducer };

const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ChatLandingPage);

