import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { apiUpdatePaymentResultAndUpdateOrder } from "./orderConfirmation.api";
import * as orderConfirmationReducer from "./orderConfirmation.reducer";
import * as cartReducer from "../../containers/Cart/cart.reducer";
import {
    Segment,
    Icon,
} from "semantic-ui-react";

class PaymentConfirmation extends React.Component {
    constructor() {
        super();
        const paymentResult = this.getPaymentResponse();
        this.updateOrderWithPaymentResponse(paymentResult);
    }

    getPaymentResponse = () => {
        let url = document.location.href;
        url = new URL(url);
        const { searchParams } = url;
        const result = searchParams.get("result");
        const userId = searchParams.get("userid");
        const success = searchParams.get("success");

        const paymentResult = {
            success,
            result,
            userId
        }

        return paymentResult;
    };

    updateOrderWithPaymentResponse = async (paymentResult) => {
        const { success } = paymentResult;
        const response = await apiUpdatePaymentResultAndUpdateOrder(paymentResult);

        if (success === "true" || success === true) {
            // console.log("this.props", this.props);
            this.props.actionClearAll();
        }
        this.props.actionSetPaymentGatewayResponse(response);
        this.props.history.push("/orderConfirmation");
    }

    render() {
        return (
            <Segment color='green'>
                <Icon loading name='spinner' />
                <Icon name="credit card outline" />
                Updating Payment Status. Please wait ...
            </Segment>
        );
    }
}

const mapStateToProps = state => {
    return {

    };
};

const reducer = { ...orderConfirmationReducer, ...cartReducer };

const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PaymentConfirmation);
