import polyglot from "../i18n";

export const getErrorMessage = status => {
  switch (status) {
    case 1001: {
      return polyglot.t("errorMessage.invalidCalculation");
    }
    case 1002: {
      return polyglot.t("errorMessage.noAccess");
    }
    case 1003: {
      return polyglot.t("errorMessage.unknownType");
    }
    case 1004: {
      return polyglot.t("errorMessage.creditNotAssign");
    }
    case 1005: {
      return polyglot.t("errorMessage.insufficientCredit");
    }

    case 1101: {
      return polyglot.t("errorMessage.stockCodeEmpty");
    }
    case 1102: {
      return polyglot.t("errorMessage.insufficientStock");
    }
    case 1103: {
      return polyglot.t("errorMessage.invalidFromLocation");
    }
    case 1104: {
      return polyglot.t("errorMessage.duplicateLocation");
    }
    case 1105: {
      return polyglot.t("errorMessage.allFieldsRequire");
    }
    case 1106: {
      return polyglot.t("errorMessage.invalidOrder");
    }
    case 1107: {
      return polyglot.t("errorMessage.invalidLocation");
    }
    case 1108: {
      return polyglot.t("errorMessage.duplicateCrew");
    }
    case 1109: {
      return polyglot.t("errorMessage.invalidCrew");
    }
    case 1110: {
      return polyglot.t("errorMessage.invalidAsset");
    }
    case 1112: {
      return polyglot.t("errorMessage.duplicateForm");
    }
    case 1113: {
      return polyglot.t("errorMessage.insufficientValue");
    }
    case 1114: {
      return polyglot.t("errorMessage.endDateBlank");
    }
    case 1115: {
      return polyglot.t("errorMessage.invalidScheduleMaintenance");
    }
    case 1116: {
      return polyglot.t("errorMessage.invalidUser");
    }
    case 1117: {
      return polyglot.t("errorMessage.urlValueMissing");
    }
    case 1118: {
      return polyglot.t("errorMessage.duplicateAsset");
    }
    case 1119: {
      return polyglot.t("errorMessage.formAlreadyInUseError");
    }
    case 1121: {
      return polyglot.t("errorMessage.assetLocationCannotBeDeleted");
    }
    case 1122: {
      return polyglot.t("errorMessage.crewCannotBeDeleted");
    }
    case 1123: {
      return polyglot.t("errorMessage.inventoryLocationCannotBeDeleted");
    } 
    case 1124: {
      return polyglot.t("errorMessage.duplicateCheckpointField");
    }
    case 1125: {
      return polyglot.t("errorMessage.noteRequired");
    }
    default:
      return polyglot.t("errorMessage.default");
  }
};
