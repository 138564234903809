import React from "react";
import { Grid, Image, Divider, Checkbox, Message } from "semantic-ui-react";
import PropTypes from "prop-types";
import _ from "lodash";
import hoseDemoImage from "./assets/images/hose/Hose_body1.png";
import spiralDemoImage from "./assets/images/hose/spiralGuard.png";
import HoseImages from "./HoseImages";
import HosePriceCalculation from "./HosePriceCalculations";
import { TBA, HOSE_FITTING_IMAGE_S3_LINK } from "./hoseConstants";
import FerruleImage from "./FerruleImage";
import "./hoseConfirmation.css";

const HoseConfirmModal = ({
  hose,
  left,
  right,
  leftAngle,
  rightAngle,
  spiral,
  additionalProducts,
  hoseLength,
  overAllLength,
  checkboxHandler,
  crimpLeft,
  crimpRight,
  phase,
  leftSeatTypeImage,
  rightSeatTypeImage,
  rightFerrule,
  leftFerrule,
  toCart,
  saveHoseRecipe
}) => {
  let hoseImage = "";
  let leftFittingImage = "";
  let rightFittingImage = "";
  let spiralImage = "";
  let leftThreadType1 = "";
  let rightThreadType1 = "";
  let customClass = "";
  if (!_.isEmpty(hose)) {
    hoseImage = hoseDemoImage;
  }
  if (!_.isEmpty(left)) {
    const { productDimensions, stockCode } = left;
    const {
      threadType1,
      orientation,
      physicalThreadSize1,
      nominalThreadSize1 = ""
    } = productDimensions;
    if (orientation && orientation !== "STRAIGHT") {
      customClass = "rotateUp";
    }
    leftThreadType1 = `${physicalThreadSize1}mm 'D' ${nominalThreadSize1} ${threadType1} `;
    const stockCodeArray = stockCode.split("-");
    const code = stockCodeArray[0];
    leftFittingImage = HOSE_FITTING_IMAGE_S3_LINK + code + `HM_1.jpg`;
  }
  if (!_.isEmpty(right)) {
    const { productDimensions, stockCode } = right;
    const {
      threadType1,
      physicalThreadSize1,
      nominalThreadSize1 = ""
    } = productDimensions;
    rightThreadType1 = `${physicalThreadSize1}mm 'D' ${nominalThreadSize1} ${threadType1}`;
    const stockCodeArray = stockCode.split("-");
    const code = stockCodeArray[0];
    rightFittingImage = HOSE_FITTING_IMAGE_S3_LINK + code + `HM_1.jpg`;
  }
  if (!_.isEmpty(spiral)) {
    spiralImage = spiralDemoImage;
  }
  return (
    <React.Fragment>
      <div className="hose-confirm-modal">
        <Grid columns="equal">
          <Grid.Column>
            <div>
              {" "}
              <b>Crimp : {crimpLeft ? crimpLeft + "mm" : TBA}</b>
              <br />
              <b>
                {leftThreadType1}{" "}
                {leftSeatTypeImage && (
                  <Image src={leftSeatTypeImage} size={"tiny"} />
                )}
              </b>
              {!_.isEmpty(rightFerrule) && (
                <FerruleImage alt="Left ferrule" ferrule={leftFerrule} />
              )}
            </div>
          </Grid.Column>
          <Grid.Column>&nbsp;</Grid.Column>
          <Grid.Column>
            <div>
              {" "}
              <b>Crimp : {crimpRight ? crimpRight + "mm" : TBA}</b>
              <br />
              <b>
                {rightThreadType1}{" "}
                {rightSeatTypeImage && (
                  <Image src={rightSeatTypeImage} size={"tiny"} />
                )}
              </b>
              {!_.isEmpty(rightFerrule) && (
                <FerruleImage alt="Right ferrule" ferrule={rightFerrule} />
              )}
            </div>
          </Grid.Column>
        </Grid>
        <Grid columns="equal">
          <Grid.Column>
            <Image
              src={leftFittingImage}
              className={
                customClass ? "fitter-image-left rotateUp" : "fitter-image-left"
              }
            />
          </Grid.Column>
          <Grid.Column className="hose-bore">
            <Image src={hoseImage} className="bore-image" />
          </Grid.Column>
          <Grid.Column>
            <Image src={rightFittingImage} className="fitter-image-right" />
            <b>Rotation: {rightAngle} </b>
          </Grid.Column>
        </Grid>
        <Divider horizontal className="hose-length">
          Hose Length :{" "}
          {hoseLength > 0 ? <span>{hoseLength} mm</span> : <span>mm</span>}
        </Divider>
        <Divider horizontal className="overall-length">
          Overall Length : {overAllLength ? overAllLength.toFixed(2) : " "} mm
        </Divider>
        <Grid>
          <Grid.Column>
            <Image src={spiralImage} />
          </Grid.Column>
        </Grid>
        <HoseImages additionalProduct={additionalProducts} />
        {toCart &&
        !saveHoseRecipe && ( // phase === 1 && - removed for replace hose flow on click of Order hose from boa button
            <Grid>
              <Grid.Column width={4}>
                <HosePriceCalculation />
              </Grid.Column>
              <Grid.Column width={12}>
                <Message>
                  <Message.Content>
                    <p>
                      <b>
                        Please review your hose assembly thoroughly and ensure
                        it is correct as per your requirements.
                      </b>{" "}
                      Please ensure hose, threads, angles and lengths are
                      correct prior to adding this hose assembly to your cart.
                      Once a hose assembly is added to the cart it is considered
                      to be correct and will be manufactured as shown
                    </p>
                    <Checkbox
                      label={
                        "I have checked my hose assembly and agree it is correct."
                      }
                      onChange={checkboxHandler}
                    />
                  </Message.Content>
                </Message>
              </Grid.Column>
            </Grid>
          )}
      </div>
    </React.Fragment>
  );
};

HoseConfirmModal.propTypes = {
  hose: PropTypes.object,
  left: PropTypes.object,
  right: PropTypes.object,
  spiral: PropTypes.object,
  additionalProducts: PropTypes.array,
  leftAngle: PropTypes.number,
  rightAngle: PropTypes.number,
  hoseLength: PropTypes.number,
  overAllLength: PropTypes.number,
  hoseString: PropTypes.string,
  hoseDescription: PropTypes.string,
  makeAtBoa: PropTypes.bool,
  totalPrice: PropTypes.number,
  checkboxHandler: PropTypes.func,
  crimpLeft: PropTypes.string,
  crimpRight: PropTypes.string,
  phase: PropTypes.number,
  toCart: PropTypes.toCart
};

export default HoseConfirmModal;
