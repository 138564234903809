import axios from "axios";
import * as API_LINKS from "../../constants/apiLink";
import _ from "lodash";

export const apiHoseCategories = type => {
  const url =
    API_LINKS.HOSE_MAKER + "/" + API_LINKS.HOSE_CATEGORIES + "?type=" + type;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiGetAllSpiralGuards = () => {
  const url = API_LINKS.HOSE_MAKER + "/" + API_LINKS.HOSE_ALL_SPIRAL_GUARDS;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiGetProductDetails = stockCode => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.HOSE_PRODUCT_DETAILS +
    "?stockCode=" +
    stockCode;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiGetHoseDiameters = group => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.GET_HOSE_DIAMETERS +
    "?group=" +
    group;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiSearchHoseWithFilter = (group, diameter) => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.SEARCH_HOSE +
    "?group=" +
    group +
    "&diameter=" +
    diameter;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiSearchFittingForHose = (
  dashSize,
  orientation,
  measurement,
  hoseStockCode,
  threadType
) => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.SEARCH_FITTER +
    `?dashSize=${dashSize}&orientation=${orientation}&measurement=${measurement}&hoseStockCode=${hoseStockCode}&threadType=${threadType}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiAutocompleteFittings = (searchTerm, dashSize, stockCode) => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.FITTING_AUTO_COMPLETE +
    `?searchTerm=${searchTerm}&dashSize=${dashSize}&stockCode=${stockCode}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiGetFittingMeasurementByOrientation = (
  dashSize,
  orientation,
  stockCode
) => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.GET_FITTING_MEASUREMENT +
    `?dashSize=${dashSize}&orientation=${orientation}&stockCode=${stockCode}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiAdditionalProductAutoComplete = (searchTerm, category) => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.HOSE_ADDITIONAL_PRODUCT_AUTO_COMPLETE +
    `?searchTerm=${searchTerm}&category=${category}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiAdditionalProductSearch = (
  searchTerm,
  level,
  activePage = 1,
  threadType1,
  threadSize1,
  threadType2,
  threadSize2,
  size = 10
) => {
  let url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.HOSE_ADDITIONAL_PRODUCT_SEARCH +
    `?searchTerm=${searchTerm}`;
  let filter = ``;
  if (level) {
    level = _.replace(level, "&", "$and$");
    url = `${url}&level=${level}&category=${level}`;
  }
  if (activePage) {
    url = `${url}&activePage=${activePage}`;
  }
  if (threadType1) {
    filter = `&threadType1=${threadType1}`;
  }
  if (threadSize1) {
    filter = `${filter}&threadSize1=${threadSize1}`;
  }
  if (threadType2) {
    filter = `${filter}&threadType2=${threadType2}`;
  }
  if (threadSize2) {
    filter = `${filter}&threadSize2=${threadSize2}`;
  }
  url = `${url}${filter}&size=${size}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiGetMakeAtBoaCharges = (stockCode, spiral) => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.MAKE_AT_BOA_CHARGES +
    `?stockCode=${stockCode}&spiral=${spiral}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiSaveHose = hoseInfo => {
  const url = API_LINKS.HOSE_MAKER + "/" + API_LINKS.SAVE_HOSE;
  return axios.post(url, hoseInfo).then(data => {
    return data;
  });
};

export const apiHoseAutoComplete = searchTerm => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.HOSE_AUTO_COMPLETE +
    `?searchTerm=${searchTerm}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiHoseSearchByAutoCompleteValue = (searchTerm, activePage) => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.HOSE_SEARCH_BY_AUTO_COMPLETE_VALUE +
    `?searchTerm=${searchTerm}&activePage=${activePage}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiGetCrimpSize = (
  dashSize,
  productFamily,
  tertiaryGroup,
  ferruleProductFamily
) => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.GET_CRIMP_SIZE +
    `?dashSize=${dashSize}&productFamily=${productFamily}&tertiaryGroup=${tertiaryGroup}&ferruleProductFamily=${ferruleProductFamily}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiGetHoseData = hoseId => {
  const url =
    API_LINKS.HOSE_MECHANIC +
    "/" +
    API_LINKS.GET_HOSE_MECHANIC_HOSE_DATA +
    `?hoseId=${hoseId}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiGetFittingOrientationsByDashSize = (dashSize, stockCode) => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.GET_FITTING_ORIENTATIONS +
    `?dashSize=${dashSize}&stockCode=${stockCode}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiGetHoseFerrule = stockCode => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.GET_FERRULE +
    `?stockCode=${stockCode}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiFerruleSearchAutoComplete = value => {
  const url =
    API_LINKS.HOSE_MAKER +
    "/" +
    API_LINKS.FERRULE_AUTO_COMPLETE +
    `?searchTerm=${value}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiSaveHoseRecipe = data => {
  const url = "asset/saveHoseRecipe/";
  return axios.post(url, data).then(result => {
    return result;
  });
};
