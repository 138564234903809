// xeroService.js
import axios from "axios";
import queryString from "query-string";

const xeroAuthUrl = "https://login.xero.com/identity/connect/authorize";

export const getXeroAuthUrl = () => {
  const currentURL = window.location.href;
  const urlObject = new URL(currentURL);
  const baseURL = `${urlObject.protocol}//${urlObject.host}`;

  const xeroAuthUrlFromAPI = localStorage.getItem("xeroAuthUrl");
  const urlObj = new URL(xeroAuthUrlFromAPI);
  const params = new URLSearchParams(urlObj.search);

  // Get individual parameter values
  const responseType = params.get("response_type");
  const clientId = params.get("client_id");
  const redirectUri = params.get("redirect_uri");
  const scope = params.get("scope");
  const state = params.get("state");

  const queryParams = {
    client_id: clientId,
    redirect_uri: `${baseURL}/callback/`,
    response_type: responseType,
    scope: scope,
    state: state,
  };

  return `${xeroAuthUrl}?${queryString.stringify(queryParams)}`;
};

export const exchangeCodeForToken = async (code) => {
  const tokenUrl = "https://identity.xero.com/connect/token";

  const data = {
    code,
    client_id: clientId,
    client_secret: "mJtbiJansCXX5OY04dd2rO_hjg6v1aBiLTUGAWE1S_SqGFKD",
    redirect_uri: redirectUri,
    grant_type: "authorization_code",
  };

  try {
    const response = await axios.post(tokenUrl, queryString.stringify(data));
    return response.data;
  } catch (error) {
    console.error("Error exchanging code for token:", error);
    throw error;
  }
};
