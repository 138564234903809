import _ from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Errors } from "react-redux-form";
import * as inventoryReducer from "./inventory.reducer";
import Loader from "../../components/Loader";
import NumericInput from "react-numeric-input";
import LocationDropdown from "../../components/LocationDropdown";
import {
  Grid,
  Divider,
  Form,
  Button,
  Message,
  Dropdown
} from "semantic-ui-react";

const TRANSFER_FORM = "transferLocationForm";

class TransferLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      stockId: "",
      inventoryType: "",
      location: "",
      value: ""
    };
  }

  setFromLocation = (e, data) => {
    const { value } = data;
    this.props.actionInventorySetFormField(
      `${TRANSFER_FORM}.fromLocation`,
      value
    );
  };
  setToLocation = (e, data) => {
    const { value } = data;
    this.props.actionInventorySetFormField(
      `${TRANSFER_FORM}.toLocation`,
      value
    );
  };

  transferLocation = async () => {
    const { forms } = this.props;
    const { transferLocationForm } = forms;
    this.setState({ isLoading: true });
    await this.props.actionTransferLocation(transferLocationForm);
    this.setState({ isLoading: false });
  };

  quantityChangeHandler = (e, data) => {
    this.props.actionSetQuantity(`${TRANSFER_FORM}.quantity`, e);
  };

  render() {
    const { isLoading } = this.state;
    const { locations, forms, stock, isError, errorMessage } = this.props;
    const { fromLocation, toLocation } = forms.transferLocationForm;
    const { locationQuantity } = stock;

    const fromLocations = [];

    const locationList = [];
    if (locations && locations.length) {
      _.map(locations, (location, l_key) => {
        const { name } = location;
        if (fromLocation !== name) {
          locationList.push({
            key: l_key,
            text: name,
            value: name
          });
        }
      });
    }

    Object.keys(locationQuantity).forEach(function(key) {
      if (locationQuantity[key] != null && locationQuantity[key] > -1) {
        fromLocations.push({ name: key, quantity: locationQuantity[key] });
      }
    });

    const from = (
      <Form.Field required>
        <label>From</label>
        <LocationDropdown
          setLocation={this.setFromLocation}
          locationId={fromLocation}
          locations={fromLocations}
        />
      </Form.Field>
    );

    const to = (
      <Form.Field required>
        <label>To</label>

        <Dropdown
          placeholder="Select Location"
          fluid
          value={toLocation}
          selection
          noResultsMessage="No locations found"
          onChange={this.setToLocation}
          options={locationList}
        />
        {_.isEmpty(locationList) ? (
          <span style={{ color: "red" }}>
            No location available, Create a new one!
          </span>
        ) : null}
      </Form.Field>
    );

    const quantity = (
      <Grid columns="equal">
        <Grid.Column width={16}>
          <Form.Field required>
            <label>Quantity</label>

            <NumericInput
              min={1}
              max={10000}
              maxLength={5}
              className="form-control"
              validateOn="change"
              placeholder="Quantity"
              required
              onChange={this.quantityChangeHandler}
              //value={quantity}
            />
            <Errors
              style={{ color: "red" }}
              model="forms.sellStockForm.quantity"
              show="touched"
              messages={{
                valueMissing: "quantity is required",
                rangeUnderflow: "Please enter valid quantity"
              }}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
    );

    return (
      <div>
        {isLoading && (
          <Loader title="Loading, please wait">Loading,please wait</Loader>
        )}
        <Form
          error={isError}
          model="form.transferLocationForm"
          onSubmit={this.props.transferLocation}
          style={{ width: "100%" }}
        >
          <Grid columns="equal">
            <Grid.Column width={8} computer={8} mobile={16}>
              {from}
            </Grid.Column>

            <Grid.Column width={8} computer={8} mobile={16}>
              {to}
            </Grid.Column>

            <Grid.Column width={8} computer={8} mobile={16}>
              {quantity}
            </Grid.Column>

            <Grid.Column width={16} computer={16} mobile={16}>
              <Divider />
            </Grid.Column>
          </Grid>
          <Message error={true} content={errorMessage} />
          <Grid.Column
            width={16}
            computer={16}
            mobile={16}
            style={{ textAlign: "right" }}
          >
            <Button
              size="small"
              onClick={this.props.closeForm}
              content=" Close"
            />
            {!isLoading && (
              <Button secondary size="small" content="Submit" type="submit" />
            )}
            {isLoading && (
              <Button secondary size="small" content="Submit" loading />
            )}
          </Grid.Column>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allInventory: state.inventory.allInventory,
    locations: state.inventoryLocation.locations,
    stock: state.inventory.stock,
    response: state.inventory.response,
    forms: state.forms
  };
};

const reducer = { ...inventoryReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TransferLocation)
);
