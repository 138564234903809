import axios from "axios";

export const apiSendFeedback = async (formData) => {
  const content = formData.feedback;
  return axios
    .post(`/api/Upload/upload-feedback`, { userFeedback: content })
    .then((result) => {
      if (result.status === 204) {
        return true;
      }
      return false;
    });
};
