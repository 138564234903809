import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Search } from "semantic-ui-react";
import ResultConversationRenderer from "./ResultConversationRenderer";
import * as chatReducer from "../chat.reducer";
import _ from "lodash";
import Loader from "../Loader";
import PropTypes from "prop-types";

class SearchConversation extends React.Component {

  constructor() {
    super();
    this.state = {

      isLoading: false,
      value: ""
    };
  }

  callSearchApi = async (value,) => {
    // if (pushToHistory) {
    //   this.addParamsToUrl({ value, filter, activePage });
    // }
    //const { threadType1, threadSize1, threadType2, threadSize2 } = this.props;

    this.setState({ isLoading: true });

    const result = await this.props.actionSearchConversation(
      value,

    );
    // const { data } = result;
    // const { firstName, userRole } = data;

    // this.computeOpenFilterItems(secondaryGrpDisplayName, active);
    this.setState({
      isLoading: false
    });
  };


  handleResultSelect = (e, { result }) => {
    const value = result.friendlyName;
    //const { filter } = this.state;
    this.setState({ value });

    this.callSearchApi(value);
  };


  handleSearchChange = async (e, { value }) => {
    e.preventDefault();
    this.setState({ value, searchMessage: null });
    //const { filter } = this.state;
    await this.props.actionConversationAutoComplete(value);
    if (this.state.value === "") {
      this.props.actionClearConversationSearch();
    }
  };

  render() {
    const { isLoading, value } = this.state;
    const { autoCompleteConversationResult } = this.props;



    return (<React.Fragment>
      <Search
        className="search-box"
        loading={isLoading}
        placeholder="Search conversation by name"
        resultRenderer={ResultConversationRenderer}
        onResultSelect={this.handleResultSelect}
        onSearchChange={_.debounce(this.handleSearchChange, 500, {
          leading: true
        })}
        noResultsMessage="No direct matches found."
        results={autoCompleteConversationResult}
        value={value}
        {...this.props}
      />
      {isLoading && <Loader />}
    </React.Fragment>)
  };
}


SearchConversation.propTypes = {
  autoCompleteConversationResult: PropTypes.array,
  actionSearchConversation: PropTypes.func,
  actionConversationAutoComplete: PropTypes.func,


};

const mapStateToProps = state => {
  return {
    autoCompleteConversationResult: state.chatGroups.autoCompleteConversationResult.data
  };
};
const mapDispatchToProps = dispatch =>
  bindActionCreators(chatReducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchConversation);
