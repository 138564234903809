import React from 'react';
import { Modal,Button,List,Checkbox} from 'semantic-ui-react'
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as chatReducer from "./chat.reducer";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import SearchUser from './SearchUser/SearchUser';

const polyglot = window.polyglot;


class NewChatModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            something: '',
            showModal: false,
            channelIcon: null,
            file: null,
            chatTitle: '',
            identity: 'nnn@mailinator.com',
            errorFlag: false,
            errorMessage: "",
            accessToken: '',
            isLoading:false
        }
    }
    closeModal = () => {
        this.setState({ showModal: false })
        this.props.actionResetForm("chatForm");
        this.props.actionClearSearch();

    }
    openModal = () => {
        this.setState({ showModal: true })
        this.handleSingleChat();
    }

    onChangeImage = (event) => {
        this.setState({
            file: URL.createObjectURL(event.target.files[0])
        });
    }



    resetFile = (event) => {
        event.preventDefault();
        this.setState({ file: null });
    }

    handleCreateGroup = async () => {

        this.setState({isLoading:true})
        const { chatForm } = this.props.forms;
       

        await this.props.actionCreateChatGroup(chatForm);
        const { response } = this.props;
        //console.log("Chat group creation status :" + JSON.stringify(response))
        // if (response) {
        //   if (response.status === 424) {
        //     this.updateState({
        //       errorFlag: true,
        //       errorMessage: "Something went wrong, please try it again"
        //     });
        //   }

        //   if (response.status === 403) {
        //     this.updateState({
        //       errorFlag: true,
        //       errorMessage: "Forbidden"
        //     });
        //   }

        this.setState({isLoading:true})
        if (response.status === 200) {

            showSuccessToast("New Conversation created successfully!!")
        }
        else {
            showErrorToast("Something went wrong")
        }
        // }
        this.props.actionGetConversationsList();
        this.props.actionResetForm("chatForm")
        this.closeModal();

    };
    handleSingleChat = () => {


        this.setState({ isNewGroup: true, userErrorFlag: false })

        const formValue = {
            ...this.props.chatForm,

            chatType: 1
        };


        this.props.actionSetFormValue(formValue);


    };

    checkedUsers = (value,name) => {


        let { participantsList } = this.props.chatForm;
        let newParticipantsList = [...participantsList];
        let isAvailable = _.includes(participantsList, value);
        if (isAvailable) {
            _.remove(newParticipantsList, n => value === n);
        } else {
            newParticipantsList.push(value);
        }
        this.props.actionSetFormField("participantsList", newParticipantsList);
        //this.props.actionSetFormField("chatTitle", name);

    };


    render() {
        const {

            showModal,
            isLoading

        } = this.state

        const { chatUserList, chatForm,searchResult} = this.props;
        const { participantsList } = chatForm;

        let userList = [];
        if (searchResult && searchResult.length) {
            _.map(searchResult, user => {
                const { firstName,lastName,mobileNumber, email, _id } = user;
                userList.push({ name: firstName, value: email, id: _id,lastName:lastName,mobileNumber:mobileNumber});
            });
        }
        else {
            _.map(chatUserList, user => {
                const { firstName, email, _id ,lastName,mobileNumber,} = user;
                userList.push({ name: firstName, value: email, id: _id,lastName:lastName,mobileNumber:mobileNumber });
            });
        }
        // if (chatUserList && chatUserList.length) {
        //     _.map(chatUserList, user => {
        //         const { firstName, email, _id } = user;
        //         userList.push({ name: firstName, value: email, id: _id });
        //     });
        // }
        const filterdUsers = [...userList];
        let selectedUsers = filterdUsers.filter((filterdUsers) => participantsList.includes(filterdUsers.value))

        return (
            <div>
    
                <Button color="green" onClick={this.openModal}> <i className="boa ba-add_user"></i>New Chat</Button>
                <Modal className="custom_modal" closeIcon size='tiny' onClose={this.closeModal} open={showModal} >
                    <Modal.Header>New Chat</Modal.Header>
                    <Modal.Content >
                        <Modal.Description>
                            <div className="chat_search_wrapper">
                                <div className="chat_innersearch" >
                                    <SearchUser />
                                    {/* <Search /> */}
                                    <div className="filter_warp">
                                        <a href="#" className="filter_btn" onClick={this.openModal} > <i className="boa ba-filter"></i></a>
                                        <span className="filter_on"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="filter_options" style={{maxHeight:"100px",overflowY:"scroll"}}>
                                
                                
                                {selectedUsers &&_.map(selectedUsers, user => {
                                   return <List key={user.id}><List.Item  className="chips_item">
                                        <div className="chip_uesr_info">
                                            <div className="chip_user_img">PV</div>
                                            <div className="chip_user_name">{user.name}&nbsp;{user.lastName}</div>
                                        </div>
                                        <div className="chip_action">
                                            <a href="#" className="close_btn"> <i className="boa ba-close_circle" onClick={() => this.checkedUsers(user.value,user.name)}></i></a>
                                        </div>
                                    </List.Item> </List>
                                      })}
                               
                               
                            </div>

                            <div className="addmembermodal_list">
                                <List>
                                    {_.map(userList, users => {
                                        const { name, value, id ,lastName,mobileNumber} = users;
                                        return <List.Item key={id}>
                                            <div className="userlist_wrap">
                                                <div className="contact_img">
                                                    <div className="contact_img_txt">
                                                        HS
                                                {/* <i className="boa ba-user_group"></i> */}
                                                    </div>
                                                </div>
                                                <div className="meta">
                                                    <div className="name">
                                                        <div className="username_info">
                                                            <div className="usertext"> {name}&nbsp;&nbsp;{lastName}</div>
                                                            <div className="usersubtext">{mobileNumber}</div>
                                                        </div>
                                                        <div className="custom_checkbox">
                                                            <Checkbox
                                                                onChange={() => this.checkedUsers(value,name)}
                                                                value={value}
                                                                checked={_.includes(participantsList, value)}

                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </List.Item>
                                    })}
                                </List>

                            </div>



                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions className="modal_footer">

                        <Button loading={isLoading} color='green' onClick={this.handleCreateGroup}>Done </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}


const reducer = { ...chatReducer };


const mapStateToProps = state => {
    return {
        chatGroups: state.chatGroups,
        response: state.chatGroups.response,
        forms: state.forms,
        chatUserList: state.chatGroups.chatuserList.data,
        chatForm: state.forms.chatForm,
        searchResult:state.chatGroups.searchResult.data


    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(reducer, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(NewChatModal)
);