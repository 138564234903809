import React from "react";
import PropTypes from "prop-types";
import { Control, Errors } from "react-redux-form";
import {
  Grid,
  Form,
  Button,
  Dropdown,
  Segment,
  List,
  Radio
} from "semantic-ui-react";

import _ from "lodash";
const Member = ({
  setUser,
  manageMember,
  users,
  memberForm,
  crewForm,
  handleChange
}) => {
  const { isPortalUser, value } = memberForm;
  const { members } = crewForm;

  let usersList = [];
  if (users.length) {
    _.map(users, user => {
      const { firstName, lastName, _id } = user;
      usersList.push({
        text: `${firstName} ${lastName}`,
        value: _id
      });
    });
  }

  const MemberList = (
    <List divided verticalAlign="middle">
      {members &&
        _.map(members, (member, m_key) => {
          const { value: _id, isPortalUser, firstName , email} = member;
          let name = _id;
          let name1 = firstName;
          let email1 = email;
         
          if (isPortalUser) {
            const userInfo = _.find(users, { _id });
            name = userInfo.firstName;
            name1 = userInfo.lastName;
            email1 = userInfo.email;

          }
          return (
            <List.Item key={m_key}>
              <Grid>
                <Grid.Column  computer={4} mobile={16} tablet={4}>{name} {name1}</Grid.Column>
                <Grid.Column computer={7}> {email1}</Grid.Column>
                <Grid.Column computer={4}
                mobile={16}
                tablet={5}
                textAlign="right"
                className="no-padding">
                  <Button
                    basic
                    color="red"
                    icon="delete"
                    size="mini"
                    type="button"
                    onClick={() => manageMember("remove", m_key)}
                  />
                </Grid.Column>
              </Grid>
            </List.Item>
          );
        })}
    </List>
  );

  const PortalUser = (
    <Form.Field>
      <label>User</label>
      <Dropdown
        placeholder="Select Member"
        fluid
        value={value}
        selection
        required
        onChange={setUser}
        options={usersList}
      />
    </Form.Field>
  );

  const NonPortalUser = (
    <Form.Field>
      <label>User</label>
      <Control
        type="text"
        model="forms.memberForm.value"
        placeholder="Enter Member's Name"
        validateOn="change"
        className="input-box"
      />
      <Errors
        style={{ color: "red" }}
        model="forms.memberForm.value"
        show="touched"
        messages={{
          valueMissing: "Name is required"
        }}
      />
    </Form.Field>
  );

  const UserType = (
    <div>
      <label>
        <b>Select User Type:</b>
      </label>

      <Form.Group inline style={{ padding: "10px", marginBottom: "0px" }}>
        <Form.Field>
          <Radio
            label="Portal User"
            name="Portal User"
            value={true}
            checked={isPortalUser === true}
            onChange={handleChange}
          />
        </Form.Field>
        <Form.Field>
          <Radio
            label="Non-portal User"
            name="Non-portal User"
            value={false}
            checked={isPortalUser === false}
            onChange={handleChange}
          />
        </Form.Field>
      </Form.Group>
    </div>
  );

  return (
    <Segment>
      <h4 className="header"> Member</h4>
      <Grid className="form-panel">
        <Grid.Column computer={7} tablet={10} mobile={16}>{UserType}</Grid.Column>
        <Grid.Column computer={5} tablet={6} mobile={16}>
          {isPortalUser ? (
            <span>{PortalUser}</span>
          ) : (
            <span>{NonPortalUser}</span>
          )}
        </Grid.Column>

        <Grid.Column computer={4} tablet={4} mobile={16} className="add-member">
          <Button
            secondary
            content="Add"
            size="small"
            className="button-float"
            type="button"
            onClick={() => manageMember("add")}
          />
        </Grid.Column>
        <Grid.Column width={16} className="member-list">
          {MemberList}
        </Grid.Column>
      </Grid>
    </Segment>
  );
};
Member.propTypes = {
  setUser: PropTypes.func,
  manageMember: PropTypes.func,
  users: PropTypes.array,
  memberForm: PropTypes.object,
  crewForm: PropTypes.object,
  handleChange: PropTypes.func
};

export default Member;
