import React from "react";
import { Message, Grid, Button, Icon, Divider } from "semantic-ui-react";

const SmallerScreenDisplayMessage = () => {
  return (
    <div>
      <h3>
        <Icon name="wrench" /> Hose maker
      </h3>
      <Divider />
      <Message>
        <Grid>
          <Grid.Column width={8}>
            Using BOAhub on you mobile or tablet device? Download the app for a
            better experience!
          </Grid.Column>
          <Grid.Column width={8}>
            <Button color="black">
              <Icon name="apple" /> App Store
            </Button>
            <Button color="black">
              <Icon name="android" /> Play Store
            </Button>
          </Grid.Column>
          <Grid.Column width={16}>
            <Divider />
            Not using a mobile or tablet device? Try using full screen to access
            the hose maker!
          </Grid.Column>
        </Grid>
      </Message>
    </div>
  );
};

export default SmallerScreenDisplayMessage;
