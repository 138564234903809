import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { logout } from "../Auth/auth.reducer";
import UserApp from "./UserApp";
import { BrowserRouter } from "react-router-dom";
import CacheBuster from '../../CacheBuster';

const App = ({ auth, logout }) => {
  return (
    <BrowserRouter>
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
       // if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return (

        
            <UserApp {...{ auth, logout }} />
       
        );
      }}
    </CacheBuster>
    </BrowserRouter>
  );
};

const mapStateToProps = state => ({
  app: state.app,
  auth: state.auth
});

const mapDispatchToProps = dispatch => bindActionCreators({ logout }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(App);
