import React from "react";
import { Breadcrumb } from "semantic-ui-react";

const FamilyBreadCrumb = (props) => {
  const { secondaryGrpDisplayName, tertiaryGrpDisplayName} = props;
 
    return (
      <Breadcrumb>
        <Breadcrumb.Section>{secondaryGrpDisplayName}</Breadcrumb.Section>
        <Breadcrumb.Section>{tertiaryGrpDisplayName}</Breadcrumb.Section>
      </Breadcrumb>
    );
};
  
export default FamilyBreadCrumb;