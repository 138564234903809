import _ from "lodash";
import { apiGetCartItems, apiPatchCart, apiUpdateCart } from "./cart.api";
import ReactGA from "react-ga";
import { compose } from "redux";

const UPDATE_CART = "UPDATE_CART";
const UPDATE_ERROR = "UPDATE_ERROR";

const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ERROR:
      return {
        ...action.payload,
        isError: true,
        error: action.error,
      };
    case UPDATE_CART:
      return {
        ...action.payload,
        isError: false,
      };

    default:
      return state;
  }
};

export const actionUpdateCart = (sortedCart) => {
  return async (dispatch) => {
    try {
      await apiUpdateCart(sortedCart);
      dispatch({ type: UPDATE_CART, payload: sortedCart });
    } catch (e) {
      dispatch({ type: UPDATE_CART, payload: e });
    }
  };
};

export const actionPatchCart = (sortedCart, action) => {
  return async (dispatch) => {
    try {
      const result = await apiPatchCart(sortedCart, action);
      if (result.data) {
        // await apiUpdateCart(sortedCart);
        // dispatch({ type: UPDATE_CART, payload: sortedCart });
      } else {
        dispatch({
          type: UPDATE_ERROR,
          payload: sortedCart,
          error: result.message,
        });
      }
    } catch (e) {
      dispatch({ type: UPDATE_CART, payload: e });
    }
  };
};

// export const actionLoadCartObj = () => {
//   return async (dispatch) => {
//     try {
//       const result = await apiGetCartItems();
//       const { data } = result;
//       dispatch({ type: UPDATE_CART, payload: data });
//     } catch (e) {
//       console.error("Failed to load cart", e);
//     }
//   };
// };
