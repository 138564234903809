import { toast } from "react-toastify";

export const showSuccessToast = (message, autoClose = 4000) => {
  toast.dismiss();
  toast.success(message, {
    autoClose,
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    pauseOnHover: true,
    className: "toast-success",
  });
};

export const showErrorToast = (message) => {
  toast.error(message, {
    autoClose: 4500,
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    pauseOnHover: true,
    className: "toast-error",
  });
};

export const showWarningToast = (message) => {
  toast.warn(message, {
    autoClose: 5000,
    position: toast.POSITION.TOP_CENTER,
    hideProgressBar: true,
    pauseOnHover: true,
    className: "toast-warning",
    style: { backgroundColor: "#FFAD00", color: "black" },
  });
};
