import {
  apiGetSingleAsset,
  apiGetSchedulesByAsset,
  apiUpdateAsset,
  apiDeleteAsset,
  apiDeleteSchedule,
  apiAddSchedule,
  apiGetAllHoses,
  apiAttachHoses,
  apiGetAttachHoses,
  apiUpdateAttachHose,
  apiGetPositionList,
  apiSaveNewPosition,
  apiGetHoseSuggestion
} from "./assetDetail.api";
import { apiGetForms } from "../MaintenanceForm/maintenanceForm.api";
import { apiGetUsersByAccount } from "../CrewManagement/crew.api";
import { actions } from "react-redux-form";

const ADD_SCHEDULE = "ADD_SCHEDULE";
const GET_SINGLE_ASSET = "GET_SINGLE_ASSET";
const UPDATE_ASSET = "UPDATE_ASSET";
const DELETE_ASSET = "DELETE_ASSET";
const SET_SCHEDULE_BY_ASSET = "SET_SCHEDULE_BY_ASSET";
const DELETE_SCHEDULE = "DELETE_SCHEDULE";
const SET_FORMS = "SET_FORMS";
const SET_USERS = "SET_USERS";
const GET_HOSES = "GET_HOSES";
const ATTACH_HOSE = "ATTACH_HOSE";
const GET_ATTACHED_HOSES = "GET_ATTACHED_HOSES";
const UPDATE_ATTACHED_HOSE = "UPDATE_ATTACHED_HOSE";
const CLEAR_REDUCER = "CLEAR_REDUCER";
const POSITION_LIST = "POSITION_LIST";
const HOSE_SUGGESTION_LIST = "HOSE_SUGGESTION_LIST";

const initialState = {
  asset: {},
  schedules: [],
  response: {},
  allForms: [],
  users: [],
  inventoryHoses: [],
  attachedHoses: [],
  positionList: [],
  hoseSuggestions: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_SINGLE_ASSET:
      return {
        ...state,
        asset: action.payload
      };
    case SET_SCHEDULE_BY_ASSET:
      return {
        ...state,
        schedules: action.payload
      };

    case UPDATE_ASSET:
      return {
        ...state,
        response: action.payload
      };

    case DELETE_ASSET:
      return {
        ...state,
        response: action.payload
      };

    case DELETE_SCHEDULE:
      return {
        ...state,
        response: action.payload
      };

    case ADD_SCHEDULE:
      return {
        ...state,
        response: action.payload
      };

    case SET_FORMS:
      return {
        ...state,
        allForms: action.payload
      };

    case SET_USERS:
      return {
        ...state,
        users: action.payload
      };

    case GET_HOSES:
      return {
        ...state,
        inventoryHoses: action.payload
      };
    case ATTACH_HOSE:
      return {
        ...state,
        response: action.payload
      };
    case GET_ATTACHED_HOSES:
      return {
        ...state,
        attachedHoses: action.payload
      };
    case UPDATE_ATTACHED_HOSE:
      return {
        ...state,
        response: action.payload
      };
    case CLEAR_REDUCER: {
      return {
        ...state,
        response: {},
        allForms: [],
        users: [],
        inventoryHoses: [],
        attachedHoses: []
      };
    }

    case POSITION_LIST: {
      return {
        ...state,
        positionList: action.payload
      };
    }
    case HOSE_SUGGESTION_LIST: {
      return {
        ...state,
        hoseSuggestions: action.payload
      };
    }
    default:
      return { ...state };
  }
};

export const actionResetForm = name => {
  return dispatch => {
    dispatch(actions.reset("forms." + name));
  };
};

export const actionSetAssetFormInfo = form => {
  return dispatch => {
    dispatch(actions.change("forms.assetForm", form));
  };
};

// export const actionSetFormField = (name, field) => {
//   return dispatch => {
//     dispatch(actions.change("forms.assetForm." + name, field));
//   };
// };

export const actionSetFormField = (name, field) => {
  return dispatch => {
    dispatch(actions.change("forms.scheduleForm." + name, field));
  };
};

export const actionOmitFormField = field => {
  return dispatch => {
    dispatch(actions.omit("forms.scheduleForm", field));
  };
};

export const actionSetAttachToAssetField = (name, field) => {
  return dispatch => {
    dispatch(actions.change("forms.attachToAssetForm." + name, field));
  };
};

export const actionGetSingleAsset = id => {
  return async dispatch => {
    try {
      const result = await apiGetSingleAsset(id);
      dispatch({ type: GET_SINGLE_ASSET, payload: result.data });
    } catch (e) {
      dispatch({ type: GET_SINGLE_ASSET, payload: e });
    }
  };
};

export const actionGetSchedulesByAsset = assetId => {
  return async dispatch => {
    try {
      const result = await apiGetSchedulesByAsset(assetId);
      dispatch({ type: SET_SCHEDULE_BY_ASSET, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_SCHEDULE_BY_ASSET, payload: e });
    }
  };
};

export const actionUpdateAsset = asset => {
  return async dispatch => {
    try {
      const result = await apiUpdateAsset(asset);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: UPDATE_ASSET, payload: result });
      } else {
        dispatch({ type: UPDATE_ASSET, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: UPDATE_ASSET, payload: e.response });
    }
  };
};

export const actionDeleteAsset = assetId => {
  return async dispatch => {
    try {
      const result = await apiDeleteAsset(assetId);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: DELETE_ASSET, payload: result });
      } else {
        dispatch({ type: DELETE_ASSET, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: DELETE_ASSET, payload: e });
    }
  };
};

export const actionDeleteSchedule = scheduleName => {
  return async dispatch => {
    try {
      const result = await apiDeleteSchedule(scheduleName);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: DELETE_SCHEDULE, payload: result });
      } else {
        dispatch({ type: DELETE_SCHEDULE, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: DELETE_SCHEDULE, payload: e });
    }
  };
};

export const actionAddSchedule = data => {
  return async dispatch => {
    try {
      const result = await apiAddSchedule(data);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: ADD_SCHEDULE, payload: result });
      } else {
        dispatch({ type: ADD_SCHEDULE, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: ADD_SCHEDULE, payload: e.response });
    }
  };
};

export const actionGetFormsForDropdown = () => {
  return async dispatch => {
    try {
      const result = await apiGetForms();
      dispatch({ type: SET_FORMS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_FORMS, payload: e });
    }
  };
};

export const actionGetUsersForDropdown = () => {
  return async dispatch => {
    try {
      const result = await apiGetUsersByAccount();
      dispatch({ type: SET_USERS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_USERS, payload: [] });
    }
  };
};

export const actionGetHoseInventory = () => {
  return async dispatch => {
    try {
      const result = await apiGetAllHoses();
      dispatch({ type: GET_HOSES, payload: result.data });
    } catch (e) {
      dispatch({ type: GET_HOSES, payload: [] });
    }
  };
};

export const actionAttachHose = data => {
  return async dispatch => {
    try {
      const result = await apiAttachHoses(data);
      dispatch({ type: ATTACH_HOSE, payload: result });
    } catch (e) {
      dispatch({ type: ATTACH_HOSE, payload: e });
    }
  };
};

export const actionGetAttachedHoses = id => {
  return async dispatch => {
    try {
      const result = await apiGetAttachHoses(id);

      let hoseList = [];
      if (result.data) {
        hoseList = result.data[0].attachedHose;
      }
      dispatch({
        type: GET_ATTACHED_HOSES,
        payload: hoseList
      });
    } catch (e) {
      dispatch({ type: GET_ATTACHED_HOSES, payload: e });
    }
  };
};
export const actionUpdateAttachHose = data => {
  return async dispatch => {
    try {
      const result = await apiUpdateAttachHose(data);
      dispatch({
        type: UPDATE_ATTACHED_HOSE,
        payload: result
      });
    } catch (e) {
      dispatch({ type: UPDATE_ATTACHED_HOSE, payload: e });
    }
  };
};

export const actionSetPositionField = (name, field) => {
  return dispatch => {
    dispatch(actions.change("forms." + name, field));
  };
};

export const actionSetQuantity = (name, field) => {
  return dispatch => {
    dispatch(actions.change("forms." + name, field));
  };
};

// export const actionOmitFormField = field => {
//   return dispatch => {
//     dispatch(actions.omit("forms.scheduleForm", field));
//     dispatch(actions.change("forms.assetForm." + name, field));
//   };
// };

export const actionCleatAssetDetailsReducer = () => {
  return dispatch => {
    dispatch({ type: CLEAR_REDUCER, payload: {} });
  };
};

export const actionGetPositionList = () => {
  return async dispatch => {
    try {
      const result = await apiGetPositionList();
      dispatch({
        type: POSITION_LIST,
        payload: result.data
      });
    } catch (e) {
      dispatch({ type: POSITION_LIST, payload: e });
    }
  };
};

export const actionSaveNewPosition = position => {
  return async dispatch => {
    try {
      const result = await apiSaveNewPosition(position);

      dispatch({
        type: POSITION_LIST,
        payload: result.data
      });
    } catch (e) {
      dispatch({ type: POSITION_LIST, payload: e });
    }
  };
};

export const actionGetHoseSuggestions = data => {
  return async dispatch => {
    try {
      const result = await apiGetHoseSuggestion(data);

      dispatch({
        type: HOSE_SUGGESTION_LIST,
        payload: result.data
      });
    } catch (e) {
      dispatch({ type: HOSE_SUGGESTION_LIST, payload: e });
    }
  };
};

export const actionClearHoseSuggestions = () => {
  return dispatch => {
    dispatch({
      type: HOSE_SUGGESTION_LIST,
      payload: []
    });
  };
};
