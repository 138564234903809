import axios from "axios";

export const apiAddDTHoseLocation = async (data) => {
  const companyId = Number(localStorage.getItem("companyId"));
  data.companyId = companyId;
  return axios
    .post(`/api/DigitalTwin/digital-twin-location`, data)
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiUpdateTHoseLocation = async (data) => {
  const assetId = Number(localStorage.getItem("selectedAssetId"));
  const companyId = Number(localStorage.getItem("companyId"));
  data.companyId = companyId;
  data.isDeleted = false;
  data.assetId = assetId;
  delete data.assetDetails;

  return axios
    .put(`/api/DigitalTwin/digital-twin-location`, data)
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiGetDTImages = (locationId, assetId) => {
  return axios
    .get(
      `/api/DigitalTwin/digital-twin-images?twinLocationId=${locationId}&assetId=${assetId}`
    )
    .then((result) => {
      if (result) {
        if (result.data) {
          if (result.data) {
            const sortByID = (a, b) => a.id - b.id;
            const sortedArray = result.data.sort(sortByID);
            return sortedArray;
          }
        }
      }
      return false;
    });
};

export const apiAddHoseLocationImage = async (data, assetId, locationId) => {
  const formData = new FormData();

  formData.append("file", data);
  return axios
    .post(
      `/api/DigitalTwin/upload-digital-twin-image?digitalTwinLocationId=${locationId}&assetId=${assetId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      console.log(error);
      if (error.response.data) {
        console.log(error.response.data);
        const data = error.response.data;
        showErrorToast(data?.message);
        if (data.statusCode === 400) return false;
        if (data.statusCode === 500) return false;
        return false;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiUpdateImage = async (imageId, imageName) => {
  const data = {
    digitalTwinImages: [{ imageId: imageId, imageName: imageName }],
  };
  return axios
    .put(`/api/DigitalTwin/digital-twin-image?imageId=${imageId}`, data)
    .then((result) => {
      if (result && result.data) {
        return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiHoseRecognition = (data, assetId, locationId) => {
  const sessionID = localStorage.getItem("sessionID");
  const companyId = Number(localStorage.getItem("companyId"));
  const formData = new FormData();
  formData.append("token", sessionID);
  formData.append("file", data);

  const axiosInstance = axios.create({
    baseURL: "https://ml.boahub.co",
    auth: {
      username: "boahub",
      password: "BOAhub@2024",
    },
  });

  return axiosInstance
    .post(
      `/api/recognition/hose_end?platform=web&asset_id=${assetId}&location_id=${locationId}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Basic ${btoa("boahub:BOAhub@2024")}`,
          XAPIKey: undefined,
        },
      }
    )
    .then((result) => {
      if (result) {
        if (result.data) {
          console.log(result.data);
          result.data?.data.map((item, i) => {
            item.sequenceId = i + 1;
            item.isRemoved = false;
          });
          return result.data?.data;
        }
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      console.log(error);
      if (error.response.data) {
        console.log(error.response.data);
        const data = error.response.data;
        showErrorToast(data?.message);
        if (data.statusCode === 400) return false;
        if (data.statusCode === 500) return false;
        return false;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiGetHoseEnds = (imageId, assetId) => {
  return axios
    .get(
      `/api/DigitalTwin/hose-ends-by-image-id?imageId=${imageId}&assetId=${assetId}`
    )
    .then((result) => {
      if (result) {
        if (result.data) {
          const hoseEnds = result.data;
          hoseEnds.map((item, i) => {
            item.sequenceId = i + 1;
            item.hoseProductId = item.hoseEndBomData?.hoseProductId;
            item.hoseSku = item.hoseEndBomData?.hoseSku;
            item.hoseName = item.hoseEndBomData?.hoseName;
            item.fittingProductId = item.hoseEndBomData?.fittingProductId;
            item.fittingName = item.hoseEndBomData?.fittingName;
            item.fittingSku = item.hoseEndBomData?.fittingSku;
            item.ferruleProductId = item.hoseEndBomData?.ferruleProductId;
            item.ferruleProductName = item.hoseEndBomData?.ferruleProductName;
            item.ferruleSku = item.hoseEndBomData?.ferruleSku;
            item.oal = item.hoseEndBomData?.oAL;
            item.pairedHoseEnd = item.pairedHoseEndData?.id;
            item.pairedHoseEndAssetId =
              item.pairedHoseEndData?.pairedHoseEndAssetId;
          });
          return result.data;
        }
      }
      return false;
    });
};

export const apiSaveHoseEnds = async (
  hoseEnds,
  assetId,
  locationId,
  imageId,
  imageName
) => {
  let hoseEndsData = hoseEnds.filter((item) => !item.isRemoved);
  hoseEndsData.map((item, i) => {
    item.sequenceId = i + 1;
    item.assetId = Number(assetId);
    item.twinLocationId = locationId;
    item.twinImageId = imageId;
    item.bboxLeftTopX = Number(item.xmin);
    item.bboxLeftTopY = Number(item.ymin);
    item.bboxWidth = Number(item.width);
    item.bboxHeight = Number(item.height);
  });
  const data = {
    digitalTwinHoseEnds: hoseEndsData,
    imageDet: { imageId: imageId, imageName: imageName },
  };
  return axios
    .post(`/api/DigitalTwin/digital-twin-hose-end`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiUpdateHoseEnds = async (
  hoseEnds,
  locationId,
  imageId,
  imageName
) => {
  let hoseEndsData = hoseEnds.filter((item) => !item.isRemoved);
  hoseEndsData.map((item) => {
    item.twinLocationId = locationId;
    item.twinImageId = imageId;
    item.bboxLeftTopX = Number(item.xmin);
    item.bboxLeftTopY = Number(item.ymin);
    item.bboxWidth = Number(item.width);
    item.bboxHeight = Number(item.height);
    item.pairedHoseEnd = item.pairedHoseEnd ? item.pairedHoseEnd : null;
    item.pairedHoseEndAssetId = item.pairedHoseEndAssetId
      ? item.pairedHoseEndAssetId
      : null;
    delete item.hoseEndBomData;
    delete item.pairedHoseEndData;
    item.otherItems?.map((bom, i) => {
      bom.id = bom.id ? bom.id : 0;
      bom.productName = bom.stockDescription;
      bom.productSKU = bom.stockCode;
      bom.hoseEndBomId = item.hoseEndBomData?.id ? item.hoseEndBomData?.id : 0;
      delete bom.stockDescription;
      delete bom.stockCode;
      delete bom.stockImage1;
      delete bom.barcode;
      delete bom.name;
    });
  });
  const data = {
    digitalTwinHoseEnds: hoseEndsData,
    imageDet: { imageId: imageId, imageName: imageName },
  };
  return axios
    .put(`/api/DigitalTwin/digital-twin-hose-end`, data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return result.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response) {
        console.log("Error:", error);
      }
    });
};

export const apiDeleteHoseEnd = async (hoseEndId) => {
  return axios
    .delete(`/api/DigitalTwin/digital-twin-hose-end?hoseEndId=${hoseEndId}`)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiDeleteHoseLocation = async (locationId) => {
  return axios
    .delete(
      `/api/DigitalTwin/digital-twin-location?twinLocationId=${locationId}`
    )
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiDeleteHoseLocationImage = async (imageId) => {
  return axios
    .delete(`/api/DigitalTwin/digital-twin-image?imageId=${imageId}`)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};
