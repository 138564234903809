import {
  apiGetLocations,
  apiAddLocation,
  apiDeleteLocation,
  apiUpdateLocation
} from "./assetLocation.api";
import { actions } from "react-redux-form";

const SET_LOCATIONS = "SET_LOCATIONS";
const ADD_LOCATIONS = "ADD_LOCATIONS";
const DELETE_LOCATIONS = "DELETE_LOCATIONS";
const UPDATE_LOCATION = "UPDATE_LOCATION"

const initialState = {
  isLoading: false,
  allLocations: [],
  response: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_LOCATIONS:
      return {
        ...state,
        allLocations: action.payload
      };
    case ADD_LOCATIONS:
      return {
        ...state,
        response: action.payload
      };
    case DELETE_LOCATIONS:
      return {
        ...state,
        response: action.payload
      };
    case UPDATE_LOCATION:
    return {
      ...state,
      response: action.payload
    };  

    default:
      return state;
  }
};

export const actionGetAllLocation = () => {
  return async dispatch => {
    try {
      const result = await apiGetLocations();
      dispatch({ type: SET_LOCATIONS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_LOCATIONS, payload: e });
    }
  };
};

export const actionAddLocation = data => {
  return async dispatch => {
    try {
      const result = await apiAddLocation(data);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: ADD_LOCATIONS, payload: result });
      } else {
        dispatch({ type: ADD_LOCATIONS, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: ADD_LOCATIONS, payload: e.response });
    }
  };
};

export const actionDeleteLocation = name => {
  return async dispatch => {
    try {
      const result = await apiDeleteLocation(name);
      const {status} = result;
      if(status === 200) {
        dispatch({ type: DELETE_LOCATIONS, payload: result });
      }
      else {      
      dispatch({ type: DELETE_LOCATIONS, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: DELETE_LOCATIONS, payload: e.response });
    }
  };
};

export const actionUpdateLocation = (id,data) => {
  return async dispatch => {
    try {
      const result = await apiUpdateLocation(id,data);
      const {status} = result;
      if(status === 200) {
        dispatch({ type: UPDATE_LOCATION, payload: result });
      }
      else {
        dispatch({ type: UPDATE_LOCATION, payload: result.response });
      }
    }catch (e) {
      dispatch({ type: UPDATE_LOCATION, payload: e.response });
    }
  }
};

export const actionResetLocationForm = () => {
  return dispatch => {
    dispatch(actions.reset("forms.locationForm"));
  };
};

export const editAssetLocation = (field, value) => {
  return dispatch => {
    dispatch(actions.change("forms.stockForm." + field, value));
  };
};