import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { withPreviews, clearPreviews } from './withPreview';
//import styles from './assets/Conversation.module.css'
import { Button, Icon, Input, Image, Popup } from "semantic-ui-react";
import ConversationsMessages from "./ConversationsMessages";
import PropTypes from "prop-types";
import WebCamera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import '../../containers/Chat/chat.css'
import { Modal } from 'semantic-ui-react';
import ReactPlayer from "react-player";
import mime from "mime-types";
import FileViewer from 'react-file-viewer';
import { CustomErrorComponent } from 'custom-error';
//import VoiceMessage from './VoiceMessage';

class Conversation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newMessage: '',
            conversationProxy: props.conversationProxy,
            messages: [],
            loadingState: 'initializing',
            boundConversations: new Set(),
            files: [],
            videoFiles: [],
            audioFiles: [],
            voiceMessages: false,
            docFiles: [],
            showCamera: false,
            showImage: false,
            showVideo: false,
            mediaFile: false,
            imageFile: [],
            base64URL: '',
            allowEnter: true,
            isLoading: false,
            openRecorder: false,
            mediaRecorderState: "inactive",

        };
        this.stream = null;
        this.mediaRecorder = null;
        this.audioBlob = [];
        this.audioUrl = null;
        this.fileInput = React.createRef();
    }

    //==========================Voice Messages===========================
    openVoiceMessageModal = () => {
        this.setState({ openRecorder: true })
    }
    closeVoiceMessageModal = () => {
        this.setState({ openRecorder: false })
        this.audioBlob = [];
        this.audioUrl = null;

    }
    createStream() {
        return navigator.mediaDevices.getUserMedia({ audio: true });
    }
    componentWillUnmount = () => {
        this.mediaRecorder && this.mediaRecorder.removeEventListener("start", (event) => {
            this.audioUrl = "";
            console.log(event);
            audioChunks = [];
            this.setState({ mediaRecorderState: "recording" });
        });
    }
    async createRecorder() {
        if (!this.stream) this.stream = await this.createStream();
        if (!this.mediaRecorder) {
            this.mediaRecorder = new MediaRecorder(this.stream);
            let audioChunks = [];
            this.mediaRecorder.addEventListener("start", (event) => {
                this.audioUrl = "";
                console.log(event);
                audioChunks = [];
                this.setState({ mediaRecorderState: "recording" });
            });
            this.mediaRecorder.ondataavailable = (event) => {
                console.log(event.timecode);
                audioChunks.push(event.data);
                if (this.state.mediaRecorderState !== "recording")
                    this.setState({ mediaRecorderState: "recording" });
            };
            this.mediaRecorder.onstop = (event) => {
                this.audioBlob = new Blob(audioChunks);
                this.audioUrl = URL.createObjectURL(this.audioBlob);
                this.setState({ mediaRecorderState: "inactive" });
            };
            this.mediaRecorder.onpause = (event) => {
                this.setState({ mediaRecorderState: "paused" });
            };
            this.mediaRecorder.onresume = (event) => {
                this.setState({ mediaRecorderState: "recording" });
            };
        }
        return this.mediaRecorder;
    }
    get isRecording() {
        return this.mediaRecorder && this.mediaRecorder.state === "recording";
    }
    async toggleRecord() {
        await this.createRecorder();
        if (this.mediaRecorder.state === "inactive") {
            this.mediaRecorder.start(1000);
        } else if (this.mediaRecorder.state === "paused") {
            this.mediaRecorder.resume();
        } else if (this.mediaRecorder.state === "recording") {
            this.mediaRecorder.pause();
        }
    }
    async stop() {
        if (!this.mediaRecorder || this.mediaRecorder.state === "inactive") return;
        this.mediaRecorder.stop();
    }

    //====================Camera================================
    showImageModal = () => {
        this.setState({ showImage: true })
    }
    closeImageModal = () => {
        this.setState({ showImage: false, files: [], base64URL: '' })
    }
    closeVideoModal = () => {
        this.setState({ showVideo: false, files: [], audioFiles: [], videoFiles: [] })
    }

    handleVoiceMessage = () => {
        alert("Clicked ")
        this.setState({ voiceMessages: true })
    }

    onChangeImage = (event) => {
        this.setState({
            file: URL.createObjectURL(event.target.files[0])
        });
    }

    handleCameraClose = () => {
        this.setState({ imageFile: "" });
        this.setState({ showCamera: false });
    }
    handleTakePhoto = dataUri => {
        this.setState({ imageFile: dataUri });
        this.setState({ showCamera: false });

    }

    toggleCamera = () => {
        this.setState({ showCamera: !this.state.showCamera })

    }


    //====================Camera================================

    loadMessagesFor = (thisConversation) => {
        if (this.state.conversationProxy === thisConversation) {
            thisConversation.getMessages()
                .then(messagePaginator => {
                    if (this.state.conversationProxy === thisConversation) {
                        this.setState({ messages: messagePaginator.items, loadingState: 'ready' });
                    }
                })
                .catch(err => {
                    console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
                    this.setState({ loadingState: "failed" });
                });
        }
    };

    componentDidMount = () => {

        if (this.state.conversationProxy) {
            this.loadMessagesFor(this.state.conversationProxy);

            if (!this.state.boundConversations.has(this.state.conversationProxy)) {
                let newConversation = this.state.conversationProxy;
                newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
                this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
            }
        }
    }

    componentDidUpdate = (oldProps, oldState) => {
        if (this.state.conversationProxy !== oldState.conversationProxy) {
            this.loadMessagesFor(this.state.conversationProxy);

            if (!this.state.boundConversations.has(this.state.conversationProxy)) {
                let newConversation = this.state.conversationProxy;
                newConversation.on('messageAdded', m => this.messageAdded(m, newConversation));
                this.setState({ boundConversations: new Set([...this.state.boundConversations, newConversation]) });
            }
        }
    };

    static getDerivedStateFromProps(newProps, oldState) {
        let logic = (oldState.loadingState === 'initializing') || oldState.conversationProxy !== newProps.conversationProxy;
        if (logic) {
            return { loadingState: 'loading messages', conversationProxy: newProps.conversationProxy };
        } else {
            return null;
        }
    }

    messageAdded = (message, targetConversation) => {
        if (targetConversation === this.state.conversationProxy)
            this.setState((prevState, props) => ({
                messages: [...prevState.messages, message]
            }));
    };

    onMessageChanged = event => {
        this.setState({ newMessage: event.target.value });
    };

    handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.key === "Enter") {
            this.sendMessage(e);
        }


    };

    sendMessage = (event) => {
        event.preventDefault();

        const { audioFiles, videoFiles, base64URL, docFiles, voiceMessages } = this.state;
        // alert("Sending" + voiceMessages + JSON.stringify(audioURL) )
        this.setState({ isLoading: true })
        const message = this.state.newMessage;
        if (message.length>0) {

            this.setState({ newMessage: '' });
            this.state.conversationProxy.sendMessage(message);
            alert("text")

        }
       else if (audioFiles || videoFiles || base64URL || docFiles || this.audioUrl) {
            this.handleMediaMessage()
        }
       

        this.setState({ isLoading: false })
        this.closeVoiceMessageModal()
        // this.setState({ files: [], audioFiles: [], videoFiles: [], base64URL:'' })



    };
    sendWebCamMessage = () => {
        const { imageFile } = this.state;
        //event.preventDefault();
        // const message = this.state.newMessage;
        // this.setState({ newMessage: '' });
        //this.state.conversationProxy.sendMessage(message);

        this.state.conversationProxy.sendMessage(imageFile);
        this.closeImageModal();
    };

    onDrop = acceptedFiles => {
        alert(JSON.stringify(acceptedFiles))

        this.state.conversationProxy.sendMessage({ contentType: 'image/*', media: acceptedFiles[0] });
    };

    componentWillUnmount() {
        this.state.files && clearPreviews(this.state.files)
        //this.setState({files:[]})
    }

    //===================================Media messages================================


    getBase64 = (file) => {
        return new Promise((resolve) => {
            let fileInfo;
            let baseURL = "";
            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {
                // Make a fileInfo Object
                //console.log("Called", reader);
                baseURL = reader.result;
                //console.log(baseURL);
                resolve(baseURL);
            };
            // console.log(fileInfo);
        });
    };


    handleChangeMedia = (e) => {



        this.setState({ files: e.target.files[0] }, () => {
            const metadata = { contentType: mime.contentType(e.target.files[0].name) };
            alert("Mime : " + JSON.stringify(metadata + "Type :" + e.target.files[0].type))
            if (metadata && (e.target.files[0].type.slice(0, 2) === 'im')) {

                let { files } = this.state;


                files = e.target.files[0];

                this.getBase64(files)
                    .then((result) => {
                        files["base64"] = result;
                        console.log("File Is", files);
                        this.setState({
                            base64URL: result,
                            files
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                    });


                this.setState({ showImage: true, imageFile: URL.createObjectURL(e.target.files[0]) })
            } else if (metadata && metadata.contentType.slice(0, 2) === 'vi') {
                this.setState({ videoFiles: URL.createObjectURL(e.target.files[0]), showVideo: true })
            }
            else if (metadata && metadata.contentType.slice(0, 2) === 'au') {
                this.setState({ audioFiles: URL.createObjectURL(e.target.files[0]), showVideo: true })
            }
            else if (metadata && (metadata.contentType.slice(0, 2) === 'ap' || 'te')) {
                this.setState({ docFiles: URL.createObjectURL(e.target.files[0]), showImage: true })
            }



        })

        // this.setState({
        //     files: URL.createObjectURL(e.target.files[0])
        // }, () => {
        //     const metadata = { contentType: mime.lookup(e.target.files[0].name) };
        //     this.setState({ showImage: true })
        //     console.log("video : " + JSON.stringify(metadata ))
        // });
    }
    handleMediaMessage = (e) => {

        if (this.state.videoFiles.length > 0) {
            this.state.conversationProxy.sendMessage({ contentType: 'video/*', media: this.state.files });
        }
        else if (this.state.audioFiles.length > 0) {
            this.state.conversationProxy.sendMessage({ contentType: 'audio/*', media: this.state.files });
        }
        else if (this.state.imageFile.length > 0) {
            this.state.conversationProxy.sendMessage({ contentType: 'image/*', media: this.state.files });
            alert("Image")

        }
        else if (this.state.docFiles.length > 0) {
            this.state.conversationProxy.sendMessage({ contentType: 'application/*', media: this.state.files });
            alert(this.state.files.type)

        }
        else if (this.audioUrl !== null) {
            alert("Voice message" + this.audioUrl)
            this.state.conversationProxy.sendMessage({ contentType: 'audio/*', media: this.audioBlob });


        }

        this.closeVideoModal()
        this.closeImageModal();
        //alert(this.state.files)
    }


    render = () => {
        const { showCamera, imageFile, newMessage, messages, showImage, showVideo, videoFiles, audioFiles, isLoading, base64URL, docFiles, openRecorder } = this.state;

        return (
            <React.Fragment>


                <Dropzone

                    className="custom_dropzone"
                    onDrop={this.onDrop}
                    accept="image/*"
                >



                    {({ getRootProps, getInputProps, isDragActive }) => (
                        <div className="messages"
                            {...getRootProps()}
                            onClick={() => {
                            }}
                            id="OpenChannel"


                        >


                            <div

                            >
                                <input id="files" {...getInputProps()} />



                                <div className="messagebox_inner">
                                    <ConversationsMessages
                                        identity={this.props.myIdentity}
                                        messages={messages} />


                                </div>

                                <div>



                                    {showCamera && (
                                        // <CameraModal onClose={this.handleCameraClose} open={showCamera}>

                                        <Modal closeIcon open={showCamera} onClose={this.toggleCamera} >
                                            <div style={{ padding: "20px" }}>
                                                <WebCamera
                                                    // onTakePhoto={(e,dataUri)=>this.sendWebCamMessage(e,dataUri)}

                                                    onTakePhoto={(dataUri) => {
                                                        this.setState({
                                                            imageFile: dataUri,
                                                            showImage: true,
                                                            showCamera: false
                                                        })

                                                    }}
                                                />

                                            </div>


                                        </Modal>

                                    )}
                                </div>


                                <Modal open={showImage} size="tiny">


                                    <Modal.Header>{imageFile.length > 0 ? "Upload Image" : "Upload Document"} </Modal.Header>
                                    <Modal.Content >
                                        {base64URL && (

                                            <Image

                                                src={base64URL}
                                            />

                                        )}
                                        {/* Documents
                                        {docFiles && <FileViewer
                                            fileType="pdf"
                                            filePath={docFiles}
                                            errorComponent={CustomErrorComponent}
                                        // onError={this.onError}
                                        />} */}

                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button onClick={this.closeImageModal}>Cancel</Button>
                                        <Button onClick={this.sendMessage} positive>
                                            Send
                                        </Button>
                                    </Modal.Actions>
                                </Modal>

                                <Modal open={showVideo} size="tiny">


                                    <Modal.Header>{videoFiles.length > 0 ? "Upload Video" : "Upload Audio"}   </Modal.Header>
                                    <Modal.Content >
                                        {this.state.files && videoFiles ? (

                                            <ReactPlayer url={this.state.videoFiles} width="100%" height="100%" controls={true} />

                                        ) : (this.state.files && audioFiles ? <ReactPlayer url={this.state.audioFiles} width="100%" height="100%" controls={true} /> : null)}

                                    </Modal.Content>
                                    <Modal.Actions>
                                        <Button onClick={this.closeVideoModal}>Cancel</Button>
                                        <Button loading={isLoading} onClick={this.sendMessage} positive>
                                            Send
                                        </Button>
                                    </Modal.Actions>
                                </Modal>


                                <Modal open={openRecorder} size="tiny">


                                    <Modal.Header>Voice Recorder </Modal.Header>
                                    <Modal.Content >
                                        <Button onClick={(e) => this.toggleRecord()} color="green">
                                            {this.state.mediaRecorderState === "inactive"
                                                ? "Record"
                                                : this.state.mediaRecorderState === "paused"
                                                    ? "Resume"
                                                    : "Pause"}
                                        </Button>
                                        <Button onClick={(e) => this.stop()}>Stop</Button>

                                        <audio controls controlsList="delete, mute" src={this.audioUrl} />
                                    </Modal.Content>
                                    <Modal.Actions>

                                        <Button onClick={this.closeVoiceMessageModal}>
                                            Cancel
                                         </Button>
                                        <Button onClick={this.sendMessage} color="green">
                                            Send
                                        </Button>

                                    </Modal.Actions>

                                </Modal>

                                {/* 
                                <form 
                                // onSubmit={this.sendMessage}
                                > */}



                                {/* </form> */}

                            </div>
                        </div>
                    )}

                </Dropzone>
                <div className="message-input">

                    <div className="message_footer">


                        <Button icon className="blank_btn" onClick={this.handleVoiceMessage}>
                            <i className="boa ba-mic" onClick={this.openVoiceMessageModal}></i>
                        </Button>
                        <Input iconPosition='left' placeholder='Type a message..'
                            type="text"
                            name="message"
                            placeholder="Please type messages here"

                            //disabled={this.state.loadingState !== 'ready'}
                            onChange={this.onMessageChanged}
                            value={newMessage}
                            onKeyPress={this.handleKeypress}
                        >
                            {/* <i className="boa ba-mic"></i> */}
                            {/* <input /> */}
                        </Input>

                    </div>

                    <div className="input-group-append">
                        <Button icon className="blank_btn" onClick={this.toggleCamera}>
                            <i className="boa ba-camera"></i>
                        </Button>

                        <Popup
                            content={<div>
                                <input type="file" style={{ display: "none" }} id="fileInput" onChange={this.handleChangeMedia} accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                                    text/*, application/pdf," />
                                <label for="fileInput" >
                                    <p><Icon name="file" /> &nbsp; Document</p>

                                </label>
                                <input type="file" style={{ display: "none" }} id="fileInput" onChange={this.handleChangeMedia} accept="image/*,video/*,audeo/*" />
                                <label for="fileInput" >
                                    <p><Icon name="image" /> &nbsp; Gallery</p>

                                </label>



                            </div>}
                            on='click'
                            pinned
                            trigger={<Button className="blank_btn" >
                                <i className="boa ba-paperclip" ></i>
                            </Button>}
                        />





                        <Button icon className="send_btn" type="submit" onClick={this.sendMessage}
                        //disabled={!newMessage}
                        >
                            <i className="boa ba-send"></i>
                        </Button>

                    </div>
                </div>

            </React.Fragment>



        );
    }
}

Conversation.propTypes = {
    myIdentity: PropTypes.string.isRequired
};

export default Conversation;