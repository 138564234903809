import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import app from "./containers/App/app.reducer";
import auth from "./containers/Auth/auth.reducer";
import hoseMaker from "./containers/HoseMaker/hoseMaker.reducer";
import cart from "./containers/Cart/cart.reducer";
import userCart from "./containers/Cart/userCart.reducer";
import orderConfirmation from "./containers/OrderConfirmation/orderConfirmation.reducer";
import deliveryAddress from "./containers/Profile/DeliveryAddress/deliveryAddress.reducer";
import { allForms } from "./forms";
import search from "./containers/Search/search.reducer";
import cafForm from "./containers/CafForm/caf.reducer";
import orderHistory from "./containers/OrderHistory/orderHistory.reducer";
import myFavorites from "./containers/MyFavorites/myFavorites.reducer";
import home from "./containers/Home/home.reducer";
import assets from "./containers/Asset/asset.reducer";
import assetForm from "./containers/AssetForm/assetForm.reducer";
import assetLocation from "./containers/AssetLocation/assetLocation.reducer";
import crew from "./containers/CrewManagement/crew.reducer";
import inventory from "./containers/Inventory/inventory.reducer";
import inventoryLocation from "./containers/InventoryLocation/inventoryLocation.reducer";
import stock from "./containers/StockReview/stock.reducer";
import schedule from "./containers/Schedule/schedule.reducer";
import maintenance from "./containers/MaintenanceForm/maintenanceForm.reducer";
import auditTrail from "./containers/AuditTrail/auditTrail.reducer";
import assetDetail from "./containers/AssetDetails/assetDetail.reducer";
import reviewMaintenance from "./containers/ReviewMaintenanceForm/reviewMaintenance.reducer";
import myHoses from "./containers/MyHoses/myHoses.reducer";
import hoseMechanic from "./containers/HoseMechanic/hoseMechanic.reducer";

import chatGroups from "./containers/Chat/chat.reducer";

export default combineReducers({
  router: routerReducer,
  auth,
  app,
  forms: allForms,
  hoseMaker,
  deliveryAddress,
  cart,
  userCart,
  search,
  cafForm,
  orderConfirmation,
  orderHistory,
  myFavorites,
  home,
  assets,
  assetForm,
  assetLocation,
  crew,
  inventory,
  inventoryLocation,
  stock,
  schedule,
  maintenance,
  auditTrail,
  assetDetail,
  reviewMaintenance,
  myHoses,
  hoseMechanic,

  chatGroups,
});
