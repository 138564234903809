import _ from "lodash";
import {
  apiUpdateCart,
  apiGetCartItems,
  apiCreateCart,
  apiDeleteCart,
  apiUpdateCartByLocationId,
} from "./cart.api";
import ReactGA from "react-ga";

const ADD_ITEM = "ADD_ITEM";
const REMOVE_ITEM = "REMOVE_ITEM";
const CLEAR_ITEMS = "CLEAR_ITEMS";
const UPDATE_ITEMS = "UPDATE_ITEMS";
const UPDATE_CART = "UPDATE_CART";
const UPDATE_QUANTITY = "UPDATE_QUANTITY";
const UPDATE_DESCRIPTION = "UPDATE_DESCRIPTION";
const ADD_HOSE_TO_CART = "ADD_HOSE_TO_CART";

const initialState = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM: {
      let itemToAdd = action.payload;
      let filteredData = state;
      // const { userCartItems } = filteredData;
      const cartLength = filteredData && filteredData.length;
      const { quantity = 1, pack, stockCode, refId } = itemToAdd;
      var currentDate = new Date();
      var utcDate = currentDate.toISOString();

      itemToAdd.cartIndex = cartLength + 1;

      const isPresent = _.filter(state, (item) => {
        if (
          refId === null &&
          item.refId === "00000000-0000-0000-0000-000000000000"
        ) {
          return stockCode === item.barcode;
        }
        if (refId === item.refId) return stockCode === item.barcode;
      });

      if (!_.isEmpty(isPresent)) {
        const existingProduct = _.head(isPresent);

        filteredData = _.filter(state, (item) => {
          if (
            refId === null &&
            item.refId !== "00000000-0000-0000-0000-000000000000"
          )
            return item;
          return item.barcode !== stockCode;
        });

        // itemToAdd2 = {
        //   ...existingProduct,
        //   barcode: existingProduct.barcode,
        //   quantity: Number(existingProduct.quantity) + Number(quantity),
        //   pack: pack,
        // };

        const newQuantity =
          Number(existingProduct.quantity) < Number(quantity)
            ? Number(quantity)
            : Number(existingProduct.quantity) + Number(quantity);

        itemToAdd = {
          email: itemToAdd.email,
          currencyId: itemToAdd.currencyId,
          userCartItems: [
            {
              ...existingProduct,
              barcode: existingProduct.barcode,
              quantity: newQuantity,
              pack: pack,
            },
            ...filteredData,
          ],
        };
      } else {
        itemToAdd = {
          email: itemToAdd.email,
          currencyId: itemToAdd.currencyId,
          userCartItems: [
            {
              productId: itemToAdd.id,
              barcode: itemToAdd.stockCode,
              productName: itemToAdd.stockDescription,
              quantity: quantity,
              price: itemToAdd.price,
              boxQty: 0,
              productUom: itemToAdd.productUom,
              taxId: itemToAdd.taxId,
              refId: "00000000-0000-0000-0000-000000000000",
              stockImage1: itemToAdd.image128,
              image128: itemToAdd.image128,
              buyPrice: itemToAdd.buyPrice,
              retailPrice: itemToAdd.retailPrice,
              countryCode: itemToAdd.countryCode,
              boahubFullCarton: itemToAdd.boahubFullCarton,
              createdDateTime: utcDate,
              isSelected: true,
            },
            ...filteredData,
          ],
        };
      }

      // const newState = [...filteredData, itemToAdd];
      const { userCartItems } = itemToAdd;
      apiUpdateCart(itemToAdd);
      return userCartItems;
    }

    case REMOVE_ITEM: {
      const { payload } = action;

      apiUpdateCart(payload);
      return payload.userCartItems;
    }

    case UPDATE_ITEMS: {
      return [...action.payload];
    }
    case CLEAR_ITEMS: {
      const newState = [];

      apiDeleteCart(newState);
      // apiUpdateCart(newState);
      return newState;
    }
    case UPDATE_QUANTITY: {
      return [...action.payload];
    }
    case UPDATE_DESCRIPTION: {
      let { payload: itemToUpdate } = action;
      let filteredData = state;

      const isPresent = _.filter(state, (item) => {
        return item.stockCode === itemToUpdate.stockCode;
      });

      if (!_.isEmpty(isPresent)) {
        const existingProduct = _.head(isPresent);
        filteredData = _.filter(state, (item) => {
          return item.stockCode !== itemToUpdate.stockCode;
        });
        const { stockDescription } = itemToUpdate;
        itemToUpdate = {
          ...existingProduct,
          stockCode: existingProduct.stockCode,
          stockDescription:
            stockDescription || existingProduct.stockDescription,
        };
      }

      const newState = [...filteredData, itemToUpdate];

      const sortedCart = _.sortBy(newState, ["cartIndex"]);
      apiUpdateCart(sortedCart);

      return sortedCart;
    }
    case ADD_HOSE_TO_CART: {
      let cartData = state;
      const { payload: itemToUpdate } = action;
      const { quantity = 1, pack } = itemToUpdate;
      let itemToAdd = action.payload;
      let index = cartData.length + 1;
      itemToAdd.cartIndex = index;
      const isPresent = _.filter(state, (item) => {
        return item.stockCode === itemToUpdate.stockCode;
      });

      if (!_.isEmpty(isPresent)) {
        const existingProduct = _.head(isPresent);
        cartData = _.filter(state, (item) => {
          return item.stockCode !== itemToUpdate.stockCode;
        });
        itemToAdd = {
          ...existingProduct,
          stockCode: existingProduct.stockCode,
          quantity: Number(existingProduct.quantity) + Number(quantity),
          pack: pack,
        };
      }
      const newState = [...cartData, itemToAdd];
      apiUpdateCart(newState);
      return newState;
    }
    default:
      return state;
  }
};

export const actionAddToCart = (item) => {
  return async (dispatch) => {
    dispatch({ type: ADD_ITEM, payload: item });
  };
};

export const actionAddProductToCart = (item, cartItems, locationId) => {
  let itemToAdd = item;
  let filteredData = cartItems;
  const cartLength = filteredData && filteredData.length;
  const { quantity = 1, pack, stockCode, refId } = itemToAdd;
  var currentDate = new Date();
  var utcDate = currentDate.toISOString();

  itemToAdd.cartIndex = cartLength + 1;

  itemToAdd = {
    boahubLocationId: locationId,
    email: itemToAdd.email,
    currencyId: itemToAdd.currencyId,
    updatedCart: {
      productId: itemToAdd.id,
      barcode: itemToAdd.stockCode,
      productName: itemToAdd.stockDescription,
      quantity: quantity,
      price: itemToAdd.price,
      boxQty: 0,
      productUom: itemToAdd.productUom,
      taxId: itemToAdd.taxId,
      refId: "00000000-0000-0000-0000-000000000000",
      stockImage1: itemToAdd.image128,
      image128: itemToAdd.image128,
      buyPrice: itemToAdd.buyPrice,
      retailPrice: itemToAdd.retailPrice,
      countryCode: itemToAdd.countryCode,
      boahubFullCarton: itemToAdd.boahubFullCarton,
      createdDateTime: utcDate,
      isSelected: true,
    },
  };

  // const newState = [...filteredData, itemToAdd];
  const { userCartItems } = itemToAdd;
  apiUpdateCartByLocationId(itemToAdd);
  return userCartItems;
};

export const actionRemoveFromCart = (item) => {
  return async (dispatch) => {
    dispatch({ type: REMOVE_ITEM, payload: item });
  };
};

export const actionClearAll = () => {
  ReactGA.event({
    category: "Cart",
    action: "Clear all",
  });

  return async (dispatch) => {
    dispatch({ type: CLEAR_ITEMS });
  };
};

export const actionLoadCart = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetCartItems();
      const { data } = result;
      const { userCartItems } = data;
      userCartItems.map((item, i) => {
        item.isSelected = true;
      });
      userCartItems.sort(function (a, b) {
        return new Date(b.createdDateTime) - new Date(a.createdDateTime);
      });
      dispatch({ type: UPDATE_ITEMS, payload: userCartItems });
      dispatch({ type: UPDATE_CART, payload: userCartItems });
    } catch (e) {
      console.error("Failed to load cart", e);
    }
  };
};

export const actionUpdateItems = (sortedCart) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_QUANTITY, payload: sortedCart });
    } catch (e) {
      dispatch({ type: UPDATE_QUANTITY, payload: e });
    }
  };
};

export const actionUpdateQuantity = (sortedCart) => {
  return async (dispatch) => {
    try {
      await apiUpdateCart(sortedCart);
      dispatch({ type: UPDATE_QUANTITY, payload: sortedCart });
    } catch (e) {
      dispatch({ type: UPDATE_QUANTITY, payload: e });
    }
  };
};

export const actionUpdateDescription = (item) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_DESCRIPTION, payload: item });
  };
};

export const actionAddHoseToCart = (item) => {
  return (dispatch) => {
    dispatch({ type: ADD_HOSE_TO_CART, payload: item });
  };
};
