import React from "react";
import { Icon } from "semantic-ui-react";
import "./contentCard.css";
import videoIcon from "../../assets/images/doc_type/VID.svg";
import docIcon from "../../assets/images/doc_type/DOC.svg";
import audIcon from "../../assets/images/doc_type/AUD.svg";
import webIcon from "../../assets/images/doc_type/WEB.png";

const ContentCard = ({ content }) => {
  return (
    <div
      className="content-card"
      title={content.contentName}
      onClick={() => window.open(content.contentFilePath, "_blank")}
    >
      {content.contentType === "IMG" ? (
        <img
          className="content_img"
          src={content.contentFilePath}
          alt={content.contentName}
        ></img>
      ) : (
        <div className="content_icon">
          {renderContentIcon(content.contentType)}
        </div>
      )}

      <div className="content_name">
        <div className="content_txt">{content.contentName}</div>
      </div>
    </div>
  );
};

const renderContentIcon = (type) => {
  switch (type) {
    case "VID":
      return <img src={videoIcon} className="type-icon" alt={type} />;
    case "DOC":
      return <img src={docIcon} className="type-icon" alt={type} />;
    case "AUD":
      return <img src={audIcon} className="type-icon" alt={type} />;
    case "WEB":
      return <img src={webIcon} className="type-icon" alt={type} />;
    default:
      return <Icon name="file" className="type-icon" />;
  }
};

export default ContentCard;
