import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Control, Errors } from "react-redux-form";
import { Grid, Divider, Form } from "semantic-ui-react";
import polyglot from "../../i18n";
import * as cafReducer from "../../containers/CafForm/caf.reducer";

class CafDepartmentForm extends React.Component {
  constructor() {
    super();
    this.state = {
      
    };
  }

  render() {
    const manager = (
      <Form.Field>
        <label>{polyglot.t("cafForm.manager")}</label>
        <Control
          type="text"
          model="forms.cafForm.manager"
          placeholder={polyglot.t("cafForm.manager")}
          validateOn="change"
          className="input-box"
          autoComplete="new-password"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.manager"
          show="touched"
          messages={{
            valueMissing: polyglot.t("cafForm.required.manager")
          }}
        />
      </Form.Field>
    );

    const accounts = (
      <Form.Field >
        <label>{polyglot.t("cafForm.accounts")}</label>
        <Control
          type="text"
          model="forms.cafForm.accounts"
          placeholder={polyglot.t("cafForm.accounts")}
          validateOn="change"
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.accounts"
          show="touched"
          messages={{
            valueMissing: polyglot.t("cafForm.required.accounts")
          }}
        />
      </Form.Field>
    );
    const purchasing = (
      <Form.Field>
        <label>{polyglot.t("cafForm.purchasing")}</label>
        <Control
          type="text"
          model="forms.cafForm.purchasing"
          placeholder={polyglot.t("cafForm.purchasing")}
          validateOn="change"
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.purchasing"
          show="touched"
          messages={{
            valueMissing: polyglot.t("cafForm.required.purchasing")
          }}
        />
      </Form.Field>
    );

    const managersEmail = (

      <Form.Field>
        <label>{polyglot.t("cafForm.managersEmail")}</label>
        <Control
          type="email"
          model="forms.cafForm.managerEmail"
          placeholder={polyglot.t("cafForm.managersEmail")}
          validateOn="change"
          className="input-box"
          autoComplete="new-password"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.managerEmail"
          show="touched"
          messages={{
            valueMissing: polyglot.t("cafForm.required.email"),
            typeMismatch: polyglot.t("cafForm.valid.email")
          }}
        />
      </Form.Field>
    );

    const accountsEmail = (
      
      <Form.Field>
        <label>{polyglot.t("cafForm.accountsEmail")}</label>
        <Control
          type="email"
          model="forms.cafForm.accountsEmail"
          placeholder={polyglot.t("cafForm.accountsEmail")}
          validateOn="change"
          className="input-box"
          
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.accountsEmail"
          show="touched"
          messages={{
            valueMissing: polyglot.t("cafForm.required.email"),
            typeMismatch: polyglot.t("cafForm.valid.email")
          }}
        />
      </Form.Field>
    );
    const purchasingEmail = (
      <Form.Field >
        <label>{polyglot.t("cafForm.purchasingEmail")}</label>
        <Control
          type="email"
          model="forms.cafForm.purchasingEmail"
          placeholder={polyglot.t("cafForm.purchasingEmail")}
          validateOn="change"
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.cafForm.purchasingEmail"
          show="touched"
          messages={{
            valueMissing: polyglot.t("cafForm.required.email"),
            typeMismatch: polyglot.t("cafForm.valid.email")
          }}
        />
      </Form.Field>
    );

    return (
      <React.Fragment>
        <h3>{polyglot.t("cafForm.title.departmentContacts")}</h3>
        <Divider />

        <Grid columns="equal">
              <Grid.Column width={8} computer={8} mobile={16}>{manager}</Grid.Column>
              <Grid.Column width={8} computer={8} mobile={16}>{managersEmail}</Grid.Column>
              <Grid.Column width={8} computer={8} mobile={16}>{accounts}</Grid.Column>
              <Grid.Column width={8} computer={8} mobile={16}>{accountsEmail}</Grid.Column>
              <Grid.Column width={8} computer={8} mobile={16}>{purchasing}</Grid.Column>
              <Grid.Column width={8} computer={8} mobile={16}>{purchasingEmail}</Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    forms: state.forms,
    auth: state.auth,
  };
};
const mapDispatchToProps = dispatch => bindActionCreators(cafReducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CafDepartmentForm);
