import { apiGetAssets, apiAddAsset, apiGetAllAsset } from "./asset.api";

const ADD_ASSET = "ADD_ASSET";
const SET_ASSET = "SET_ASSET";
const SET_ASSET_FOR_DROPDOWN = "SET_ASSET_FOR_DROPDOWN";

const initialState = {
  allAsset: {},
  assets: [],
  assetObject: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD_ASSET:
      return {
        ...state
      };

    case SET_ASSET:
      return {
        ...state,
        allAsset: action.payload
      };

    case SET_ASSET_FOR_DROPDOWN:
      return {
        ...state,
        assets: action.payload
      };

    default:
      return { ...state };
  }
};

export const actionGetAssets = (limit, pageNumber) => {
  return async dispatch => {
    try {
      const result = await apiGetAssets(limit, pageNumber);
      dispatch({ type: SET_ASSET, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_ASSET, payload: e });
    }
  };
};
export const actionAddAsset = data => {
  return async dispatch => {
    try {
      const result = await apiAddAsset(data);
      dispatch({ type: ADD_ASSET, payload: result });
    } catch (e) {
      dispatch({ type: ADD_ASSET, payload: e });
    }
  };
};

export const actionGetAllAsset = () => {
  return async dispatch => {
    try {
      const result = await apiGetAllAsset();
      dispatch({ type: SET_ASSET_FOR_DROPDOWN, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_ASSET_FOR_DROPDOWN, payload: e });
    }
  };
};
