import React, { useState } from "react";
import "./SendFeedback.css";
import feedbackIcon from "../../assets/images/send.svg";
import FeedbackForm from "./FeedbackForm";

const SendFeedback = () => {
  const [isFormVisible, setFormVisible] = useState(false);

  const showForm = () => {
    setFormVisible(true);
  };

  const closeForm = () => {
    setFormVisible(false);
  };

  return (
    <React.Fragment>
      <button onClick={showForm} className="feedback-button">
        <div className="content-container">
          <img src={feedbackIcon} className="feedback-icon" alt="Feedback" />
          Feedback
        </div>
      </button>
      {isFormVisible && <FeedbackForm closeForm={closeForm} />}
    </React.Fragment>
  );
};

export default SendFeedback;
