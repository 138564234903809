import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from "lodash";
import * as chatReducer from './chat.reducer';

import ChatLandingPage from './ChatLandingPage';



class Chat extends Component {
    constructor(props) {
        super(props);
     
    }

   
  

    render() {
   
        return (
            <React.Fragment>
                <ChatLandingPage/>
            </React.Fragment>

        )
    }
}

const mapStateToProps = state => {
    return {
        chatUserList: state.chatGroups.chatuserList.data,
        conversationList:state.chatGroups.allGroups.data,
        chatForm: state.forms.chatForm,
    }
}



const reducer = { ...chatReducer };

const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Chat);