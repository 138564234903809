import axios from "axios";

export const apiGetMinMaxReport = (id) => {
  const data = {
    stockLocationId: id,
  };
  return axios
    .get(`/api/InventoryManagement/min-max-get-report-products`, {
      params: data,
    })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiMinMaxReportAddToCart = (stockLocationId, products) => {
  const payload = {
    stockLocationId: stockLocationId,
    addToCartProductsFromMinMaxDetails: products,
  };
  return axios
    .patch("/api/InventoryManagement/min-max-add-cart-products", payload)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};
