import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Segment,
  List,
  Radio,
  Popup,
  Icon,
  Modal,
  Header
} from "semantic-ui-react";
import ViewImage from "../../components/ViewImage";

const answerOptions = ["red", "orange", "green"];

const CheckPoints = ({
  manageCheckPoints,
  maintenanceForm,
  addNotePage,
  addImagePage,
  deleteImage
}) => {
  const { data } = maintenanceForm;

  const sectioned = _.groupBy(data, "section");

  const goToAddNote = item => {
    const index = _.findIndex(data, item);
    addNotePage(item, index);
  };

  const goToAddImage = item => {
    const index = _.findIndex(data, item);
    addImagePage(item, index);
  };

  const CheckPointList = (
    <div>
      {sectioned &&
        _.map(sectioned, (point, c_key) => {
          return (
            <Segment key={c_key}>
              <h4>
                <b>Section: </b>
                {c_key}
              </h4>
              <List key={c_key} divided verticalAlign="middle">
                {_.map(point, item => {
                  const { field, result, note, imageUrl } = item;
                  let isAddNoteButton = false;
                  if (result === "red" || result === "orange") {
                    isAddNoteButton = true;
                  }
                  return (
                    <List.Item key={field}>
                      <Grid columns="equal">
                        <Grid.Column
                          width={4}
                          computer={4}
                          mobile={16}
                          verticalAlign="middle"
                          className="point-header"
                        >
                          <p>{field}</p>
                        </Grid.Column>
                        <Grid.Column
                          width={7}
                          computer={7}
                          className="check-result"
                          textAlign="center"
                          verticalAlign="middle"
                        >
                          {_.map(answerOptions, (option, o_key) => {
                            return (
                              <span
                                key={o_key}
                                onClick={() => manageCheckPoints(item, option)}
                              >
                                <Radio
                                  label={option}
                                  checked={result === option}
                                  value={option}
                                  className={option}
                                />
                              </span>
                            );
                          })}
                        </Grid.Column>
                        {isAddNoteButton && (
                          <Grid.Column
                            width={5}
                            mobile={16}
                            computer={5}
                            textAlign="right"
                          >
                            <span>
                              <Button
                                basic
                                color="blue"
                                content="Note"
                                size="mini"
                                icon="plus"
                                type="button"
                                textAlign="right"
                                onClick={() => goToAddNote(item)}
                              />
                              <Button
                                basic
                                color="blue"
                                content="Image"
                                size="mini"
                                type="button"
                                icon="plus"
                                textAlign="right"
                                onClick={() => goToAddImage(item)}
                              />
                            </span>
                          </Grid.Column>
                        )}
                        {imageUrl && imageUrl.length > 0 ? (
                          <Grid.Column width={8}>
                            <b>Images:</b>
                            <ViewImage
                              imageUrl={imageUrl}
                              deleteImage={imageObject =>
                                deleteImage(imageObject, item)
                              }
                            />
                          </Grid.Column>
                        ) : null}
                        {note && (
                          <Grid.Column width={8}>
                            <b>Short note:</b>
                            <div>{note}</div>
                          </Grid.Column>
                        )}
                      </Grid>
                    </List.Item>
                  );
                })}
              </List>
            </Segment>
          );
        })}
    </div>
  );

  return (
    <Segment className="check-points">
      <Grid>
        <Grid.Column width={3}>
          <h4 className="header"> Check Points</h4>
        </Grid.Column>
        {/* <Grid.Column width={13}>
          <Modal
            trigger={<a className="HelpText_checkList">Help ?</a>}
            closeIcon
          >
            <Modal.Content>
              <Modal.Description>
                <Header>ACCEPTED INSPECTION TERMS:</Header>
                <ul style={{ listStyle: "none" }}>
                  <li>Dry = No oil loss at seals</li>
                  <li>Tight = Joint moves as new</li>
                  <li>Movement = Joint shows normal use</li>
                  <li>
                    <Icon name="circle" color="orange" />
                    Seep = Oil slowly accumulating
                  </li>
                  <li>
                    <Icon name="circle" color="orange" />
                    Play = Joint will need eventual attention
                  </li>
                  <li>
                    <Icon name="circle" color="red" />
                    LEAK = Needs immediate repair / replacement
                  </li>
                  <li>
                    <Icon name="circle" color="red" />
                    Loose = Joint needs immediate repair / replacement
                  </li>
                  <li>
                    <Icon name="circle" color="orange" />
                    <Icon name="circle" color="red" />
                    Wet = Fresh Oil Sitting on Seal
                  </li>
                </ul>
              </Modal.Description>
            </Modal.Content>
          </Modal>
        </Grid.Column> */}
      </Grid>
      <Grid className="form-panel">
        <Grid.Column width={16} mobile={16}>
          {CheckPointList}
        </Grid.Column>
      </Grid>
    </Segment>
  );
};
CheckPoints.propTypes = {
  manageCheckPoints: PropTypes.func,
  addNotePage: PropTypes.func,
  maintenanceForm: PropTypes.object,
  addImagePage: PropTypes.func,
  deleteImage: PropTypes.func
};

export default CheckPoints;
