import React from "react";
import { Grid, Icon, Divider, Modal } from "semantic-ui-react";
import PropTypes from "prop-types";
import SearchFitterById from "./SearchFitterById";
import _ from "lodash";
import SearchFitterByFilter from "./SelectFitterByFilters";
import { HOSE_FITTING_IMAGE_S3_LINK } from "./hoseConstants";
class Fitting extends React.Component {
  constructor() {
    super();
    this.state = {
      modalOpen: false
    };
  }

  onSelectFitting = fitting => {
    this.props.fittingChangeHandler(fitting);
    this.setState({ modalOpen: false });
  };

  handleOpen = () => this.setState({ modalOpen: true });

  handleClose = () => this.setState({ modalOpen: false });

  render = () => {
    const { modalOpen } = this.state;
    const { side, fittingValue = {} } = this.props;
    const {
      stockImage1 = "",
      productDimensions = {},
      stockCode = ""
    } = fittingValue;
    
    // eslint-disable-next-line
    const { orientation = null } = productDimensions;
    let customClass = "";
    const stockCodeArray = stockCode.split("-");
    const code = stockCodeArray[0];

    let imageLink = HOSE_FITTING_IMAGE_S3_LINK + code + `HM_1.jpg`;

    // if (orientation && orientation !== "STRAIGHT") {
    //   customClass = "rotateUp";
    // }

    let valueJSX = "";
    if (side === "right") {
      valueJSX = (
        <div>
          {stockImage1 && side === "right" ? (
            <img
              onClick={this.handleOpen}
              alt="right fitting"
              src={imageLink}
              className="fitter-image-right"
            />
          ) : (
            "Image not found"
          )}
        </div>
      );
    } else {
      valueJSX = (
        <div>
          {stockImage1 && side === "left" ? (
            <img
              onClick={this.handleOpen}
              alt="left fitting"
              src={imageLink}
              className={
                customClass ? "fitter-image-left" : "fitter-image-left"
              }
            />
          ) : (
            "Image not found"
          )}
        </div>
      );
    }
    return (
      <div>
        <Modal
          open={modalOpen}
          onClose={this.handleClose}
          closeIcon
          size={"fullscreen"}
        >
          <Modal.Header>Select {side} fitting</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Grid>
                <Grid.Column width={16}>
                  <SearchFitterById selectedFitting={this.onSelectFitting} />
                  <Divider horizontal>Or</Divider>
                </Grid.Column>
              </Grid>
              <SearchFitterByFilter side={side} closeModal={this.handleClose} />
            </Modal.Description>
          </Modal.Content>
        </Modal>
        {!_.isEmpty(fittingValue) ? (
          valueJSX
        ) : (
          <div className="add-fitter" onClick={this.handleOpen}>
            <Icon name="add" color="green" />
            <div className="fitting-message">
              <h3>
                {" "}
                {side === "left" ? "2." : "3."} Select {side} Fitting
              </h3>
              Click to Select Fitting
            </div>
          </div>
        )}
      </div>
    );
  };
}

Fitting.propTypes = {
  fittingChangeHandler: PropTypes.func,
  fittingValue: PropTypes.object,
  side: PropTypes.string,
  type: PropTypes.string
};

export default Fitting;
