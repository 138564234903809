import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Search } from "semantic-ui-react";
import QuickSearchResultRenderer from "./QuickSearchResultRenderer";
import * as searchReducer from "../../containers/Search/search.reducer";
import _ from "lodash";

const QuickSearch = (props) => {
  const [value, setValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [filter] = useState("");

  const handleResultSelect = (e, { result }) => {
    e.preventDefault();
    props.addToCart(result);
  };

  const handleSearchChange = async (e, { value }) => {
    setValue(value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      searchProduct(value);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [value]);

  const searchProduct = async (search) => {
    if (search === "") {
      setIsLoading(false);
      return false;
    }
    setIsLoading(true);
    await props.actionAutoComplete(value, filter);
    setIsLoading(false);
  };

  const { autoCompleteResult = [] } = props;

  return (
    <React.Fragment>
      <Grid>
        <Grid.Column width={16}>
          <Search
            className="search-box"
            loading={isLoading}
            placeholder="Quick add by product code and description."
            resultRenderer={QuickSearchResultRenderer}
            onResultSelect={handleResultSelect}
            onSearchChange={handleSearchChange}
            noResultsMessage="No direct matches found."
            results={autoCompleteResult}
            value={value}
            {...props}
          />
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  autoCompleteResult: state.search.autoCompleteResult,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(searchReducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearch);
