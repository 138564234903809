import React from 'react';
import './chat.css';
import {
    List,
    Image,
} from "semantic-ui-react";
import _ from 'lodash';
import EditGroupModal from './EditGroup';
import AddMemberModal from './AddMemberModal';
import * as chatReducer from './chat.reducer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ExitGroupModal from './ExitGroupModal';

class GroupInfo extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount = async () => {
        const { conversationDetails } = this.props;
        const userDetail = _.map(conversationDetails.participantsDetails, user => conversationDetails.chatDetails.createdBy !== user.email ? user._id : null);
        const userId = userDetail[0]
        if (conversationDetails.chatDetails.chatType === 1) {
            await this.props.getChatUserDetails(userId);
        }

    }

    componentDidUpdate(prevProps, prevState) {
        const { conversationDetails, userDetails } = this.props;
        const userDetail = _.map(conversationDetails.participantsDetails, user => conversationDetails.chatDetails.createdBy !== user.email ? user._id : null);
        const userId = userDetail[0]

        if (prevProps.userDetails == userDetails && conversationDetails.chatDetails.chatType === 1) {


            this.props.getChatUserDetails(userId);

        }
    }

    handleExitChatgroup = async () => {
        this.setState({ loading: true });
        const { conversationDetails } = this.props;
        let conversationId = {
            conversationSID: conversationDetails.sid
        };


        await this.props.actionExitChatgroup(conversationId);


        this.setState({ loading: false });
        this.props.closeGroupInfo();
    };

    render() {
        const { closeGroupInfo, conversationDetails, userDetails, onExitChat } = this.props;


        const userDetail = [{ ...userDetails }];
        const loggedInUser = localStorage.getItem("user");


        return (

            <div className="temp">
                <div className="groupinfo_header">
                    <h3>
                        {conversationDetails && conversationDetails.chatDetails.chatType === 2 ? conversationDetails && conversationDetails.friendlyName : conversationDetails && conversationDetails.participantsDetails.map(user => {
                            if (user.email !== loggedInUser) {
                                return <text>{user.firstName}&nbsp;{user.lastName}</text>
                            }

                        })}

                    </h3>

                    {/* <h3>{conversationDetails.chatDetails.chatTitle}</h3> */}
                    <a href="#"> <i className="boa ba-close" onClick={closeGroupInfo}></i></a>
                </div>

                {conversationDetails && conversationDetails.chatDetails.chatType === 2 ?

                    <div className="groupinfo_body">
                        <div className="groupinfo_topblock">
                            <div className="groupinfo_user">
                                <div className="groupinfo_user_img">
                                    {/* <i class="boa ba-user_group"></i> */}
                                    <Image src={conversationDetails.chatDetails.channelIcon} style={{ borderRadius: "100px" }} />

                                </div>
                                <div>
                                    {loggedInUser === conversationDetails.chatDetails.createdBy ? <EditGroupModal conversationDetails={conversationDetails} /> : null}



                                </div>

                            </div>
                            <div className="groupinfo_edit_list">
                                <List>
                                    <List.Item>

                                        {conversationDetails && conversationDetails.chatDetails.chatType === 2 ? conversationDetails && conversationDetails.friendlyName : conversationDetails && conversationDetails.participantsDetails.map(user => {
                                            if (user.email !== loggedInUser) {
                                                return <text>{user.firstName}</text>
                                            }

                                        })}

                                    </List.Item>
                                    <List.Item>
                                        <p>{conversationDetails && (new Date(conversationDetails.dateCreated).toLocaleString())}</p>
                                    </List.Item>

                                </List>
                            </div>
                        </div>
                        <div className="groupmember_wrapper">
                            <List>
                                <List.Item>

                                    {loggedInUser === conversationDetails.chatDetails.createdBy ?
                                        <AddMemberModal
                                            operation="add"
                                        /> : null}


                                </List.Item>
                                {

                                    conversationDetails.participantsDetails.map(member => {
                                        const { _id, firstName, mobileNumber } = member;
                                        return <List.Item key={_id}>
                                            <div className="wrap">
                                                <div className="contact_img">
                                                    <div className="contact_img_txt">
                                                        HS
                                                    {/* <i className="boa ba-user_group"></i> */}
                                                    </div>
                                                </div>
                                                <div className="meta">
                                                    <div className="name">
                                                        <div className="username_info">
                                                            <div className="usertext"> {firstName}</div>
                                                            <div className="userphone"> {mobileNumber}</div>
                                                        </div>
                                                        <div className="userstatus"> {conversationDetails.chatDetails.createdBy === member.email ? "Owner" : null}
                                                            {member[0] = (conversationDetails.chatDetails.createdBy === member.email)}
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </List.Item>
                                    })
                                }


                            </List>
                        </div>

                        <div className="groupaction_footer">
                            {conversationDetails.chatDetails.createdBy === loggedInUser ? <List>
                                <List.Item >
                                    {/* <ExitGroupModal onExitChat={this.handleExitChatgroup} /> */}
                                </List.Item>
                            </List> : null
                            }

                        </div>


                    </div>


                    //* =============================================User Details=================================== */}

                    : <div className="groupinfo_body">
                        <div className="groupinfo_topblock">
                            <div className="groupinfo_user">
                                <div className="groupinfo_user_img">
                                    {/* <i class="boa ba-user_group"></i> */}
                                    {/* <Image src={conversationDetails.chatDetails.channelIcon} style={{ borderRadius: "100px" }} /> */}

                                </div>


                            </div>
                            <div className="groupinfo_edit_list">

                                {/* {_.map(userDetail,user=> <div>
                                
                                  <List key={user._id}>
                                        <List.Item>
                                           {user.firstName}
                                        </List.Item>
                                        <List.Item>
                                            <p>{user.mobileNumber}</p>
                                        </List.Item>
                                        <List.Item>
                                            <p>{user.userRole}</p>
                                        </List.Item>

                                    </List> 
                                    </div>
                               )}  */}
                                {/* {_.map(conversationDetails.participantsDetails,user=> <div>
                                
                                    {conversationDetails.chatDetails.createdBy !== user.email ?  <List onClick={this.handleUserDetails}>
                                        <List.Item>
                                           {user.firstName}
                                        </List.Item>
                                        <List.Item>
                                            <p>{user.mobileNumber}</p>
                                        </List.Item>
                                        <List.Item>
                                            <p>{user.userRole}</p>
                                        </List.Item>

                                    </List> : null}
                                    </div>
                               )} */}
                                {

                                    conversationDetails.participantsDetails.map(member => {
                                        const { _id, firstName,lastName, mobileNumber, userRole, email } = member;
                                        if (email !== loggedInUser) {
                                            return <List onClick={this.handleUserDetails} key={_id}>

                                                <List.Item>
                                                    {firstName} &nbsp; {lastName}
                                                </List.Item>
                                                <List.Item>
                                                    <p>{mobileNumber}</p>
                                                </List.Item>
                                                <List.Item>
                                                    <p>{userRole}</p>
                                                </List.Item>

                                            </List>
                                        }





                                    })
                                }







                            </div>
                        </div>




                    </div>
                }
            </div>

        );
    }
}



const mapStateToProps = state => {
    return {
        conversationDetails: state.chatGroups.conversationDetails.data,
        chatUserList: state.chatGroups.chatuserList.data,
        chatForm: state.forms.chatForm,
        userDetails: state.chatGroups.userDetails.data

    }
}



const reducer = { ...chatReducer };

const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(GroupInfo);