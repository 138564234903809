import axios from "axios";
import { SCHEDULE } from "../../constants/apiLink";

export const apiGetMaintenanceRecord = async id => {
  const url = SCHEDULE + "record/" + id;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiUpdateMaintenanceRecord = async formData => {
  const { _id } = formData;
  const url = SCHEDULE + "record/" + _id;
  return axios.put(url, formData).then(result => {
    return result;
  });
};

export const apiUploadImage = async fileData => {
  const url = SCHEDULE + "upload";
  return axios.post(url, fileData).then(result => {
    return result;
  });
};

export const apiDeleteImage = async imageKey => {
  const url = SCHEDULE + "?imageKey=" + imageKey;
  return axios.delete(url).then(result => {
    return result;
  });
};
