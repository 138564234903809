import React, { useState, useEffect } from "react";
import {
  Button,
  Icon,
  Grid,
  Divider,
  Confirm,
  Modal,
  Header,
  Checkbox,
} from "semantic-ui-react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MetaData from "../../components/MetaData";
import * as cartReducer from "./cart.reducer";
import * as userCartReducer from "./userCart.reducer";
import * as authReducer from "../Auth/auth.reducer";
import * as orderConfirmationReducer from "../OrderConfirmation/orderConfirmation.reducer";
import _ from "lodash";
import "./cart.css";
import CartProducts from "../../components/CartProducts";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import TotalPrice from "../../components/TotalPrice";
import Loader from "../../components/Loader";
import QuickSearch from "../../components/QuickSearch";
import UserRoleCheck from "../../components/UserRoleCheck";
import { apiGetProductDetails } from "../HoseMaker/hoseMaker.api";
import { apiGetUserCurrentStockLocationForChecking } from "./cart.api";

function Cart(props) {
  const [state, setState] = useState({
    initialLoad: true,
    updatingQty: false,
    isLoading: true,
    updatingStockCode: "",
    confirmModal: false,
    isSearching: false,
    zeroPriceModal: false,
    stockCode: null,
  });
  const [hasError, setHasError] = useState(false);
  const [noItemsSelected, setNoItemsSelected] = useState(false);
  const [hasLessQuantityThanRoll, setHasLessQuantityThanRoll] = useState(false);
  const [itemWithLessThanRoll, setItemWithLessThanRoll] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasInfo, setHasInfo] = useState(false);
  const [newStockLocationName, setNewStockLocationName] = useState("");

  const openNode = [];

  useEffect(() => {
    if (props.userCart.isError) {
      setHasError(true);
      setErrorMessage(props.userCart?.error);
    }
  }, [props.userCart.isError]);

  useEffect(() => {
    loadCart();
  }, []);

  useEffect(() => {
    const selectedItems = props.cart.filter((item) => item.isSelected === true);
    localStorage.setItem("selectedCartItems", JSON.stringify(selectedItems));
  }, [props]);

  const loadCart = async () => {
    await props.actionLoadCart();
    // await props.actionLoadCartObj();
    // await props.actionGetOverallDiscount();
    setState({ ...state, initialLoad: false, isLoading: false });
  };

  const getAccountDetails = async () => {
    await props.actionGetProfileData();
    await props.actionSetDeliveryDetails();
  };

  const setProductIsSelected = async (itemToSelect, isSelected) => {
    const { cart } = props;
    const { stockCode, userCart } = itemToSelect;

    setState({
      ...state,
      isLoading: true,
      updatingStockCode: stockCode,
      updatingQty: true,
    });

    let newCart = cart.map((item, i) => {
      if (
        item.barcode === itemToSelect.stockCode &&
        item.refId === itemToSelect.refId
      ) {
        item.isSelected = isSelected;
      }
      return item;
    });

    userCart.userCartItems = newCart;

    await props.actionUpdateItems(newCart);

    setState({
      ...state,
      isLoading: false,
      updatingStockCode: "",
      updatingQty: false,
    });
  };

  const setSelectAllProducts = async (selectAll) => {
    const { cart } = props;

    setState({
      ...state,
      isLoading: true,
      updatingStockCode: stockCode,
      updatingQty: true,
    });

    let newCart = cart.map((item, i) => {
      item.isSelected = selectAll;
      return item;
    });
    userCart.userCartItems = newCart;

    await props.actionUpdateItems(newCart);

    setState({
      ...state,
      isLoading: false,
      updatingStockCode: "",
      updatingQty: false,
    });
  };

  const patchQuantity = async (itemToAdd, isManual) => {
    const { stockCode, userCart } = itemToAdd;
    let action = null;
    let difference = 0;

    setState({
      ...state,
      isLoading: true,
      updatingStockCode: stockCode,
      updatingQty: true,
    });

    const newCart = getNewCartObject(itemToAdd);
    const updatedItem = newCart.updatedCart[0];
    userCart.updatedCart = newCart.updatedCart[0];
    userCart.userCartItems = newCart.userCartItems;

    if (itemToAdd.quantity > updatedItem.quantity) {
      difference = itemToAdd.quantity - updatedItem.quantity;
      action = "add";
    }
    if (itemToAdd.quantity < updatedItem.quantity) {
      difference = updatedItem.quantity - itemToAdd.quantity;
      action = "minus";
    }
    if (itemToAdd.quantity === 0) {
      action = "delete";
    }

    if (!isManual && difference !== 0) {
      userCart.updatedCart.quantity = difference;
    } else {
      userCart.updatedCart.quantity = itemToAdd.quantity;
      action = null;
    }

    await props.actionUpdateItems(newCart.userCartItems);
    await props.actionPatchCart(userCart, action);
    await props.actionLoadCart();

    setState({
      ...state,
      isLoading: false,
      updatingStockCode: "",
      updatingQty: false,
    });
  };

  const updateQuantity = async () => {
    await props.actionUpdateCart(userCartData);
  };

  const getNewCartObject = (itemToUpdate) => {
    const { cart } = props;
    let filteredData = cart;

    const isPresent = _.filter(cart, (item) => {
      if (item.refId === itemToUpdate.refId)
        return item.barcode === itemToUpdate.stockCode;
    });

    if (!_.isEmpty(isPresent)) {
      const existingProduct = _.head(isPresent);
      filteredData = _.filter(cart, (item) => {
        if (item.barcode === itemToUpdate.stockCode)
          return item.refId !== itemToUpdate.refId;
        return item.barcode !== itemToUpdate.stockCode;
      });
      const { quantity = 1 } = itemToUpdate;
      itemToUpdate = {
        ...existingProduct,
        stockCode: existingProduct.stockCode,
        quantity: quantity,
      };
    }
    const newCart = [...filteredData, itemToUpdate];
    const sortedCart = _.sortBy(newCart, ["productId", "refId"]);
    const cartData = { updatedCart: isPresent, userCartItems: sortedCart };
    return cartData;
  };

  const updateLength = async (itemToAdd) => {
    const { stockCode } = itemToAdd;
    setState({ ...state, isLoading: true, updatingStockCode: stockCode });
    await props.actionUpdateLength(itemToAdd);
    setState({ ...state, isLoading: false, updatingStockCode: "" });
  };

  const updateDescription = (itemToUpdate) => {
    props.actionUpdateDescription(itemToUpdate);
  };

  const removeFromCart = (payload, userCart) => {
    const filteredData = _.filter(userCart.userCartItems, (item) => {
      if (item.barcode === payload.barcode) return item.refId !== payload.refId;
      return item.barcode !== payload.barcode;
    });

    const newState = [...filteredData];
    userCart.userCartItems = newState;

    props.actionRemoveFromCart(userCart);
    showSuccessToast(
      <div>
        <i className="trash alternate icon" />{" "}
        {`Removed ${payload.stockCode} from the Cart`}
      </div>
    );
  };

  const clearCart = () => {
    props.actionClearAll();
    setState({ ...state, confirmModal: false });
    const successToast = (
      <div>
        <i className="trash alternate icon" /> Cart is cleared{" "}
      </div>
    );
    showSuccessToast(successToast);
  };

  const triggerClearCart = () => {
    setState({ ...state, confirmModal: true });
  };

  const closeConfirmation = () => {
    setState({ ...state, confirmModal: false });
  };

  const goToConfirmOrder = async () => {
    const selectedItems = props.cart.filter((item) => item.isSelected === true);
    const noItemsSelectedData = props.cart.every(
      (obj) => obj.isSelected === false
    );
    if (noItemsSelectedData) {
      setNoItemsSelected(true);
      return false;
    }
    const itemsLessThanRoll = selectedItems.filter(
      (obj) => obj.productUom === 8 && obj.quantity < obj.boahubFullCarton
    );
    if (itemsLessThanRoll?.length > 0) {
      setItemWithLessThanRoll(itemsLessThanRoll);
      setHasLessQuantityThanRoll(true);
      return false;
    }

    onConfirmOrder();
  };

  const onConfirmOrder = async () => {
    if (getCartCount) {
      const stockLocationId = localStorage.getItem("boahubLocationId");
      const currentStockLocationId = Number(stockLocationId);
      const currentUserInfo = await apiGetUserCurrentStockLocationForChecking();
      const isPartial = cart.some((item) => item.isSelected === false);
      localStorage.setItem("isPartial", isPartial);
      if (currentUserInfo) {
        if (currentStockLocationId !== currentUserInfo.id) {
          setHasInfo(true);
        } else {
          props.history.push("/orderConfirmation");
        }
      } else {
        props.history.push("/orderConfirmation");
      }
    }
  };

  const getCartCount = () => {
    let quantity = 0;
    _.forEach(props.cart, (item) => {
      const { pack } = item;
      if (pack === "Metre") {
        quantity += 1;
      } else {
        quantity += Number(item.quantity);
      }
    });
    return <span> Quantity {quantity}</span>;
  };

  const goBack = (e) => {
    e.preventDefault();
    props.history.goBack();
  };

  const handleClose = () => {
    setState({ ...state, zeroPriceModal: false });
  };

  const handleAddToCart = async (product) => {
    const { cart, userInfo } = props;
    const {
      barcode: stockCode,
      name: stockDescription,
      pack,
      listPrice: price,
      uom,
      currency,
      id,
      taxId,
      image128,
      buyPrice,
      retailPrice,
      countryCode,
      boahubFullCarton,
    } = product;

    product.stockImage1 = product.image128;
    product.stockCode = product.barcode;

    setState({
      ...state,
      zeroPriceModal: false,
      initialLoad: true,
      isLoading: true,
    });

    var currentDate = new Date();
    var utcDate = currentDate.toISOString();

    let addToCarData = {
      stockCode,
      quantity: 1,
      length: 1,
      pack: uom && uom.name,
      stockDescription,
      price,
      currencyId: currency && currency.id,
      id,
      email: userInfo && userInfo.email,
      taxId,
      productUom: uom && uom.id,
      image128,
      buyPrice,
      retailPrice,
      countryCode,
      refId: null,
      boahubFullCarton: boahubFullCarton,
      createdDateTime: utcDate,
      isSelected: true,
    };

    props.actionAddToCart(addToCarData);

    const isPresent = _.filter(cart, (item) => {
      return stockCode === item.barcode;
    });

    _.isEmpty(isPresent) &&
      showSuccessToast(
        <div>
          <i className="shopping cart icon" />{" "}
          {`Added  product ${stockCode} to cart.`}
        </div>
      );

    !_.isEmpty(isPresent) &&
      showSuccessToast(
        <div>
          <i className="shopping cart icon" />{" "}
          {`Product ${stockCode} is already in your cart - please check the quantity.`}
        </div>
      );

    setState({ ...state, initialLoad: false, isLoading: false });
  };

  const { cart, userCart, userInfo, accountInfo, overallDiscount } = props;
  const { account = {}, showPriceToUser } = userInfo;
  const { showPrice = false } = account;

  const { discount = 0 } = overallDiscount;
  const {
    isLoading,
    updatingStockCode,
    initialLoad,
    updatingQty,
    zeroPriceModal,
    stockCode,
  } = state;
  const { userRole } = userInfo;

  const action_buttons = (
    <span>
      <Button floated="left" icon labelPosition="left" onClick={goBack}>
        <Icon name="left arrow" />
        Go back
      </Button>
      {cart.length > 0 && (
        <Button floated="left" color="black" onClick={triggerClearCart}>
          <Icon name="cart" /> Clear
        </Button>
      )}
    </span>
  );

  const order_details = (
    <span>
      {cart.length > 0 && (
        <div className="cart-confirm-order">
          <Grid centered>
            <Grid.Column computer={16} mobile={16} tablet={16}>
              {account.showPrice && showPriceToUser ? (
                <TotalPrice
                  cart={cart}
                  cartCount={getCartCount()}
                  isLoading={isLoading}
                  account={accountInfo}
                  overallDiscount={discount}
                  showPrice={showPrice}
                  userInfo={userInfo}
                />
              ) : null}
              <Button
                floated="right"
                fluid
                size="medium"
                style={{
                  backgroundColor: "#66cc23",
                  color: "#fff",
                }}
                onClick={goToConfirmOrder}
              >
                Confirm order
              </Button>
            </Grid.Column>
          </Grid>
        </div>
      )}
    </span>
  );

  const cart_products = (
    <CartProducts
      cartProducts={cart}
      userCart={userCart}
      removeFromCart={removeFromCart}
      addQuantityToCart={patchQuantity}
      addLengthToCart={updateLength}
      updateDescriptionOfProduct={updateDescription}
      setProductIsSelected={setProductIsSelected}
      options={true}
      isLoading={isLoading}
      updatingStockCode={updatingStockCode}
      showPrice={showPrice}
      {...props}
    />
  );

  const zeroPriceJSX = (
    <Modal open={zeroPriceModal} onClose={handleClose} basic size="small">
      <Header> Product info : {stockCode} </Header>
      <Modal.Content>
        <h3>Call BOA on 0800 20 20 20 for this item</h3>
      </Modal.Content>
      <Modal.Actions>
        <Button
          style={{
            backgroundColor: "#66cc23",
            color: "#fff",
          }}
          onClick={handleClose}
          inverted
        >
          <Icon name="checkmark" /> Ok
        </Button>
      </Modal.Actions>
    </Modal>
  );

  return (
    <div className="cart">
      <Modal size="mini" open={noItemsSelected}>
        <Modal.Content>
          <div style={{ textAlign: "left" }}>
            <p>Select items you want to order</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => {
              setNoItemsSelected(false);
            }}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="tiny" open={hasLessQuantityThanRoll}>
        <Modal.Content>
          <div style={{ textAlign: "left" }}>
            <p>
              Quantity of{" "}
              {itemWithLessThanRoll.map((item, index) => (
                <>
                  {item.barcode}
                  {index !== itemWithLessThanRoll.length - 1 && ", "}
                </>
              ))}{" "}
              is less than a full roll.
            </p>
          </div>
          <div style={{ textAlign: "left" }}>
            <p>Please adjust the quantity if you would like a full roll.</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            style={{
              color: "#fff",
            }}
            onClick={() => {
              setHasLessQuantityThanRoll(false);
            }}
          >
            Back
          </Button>
          <Button
            style={{
              backgroundColor: "#66cc23",
              color: "#fff",
            }}
            onClick={() => {
              onConfirmOrder();
            }}
          >
            Continue
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={hasError}>
        <Modal.Content>
          <div style={{ textAlign: "left", marginBottom: 10 }}>
            <p style={{ color: "red" }}>Error</p>
          </div>
          <div style={{ textAlign: "left" }}>
            <p>{errorMessage}</p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => {
              setHasError(false);
              props.actionLoadCart();
            }}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={hasInfo}>
        <Modal.Content>
          <div style={{ textAlign: "left", marginBottom: 10 }}>
            <p style={{ color: "red" }}>Info</p>
          </div>
          <div style={{ textAlign: "left" }}>
            <p>
              The stock location of your user has been changed. This order will
              be placed to {localStorage.getItem("stockLocationName")}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => {
              props.history.push("/orderConfirmation");
            }}
          >
            OK
          </Button>
        </Modal.Actions>
      </Modal>
      <Confirm
        open={state.confirmModal}
        onCancel={closeConfirmation}
        onConfirm={clearCart}
      />

      <MetaData title="Cart" />

      <h3>
        <Icon name="cart" /> Cart- {localStorage.getItem("stockLocationName")}
      </h3>

      <Divider />
      {zeroPriceJSX}
      {initialLoad && (
        <Loader text="Loading cart, please wait">Loading,please wait</Loader>
      )}
      {updatingQty && (
        <Loader text="Updating cart, please wait">Updating,please wait</Loader>
      )}
      {!initialLoad && (
        <Grid>
          <Grid.Column computer={10} mobile={16} tablet={16}>
            <Grid>
              <Grid.Column width={16}>{action_buttons}</Grid.Column>
              <Grid.Column width={16}>
                <QuickSearch addToCart={handleAddToCart} />
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column computer={6} mobile={16} tablet={16}>
            <UserRoleCheck userRole={userRole}>{order_details}</UserRoleCheck>
          </Grid.Column>
          <Grid.Column
            computer={6}
            mobile={16}
            tablet={16}
            style={{ paddingBottom: 0 }}
          >
            <div
              style={{
                marginLeft: 26,
              }}
            >
              <Checkbox
                checked={cart.every((item) => item.isSelected === true)}
                label={
                  cart.some((item) => item.isSelected === true)
                    ? "Unselect all"
                    : "Select all"
                }
                onChange={() =>
                  setSelectAllProducts(
                    cart.some((item) => item.isSelected === true) ? false : true
                  )
                }
              />
            </div>
          </Grid.Column>
          <Grid.Column computer={16} mobile={16} tablet={16}>
            {cart_products}
          </Grid.Column>
        </Grid>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    userCart: state.userCart,
    userInfo: state.auth.user,
    accountInfo: state.orderConfirmation.accountInfo,
    overallDiscount: state.orderConfirmation.overallDiscount,
  };
};

const reducer = {
  ...cartReducer,
  ...userCartReducer,
  ...orderConfirmationReducer,
  ...authReducer,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Cart);
