import React from "react";
import { Grid, Segment, Divider } from "semantic-ui-react";
import HoseInfo from "./HoseInformation";
import PropTypes from "prop-types";
import RightFittingInfo from "./RightFittingInfo";
import LeftFittingInfo from "./LeftFittingInfo";
import SpiralInfo from "./SpiralInfo";
import FerruleInfo from "./FerruleInfo";
import _ from "lodash";
import "./selectedHose.css";

const SelectedHoseInfo = ({
  hoseType = {},
  leftFitting = null,
  rightFitting = null,
  clearHose,
  removeFitting,
  hoseLength = 1,
  leftAngle,
  rightAngle,
  spiralGuard = null,
  removeSpiralGuard,
  leftFerrule = {},
  rightFerrule = {},
  removeFerrule
}) => {
  return (
    <React.Fragment>
      <h4>
        Hose details <Divider />
      </h4>
      <Grid className="hose-summary">
        <Grid.Column width={16}>
          <Segment.Group>
            <HoseInfo
              hoseInfo={hoseType}
              hoseLength={hoseLength}
              clearHose={clearHose}
            />
            {leftFitting && (
              <LeftFittingInfo
                removeFitting={removeFitting}
                leftFittingInfo={leftFitting}
                angle={leftAngle}
              />
            )}
            {!_.isEmpty(leftFerrule) &&
              <FerruleInfo
                heading="Left ferrule"
                ferruleInfo={leftFerrule}
                type="left"
                removeFerrule={() => removeFerrule("left")}
            />
            }
            {rightFitting && (
              <RightFittingInfo
                removeFitting={removeFitting}
                rightFittingInfo={rightFitting}
                angle={rightAngle}
              />
            )}
            {!_.isEmpty(rightFerrule) &&
              <FerruleInfo
              heading="Right ferrule"
              ferruleInfo={rightFerrule}
              type="right"
              removeFerrule={() => removeFerrule("right")}
            />
            }
            {!_.isEmpty(spiralGuard) && (
              <SpiralInfo
                spiralInfo={spiralGuard}
                removeSpiral={removeSpiralGuard}
                hoseLength={hoseLength}
              />
            )}
          </Segment.Group>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

SelectedHoseInfo.propTypes = {
  hoseType: PropTypes.object,
  leftFitting: PropTypes.object,
  rightFitting: PropTypes.object,
  clearHose: PropTypes.func,
  removeFitting: PropTypes.func,
  hoseLength: PropTypes.number,
  rightAngle: PropTypes.number,
  leftAngle: PropTypes.number,
  spiralGuard: PropTypes.object,
  removeSpiralGuard: PropTypes.func,
  leftFerrule: PropTypes.object,
  rightFerrule: PropTypes.object,
  removeFerrule: PropTypes.func
};

export default SelectedHoseInfo;
