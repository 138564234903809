import React from 'react';
import { Modal,  Button,  List, Checkbox ,Search} from 'semantic-ui-react'
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as chatReducer from "./chat.reducer";


const polyglot = window.polyglot;


class AddRemovMemberModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            something: '',
            showModal: false,
            channelIcon: null,
            file: null,
            chatTitle: '',
            identity: 'nnn@mailinator.com',
            errorFlag: false,
            errorMessage: "",
            accessToken: '',
            isLoading:false,
            members:''
        }
    }
    closeModal = () => {
        this.setState({ showModal: false })
        this.props.actionResetForm("chatForm")

    }
    openModal = async () => {
        const {conversationDetails,chatForm} = this.props;
        this.setState({ showModal: true
           })
        const formData = {
            ...chatForm,
            participantsList:conversationDetails.chatDetails.participants


        }
     
        

       await this.props.actionSetFormValue(formData)
      
    }

 
    handleAddParticipants = async () => {
        this.setState({isLoading:true})
        const { chatForm } = this.props.forms;
        const { conversationDetails } = this.props;

        const formData = {
            ...chatForm,
            conversationId:conversationDetails.sid
        }

        await this.props.actionUpdateParticipants(formData);
        this.props.getConversationDetails(conversationDetails.sid)

       // const { response } = this.props;
       
        // if (response) {
        //   if (response.status === 424) {
        //     this.updateState({
        //       errorFlag: true,
        //       errorMessage: "Something went wrong, please try it again"
        //     });
        //   }
      
        //   if (response.status === 403) {
        //     this.updateState({
        //       errorFlag: true,
        //       errorMessage: "Forbidden"
        //     });
        //   }
     
        this.setState({isLoading:true})
        // if (response.status === 200) {
        //     // window.scrollTo(0, 0);
        //     // this.updateState({
        //     //   success: true
        //     // });
        //     showSuccessToast("New Conversation created successfully!!")
        // }
      
        // // }

       
        this.props.actionResetForm("chatForm")
        this.closeModal();

    };
 

    checkedUsers = (value,name) => {


        let { participantsList } = this.props.chatForm;
        let newParticipantsList = [...participantsList];
        let isAvailable = _.includes(participantsList, value);
        if (isAvailable) {
            _.remove(newParticipantsList, n => value === n);
        } else {
            newParticipantsList.push(value);
        }
        this.props.actionSetFormField("participantsList", newParticipantsList);
      

        // console.log("Checked user list :" + participantsList)
    };


    render() {
        const {

            showModal,
            isLoading

        } = this.state

        const { chatUserList, chatForm,operation} = this.props;
        const { participantsList } = chatForm;

        let userList = [];
        if (chatUserList && chatUserList.length) {
            _.map(chatUserList, user => {
                const { firstName, email, _id } = user;
                userList.push({ name: firstName, value: email, id: _id });
            });
        }
        const filterdUsers = [...userList];
        let selectedUsers = filterdUsers.filter((filterdUsers) => participantsList.includes(filterdUsers.value))

       // const {  onDeleteUser, forms } = this.props;
        return (
            <div>
                {
                    operation==="add"?  <a href="#" className="addmember_link" onClick={this.openModal}>
                    <span className="addmember_icon"><i class="boa ba-plus"></i></span>
                    <span className="addmember_txt">Add New Members</span>
                </a>
                 :  <text onClick={this.openModal}>Remove Member</text>   
                }
                
                <Modal className="custom_modal" closeIcon size='tiny' onClose={this.closeModal} open={showModal} >
                    <Modal.Header>{operation==="add"?"Add New Members":"Remove Member"}</Modal.Header>
                    <Modal.Content >
                        <Modal.Description>
                            <div className="chat_search_wrapper">
                                <div className="chat_innersearch">
                                    <Search />
                                    <div className="filter_warp">
                                        <a href="#" className="filter_btn" onClick={this.openModal} > <i className="boa ba-filter"></i></a>
                                        <span className="filter_on"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="filter_options">
                                
                                {_.map(selectedUsers, user => {
                                   return <List key={user.id}><List.Item  className="chips_item">
                                        <div className="chip_uesr_info">
                                            <div className="chip_user_img">PV</div>
                                            <div className="chip_user_name">{user.name}</div>
                                        </div>
                                        <div className="chip_action">
                                            <a href="#" className="close_btn"> <i className="boa ba-close_circle" onClick={() => this.checkedUsers(user.value,user.name)}></i></a>
                                        </div>
                                    </List.Item> </List>
                                      })}
                               
                               
                            </div>

                            <div className="addmembermodal_list">
                                <List>
                                    {_.map(userList, users => {
                                        const { name, value, id } = users;
                                        return <List.Item key={id}>
                                            <div className="userlist_wrap">
                                                <div className="contact_img">
                                                    <div className="contact_img_txt">
                                                        HS
                                                {/* <i className="boa ba-user_group"></i> */}
                                                    </div>
                                                </div>
                                                <div className="meta">
                                                    <div className="name">
                                                        <div className="username_info">
                                                            <div className="usertext"> {name}</div>
                                                            <div className="usersubtext">Lorem Ipsum is simply dummy text</div>
                                                        </div>
                                                        <div className="custom_checkbox">
                                                            <Checkbox
                                                                onChange={() => this.checkedUsers(value,name)}
                                                                value={value}
                                                                checked={_.includes(participantsList, value)}

                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </List.Item>
                                    })}
                                </List>

                            </div>



                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions className="modal_footer">

                        <Button loading={isLoading} color='green' onClick={this.handleAddParticipants}>Done </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}


const reducer = { ...chatReducer };


const mapStateToProps = state => {
    return {
        chatGroups: state.chatGroups,
        response: state.chatGroups.response,
        forms: state.forms,
        chatUserList: state.chatGroups.chatuserList.data,
        chatForm: state.forms.chatForm,
        conversationDetails: state.chatGroups.conversationDetails.data,


    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(reducer, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddRemovMemberModal)
);