import React from "react";
import PropTypes from "prop-types";
import HoseDetailsCard from "./HoseDetailsCard";

const FilteredHoseResult = ({result =[], onBoarSelect, isLoading}) => {
    return (
        <React.Fragment>
            {result.length > 0 && <HoseDetailsCard hoseResult={result} onBoarSelect={onBoarSelect} />}
        </React.Fragment>
    )
};
FilteredHoseResult.propTypes = {
    result: PropTypes.array,
    onBoarSelect: PropTypes.func,
    isLoading: PropTypes.bool
}

export default FilteredHoseResult;