import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { Button, Dimmer, Form, Grid, Loader, Modal } from "semantic-ui-react";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  apiDeleteTrainingCertificate,
  apiUpdateTrainingDates,
  apiUploadTrainingCertificate,
} from "../settings.api";
import FileViewer from "react-file-viewer";
import { getFileExtension } from "../../../utils/utils";

export default function TrainingCertificate(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [trainingDate, setTrainingDate] = useState(null);
  const [retrainingDate, setRetrainingDate] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [deleteFileOpen, setDeleteFileOpen] = useState(false);

  const [key, setKey] = useState(0);
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setIsLoading(true);
      setImageData(null);
      const delayDebounceFn = setTimeout(() => {
        setImageData(file);
        setKey(key + 1);
        setIsLoading(false);
      }, 1000);

      return () => clearTimeout(delayDebounceFn);
    }
  };

  const onUpdate = async () => {
    setIsUpdate(true);
    setImageData(null);
    setImageSrc(null);
  };

  const onUpdateCertificate = async () => {
    const stockData = await apiUpdateTrainingDates(
      trainingDate,
      retrainingDate,
      props.userData.id
    );
    if (stockData) {
      return true;
    } else {
      showErrorToast("Something went wrong");
    }
  };

  const onUploadCertificate = async () => {
    setIsLoading(true);

    if (imageData) {
      const logo = await apiUploadTrainingCertificate(
        imageData,
        props.userData.id
      );
      if (logo) {
        const datesUpdated = await onUpdateCertificate();
        if (datesUpdated) {
          showSuccessToast(
            <div>
              <i className="check icon" /> Training Certificate Updated
              Successfully
            </div>
          );
          const delayDebounceFn = setTimeout(() => {
            setIsUpdate(false);
            props.getMyUsersData();
            setIsLoading(false);
            setImageData(null);
            setImageSrc(null);
            props.setViewTrainingCertificate(false);
          }, 500);

          return () => clearTimeout(delayDebounceFn);
        }
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } else {
      const datesUpdated = await onUpdateCertificate();
      if (datesUpdated) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Training Certificate Updated
            Successfully
          </div>
        );
        const delayDebounceFn = setTimeout(() => {
          setIsUpdate(false);
          props.getMyUsersData();
          setIsLoading(false);
          setImageData(null);
          setImageSrc(null);
          props.setViewTrainingCertificate(false);
        }, 500);

        return () => clearTimeout(delayDebounceFn);
      }
    }
  };

  const onDeleteTrainingCertificate = async () => {
    setIsLoading(true);
    try {
      const logo = await apiDeleteTrainingCertificate(props.userData.id);
      if (logo) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Training Certificate Deleted
            Successfully
          </div>
        );
        props.getMyUsersData();
        setIsLoading(false);
        setImageData(null);
        setImageSrc(null);
        setDeleteFileOpen(false);
        props.setViewTrainingCertificate(false);
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const handleDownloadFile = (filePath) => {
    if (!filePath) {
      showErrorToast("Save asset first before downloading this file");
      return false;
    }
    try {
      const parts = filePath.split("/");
      const fileName = parts[parts.length - 1];
      fetch(filePath)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } catch {
      showErrorToast("Something went wrong");
    }
  };

  const handleDownloadLocal = (file) => {
    // Create a URL for the file
    const url = URL.createObjectURL(file);

    // Create a temporary anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = file.name; // Set the file name for download

    // Append the anchor to the body
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download
    a.click();

    // Remove the anchor from the document
    document.body.removeChild(a);

    // Revoke the object URL to free up resources
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (props.userData) {
      setTrainingDate(
        props.userData?.trainDate ? new Date(props.userData?.trainDate) : null
      );
      setRetrainingDate(
        props.userData?.reTrainDate
          ? new Date(props.userData?.reTrainDate)
          : null
      );
    }
  }, [props.userData]);

  return (
    <Modal
      open={true}
      onClose={() => props.setViewTrainingCertificate(false)}
      size="small"
      closeOnDimmerClick={false}
      closeIcon
    >
      <Modal.Header>
        <div style={{ paddingTop: 10, paddingBottom: 5 }}>
          <span style={{ fontSize: 24, fontWeight: "normal" }}>
            {props.userData?.rs23TrainingCertificate?.id && !isUpdate
              ? "VIEW TRAINING CERTIFICATE"
              : isUpdate
              ? "UPDATE TRAINING CERTIFICATE"
              : "UPLOAD TRAINING CERTIFICATE"}
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal size="mini" open={deleteFileOpen}>
          <div style={{ position: "absolute", bottom: 100, left: 150 }}>
            {isLoading ? (
              <Loader
                active
                className="workaround"
                size="large"
                inline="centered"
              />
            ) : null}
          </div>
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content
            style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
          >
            <p>
              Are you sure you want <br /> to delete training certificate?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="youtube" onClick={() => setDeleteFileOpen(false)}>
              NO
            </Button>
            <Button
              style={{
                backgroundColor: "rgb(102, 204, 35)",
                color: "#fff",
              }}
              onClick={() => onDeleteTrainingCertificate()}
            >
              YES
            </Button>
          </Modal.Actions>
        </Modal>
        {isLoading || props.isLoading ? (
          <Dimmer active inverted>
            <Loader size="large"></Loader>
          </Dimmer>
        ) : null}
        <div
          className="job-list-scroll"
          style={{ minHeight: 450, paddingRight: 0, color: "#6d6d6d" }}
        >
          <Form>
            <Grid style={{ marginBottom: 0 }}>
              <Grid.Column width={16} style={{ paddingBottom: 10 }}>
                <div
                  style={{
                    fontSize: 20,
                    color: "#6D6D6D",
                    textAlign: "center",
                  }}
                >
                  <span>{props.userData.email}</span>
                </div>
              </Grid.Column>
              <Grid.Column width={8}>
                <Form.Field>
                  <label>Training Date</label>
                  <DatePicker
                    dateFormat="dd/MM/yy"
                    selected={trainingDate}
                    onChange={(date) => setTrainingDate(date)}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={8}>
                <Form.Field>
                  <label>Retraining Date</label>
                  <DatePicker
                    dateFormat="dd/MM/yy"
                    selected={retrainingDate}
                    onChange={(date) => setRetrainingDate(date)}
                  />
                </Form.Field>
              </Grid.Column>
              <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                <div
                  className="image-viewer"
                  style={{
                    position: "relative",
                    marginLeft: "auto",
                    marginRight: "auto",
                    textAlign: "center",
                    borderStyle: imageSrc ? "solid" : "dashed",
                  }}
                >
                  {imageData ? (
                    <div
                      onClick={() =>
                        handleDownloadFile(URL.createObjectURL(imageData))
                      }
                      style={{ height: "100%" }}
                    >
                      <FileViewer
                        style={{ margin: "auto" }}
                        fileType={getFileExtension(imageData.name)}
                        filePath={URL.createObjectURL(imageData)}
                      />
                    </div>
                  ) : props.userData?.rs23TrainingCertificate?.id &&
                    !isUpdate ? (
                    <div
                      onClick={() =>
                        handleDownloadFile(
                          props.userData?.rs23TrainingCertificate?.filePath
                        )
                      }
                      style={{ height: "100%" }}
                    >
                      <FileViewer
                        style={{ margin: "auto" }}
                        fileType={getFileExtension(
                          props.userData?.rs23TrainingCertificate
                            ?.fileDisplayName
                        )}
                        filePath={
                          props.userData?.rs23TrainingCertificate?.filePath
                        }
                      />
                    </div>
                  ) : (
                    <div style={{ paddingTop: 190 }}>
                      <span style={{ fontSize: 30, color: "#000" }}>
                        PREVIEW
                      </span>
                    </div>
                  )}
                </div>
              </Grid.Column>
              <Grid.Column width={8}>
                <div style={{ marginTop: 10 }}>
                  {props.userData?.rs23TrainingCertificate?.id && !isUpdate ? (
                    <Button
                      color="red"
                      size="mini"
                      style={{
                        fontSize: 20,
                        padding: 15,
                        width: "100%",
                      }}
                      onClick={() => setDeleteFileOpen(true)}
                      loading={isLoading}
                    >
                      Delete
                    </Button>
                  ) : (
                    <>
                      <Button
                        color="orange"
                        size="mini"
                        style={{
                          fontSize: 20,
                          padding: 15,
                          backgroundColor: "#FFAD00",
                          width: "100%",
                        }}
                        onClick={handleButtonClick}
                      >
                        {imageData ? "Re-upload" : "Upload"}
                      </Button>
                      <input
                        key={key}
                        ref={fileInputRef}
                        type="file"
                        hidden
                        onChange={handleFileChange}
                      />
                    </>
                  )}
                </div>
              </Grid.Column>
              <Grid.Column width={8}>
                <div style={{ marginTop: 10 }}>
                  <Button
                    size="mini"
                    style={{
                      backgroundColor: "#66cc23",
                      color: "#fff",
                      fontSize: 20,
                      padding: 15,
                      width: "100%",
                    }}
                    onClick={() => onUploadCertificate()}
                    loading={isLoading}
                  >
                    Save
                  </Button>
                </div>
              </Grid.Column>
              <Grid.Column
                width={16}
                style={{ textAlign: "center", padding: 0 }}
              >
                {props.userData?.rs23TrainingCertificate?.id && !isUpdate ? (
                  <Button
                    size="mini"
                    style={{
                      backgroundColor: "#FFAD00",
                      color: "#fff",
                      fontSize: 20,
                      padding: 15,
                      width: "48%",
                    }}
                    onClick={() => onUpdate()}
                    loading={isLoading}
                  >
                    Update Certificate
                  </Button>
                ) : null}
              </Grid.Column>
            </Grid>
          </Form>
        </div>
      </Modal.Content>
    </Modal>
  );
}
