import axios from "axios";
//import { INVENTORY, REVIEW_STOCK } from "../../constants/apiLink";
import _ from "lodash";
import {
  INVENTORY,
  REVIEW_STOCK,
  PRODUCTS,
  AUTOCOMPLETE
} from "../../constants/apiLink";

export const apiUpdateStock = async data => {
  const url = INVENTORY + REVIEW_STOCK;

  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiAddStock = async data => {
  const url = INVENTORY;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiGetStock = async (location, limit, pageNumber) => {
  let url = INVENTORY + REVIEW_STOCK + "?location=" + location;

  if (limit) {
    url = url + "&limit=" + limit;
  }
  if (pageNumber) {
    url = url + "&pageNumber=" + pageNumber;
  }

  return axios.get(url).then(result => {
    return result;
  });
};

export const apiBoaStockAutoComplete = async (searchTerm, level = null) => {
  let url = PRODUCTS + AUTOCOMPLETE + `?searchTerm=${searchTerm}`;
  if (level) {
    level = _.replace(level, "&", "$and$");
    url = `${url}&level=${level}`;
  }

  return await axios.get(url);
};
