import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Card,
  Container,
  Form,
  Grid,
  Icon,
  Input,
  Image,
  Loader,
  Button,
  Modal,
  Select,
  TextArea,
} from "semantic-ui-react";
import "./index.css";
import {
  apiUpdateStockAdjustment,
  apiCreateStockAdjustment,
  apiDeleteStockAdjustment,
  apiGetStockAdjustmentDetails,
  apiGetStockAdjustments,
} from "./stockAdjustment.api";
import MetaData from "../../../components/MetaData";
import { useDraggable } from "react-use-draggable-scroll";
import _debounce from "lodash/debounce";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import { apiGetStockLocationList } from "../../Settings/settings.api";
import cartReducer from "../../Cart/cart.reducer";
import searchReducer from "../../Search/search.reducer";
import inventoryIcon from "../../../assets/images/pages/inventory.png";
import { adjustmentStatusList, adjustmentStatusListOperator } from "./data";
import { formatDateTime, NumericInputChecker } from "../../../utils/utils";
import productDefaultImage from "../../../assets/images/product_default.png";
import QuickSearch from "../../../components/QuickSearch";
import { apiGetStockProducts } from "../inventoryManagement.api";

const StockAdjustment = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [adjustmentsList, setAdjustmentsList] = useState([]);
  const [stocklocationList, setStocklocationList] = useState([]);
  const [formData, setFormData] = useState({ statusId: 0 });
  const [status, setStatus] = useState("In Progress");
  const [productList, setProductList] = useState([]);
  const [stockFormData, setStockFormData] = useState({});
  const [deleteStockAdjustmentOpen, setDeleteStockAdjustmentOpen] =
    useState(false);
  const [discardStockAdjustmentOpen, setDiscardStockAdjustmentOpen] =
    useState(false);

  const ref = useRef();
  const { events } = useDraggable(ref);

  function handleChange(dataType, value) {
    let newState = [];
    newState.push(formData);
    let data = newState.map((item, i) => {
      if (i == 0) {
        if (dataType === "stockLocationTo") {
          const stockLocation = stocklocationList.find(
            (item) => item.id === value
          );
          item.stockLocationToName = stockLocation?.stockLocationName;
        }
        return { ...item, [dataType]: value };
      }
      return item;
    });

    setFormData(data[0]);
  }

  const getStockLocationName = (id) => {
    const stockLocation = stocklocationList.find((data) => data?.id === id);
    if (stockLocation) {
      return stockLocation.stockLocationName;
    }
    return "";
  };

  const getStockLocationsData = async () => {
    const result = await apiGetStockLocationList();
    if (result) {
      setStocklocationList(result);
    } else {
      setStocklocationList([]);
    }
  };

  const getAdjustmentsList = async () => {
    setSearchLoading(true);
    const result = await apiGetStockAdjustments(status);
    if (result) {
      setAdjustmentsList(result);
      setSearchLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setSearchLoading(false);
    }
  };

  const getAdjustmentDetails = async (id) => {
    if (!id) return false;
    setIsLoading(true);
    const result = await apiGetStockAdjustmentDetails(id);
    if (result) {
      result.map((product) => {
        product.initialSignedMoveQty = Number(product.signedMoveQty);
        product.initialAvailableQty =
          product.signedMoveQty <= 0
            ? Number(product.availableQty)
            : Number(product.availableQty) + Number(product.signedMoveQty);
        product.adjustedAvailableQty = null;
      });
      setProductList(result);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onDeleteStockAdjustment = async () => {
    setIsLoading(true);
    const stockData = await apiDeleteStockAdjustment(selectedId);
    if (stockData) {
      showSuccessToast(
        <div>
          <i className="check icon" /> Stock Adjustment Deleted Successfully
        </div>
      );
      setTimeout(function () {
        setProductList([]);
        setFormData({ statusId: 0, adjustmentReason: "" });
        setDeleteStockAdjustmentOpen(false);
        getAdjustmentsList();
        setIsLoading(false);
      }, 300);
    } else {
      showErrorToast("Something went wrong");
      setDeleteStockAdjustmentOpen(false);
      setIsLoading(false);
    }
  };

  const onSaveStockAdjustment = async (isComplete = false) => {
    setIsLoading(true);
    const result = await apiCreateStockAdjustment(
      formData,
      productList,
      isComplete
    );
    if (result) {
      showSuccessToast(
        <div>
          <i className="check icon" /> Stock Adjustment saved successfully
        </div>
      );
      setTimeout(function () {
        getAdjustmentsList();
        setFormData({ statusId: 0, adjustmentReason: "" });
        setSelectedId(null);
        setIsLoading(false);
      }, 300);
    } else {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const onUpdateStockAdjustment = async (isComplete = false) => {
    setIsLoading(true);
    const result = await apiUpdateStockAdjustment(
      formData,
      productList,
      isComplete
    );
    if (result) {
      showSuccessToast(
        <div>
          <i className="check icon" /> Stock Adjustment completed successfully
        </div>
      );
      setTimeout(function () {
        getAdjustmentsList();
        setFormData({ statusId: 0, adjustmentReason: "" });
        setSelectedId(null);
        setIsLoading(false);
      }, 300);
    } else {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  useEffect(() => {
    if (selectedId !== 0) {
      getAdjustmentDetails();
    } else {
      setStockFormData({});
    }
  }, [selectedId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getStockLocationsData();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAdjustmentsList();
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [status]);

  useEffect(() => {
    if (selectedId) {
      getAdjustmentDetails(selectedId);
    } else {
      handleChange("adjustmentReason", "");
    }
  }, [selectedId]);

  function handleQuantityChange(value, index, uomId) {
    let newState = productList;

    if (value === "remove") {
      // Remove product in list
      let productsData = newState.map((item, i) => {
        return item;
      });
      productsData.splice(index, 1);
      setProductList(productsData);
      return false;
    }

    value = Number(value);

    // Check if uom is not Metre and prevent decimal value
    if (uomId !== 8 && Number(value) === value && value % 1 !== 0) {
      value = Math.trunc(value);
    }

    // Update product Adjusted Quantity
    let productsData = newState.map((item, i) => {
      if (i === index) {
        item.quantity = value;
        item.signedMoveQty = value;
        item.adjustedAvailableQty =
          item.initialSignedMoveQty <= 0
            ? item.availableQty + value - item.initialSignedMoveQty
            : item.availableQty + value;
      }
      return item;
    });
    setProductList(productsData);
  }

  const getStockProductsData = async (textSearch = "") => {
    const result = await apiGetStockProducts(
      localStorage.getItem("selectedStockLocationId"),
      textSearch
    );
    if (result) {
      return result.records[0];
    } else {
      showErrorToast("Something went wrong");
    }
  };

  const addProduct = async (product) => {
    if (!product) return false;
    let productData = {};
    const stockProductData = await getStockProductsData(product.barcode);
    if (stockProductData) {
      productData = {
        id: product.id,
        productId: product.id,
        moveQty: 0,
        stockCode: product.barcode,
        productName: product.name,
        image128: product.image128,
        name: product.name,
        barCode: product.barcode,
        uomData: product.uom,
        availableQty: stockProductData?.inventoryData?.available
          ? stockProductData?.inventoryData?.available
          : 0,
        adjustedAvailableQty: stockProductData?.inventoryData?.available
          ? stockProductData?.inventoryData?.available
          : 0,
        signedMoveQty: 0,
      };
    }

    let newState = productList;
    const existing = newState.find(
      (element) => element.productId === product.id
    );

    if (!existing) {
      newState.unshift(productData);
    } else {
      let invComponentsNewState = newState.map((item, i) => {
        if (item.productId === product.id) {
          item.moveQty += 1;
        }
        return item;
      });
      setProductList([...invComponentsNewState]);
      return true;
    }

    // Sort inventory components AFTER adding product
    newState.sort(function (a, b) {
      return new Date(b.barCode) - new Date(a.barCode);
    });

    setProductList([...newState]);
  };

  const Quantity = ({ pack, quantity, index, uomId }) => {
    // const isEach = pack == "Each";
    const isMeter = pack === "Metre" || pack === "Feet";

    const quantityMessage = isMeter ? pack : "Quantity";

    let newQuantity = uomId === 8 ? parseFloat(quantity) : parseInt(quantity);

    return (
      <div className="quantity-form">
        <Grid columns="equal">
          <Grid.Column width={16}>
            <Form.Field>
              <div style={{ marginBottom: 10 }}>
                <Grid style={{ margin: 0, padding: 0 }}>
                  <Grid.Column width={14} style={{ margin: 0, padding: 0 }}>
                    <Input
                      key={index}
                      type="text"
                      defaultValue={
                        uomId === 8 ? newQuantity.toFixed(1) : newQuantity
                      }
                      onBlur={(e) =>
                        handleQuantityChange(e.target.value, index, uomId)
                      }
                      onKeyDown={NumericInputChecker}
                      input={{
                        style: {
                          textAlign: "center",
                          color:
                            newQuantity > 0
                              ? "#66CC23"
                              : newQuantity < 0
                              ? "#FF1414"
                              : "#000",
                        },
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={2} style={{ margin: 0, padding: 0 }}>
                    <Container className="vertical-buttons">
                      <Button
                        style={{
                          fontSize: 15,
                          margin: 0,
                          padding: 3.3,
                          borderRadius: 0,
                        }}
                        icon="angle up"
                        onClick={() =>
                          handleQuantityChange(newQuantity + 1, index, uomId)
                        }
                      />
                      <Button
                        style={{
                          fontSize: 15,
                          margin: 0,
                          padding: 3.3,
                          borderRadius: 0,
                        }}
                        icon="angle down"
                        onClick={() =>
                          handleQuantityChange(newQuantity - 1, index, uomId)
                        }
                      />
                    </Container>
                  </Grid.Column>
                </Grid>
              </div>
            </Form.Field>
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  return (
    <React.Fragment>
      <MetaData title="Stock Adjustments" />
      <Modal size="mini" open={deleteStockAdjustmentOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure <br /> you want to delete {formData?.moveName}
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDeleteStockAdjustmentOpen(false)}>
            NO
          </Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onDeleteStockAdjustment()}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={discardStockAdjustmentOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure <br /> you want to discard the changes?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDiscardStockAdjustmentOpen(false)}>
            NO
          </Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => {
              setFormData({ statusId: 0, adjustmentReason: "" });
              setProductList([]);
              setSelectedId(null);
              setDiscardStockAdjustmentOpen(false);
            }}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid columns={3} className="stock-adjustment" style={{ marginTop: -30 }}>
        <Grid.Row style={{ paddingTop: 5, zIndex: 1 }}>
          <Grid.Column width={4} style={{ paddingRight: 0 }}>
            <h3 style={{ marginLeft: 20 }}>
              <img
                src={inventoryIcon}
                style={{
                  width: 30,
                  height: 30,
                  marginTop: -5,
                  marginRight: 8,
                }}
              />{" "}
              Stock Adjustments
            </h3>
          </Grid.Column>
          <Grid.Column width={4}>
            <div className="job-actions">
              <Button
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  minWidth: 100,
                }}
                size="small"
                onClick={() => props.history.goBack()}
              >
                <Icon name="arrow left" /> Back to Inventory Management
              </Button>
            </div>
          </Grid.Column>
          <Grid.Column width={8}>
            <div style={{ marginLeft: -50, paddingTop: 5 }}>
              <h3>{localStorage.getItem("selectedStockLocationName")}</h3>
            </div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }}>
          <Grid.Column
            width={4}
            className="adjustment-list-section"
            style={{ paddingRight: 0 }}
          >
            <hr />
            <div
              className="adjustment-status"
              style={{ paddingRight: "0px !important" }}
            >
              <Grid columns={2}>
                <Grid.Row>
                  <Grid.Column
                    width={16}
                    style={{ paddingTop: 0, paddingBottom: 10 }}
                  >
                    <div>
                      <Button
                        className="active-status"
                        size="small"
                        style={{
                          width: "100%",
                          padding: 11,
                        }}
                        onClick={() => {
                          setFormData({ statusId: 0, adjustmentReason: "" });
                          setProductList([]);
                          setSelectedId(0);
                        }}
                      >
                        New Adjustment
                      </Button>
                    </div>
                    <div>
                      <Select
                        style={{
                          fontSize: 13,
                          width: "100%",
                          marginTop: 10,
                        }}
                        options={
                          localStorage.getItem("userType") === "4"
                            ? adjustmentStatusListOperator
                            : adjustmentStatusList
                        }
                        value={status}
                        onChange={(e, data) => setStatus(data.value)}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </div>
            <div className="adjustment-list">
              <div style={{ position: "absolute", top: 300, left: 80 }}>
                {searchLoading ? (
                  <Loader
                    active
                    className="workaround"
                    size="large"
                    inline="centered"
                  >
                    Loading Stock Adjustments...
                  </Loader>
                ) : null}
              </div>
              <div
                {...events}
                ref={ref}
                className="inventory-list-scroll"
                disabled={searchLoading}
                style={{ opacity: searchLoading ? 0.2 : 1, paddingTop: 5 }}
              >
                {adjustmentsList.length === 0 && (
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: 100,
                      paddingRight: 20,
                      color: "#AFABAB",
                      fontSize: 19,
                    }}
                  >
                    <span>No adjustments found</span>
                  </div>
                )}
                {adjustmentsList?.map((data, index) => {
                  // if (localStorage.getItem("userType") === "4" && index !== 0) {
                  //   return null;
                  // }
                  return (
                    <Card
                      className={
                        selectedId === data?.id ? "job-card active" : "job-card"
                      }
                      style={{
                        cursor: "default",
                        minHeight: 126,
                        minWidth: 185,
                        borderRadius: 10,
                        marginBottom: "0px !important",
                        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3)",
                        position: "relative", // Ensure relative positioning
                        cursor: "pointer",
                      }}
                    >
                      <Card.Content
                        style={{
                          minHeight: 60,
                          padding: 10,
                        }}
                        onClick={() => {
                          if (selectedId !== data?.id) {
                            setSelectedId(data?.id);
                            setFormData(data);
                          } else {
                            setSelectedId(null);
                            setProductList([]);
                            setFormData(null);
                          }
                        }}
                      >
                        <Grid style={{ paddingBottom: 15 }}>
                          <Grid.Column
                            width={16}
                            style={{
                              paddingTop: 15,
                              paddingBottom: 5,
                              paddingLeft: 20,
                              paddingRight: 0,
                            }}
                          >
                            <div>
                              <h5>{data?.moveName}</h5>
                            </div>
                            <div
                              style={{
                                paddingTop: 10,
                                paddingBottom: 10,
                                paddingLeft: 20,
                                fontSize: 13,
                              }}
                            >
                              <span>Reason: {data?.moveReason}</span>
                            </div>
                          </Grid.Column>
                          <Grid.Column
                            width={6}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              marginTop: -5,
                            }}
                          >
                            <span style={{ fontSize: 11, color: "#9F9F9F" }}>
                              Created By:
                            </span>
                          </Grid.Column>
                          <Grid.Column
                            width={10}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              marginTop: -5,
                              textAlign: "right",
                            }}
                          >
                            <span style={{ fontSize: 11, color: "#9F9F9F" }}>
                              {data?.createdBy}
                            </span>
                          </Grid.Column>
                          <Grid.Column
                            width={6}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              marginTop: -5,
                            }}
                          >
                            <span style={{ fontSize: 11, color: "#9F9F9F" }}>
                              Created Date:
                            </span>
                          </Grid.Column>
                          <Grid.Column
                            width={10}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              marginTop: -5,
                              textAlign: "right",
                            }}
                          >
                            <span style={{ fontSize: 11, color: "#9F9F9F" }}>
                              {formatDateTime(data?.createdDateTime, false)}
                            </span>
                          </Grid.Column>
                        </Grid>
                      </Card.Content>
                      {data?.statusId === 1 ||
                      data?.statusId === 99 ||
                      localStorage.getItem("userType") === "4" ? null : (
                        <div
                          style={{
                            position: "absolute",
                            top: 40,
                            right: 5,
                            fontSize: 13,
                          }}
                        >
                          <Icon
                            style={{ marginRight: 8, color: "red" }}
                            link
                            name="trash"
                            onClick={() => {
                              setSelectedId(data?.id);
                              setFormData(data);
                              setTimeout(() => {
                                setDeleteStockAdjustmentOpen(true);
                              }, 300);
                            }}
                          />
                        </div>
                      )}
                      {localStorage.getItem("userType") === "4" &&
                      data?.statusId === 0 ? (
                        <div
                          style={{
                            position: "absolute",
                            top: 40,
                            right: 5,
                            fontSize: 13,
                          }}
                        >
                          <Icon
                            style={{ marginRight: 8, color: "red" }}
                            link
                            name="trash"
                            onClick={() => {
                              setSelectedId(data?.id);
                              setFormData(data);
                              setTimeout(() => {
                                setDeleteStockAdjustmentOpen(true);
                              }, 300);
                            }}
                          />
                        </div>
                      ) : null}
                    </Card>
                  );
                })}
              </div>
            </div>
          </Grid.Column>
          <Grid.Column width={12} style={{ paddingRight: 0 }}>
            <hr />
            {selectedId === null ? null : (
              <div className="adjustment-form">
                <Grid style={{ paddingLeft: 9, paddingTop: 5 }}>
                  <Grid.Column width={6} style={{ paddingRight: 0 }}>
                    <div
                      style={{
                        paddingTop: 7.5,
                        fontSize: 16,
                        fontWeight: "bolder",
                      }}
                    >
                      {formData?.id ? (
                        <span>Stock Adjustment - {formData?.moveName}</span>
                      ) : (
                        <span>New Stock Adjustment</span>
                      )}
                    </div>
                  </Grid.Column>
                  <Grid.Column width={10}>
                    {!formData.statusId || formData.statusId === 0 ? (
                      <div
                        className="job-actions"
                        style={{ textAlign: "left" }}
                      >
                        {localStorage.getItem("userType") === "4" ? null : (
                          <Button
                            disabled={
                              productList.length === 0 ||
                              !formData.adjustmentReason
                            }
                            className="active-status"
                            style={{
                              minWidth: 100,
                              marginRight: 10,
                            }}
                            size="small"
                            onClick={() => {
                              if (formData?.id) {
                                onUpdateStockAdjustment(true);
                              } else {
                                onSaveStockAdjustment(true);
                              }
                            }}
                          >
                            Complete
                          </Button>
                        )}
                        <Button
                          disabled={
                            productList.length === 0 &&
                            !formData.adjustmentReason
                          }
                          className="default-status"
                          style={{
                            minWidth: 100,
                            marginRight: 10,
                          }}
                          size="small"
                          onClick={() => {
                            if (formData?.id) {
                              onUpdateStockAdjustment();
                            } else {
                              onSaveStockAdjustment();
                            }
                          }}
                        >
                          Save and close
                        </Button>
                        {formData?.adjustmentReason ||
                        productList.length > 0 ? (
                          <Button
                            color="youtube"
                            style={{
                              minWidth: 100,
                            }}
                            size="small"
                            onClick={() => setDiscardStockAdjustmentOpen(true)}
                          >
                            Discard
                          </Button>
                        ) : null}
                      </div>
                    ) : (
                      <div className="job-actions">
                        <Button
                          className="default-status"
                          style={{
                            minWidth: 100,
                            marginRight: 10,
                          }}
                          size="small"
                          onClick={() => {
                            setFormData({ statusId: 0, adjustmentReason: "" });
                            setSelectedId(null);
                            setProductList([]);
                          }}
                        >
                          Close
                        </Button>
                      </div>
                    )}
                  </Grid.Column>
                  {formData.statusId === 1 || formData.statusId === 99 ? (
                    <Grid.Column width={16}>
                      <Grid>
                        {formData.statusId === 1 && (
                          <Grid.Column
                            width={16}
                            style={{ paddingTop: 0, paddingBottom: 10 }}
                          >
                            <div style={{ color: "#9F9F9F", fontSize: 14 }}>
                              <span>Completed By: {formData.completedBy}</span>
                              <br />
                              <span>
                                Completed Date Time:{" "}
                                {formatDateTime(
                                  formData.completedDateTime,
                                  true
                                )}
                              </span>
                            </div>
                          </Grid.Column>
                        )}
                        {formData.statusId === 99 && (
                          <Grid.Column
                            width={16}
                            style={{ paddingTop: 0, paddingBottom: 10 }}
                          >
                            <div style={{ color: "#9F9F9F", fontSize: 14 }}>
                              <span>Deleted By: {formData.deletedBy}</span>
                              <br />
                              <span>
                                Deleted Date Time:{" "}
                                {formatDateTime(formData.deletedDateTime, true)}
                              </span>
                            </div>
                          </Grid.Column>
                        )}
                      </Grid>
                    </Grid.Column>
                  ) : null}
                  <Grid.Column
                    width={7}
                    style={{
                      textAlign: "left",
                      paddingTop: 5,
                      paddingBottom: 0,
                    }}
                  >
                    {formData.statusId === 0 && (
                      <QuickSearch addToCart={addProduct} />
                    )}
                  </Grid.Column>
                  <Grid.Column
                    width={9}
                    style={{
                      paddingTop: 5,
                      paddingBottom: 0,
                    }}
                  ></Grid.Column>
                  <Grid.Column width={16} style={{ zIndex: 10 }}>
                    <div>
                      <div
                        className="adjustment-form-content"
                        style={{
                          paddingRight: "0px !important",
                          opacity: dataLoading ? 0.5 : 1,
                        }}
                      >
                        <Grid
                          style={{
                            padding: 0,
                            paddingBottom: 0,
                          }}
                        >
                          <Grid.Column
                            width={15}
                            style={{ padding: 0, paddingTop: 25 }}
                          >
                            <Grid>
                              <Grid.Column
                                width={2}
                                style={{
                                  padding: 0,
                                  paddingLeft: 43,
                                  paddingBottom: 20,
                                }}
                              ></Grid.Column>
                              <Grid.Column
                                width={formData.statusId === 0 ? 8 : 11}
                              >
                                <span
                                  style={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                  }}
                                >
                                  Stock Code
                                </span>
                              </Grid.Column>
                              <Grid.Column
                                width={3}
                                style={{ textAlign: "center" }}
                              >
                                <span
                                  style={{
                                    fontSize: 16,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {formData.statusId === 0
                                    ? "Adjusted"
                                    : "Adjustment"}
                                </span>
                              </Grid.Column>
                              {formData.statusId === 0 && (
                                <Grid.Column
                                  width={3}
                                  style={{ textAlign: "center" }}
                                >
                                  <span
                                    style={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Available
                                  </span>
                                </Grid.Column>
                              )}
                            </Grid>
                            <hr style={{ marginRight: 0 }} />
                          </Grid.Column>
                          {productList?.map((card, index) => {
                            return (
                              <Grid.Column
                                width={15}
                                style={{ padding: 0, paddingTop: 25 }}
                                key={card.id}
                              >
                                <Grid>
                                  <Grid.Column
                                    width={2}
                                    style={{
                                      padding: 0,
                                      paddingLeft: 43,
                                      paddingBottom: 20,
                                    }}
                                  >
                                    <Image
                                      style={{
                                        width: 85,
                                        height: 85,
                                      }}
                                      src={
                                        card.image128
                                          ? `data:image/jpeg;base64,${card.image128}`
                                          : productDefaultImage
                                      }
                                    />
                                  </Grid.Column>
                                  <Grid.Column
                                    width={formData.statusId === 0 ? 8 : 11}
                                    style={{
                                      paddingTop: 5,
                                      paddingLeft: 20,
                                      paddingBottom: 20,
                                    }}
                                  >
                                    <div>
                                      <span
                                        style={{
                                          fontSize: 16,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {card.barCode}
                                      </span>
                                    </div>
                                    <div>
                                      <span
                                        style={{
                                          fontSize: 14,
                                          fontWeight: "bolder",
                                        }}
                                      >
                                        Stock Description:{" "}
                                      </span>
                                      <span
                                        style={{
                                          fontSize: 14,
                                        }}
                                      >
                                        {card?.productName}
                                      </span>
                                    </div>
                                    <div>
                                      {formData.statusId === 0 ||
                                      !formData.statusId ? (
                                        <span
                                          onClick={() =>
                                            handleQuantityChange(
                                              "remove",
                                              index,
                                              0
                                            )
                                          }
                                        >
                                          <span
                                            style={{
                                              color: "red",
                                              fontSize: 13,
                                              cursor: "pointer",
                                            }}
                                          >
                                            <Icon name="cancel" />
                                            Remove
                                          </span>
                                        </span>
                                      ) : null}
                                    </div>
                                  </Grid.Column>
                                  <Grid.Column width={3}>
                                    {formData.statusId === 0 ? (
                                      <Form
                                        model=""
                                        style={{ textAlign: "center" }}
                                      >
                                        <Quantity
                                          pack={null}
                                          quantity={card.signedMoveQty}
                                          index={index}
                                          uomId={card.uomData?.id}
                                        />
                                      </Form>
                                    ) : (
                                      <div
                                        style={{
                                          paddingTop: 7.5,
                                          fontSize: 18,
                                          textAlign: "center",
                                          color:
                                            card.signedMoveQty > 0
                                              ? "#66CC23"
                                              : card.signedMoveQty < 0
                                              ? "#FF1414"
                                              : "#000",
                                        }}
                                      >
                                        <span>{card.signedMoveQty}</span>
                                      </div>
                                    )}
                                  </Grid.Column>
                                  {formData.statusId === 0 && (
                                    <Grid.Column width={3}>
                                      <div
                                        style={{
                                          textAlign: "center",
                                          color: "#AFABAB",
                                          paddingTop: 7.5,
                                          fontSize: 18,
                                        }}
                                      >
                                        <span>
                                          {card.adjustedAvailableQty !== null
                                            ? card.adjustedAvailableQty
                                            : card.initialAvailableQty}
                                        </span>
                                      </div>
                                    </Grid.Column>
                                  )}
                                </Grid>
                                <hr style={{ marginRight: 0 }} />
                              </Grid.Column>
                            );
                          })}
                        </Grid>
                      </div>
                    </div>
                  </Grid.Column>
                  <Grid.Column
                    width={16}
                    style={{ minHeight: 200, zIndex: 11 }}
                    disabled={
                      formData.statusId === 1 || formData.statusId === 99
                    }
                  >
                    <Form>
                      <Grid>
                        <Grid.Column
                          required
                          width={15}
                          style={{ paddingTop: 0 }}
                        >
                          <Form.Field>
                            <label>
                              Reason <span style={{ color: "red" }}>*</span>
                            </label>
                            <TextArea
                              style={{ minHeight: 130 }}
                              onChange={(e, data) =>
                                handleChange("adjustmentReason", data.value)
                              }
                              value={formData?.adjustmentReason}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </Grid>
                    </Form>
                  </Grid.Column>
                </Grid>
              </div>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    userInfo: state.auth.user,
  };
};

const reducer = { ...cartReducer, ...searchReducer };

const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StockAdjustment);
