import {
  apiGetDiscountStatistics,
  apiSendBoaAccountEmail,
  apiGetTotalSavings,
  apiGetAccountDetails,
} from "./home.api";
// import { apiGetAccountDetails } from "../Auth/auth.api";

const SET_DISCOUNT_STATISTICS = "SET_DISCOUNT_STATISTICS";
const SET_BOA_ACCOUNT_EMAIL_SENT = "SET_BOA_ACCOUNT_EMAIL_SENT";
const TOTAL_SAVINGS = "TOTAL_SAVINGS";
const ACCOUNT = "ACCOUNT";
const initialState = {
  discountStatistics: {},
  emailSent: false,
  totalSavings: {},
  account: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DISCOUNT_STATISTICS: {
      return {
        ...state,
        discountStatistics: action.payload,
      };
    }

    case SET_BOA_ACCOUNT_EMAIL_SENT: {
      return {
        ...state,
        emailSent: action.payload,
      };
    }

    case TOTAL_SAVINGS:
      return {
        ...state,
        totalSavings: action.payload,
      };

    case ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };

    default:
      return state;
  }
};

export const actionSetDiscountStatistics = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetDiscountStatistics();
      dispatch({ type: SET_DISCOUNT_STATISTICS, payload: result });
    } catch (e) {
      dispatch({ type: SET_DISCOUNT_STATISTICS, payload: e });
    }
  };
};

export const actionSendBoaAccountEmail = () => {
  return async (dispatch) => {
    try {
      await apiSendBoaAccountEmail();
      dispatch({ type: SET_BOA_ACCOUNT_EMAIL_SENT, payload: true });
    } catch (e) {
      dispatch({ type: SET_BOA_ACCOUNT_EMAIL_SENT, payload: false });
    }
  };
};

export const actionGetTotalSavings = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetTotalSavings();

      const { status } = result;
      if (status === 200) {
        dispatch({ type: TOTAL_SAVINGS, payload: result.data });
      } else {
        dispatch({ type: TOTAL_SAVINGS, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: TOTAL_SAVINGS, payload: e.response });
    }
  };
};

export const actionGetAccountDetails = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetAccountDetails();
      dispatch({ type: ACCOUNT, payload: result.data });
    } catch (e) {
      dispatch({ type: ACCOUNT, payload: e });
    }
  };
};
