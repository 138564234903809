export const maintenanceForm = {
  name: "",
  maintenanceUnit: "",
  data: [],
  note: "",
  imageUrl: [],
  selectfile: ""
};

export const checkPoint = {
  section: "",
  field: "",
  result: "",
  note: "",
  imageUrl: []
};
