import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Modal, Search, Button, Header, Icon  } from "semantic-ui-react";
import QuickSearchResultRenderer from "./QuickSearchResultRenderer";
import * as hoseMakerReducer from "../../hoseMaker.reducer";
import _ from "lodash";
import Loader from "../Loader";
class ChangeFerruleModal extends React.Component {
    
    constructor() {
        super();
        this.state = {
            showModal: false,
            isLoading: false, 
            value: ""
        };
    }

    handleOpen = () => {
        this.setState({ showModal: true });
    }

    handleResultSelect = async (e, { result }) => {
        const { type } = this.props;
        this.setState({ isLoading: true });
        await this.props.actionChangeFerrule(result, type);
        this.setState({ showModal: false });
    };

    handleSearchChange = async (e, { value }) => {
        e.preventDefault();
        this.setState({value, isLoading: true });
        await this.props.actionFerruleAutoCompleteCall(value);
        this.setState({ isLoading: false });
    };

    cancelModal = () => {
        this.setState({ showModal: false, isLoading: false });
    };
    
    render() {
        const { showModal, isLoading, value } = this.state;
        const { autoCompleteResult } = this.props;

        const modalJSX = (
            <Modal
              open={showModal}
              trigger={<Icon onClick={this.handleOpen} name="edit"></Icon>}
              onClose={this.cancelModal}
              size="small"
              closeOnEscape={false}
              closeOnDimmerClick={false}
              closeIcon
            >
              <Header content="Select ferrule" />
                <Modal.Content>
              <Grid>
                    <Grid.Column width={16}>
                        <Search
                            className="search-box"
                            loading={isLoading}
                            placeholder="Search ferrules by product code and description."
                            resultRenderer={QuickSearchResultRenderer}
                            onResultSelect={this.handleResultSelect}
                            onSearchChange={_.debounce(this.handleSearchChange, 500, {
                                leading: true
                            })}
                            noResultsMessage="No direct matches found."
                            results={autoCompleteResult}
                            value={value}
                            {...this.props}
                            />
                            {isLoading && <Loader />}
                    </Grid.Column>
                </Grid>   
              </Modal.Content>
              <Modal.Actions>
                <Button primary onClick={this.cancelModal} inverted>
                  <Icon name="cancel" /> Cancel
                </Button>
              </Modal.Actions>
            </Modal>
          ); 
        
        return (<React.Fragment>    
            {modalJSX}
        </React.Fragment>)  
    };
}


const mapStateToProps = state => {
    return {
      autoCompleteResult: state.hoseMaker.autoCompleteResult
    };
  };
  const mapDispatchToProps = dispatch =>
    bindActionCreators(hoseMakerReducer, dispatch);
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ChangeFerruleModal);
  