import React, { useState } from "react";
import { toast } from "react-toastify";
import { HISputData, HISdeleteData } from "./hisAPIprovider";

const UserCard = ({ user, onDelete }) => {
  const [userPermission, setUserPermission] = useState(user.active);

  const changePermission = async () => {
    const response = await HISputData(`/his/users/${user.user_id}`, {
      email: user.email,
      active: !userPermission,
    });
    if (!response.ok) {
      toast.error("Error changing permission");
      return;
    }
    toast.success("Permission changed successfully");
    setUserPermission(!userPermission);
  };

  const handleDelete = async () => {
    const response = await HISdeleteData(`/his/users/${user.user_id}`);
    if (!response.ok) {
      toast.error("Error deleting user");
      return;
    }
    toast.success("User deleted successfully");
    onDelete(user.user_id);
  };

  return (
    <div className="user_card">
      <div className="user_email" onClick={handleDelete}>
        {user.email}
      </div>
      <input
        type="checkbox"
        className="user_permission"
        checked={userPermission}
        onClick={(e) => {
          e.stopPropagation(); // Prevent click from propagating to parent elements
          changePermission();
        }}
      />
    </div>
  );
};

export default UserCard;
