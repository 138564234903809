import "./asset.css";
import _ from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import * as assetReducer from "./asset.reducer";
import AssetList from "../../components/AssetList";
import {
  Grid,
  Divider,
  Icon,
  Statistic,
  Button,
  Pagination
} from "semantic-ui-react";

const paginationLimit = 20;
class Assets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activePage: 1
    };
  }

  componentDidMount = async () => {
    await this.getAssets(0);
  };

  getAssets = async pageNumber => {
    this.setState({ isLoading: true });
    await this.props.actionGetAssets(paginationLimit, pageNumber);
    this.setState({ isLoading: false });
  };


  goToPageBack = path => {
    this.props.history.goBack();
  };


  goToPage = path => {
    this.props.history.push(`/${path}`);
  };

  goToAssetDetail = id => {
    this.props.history.push(`/assetDetails/${id}`);
  };

  // goToHoseMaker = () =>{
  //   const { asset } = this.props;
  //   const { _id } = asset;
  //   this.props.history.push({
  //     pathname: "/hoseMaker",
  //     search: `?${ASSET_ID_FOR_URL_PARAM}=${_id}`
  //   });
  // }

  handlePaginationChange = (...params) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;
    const { totalPages } = this.props;

    if (totalPages === 1) {
      return;
    }

    this.setState({
      activePage
    });
    this.getAssets(activePage - 1);
  };

  render() {
    const { isLoading, activePage } = this.state;
    const { allAsset } = this.props;

    const { statistics, assets, totalPages } = allAsset;

    const AssetStatistic = () => {
      return (
        <Grid>
          <Grid.Row columns="equal" className="asset-statistics">
            {_.map(statistics, (stat, key) => {
              const { value, description } = stat;
              return (
                <Grid.Column
                  key={key}
                  computer={5}
                  mobile={16}
                  tablet={5}
                  textAlign="center"
                >
                  <Statistic size="small">
                    <Statistic.Value className="statistic-value">
                      {value}
                    </Statistic.Value>
                    <Statistic.Label>{description}</Statistic.Label>
                  </Statistic>
                </Grid.Column>
              );
            })}
          </Grid.Row>
        </Grid>
      );
    };

    const AssetButton = () => (
      <Grid>
        <Grid.Column
          computer={8}
          mobile={16}
          tablet={8}
          className="no-bottom-padded"
        >
          <h3>
            <Icon name="zip" />
            Available assets
          </h3>
        </Grid.Column>

        <Grid.Column computer={8} mobile={12} tablet={16} textAlign="right">
          <Button
            color="black"
            size="small"
            onClick={() => this.goToPage("crew")}
            icon="users"
            content="Crews"
            className="asset-Button"
          />
          <Button
            color="black"
            size="small"
            onClick={() => this.goToPage("asset/location")}
            icon="location arrow"
            content="Asset Location"
            className="asset-Button"
          />
          <Button
            color="black"
            size="small"
            onClick={() => this.goToPage("manageAsset")}
            content="New asset"
            icon="plus"
            className="asset-Button"
          />
        </Grid.Column>
      </Grid>
    );

    return (
      <div className="my-asset">
        <MetaData title="Asset" />
        {isLoading && (
          <Loader title="Loading, please wait">Loading,please wait</Loader>
        )}
        <Grid>
          <Grid.Row>
            <Grid.Column width={16}>
              <Grid className="my-location-header">
                <Grid.Column tablet={10} computer={10} mobile={16}>
                  <h3>
                    <Icon name="chart bar" />
                Asset statistics

              </h3>
                </Grid.Column>
                <Grid.Column
                  textAlign="right"
                  width={6}
                  mobile={16}
                  computer={6}
                >
                  <Button
                    size="tiny"
                    onClick={this.goToPageBack}
                    icon="left arrow"
                    content="Go back"
                    labelPosition="left"
                    className="button-float"
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>

          </Grid.Row>
          <Divider />
          <Grid.Column
            width={16}
            textAlign="center"
            className="no-top-padded"
          //style={{ paddingBottom: "0px" }}
          >

            <h3 >Coming soon</h3>
          </Grid.Column>

          {/* <Grid.Column width={16} className="no-top-padded">
            <AssetStatistic />
            <Divider />
          </Grid.Column>

          <Grid.Column width={16} className="no-top-padded">
            <AssetButton />
            <Divider />
          </Grid.Column>

          <Grid.Column width={16} className="no-top-padded">
            <AssetList
              assets={assets}
              goToPage={this.goToPage}
              goToAssetDetail={this.goToAssetDetail}
            />
          </Grid.Column>

          <Grid.Column width={16}>
            <div>
              {!_.isEmpty(assets) ? (
                <Pagination
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={this.handlePaginationChange}
                  totalPages={totalPages}
                  firstItem={null}
                  lastItem={null}
                />
              ) : null}
            </div>
          </Grid.Column> */}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allAsset: state.assets.allAsset
  };
};

const reducer = { ...assetReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Assets)
);
