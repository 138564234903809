import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import MetaData from "../../components/MetaData";
import * as MyFavoritesReducer from "./myFavorites.reducer";
import * as cartReducer from "../Cart/cart.reducer";
import addToCart from "../Search/AddtoCart";
import {
  Divider,
  Grid,
  Image,
  Accordion,
  Icon,
  List,
  Button,
  Message,
  Modal,
  Header,
} from "semantic-ui-react";
import productDefaultImage from "../../assets/images/product_default.png";
import moment from "moment";
import { withRouter } from "react-router-dom";
import "./myFavorites.css";
import Loader from "../../components/Loader";
import _ from "lodash";

class MyFavoritesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      showMore: false,
      imageModal: false,
      imageLink: "",
    };
  }
  componentWillMount = async () => {
    await this.props.actionSetMostOrdered();
    this.setState({ isLoading: false });
  };

  showMore = () => {
    this.setState({ showMore: true });
  };

  showLess = () => {
    this.setState({ showMore: false });
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.props;
    const newIndex = activeIndex === index ? -1 : index;
    this.props.toggleActiveCategory(newIndex);
    this.setState({ activeIndex: newIndex, showMore: false });
  };

  takeToStock = (stockCode) => {
    this.props.history.push(`/search?value=${stockCode}`);
  };

  goToSearchPage = () => {
    this.props.history.push("/search");
  };

  addToCartHandler = async (item) => {
    const { stockCode, stockDescription, pack } = item;
    const isMeter = pack === "Metre" || pack === "Feet";
    const unit = isMeter ? pack : "Quantity";
    const data = {
      stockCode: stockCode,
      quantity: 1,
      unit,
      stockDescription,
      refId: null,
    };
    await this.props.actionAddToCart(data);
    addToCart({ stockCode });
  };

  openImageModal = (imageLink) => {
    this.setState({ imageModal: true, imageLink: imageLink });
  };

  handleClose = () => {
    this.setState({ imageModal: false, imageLink: "" });
  };

  render() {
    const { mostOrdered, activeIndex } = this.props;
    const { isLoading, imageLink, imageModal } = this.state;

    const ShowMoreButtons = () => {
      const { showMore } = this.state;
      return (
        <Grid>
          <Grid.Column computer={5} mobile={0} tablet={5} />
          <Grid.Column
            computer={6}
            mobile={16}
            tablet={5}
            className="no-padding"
          >
            {showMore && (
              <List.Item>
                <a onClick={() => this.showLess()}>
                  <Button
                    fluid
                    size="mini"
                    type="submit"
                    color="gray"
                    className="add-to-cart"
                  >
                    <Button.Content>Show less</Button.Content>
                  </Button>
                </a>
              </List.Item>
            )}
            {!showMore && (
              <List.Item>
                <a onClick={() => this.showMore()}>
                  <Button
                    fluid
                    size="mini"
                    type="submit"
                    color="green"
                    className="add-to-cart"
                  >
                    <Button.Content>Show More</Button.Content>
                  </Button>
                </a>
              </List.Item>
            )}
          </Grid.Column>
          <Grid.Column computer={5} mobile={0} tablet={5} />
        </Grid>
      );
    };

    const OrderDetail = (items = []) => {
      const showButtons = items.length > 4;

      return (
        <List celled>
          {items.length &&
            items.map((item, i_key) => {
              const { stockImage1, stockCode, stockDescription, updatedAt } =
                item;
              const lastBought = (
                <span>
                  <Icon name="wait" />
                  {"Last bought: " +
                    moment(updatedAt).subtract("days").calendar()}
                </span>
              );

              return (
                <List.Item key={i_key} className="my-favorite-card">
                  <Grid columns="equal">
                    <Grid.Column
                      computer={2}
                      mobile={4}
                      tablet={2}
                      className="no-padding"
                    >
                      <Image
                        src={stockImage1 ? stockImage1 : productDefaultImage}
                        alt="product"
                        className="cart-product-image"
                        onClick={() =>
                          this.openImageModal(
                            stockImage1 ? stockImage1 : productDefaultImage
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column
                      computer={10}
                      mobile={11}
                      tablet={10}
                      style={{ margin: "auto" }}
                      className="no-right-padded"
                    >
                      <b>
                        {" "}
                        <a onClick={() => this.takeToStock(stockCode)}>
                          {stockCode}{" "}
                        </a>
                      </b>
                      <br />
                      {stockDescription}
                      <div className="last-bought">{lastBought}</div>
                    </Grid.Column>
                    <Grid.Column
                      computer={4}
                      mobile={16}
                      tablet={4}
                      style={{ margin: "auto" }}
                      centered
                    >
                      <a onClick={() => this.addToCartHandler(item)}>
                        <Button
                          fluid
                          animated="vertical"
                          size="small"
                          type="submit"
                          color="black"
                          className="add-to-cart"
                        >
                          <Button.Content hidden>
                            <Icon name="cart" />
                          </Button.Content>
                          <Button.Content visible>Add to cart</Button.Content>
                        </Button>
                      </a>
                    </Grid.Column>
                  </Grid>
                </List.Item>
              );
            })}

          {showButtons && <ShowMoreButtons />}
        </List>
      );
    };

    const Category = () => {
      return (
        <Accordion fluid styled className="category-section" exclusive={false}>
          {mostOrdered.map((category, c_key) => {
            const { items, key } = category;

            const { showMore } = this.state;
            let displayItems = _.take(items, 5);
            if (showMore) {
              displayItems = items;
            }

            return (
              <span>
                <Accordion.Title
                  className="title"
                  active={activeIndex === c_key}
                  index={c_key}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  {key}
                </Accordion.Title>
                <Accordion.Content
                  className="item-content"
                  active={activeIndex === c_key}
                >
                  {OrderDetail(displayItems)}
                </Accordion.Content>
              </span>
            );
          })}
        </Accordion>
      );
    };

    const modalJSX = (
      <Modal open={imageModal} onClose={this.handleClose} basic size="large">
        <Header icon="browser" content="Product image" />
        <Modal.Content>
          <Image src={imageLink} />
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.handleClose} inverted>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );

    return (
      <div className="my-favorite">
        <MetaData title="Favorites" />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading,please wait</Loader>
          )}
          <Grid.Column width={16}>
            <h3>
              <Icon name="favorite" />
              Favourite orders
              <Divider />
            </h3>
          </Grid.Column>
          <Grid.Column width={16}>
            {mostOrdered.length > 0 ? (
              <Category />
            ) : (
              <Message
                info
                header="Sorry, No favorite orders found!"
                content={
                  <div>
                    <span
                      className="cart-go-search-link"
                      onClick={this.goToSearchPage}
                    >
                      Click here to add products and place order
                    </span>
                  </div>
                }
              />
            )}
          </Grid.Column>
        </Grid>
        {modalJSX}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mostOrdered: state.myFavorites.mostOrdered,
    activeIndex: state.myFavorites.activeIndex,
  };
};

const reducer = { ...MyFavoritesReducer, ...cartReducer };
const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MyFavoritesComponent)
);
