import React from "react";
import defaultImage from "../assets/images/product_default.png";
import { Grid, Image, Button } from "semantic-ui-react";
import _ from "lodash";
import ItemProperties from "./ItemProperties";
import FamilyBreadCrumb from "./FamilyBreadCrumb";

class HoseDescription extends React.Component {
  
  constructor() {
    super();
    this.state = { 
      isShowMore: false,
    }
  }

  isShowMoreButton = (properties, images) => {
    properties = _.pickBy(properties, _.identity);
    if (properties.length < 2) {
      return false;
    }

    if (!images) {
      return false;
    }

    return true;
  };
  
  onShowMore = () => {
    this.setState({ isShowMore: true });
  };

  onShowLess = () => {
    this.setState({ isShowMore: false });
  };
  
  render() {
    const { hose, addToHose } = this.props;
    const { stockImage1 = "", stockImage2, stockImage3, productDimensions } = hose;

    return (
      <React.Fragment>
        <Grid>
          <Grid.Column width={2}>
            <Image size={"small"} src={stockImage1 ? stockImage1 : defaultImage} alt="hose" />
            {this.state.isShowMore && (
              <div>
                {stockImage2 && (
                  <Image size={"small"}
                    src={stockImage2 ? stockImage2 : defaultImage}
                  />
                )}
                {stockImage3 && (
                  <Image size={"small"}
                    src={stockImage3 ? stockImage3 : defaultImage}
                  />
                )}
              </div>
            )}
          </Grid.Column>
          <Grid.Column width={10}>
          <FamilyBreadCrumb {...hose} />
            <ItemProperties
                  {...{
                    ...hose,
                    isShowMore: this.state.isShowMore,
                    onShowMore: this.onShowMore,
                    onShowLess: this.onShowLess,
                    stockImage2: stockImage2,
                    isShowMoreButton: this.isShowMoreButton(
                      productDimensions,
                      stockImage2
                    )
                  }}
                />
          </Grid.Column>
          <Grid.Column width={4} mobile={16} tablet={4} computer={4}>
            <Button color="black" onClick={(e) => addToHose(e, hose)}>
              Add to hose
            </Button>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

export default HoseDescription;
