import { apiGetAudits, apiSearchStock } from "./auditTrail.api";

const SET_AUDITS = "SET_AUDITS";
const SET_TYPE = "SET_TYPE";
const SET_STOCK_CODE = "SET_STOCK_CODE";
const SET_FROM_DATE = "SET_FROM_DATE";
const SET_TO_DATE = "SET_TO_DATE";
const SET_OPERATOR = "SET_OPERATOR";
const SET_NAME = "SET_NAME";
const SEARCH_STOCK = "SEARCH_STOCK";


const initialState = {
  allaudit: [],
  response: {},
  searchsuggestion: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUDITS: {
      return {
        ...state,
        allaudit: action.payload
      };
    }

    case SET_TYPE:
      return {
        ...state
      };

    case SET_STOCK_CODE:
      return {
        ...state
      };

    case SET_FROM_DATE:
      return {
        ...state
      };

    case SET_TO_DATE:
      return {
        ...state
      };

    case SET_OPERATOR:
      return {
        ...state
      };

    case SET_NAME:
      return {
        ...state
      };

      case SEARCH_STOCK: {
        return {
          ...state,
          searchsuggestion: action.payload
        };
      }

    default:
      return { ...state };
  }
};

export const actionsGetAudits = (
  limit,
  pageNumber,
  type,
  stockCode,
  fromDate,
  toDate,
  operator,
  assetName
) => {
  return async dispatch => {
    try {
      const result = await apiGetAudits(
        limit,
        pageNumber,
        type,
        stockCode,
        fromDate,
        toDate,
        operator,
        assetName
      );
      dispatch({ type: SET_AUDITS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_AUDITS, payload: e });
    }
  };
};

export const actionSearchStock = text => {
  return async dispatch => {
    try {
      const result = await apiSearchStock(text);
      dispatch({ type: SEARCH_STOCK, payload: result.data });
    } catch (e) {
      dispatch({ type: SEARCH_STOCK, payload: e.response });
    }
  };
};
