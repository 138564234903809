import React from "react";
import PropTypes from "prop-types";
import Cleave from "cleave.js/react";
// eslint-disable-next-line
import CleavePhone from "cleave.js/dist/addons/cleave-phone.nz"; // Do not remove this plugin

const CustomInput = props => {
  const { placeholder, onChangeHandler, options, defaultValue } = props;
  return (
    <Cleave
      placeholder={placeholder}
      options={options}
      onChange={onChangeHandler}
      value={defaultValue}
    />
  );
};
CustomInput.propTypes = {
  placeholder: PropTypes.string,
  onChangeHandler: PropTypes.func,
  options: PropTypes.object,
  defaultValue: PropTypes.any
};

export default CustomInput;
