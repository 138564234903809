import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CartProductDetails from "./CartProductDetails";
import NumericInputBox from "../NumericInputBox";
import productDefaultImage from "../../assets/images/product_default.png";
import Loader from "../Loader";
import {
  Grid,
  Icon,
  Image,
  Form,
  List,
  Label,
  Modal,
  Header,
  Button,
  Checkbox,
} from "semantic-ui-react";
import UserRoleCheck from "../UserRoleCheck";
import BuyByCoil from "../BuyByCoil";
import { FULL_BOX_POLICY } from "../../constants/appConstants";
import * as cartReducer from "../../containers/Cart/cart.reducer";
import * as orderConfirmationReducer from "../../containers/OrderConfirmation/orderConfirmation.reducer";
import userCartReducer from "../../containers/Cart/userCart.reducer";
import _ from "lodash";

import "./cartProducts.css";

function CartProduct(props) {
  const [boxSize, setBoxSize] = useState(0);
  const [imageModal, setImageModal] = useState(false);
  const [imageLink, setImageLink] = useState("");
  const [isManual, setIsManual] = useState(false);

  const {
    item,
    userCart,
    removeFromCart,
    options,
    isLoading,
    updatingStockCode = "",
    quantityChangeHandler,
    updateDescriptionHandler,
    selectProductHandler,
    showPrice = false,
    userInfo,
  } = props;

  const { account = {}, showPriceToUser = false } = userInfo;

  const addBoxRollQuantity = (value, userCart, item) => {
    const { barcode: stockCode, quantity, refId } = item;
    const pack = "";
    const newQuantity = quantity < value ? value : quantity + value;
    return quantityChangeHandler(newQuantity, stockCode, pack, userCart, refId);
  };

  const beforeQuantity = (value, userCart) => {
    const { barcode: stockCode, quantity, refId } = item;
    const pack = "";
    return quantityChangeHandler(
      value,
      stockCode,
      pack,
      userCart,
      refId,
      isManual
    );
  };

  const setProductIsSelected = (isSelected, userCart) => {
    const { barcode: stockCode, refId } = item;
    return selectProductHandler(stockCode, refId, userCart, isSelected);
  };

  const openImageModal = (imageLink) => {
    setImageModal(true);
    setImageLink(imageLink);
  };

  const handleClose = () => {
    setImageModal(false);
    setImageLink("");
  };

  const remove_button = (item, userCart) => (
    <span onClick={() => beforeQuantity(0, userCart)}>
      {options && (
        <span className="cart-update-remove-link">
          <Icon name="cancel" />
          Remove
        </span>
      )}
    </span>
  );

  const show_price = (type, countryCode, price) => (
    <div className="cart-product-price">
      <p className="card-product-type">{type}</p>

      {price != null && price === 0 && (
        <p className={"card-product-callboa"} style={{ marginBottom: "0" }}>
          {"CALL BOA"}
        </p>
      )}
      {price != null && price > 0 && (
        <>
          <p
            className={type === "BUY" ? "card-product-buy" : "card-product-rrp"}
            style={{ marginBottom: "0" }}
          >
            {countryCode} ${(Math.round(price * 100) / 100).toFixed(2)}
          </p>
          <p style={{ fontSize: "10px", fontStyle: "italic" }}>
            {"(excl GST)"}
          </p>
        </>
      )}
    </div>
  );

  const sub_total_price = (qty, price, countryCode) => {
    return (
      <div className="cart-product-price center">
        <p className="card-product-type">SUB TOTAL</p>
        {price > 0 ? (
          <>
            <p className="card-product-buy" style={{ marginBottom: "0" }}>
              {countryCode} ${(Math.round(qty * price * 100) / 100).toFixed(2)}
            </p>
            <p style={{ fontSize: "10px", fontStyle: "italic" }}>{"BUY"}</p>
          </>
        ) : (
          <p className="card-product-callboa" style={{ marginBottom: "0" }}>
            CALL BOA
          </p>
        )}
      </div>
    );
  };

  const product_image = (image) => (
    <span>
      {image && (
        <Image
          src={`data:image/jpeg;base64,${image}`}
          alt={image}
          className="cart-product-image"
          onClick={() => openImageModal(image)}
        />
      )}
      {!image && (
        <Image
          src={`data:image/jpeg;base64,${productDefaultImage}`}
          alt={image}
          className="cart-product-image"
          onClick={() => openImageModal(productDefaultImage)}
        />
      )}
    </span>
  );

  const product_details = (details, stockDescription) => (
    <CartProductDetails
      {...{ details }}
      stockDescription={stockDescription}
      updateDescription={updateDescriptionHandler}
      options={options}
    />
  );

  const quantity_detail = (item, details, userCart) => {
    let { barcode: stockCode, quantity } = item;
    const pack = item.productUom === 8 ? "Metre" : "Quantity";
    const isMeter = pack === "Metre" || pack === "Feet";
    let quantityMessage = isMeter ? pack : "Quantity";
    const precision =
      item.productUom === 8 ? 1 : item.productUom === 84 ? 1 : 0;

    // Convert Metre to Feet is user's country code is US
    if (
      localStorage.getItem("userCountryCode") === "US" &&
      quantityMessage === "Metre"
    )
      quantityMessage = "Feet";

    return (
      <span>
        <div>
          {!options && (
            <p className="cart-product-price">
              {quantityMessage} <br />
              {quantity}
            </p>
          )}
          {options && (
            <div className="quantity-form">
              <Form>
                <label>{quantityMessage}</label>
                <Form.Field>
                  <NumericInputBox
                    value={quantity}
                    onKeyDownHandler={(e) => {
                      setIsManual(true);
                      if (e.key === "Enter") {
                        beforeQuantity(e.target.value, userCart);
                      }
                    }}
                    onBlurHandler={(e) => {
                      beforeQuantity(e.target.value, userCart);
                      setTimeout(() => {
                        setIsManual(false);
                      }, 1000);
                    }}
                    name="quantity"
                    min={1}
                    max={10000}
                    maxLength={6}
                    precision={precision}
                    step={1}
                  />
                </Form.Field>
              </Form>
            </div>
          )}
        </div>
      </span>
    );
  };

  const uom_detail = (item, details, userCart) => {
    const { pack = "" } = details;
    return (
      <span>
        <div>
          {options && (
            <div className="quantity-form">
              <Form>
                <label style={{ fontSize: 12 }}>
                  {item.productUom === 1 ? "Box" : "Roll"} size:{" "}
                  {item.boahubFullCarton}
                </label>
                <Form.Field>
                  <Button
                    onClick={() =>
                      addBoxRollQuantity(item.boahubFullCarton, userCart, item)
                    }
                  >
                    ADD {item.boahubFullCarton}
                  </Button>
                </Form.Field>
              </Form>
            </div>
          )}
        </div>
      </span>
    );
  };

  const modalJSX = (
    <Modal open={imageModal} onClose={handleClose} basic size="large">
      <Header icon="browser" content="Cart Product image" />
      <Modal.Content>{product_image(item.image128)}</Modal.Content>
      <Modal.Actions>
        <Button
          style={{
            backgroundColor: "#66cc23",
            color: "#fff",
          }}
          onClick={handleClose}
          inverted
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );

  return (
    <>
      <List.Item className="cart-product-card">
        {item.refId === "00000000-0000-0000-0000-000000000000" ||
        item.refId === null ||
        !item.refId ? null : (
          <Label as="a" color="red" ribbon style={{ marginLeft: 25 }}>
            Added Automatically
          </Label>
        )}
        <span className="remove-button-mobile">
          {remove_button(item, userCart)}
        </span>
        <Grid style={{ paddingRight: "1rem" }}>
          {options && (
            <Grid.Column
              tablet={1}
              computer={1}
              mobile={4}
              className="no-left-padded"
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <Checkbox
                  onChange={(e, data) =>
                    setProductIsSelected(data.checked, userCart)
                  }
                  checked={item.isSelected}
                />
              </div>
            </Grid.Column>
          )}
          <Grid.Column
            tablet={1}
            computer={1}
            mobile={4}
            className="no-left-padded"
          >
            {product_image(item.image128)}
          </Grid.Column>
          <Grid.Column
            tablet={5}
            computer={options ? 7 : 10}
            mobile={10}
            className="no-padding"
          >
            {product_details(item, item.stockDescription)}
            {options && (
              <BuyByCoil {...item.productDimensions} addBoxSize={setBoxSize} />
            )}

            <div className="discount">
              {item.policyName && (
                <Label color="teal" size="tiny" tag>
                  {item.policyName === FULL_BOX_POLICY
                    ? "Box size"
                    : item.policyName}{" "}
                  policy - applied for this product{" "}
                  {item.policyName === FULL_BOX_POLICY ? (
                    "with extra discount"
                  ) : (
                    <span> with {item.discount}% discount </span>
                  )}
                </Label>
              )}

              <UserRoleCheck>
                {item.discount > 0 && (
                  <Label color="green" size="tiny" tag>
                    You save - {item.currency} {item.totalDiscount}
                  </Label>
                )}
              </UserRoleCheck>
            </div>

            <Grid.Row>
              <span className="remove-button">
                {remove_button(item, userCart)}
              </span>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column
            tablet={3}
            computer={1}
            mobile={8}
            className="ui center aligned"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <p>
              {item.retailPrice !== null &&
                show_price("RRP", item.countryCode, item.retailPrice)}
            </p>
          </Grid.Column>
          <Grid.Column
            tablet={3}
            computer={1}
            mobile={8}
            className="ui center aligned"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <p>
              {item.buyPrice !== null &&
                show_price("BUY", item.countryCode, item.buyPrice)}
            </p>
          </Grid.Column>
          <Grid.Column
            tablet={3}
            computer={2}
            mobile={8}
            className="ui center aligned"
            style={{
              paddingRight: 0,
            }}
          >
            {quantity_detail(item, item, userCart)}
          </Grid.Column>
          {item.boahubFullCarton > 0 && options ? (
            <Grid.Column
              tablet={3}
              computer={2}
              mobile={8}
              className="ui center aligned"
            >
              {uom_detail(item, item, userCart)}
            </Grid.Column>
          ) : options ? (
            <Grid.Column
              tablet={3}
              computer={2}
              mobile={8}
              className="ui center aligned"
            ></Grid.Column>
          ) : null}
          <Grid.Column
            tablet={3}
            computer={1}
            mobile={8}
            className="ui center aligned"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            {item.buyPrice !== null &&
              sub_total_price(item.quantity, item.buyPrice, item.countryCode)}
          </Grid.Column>
        </Grid>
      </List.Item>
      {modalJSX}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    userCart: state.userCart,
    userInfo: state.auth.user,
    accountInfo: state.orderConfirmation.accountInfo,
    overallDiscount: state.orderConfirmation.overallDiscount,
  };
};

const reducer = {
  ...cartReducer,
  ...userCartReducer,
  ...orderConfirmationReducer,
};

const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CartProduct);
