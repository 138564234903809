import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import "./viewImages.css";
import { Image, Icon } from "semantic-ui-react";

const ViewImage = ({ imageUrl, deleteImage }) => {
  return (
    <Image.Group size="mini" className="issue-images">
      {_.map(imageUrl, image => {
        return (
          <span className="image-list">
            <Image inline src={image.link} className="issue-images" />
            {deleteImage && <Icon
              name="delete"
              color="white"
              className="delete-icon"
              onClick={() => deleteImage(image)}
            />}
          </span>
        ); 
      })}
    </Image.Group>
  );
};

ViewImage.propTypes = {
  imageUrl: PropTypes.array,
  deleteImage: PropTypes.func
};

export default ViewImage;
