import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Control, Errors } from "react-redux-form";
import MetaData from "../../components/MetaData";
import Loader from "../../components/Loader";
import * as orderReducer from "./orderConfirmation.reducer";
import * as cartReducer from "../Cart/cart.reducer";
import _ from "lodash";
import * as orderUtils from "./order.utils";
import {
  Grid,
  Divider,
  Button,
  List,
  Segment,
  Label,
  Modal,
  Image,
  Icon,
  Form,
  Message,
  Confirm,
  Checkbox,
} from "semantic-ui-react";
import CartProducts from "../../components/CartProducts";
import TotalPrice from "../../components/TotalPrice";
import "./orderConfirmation.css";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import boaLogo from "../../assets/images/boa_icon.png";
import OrderDetails from "../../components/OrderHistoryList/OrderDetails";
import { getErrorMessage } from "../../utils/error.utils";
import ErrorMessageBox from "../../components/ShowErrorMessage";
import DispatchDetailsModal from "../../components/DispatchDetailsModal";
import UserRoleCheck from "../../components/UserRoleCheck";
import { DEFAULT_TAX } from "../../constants/appConstants";
import { getAccountTax } from "../../containers/OrderConfirmation/order.utils";
import { apiGetUserCurrentStockLocation } from "../Cart/cart.api";
import { refreshToken } from "../Auth/auth.api";
import * as authReducer from "../Auth/auth.reducer";

const ENTERPRISE = "enterprise";
const RETAIL = "retail";

class OrderConfirmationComponent extends Component {
  constructor() {
    super();

    this.state = {
      initialLoad: true,
      modalOpen: false,
      orderPlaced: false,
      isError: true,
      errorMessage: "",
      isLoading: false,
      iAgree: false,
      pickUpFlag: false,
      pickUpModalOpen: false,
      pickupData: null,
      pickAddressObject: null,
    };
  }

  calculateTax = () => {
    const { accountInfo } = this.props;
    let tax = DEFAULT_TAX;

    if (accountInfo) {
      const { overrideGSTRate } = accountInfo;
      tax = getAccountTax(overrideGSTRate);
    }
    return tax;
  };

  componentWillMount() {
    this.getData();
  }

  getData = async () => {
    await this.props.actionGetAccountDetails();
    await this.props.actionSetDeliveryDetails();
    await this.props.actionSetCart();
    // await this.props.actionSetCustomerOrderNumber();
    // await this.props.actionSetReference();
    // await this.props.actionSetCustomNotes();
    // await this.props.actionGetOverallDiscount();
    // await this.props.actionGetPickUpAddress();
    this.setState({ initialLoad: false });
  };

  componentWillUnmount() {
    this.props.actionClearResponse();
    // this.props.actionSetCustomerOrderNumber();
    // this.props.actionSetReference();
  }

  componentDidMount() {
    this.props.actionSetCustomerOrderNumber("");
    this.props.actionSetCustomNotes("");
  }

  goToCart = () => {
    this.props.history.push("/cart");
  };

  getOrderDetails = () => {
    const { cart } = this.props;
    return orderUtils.getOrderDetails(cart);
  };

  payWithBoa = async () => {
    this.setState({
      creditPaymentOpen: false,
      errorMessage: false,
      isError: false,
      pickUpAddressOpen: false,
    });
    const {
      customerOrderNumber,
      reference,
      deliveryAddress,
      cart,
      accountInfo,
      overallDiscount,
      customNotes,
      user,
    } = this.props;
    const { pickAddressObject } = this.state;
    const { needOrderNumber = true } = accountInfo;
    if (needOrderNumber) {
      if (_.isEmpty(customerOrderNumber)) {
        this.setState({
          isError: false,
          errorMessage: "Please add a Purchase Order number",
          paymentConfirmationOpen: false,
        });
        window.scrollTo(0, 0);
        return;
      }
    }

    if (_.isEmpty(deliveryAddress)) {
      this.setState({
        isError: false,
        errorMessage: "Please add delivery address",
        paymentConfirmationOpen: false,
      });
      window.scrollTo(0, 0);
      return;
    }

    this.setState({ isLoading: true });
    const { discount = 0 } = overallDiscount;
    const { pickUpFlag } = this.state;
    let tax = this.calculateTax();
    const stockLocationId = localStorage.getItem("boahubLocationId");
    const currentStockLocationId = Number(stockLocationId);
    if (pickUpFlag === true) {
      deliveryAddress = pickAddressObject;
      let orderData = Object.assign(
        {},
        {
          partnerInvoiceId: user.id,
          partnerShippingId: deliveryAddress.id,
          clientOrderRef: customerOrderNumber,
          probDeliveryNotes: customNotes,
          boahubLocationId: currentStockLocationId,
          selectedCartItems: cart,
        }
      );

      // let orderData = orderUtils.constructPayWithBOACreditObject({
      //   customerOrderNumber,
      //   reference,
      //   deliveryAddress,
      //   cart,
      //   tax,
      //   discount,
      //   iWillPickUp: true,
      // });
      // orderData.paymentType = "boa credit";
      await this.props.actionPlaceOrderCredit(orderData);
      await refreshToken();
      await this.getCurrentStockLocation();
    } else {
      let orderData = Object.assign(
        {},
        {
          partnerInvoiceId: user.id,
          partnerShippingId: deliveryAddress.id,
          clientOrderRef: customerOrderNumber,
          probDeliveryNotes: customNotes,
          boahubLocationId: currentStockLocationId,
          selectedCartItems: cart,
        }
      );
      // let orderData = orderUtils.constructPayWithBOACreditObject({
      //   customerOrderNumber,
      //   reference,
      //   deliveryAddress,
      //   cart,
      //   tax,
      //   discount,
      //   iWillPickUp: false,
      // });
      // orderData.paymentType = "boa credit";
      await this.props.actionPlaceOrderCredit(orderData);
      await refreshToken();
      await this.getCurrentStockLocation();
    }
    this.setState({ isLoading: false });
    // const { response } = this.props;
    const { status, statusText: failedResponse } = this.props;
    if (status === 200) {
      this.showOrderSuccessMessage();
    } else if (status === 424) {
      this.setState({ paymentConfirmationOpen: false });
      return showErrorToast("Something went wrong, please try again");
    } else {
      this.setState({ paymentConfirmationOpen: false });
      // const { data: failedData } = failedResponse;
      // const { code } = failedData;
      // this.showOrderErrorMessage(code);
      window.scrollTo(0, 0);
      this.setState({
        isError: false,
        errorMessage: failedResponse,
      });
    }
  };

  getCurrentStockLocation = async () => {
    const currentStockLocation = await apiGetUserCurrentStockLocation();
    if (currentStockLocation.id)
      window.localStorage.setItem("boahubLocationId", currentStockLocation.id);
    window.localStorage.setItem(
      "stockLocationName",
      currentStockLocation.stockLocationName
    );
  };

  showOrderErrorMessage = async (status) => {
    window.scrollTo(0, 0);
    let message = await getErrorMessage(status);

    this.setState({
      isError: false,
      errorMessage: message,
    });
  };

  showOrderSuccessMessage = () => {
    window.scrollTo(1, 1);
    // this.props.actionClearAll();
    showSuccessToast(
      <div>
        <i className="check icon" /> Order Placed Successfully
      </div>
    );
    this.setState({ orderPlaced: true });
  };

  PayWithPaymentExpress = async () => {
    this.setState({
      paymentConfirmationOpen: false,
      errorMessage: false,
      isError: false,
    });
    const {
      customerOrderNumber,
      reference,
      cart,
      accountInfo,
      overallDiscount,
      customNotes,
    } = this.props;
    let { deliveryAddress } = this.props;
    const { needOrderNumber = true } = accountInfo;
    if (needOrderNumber) {
      if (_.isEmpty(customerOrderNumber)) {
        this.setState({
          isError: false,
          errorMessage: "Please add PO number",
        });
        window.scrollTo(0, 0);
        return;
      }
    }

    if (_.isEmpty(deliveryAddress)) {
      this.setState({
        isError: false,
        errorMessage: "Please add delivery address",
      });
      window.scrollTo(0, 0);
      return;
    }

    this.setState({ isLoading: true });
    const { discount = 0 } = overallDiscount;
    const { pickUpFlag, pickAddressObject } = this.state;
    let tax = this.calculateTax();
    if (pickUpFlag === true) {
      deliveryAddress = pickAddressObject;
      // let orderData = orderUtils.constructPayWithBOACreditObject({
      //   customerOrderNumber,
      //   reference,
      //   deliveryAddress,
      //   //pickAddressObject,
      //   cart,
      //   tax,
      //   discount,
      //   iWillPickUp: true,
      // });
      // orderData.paymentType = "payment gateway";
      // await this.props.actionPlaceOrderPaymentGateway(orderData);
    } else {
      // let orderData = orderUtils.constructPayWithBOACreditObject({
      //   customerOrderNumber,
      //   reference,
      //   deliveryAddress,
      //   cart,
      //   tax,
      //   discount,
      //   iWillPickUp: false,
      // });
      // orderData.paymentType = "payment gateway";
      // await this.props.actionPlaceOrderPaymentGateway(orderData);
    }
    const { pxPayInitiateResponse } = this.props;
    const { status = 200, data } = pxPayInitiateResponse;
    this.setState({ isLoading: false });
    if (status === 200) {
      const { pxPayUrl = "" } = data;
      document.location.href = pxPayUrl;
    } else if (status === 424) {
      return showErrorToast("Something went wrong, please try again");
    } else {
      const { code } = data;
      this.showOrderErrorMessage(code);
    }
  };

  getCartCount = () => {
    let quantity = 0;
    _.forEach(this.props.cart, (item) => {
      quantity = quantity + Number(item.quantity);
    });
    return <span> Quantity {quantity}</span>;
  };

  computeSubtotal = () => {
    _.forEach(this.props.cart, (item) => {
      if (item.buyPrice === 0 || item.retailPrice === 0) return 0;
    });
    let subTotal = this.props.cart.reduce(
      (prev, item) => prev + item.buyPrice * item.quantity,
      0
    );
    return subTotal;
  };

  editAddress = (e) => {
    e.preventDefault();
    this.props.history.push("/profile/deliveryAddress");
  };

  modelOpenHandler = (e) => {
    e.preventDefault();
    this.setState({ modalOpen: true });
  };

  modelCloseHandler = () => {
    this.setState({ modalOpen: false, pickUpModalOpen: false });
  };

  modelPickupOpenHandler = (e) => {
    e.preventDefault();
    this.setState({ pickUpModalOpen: true });
  };

  modelPickUpCloseHandler = () => {
    this.setState({ pickUpModalOpen: false });
  };

  changeAddressHandler = (e, address) => {
    this.props.actionChangeAddress(address);
    showSuccessToast(
      <div>
        <Icon name="truck" /> Delivery Address changed{" "}
      </div>
    );
    this.modelCloseHandler();
  };

  changePickupAddressHandler = (e, address) => {
    this.setState({
      pickAddressObject: address,
    });
    this.props.actionChangeAddress(address);
    showSuccessToast(
      <div>
        <Icon name="truck" /> Delivery Address changed{" "}
      </div>
    );
    this.modelPickUpCloseHandler();
  };

  onChangeReferenceHandler = (e) => {
    const { value } = e.target;
    this.props.actionSetReference(value);
  };

  onChangeCustomNotesHandler = (e) => {
    const { value } = e.target;
    this.props.actionSetCustomNotes(value);
  };

  onChangeCustomerOrderHandler = (e) => {
    const { value } = e.target;
    this.props.actionSetCustomerOrderNumber(value);
  };

  goToOrderHistory = (e) => {
    e.preventDefault();
    this.props.history.push("/orderHistory");
  };

  openDispatchDetails = (e) => {
    e.preventDefault();
    this.setState({ openDispatchDetails: true });
  };

  closeDispatchDetails = (e) => {
    e.preventDefault();
    this.setState({ openDispatchDetails: false });
  };

  orderSuccessSummary = (data) => {
    const { status } = data;
    return (
      <div className="order-success-message">
        <Grid>
          <Grid.Column width={16}>
            {status === "success" && (
              <Message positive icon>
                <Icon name="check" />
                <Message.Content>
                  <Message.Header>
                    Thank you, Your order has been placed
                  </Message.Header>
                  Your payment has been processed.
                </Message.Content>
              </Message>
            )}
            {status === "failed" && (
              <Message negative icon>
                <Icon name="close" />
                <Message.Content>
                  <Message.Header>Sorry, Your order failed</Message.Header>
                  Your payment has been declined.
                </Message.Content>
              </Message>
            )}
          </Grid.Column>
          <Grid.Column width={16}>
            <h3>
              <Icon name="sticky note outline" />
              Order Summary
            </h3>
            <Divider />
            <OrderDetails order={data} options={false} showPrice={true} />
          </Grid.Column>
        </Grid>
      </div>
    );
  };

  changedPickupAdd = () => {
    const { pickUpAllAddresses } = this.props;
    // const { data } = pickUpAllAddresses;
    this.setState({
      pickupData: pickUpAllAddresses,
    });

    if (pickUpAllAddresses != undefined) {
      pickUpAllAddresses.map((address, key) => {
        if (address.isPrimary === true) {
          this.setState({
            pickAddressObject: address,
          });
        }
      });
    }
  };
  checkBoxAddressHandler = async (e, data) => {
    const { checked } = data;
    if (checked) {
      this.setState({
        iAgree: checked,
        pickUpFlag: true,
      });
      this.changedPickupAdd();
    } else {
      this.setState({
        pickUpFlag: false,
      });
      await this.props.actionSetDeliveryDetails();
    }
  };
  render() {
    const {
      cart,
      deliveryAddress,
      allAddresses,
      reference,
      customNotes,
      customerOrderNumber,
      accountInfo,
      user,
      pickUpAllAddresses,
    } = this.props;

    //const pickupData = pickUpAllAddresses.data;
    const {
      modalOpen,
      orderPlaced,
      errorMessage,
      isError,
      isLoading,
      openDispatchDetails,
      iAgree,
      pickUpFlag,
      pickUpModalOpen,
      pickupData,
      pickAddressObject,
      initialLoad,
    } = this.state;

    const { customerType, userRole, account = {} } = user;
    const { showPrice = false } = account;
    const { response, overallDiscount } = this.props;
    const { status, data } = response;
    const { stopCredit, needOrderNumber } = accountInfo;
    const { discount = 0 } = overallDiscount;
    let responseJSX = "";
    if (status === 200) {
      return this.orderSuccessSummary(data);
    }

    // Review Move this Modal out
    const selectAddressModelJSX = (
      <Modal
        trigger={
          <a onClick={this.modelOpenHandler} className="change-address">
            Change address
          </a>
        }
        open={modalOpen}
        onClose={this.modelCloseHandler}
        closeIcon
        size="small"
      >
        <Modal.Header>Choose the delivery Addresses</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <List animated verticalAlign="middle">
              {allAddresses &&
                allAddresses.map((address, key) => {
                  const color = address.isPrimary ? "green" : "";
                  return (
                    <List.Item
                      key={address.id}
                      color={color}
                      onClick={(e) => this.changeAddressHandler(e, address)}
                    >
                      <List.Header>
                        <Icon disabled name="truck" />
                        {`${address.street2 ? address.street2 + ", " : ""} ${
                          address.street ? address.street : ""
                        }`}
                      </List.Header>
                      <List.Content>
                        <div>{`${address.city}, ${address.country.name} `}</div>
                        <div>{`${address.zip} `}</div>
                        <Divider horizontal />
                      </List.Content>
                    </List.Item>
                  );
                })}
            </List>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );

    const pickUp = (
      <Modal
        trigger={
          <h4>
            <Message>
              <Message.Content>
                <Checkbox
                  label={"Pick up"}
                  onChange={this.checkBoxAddressHandler}
                />
                {pickUpFlag && (
                  <a
                    onClick={this.modelPickupOpenHandler}
                    className="change-address"
                  >
                    Change pickup address
                  </a>
                )}
              </Message.Content>
            </Message>
          </h4>
        }
        open={pickUpModalOpen}
        onClose={this.modelPickUpCloseHandler}
        closeIcon
        size="small"
      >
        <Modal.Header>Choose the pick-up Addresses</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {pickupData != undefined && (
              <List animated verticalAlign="middle">
                {pickupData.map((address, key) => {
                  const color = address.isPrimary ? "green" : "";
                  return (
                    <List.Item
                      key={address.id}
                      color={color}
                      onClick={(e) =>
                        this.changePickupAddressHandler(e, address)
                      }
                    >
                      <List.Header>
                        <Icon disabled name="truck" />
                        {`${address.street}, ${
                          address.suburb ? address.suburb : ""
                        }`}
                      </List.Header>
                      <List.Content>
                        <div>{`${address.city}, ${address.country} `}</div>
                        <div>{`${address.postalCode} `}</div>
                        <Divider horizontal />
                      </List.Content>
                    </List.Item>
                  );
                })}
              </List>
            )}
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );

    const cartJSX = (
      <div>
        {user.boahubShowPurchasePrice && (
          <Grid>
            <Grid.Column width={16}>
              <div className="total-price">
                <TotalPrice
                  cart={cart}
                  cartCount={this.getCartCount()}
                  account={accountInfo}
                  overallDiscount={discount}
                  subtotal={this.computeSubtotal()}
                />
              </div>
            </Grid.Column>
          </Grid>
        )}
        <Grid columns="equal">
          <Grid.Column computer={8} mobile={16}>
            <Form>
              <Form.Field>
                <h4>
                  <Icon name="pencil alternate" />
                  <label>
                    {" "}
                    PO number <span style={{ color: "red" }}>*</span>
                    {/* {errorMessage && (
                      <span style={{ color: "red" }}>*</span>
                    )}{" "} */}
                  </label>
                </h4>
                <input
                  type="text"
                  className="input-box customer-order-input"
                  maxLength={20}
                  name="customerOrderNumber"
                  onChange={this.onChangeCustomerOrderHandler}
                  value={customerOrderNumber}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16}>
            <Form>
              <Form.Field>
                <h4>
                  <Icon name="pencil alternate" /> <label>Custom notes </label>
                </h4>
                <textarea
                  maxLength={255}
                  rows="4"
                  className="input-box customer-order-input"
                  name="reference"
                  onChange={this.onChangeCustomNotesHandler}
                  value={customNotes}
                />
              </Form.Field>
            </Form>
          </Grid.Column>
        </Grid>
        <Grid columns={2}>
          {/* <Grid.Column computer={8} mobile={16}>
            <p>{pickUp}</p>
          </Grid.Column> */}
        </Grid>
        <Grid centered columns={2}>
          <Grid.Column computer={8} mobile={16}>
            {!pickUpFlag ? (
              <h4>
                <Icon name="truck" /> Delivery address
                <a onClick={this.editAddress} className="linkToPage">
                  Add / Edit
                </a>
                {selectAddressModelJSX}
              </h4>
            ) : (
              <h4>
                <Icon name="truck" /> Delivery address
              </h4>
            )}
            <Divider />
            {!_.isEmpty(deliveryAddress) && (
              <Segment>
                {!pickUpFlag ? (
                  <div>
                    {deliveryAddress.isPrimary && (
                      <Label as="a" color="green" ribbon>
                        <p>Default address</p>
                      </Label>
                    )}
                    <div style={{ paddingTop: "20px" }}>
                      {deliveryAddress.street}
                    </div>
                    <div>{deliveryAddress.street2}</div>
                    <div>{deliveryAddress.suburb}</div>
                    <div>{deliveryAddress.city}</div>
                    <div>{deliveryAddress.country.name}</div>
                    <div>{deliveryAddress.zip}</div>
                  </div>
                ) : (
                  <div>
                    {!_.isEmpty(pickAddressObject) && (
                      <div>
                        <Label as="a" color="green" ribbon>
                          <p> Pickup Address</p>
                        </Label>
                        <div style={{ paddingTop: "20px" }}>
                          {deliveryAddress.street}
                        </div>
                        <div>{deliveryAddress.street2}</div>
                        <div>{deliveryAddress.suburb}</div>
                        <div>{deliveryAddress.city}</div>
                        <div>{deliveryAddress.country.name}</div>
                        <div>{deliveryAddress.zip}</div>
                      </div>
                    )}
                  </div>
                )}
              </Segment>
            )}

            {_.isEmpty(deliveryAddress) && (
              <Message
                icon="warning sign"
                header="Please add delivery address"
                content="You cant place order without delivery address"
              />
            )}
          </Grid.Column>
          {/* <Grid.Column computer={8} mobile={16}></Grid.Column> */}
          <Grid.Column computer={8} mobile={16}>
            <UserRoleCheck userRole={userRole}>
              <h4>
                <Icon name="credit card outline" /> Payment mode
              </h4>
              <Divider />
              <Segment padded>
                {/* {customerType === ENTERPRISE && stopCredit && (
                  <div>
                    <Button disabled fluid size="large">
                      Credit option unavailable for this account. Please get in
                      touch with <Image src={boaLogo} size="mini" spaced /> to
                      know why.
                    </Button>
                    <Divider horizontal>Or</Divider>
                  </div>
                )}
                {(customerType === ENTERPRISE || customerType === RETAIL) &&
                  !stopCredit && (
                    <div>
                      {!isLoading && (
                        <React.Fragment>
                          <Button
                            onClick={() => {
                              this.setState({ creditPaymentOpen: true });
                            }}
                            fluid
                            color="green"
                            size="large"
                          >
                            Use <Image src={boaLogo} size="mini" spaced />
                            credit
                          </Button>
                          <Confirm
                            open={this.state.creditPaymentOpen}
                            content="Are you sure you want to pay with BOA credit"
                            onCancel={() => {
                              this.setState({ creditPaymentOpen: false });
                            }}
                            onConfirm={this.payWithBoa}
                          />
                        </React.Fragment>
                      )}
                      {isLoading && (
                        <Button loading fluid color="green" size="large">
                          Loading
                        </Button>
                      )}
                      <Divider horizontal>Or</Divider>
                    </div>
                  )} */}
                {!isLoading && (
                  <React.Fragment>
                    <Button
                      onClick={() => {
                        this.setState({ paymentConfirmationOpen: true });
                      }}
                      primary
                      fluid
                      size="large"
                    >
                      {/* <Icon name="credit card outline" />
                      <Icon name="credit card alternative" /> */}
                      Submit
                    </Button>
                    <Confirm
                      open={this.state.paymentConfirmationOpen}
                      // content="Are you sure you want to pay through the Payment Gateway? Please dont close the window till after you have completed the payment and returned to this site."
                      content="Are you sure you want to submit your order?"
                      onCancel={() => {
                        this.setState({ paymentConfirmationOpen: false });
                      }}
                      onConfirm={this.payWithBoa}
                    />
                  </React.Fragment>
                )}
                {isLoading && (
                  <Button loading primary size="large" fluid>
                    Loading
                  </Button>
                )}
              </Segment>
            </UserRoleCheck>
          </Grid.Column>
        </Grid>{" "}
      </div>
    );

    const placeOrderJSX = (
      <div>
        {initialLoad && (
          <Loader text="Loading order, please wait">Loading,please wait</Loader>
        )}
        {!initialLoad && (
          <div>
            <Grid>
              <Grid.Column width={16}>
                <h3>
                  <Icon name="cart" />
                  Cart items
                  <Button secondary floated="right" onClick={this.goToCart}>
                    Change items
                  </Button>
                </h3>
              </Grid.Column>
              <Grid.Column width={16}>
                <CartProducts
                  cartProducts={cart}
                  options={false}
                  {...this.props}
                  showPrice={showPrice}
                />
              </Grid.Column>
            </Grid>
            {cartJSX}
          </div>
        )}
      </div>
    );

    return (
      <div className="confirm-order">
        <MetaData title="Order Confirmation" />
        <Grid.Row>
          <h3>
            <Icon name="check square outline" />
            Order confirmation
          </h3>
          <Divider />
        </Grid.Row>
        <ErrorMessageBox isError={isError} message={errorMessage} />
        {status === 200 && orderPlaced && responseJSX}
        {!orderPlaced && placeOrderJSX}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: JSON.parse(localStorage.getItem("selectedCartItems")),
    deliveryAddress: state.orderConfirmation.deliveryAddress,
    allAddresses: state.orderConfirmation.allAddresses,
    reference: state.orderConfirmation.reference,
    customerOrderNumber: state.orderConfirmation.customerOrderNumber,
    customNotes: state.orderConfirmation.customNotes,
    response: state.orderConfirmation.response,
    user: state.auth.user,
    accountInfo: state.orderConfirmation.accountInfo,
    pxPayInitiateResponse: state.orderConfirmation.pxPayInitiateResponse,
    overallDiscount: state.orderConfirmation.overallDiscount,
    pickUpAllAddresses: state.orderConfirmation.pickUpAllAddresses,
    status: state.orderConfirmation.status,
    statusText: state.orderConfirmation.statusText,
  };
};

let reducer = { ...authReducer, ...orderReducer, ...cartReducer };

const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderConfirmationComponent);
