import React, { useEffect, useRef, useState } from "react";
import { Grid, Button, Loader, Popup, Table } from "semantic-ui-react";
import MetaData from "../../../components/MetaData";
import _debounce from "lodash/debounce";
import {
  apiGetStockAdjustmentBulk,
  apiSaveStockAdjustmentBulk,
} from "./bulkAdjustment.api";
import Papa from "papaparse";
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from "../../../utils/toastUtils";

const BulkAdjustment = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [jsonResult, setJsonResult] = useState([]);
  const [payloadData, setPayloadData] = useState([]);
  const [stockAdjustmentsData, setStockAdjustmentsData] = useState([]);
  const [failedImports, setFailedImports] = useState([]);

  const csvRef = useRef();

  const onGetStockAdjustmentBulk = async () => {
    setIsLoading(true);
    const result = await apiGetStockAdjustmentBulk(props.stockLocationId);
    if (result) {
      let productList = [];
      result.map((item, i) => {
        const product = {
          "Product ID": item.id,
          "Product SKU": item.barCode,
          Description: item.name,
          UoM: item.uomData?.name,
          Quantity: "",
        };
        productList.push(product);
      });
      const csvData = convertToCSV(productList);
      downloadCSV(
        csvData,
        `bulk-stock-adjustment-${props.stockLocationId}.csv`
      );
      setStockAdjustmentsData(productList);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  // Convert JSON to CSV format
  function convertToCSV(data) {
    const headers = Object.keys(data[0]);
    const csvRows = [headers.join(",")]; // Create header row

    // Add each row's values
    data.forEach((row) => {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '""'); // Escape double quotes
        return `"${escaped}"`; // Wrap in double quotes
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n"); // Join rows with newlines
  }

  // Trigger CSV download
  function downloadCSV(csv, filename) {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const handleFileChange = (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (file) {
      setFailedImports([]);
      Papa.parse(file, {
        complete: (result) => {
          let resultData = result.data;
          let payloadProducts = [];

          resultData.map(async (product) => {
            // Check if quantity is number
            if (
              !isNaN(product.Quantity) &&
              !isNaN(parseFloat(product.Quantity))
            ) {
              product.Quantity = Number(product.Quantity);

              // Check if uom is not Metre and prevent decimal value
              if (
                product.UoM.toLowerCase() !== "metre" &&
                product.Quantity === product.Quantity &&
                product.Quantity % 1 !== 0
              ) {
                product.Quantity = Math.trunc(product.Quantity);
              } else {
                product.Quantity = Math.trunc(product.Quantity * 10) / 10;
              }
            }

            product.productId = product["Product ID"];
            product.adjustedQty = product.Quantity;
          });

          let filteredData = resultData.filter((obj) => {
            return (
              obj.Quantity !== undefined &&
              obj.Quantity !== null &&
              obj.Quantity !== ""
            );
          });

          filteredData.map(async (product) => {
            const productData = {
              productId: Number(product["Product ID"]),
              adjustedQty:
                product.adjustedQty || product.adjustedQty === 0
                  ? product.adjustedQty
                  : null,
            };
            payloadProducts.push(productData);
          });

          setJsonResult(filteredData);
          setPayloadData(payloadProducts);
        },
        header: true, // Set this to true if your CSV file has headers
      });
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  const onImportBulkStockAdjustment = async () => {
    // Validate all payload data for errors
    let errorCount = 0;
    payloadData.map((data) => {
      if (hasError(data.adjustedQty)) errorCount++;
      if (hasProductIdError(data.productId)) errorCount++;
    });
    if (errorCount > 0) {
      showErrorToast("Please correct all data before importing");
      return false;
    }

    setIsImporting(true);
    setFailedImports([]);
    try {
      const data = await apiSaveStockAdjustmentBulk(
        payloadData,
        props.stockLocationId
      );
      if (data) {
        if (data.failedImports?.length > 0) {
          setFailedImports(data.failedImports);
          let failedProducts = [];
          data.failedImports.map((failedProduct) => {
            jsonResult.map((product) => {
              if (
                failedProduct.productId.toString() === product["Product ID"]
              ) {
                product.importReport = failedProduct.importReport;
                failedProducts.push(product);
              }
            });
          });
          setJsonResult(failedProducts);
          setPayloadData(data.failedImports);
          setIsImporting(false);
          showWarningToast(
            <div>
              <i className="check icon" /> Stock Adjustment Imported
              Successfully with Errors: Some records were not imported.
            </div>
          );
        } else {
          showSuccessToast(
            <div>
              <i className="check icon" /> Stock Adjustment Imported
              Successfully
            </div>
          );
          props.setBulkAdjustmentOpen(false);
          setIsImporting(false);
          setJsonResult([]);
          setPayloadData([]);
        }
      } else {
        showErrorToast("Something went wrong");
        setIsImporting(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsImporting(false);
    }
  };

  // Error checker for quantity field
  function hasError(quantity) {
    let hasError = false;

    if (isNaN(quantity)) {
      hasError = true;
    }
    if (!Number.isFinite(Number(parseFloat(quantity)))) {
      hasError = true;
    }
    return hasError;
  }

  function hasProductIdError(productId) {
    if (/[^0-9]/.test(productId)) return true;
  }

  const headerStyle = {
    position: "sticky",
    top: 0,
    backgroundColor: "white",
    zIndex: 1,
  };

  return (
    <React.Fragment>
      <MetaData title="Inventory" />
      <div style={{ position: "absolute", top: 250, left: 625 }}>
        {isLoading ? (
          <Loader
            active
            className="workaround"
            size="large"
            inline="centered"
          />
        ) : null}
      </div>
      <div disabled={isLoading} style={{ opacity: isLoading ? 0.3 : 1 }}>
        <Grid style={{ margin: 20, marginTop: 10, marginBottom: 0 }}>
          <Grid.Column
            width={16}
            style={{
              minHeight: 400,
              maxHeight: 400,
              overflowY: "auto",
              paddingTop: 0,
            }}
          >
            {jsonResult.length > 0 ? (
              <Table sortable celled fixed>
                <Table.Header style={{ fontSize: 15 }}>
                  <Table.Row>
                    <Table.HeaderCell style={headerStyle} textAlign="center">
                      Product ID
                    </Table.HeaderCell>
                    <Table.HeaderCell style={headerStyle} textAlign="center">
                      Product SKU
                    </Table.HeaderCell>
                    <Table.HeaderCell
                      style={headerStyle}
                      textAlign="center"
                      width={6}
                    >
                      Description
                    </Table.HeaderCell>
                    <Table.HeaderCell style={headerStyle} textAlign="center">
                      UoM
                    </Table.HeaderCell>
                    <Table.HeaderCell style={headerStyle} textAlign="center">
                      Quantity
                    </Table.HeaderCell>
                    {failedImports.length > 0 ? (
                      <Table.HeaderCell textAlign="center">
                        Remarks
                      </Table.HeaderCell>
                    ) : null}
                  </Table.Row>
                </Table.Header>
                <Table.Body style={{ fontSize: 13 }}>
                  {jsonResult.map((product) => (
                    <Table.Row key={product["Product ID"]}>
                      <Table.Cell
                        textAlign="center"
                        style={{
                          border: hasProductIdError(product["Product ID"])
                            ? "solid 2.5px red"
                            : "",
                        }}
                      >
                        {hasProductIdError(product["Product ID"]) ? (
                          <Popup
                            content={"Product ID must be numeric"}
                            trigger={<div>{product["Product ID"]}</div>}
                            position="top center"
                            inverted
                          />
                        ) : (
                          product["Product ID"]
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {product["Product SKU"]}
                      </Table.Cell>
                      <Table.Cell>{product["Description"]}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {product["UoM"]}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="center"
                        style={{
                          border: hasError(product.Quantity)
                            ? "solid 2.5px red"
                            : "",
                        }}
                      >
                        {/* Show popup message for data errors */}
                        {!Number.isFinite(
                          Number(parseFloat(product.Quantity))
                        ) ? (
                          <Popup
                            content={"Quantity must be a number"}
                            trigger={<div>{product.Quantity}</div>}
                            position="top center"
                            inverted
                          />
                        ) : isNaN(product.Quantity) ? (
                          <Popup
                            content={"Quantity must be a number"}
                            trigger={<div>{product.Quantity}</div>}
                            position="top center"
                            inverted
                          />
                        ) : (
                          product.Quantity
                        )}
                      </Table.Cell>
                      {failedImports.length > 0 ? (
                        <Table.Cell
                          textAlign="center"
                          style={{
                            border: "solid 2.5px red",
                          }}
                        >
                          {product.importReport}
                        </Table.Cell>
                      ) : null}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <div
                style={{
                  paddingTop: 100,
                  width: 550,
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontSize: 22,
                  color: "#AFABAB",
                  lineHeight: 2.5,
                }}
              >
                <span>1. Download File for Bulk Adjustment as CSV</span>
                <br />
                <span>2. Edit the CSV file locally</span>
                <br />
                <span>3. Upload the CSV file for preview</span>
              </div>
            )}
          </Grid.Column>
          <Grid.Column width={7} style={{ paddingRight: 5, paddingTop: 35 }}>
            <Button
              size="small"
              style={{ paddingLeft: 50, paddingRight: 50 }}
              onClick={() => onGetStockAdjustmentBulk()}
            >
              Download File
            </Button>
          </Grid.Column>
          <Grid.Column
            width={9}
            style={{ paddingLeft: 5, textAlign: "right", paddingTop: 35 }}
          >
            <Button
              size="small"
              color="youtube"
              onClick={() => {
                setJsonResult([]);
                props.setBulkAdjustmentOpen(false);
              }}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              style={{
                backgroundColor: "#66CC23",
                color: "#FFF",
                marginRight: 10,
              }}
              onClick={() => csvRef.current.click()}
            >
              Load New File
            </Button>
            {/* Rerender upload file so user can upload the same file after */}
            {!isLoading ? (
              <input
                ref={csvRef}
                type="file"
                hidden
                onChange={handleFileChange}
              />
            ) : null}
            <input
              ref={csvRef}
              type="file"
              hidden
              onChange={handleFileChange}
            />
            <Button
              disabled={jsonResult.length === 0 || isLoading}
              size="small"
              style={{
                backgroundColor: "rgb(102, 204, 35)",
                color: "#fff",
                marginLeft: "auto",
              }}
              onClick={() => onImportBulkStockAdjustment()}
            >
              {!isImporting ? "Import" : "Importing..."}
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default BulkAdjustment;
