import _ from "lodash";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Control, Errors } from "react-redux-form";
import NumericInput from "react-numeric-input";
import Loader from "../../components/Loader";
import * as assetReducer from "../Asset/asset.reducer";
import * as inventoryReducer from "./inventory.reducer";
import * as assetDetailReducer from "../AssetDetails/assetDetail.reducer";

import LocationDropdown from "../../components/LocationDropdown";
import {
  Grid,
  Divider,
  Form,
  Button,
  Message,
  Dropdown
} from "semantic-ui-react";

const ATTACH_TO_ASSET = "attachToAssetForm";
let positionList = [];

class AttachToAsset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      positionList,
      isDropDownFlag: false,
      value: 1,
      stockId: "",
      inventoryType: "",
      location: "",
      searchText: ""
    };
  }
  componentDidMount = async () => {
    await this.props.actionGetAllAsset();
    await this.setQuantity(1);
    const { individualStock } = this.props;
    const {
      hoseId = "",
      hoseUniqueCode = "",
      type,
      description = ""
    } = individualStock;
    if (type === "HOSE_ASSEMBLY") {
      await this.props.actionInventorySetFormField(
        `${ATTACH_TO_ASSET}.hoseId`,
        hoseId
      );
      await this.props.actionInventorySetFormField(
        `${ATTACH_TO_ASSET}.hoseUniqueCode`,
        hoseUniqueCode
      );
      await this.props.actionInventorySetFormField(
        `${ATTACH_TO_ASSET}.description`,
        description
      );
    }
  };

  setAssetId = async (e, data) => {
    const { value } = data;
    await this.props.actionInventorySetFormField(
      `${ATTACH_TO_ASSET}.assetId`,
      value
    );
    const { forms } = this.props;
    const { attachToAssetForm } = forms;
    const { assetId } = attachToAssetForm;
    await this.props.actionGetAttachedHoses(assetId);
    this.setState({
      isDropDownFlag: true
    });
  };

  setLocation = (e, data) => {
    const { value } = data;
    this.props.actionInventorySetFormField(
      `${ATTACH_TO_ASSET}.location`,
      value
    );
  };

  setQuantity = value => {
    if (this.props.individualStock.type === "HOSE_ASSEMBLY") {
      this.props.actionSetQuantity(`${ATTACH_TO_ASSET}.quantity`, value);
    } else {
      let value = "";
      this.props.actionSetQuantity(`${ATTACH_TO_ASSET}.quantity`, value);
    }
  };

  attachToAsset = async () => {
    const { forms } = this.props;
    const { attachToAssetForm } = forms;

    this.setState({ isLoading: true });
    await this.props.actionAssignStock(attachToAssetForm);
    this.setState({ isLoading: false });
  };

  clearErrorMessage = () => {
    this.setState({
      errorMessageflag: false,
      isError: false,
      errorMessage: ""
    });
  };

  handleChangePosition = (e, data) => {
    const { value } = data;
    this.props.actionSetPositionField("position", value);
    this.setState({ position: value });
    this.props.actionInventorySetFormField(
      `${ATTACH_TO_ASSET}.position`,
      value
    );
  };

  handleAddition = (e, { value }) => {
    this.setState({
      positionList: [{ text: value, value }, ...this.state.positionList]
    });
  };

  quantityChangeHandler = (e, data) => {
    this.props.actionSetQuantity(`${ATTACH_TO_ASSET}.quantity`, e);
  };

  render() {
    const { isLoading, isDropDownFlag } = this.state;
    const {
      forms,
      stock,
      assets,
      attachedHoses,
      isError,
      errorMessage
    } = this.props;
    const { location, assetId, position } = forms.attachToAssetForm;
    const { locationQuantity } = stock;
    let { positionList } = this.state;
    const fromLocations = [];
    let assetList = [];
    if (assets && assets.length) {
      _.map(assets, asset => {
        assetList.push({
          text: asset.name,
          value: asset._id
        });
      });
    }

    if (attachedHoses && attachedHoses.length) {
      _.map(attachedHoses, hose => {
        positionList.push({
          text: hose.position,
          value: hose.position
        });
      });
      positionList = _.uniqBy(positionList, name => {
        return name.text;
      });
    }

    Object.keys(locationQuantity).forEach(function(key) {
      if (locationQuantity[key] != null && locationQuantity[key] > -1) {
        fromLocations.push({ name: key, quantity: locationQuantity[key] });
      }
    });

    const assetName = (
      <Form.Field required>
        <label>Asset Name</label>

        <Dropdown
          placeholder="Select asset"
          fluid
          value={assetId}
          selection
          required
          onChange={this.setAssetId}
          options={assetList}
        />
      </Form.Field>
    );

    const positionOfHose = (
      <Grid width={16}>
        <Grid.Column width={16}>
          <Form.Field required>
            <label>Hose Position</label>
            <Dropdown
              fluid
              required
              allowAdditions
              selection
              placeholder="Hose position"
              options={positionList}
              onChange={this.handleChangePosition}
              onAddItem={this.handleAddition}
              value={position}
              search
              disabled={!isDropDownFlag}
            />
          </Form.Field>
        </Grid.Column>
      </Grid>
    );
    const locationDetail = (
      <Form.Field required>
        <label>Inventory Location</label>
        <LocationDropdown
          setLocation={this.setLocation}
          locationId={location}
          locations={fromLocations}
        />
      </Form.Field>
    );

    const quantity = (
      <Form.Field required>
        <label>Quantity</label>
        <NumericInput
          min={1}
          max={10000}
          maxLength={5}
          className="form-control"
          precision={0}
          validateOn="change"
          placeholder="Quantity"
          model="forms.attachToAssetForm.quantity"
          required
          onChange={this.quantityChangeHandler}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.attachToAssetForm.quantity"
          show="touched"
          messages={{
            valueMissing: "quantity is required",
            rangeUnderflow: "Please enter valid quantity"
          }}
        />
      </Form.Field>
    );

    const hoseQuantity = (
      <Form.Field>
        <label>Quantity</label>
        <Control
          type="number"
          model="forms.attachToAssetForm.quantity"
          placeholder="Quantity"
          validateOn="change"
          required
          className="input-box"
          value={1}
          min={1}
          disabled={true}
        />

        <Errors
          style={{ color: "red" }}
          model="forms.attachToAssetForm.quantity"
          show="touched"
          messages={{
            valueMissing: "quantity is required",
            rangeUnderflow: "Please enter valid quantity"
          }}
        />
      </Form.Field>
    );
    return (
      <div>
        {isLoading && (
          <Loader title="Loading, please wait">Loading,please wait</Loader>
        )}
        <Form
          error={isError}
          model="form.attachToAssetForm"
          onSubmit={this.props.attachToAsset}
          style={{ width: "100%" }}
        >
          <Grid columns="equal">
            <Grid.Column width={8} computer={8} mobile={16}>
              {assetName}
            </Grid.Column>

            {this.props.individualStock.type === "HOSE_ASSEMBLY" ? (
              <Grid.Column width={8} computer={8} mobile={16}>
                {positionOfHose}
              </Grid.Column>
            ) : null}

            <Grid.Column width={8} computer={8} mobile={16}>
              {locationDetail}
            </Grid.Column>

            {this.props.individualStock.type === "HOSE_ASSEMBLY" ? (
              <Grid.Column width={8} computer={8} mobile={16}>
                {hoseQuantity}
              </Grid.Column>
            ) : (
              <Grid.Column width={8} computer={8} mobile={16}>
                {quantity}
              </Grid.Column>
            )}

            <Grid.Column width={16} computer={16} mobile={16}>
              <Divider />
            </Grid.Column>
          </Grid>
          <Message error={true} content={errorMessage} />
          <Grid.Column
            width={16}
            computer={16}
            mobile={16}
            style={{ textAlign: "right" }}
          >
            <Button
              size="small"
              onClick={this.props.closeForm}
              content="Close"
            />
            {!isLoading && (
              <Button secondary size="small" content="Submit" type="submit" />
            )}
            {isLoading && (
              <Button secondary size="small" content="Submit" loading />
            )}
          </Grid.Column>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allInventory: state.inventory.allInventory,
    assets: state.assets.assets,
    stock: state.inventory.stock,
    forms: state.forms,
    attachedHoses: state.assetDetail.attachedHoses,
    response: state.inventory.response
  };
};

const reducer = { ...inventoryReducer, ...assetReducer, ...assetDetailReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AttachToAsset)
);
