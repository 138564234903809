import _ from "lodash";
import SellStock from "./SellStock";
import { connect } from "react-redux";
import React, { Component } from "react";
import ConsumeStock from "./ConsumeStock";
import { bindActionCreators } from "redux";
import AttachToAsset from "./AttachToAsset";
import ResultRenderer from "./ResultRender";
import Loader from "../../components/Loader";
import MetaData from "../../components/MetaData";
import TransferLocation from "./TransferLocation";
import * as inventoryReducer from "./inventory.reducer";
import { getErrorMessage } from "../../utils/error.utils";
import { showSuccessToast } from "../../utils/toastUtils";
import StockActivity from "../../components/StockActivity";
import InventoryList from "../../components/InventoryList";
import {
  Grid,
  Divider,
  Icon,
  Button,
  Modal,
  Search,
  Pagination,
  Dropdown
} from "semantic-ui-react";
import * as locationReducer from "../InventoryLocation/inventoryLocation.reducer";
import LocationDropdown from "../../components/LocationDropdown";
import "./inventory.css";

const ID = "stockId";
const STOCK_CODE = "stockCode";
const DESCRIPTION = "stockDescription";
const PRICE = "price";
const TRANSFER_FORM = "transferLocationForm";
const SELL_STOCK = "sellStockForm";
const CONSUME_STOCK = "consumeStockForm";
const ATTACH_TO_ASSET = "attachToAssetForm";

const paginationLimit = 20;
class Inventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      formHeader: "",
      type: "",
      isError: false,
      errorMessage: "",
      stockId: "",
      inventoryType: "",
      location: "",
      activePage: 1,
      value: "",
      inventoryIndex: ""
    };
  }

  componentDidMount = async () => {
    this.getInventories(0);
    this.props.actionGetAllLocationToDropdown();
    this.props.actionsGetTypes();
  };

  getInventories = async pageNumber => {
    const { stockId, inventoryType, location, value: searchText } = this.state;
    this.setState({ isLoading: true });
    await this.props.actionGetInventories(
      paginationLimit,
      pageNumber,
      stockId,
      inventoryType,
      location,
      searchText
    );
    this.setState({ isLoading: false });
  };

  goToAddStock = () => {
    this.props.history.push("/stock");
  };

  goToPageBack = path => {
    this.props.history.goBack();
  };

  goToPage = path => {
    let url = `/${path}`;
    this.props.history.push(url);
  };

  updateState = (isError, errorMessage) => {
    this.setState({ isError, errorMessage });
  };

  openAddStock = inventory => {
    this.addParamsToUrl({ inventory });
  };

  addParamsToUrl = ({ inventory }) => {
    let { _id, stockCode, description, price } = inventory;
    this.props.history.push({
      pathname: "/stock",
      search: `?${ID}=${_id}&${STOCK_CODE}=${stockCode}&${DESCRIPTION}=${description}&${PRICE}=${price}`
    });
  };

  openForm = (stock, formHeader, type) => {
    this.setState({
      formHeader,
      type,
      isError: false,
      errorMessage: "",
      individualStock: stock
    });
    const { stockCode } = stock;
    this.props.actionSetStock(stock);
    if (type === "transfer") {
      this.props.actionResetForm(TRANSFER_FORM);
      this.props.actionInventorySetFormField(
        `${TRANSFER_FORM}.stockCode`,
        stockCode
      );
    }
    if (type === "sell") {
      this.setInitialSellValue(stock);
    }

    if (type === "consume") {
      this.props.actionResetForm(CONSUME_STOCK);
      this.props.actionInventorySetFormField(
        `${CONSUME_STOCK}.stockCode`,
        stockCode
      );
    }
    if (type === "attach") {
      this.props.actionResetForm(ATTACH_TO_ASSET);
      this.props.actionInventorySetFormField(
        `${ATTACH_TO_ASSET}.stockCode`,
        stockCode
      );
    }

    this.props.actionToggleModal(true);
  };

  setInitialSellValue = stock => {
    let { stockCode, price } = stock;
    this.props.actionResetForm(SELL_STOCK);
    this.props.actionInventorySetFormField(
      `${SELL_STOCK}.stockCode`,
      stockCode
    );

    const { account } = this.props;
    const { priceMargin } = account;
    if (priceMargin && price) {
      let margin = (price * priceMargin) / 100;
      price = (price + margin).toFixed(2);
    }
    this.props.actionInventorySetFormField(`${SELL_STOCK}.price`, price);
  };

  closeForm = formName => {
    this.props.actionResetForm(formName);
    this.props.actionToggleModal(false);
  };

  transferLocation = async () => {
    const { forms } = this.props;
    const { transferLocationForm } = forms;
    const { quantity } = transferLocationForm;
    if (
      transferLocationForm.toLocation === "" ||
      transferLocationForm.fromLocation === ""
    ) {
      let message = await getErrorMessage(1105);
      return this.updateState(true, message);
    }

    if (quantity === 0) {
      this.setState({
        isError: true,
        errorMessage: "Quantity can not be 0"
      });
      return;
    }

    this.setState({ isLoading: true });
    await this.props.actionTransferLocation(transferLocationForm);
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      showSuccessToast("Location Transfer successfully");
      this.setState({ isLoading: false });
    } else {
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
      // return this.setState({
      //   isError: true,
      //   errorMessage: message
      // });
    }
    this.closeForm(TRANSFER_FORM);
    this.props.actionGetInventories();
  };

  sellStock = async () => {
    this.setState({ isLoading: true });
    const { forms } = this.props;
    const { sellStockForm } = forms;
    const { quantity } = sellStockForm;
    if (sellStockForm.location === "") {
      let message = await getErrorMessage(1105);
      return this.updateState(true, message);
    }
    if (quantity === 0) {
      this.setState({
        isError: true,
        errorMessage: "Quantity can not be 0"
      });
      return;
    }

    await this.props.actionSellStock(sellStockForm);
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      showSuccessToast("You have sold a stack");
      this.setState({ isLoading: false });
    } else {
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
    this.closeForm(SELL_STOCK);
    this.props.actionGetInventories();
  };

  consumeStock = async () => {
    this.setState({ isLoading: true });
    const { forms } = this.props;
    const { consumeStockForm } = forms;
    const { location, quantity } = consumeStockForm;
    if (consumeStockForm.location === "") {
      let message = await getErrorMessage(1105);
      return this.updateState(true, message);
    }
    if (!location) {
      this.setState({
        isError: true,
        errorMessage: "From is required"
      });
      return;
    }
    if (quantity === 0) {
      this.setState({
        isError: true,
        errorMessage: "Quantity can not be 0"
      });
      return;
    }
    await this.props.actionConsumeStock(consumeStockForm);

    const { response } = this.props;
    const { status } = response;
    if (status === 200) {
      showSuccessToast("Your have consumed a stock");
      this.setState({ isLoading: false });
    } else {
      const { data } = response;
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
    this.closeForm(CONSUME_STOCK);
    this.props.actionGetInventories();
  };

  attachToAsset = async () => {
    this.setState({ isLoading: true });
    const { forms } = this.props;
    const { attachToAssetForm } = forms;
    const { quantity } = attachToAssetForm;
    if (attachToAssetForm.assetId === "" || attachToAssetForm.location === "") {
      let message = await getErrorMessage(1105);
      return this.updateState(true, message);
    }
    if (quantity === 0) {
      this.setState({
        isError: true,
        errorMessage: "Quantity can not be  0"
      });
      return;
    }
    await this.props.actionAssignStock(attachToAssetForm);
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      showSuccessToast("Your have attached a stock");
      this.setState({ isLoading: false });
    } else {
      const { code } = data;
      if (code === 1102) {
        return this.setState({
          isError: true,
          errorMessage: "Selected location does not have sufficient stock"
        });
      }
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
    this.closeForm(ATTACH_TO_ASSET);
    await this.props.actionGetInventories();
  };

  handleResultSelect = (e, { result }) => {
    const { stockCode, _id } = result;

    const value = `${stockCode}`;
    this.setState({ stockId: _id, value });
  };

  handleSearchChange = async (e, { value }) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (!value) {
      this.setState({ value, isLoading: false });
      return this.getInventories(0);
    }
    await this.props.actionSearchStock(value);
    this.setState({ value, searchMessage: null, isLoading: false });
  };

  handlePaginationChange = (...params) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;
    const { totalPages } = this.props;

    if (totalPages === 1) {
      return;
    }

    this.setState({ activePage });
    this.getInventories(activePage - 1);
  };

  setType = (e, data) => {
    const { value } = data;
    this.setState({ inventoryType: value });
  };

  setLocation = (e, data) => {
    const { value } = data;
    this.setState({ location: value });
  };

  clearFilter = async () => {
    await this.setState({
      stockId: "",
      inventoryType: "",
      location: "",
      value: ""
    });
    this.getInventories(0);
  };

  textHandle = event => {
    // var newValue = event.target.value;
    const { target } = event;
    const { value } = target;
    let newValue = value;
    if (newValue.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  };

  render() {
    const {
      isLoading,
      formHeader,
      type,
      isError,
      errorMessage,
      value,
      activePage,
      inventoryType,
      location
    } = this.state;
    const {
      allInventory,
      isModalOpen,
      searchSuggestion,
      stockType,
      locations
    } = this.props;
    const { totalPages, inventories, stockActivity } = allInventory;

    const allStock = [];

    _.map(stockType, type => {
      allStock.push({ text: type, value: type });
    });

    const ActionButton = (
      <React.Fragment>
        <Button
          color="black"
          size="small"
          //icon="clipboard list"
          onClick={() => this.goToPage("hoseMechanic")}
          content="Hose Mechanic"
          className="inventory-Button"
        />
        <Button
          //fluid
          color="black"
          size="small"
          icon="clipboard"
          onClick={() => this.goToPage("audit")}
          content="Audits"
          className="inventory-Button"
        />
        <Button
          color="black"
          size="small"
          icon="location arrow"
          onClick={() => this.goToPage("inventory/location")}
          content="Inventory Location"
          className="inventory-Button"
        />

        <Button
          color="black"
          size="small"
          icon="archive"
          onClick={() => this.goToPage("stock")}
          content="Add stock"
          className="inventory-Button"
        />
        <Button
          color="black"
          size="small"
          icon="table"
          onClick={() => this.goToPage("stockReview")}
          content="Review stock"
          className="inventory-Button"
        />
      </React.Fragment>
    );

    const OpenModal = (
      <Modal open={isModalOpen} onClose={() => this.closeForm()} closeIcon>
        <Modal.Header>{formHeader}</Modal.Header>
        <Modal.Content>
          {type === "transfer" && (
            <TransferLocation
              closeForm={this.closeForm}
              transferLocation={this.transferLocation}
              isError={isError}
              errorMessage={errorMessage}
            />
          )}

          {type === "sell" && (
            <SellStock
              closeForm={this.closeForm}
              isError={isError}
              errorMessage={errorMessage}
              sellStock={this.sellStock}
            />
          )}
          {type === "consume" && (
            <ConsumeStock
              closeForm={this.closeForm}
              consumeStock={this.consumeStock}
              isError={isError}
              errorMessage={errorMessage}
            />
          )}
          {type === "attach" && (
            <AttachToAsset
              closeForm={this.closeForm}
              attachToAsset={this.attachToAsset}
              isError={isError}
              errorMessage={errorMessage}
              individualStock={this.state.individualStock}
            />
          )}
        </Modal.Content>
      </Modal>
    );

    const searchBox = (
      <Search
        loading={isLoading}
        className="search-box"
        placeholder="Search by stock code"
        resultRenderer={ResultRenderer}
        onResultSelect={this.handleResultSelect}
        onSearchChange={_.debounce(this.handleSearchChange, 500, {
          leading: true
        })}
        noResultsMessage="No direct matches found."
        results={searchSuggestion}
        value={value}
      // {...this.props}
      />
    );

    const typeFilter = (
      <Dropdown
        placeholder="Select type"
        fluid
        value={inventoryType}
        selection
        options={allStock}
        onChange={this.setType}
      />
    );

    const locationFilter = (
      <LocationDropdown
        setLocation={this.setLocation}
        locationId={location}
        locations={locations}
      />
    );

    const searchButton = (
      <div style={{ textAlign: "right" }}>
        <Button content="Clear" size="small" onClick={this.clearFilter} />
        <Button
          content="Search"
          onClick={() => this.getInventories(0)}
          size="small"
          color="black"
        />
      </div>
    );

    return (
      <div className="my-inventory">
        <MetaData title="Asset" />
        {isLoading && (
          <Loader title="Loading, please wait">Loading,please wait</Loader>
        )}
        <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
          <Grid className="my-location-header">
          <Grid.Column tablet={10} computer={10} mobile={16}>
            <h3>
              <Icon name="chart bar" />
              Inventory
            
            </h3>
          </Grid.Column>
          <Grid.Column
                  textAlign="right"
                  width={6}
                  mobile={16}
                  computer={6}
                >
                  <Button
                    size="tiny"
                    onClick={this.goToPageBack}
                    icon="left arrow"
                    content="Go back"
                    labelPosition="left"
                    className="button-float"
                  />
                </Grid.Column>
                </Grid>
                </Grid.Column>
                </Grid.Row>
                <Divider />
          <Grid.Column
            width={16}
            textAlign="center"
            className="no-top-padded"
            //style={{ paddingBottom: "0px" }}
          >

            <h3 >Coming soon</h3>
          </Grid.Column>

          {/* <Grid.Column
            width={16}
            className="no-top-padded"
            style={{ paddingBottom: "0px" }}
          >
            <StockActivity stockActivity={stockActivity} />
            <Divider />
          </Grid.Column> */}

          {/* <Grid.Column textAlign="right" width={16}>
            {ActionButton}
          </Grid.Column>

          <Grid.Column width={16} className="no-top-padded">
            <Grid>
              <Grid.Column computer={6} mobile={16} tablet={6}>
                {searchBox}
              </Grid.Column>
              <Grid.Column computer={3} mobile={16} tablet={3}>
                {typeFilter}
              </Grid.Column>
              <Grid.Column computer={3} mobile={16} tablet={3}>
                {locationFilter}
              </Grid.Column>
              <Grid.Column computer={4} mobile={16} tablet={4}>
                {searchButton}
              </Grid.Column>
            </Grid>
            <Divider />
          </Grid.Column>

          <Grid.Column
            width={16}
            mobile={16}
            tablet={16}
            className="no-top-padded"
            style={{ paddingTop: "0px" }}
          >
            <InventoryList
              inventories={inventories}
              goToAddStock={this.goToAddStock}
              openForm={this.openForm}
              openAddStock={this.openAddStock}
            />
          </Grid.Column>
          <Grid.Column width={16} mobile={16} tablet={16}>
            <div>
              {!_.isEmpty(inventories) ? (
                <Pagination
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={this.handlePaginationChange}
                  totalPages={totalPages}
                  firstItem={null}
                  lastItem={null}
                />
              ) : null}
            </div>
          </Grid.Column> */}
        </Grid>
        {/* 
        <Grid>
          <Grid.Column width={16} mobile={16} tablet={8}>
            {OpenModal}
          </Grid.Column>
        </Grid> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchSuggestion: state.inventory.searchSuggestion,
    allInventory: state.inventory.allInventory,
    isModalOpen: state.inventory.isModalOpen,
    locations: state.inventoryLocation.locations,
    response: state.inventory.response,
    stockType: state.inventory.stockTypes,
    account: state.auth.user.account,
    forms: state.forms
  };
};

const reducer = { ...inventoryReducer, ...locationReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Inventory);
