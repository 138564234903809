import {
  apiGetAllFilteredOrders,
  apiGetLocations,
  apiAddToInventory
} from "./orderHistory.api";

export const SET_ORDER_LIST = "SET_ORDER_LIST";
export const SET_TO_DATE = "SET_TO_DATE";
export const SET_FROM_DATE = "SET_FROM_DATE";
export const SET_LOCATIONS = "SET_LOCATIONS";
export const SET_ORDER_NO = "SET_ORDER_NO";
export const SET_LOCATION_ID = "SET_LOCATION_ID";
export const TOGGLE_INVENTORY_FORM = "TOGGLE_INVENTORY_FORM";
export const ADD_TO_INVENTORY = "ADD_TO_INVENTORY";

const initialState = {
  allOrders: [],
  toDate: null,
  fromDate: null,
  totalPages: 1,
  locations: [],
  orderNo: "",
  locationId: "",
  isAddInventory: false,
  response: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_LIST: {
      const { totalPages, orderHistory } = action.payload;
      return {
        ...state,
        allOrders: orderHistory,
        totalPages: totalPages
      };
    }
    case SET_TO_DATE: {
      return {
        ...state,
        toDate: action.payload
      };
    }
    case SET_FROM_DATE: {
      return {
        ...state,
        fromDate: action.payload
      };
    }
    case SET_LOCATIONS: {
      return {
        ...state,
        locations: action.payload
      };
    }
    case SET_ORDER_NO: {
      return {
        ...state,
        orderNo: action.payload
      };
    }
    case SET_LOCATION_ID: {
      return {
        ...state,
        locationId: action.payload
      };
    }
    case TOGGLE_INVENTORY_FORM: {
      return {
        ...state,
        isAddInventory: action.payload
      };
    }
    case ADD_TO_INVENTORY: {
      return {
        ...state,
        response: action.payload
      };
    }
    default:
      return state;
  }
};

export const actionGetAllOrders = (
  searchTerm,
  status,
  fromDate,
  toDate,
  limit,
  pageNumber
) => {
  return async dispatch => {
    try {
      const result = await apiGetAllFilteredOrders(
        searchTerm,
        status,
        fromDate,
        toDate,
        limit,
        pageNumber
      );
      const { data } = result;
      dispatch({ type: SET_ORDER_LIST, payload: data });
    } catch (e) {
      dispatch({ type: SET_ORDER_LIST, payload: e });
    }
  };
};

export const actionSetToDate = toDate => {
  return dispatch => {
    dispatch({ type: SET_TO_DATE, payload: toDate });
  };
};

export const actionSetFromDate = toDate => {
  return dispatch => {
    dispatch({ type: SET_FROM_DATE, payload: toDate });
  };
};

export const actionGetAllLocation = () => {
  return async dispatch => {
    try {
      const result = await apiGetLocations();
      dispatch({ type: SET_LOCATIONS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_LOCATIONS, payload: [] });
    }
  };
};

export const setLocationId = id => {
  return dispatch => {
    dispatch({ type: SET_LOCATION_ID, payload: id });
  };
};

export const setOrderNo = orderNo => {
  return dispatch => {
    dispatch({ type: SET_ORDER_NO, payload: orderNo });
  };
};

export const toggleInventoryForm = flag => {
  return dispatch => {
    dispatch({ type: TOGGLE_INVENTORY_FORM, payload: flag });
  };
};

export const actionAddToInventory = data => {
  return async dispatch => {
    try {
      const result = await apiAddToInventory(data);
      if (result.response && result.response.status !== 200) {
        dispatch({
          type: ADD_TO_INVENTORY,
          payload: result.response
        });
      } else {
        dispatch({ type: ADD_TO_INVENTORY, payload: result });
      }
    } catch (e) {
      dispatch({
        type: ADD_TO_INVENTORY,
        payload: e.response
      });
    }
  };
};
