import React from "react";
import "./hisDropdown.css";

const HISDropdown = ({ title, dataList, onSelect }) => {
  return (
    dataList !== null &&
    dataList.data.length > 0 && (
      <div style={{ marginBottom: "20px" }}>
        <div className="model_label">{title}</div>
        <select onChange={(e) => onSelect(e.target.value)}>
          <option value="">==== SELECT ====</option>
          {dataList.data.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
    )
  );
};

export default HISDropdown;
