import axios from "axios";
import * as API from "../../constants/apiLink";

export const apiGetCustomerHoses = activePage => {
  const url =
    API.HOSE_MECHANIC +
    "/" +
    API.GET_HOSE_MECHANIC_HOSES +
    `?activePage=${activePage}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiCheckHoseItems = (hoseId, location) => {
  const url =
    API.INVENTORY +
    API.CHECK_HOSE_ITEMS +
    `?hoseId=${hoseId}&location=${location}`;
  return axios.get(url).then(data => {
    return data;
  });
};
export const apiAddHoseItems = (hoseId, location) => {
  const url =
    API.INVENTORY +
    API.ADD_HOSE_ITEMS +
    `?hoseId=${hoseId}&location=${location}`;
  return axios.get(url).then(data => {
    return data;
  });
};

export const apiSaveToHoseMechanic = hoseData => {
  const url = API.HOSE_MECHANIC + "/" + API.SAVE_TO_HOSE_MECHANIC;
  return axios.post(url, hoseData).then(data => {
    return data;
  });
};

export const apiUpdateHose = (hoseData) => {
  const url = API.HOSE_MECHANIC + "/" + API.UPDATE_HOSE_MECHANIC_HOSE;
  return axios.put(url, hoseData).then((data) => {
    return data;
  });
};


export const apiUpdateHoseConfirmationData = (hoseData) => {
  const url = API.HOSE_MECHANIC + "/" + API.UPDATE_HOSE_CONFIRMATION_DATA;
  return axios.put(url, hoseData).then((data) => {
    return data;
  });
};

export const apiUpdateHoseConfirmationImages = (imageFile) => {
  const url = API.HOSE_MECHANIC + "/" + API.UPDATE_HOSE_CONFIRMATION_IMAGES;
  return axios.post(url, imageFile).then((data) => {
    return data;
  });
}