import React, { useState } from "react";
import "./assembly.css";
import { toast } from "react-toastify";
import { HISfetchData } from "./hisAPIprovider";
import AssemblyDisplay from "./assemblyDisplay";

const Assembly = () => {
  const [partNo, setPartNo] = useState("");
  const [assemblyData, setAssemblyData] = useState(null);

  const handleChange = (e) => {
    setAssemblyData(null);
    setPartNo(e.target.value);
  };

  const handleClick = async () => {
    if (!partNo) {
      toast.error("Please enter the assembly part number first");
      return;
    }
    const response = await HISfetchData("/his/get_assembly", {
      assembly_id: partNo,
    });

    if (!response.ok) {
      toast.error("The assembly part could not be found");
      return;
    }
    const data = await response.json();
    setAssemblyData(data);
  };
  return (
    <div>
      <div className="search_area">
        <div>
          <div className="category_label">Assembly Part No</div>
          <div>
            <input
              type="text"
              className="part_no_input"
              value={partNo}
              onChange={handleChange}
            />
          </div>
        </div>
        <div
          style={{
            marginLeft: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <button className="assembly_search_button" onClick={handleClick}>
            Search
          </button>
        </div>
      </div>
      <div className="info_display">
        {assemblyData && (
          <AssemblyDisplay
            title="assembly"
            sectionData={assemblyData.assembly}
          />
        )}
        {assemblyData && (
          <AssemblyDisplay title="bulk_hose" sectionData={assemblyData.hose} />
        )}
        {assemblyData && (
          <AssemblyDisplay
            title="coupling"
            sectionData={assemblyData.coupling}
          />
        )}
        {assemblyData && (
          <AssemblyDisplay
            title="coupling_seal"
            sectionData={assemblyData.seal}
          />
        )}
        {assemblyData && (
          <AssemblyDisplay title="sleeve" sectionData={assemblyData.sleeve} />
        )}
      </div>
    </div>
  );
};

export default Assembly;
