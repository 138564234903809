import React from "react";
import { Image } from "semantic-ui-react";
import PropTypes from "prop-types";
import _ from "lodash";

const FerruleImage = ({ ferrule = {}, alt }) => {
    let ferruleImage = "";
    if (_.isEmpty(ferrule)) {
        return "";
    }
    
    if (!_.isEmpty(ferrule)) {
        const { stockImage1 } = ferrule;
        ferruleImage = stockImage1;
    }
    
    return (<React.Fragment>
        <Image
          src={ferruleImage}
          size="tiny"
          alt={alt}
          verticalAlign='middle'
        /> <span> Ferrule</span>
      </React.Fragment>);
};


FerruleImage.propTypes = {
    ferrule: PropTypes.object,
    alt: PropTypes.string
};

export default FerruleImage;