import axios from "axios";
import {
  ACCOUNT,
  DETAILS,
  DELIVERY_ADDRESS_ROUTE,
  RELATED_CONTACTS,
  USER,
  UPDATE_USER_INFO,
  SET_DEFAULT_DELIVERY_ADDRESS,
} from "../../../constants/apiLink";

export const apiUpdateAccountDeliveryAddress = async (data) => {
  const url = ACCOUNT + DETAILS;
  return axios.put(url, data).then((result) => {
    return result;
  });
};

export const apiCreateAccountDeliveryAddress = async (address) => {
  const url = RELATED_CONTACTS;
  address.type = 3;
  return axios.post(url, address).then((result) => {
    return result;
  });
};

export const apiUpdateDeliveryAddress = async (address) => {
  const url = USER + UPDATE_USER_INFO;
  return axios.patch(url, address).then((result) => {
    return result;
  });
};

export const apiDeleteDeliveryAddress = async (id) => {
  const url = RELATED_CONTACTS + "/" + id;
  return axios.delete(url).then((result) => {
    return result;
  });
};

export const apiCreateContact = async (data) => {
  const url = RELATED_CONTACTS;
  return axios.post(url, data).then((result) => {
    return result;
  });
};

export const apiUpdateContact = async (data) => {
  const url = RELATED_CONTACTS;
  return axios.patch(url, data).then((result) => {
    return result;
  });
};

export const apiSetDefaultDeliveryAddress = async (address) => {
  const url = USER + SET_DEFAULT_DELIVERY_ADDRESS + address;
  return axios.patch(url, address).then((result) => {
    return result;
  });
};
