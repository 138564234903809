import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MetaData from "../../../components/MetaData";
import { Control, Errors } from "react-redux-form";
import { Grid, Form, Button, Message, Icon } from "semantic-ui-react";
import Loader from "../../../components/Loader";
import polyglot from "../../../i18n";
import * as authReducer from "../../Auth/auth.reducer";
import "./changePassword.css";

class ChangePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      errorFlag: false,
      status: null,
      hidden: true,
    };
  }

  componentDidMount() {
    this.props.actionResetState("forms.changePasswordForm");
  }

  resetPassword = async (e) => {
    e.preventDefault;
    this.setState({ loading: true });
    let data = {
      password: this.props.forms.changePasswordForm.password,
      confirmPassword: this.props.forms.changePasswordForm.confirmPassword,
    };
    if (data.password !== data.confirmPassword) {
      this.setState({
        errorFlag: true,
        loading: false,
      });
      return;
    }
    if (data.password.length < 6) {
      this.setState({
        errorFlag: true,
        loading: false,
      });
      return;
    }

    await this.props.actionChangePassword(data);
    const { status } = this.props.response;
    this.setState({
      loading: false,
      status: status,
      errorFlag: false,
    });
  };

  goToProfile = (e) => {
    e.preventDefault();
    this.props.history.push("/home");
  };

  showPassword = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  render() {
    let responseJSX = null;
    let loader = "";
    // const { status } = this.props.response;
    const { errorFlag, status } = this.state;
    const comparePassword = (val) => {
      return this.props.forms.changePasswordForm.password !== val;
    };
    const longEnough = (val) => {
      const passwordRegx = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})"
      );
      if (!passwordRegx.test(val)) {
        return true;
      }
      return false;
    };

    if (status !== null) {
      responseJSX = (
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {status === 200 && (
            <React.Fragment>
              <Message style={{ color: "green" }}>
                {polyglot.t("changePassword.passwordResetSuccessful")}
              </Message>
              <div>&nbsp;</div>
            </React.Fragment>
          )}
          {status === 404 &&
            status ===
              400(
                <Message style={{ color: "red" }}>
                  {polyglot.t("changePassword.errorMessage")}
                </Message>
              )}
        </div>
      );
    }

    if (this.state.loading) {
      loader = <Loader text="Loading, please wait" />;
    }

    let formCode = (
      <div className="ui">
        <MetaData title="Change password" />
        <p className="login-title"> {polyglot.t("changePassword.title")}</p>
        <Form
          error={errorFlag}
          className="ui form"
          model="form.changePasswordForm"
        >
          <Form.Field required>
            <label>{polyglot.t("changePassword.password")}</label>
            <h6 className="reset-password-note">(Min 6 characters)</h6>
            <Grid>
              <Grid.Column width={14}>
                <Control
                  type={this.state.hidden ? "password" : "text"}
                  model="forms.changePasswordForm.password"
                  placeholder={polyglot.t("changePassword.password")}
                  required
                  validateOn="blur"
                  className="input-box-icon"
                  minLength="6"
                  validators={{
                    longEnough: (val) => !longEnough(val),
                    length: (val) => val.length > 5,
                  }}
                />
              </Grid.Column>
              <Grid.Column
                width={2}
                style={{ marginLeft: "-30px", marginTop: "1px" }}
              >
                <Button basic icon onClick={this.showPassword} type="button">
                  {this.state.hidden ? (
                    <Icon name="eye" />
                  ) : (
                    <Icon name="eye slash" />
                  )}
                </Button>
              </Grid.Column>
            </Grid>
            <Errors
              style={{ color: "red" }}
              model="forms.changePasswordForm.password"
              show="touched"
              messages={{
                valueMissing: <p>Password is required</p>,
                longEnough: (
                  <p>{polyglot.t("createAccountPage.passwordCriteria2")}</p>
                ),
                // longEnough: (
                //   <p className="password-criteria-message">
                //     {polyglot.t("createAccountPage.passwordCriteria")}
                //   </p>
                // ),
                length: <p>Min 6 characters required</p>,
              }}
            />

            <Grid.Column width={16} mobile={16} computer={16}>
              <div style={{ fontSize: "13px" }}>
                Tips on Strong Passwords Using a strong password helps protect
                your data. Try creating a password that you will remember but
                will be hard for others to guess by incorporating a mix of upper
                and lower case letters and special characters."
              </div>
            </Grid.Column>
          </Form.Field>
          <Form.Field required>
            <label>{polyglot.t("changePassword.confirmPassword")}</label>

            <Control
              type="password"
              model="forms.changePasswordForm.confirmPassword"
              placeholder={polyglot.t("changePassword.confirmPassword")}
              required
              validateOn="change"
              className="input-box-icon"
              validators={{ comparePassword: (val) => !comparePassword(val) }}
            />
            <Errors
              style={{ color: "red" }}
              model="forms.changePasswordForm.confirmPassword"
              show="touched"
              messages={{
                valueMissing: "Confirm password is required",
                comparePassword: "Password do not match",
              }}
            />
          </Form.Field>
          <Message error={true} content={this.state.errorMessage} />
          <div className="button-container">
            <Button
              size="small"
              secondary
              type="submit"
              className="button-float"
              onClick={this.resetPassword}
              // onClick={e => this.resetPassword}
            >
              {polyglot.t("changePassword.submit")}
            </Button>
          </div>
        </Form>
      </div>
    );

    return (
      <React.Fragment>
        <MetaData title="Change Password" />
        <Grid className="change-password-box">
          <Grid.Row>
            <div className="ui centered container change-password">
              {loader}
              <div className="change-password-container">
                {status !== 200 && formCode}
                {responseJSX}
                <div className="home-page-link">
                  <Button
                    size="small"
                    className="button-float"
                    type="button"
                    onClick={this.goToProfile}
                  >
                    Back to profile
                  </Button>
                </div>
              </div>
            </div>
          </Grid.Row>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    forms: state.forms,
    response: state.auth.response,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(authReducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
);
