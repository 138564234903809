import { apiGetUserInfo } from "../Auth/auth.api";
import { apiGetCartItems } from "../Cart/cart.api";
import {
  apiPlaceOrderCredit,
  apiInitiatePaymentGatewayOrder,
  apiGetOverallDiscount,
  apiGetPickUpAddress,
} from "./orderConfirmation.api";

import _ from "lodash";
import Description from "./../Search/Description";

export const SET_DEFAULT_ADDRESS = "DEFAULT_ADDRESS";
export const SET_CART_PRODUCTS = "SET_CART_PRODUCTS";
export const CHANGE_ADDRESS = "CHANGE_ADDRESS";
export const SET_REFERENCE = "SET_REFERENCE";
export const SET_CUSTOM_NOTES = "SET_CUSTOM_NOTES";
export const SET_CUSTOMER_ORDER_NUMBER = "SET_CUSTOMER_ORDER_NUMBER";
export const SET_PLACE_ORDER_RESPONSE = "SET_PLACE_ORDER_RESPONSE";
export const SET_PLACE_ORDER_ERROR_RESPONSE = "SET_PLACE_ORDER_ERROR_RESPONSE";
export const CLEAR_RESULT = "CLEAR_RESULT";
export const PX_PAY_INITIATE_RESPONSE = "PX_PAY_INITIATE_RESPONSE";
export const OVERALL_DISCOUNT = "OVERALL_DISCOUNT";
export const PICKUP_ADDRESS = "PICKUP_ADDRESS";

const initialState = {
  cart: [],
  deliveryAddress: {},
  allAddresses: [],
  customerOrderNumber: "",
  reference: "",
  customNotes: "",
  response: {},
  accountInfo: {},
  pxPayInitiateResponse: {},
  overallDiscount: {},
  pickUpAllAddresses: [],
  status: 0,
  statusText: "",
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CART_PRODUCTS: {
      return {
        ...state,
        cart: action.payload,
      };
    }
    case SET_DEFAULT_ADDRESS: {
      const {
        stopCredit,
        needOrderNumber,
        overrideGSTRate,
        deliveryAddress,
        defaultContactAddressId,
        contactsAndAddresses,
      } = action.payload;
      const address = _.filter(contactsAndAddresses, (item) => {
        item.isPrimary = item.id === defaultContactAddressId;
        return item.id === defaultContactAddressId;
      });
      return {
        ...state,
        deliveryAddress: address[0] ? address[0] : {},
        allAddresses: contactsAndAddresses,
        accountInfo: {
          stopCredit,
          needOrderNumber,
          overrideGSTRate,
        },
      };
    }
    case CHANGE_ADDRESS: {
      return {
        ...state,
        deliveryAddress: action.payload,
      };
    }
    case SET_REFERENCE: {
      return {
        ...state,
        reference: action.payload,
      };
    }
    case SET_CUSTOM_NOTES: {
      return {
        ...state,
        customNotes: action.payload,
      };
    }
    case SET_CUSTOMER_ORDER_NUMBER: {
      return {
        ...state,
        customerOrderNumber: action.payload,
      };
    }
    case SET_PLACE_ORDER_RESPONSE: {
      const { status, statusText } = action.payload;
      return {
        ...state,
        status: status,
        statusText: statusText,
      };
    }
    case SET_PLACE_ORDER_ERROR_RESPONSE: {
      const { statusCode: status, description: statusText } =
        action.payload.response.data;
      return {
        ...state,
        status: status,
        statusText: statusText,
      };
    }
    case CLEAR_RESULT: {
      return {
        ...state,
        response: {},
      };
    }
    case PX_PAY_INITIATE_RESPONSE: {
      return {
        ...state,
        pxPayInitiateResponse: action.payload,
      };
    }
    case OVERALL_DISCOUNT: {
      return {
        ...state,
        overallDiscount: action.payload,
      };
    }
    case PICKUP_ADDRESS: {
      const { defaultContactAddressId, contactsAndAddresses } = action.payload;
      const address = _.filter(contactsAndAddresses, (item) => {
        item.isPrimary = item.id === defaultContactAddressId;
        return item.id === defaultContactAddressId;
      });
      return {
        ...state,
        pickUpAllAddresses: contactsAndAddresses,
      };
    }
    default:
      return state;
  }
};

export const actionSetCart = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetCartItems();
      const { userCartItems } = result.data;
      userCartItems.sort(function (a, b) {
        return new Date(b.createdDateTime) - new Date(a.createdDateTime);
      });
      dispatch({ type: SET_CART_PRODUCTS, payload: userCartItems });
    } catch (e) {
      dispatch({ type: SET_CART_PRODUCTS, payload: e });
    }
  };
};

export const actionSetDeliveryDetails = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetUserInfo();
      dispatch({ type: SET_DEFAULT_ADDRESS, payload: result });
      dispatch({ type: PICKUP_ADDRESS, payload: result });
    } catch (e) {
      dispatch({ type: SET_DEFAULT_ADDRESS, payload: e });
    }
  };
};

export const actionChangeAddress = (address) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_ADDRESS, payload: address });
  };
};

export const actionSetReference = (reference) => {
  return (dispatch) => {
    dispatch({ type: SET_REFERENCE, payload: reference });
  };
};

export const actionSetCustomNotes = (customNotes) => {
  return (dispatch) => {
    dispatch({ type: SET_CUSTOM_NOTES, payload: customNotes });
  };
};

export const actionSetCustomerOrderNumber = (customerOrderNumber) => {
  return (dispatch) => {
    dispatch({ type: SET_CUSTOMER_ORDER_NUMBER, payload: customerOrderNumber });
  };
};

export const actionPlaceOrderCredit = (orderData) => {
  return async (dispatch) => {
    try {
      const result = await apiPlaceOrderCredit(orderData);
      if (result.status === 200)
        dispatch({
          type: SET_PLACE_ORDER_RESPONSE,
          payload: result,
        });
      else
        dispatch({
          type: SET_PLACE_ORDER_ERROR_RESPONSE,
          payload: result,
        });
    } catch (e) {
      const { response } = e;
      dispatch({
        type: SET_PLACE_ORDER_RESPONSE,
        payload: response,
      });
    }
  };
};

export const actionClearResponse = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_RESULT, payload: {} });
  };
};

export const actionSetPaymentGatewayResponse = (result) => {
  return (dispatch) => {
    dispatch({ type: SET_PLACE_ORDER_RESPONSE, payload: result });
  };
};

export const actionPlaceOrderPaymentGateway = (orderData) => {
  return async (dispatch) => {
    try {
      const result = await apiInitiatePaymentGatewayOrder(orderData);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: PX_PAY_INITIATE_RESPONSE, payload: result });
      } else {
        dispatch({ type: PX_PAY_INITIATE_RESPONSE, payload: result.response });
      }
    } catch (e) {
      const { response } = e;
      dispatch({ type: PX_PAY_INITIATE_RESPONSE, payload: response });
    }
  };
};

export const actionGetOverallDiscount = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetOverallDiscount();
      const { data } = result;

      dispatch({ type: OVERALL_DISCOUNT, payload: data });
    } catch (e) {
      console.error("Failed to load cart", e);
    }
  };
};

export const actionGetPickUpAddress = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetUserInfo();

      dispatch({ type: PICKUP_ADDRESS, payload: result });
    } catch (e) {
      dispatch({ type: PICKUP_ADDRESS, payload: e });
    }
  };
};
