import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import store, { history } from "./store";
import App from "./containers/App";
import registerServiceWorker from "./registerServiceWorker";
import "sanitize.css/sanitize.css";
import "./index.css";
import "./utils/axios.utils";
import "./assets/fonts/boafont.css";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration()],
  environment:
    window.location.hostname === "localhost"
      ? "localhost"
      : process.env.REACT_APP_ENV,
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE, //  Capture 100% of the transactions
});

const _ = require("lodash");
window._ = _;

const target = document.querySelector("#root");

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  target
);

registerServiceWorker();
