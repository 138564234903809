import axios from "axios";
import {
  ORDER,
  HISTORY,
  INVENTORY_LOCATION,
  ALL,
  INVENTORY,
  ADD_FORM_HISTORY
} from "../../constants/apiLink";

export const apiGetAllFilteredOrders = (
  searchTerm,
  status,
  fromDate,
  toDate,
  limit,
  pageNumber
) => {
  let URL = ORDER + HISTORY;
  if (pageNumber > -1) {
    URL = URL + "?pageNumber=" + pageNumber;
  }
  if (limit) {
    URL = URL + "&limit=" + limit;
  }
  if (searchTerm) {
    URL = URL + "&searchTerm=" + searchTerm;
  }
  if (status) {
    URL = URL + "&status" + status;
  }
  if (fromDate) {
    fromDate = new Date(fromDate);
    fromDate.setHours(0);
    fromDate.setMinutes(1);
    fromDate = fromDate.getTime();
    URL = URL + "&fromDate=" + fromDate;
  }
  if (toDate) {
    toDate = new Date(toDate);
    toDate.setHours(23);
    toDate.setMinutes(59);
    toDate = toDate.getTime();
    URL = URL + "&toDate=" + toDate;
  }

  return axios.get(URL).then(data => {
    return data;
  });
};

export const apiGetLocations = async () => {
  const url = INVENTORY_LOCATION + ALL;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiAddToInventory = async data => {
  const url = INVENTORY + ADD_FORM_HISTORY;
  return axios.post(url, data).then(
    result => {
      return result;
    },
    error => {
      return error;
    }
  );
};
