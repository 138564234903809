import AddCrew from "./AddCrew";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import * as crewReducer from "./crew.reducer";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import CrewList from "../../components/CrewList";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import { getErrorMessage } from "../../utils/error.utils";
import {
  Grid,
  Divider,
  Icon,
  Button,
  Confirm,
  Pagination
} from "semantic-ui-react";

const paginationLimit = 10;
class CrewManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      errorMessage: "",
      isDelete: false,
      crew: "",
      activePage: 1,
      isDeleteMember: false,
      member: "",
      memberIndex: ""
    };
  }

  componentDidMount = async () => {
    const { users } = this.props;

    if (!users.length) {
      await this.props.getUserList();
    }
    await this.getCrews(0);
  };

  getCrews = async pageNumber => {
    this.setState({ isLoading: true });
    await this.props.actionGetCrews(paginationLimit, pageNumber);
    this.setState({ isLoading: false });
  };

  goToPage = path => {
    this.props.history.goBack();
  };

  openAddCrewForm = () => {
    this.props.actionResetForm("crewForm");
    this.props.actionResetForm("memberForm");
    this.toggleCrewForm(true, false);
  };

  toggleCrewForm = (isCrew, inMemberOnly) => {
    this.updateState(false, null);
    this.props.actionToggleForm(isCrew, inMemberOnly);
  };

  manageCrew = async () => {
    const { isMember } = this.props;
    if (isMember) {
      this.modifyCrew();
    } else {
      this.addCrew();
    }
  };

  addCrew = async () => {
    this.setState({ isLoading: true });
    const { crewForm } = this.props.forms;
    const { name } = crewForm;
    await this.props.actionAddCrew({ name });
    this.setState({ isLoading: false });
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      this.props.actionGetCrews();
    } else {
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
    showSuccessToast("Crew added");
    this.toggleCrewForm(false, false);
  };

  modifyCrew = async () => {
    this.updateState(false, "message");
    const { crewForm } = this.props.forms;
    const { members } = crewForm;
    if (!members.length) {
      return this.updateState(true, "Please select at least one member");
    }
    this.setState({ isLoading: true });
    await this.props.actionUpdateCrew(crewForm);
    this.setState({ isLoading: false });
    this.props.actionGetCrews();
    showSuccessToast("Crew updated");
    this.toggleCrewForm(false, false);
  };

  openDeleteBox = crewObject => {
    this.setState({ crew: crewObject, isDelete: true });
  };
  cancelDeleteBox = () => {
    this.setState({ crew: "", isDelete: false });
  };

  deleteCrew = async () => {
    this.setState({ isLoading: true });
    const { crew } = this.state;
    const { _id, name } = crew;
    await this.props.actionDeleteCrew(_id);
    this.setState({ isLoading: false });
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      this.props.actionGetCrews();
      showSuccessToast(`you have deleted ${name} crew.`);
    } else {
      const { code } = data;
      const message = getErrorMessage(code);
      showErrorToast(message);
    }

    this.cancelDeleteBox();
  };

  manageCrewMember = async (crewObject, type, index) => {
    if (type === "remove") {
      this.setState({
        member: crewObject,
        isDeleteMember: true,
        memberIndex: index
      });
    }
    if (type === "add") {
      this.addMember(crewObject);
    }
  };

  cancelDeleteMemberBox = () => {
    this.setState({ member: "", isDeleteMember: false, memberIndex: "" });
  };

  removeMember = async () => {
    this.setState({ isLoading: true });
    const { member, memberIndex } = this.state;
    const { members, _id, name } = member;
    members.splice(memberIndex, 1);
    const data_to_send = { _id, name, members };
    await this.props.actionUpdateCrew(data_to_send);
    this.setState({ isLoading: false });
    this.props.actionGetCrews();
    showSuccessToast(`Member is deleted from ${name} crew.`);
    this.cancelDeleteMemberBox();
  };

  addMember = crewObject => {
    this.props.actionResetForm("memberForm");
    this.props.actionSetCrewForm(crewObject);
    this.toggleCrewForm(true, true);
  };

  updateState = (isError, errorMessage) => {
    this.setState({ isError, errorMessage });
  };

  handlePaginationChange = (...params) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;
    const { totalPages } = this.props;

    if (totalPages === 1) {
      return;
    }

    this.setState({
      activePage
    });
    this.getCrews(activePage - 1);
  };

  render() {
    const {
      isLoading,
      isError,
      errorMessage,
      isDelete,
      crew,
      activePage,
      isDeleteMember
    } = this.state;
    const { name } = crew;
    const { crews, totalPages, users } = this.props;

    const deleteMessage = `Do you want to delete ${name} crew ?`;

    return (
      <div className="my-asset">
        <Confirm
          open={isDeleteMember}
          content="Do you want to delete this member?"
          onCancel={this.cancelDeleteMemberBox}
          onConfirm={this.removeMember}
        />
        <Confirm
          open={isDelete}
          content={deleteMessage}
          onCancel={this.cancelDeleteBox}
          onConfirm={this.deleteCrew}
        />
        <MetaData title="Crew Management" />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading,please wait</Loader>
          )}
          <Grid.Row>
            <Grid.Column width={16}>
              <Grid className="my-location-header">
                <Grid.Column tablet={10} computer={10} mobile={16}>
                  <h3>
                    <Icon name="users" />
                    Crew/Team
                  </h3>
                </Grid.Column>
                <Grid.Column
                  textAlign="right"
                  width={6}
                  mobile={16}
                  computer={6}
                >
                  <Button
                    size="tiny"
                    onClick={this.goToPage}
                    icon="left arrow"
                    content="Go back"
                    labelPosition="left"
                    className="button-float"
                  />
                  {/* <Button
                    content="Add crew"
                    onClick={() => this.openAddCrewForm()}
                    size="tiny"
                    color="black"
                  /> */}
                </Grid.Column>
              </Grid>
              <Divider />
            </Grid.Column>

            <Grid.Column
            width={16}
            textAlign="center"
            className="no-top-padded"
            //style={{ paddingBottom: "0px" }}
          >

            <h3>Coming soon</h3>
          </Grid.Column>

            {/* <Grid.Column width={16}>
              <CrewList
                crews={crews}
                manageCrewMember={this.manageCrewMember}
                deleteCrew={this.openDeleteBox}
                users={users}
              />
            </Grid.Column>
            <Grid.Column width={16}>
              <div>
                {crews.length ? (
                  <Pagination
                    activePage={activePage}
                    boundaryRange={1}
                    onPageChange={this.handlePaginationChange}
                    totalPages={totalPages}
                    firstItem={null}
                    lastItem={null}
                  />
                ) : null}
              </div>
            </Grid.Column>
            <Grid.Column width={16}>
              <AddCrew
                manageCrew={this.manageCrew}
                closeForm={() => this.toggleCrewForm(false, false)}
                isError={isError}
                errorMessage={errorMessage}
              />
            </Grid.Column> */}
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    crews: state.crew.crews,
    isMember: state.crew.isMember,
    response: state.crew.response,
    totalPages: state.crew.totalPages,
    forms: state.forms,
    users: state.crew.users
  };
};

const reducer = { ...crewReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(CrewManagement)
);
