import axios from "axios";
import { SCHEDULE, ASSETS, SCHEDULE_COUNT } from "../../constants/apiLink";

export const apiGetSchedules = async (
  limit,
  pageNumber,
  isHistory,
  fromDate,
  toDate,
  assignTo,
  assetId
) => {
  let url = SCHEDULE;

  if (limit) {
    url = url + "?limit=" + limit;
  }
  if (pageNumber) {
    url = url + "&pageNumber=" + pageNumber;
  }

  if (fromDate) {
    url = url + "&fromDate=" + fromDate;
  }

  if (toDate) {
    url = url + "&toDate=" + toDate;
  }

  if (assignTo) {
    url = url + "&assignTo=" + assignTo;
  }
  if (assetId) {
    url = url + "&assetId=" + assetId;
  }
  if (isHistory) {
    url = url + "&isHistory=" + isHistory;
    // url = url + "&isHistory=history";
  }

  return axios.get(url).then(result => {
    return result;
  });
};

export const apiUpdateSchedule = async id => {
  const url = SCHEDULE + "id=" + id;

  return axios.delete(url).then(result => {
    return result;
  });
};

export const apiGetAssets = async () => {
  const url = ASSETS;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiGetScheduleCounts = async (
  fromDate,
  toDate,
  assignTo,
  assetId
) => {
  let url = SCHEDULE + SCHEDULE_COUNT + "/?limit=10" ;
  
  if (fromDate) {
    url = url + "&fromDate=" + fromDate;
  }

  if (toDate) {
    url = url + "&toDate=" + toDate;
  }

  if (assignTo) {
    url = url + "&assignTo=" + assignTo;
  }
  if (assetId) {
    url = url + "&assetId=" + assetId;
  }
  return axios.get(url).then(result => {
    return result;
  });
};
