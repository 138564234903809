import React from 'react';
import { Grid } from 'semantic-ui-react';
import Fitting from './Fitting';
import PropTypes from "prop-types";

const FittingRight = ({ selectedTypes, fittingHandler }) => {
  
    this.fittingChangeHandler = (value, type) => {
            fittingHandler(value);
    };

    return (<div>
        <Grid columns='equal'>
        <Grid.Column className="left-fitting-box">
                <Fitting key="1" fittingChangeHandler={this.fittingChangeHandler} type={"first"} fittingValue={selectedTypes} side={"right"} />               
            </Grid.Column>
        </Grid>
    </div>);
}


FittingRight.propTypes = {
  fittingHandler: PropTypes.func,
  selectedTypes: PropTypes.object
};

export default FittingRight;