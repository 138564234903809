import _ from "lodash";
import React, { Component } from "react";
import { Search } from "semantic-ui-react";

class AssetSearch extends Component {
  constructor() {
    super();
  }

  // Debounce the onSearchChange handler
  onSearchChange = _.debounce((e, { value }) => {
    // Update the state with the current search value
    this.props.onSearchChange(value);
  }, 1000); // Adjust the debounce delay as needed

  render() {
    const {
      loading,
      value,
      enable,
      onResultSelect,
      onSearchChange,
      noResultsMessage,
    } = this.props;

    return (
      <Search
        className="search-box"
        loading={loading}
        placeholder={loading ? "" : "Search Asset Details"}
        onResultSelect={onResultSelect}
        onSearchChange={onSearchChange}
        noResultsMessage={noResultsMessage}
        value={value}
        style={{
          opacity: enable ? "1" : "0.6",
          pointerEvents: enable ? "" : "none",
        }}
        {...this.props}
      />
    );
  }
}

export default AssetSearch;
