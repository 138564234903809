import React from "react";
import { Item } from "semantic-ui-react";

const resultRenderer = ({ stockCode, description, _id, type }) => {
  return (
    <Item key={_id}>
      <Item.Content>
        <Item.Header as="header">
          {stockCode}- {description}
        </Item.Header>
        <Item.Description>{type}</Item.Description>
      </Item.Content>
    </Item>
  );
};

export default resultRenderer;
