
const siteBase = "https://s3-ap-southeast-2.amazonaws.com/portal-static-images/categoryImages/";
const base_url = siteBase ;

export const CATEGORIES_IMAGES = {
    "Accessories": base_url + "Accessories-hyd.jpg",
    "Adaptors": base_url + "Adaptors.jpg",
    "Swage Hose Tails": base_url + "Hydraulic_Fittings_1.jpg",
    "Hose": base_url + "Hydraulic_Hose_1.jpg",
    "Quick Release Couplers": base_url + "QRC_1.jpg",
    "Workshop Machinery": base_url + "Machines_2.jpg",
    "Hydraulic Ferrules": base_url + "Ferrules_1.jpg"
};
  

export const hoseAdditionalProductsCategories = [
    "Adaptors",
    "Accessories",
    "Quick Release Couplers"
];
  
export const HOSE_FITTING_IMAGE_S3_LINK = "https://s3-ap-southeast-2.amazonaws.com/portal-static-images/hoseMaker/";
export const TBA = "TBA - Please call 0800 20 20 20";
export const FITTING_TERTIARY_GROUP_DISPLAY_NAME_FOR_FERRULE_CONDITION = "S-Series 2-Piece Swage Fittings"; 

