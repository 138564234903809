// DetailedAssembly.js
import React from "react";
import "./detailAssembly.css";

const DetailedAssembly = ({ title, details, onClose }) => {
  return (
    <div className="full_screen_popup">
      <div className="popup_content">
        <h2
          style={{
            textAlign: "center",
            fontSize: "30px",
            marginBottom: "30px",
          }}
        >
          {title}
        </h2>
        {Object.entries(details).map(
          ([key, value]) =>
            value !== "" &&
            value !== 0 &&
            value !== "None" && (
              <div className="detail_data_row">
                {key.includes("Boa Code") ? (
                  <p
                    key={key}
                    style={{
                      color: "#66cb23",
                      fontWeight: "bold",
                    }}
                  >
                    <strong>{key.toUpperCase()}:</strong>
                  </p>
                ) : (
                  <p key={key}>
                    <strong>{key}:</strong>
                  </p>
                )}
                {key.includes("Boa Code") ? (
                  <p
                    style={{
                      color: "#66cb23",
                      fontWeight: "bold",
                    }}
                  >
                    ( K / J / S ) {value}
                  </p>
                ) : (
                  <p>{value}</p>
                )}
              </div>
            )
        )}
        <div className="close_btn_container">
          <button onClick={onClose} className="close_btn_assem">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailedAssembly;
