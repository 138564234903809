import React from "react";
import _ from "lodash";
import { Grid, Image, List } from "semantic-ui-react";
import defaultImage from "../../../assets/images/product_default.png"

const HoseImages = ({ additionalProduct = [] }) => {

    if (_.isEmpty(additionalProduct)) {
        return "";
    }
    return (
        <React.Fragment>
            <Grid>
                <Grid.Column floated='right' width={16}>
                    <List horizontal style={{ float: "right" }}>
                        {additionalProduct.map((item, key) => {
                            const { stockImage1, stockCode, quantity, stockDescription } = item;
                            return (

                                <List.Item>
                                    <Image style={{height: '50px', width:'50px'}} key={key} src={stockImage1 ? stockImage1 : defaultImage} alt={stockCode} />
                                    <List.Content>
                                        <List.Header>{stockCode} X {quantity}</List.Header>
                                        {stockDescription}
                                    </List.Content>
                                </List.Item>
                            )
                        })
                        };
                    </List>
                </Grid.Column>
            </Grid>
        </React.Fragment >
    )
};


export default HoseImages;