import React from "react";
import FamilyBreadCrumb from "./FamilyBreadCrumb";
import PropTypes from "prop-types";

const resultRenderer = ( {
  stockDescription,
  stockCode,
  price,
  type,
  secondaryGrpDisplayName,
  tertiaryGrpDisplayName,
  productFamilyDisplayName
} ) => {
    return (
      <div key={stockDescription}>
        <div>
          <b>({stockCode}) </b> {stockDescription}{" "}
        </div>
        <div>
          <FamilyBreadCrumb
            key={stockCode}
            {...{ secondaryGrpDisplayName, tertiaryGrpDisplayName, productFamilyDisplayName }}
          />{" "}
        </div>
      </div>
    );
};

resultRenderer.propTypes = {
  stockDescription: PropTypes.string,
  stockCode: PropTypes.string,
  price: PropTypes.number,
  type: PropTypes.string,
  secondaryGrpDisplayName: PropTypes.string,
  tertiaryGrpDisplayName: PropTypes.string,
  productFamilyDisplayName: PropTypes.string
};
  
export default resultRenderer;