import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import * as crewReducer from "./crew.reducer";
import { withRouter } from "react-router-dom";
import { Control, Errors } from "react-redux-form";
import { showErrorToast } from "../../utils/toastUtils";
import { Modal, Grid, Form, Button, Message } from "semantic-ui-react";
import Member from "./Member";
import _ from "lodash";
import "./crew.css";

class AddCrew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false
    };
  }

  handleChange = (e, data) => {
    const { value } = data;
    this.props.actionSetMemberInfo("isPortalUser", value);
    this.props.actionSetMemberInfo("value", "");
  };

  setUser = async (e, data) => {
    const { value } = data;

    this.props.actionSetMemberInfo("value", value);
  };

  manageMember = async (type, index) => {
    const { crewForm } = this.props;
    const { members } = crewForm;
    let all_members = [...members];
    if (type === "add") {
      this.addNewMember(all_members);
    } else if (type === "remove") {
      this.removeMember(all_members, index);
    }
  };

  addNewMember = all_members => {
    const { memberForm } = this.props;
    const { value } = memberForm;
    if (value.length <= 0) {
      return;
    }
    //let isAvailable = _.find(all_members, { value });
    let isAvailable = _.filter(all_members, val => {
      return val.value.toLowerCase() === value.toLowerCase();
    });
    if (isAvailable.length > 0) {
      return showErrorToast("Member is already added in crew");
    }

    all_members.push(memberForm);
    this.props.actionSetMembers(all_members);
    this.props.actionSetMemberInfo("value", "");
  };

  removeMember = (all_members, index) => {
    all_members.splice(index, 1);
    this.props.actionSetMembers(all_members);
  };

  manageCrewCall = async () => {
    await this.setState({ isLoading: true });
    await this.props.manageCrew();
    this.setState({ isLoading: false });
  };

  textHandle = event => {
    // var newValue = event.target.value;
    const { target } = event;
    const { value } = target;
    let newValue = value;
    if (newValue.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  };

  render() {
    const {
      isForm,
      closeForm,
      isMember,
      crewForm,
      memberForm,
      users,
      isError,
      errorMessage
    } = this.props;

    const { isLoading } = this.state;
    const CrewName = (
      <Form.Field>
        <label>Crew Name</label>
        <Control
          type="text"
          model="forms.crewForm.name"
          placeholder="Crew Name"
          validateOn="change"
          required
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.crewForm.name"
          show="touched"
          messages={{
            valueMissing: "Crew name is required"
          }}
        />
      </Form.Field>
    );

    return (
      <Grid>
        <Modal open={isForm} onClose={() => closeForm()} closeIcon>
          <Modal.Header>Crew/Team Information</Modal.Header>
          <Modal.Content className="add-location">
            <Form
              model="forms.crewForm"
              error={isError}
              onSubmit={this.manageCrewCall}
            >
              <Grid>
                <Grid.Column width={16}>{CrewName}</Grid.Column>
                {isMember && (
                  <Grid.Column width={16} className="member-form">
                    <Member
                      users={users}
                      memberForm={memberForm}
                      setUser={this.setUser}
                      manageMember={this.manageMember}
                      handleChange={this.handleChange}
                      crewForm={crewForm}
                    />
                  </Grid.Column>
                )}

                <Grid.Column
                  width={16}
                  className="button-panel"
                  textAlign="right"
                >
                  <Message
                    error={true}
                    content={errorMessage}
                    style={{ textAlign: "left" }}
                  />
                  <Button
                    size="small"
                    onClick={closeForm}
                    className="button-float"
                  >
                    Close
                  </Button>
                  <Button
                    secondary
                    size="small"
                    className="button-float"
                    loading={isLoading}
                    type="submit"
                  >
                    Submit
                  </Button>
                </Grid.Column>
              </Grid>
            </Form>
          </Modal.Content>
        </Modal>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    isForm: state.crew.isForm,
    isMember: state.crew.isMember,
    users: state.crew.users,
    account: state.auth.user.account,
    memberForm: state.forms.memberForm,
    crewForm: state.forms.crewForm
  };
};

const reducer = { ...crewReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AddCrew)
);
