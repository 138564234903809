import axios from "axios";
import { CREW, USER_LIST, USER } from "../../constants/apiLink";

export const apiGetCrews = async (limit, pageNumber) => {
  let url = CREW;

  if (limit) {
    url = url + "?limit=" + limit;
  }
  if (pageNumber) {
    url = url + "&pageNumber=" + pageNumber;
  }

  return axios.get(url).then(result => {
    return result;
  });
};

export const apiAddCrew = async data => {
  const url = CREW;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiUpdateCrew = async data => {
  const { _id } = data;
  const url = CREW + "/" + _id;
  return axios.put(url, data).then(result => {
    return result;
  });
};

export const apiDeleteCrew = async id => {
  const url = CREW + "/" + id;

  return axios.delete(url).then(result => {
    return result;
  });
};

export const apiGetUsersByAccount = () => {
  let url = USER + USER_LIST;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiGetAllCrew = async (list) => {
  let url = CREW;
  if (list) {
    url = url + "?list=" + list;
  }
  return axios.get(url).then(result => {
    return result;
  });
};
