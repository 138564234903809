import _ from "lodash";
import { DEFAULT_TAX, FULL_BOX_POLICY } from "../../constants/appConstants";

export const getOrderDetails = cart => {
  let orderDetails = [];
  _.forEach(cart, item => {
    const {
      stockCode,
      quantity,
      stockDescription,
      pricingPolicy,
      hose = false,
      hoseId = "",
      details
    } = item;
    const { price, currency, totalPrice, discount, policyName } = pricingPolicy;
    const { stockImage1 = "" } = details;
    const productPolicyApplied =
      policyName !== FULL_BOX_POLICY && policyName !== "" ? true : false;
    let order = {
      stockCode,
      currency,
      orderQuantity: Number(quantity),
      description: stockDescription,
      salesPrice: price,
      totalPrice: Number(totalPrice),
      stockImage: stockImage1,
      discount: discount,
      policyName: policyName,
      productPolicyApplied: productPolicyApplied
    };
    if (hose) {
      order.hose = hose;
      order.hoseId = hoseId;
    }

    orderDetails.push(order);
  });
  return orderDetails;
};

export const constructPayWithBOACreditObject = ({
  customerOrderNumber,
  reference,
  deliveryAddress,
  cart,
  tax,
  discount = 0,
  iWillPickUp
}) => {
  const subTotal = Number(getTotal(cart, discount));
  const taxTotal = Number(getTaxTotal(subTotal.toFixed(2), tax));
  const orderTotal = Number((subTotal + taxTotal).toFixed(2));
  const orderDetails = getOrderDetails(cart);
  let data = orderDetails[0];
  const { currency } = data;
  const orderData = {
    customerOrderNumber,
    reference,
    address: deliveryAddress,
    subTotal,
    taxTotal,
    orderTotal,
    paymentType: "boa credit",
    orderDetails,
    currency,
    orderSource: "WP-Order",
    iWillPickUp
  };
  return orderData;
};

const getTotal = (cart, discount) => {
  let total = 0;
  let calculatedDiscount = 0;
  _.forEach(cart, item => {
    const { pricingPolicy } = item;
    total = total + Number(pricingPolicy.totalPrice);
  });
  calculatedDiscount = (total / 100) * discount;
  return Number((total - calculatedDiscount).toFixed(2));
};

const getTaxTotal = (total, tax) => {
  return ((total / 100) * tax).toFixed(2);
};

export const getAccountTax = overrideGSTRate => {
  if (typeof overrideGSTRate !== "number" && overrideGSTRate) {
    if (overrideGSTRate.includes("Auto Select Rate")) {
      return DEFAULT_TAX;
    }
    let rate = overrideGSTRate.split("%");
    let percent = rate[0];

    return percent;
  }
  return DEFAULT_TAX;
};
