import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import * as auditTrailReducer from "./auditTrail.reducer";
import AuditTrailList from "../../components/AuditTrailList";
import {
  Grid,
  Divider,
  Icon,
  Pagination,
  Dropdown,
  Button,
  Input,
  Search
} from "semantic-ui-react";
import RangePicker from "../../components/DateRangePicker";
import ResultRenderer from "../Inventory/ResultRender";
import { stock_operation } from "../../constants/appConstants";
import _ from "lodash";

const TYPE = "type";
const OPERATOR = "operator";
const ASSET = "asset";
const STOCK_CODE = "stockCode";
const FROM_DATE = "fromDate";
const TO_DATE = "toDate";

const paginationLimit = 50;

class AuditTrail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activePage: 1,
      type: "",
      operator: "",
      assetName: "",
      stockCode: "",
      fromDate: "",
      toDate: "",
      value: ""
    };
  }

  componentDidMount = () => {
    const urlParams = this.getUrlParams() || {};
    const {
      type = "",
      stockCode,
      fromDate = "",
      toDate = "",
      operator = "",
      assetName = ""
    } = this.state;

    if (
      urlParams.type === type &&
      urlParams.operator === operator &&
      urlParams.assetName === assetName &&
      urlParams.stockCode === stockCode &&
      urlParams.fromDate === fromDate &&
      urlParams.toDate === toDate
    ) {
      return;
    }

    this.setInitialValue(urlParams);
  };

  getUrlParams = () => {
    let url = document.location.href;
    url = new URL(url);

    const { searchParams } = url;
    const type = searchParams.get(TYPE);
    const operator = searchParams.get(OPERATOR);
    const assetName = searchParams.get(ASSET);
    const stockCode = searchParams.get(STOCK_CODE);
    const fromDate = searchParams.get(FROM_DATE);
    const toDate = searchParams.get(TO_DATE);

    if (
      !!type ||
      !!operator ||
      !!assetName ||
      !!stockCode ||
      !!fromDate ||
      !!toDate
    ) {
      return { type, operator, assetName, stockCode, fromDate, toDate };
    }
    return null;
  };

  setInitialValue = async ({
    type = "",
    operator = "",
    assetName = "",
    stockCode = "",
    fromDate = "",
    toDate = ""
  }) => {
    this.setState({ type, operator, assetName, stockCode, fromDate, toDate });
    await this.props.actionsGetAudits(
      paginationLimit,
      0,
      type,
      stockCode,
      fromDate,
      toDate,
      operator,
      assetName
    );
    this.setState({ isLoading: false });
  };

  addParamsToUrl = () => {
    let {
      type = "",
      operator = "",
      assetName = "",
      stockCode = "",
      fromDate = "",
      toDate = ""
    } = this.state;
    this.props.history.push({
      pathname: "/audit",
      search: `?${TYPE}=${type}&${OPERATOR}=${operator}&${ASSET}=${assetName}&${STOCK_CODE}=${stockCode}&${FROM_DATE}=${fromDate}&${TO_DATE}=${toDate}`
    });
  };

  getAudits = async pageNumber => {
    this.setState({ isLoading: true });
    let { type, stockCode, fromDate, toDate, operator, assetName } = this.state;

    if (type === "ALL") {
      type = "";
    }

    await this.props.actionsGetAudits(
      paginationLimit,
      pageNumber,
      type,
      stockCode,
      fromDate,
      toDate,
      operator,
      assetName
    );
    this.setState({ isLoading: false });
  };

  setType = (e, data) => {
    const { value } = data;
    this.setState({ type: value });
  };

  setOperator = (e, data) => {
    const { value } = data;
    this.setState({ operator: value });
  };

  setAssetName = (e, data) => {
    const { value } = data;
    this.setState({ assetName: value });
  };

  setStockCode = (e, data) => {
    const { value } = data;
    this.setState({ stockCode: value });
  };

  goBack = () => {
    this.props.history.push("/inventory");
  };

  clearFilter = async () => {
    await this.setState({
      type: "",
      operator: "",
      assetName: "",
      fromDate: "",
      toDate: "",
      stockCode: ""
    });
    this.props.history.push({
      pathname: "/audit",
      search: ``
    });
    this.getAudits(0);
  };

  setFromDate = date => {
    this.setState({ fromDate: date });
  };
  setToDate = date => {
    this.setState({ toDate: date });
  };

  handlePaginationChange = (...params) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;
    const { totalPages } = this.props;

    if (totalPages === 1) {
      return;
    }
    this.setState({ activePage });
    this.getAudits(activePage - 1);
  };

  searchAudits = () => {
    this.addParamsToUrl();
    this.getAudits(0);
  };

  handleResultSelect = (e, { result }) => {
    const { stockCode, _id } = result;

    this.setState({ stockId: _id, stockCode: stockCode });
  };

  handleSearchChange = async (e, { value }) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (!value) {
      this.setState({ stockCode: value, isLoading: false });
      return this.getAudits(0);
    }
    await this.props.actionSearchStock(value);
    this.setState({ stockCode: value, searchMessage: null, isLoading: false });
  };

  render() {
    const {
      isLoading,
      activePage,
      type,
      operator,
      assetName,
      stockCode,
      fromDate,
      toDate
    } = this.state;
    const { allaudit, searchsuggestion } = this.props;
    const { totalPages, auditTrails } = allaudit;


    const Type = (
      <span>
        <b>
          <Icon name="tasks" />
          Type
        </b>
        <Dropdown
          placeholder="Select Type"
          fluid
          value={type}
          selection
          required
          onChange={this.setType}
          options={stock_operation}
        />
        {_.isEmpty(stock_operation) ? (<span style={{ color: "red" }}>
          No results found</span>)
          : null}
      </span>
    );

    const operatorName = (
      <span>
        <b>
          <Icon name="user" />
          User
        </b>
        <Input
          type="text"
          fluid
          placeholder="Type user email"
          onChange={this.setOperator}
          value={operator}
        />
      </span>
    );

    const assetTextBox = (
      <span>
        <b>
          <Icon name="zip" />
          Asset Name
        </b>
        <Input
          type="text"
          fluid
          placeholder="Asset Name"
          onChange={this.setAssetName}
          value={assetName}
        />
      </span>
    );

    const StockCode = (
      <span>
        <b>
          <Icon name="zip" />
          Stock Code
        </b>
        <Search
          loading={isLoading}
          className="search-box"
          placeholder="Search by stock code"
          resultRenderer={ResultRenderer}
          onResultSelect={this.handleResultSelect}
          onSearchChange={_.debounce(this.handleSearchChange, 500, {
            leading: true
          })}
          // noResultsMessage="No direct matches found."
          results={_.uniqBy(searchsuggestion , item => { return item.name})}
          value={stockCode}
          {...this.props}
          style={{ marginTop: "2px" }}
        />
      </span>
    );

    const actionButtons = (
      <span>
        <Button content="Clear" onClick={this.clearFilter} />
        <Button
          content="Search"
          onClick={() => this.searchAudits()}
          color="black"
        />
      </span>
    );

    return (
      <div className="my-inventory">
        <MetaData title="Audit Trail" />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading,please wait</Loader>
          )}
          <Grid.Column width={16}>
            <Grid>
              <Grid.Column computer={10} mobile={16} width={10}>
                <h3>
                  <Icon name="clipboard" />
                  Audit trail
                </h3>
              </Grid.Column>
              <Grid.Column
                textAlign="right"
                width={16}
                mobile={16}
                computer={6}
              >
                <Button
                  size="tiny"
                  onClick={this.goBack}
                  icon="left arrow"
                  content="Go back"
                  labelPosition="left"
                  className="button-float"
                />
              </Grid.Column>
            </Grid>
            <Divider />
          </Grid.Column>
          <Grid.Column width={16}>
            <Grid>
              <Grid.Column width={4} computer={8} mobile={16} tablet={8}>
                {Type}{" "}
              </Grid.Column>
              <Grid.Column width={4} computer={8} mobile={16} tablet={8}>
                {operatorName}{" "}
              </Grid.Column>

              <Grid.Column width={4} computer={8} mobile={16} tablet={8}>
                {type === "ATTACHED" && assetTextBox}
              </Grid.Column>

              <Grid.Column width={4}>
                <div />
              </Grid.Column>
              <Grid.Column width={4} computer={8} mobile={16} tablet={8}>
                {StockCode}
              </Grid.Column>
              <Grid.Column width={8} computer={8} mobile={16} tablet={8}>
                <RangePicker
                  setStartDate={date => this.setFromDate(date)}
                  setEndDate={date => this.setToDate(date)}
                  startDate={fromDate}
                  endDate={toDate}
                />
              </Grid.Column>
              <Grid.Column
                computer={16}
                mobile={16}
                tablet={8}
                textAlign="right"
                style={{ marginTop: "27px" }}
              >
                {actionButtons}
              </Grid.Column>
            </Grid>
          </Grid.Column>
          {!isLoading && (
            <Grid.Column width={16} computer={16} mobile={16} tablet={16}>
              <AuditTrailList audits={auditTrails} />
            </Grid.Column>
          )}
          <Grid.Column width={16}>
            <div>
              {!_.isEmpty(auditTrails) ? (
                <Pagination
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={this.handlePaginationChange}
                  totalPages={totalPages}
                  firstItem={null}
                  lastItem={null}
                />
              ) : null}
            </div>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allaudit: state.auditTrail.allaudit,
    users: state.crew.users,
    assets: state.assets.assets,
    searchsuggestion: state.auditTrail.searchsuggestion
  };
};

const reducer = { ...auditTrailReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuditTrail)
);
