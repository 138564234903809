import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Grid,
  Image as ImageContainer,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { showErrorToast } from "../../utils/toastUtils";
import "./hoseMaker.css";
import { set } from "react-ga";

const baseStyle = {
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#979fa8",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#979fa8",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function HoseMakerImageUploader(props) {
  const [hasUploaded, setHasUploaded] = useState(false);
  const [uploadedImage, setUploadedImage] = useState([]);
  const {
    acceptedFile,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    onDropAccepted,
  } = useDropzone({
    multiple: false,
    accept: "image/*",
    onDrop: (acceptedFile) => {
      setHasUploaded(true);
      setUploadedImage(acceptedFile);
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  useEffect(() => {
    if (hasUploaded) {
      props.setIsUploadingHoseMakerImage(true);
      props.uploadHoseMakerImage(uploadedImage);
    }
  }, [uploadedImage]);

  return (
    <div>
      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div style={{ textAlign: "center" }}>
          <p>Drag and Drop BOM Assembly Image (Max. 4)</p>
        </div>
        <div style={{ textAlign: "center", marginTop: 0 }}>
          <p>or</p>
        </div>
        <div style={{ textAlign: "center", marginTop: 2 }}>
          {props.isUploadingHoseMakerImage ? (
            <Dimmer active inverted>
              <Loader size="small">Uploading image...</Loader>
            </Dimmer>
          ) : (
            <Button color="blue" style={{ width: 150, fontSize: 14 }}>
              Browse
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
