import React from "react";
import PropTypes from "prop-types";
import OrderDetails from "./OrderDetails";
import { Message } from "semantic-ui-react";
import "./orderHistoryList.css";

const OrderHistoryList = ({
  allOrders = [],
  addToCartHandler,
  setOrderNo,
  showFacilities,
  showPrice
}) => {
  return (
    <React.Fragment>
      {allOrders.length > 0 ? (
        allOrders.map((item, key) => {
          return (
            <OrderDetails
              key={key}
              order={item}
              addToCart={addToCartHandler}
              setOrderNo={setOrderNo}
              showFacilities={showFacilities}
              showPrice={showPrice}
            />
          );
        })
      ) : (
        <Message info header="Sorry, No orders found!" content={<div />} />
      )}
    </React.Fragment>
  );
};

OrderHistoryList.propTypes = {
  allOrders: PropTypes.object,
  addToCartHandler: PropTypes.func,
  setOrderNo: PropTypes.func
};

export default OrderHistoryList;
