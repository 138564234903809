import React from 'react';
import { Modal, Form, Header, Image, Checkbox, Button, Input, Icon, List, Message } from 'semantic-ui-react'

class FilterModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
        }
    }
    closeModal = () => {
        this.setState({ showModal: false })
    }
    openModal = () => {
        this.setState({ showModal:true })
    }
    render() {
        const {
            showModal,
        } = this.state
        return (
            <div>
                <div className="filter_warp">
                    <a href="#" className="filter_btn" onClick={this.openModal} > <i className="boa ba-filter"></i></a>
                    <span className="filter_on"></span>
                </div>
                {/* <Button fluid onClick={this.openModal} className="next_btn">Next</Button> */}
                <Modal className="custom_modal" closeIcon size='tiny' onClose={this.closeModal} open={showModal} >
                    <Modal.Header>Filter By Role</Modal.Header>
                    <Modal.Content >
                        <Modal.Description>
                            <div className="fitler_list_wrap">
                                <List>
                                   <h3 style={{textAlign:"center"}}> Coming Soon</h3>
                                    {/* <List.Item>
                                        <div className="filter_inner_box">
                                            <div className="role_name">General Manager</div>
                                             <div className="custom_checkbox">
                                                <Checkbox />
                                            </div>
                                        </div>
                                    </List.Item>
                                    <List.Item>
                                        <div className="filter_inner_box">
                                            <div className="role_name">Company Admin</div>
                                             <div className="custom_checkbox">
                                                <Checkbox />
                                            </div>
                                        </div>
                                    </List.Item>
                                    <List.Item>
                                        <div className="filter_inner_box">
                                            <div className="role_name">Area Manager</div>
                                             <div className="custom_checkbox">
                                                <Checkbox />
                                            </div>
                                        </div>
                                    </List.Item>
                                    <List.Item>
                                        <div className="filter_inner_box">
                                            <div className="role_name">Coustomer Accountant</div>
                                             <div className="custom_checkbox">
                                                <Checkbox />
                                            </div>
                                        </div>
                                    </List.Item>
                                    <List.Item>
                                        <div className="filter_inner_box">
                                            <div className="role_name">BOA Admin</div>
                                             <div className="custom_checkbox">
                                                <Checkbox />
                                            </div>
                                        </div>
                                    </List.Item>
                                    <List.Item>
                                        <div className="filter_inner_box">
                                            <div className="role_name">Hose Champion</div>
                                             <div className="custom_checkbox">
                                                <Checkbox />
                                            </div>
                                        </div>
                                    </List.Item> */}
                                   
                                </List>
                                 
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions className="modal_footer">
                        <Button basic color='black' onClick={this.closeModal}>Clear </Button>
                        <Button color='green'>Apply </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}
export default FilterModal;