import React from "react";
import PropTypes from "prop-types";
import { Grid,Icon } from "semantic-ui-react";
import "./stockReviewList.css";

const StockDetails = ({ stock, goToAudits, openChangeModal }) => {
  const { description, location, quantity, stockCode, notes } = stock;

  const InventoryBody = () => {
    return (
      <Grid columns="equal" className="details">
        <Grid.Column computer={8} mobile={4} tablet={4}>
          <b>Stock: {stockCode}</b>
          <p>{description}</p>
        </Grid.Column>

        <Grid.Column computer={5} mobile={16} tablet={4}>
          <div>
            <b>Quantity:</b>
            <div
              onClick={() => openChangeModal({ location, quantity, stockCode, notes }) }
              className="link_To_Page"
            >
            <Icon name="pencil"/>
            </div>
            <p>{quantity}</p>
          </div>
        </Grid.Column>
        <Grid.Column
          computer={2}
          mobile={16}
          tablet={4}
          textAlign="right"
          verticalAlign="middle"
        >
          <span
            className="link-button"
            onClick={() => goToAudits(stockCode)}
          >
            View Audit Trail
          </span>
        </Grid.Column>
      </Grid>
    );
  };
  return <InventoryBody />;
};

StockDetails.propTypes = {
  allStock: PropTypes.array,
  updateStock: PropTypes.func,
  openChangeModal: PropTypes.func,
  goToAudits: PropTypes.func
};

export default StockDetails;
