import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Modal,
  Button,
  Icon,
  Form,
  Input,
  Checkbox,
  Image,
  Dimmer,
  Loader
} from "semantic-ui-react";
import * as hoseMakerReducer from "../../hoseMaker.reducer";
import * as hoseMechanicReducer from "../../../HoseMechanic/hoseMechanic.reducer";
import FittingInputs from "./FittingInputs";
import PropTypes from "prop-types";
import { showErrorToast, showSuccessToast } from "../../../../utils/toastUtils";
import Dropzone from "react-dropzone";
import { apiUpdateHoseConfirmationImages } from "../../../HoseMechanic/hoseMechanic.api";

const LEFT_TYPE = "Left";
const RIGHT_TYPE = "Right";
const IMAGE_SIZE_WARNING = "Allowed image size limit is 5MB only";

class SendToInventoryOrAssetModalJSX extends React.Component {
  constructor() {
    super();
    this.state = {
      whoMadeHose: "",
      wasPushedProperly: false,
      hoseHasBeenCleaned: false,
      isLoading: false,
      leftFittingInput: "",
      rightFittingInput: "",
      rightImageFile: null,
      leftImageFile: null,
      imageUploadError: null,
      selectedHoseImage: null,
      imageUploadLoading: false
    };
  }

  componentDidMount = () => {
    const { createdBy } = this.props;
    this.setState({ whoMadeHose: createdBy });
  };

  whoMadeHoseHandler = e => {
    const { target } = e;
    const { value } = target;
    this.setState({ whoMadeHose: value });
  };

  pushedProperlyCheckBoxHandler = (e, data) => {
    const { checked } = data;
    this.setState({ wasPushedProperly: checked });
  };

  hasHoseBeenCleanHandler = (e, data) => {
    const { checked } = data;
    this.setState({ hoseHasBeenCleaned: checked });
  };

  leftFittingInputHandler = value => {
    this.setState({ leftFittingInput: value });
  };

  rightFittingInputHandler = value => {
    this.setState({ rightFittingInput: value });
  };

  isValidImageSize = size => {
    const kbSize = size / 1000;
    const limit = 5000;
    if (kbSize > limit) {
      return false;
    }
    return true;
  };

  onSelectFile = async (files, type) => {
    const { size } = files[0];
    if (!this.isValidImageSize(size)) {
      this.setState({ imageUploadError: IMAGE_SIZE_WARNING });
      showErrorToast(IMAGE_SIZE_WARNING);
      return;
    }
    if (type === "Left") {
      const leftImageUploadResult = await this.uploadImageWrapper(files[0]);
      this.setState({
        leftImageFile: leftImageUploadResult
      });
    }
    if (type === "Right") {
      const rightImageUploadResult = await this.uploadImageWrapper(files[0]);
      this.setState({
        rightImageFile: rightImageUploadResult
      });
    }
  };

  uploadImageWrapper = async formData => {
    this.setState({ imageUploadLoading: true });
    const form = new FormData();
    form.append("file", formData);
    const result = await apiUpdateHoseConfirmationImages(form);
    const { data } = result;
    this.setState({ imageUploadLoading: false });
    return data;
  };

  onHoseSelectImageHandler = async files => {
    const { size } = files[0];
    if (!this.isValidImageSize(size)) {
      this.setState({ imageUploadError: IMAGE_SIZE_WARNING });
      showErrorToast(IMAGE_SIZE_WARNING);
      return;
    }
    const attachedHoseImage = await this.uploadImageWrapper(files[0]);
    this.setState({
      selectedHoseImage: attachedHoseImage
    });
  };

  sendToInventoryOrAssetHandler = async () => {
    const isValid = this.isValidForm();
    if (isValid) {
      showErrorToast(isValid);
      return;
    }
    const {
      whoMadeHose,
      wasPushedProperly,
      hoseHasBeenCleaned,
      leftFittingInput,
      rightFittingInput,
      rightImageFile,
      leftImageFile,
      selectedHoseImage
    } = this.state;
    this.setState({ isLoading: true });
    const {
      hoseId,
      selectedInventoryLocation,
      position,
      assetId,
      stockCode,
      hoseDescription,
      uniqueCode,
      positionId
    } = this.props;
    // const data = new FormData();
    // if (rightImageFile) {
    //   data.append("rightImageFile", rightImageFile);
    // }
    // if (leftImageFile) {
    //   data.append("leftImageFile", leftImageFile);
    // }
    // if (selectedHoseImage) {
    //   data.append("hoseImageFile", selectedHoseImage);
    // }
    // data.append("whoMadeHose", whoMadeHose);
    // data.append("wasPushedProperly", wasPushedProperly);
    // data.append("hoseHasBeenCleaned", hoseHasBeenCleaned);
    // data.append("leftFittingInput", leftFittingInput);
    // data.append("rightFittingInput", rightFittingInput);
    // data.append("hoseId", hoseId);

    const dataToSend = {
      rightImageFile,
      leftImageFile,
      selectedHoseImage,
      whoMadeHose,
      wasPushedProperly,
      hoseHasBeenCleaned,
      leftFittingInput,
      rightFittingInput,
      hoseId,
      uniqueCode,
      positionId
    };

    await this.props.actionUpdateHoseConfirmationData(dataToSend);
    const { updateHoseResponse } = this.props;
    const { status } = updateHoseResponse;
    if (status === 200) {
      /// add to inventory or asset call
      if (assetId) {
        const attachHoseToAssetData = {
          assetId,
          location: selectedInventoryLocation,
          position,
          quantity: 1,
          stockCode,
          description: hoseDescription,
          hoseId: hoseId,
          hoseUniqueCode: uniqueCode,
          positionId
        };

        const attachResult = await this.props.actionAttachHoseToAsset(
          attachHoseToAssetData
        );

        const { status } = attachResult;
        this.setState({ isLoading: false });
        if (status === 200) {
          showSuccessToast("Hose attached to asset successfully");
          this.props.history.push(`assetDetails/${assetId}`);
        } else {
          showErrorToast("Something went wrong , please try again");
        }
      } else {
        const result = await this.props.actionAddHoseItemsInInventory(
          hoseId,
          selectedInventoryLocation
        );
        const { status } = result;
        if (status === 200) {
          showSuccessToast("Hose added to inventory successfully");
          this.props.history.push("/inventory");
        }
      }
      this.setState({ isLoading: false });
    } else {
      this.setState({ isLoading: false });
      showErrorToast("Something went wrong, please try again");
    }
  };

  isValidForm = () => {
    const { whoMadeHose, leftFittingInput, rightFittingInput } = this.state;
    if (!whoMadeHose) {
      return "Who made hose is mandatory";
    }
    if (!leftFittingInput) {
      return "Left fitting input is mandatory";
    }
    if (!rightFittingInput) {
      return " Right fitting input is mandatory";
    }
    return false;
  };

  render() {
    const {
      sendToInventoryOrAssetModal,
      cancelAddToInventoryOrAssetModal,
      hoseName,
      leftCrimpSize,
      rightCrimpSize,
      assetId
    } = this.props;
    const {
      isLoading,
      wasPushedProperly,
      whoMadeHose,
      leftFittingInput,
      rightFittingInput,
      hoseHasBeenCleaned,
      rightImageFile,
      leftImageFile,
      selectedHoseImage,
      imageUploadLoading
    } = this.state;

    const whoMadeTheHoseJsx = (
      <Form>
        <Form.Field>
          <Grid>
            <Grid.Column width={16}>
              <label>
                <b>
                  Who made the hose ? <span style={{ color: "red" }}>*</span>
                </b>
              </label>
              <Input
                type="text"
                placeholder="Who made the hose?"
                value={whoMadeHose}
                onChange={this.whoMadeHoseHandler}
              />
            </Grid.Column>
          </Grid>
        </Form.Field>
      </Form>
    );

    const wasPushedProperlyJsx = (
      <Form>
        <Form.Field>
          <Grid>
            <Grid.Column>
              <Checkbox
                toggle
                label="Was the hose marked and pushed on properly ?"
                onChange={this.pushedProperlyCheckBoxHandler}
                checked={wasPushedProperly}
              />
            </Grid.Column>
          </Grid>
        </Form.Field>
      </Form>
    );

    const leftFittingJSX = (
      <FittingInputs
        type={LEFT_TYPE}
        inputChangeHandler={this.leftFittingInputHandler}
        crimpValue={leftCrimpSize}
        inputValue={leftFittingInput}
        onSelectFile={this.onSelectFile}
        selectedFile={leftImageFile}
      />
    );
    const rightFittingJSX = (
      <FittingInputs
        type={RIGHT_TYPE}
        inputChangeHandler={this.rightFittingInputHandler}
        crimpValue={rightCrimpSize}
        inputValue={rightFittingInput}
        onSelectFile={this.onSelectFile}
        selectedFile={rightImageFile}
      />
    );

    const hoseHasBeenCleanedJsx = (
      <Form>
        <Form.Field>
          <Grid>
            <Grid.Column>
              <Checkbox
                toggle
                label="Has the hose has been cleaned ?"
                onChange={this.hasHoseBeenCleanHandler}
                checked={hoseHasBeenCleaned}
              />
            </Grid.Column>
          </Grid>
        </Form.Field>
      </Form>
    );

    return (
      <Modal
        open={sendToInventoryOrAssetModal}
        onClose={cancelAddToInventoryOrAssetModal}
        size="fullscreen"
        closeIcon
      >
        {imageUploadLoading && (
          <Dimmer active>
            {" "}
            <Loader content="Uploading image please wait" />{" "}
          </Dimmer>
        )}
        <Modal.Header>
          {assetId ? "Attach" : "Add"} Hose to {assetId ? "Asset" : "inventory"}
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Column width={16}>
              <h4>{"Hose Identification : " + hoseName}</h4>
            </Grid.Column>
            <Grid.Column width={16}>{whoMadeTheHoseJsx}</Grid.Column>
            <Grid.Column width={16}>
              <Grid>
                <Grid.Column width={16}>{leftFittingJSX}</Grid.Column>
                <Grid.Column width={16}>{rightFittingJSX}</Grid.Column>
              </Grid>
            </Grid.Column>
            <Grid.Column width={16}>
              {wasPushedProperlyJsx}
              {hoseHasBeenCleanedJsx}
            </Grid.Column>
            <Grid.Column width={16}>
              <b> Attach hose image </b>
              {/* <UploadImage
                onSelectFile={}
                selectedFile={}
              /> */}
              <Grid>
                <Grid.Column
                  width={8}
                  mobile={8}
                  computer={8}
                  textAlign="center"
                >
                  <Dropzone
                    style={{
                      height: "20px !important",
                      width: "280px",
                      border: "2px dashed #555",
                      borderRadius: "5px"
                    }}
                    accept="image/jpeg, image/png"
                    multiple={false}
                    maxSize={5000000}
                    onDrop={this.onHoseSelectImageHandler}
                  >
                    <div className="dropzone">
                      <p>Drop image here / Click here to upload image</p>
                    </div>
                  </Dropzone>
                </Grid.Column>
                <Grid.Column width={8} mobile={16} computer={8}>
                  {selectedHoseImage ? (
                    <Image
                      src={selectedHoseImage.link}
                      size="small"
                      style={{ width: "80px", height: "80px" }}
                    />
                  ) : null}
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          {!isLoading ? (
            <Button color="green" onClick={this.sendToInventoryOrAssetHandler}>
              <Icon name="checkmark" />
              {/* Adding hose to asset or inventory */}
              {assetId ? "Attach" : "Add"} Hose to{" "}
              {assetId ? "Asset" : "inventory"}
            </Button>
          ) : (
            <Button color="green" loading>
              Adding please wait
            </Button>
          )}
          <Button primary onClick={cancelAddToInventoryOrAssetModal} inverted>
            <Icon name="cancel" /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

SendToInventoryOrAssetModalJSX.propType = {
  sendToInventoryOrAssetModal: PropTypes.bool,
  cancelAddToInventoryOrAssetModal: PropTypes.func,
  hoseName: PropTypes.hoseName
};

const mapStateToProps = state => ({
  response: state.hoseMaker.response,
  leftCrimpSize: state.hoseMaker.leftCrimpSize,
  rightCrimpSize: state.hoseMaker.rightCrimpSize,
  createdBy: state.hoseMaker.createdBy,
  assetId: state.hoseMaker.assetId,
  modal: state.hoseMaker.modal,
  subModal: state.hoseMaker.subModal,
  position: state.hoseMaker.position,
  hoseId: state.hoseMaker.hoseId,
  updateHoseResponse: state.hoseMechanic.updateHoseResponse,
  selectedInventoryLocation: state.hoseMechanic.selectedInventoryLocation,
  stockCode: state.hoseMaker.stockCode,
  hoseDescription: state.hoseMaker.hoseDescription,
  uniqueCode: state.hoseMaker.uniqueCode,
  positionId: state.hoseMaker.positionId
});

const reducer = { ...hoseMakerReducer, ...hoseMechanicReducer };

const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SendToInventoryOrAssetModalJSX)
);
