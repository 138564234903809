import {
  apiGetCustomerHoses,
  apiCheckHoseItems,
  apiAddHoseItems,
  apiSaveToHoseMechanic,
  apiUpdateHose,
  apiUpdateHoseConfirmationData
} from "./hoseMechanic.api";
import { apiAttachHoses } from "../AssetDetails/assetDetail.api";

const SET_MY_HOSES = "GET_MY_HOSES";
const SET_HOSE_CHECK_RESPONSE = "SET_HOSE_CHECK_RESPONSE";
const ADD_HOSE_ITEMS = "ADD_HOSE_ITEMS";
const CLEAR_ITEM_ARRAY = "CLEAR_ITEM_ARRAY";
const SAVE_TO_HOSE_MECHANIC = "SAVE_TO_HOSE_MECHANIC";
const UPDATE_HOSE_DATA = "UPDATE_HOSE_DATA";
const SET_INVENTORY_LOCATION = "SET_INVENTORY_LOCATION";
const ATTACH_HOSE_TO_ASSET = "ATTACH_HOSE_TO_ASSET";

const initialState = {
  hoses: {},
  response: {},
  itemsNotAvailable: [],
  saveHoseToHoseMechanicResponse: {},
  updateHoseResponse: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MY_HOSES: {
      return {
        ...state,
        hoses: action.payload
      };
    }
    case SET_HOSE_CHECK_RESPONSE: {
      return {
        ...state,
        itemsNotAvailable: action.payload
      };
    }
    case ADD_HOSE_ITEMS: {
      return {
        ...state,
        response: action.payload
      };
    }
    case CLEAR_ITEM_ARRAY: {
      return {
        ...state,
        itemsNotAvailable: []
      };
    }
    case SAVE_TO_HOSE_MECHANIC: {
      return {
        ...state,
        saveHoseToHoseMechanicResponse: action.payload
      };
    }
    case UPDATE_HOSE_DATA: {
      return {
        ...state,
        updateHoseResponse: action.payload
      };
    }
    case SET_INVENTORY_LOCATION: {
      return {
        ...state,
        selectedInventoryLocation: action.payload
      };
    }
    case ATTACH_HOSE_TO_ASSET: {
      return {
        ...state,
        response: action.payload
      };
    }
    default: {
      return state;
    }
  }
};

export const actionGetCustomerHoses = activePage => {
  return async dispatch => {
    try {
      const result = await apiGetCustomerHoses(activePage);
      dispatch({ type: SET_MY_HOSES, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_MY_HOSES, payload: e });
    }
  };
};

export const actionCheckHoseItemsInInventory = (hoseId, location) => {
  return async dispatch => {
    try {
      const result = await apiCheckHoseItems(hoseId, location);
      dispatch({ type: SET_HOSE_CHECK_RESPONSE, payload: result.data });
      return result;
    } catch (e) {
      dispatch({ type: SET_HOSE_CHECK_RESPONSE, payload: e });
    }
  };
};

export const actionAddHoseItemsInInventory = (hoseId, location) => {
  return async dispatch => {
    try {
      const result = await apiAddHoseItems(hoseId, location);
      dispatch({ type: ADD_HOSE_ITEMS, payload: result.data });
      return result;
    } catch (e) {
      dispatch({ type: ADD_HOSE_ITEMS, payload: e });
    }
  };
};

export const actionClearReducerItem = () => {
  return dispatch => {
    dispatch({ type: CLEAR_ITEM_ARRAY, payload: [] });
  };
};

export const actionSaveToHoseMechanic = hoseData => {
  return async dispatch => {
    try {
      const result = await apiSaveToHoseMechanic(hoseData);
      dispatch({ type: SAVE_TO_HOSE_MECHANIC, payload: result.data });
    } catch (e) {
      dispatch({ type: SAVE_TO_HOSE_MECHANIC, payload: e });
    }
  };
};

export const actionUpdateHoseOfHoseMechanic = hoseData => {
  return async dispatch => {
    try {
      const result = await apiUpdateHose(hoseData);
      dispatch({ type: UPDATE_HOSE_DATA, payload: result });
    } catch (e) {
      dispatch({ type: UPDATE_HOSE_DATA, payload: e });
    }
  };
};

export const actionUpdateHoseConfirmationData = dataToUpdate => {
  return async dispatch => {
    try {
      const result = await apiUpdateHoseConfirmationData(dataToUpdate);
      dispatch({ type: UPDATE_HOSE_DATA, payload: result });
    } catch (e) {
      dispatch({ type: UPDATE_HOSE_DATA, payload: e });
    }
  };
};

export const actionSetInventoryLocation = location => {
  return dispatch => {
    dispatch({ type: SET_INVENTORY_LOCATION, payload: location });
  };
};

export const actionAttachHoseToAsset = data => {
  return async dispatch => {
    try {
      const result = await apiAttachHoses(data);
      dispatch({ type: ATTACH_HOSE_TO_ASSET, payload: result });
      return result;
    } catch (e) {
      dispatch({ type: ATTACH_HOSE_TO_ASSET, payload: e });
    }
  };
};
