import axios from "axios";
import { ASSETS, ALL } from "../../constants/apiLink";

export const apiGetAssets = async (limit, pageNumber) => {
  let url = ASSETS;

  if (limit) {
    url = url + "?limit=" + limit;
  }
  if (pageNumber) {
    url = url + "&pageNumber=" + pageNumber;
  }

  return axios.get(url).then(result => {
    return result;
  });
};

export const apiAddAsset = async data => {
  const url = ASSETS;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiUpdateAsset = async id => {
  const url = ASSETS + "id=" + id;

  return axios.delete(url).then(result => {
    return result;
  });
};

export const apiGetAllAsset = async () => {
  const url = ASSETS + ALL;
  return axios.get(url).then(result => {
    return result;
  });
};
