import _ from "lodash";
import moment from "moment";
import "./addScheduleForm.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import { Control, Errors } from "react-redux-form";
import DatePicker from "../../components/DatePicker";
import { showSuccessToast } from "../../utils/toastUtils";
import { getErrorMessage } from "../../utils/error.utils";
import NumericInput from "react-numeric-input";
import { weeklyList } from "../../constants/appConstants";
import * as assetDetailReducer from "../../containers/AssetDetails/assetDetail.reducer";
import {
  Grid,
  Form,
  Button,
  Dropdown,
  Checkbox,
  Message,
  Icon
} from "semantic-ui-react";

const SCHEDULE_FORM = "scheduleForm";

class ScheduleForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      errorMessage: ""
    };
  }

  componentDidMount() {
    this.props.actionGetFormsForDropdown();
    this.props.actionGetUsersForDropdown();
  }

  setForm = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("form", value);
  };

  setDueDate = date => {
    this.props.actionSetFormField("dueDate", date);
  };

  setStartDate = date => {
    this.props.actionSetFormField("startDate", date);
  };
  setEndDate = date => {
    this.props.actionSetFormField("endDate", date);
  };

  setAssignedUser = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("assignedTo", value);
  };

  setNotifyMember = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("emailNotifications", value);
  };

  addNewSchedule = async () => {
    const { scheduleForm } = this.props;
    const { quantity } = scheduleForm;
    if (quantity === 0) {
      this.setState({
        isError: true,
        errorMessage: "Kilometer/Engine Hours can not be 0"
      });
      return;
    }
    this.clearErrorMessage();
    await this.props.actionAddSchedule(scheduleForm);
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      showSuccessToast(
        <div>
          <Icon name="time" />
          New schedule maintenance added
        </div>
      );
      this.props.closeModal();
    } else {
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
    this.clearErrorMessage();
  };

  repeatSchedule = occurrence => {
    const { repeat } = this.state;
    this.props.actionSetFormField("occurrence", occurrence);
    if (occurrence === "series") {
      this.props.actionOmitFormField(["dueDate", "kilometer"]);
    } else {
      this.props.actionOmitFormField(["days", "startDate", "endDate"]);
    }
    this.setState({ repeat: !repeat });
  };

  checkedDay = value => {
    let { days } = this.props.scheduleForm;
    let days_old = [...days];
    let isAvailable = _.includes(days, value);
    if (isAvailable) {
      _.remove(days_old, n => value === n);
    } else {
      days_old.push(value);
    }
    this.props.actionSetFormField("days", days_old);
  };

  checkedWeekDays = () => {
    this.props.actionSetFormField("days", [1, 2, 3, 4, 5]);
  };
  checkedAllDays = () => {
    this.props.actionSetFormField("days", [0, 1, 2, 3, 4, 5, 6]);
  };

  updateState = (isError, errorMessage) =>
    this.setState({ isError, errorMessage });

  goToAddForm = () => {
    this.props.history.push("/addMaintenanceForm");
  };

  quantityChangeHandler = (e, data) => {
    this.props.actionSetQuantity(`${SCHEDULE_FORM}.kilometer`, e);
  };

  clearErrorMessage = () => {
    this.setState({
      isError: false,
      errorMessage: ""
    });
  };

  render() {
    const { isLoading, isError, repeat, errorMessage } = this.state;
    const { allForms, users, scheduleForm } = this.props;
    const {
      assignedTo,
      form,
      dueDate,
      days,
      startDate,
      endDate,
      kilometer
    } = scheduleForm;

    let usersList = [];
    if (users.length) {
      _.map(users, user => {
        const { firstName, lastName, _id } = user;
        usersList.push({
          key: _id,
          text: `${firstName} ${lastName}`,
          value: _id
        });
      });
    }

    let formList = [];
    if (allForms && allForms.length) {
      _.map(allForms, form => {
        const { name, _id } = form;
        formList.push({ text: name, value: _id });
      });
    }

    const FormName = (
      <Form.Field>
        <label>
          Form name <span style={{ color: "red" }}>*</span>
          <a
            onClick={this.goToAddForm}
            className="linkToPage"
            style={{ float: "right" }}
          >
            Add new
          </a>
        </label>
        <Dropdown
          placeholder="Select Form"
          fluid
          selection
          value={form}
          required
          onChange={this.setForm}
          options={formList}
        />
        {_.isEmpty(formList) ? (
          <span style={{ color: "red" }}>
            No forms available, Create a new one!
          </span>
        ) : null}
        <Grid.Column
          computer={2}
          mobile={4}
          tablet={4}
          textAlign="right"
          verticalAlign="middle"
        />
      </Form.Field>
    );

    const operatorName = (
      <Form.Field required>
        <label>Assigned to</label>
        <Dropdown
          placeholder="Assigned To"
          fluid
          value={assignedTo}
          selection
          required
          onChange={this.setAssignedUser}
          options={usersList}
        />
        {_.isEmpty(usersList) ? (
          <span style={{ color: "red" }}>
            No users available, Create a new one!
          </span>
        ) : null}
      </Form.Field>
    );

    const MaintenanceDate = (
      <Form.Field required>
        <label>Maintenance date </label>
        <DatePicker
          givenDate={dueDate ? dueDate : null}
          setDate={this.setDueDate}
          minDate={moment()}
        />
      </Form.Field>
    );

    const kilometerJSX = (
      <Form.Field required>
        <label>Kilometer/Engine Hours</label>
        <NumericInput
          min={1}
          max={100000}
          maxLength={5}
          className="form-control"
          validateOn="change"
          placeholder="Kilometer/Engine Hours"
          required
          onChange={this.quantityChangeHandler}
          value={kilometer}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.scheduleForm.kilometer"
          show="touched"
        />
      </Form.Field>
    );

    const notes = (
      <Form.Field>
        <label>Notes</label>
        <Control.textarea
          model="forms.scheduleForm.note"
          placeholder="Notes"
          validateOn="change"
          rows={3}
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.scheduleForm.note"
          show="touched"
        />
      </Form.Field>
    );

    const StartDate = (
      <Form.Field required>
        <label>Start date </label>
        <DatePicker
          maxDate={endDate ? endDate : null}
          givenDate={startDate ? startDate : null}
          setDate={this.setStartDate}
          minDate={moment()}
        />
      </Form.Field>
    );
    const EndDate = (
      <Form.Field required>
        <label>End date </label>
        <DatePicker
          givenDate={endDate ? endDate : null}
          setDate={this.setEndDate}
          minDate={startDate ? startDate : null}
        />
      </Form.Field>
    );

    const WeeklyChecks = (
      <span className="weekly-checks">
        <div style={{ paddingBottom: "10px" }}>
          <Button
            type="button"
            size="mini"
            color="blue"
            content="Week Days"
            basic
            onClick={this.checkedWeekDays}
          />
          <Button
            type="button"
            size="mini"
            color="blue"
            content="All Days"
            basic
            onClick={this.checkedAllDays}
          />
          <Button
            type="button"
            size="mini"
            color="red"
            content="Clear Checks"
            basic
            onClick={() => this.props.actionSetFormField("days", [])}
          />
        </div>
        <div>
          {_.map(weeklyList, (day, key) => {
            const { name, value } = day;
            return (
              <Checkbox
                key={key}
                label={<label>{name}</label>}
                onChange={() => this.checkedDay(value)}
                value={value}
                checked={_.includes(days, value)}
              />
            );
          })}
        </div>
      </span>
    );

    const nextOptions = (
      <div>
        <label>
          <b>Maintenance type:</b>
        </label>
        <Form.Group inline style={{ padding: "10px", marginBottom: "0px" }}>
          <Form.Field>
            <Control.radio
              model="forms.scheduleForm.occurrence"
              name="single"
              value="single"
              onChange={() => this.repeatSchedule("single")}
            />
            <label htmlFor="retail" className="playerType">
              One time
            </label>
          </Form.Field>
          <Form.Field>
            <Control.radio
              model="forms.scheduleForm.occurrence"
              name="series"
              value="series"
              onChange={() => this.repeatSchedule("series")}
            />
            <label htmlFor="retail" className="playerType">
              Repeat
            </label>
          </Form.Field>
        </Form.Group>
      </div>
    );

    const OneTimeSchedule = (
      <Grid>
        <Grid.Column width={16} computer={8} mobile={16}>
          {MaintenanceDate}
        </Grid.Column>
        <Grid.Column width={16} computer={8} mobile={16}>
          {kilometerJSX}
        </Grid.Column>
      </Grid>
    );

    const RepeatSchedule = (
      <Grid className="repeat-schedule">
        <Grid.Column width={16} computer={8} mobile={16}>
          {StartDate}
        </Grid.Column>

        <Grid.Column width={16} computer={8} mobile={16}>
          {EndDate}
        </Grid.Column>

        <Grid.Column width={16} computer={16} mobile={16}>
          {WeeklyChecks}
        </Grid.Column>
      </Grid>
    );

    const actionButton = (
      <span>
        <Button
          size="small"
          onClick={this.props.closeModal}
          className="button-float"
          content="Cancel"
        />
        {!isLoading ? (
          <Button
            type="submit"
            secondary
            size="small"
            className="button-float"
            onSubmit={this.submitCafFormHandler}
            content="Submit"
          />
        ) : (
          <Button
            secondary
            size="small"
            className="button-float"
            loading
            content="Submit"
          />
        )}
      </span>
    );

    return (
      <div>
        {isLoading && (
          <Loader title="Loading, please wait">Loading,please wait</Loader>
        )}
        <Form
          error={isError}
          model="form.profileInfo"
          onSubmit={this.addNewSchedule}
        >
          <Grid>
            <Grid.Column width={16} computer={8} mobile={16}>
              {FormName}
            </Grid.Column>
            <Grid.Column width={16} computer={8} mobile={16}>
              {operatorName}
            </Grid.Column>

            <Grid.Column width={16} computer={8} mobile={16}>
              {nextOptions}
            </Grid.Column>

            <Grid.Column width={16} computer={8} mobile={16}>
              {/* {emailNotify} */}
            </Grid.Column>

            {repeat ? (
              <Grid.Column width={16}>{RepeatSchedule}</Grid.Column>
            ) : (
              <Grid.Column width={16}>{OneTimeSchedule}</Grid.Column>
            )}

            <Grid.Column width={16} computer={16} mobile={16}>
              {notes}
              <Message error={true} content={errorMessage} />
            </Grid.Column>
            <Grid.Column width={16} computer={16} mobile={16} textAlign="right">
              {actionButton}
            </Grid.Column>
          </Grid>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allForms: state.assetDetail.allForms,
    response: state.assetDetail.response,
    scheduleForm: state.forms.scheduleForm,
    users: state.assetDetail.users
  };
};

const reducer = { ...assetDetailReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ScheduleForm)
);
