import {
  apiGetCategoriesTree,
  apiSearch,
  apiDetailSearch,
  apiAutoComplete,
  apiGetUserInfo,
  apiGetBoxSizePriceDiscount,
} from "./search.api";

const SET_CATEGORIES = "SET_CATEGORIES";
const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
const UPDATE_SEARCH_RESULT = "UPDATE_SEARCH_RESULT";
const SET_AUTO_COMPLETE = "SET_AUTO_COMPLETE";
const CLEAR_SEARCH = "CLEAR_SEARCH";
const SET_THREAD_TYPE_1 = "SET_THREAD_TYPE_1";
const SET_THREAD_TYPE_2 = "SET_THREAD_TYPE_2";
const SET_THREAD_SIZE_1 = "SET_THREAD_SIZE_1";
const SET_THREAD_SIZE_2 = "SET_THREAD_SIZE_2";
const SET_ACCOUNT_INFO = "SET_ACCOUNT_INFO";
const BOX_PRICE = "BOX_PRICE";
const ALL_BOX_PRICE = "ALL_BOX_PRICE";

const initialState = {
  response: {},
  categories: [],
  searchResult: {},
  autoCompleteResult: [],
  threadType1: "",
  threadType2: "",
  threadSize1: 1,
  threadSize2: 1,
  accountDetails: {},
  boxPrice: {},
  allBoxPrice: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
      };
    case SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload,
      };
    case UPDATE_SEARCH_RESULT:
      const { searchResult } = state;
      const { data } = action.payload;

      const filtered = searchResult.result.filter((m) => m.id === data.id);
      const index = searchResult.result.indexOf(filtered[0]);

      searchResult.result[index].notes = data.boahubNotes;

      searchResult.result[index].productDimensions.workingPressurePSI =
        data.boahubWorkingPressurePsi;
      searchResult.result[index].productDimensions.workingPressureBAR =
        data.boahubWorkingPressureBar;
      searchResult.result[index].productDimensions.boahubQrcNominal =
        data.boahubQrcNominal;
      searchResult.result[index].productDimensions.boahubQrcMaxPsi =
        data.boahubQrcMaxPsi;
      searchResult.result[index].productDimensions.boahubQrcRatedFlow =
        data.boahubQrcRatedFlow;
      searchResult.result[index].productDimensions.boahubQrcMaxFlow =
        data.boahubQrcMaxFlow;
      searchResult.result[index].productDimensions.boahubQrcMinBurstMale =
        data.boahubQrcMinBurstMale;
      searchResult.result[index].productDimensions.boahubQrcMinBurstFemale =
        data.boahubQrcMinBurstFemale;
      searchResult.result[index].productDimensions.boahubQrcMinBurstCoupled =
        data.boahubQrcMinBurstCoupled;
      searchResult.result[index].productDimensions.boahubQrcFluidSpillage =
        data.boahubQrcFluidSpillage;
      searchResult.result[index].productDimensions.boahubQrcThreadSizeType =
        data.boahubQrcThreadSizeType;
      searchResult.result[index].productDimensions.boahubQrcIsoCouplerSize =
        data.boahubQrcIsoCouplerSize;
      searchResult.result[index].productDimensions.boahubMaleTipDiameter =
        data.boahubMaleTipDiameter;
      searchResult.result[index].productDimensions.boahubAccInsideDiameter =
        data.boahubAccInsideDiameter;
      searchResult.result[index].productDimensions.boahubAccOutsideDiamter =
        data.boahubAccOutsideDiamter;
      searchResult.result[index].productDimensions.boahubAccB = data.boahubAccB;
      searchResult.result[index].productDimensions.boahubAccD = data.boahubAccD;
      searchResult.result[index].productDimensions.boahubAccE = data.boahubAccE;
      searchResult.result[index].productDimensions.boahubAccH = data.boahubAccH;
      searchResult.result[index].productDimensions.boahubAccK = data.boahubAccK;
      searchResult.result[index].productDimensions.boahubAccL = data.boahubAccL;
      searchResult.result[index].productDimensions.boahubAccM = data.boahubAccM;
      searchResult.result[index].productDimensions.boahubAccN = data.boahubAccN;
      searchResult.result[index].productDimensions.boahubAccY = data.boahubAccY;
      searchResult.result[index].productDimensions.boahubThread1DashSize =
        data.boahubThread1DashSize;
      searchResult.result[
        index
      ].productDimensions.boahubThread1PhysicalThreadSize =
        data.boahubThread1PhysicalThreadSize;
      searchResult.result[
        index
      ].productDimensions.boahubThread1FlangeThickness =
        data.boahubThread1FlangeThickness;
      searchResult.result[
        index
      ].productDimensions.boahubThread1NominalPipeSize =
        data.boahubThread1NominalPipeSize;
      searchResult.result[index].productDimensions.boahubThread2DashSize =
        data.boahubThread2DashSize;
      searchResult.result[
        index
      ].productDimensions.boahubThread2PhysicalThreadSize =
        data.boahubThread2PhysicalThreadSize;
      searchResult.result[
        index
      ].productDimensions.boahubThread2FlangeThickness =
        data.boahubThread2FlangeThickness;
      searchResult.result[
        index
      ].productDimensions.boahubThread2NominalPipeSize =
        data.boahubThread2NominalPipeSize;
      searchResult.result[index].productDimensions.boahubWallThickness =
        data.boahubWallThickness;
      searchResult.result[index].productDimensions.boahubVacuum =
        data.boahubVacuum;
      searchResult.result[index].productDimensions.boahubHoseIdInch =
        data.boahubHoseIdInch;
      searchResult.result[index].productDimensions.boahubHoseIdMm =
        data.boahubHoseIdMm;
      searchResult.result[index].productDimensions.boahubHoseOdInch =
        data.boahubHoseOdInch;
      searchResult.result[index].productDimensions.boahubHoseOdMm =
        data.boahubHoseOdMm;
      searchResult.result[index].productDimensions.boahubMinBendRadius =
        data.boahubMinBendRadius;
      searchResult.result[index].productDimensions.boahubLc = data.boahubLc;
      searchResult.result[index].productDimensions.boahubLd = data.boahubLd;
      searchResult.result[index].productDimensions.boahubH1 = data.boahubH1;
      searchResult.result[index].productDimensions.boahubH = data.boahubH;
      searchResult.result[index].productDimensions.boahubD2 = data.boahubD2;
      searchResult.result[index].productDimensions.boahubCb = data.boahubCb;
      searchResult.result[index].productDimensions.boahubCd = data.boahubCd;
      searchResult.result[index].productDimensions.boahubCe = data.boahubCe;
      searchResult.result[index].productDimensions.boahubCh = data.boahubCh;
      searchResult.result[index].productDimensions.boahubCl = data.boahubCl;
      searchResult.result[index].productDimensions.boahubCm = data.boahubCm;
      searchResult.result[index].productDimensions.boahubCn = data.boahubCn;
      searchResult.result[index].productDimensions.boahubCy = data.boahubCy;
      searchResult.result[index].productDimensions.boahubCk = data.boahubCk;
      searchResult.result[index].productDimensions.boahubTubeSize =
        data.boahubTubeSize;
      searchResult.result[index].productDimensions.boahubBandWidth =
        data.boahubBandWidth;
      searchResult.result[index].productDimensions.boahubHoleSize =
        data.boahubHoleSize;
      searchResult.result[index].productDimensions.boahubThread3DashSize =
        data.boahubThread3DashSize;
      searchResult.result[
        index
      ].productDimensions.boahubThread3PhysicalThreadSize =
        data.boahubThread3PhysicalThreadSize;
      searchResult.result[
        index
      ].productDimensions.boahubThread3NominalPipeSize =
        data.boahubThread3NominalPipeSize;
      searchResult.result[index].productDimensions.boahubThread4DashSize =
        data.boahubThread4DashSize;
      searchResult.result[
        index
      ].productDimensions.boahubThread4PhysicalThreadSize =
        data.boahubThread4PhysicalThreadSize;
      searchResult.result[
        index
      ].productDimensions.boahubThread4NominalPipeSize =
        data.boahubThread4NominalPipeSize;

      //searchResult.result[index].productDimensions.y = null;
      // searchResult.result[index].productDimensions.threadTypeFilter2 = null;
      // searchResult.result[index].productDimensions.threadTypeFilter1 = null;
      // searchResult.result[index].productDimensions.threadType4 = null;
      // searchResult.result[index].productDimensions.threadType3 = null;
      // searchResult.result[index].productDimensions.threadType2 = null;
      // searchResult.result[index].productDimensions.threadType1 = null;
      // searchResult.result[index].productDimensions.threadColourCode = null;
      // searchResult.result[index].productDimensions.Thread3IDSSadaptor = null;
      // searchResult.result[index].productDimensions.tailSizeToSuitHoseID = null;
      // searchResult.result[index].productDimensions.suitsHose = null;
      // searchResult.result[index].productDimensions.ratedFlow = null;
      // searchResult.result[index].productDimensions.prefixThread1 = null;
      // searchResult.result[index].productDimensions.physicalThreadSize4 = null;
      // searchResult.result[index].productDimensions.physicalThreadSize3 = null;
      // searchResult.result[index].productDimensions.physicalThreadSize2 = null;
      // searchResult.result[index].productDimensions.physicalThreadSize1 = null;
      // searchResult.result[index].productDimensions.orientation = null;
      // searchResult.result[index].productDimensions.nominalThreadSize4 = null;
      // searchResult.result[index].productDimensions.nominalThreadSize3 = null;
      // searchResult.result[index].productDimensions.nominalThreadSize2 = null;
      // searchResult.result[index].productDimensions.nominalThreadSize1 = null;
      // searchResult.result[index].productDimensions.nominalSizeMM = null;
      // searchResult.result[index].productDimensions.nominalPipeSize = null;
      // searchResult.result[index].productDimensions.n = null;
      // searchResult.result[index].productDimensions.minimumBendRadiusMM = null;
      // searchResult.result[index].productDimensions.minBurstPressureMalePSI =
      //   null;
      // searchResult.result[index].productDimensions.minBurstPressureFemalePSI =
      //   null;
      // searchResult.result[index].productDimensions.minBurstPressureCoupledPSI =
      //   null;
      // searchResult.result[index].productDimensions.maxWorkpressurePSI = null;
      // searchResult.result[index].productDimensions.maxFlow = null;
      // searchResult.result[index].productDimensions.maleFemaleFlangeFittingOnly =
      //   null;
      // searchResult.result[index].productDimensions.m = null;
      // searchResult.result[index].productDimensions.ld = null;
      // searchResult.result[index].productDimensions.lc = null;
      // searchResult.result[index].productDimensions.l = null;
      // searchResult.result[index].productDimensions.kDrillDiameter = null;
      // searchResult.result[
      //   index
      // ].productDimensions.identifierRelatedinfoThread2ID = null;
      // searchResult.result[index].productDimensions.hoseOuterDiameterMM = null;
      // searchResult.result[index].productDimensions.hoseInnerDiameterMM = null;
      // searchResult.result[index].productDimensions.hoseInnerDiameterinches =
      //   null;
      // searchResult.result[index].productDimensions.h2 = null;
      // searchResult.result[index].productDimensions.h1 = null;
      // searchResult.result[index].productDimensions.h = null;
      // searchResult.result[index].productDimensions.fullCoilSizeMetre = null;
      // searchResult.result[index].productDimensions.fluidSpillageCC = null;
      // searchResult.result[index].productDimensions.e = null;
      // searchResult.result[index].productDimensions.DKOLDKOS = null;
      // searchResult.result[index].productDimensions.dashSize4 = null;
      // searchResult.result[index].productDimensions.dashSize3 = null;
      // searchResult.result[index].productDimensions.dashSize2 = null;
      // searchResult.result[index].productDimensions.dashSize1 = null;
      // searchResult.result[index].productDimensions.d = null;
      // searchResult.result[index].productDimensions.collatedIdentifier = null;
      // searchResult.result[index].productDimensions.b = null;

      return {
        ...state,
        searchResult,
      };
    case SET_AUTO_COMPLETE:
      return {
        ...state,
        autoCompleteResult: action.payload,
      };
    case CLEAR_SEARCH: {
      return {
        ...state,
        autoCompleteResult: [],
        searchResult: [],
      };
    }
    case SET_THREAD_TYPE_1: {
      return {
        ...state,
        threadType1: action.payload,
      };
    }
    case SET_THREAD_TYPE_2: {
      return {
        ...state,
        threadType2: action.payload,
      };
    }
    case SET_THREAD_SIZE_1: {
      return {
        ...state,
        threadSize1: action.payload,
      };
    }
    case SET_THREAD_SIZE_2: {
      return {
        ...state,
        threadSize2: action.payload,
      };
    }
    case SET_ACCOUNT_INFO:
      return {
        ...state,
        accountDetails: action.payload,
      };

    case BOX_PRICE: {
      return {
        ...state,
        boxPrice: action.payload,
      };
    }
    case ALL_BOX_PRICE: {
      return {
        ...state,
        allBoxPrice: [...state.allBoxPrice, action.payload],
      };
    }
    default:
      return state;
  }
};

export const actionSetCategories = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetCategoriesTree();
      dispatch({ type: SET_CATEGORIES, payload: result });
    } catch (e) {
      dispatch({ type: SET_CATEGORIES, payload: e.msg });
    }
  };
};

export const actionSearchProducts = (
  searchTerm,
  level,
  activePage,
  activeNode,
  threadType1,
  threadSize1,
  threadType2,
  threadSize2
) => {
  return async (dispatch) => {
    let result = {};
    try {
      if (level) {
        if (!level.includes(",") && activePage === 1) {
          result = {
            data: { length: 15, offSet: 1, pageSize: 10, records: [] },
          };
        } else {
          result = await apiSearch(
            searchTerm,
            level,
            activePage,
            activeNode,
            threadType1,
            threadSize1,
            threadType2,
            threadSize2
          );
        }
      } else {
        result = await apiSearch(
          searchTerm,
          level,
          activePage,
          activeNode,
          threadType1,
          threadSize1,
          threadType2,
          threadSize2
        );
      }

      // const pagesCount = Math.ceil(result.data.length / result.data.pageSize);
      // if (pagesCount === 1) return null;
      // const pages = _.range(1, pagesCount + 1);

      let dataResult = {
        totalPages: Math.ceil(result.data.length / result.data.pageSize),
        totalRecords: result.data.length,
        activePage: result.data.offset,
        result: [],
        secondaryGrpDisplayName: "",
        narrowFilters: [],
        tertiaryGrpDisplayName: "",
      };

      if (activeNode) dataResult.narrowFilters = [...activeNode.children];

      result.data.records.map((item) => {
        // Convert Metre to Feet is user's country code is US
        if (
          localStorage.getItem("userCountryCode") === "US" &&
          (item.uom && item.uom.name) === "Metre"
        )
          item.uom.name = "Feet";

        dataResult.result.push({
          id: item.id,
          pack: item.uom && item.uom.name,
          price: item.listPrice,
          currency: item.currency && item.currency["name"],
          primaryGrp: item.currency,
          secondaryGrp: "",
          tertiaryGrp: "",
          productFamily: "",
          boahubFullCarton: item.boahubFullCarton,
          category: item.category,
          stockCode: item.barcode,
          stockDescription: item.name,
          stockImage1: item.image128,
          stockImage2: item.image256,
          stockImage3: item.image512,
          notes: item.boahubNotes,
          productDimensions: {
            y: null,
            workingPressurePSI: item.boahubWorkingPressurePsi,
            workingPressureBAR: item.boahubWorkingPressureBar,
            threadTypeFilter2: null,
            threadTypeFilter1: null,
            threadType4: null,
            threadType3: null,
            threadType2: null,
            threadType1: null,
            threadColourCode: null,
            Thread3IDSSadaptor: null,
            tailSizeToSuitHoseID: null,
            suitsHose: null,
            ratedFlow: null,
            prefixThread1: null,
            physicalThreadSize4: null,
            physicalThreadSize3: null,
            physicalThreadSize2: null,
            physicalThreadSize1: null,
            orientation: null,
            nominalThreadSize4: null,
            nominalThreadSize3: null,
            nominalThreadSize2: null,
            nominalThreadSize1: null,
            nominalSizeMM: null,
            nominalPipeSize: null,
            n: null,
            minimumBendRadiusMM: null,
            minBurstPressureMalePSI: null,
            minBurstPressureFemalePSI: null,
            minBurstPressureCoupledPSI: null,
            maxWorkpressurePSI: null,
            maxFlow: null,
            maleFemaleFlangeFittingOnly: null,
            m: null,
            ld: null,
            lc: null,
            l: null,
            kDrillDiameter: null,
            identifierRelatedinfoThread2ID: null,
            hoseOuterDiameterMM: null,
            hoseInnerDiameterMM: null,
            hoseInnerDiameterinches: null,
            h2: null,
            h1: null,
            h: null,
            fullCoilSizeMetre: null,
            fluidSpillageCC: null,
            e: null,
            DKOLDKOS: null,
            dashSize4: null,
            dashSize3: null,
            dashSize2: null,
            dashSize1: null,
            d: null,
            collatedIdentifier: null,
            b: null,
          },
          priceGrp: "",
          shortenedCode: null,
          secondaryGrpDisplayName: "",
          tertiaryGrpDisplayName: "",
          productFamilyDisplayName: "",
          pricingPolicy: {
            price: item.listPrice,
            currency: item.currency.name,
            currencyId: item.currency.id,
            discount: 0,
            subTotal: 0,
            totalPrice: 0,
            totalDiscount: 0,
            policyName: "",
          },
          productSharingLink:
            "https://www.boahub.co/product?stockCodeStr=R5-04",
          enableProductSharingLink: true,
          productUom: item.uom.id,
          taxId: item.taxId,
          countryCode: item.countryCode,
          buyPrice: item.buyPrice,
          retailPrice: item.retailPrice,
          buyPriceStr:
            item.buyPrice != null &&
            (item.buyPrice == 0.0
              ? "CALL BOA"
              : `${item.countryCode} $${Number(item.buyPrice).toFixed(2)}`),
          retailPriceStr:
            item.retailPrice != null &&
            (item.retailPrice == 0.0
              ? "CALL BOA"
              : `${item.countryCode} $${Number(item.retailPrice).toFixed(2)}`),
        });
      });

      dispatch({ type: SET_SEARCH_RESULT, payload: dataResult });
      return result;
    } catch (e) {
      dispatch({ type: SET_SEARCH_RESULT, payload: e.msg });
    }
  };
};

export const actionSearchProductDetail = (item) => {
  return async (dispatch) => {
    try {
      const result = await apiDetailSearch(item);

      // let dataResult = {
      //   totalPages: Math.ceil(result.data.length / result.data.pageSize),
      //   totalRecords: result.data.length,
      //   activePage: result.data.offset,
      //   result: [],
      //   secondaryGrpDisplayName: "",
      //   narrowFilters: [],
      //   tertiaryGrpDisplayName: "",
      // };

      // result.data.records.map((item) => {
      //   dataResult.result.push({
      //     id: item.id,
      //     pack: item.uom && item.uom["name"],
      //     price: item.listPrice,
      //     currency: item.currency && item.currency["name"],
      //     primaryGrp: item.currency,
      //     secondaryGrp: "",
      //     tertiaryGrp: "",
      //     productFamily: "",
      //     stockCode: item.defaultCode,
      //     stockDescription: item.name,
      //     stockImage1: item.image128,
      //     stockImage2: item.image256,
      //     stockImage3: item.image512,
      //     notes: item.boahubNotes,
      //     productDimensions: {
      //       y: null,
      //       workingPressurePSI: item.boahubWorkingPressurePsi,
      //       workingPressureBAR: item.boahubWorkingPressureBar,
      //       threadTypeFilter2: null,
      //       threadTypeFilter1: null,
      //       threadType4: null,
      //       threadType3: null,
      //       threadType2: null,
      //       threadType1: null,
      //       threadColourCode: null,
      //       Thread3IDSSadaptor: null,
      //       tailSizeToSuitHoseID: null,
      //       suitsHose: null,
      //       ratedFlow: null,
      //       prefixThread1: null,
      //       physicalThreadSize4: null,
      //       physicalThreadSize3: null,
      //       physicalThreadSize2: null,
      //       physicalThreadSize1: null,
      //       orientation: null,
      //       nominalThreadSize4: null,
      //       nominalThreadSize3: null,
      //       nominalThreadSize2: null,
      //       nominalThreadSize1: null,
      //       nominalSizeMM: null,
      //       nominalPipeSize: null,
      //       n: null,
      //       minimumBendRadiusMM: null,
      //       minBurstPressureMalePSI: null,
      //       minBurstPressureFemalePSI: null,
      //       minBurstPressureCoupledPSI: null,
      //       maxWorkpressurePSI: null,
      //       maxFlow: null,
      //       maleFemaleFlangeFittingOnly: null,
      //       m: null,
      //       ld: null,
      //       lc: null,
      //       l: null,
      //       kDrillDiameter: null,
      //       identifierRelatedinfoThread2ID: null,
      //       hoseOuterDiameterMM: null,
      //       hoseInnerDiameterMM: null,
      //       hoseInnerDiameterinches: null,
      //       h2: null,
      //       h1: null,
      //       h: null,
      //       fullCoilSizeMetre: null,
      //       fluidSpillageCC: null,
      //       e: null,
      //       DKOLDKOS: null,
      //       dashSize4: null,
      //       dashSize3: null,
      //       dashSize2: null,
      //       dashSize1: null,
      //       d: null,
      //       collatedIdentifier: null,
      //       b: null,
      //     },
      //     priceGrp: "",
      //     shortenedCode: null,
      //     secondaryGrpDisplayName: "",
      //     tertiaryGrpDisplayName: "",
      //     productFamilyDisplayName: "",
      //     pricingPolicy: {
      //       price: item.listPrice,
      //       currency: item.currency.name,
      //       currencyId: item.currency.id,
      //       discount: 0,
      //       subTotal: 0,
      //       totalPrice: 0,
      //       totalDiscount: 0,
      //       policyName: "",
      //     },
      //     productSharingLink:
      //       "https://www.boahub.co/product?stockCodeStr=R5-04",
      //     enableProductSharingLink: true,
      //     productUom: item.uom.id,
      //     taxId: item.taxId,
      //   });
      // });

      dispatch({ type: UPDATE_SEARCH_RESULT, payload: result });
      return result;
    } catch (e) {
      dispatch({ type: UPDATE_SEARCH_RESULT, payload: e.msg });
    }
  };
};

export const actionAutoComplete = (searchTerm, level, searchTerm2 = "") => {
  return async (dispatch) => {
    try {
      if (searchTerm === null || searchTerm === "") {
        dispatch({ type: SET_AUTO_COMPLETE, payload: [] });
      } else {
        const result = await apiAutoComplete(searchTerm, level, searchTerm2);
        dispatch({ type: SET_AUTO_COMPLETE, payload: result.data.records });
      }
    } catch (e) {
      dispatch({ type: SET_AUTO_COMPLETE, payload: e.msg });
    }
  };
};

export const actionClearSearch = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SEARCH, payload: null });
  };
};

export const actionSetThreadType1 = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_THREAD_TYPE_1, payload: value });
  };
};

export const actionSetThreadType2 = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_THREAD_TYPE_2, payload: value });
  };
};

export const actionSetThreadSize1 = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_THREAD_SIZE_1, payload: value });
  };
};

export const actionSetThreadSize2 = (value) => {
  return (dispatch) => {
    dispatch({ type: SET_THREAD_SIZE_2, payload: value });
  };
};

export const actionSetAccountDetails = () => {
  return async (dispatch) => {
    try {
      const result = await apiGetUserInfo();
      dispatch({ type: SET_ACCOUNT_INFO, payload: result });
    } catch (e) {
      dispatch({ type: SET_ACCOUNT_INFO, payload: e });
    }
  };
};

export const actionGetBoxSizePriceDiscount = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiGetBoxSizePriceDiscount(data);
      dispatch({ type: ALL_BOX_PRICE, payload: result });
      dispatch({ type: BOX_PRICE, payload: result });
    } catch (e) {
      dispatch({ type: BOX_PRICE, payload: e });
    }
  };
};
