import React from "react";
// import Knob from "react-canvas-knob";
import NumericInput from "react-numeric-input";
import "./angleInput.css";
import { Form } from "semantic-ui-react";
import PropTypes from "prop-types";

const AngleSelector = ({angle = 180, angleChangeHandler}) => {

  function myFormat(num) {
    return num;
}
  
  return (
      <div className="angle-selector">
      {/* <Knob
          value={angle}
          min={0}
          max={360}
          step={45}
          width={100}
          thickness={0.20}
          fgColor={boaColor}
          disableTextInput={true}
          title={"Deg"}
          lineCap={"round"}
          onChange={angleChangeHandler}
        /> */}
        <Form>
          <Form.Field>
          <NumericInput 
          className="form-control"
          value={angle}
          min={0}
          max={315}
          step={45}
          strict
            onChange={angleChangeHandler}
            format={myFormat}
            />
            </Form.Field>
          </Form>
      </div>
    );
  
}

AngleSelector.propTypes = {
  angle: PropTypes.number,
  angleChangeHandler: PropTypes.func
};

export default AngleSelector;
