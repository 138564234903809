import axios from "axios";
import {
  ACCOUNT,
  STATISTICS,
  INTERESTED_IN_BOA_EMAIL,
  TOTAL_SAVING,
  DETAILS,
} from "../../constants/apiLink";

export const apiGetDiscountStatistics = () => {
  const url = ACCOUNT + STATISTICS;
  return axios.get(url).then((result) => {
    const { data } = result;
    return data;
  });
};

export const apiSendBoaAccountEmail = () => {
  const url = ACCOUNT + INTERESTED_IN_BOA_EMAIL;
  return axios.post(url).then((result) => {
    const { data } = result;
    return data;
  });
};
export const apiGetTotalSavings = () => {
  const url = ACCOUNT + TOTAL_SAVING;
  return axios.get(url).then((result) => {
    return result;
  });
};

export const apiGetAccountDetails = () => {
  const url = ACCOUNT + DETAILS;
  return axios.get(url).then((result) => {
    return result.data;
  });
};
