import axios from "axios";

export const apiExportMinMax = (id) => {
  const data = {
    stockLocationId: id,
  };
  return axios
    .get(`/api/InventoryManagement/min-max-get-all-products`, {
      params: data,
    })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiImportMinMax = (stockLocationId, products) => {
  const payload = {
    stockLocationId: stockLocationId,
    productMinMaxDetails: products,
  };
  return axios
    .put("/api/InventoryManagement/min-max-import-products", payload)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return result?.data;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};
