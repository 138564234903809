import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  Form,
  Grid,
  Modal,
  Button,
  Icon,
  Input,
  Divider,
  Segment
} from "semantic-ui-react";
import PropTypes from "prop-types";
import NumericInput from "react-numeric-input";
import _ from "lodash";
import OverAllLengthHelpModal from "./OverAllLengthHelpModal";
import * as hoseMakerReducer from "./../hoseMaker.reducer";
class HoseLength extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      openModal: false,
      hoseValue: this.props.showOal ? this.props.hoseLength : "",
      overAllValue: this.props.showOal ? this.props.overAllLength : ""
    };
  }

  static getDerivedStateFromProps(props, state) {
    return {
      hoseValue: props.hoseLength,
      overAllValue: props.overAllLength
    };
  }

  editLength = () => {
    this.setState({ edit: true });
  };

  cancelEdit = () => {
    this.setState({ edit: false });
  };
  handleOpen = () => {
    this.setState({ openModal: true });
  };

  cancelModal = () => {
    this.setState({ openModal: false, isLoading: false });
  };

  currentChangeHandler = value => {
    this.props.changeHandler(value);
    this.setState({ hoseValue: value });
  };

  currentOverAllLengthChangeHandler = value => {
    this.setState({ overAllValue: value });
    if (this.props.showOal) {
      this.props.overAllLengthChangeHandler(value);
    } else {
      this.props.overAllLengthChangeHandlerWhenNotOVAL(value);
    }
  };

  render() {
    const {
      changeHandler,
      hoseLength = 0,
      overAllLengthChangeHandler,
      overAllLength,
      showOal,
      leftFitting,
      rightFitting,
      overAllLengthChangeHandlerWhenNotOVAL
    } = this.props;

    const { openModal, hoseValue, overAllValue } = this.state;
    let leftFittingLength = 0;
    let rightFittingLength = 0;

    if (!_.isEmpty(leftFitting)) {
      const { productDimensions } = leftFitting;
      const { lc = 0 } = productDimensions;
      leftFittingLength = lc;
    }
    if (!_.isEmpty(rightFitting)) {
      const { productDimensions } = rightFitting;
      const { lc = 0 } = productDimensions;
      rightFittingLength = lc;
    }

    const editJSX = (
      <Form>
        <Form.Field required>
          <NumericInput
            onChange={value => this.currentChangeHandler(value)}
            name="Hose length"
            min={0}
            step={1}
            max={100000}
            maxLength={9}
            precision={2}
            className="form-control"
            value={hoseValue}
            required
          />
        </Form.Field>
      </Form>
    );

    const editOverAllLengthJSX = (
      <Form>
        <Form.Field required>
          <NumericInput
            onChange={this.currentOverAllLengthChangeHandler}
            name="over all length"
            min={0}
            step={1}
            precision={2}
            max={100000}
            maxLength={9}
            className="form-control"
            value={overAllValue}
            required
          />
        </Form.Field>
      </Form>
    );

    const overAllModalJSX = (
      <div>
        <p>
          Please ensure you choose between Hose Length and Overall Length
          correctly. For more information
          <a
            href=" https://s3-ap-southeast-2.amazonaws.com/portal-static-images/OAL_vs_HL.jpg"
            target="_blank"
          >
            {" "}
            click here
          </a>
        </p>
      </div>
    );

    return (
      <React.Fragment>
        <Grid columns="equal">
          {!showOal && (
            <Segment raised>
              <div style={{ fontSize: "14px" }}>
                <b> * Over all length is not available</b>
                {overAllModalJSX}
              </div>
            </Segment>
          )}
          <Grid.Column width={8} computer={8} mobile={16}>
            <label>Hose length in mm</label>
          </Grid.Column>
          <Grid.Column width={8} computer={8} mobile={16}>
            {editJSX}
          </Grid.Column>
        </Grid>

        <Grid columns="equal">
          <Grid.Column>
            <label>Overall length in mm</label>
            <br />
            <OverAllLengthHelpModal />
          </Grid.Column>
          <Grid.Column>{editOverAllLengthJSX}</Grid.Column>
          <Grid.Column width={16}>
            {showOal ? (
              <i style={{ fontSize: "12px" }}>
                Hose Length {hoseLength} + Fitting-1 {leftFittingLength} mm +
                Fitting-2 {rightFittingLength} mm ={" "}
                {overAllLength !== null
                  ? overAllLength.toFixed(2)
                  : overAllLength}{" "}
                mm
              </i>
            ) : null}
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

HoseLength.propTypes = {
  hoseLength: PropTypes.number,
  changeHandler: PropTypes.func,
  fittingLength: PropTypes.number,
  showOal: PropTypes.bool,
  leftFitting: PropTypes.object,
  rightFitting: PropTypes.object,
  overAllLengthChangeHandlerWhenNotOVAL: PropTypes.func
};

const mapStateToProps = state => ({
  hoseLength: state.hoseMaker.hoseLength,
  overAllLength: state.hoseMaker.overAllLength
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(hoseMakerReducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HoseLength)
);
