import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import {
  Segment,
  Grid,
  Icon,
  Accordion,
  Button,
  Modal,
  Header,
  Image
} from "semantic-ui-react";
import moment from "moment";
import productDefaultImage from "../../assets/images/product_default.png";

const WP_PaymentInitiated = "WP-PaymentInitiated";
const WP_PaymentFailed = "WP-PaymentFailed";
const WP_Confirmed = "WP-Confirmed";
const Invoiced = "Invoiced";
const WP_Cancelled = "WP-Cancelled";

class OrderDetails extends React.Component {
  constructor() {
    super();
    this.state = { activeIndex: null, imageModal: false, imageLink: "" };
  }

  componentDidMount() {
    this.setState({ activeIndex: null });
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  getStatus(status) {
    if (status === WP_PaymentInitiated)
      return window.polyglot.t("orderHistory.status_WP_PaymentInitiated");
    if (status === WP_PaymentFailed)
      return window.polyglot.t("orderHistory.status_WP_PaymentFailed");
    if (status === WP_Confirmed)
      return window.polyglot.t("orderHistory.status_WP_Confirmed");
    if (status === Invoiced) {
      return window.polyglot.t("orderHistory.status_Invoiced");
    }
    if (status === WP_Cancelled) {
      return window.polyglot.t("orderHistory.status_cancelled");
    }
    return "Unknown";
  }

  getSegmentColor(status) {
    if (status === WP_PaymentInitiated) return "yellow";
    if (status === WP_PaymentFailed) return "red";
    if (status === WP_Confirmed) return "green";
    if (status === Invoiced) return "green";
    if (status === WP_Cancelled) return "gray";

    return "gray";
  }

  openImageModal = imageLink => {
    this.setState({ imageModal: true, imageLink: imageLink });
  };

  handleClose = () => {
    this.setState({ imageModal: false, imageLink: "" });
  };

  render() {
    const {
      order = {},
      addToCart,
      options = true,
      setOrderNo,
      showFacilities,
      showPrice
    } = this.props;
    const { activeIndex, imageLink, imageModal } = this.state;

    const {
      createdAt = moment(),
      status,
      details,
      orderNo,
      invoiceUrl,
      addedToInventory,
      name
    } = order;
    const {
      orderTotal,
      address,
      orderDetails = [],
      subTotal,
      taxTotal,
      customerOrderNumber,
      reference
    } = details;
    let orderDate = moment(createdAt).format("LL");

    const addToCartHandler = (e, item = {}) => {
      e.preventDefault();
      addToCart(item);
    };

    const OrderHighlight = () => {
      const firstItem = _.head(orderDetails);

      const currency = firstItem.currency || "NZD";

      return (
        <Grid columns="equal">
          <Grid.Column computer={3} mobile={16} tablet={5}>
            {/* TODO check invoice url */}
            <div>
              <b>Order # </b>
              {options && invoiceUrl && (
                <a target="_black" href={invoiceUrl}>
                  View invoice
                </a>
              )}
            </div>

            <p>{name}</p>
          </Grid.Column>

          <Grid.Column computer={3} mobile={16} tablet={5}>
            <Icon name="calendar" />
            <b>Order date</b> <p> {orderDate}</p>
          </Grid.Column>

          <Grid.Column computer={3} mobile={16} tablet={5}>
            <Icon name="tag" />
            <b>Status</b>
            <p>{this.getStatus(status)}</p>
          </Grid.Column>

          <Grid.Column computer={3} mobile={16} tablet={5}>
            <Icon name="dollar" />
            <b>Total amount </b>
            {showPrice ? (
              <p>
                {currency} {orderTotal.toFixed(2)}
              </p>
            ) : null}
          </Grid.Column>

          <Grid.Column computer={4} mobile={16} tablet={16}>
            <Icon name="truck" />
            <b>Delivery address</b>
            {!_.isEmpty(address) && (
              <p>
                {address.street}, {address.city} {address.suburb}{" "}
                {address.state}, {address.country}, {address.postalCode}
              </p>
            )}
          </Grid.Column>
        </Grid>
      );
    };

    const CustomerOrderInformation = () => {
      if (!customerOrderNumber && !reference) {
        return null;
      }

      return (
        <Grid columns="equal">
          <Grid.Column computer={3} mobile={16} tablet={5}>
            <b>Customer order #</b>
            <p>{customerOrderNumber}</p>
          </Grid.Column>

          {reference && (
            <Grid.Column computer={13} mobile={16} tablet={11}>
              <b>Reference Notes</b>
              <p className="referenceText">{reference}</p>
            </Grid.Column>
          )}
        </Grid>
      );
    };

    const ProductSummary = () => {
      let summaryList = [];
      orderDetails.forEach(order => {
        const { stockCode, orderQuantity } = order;
        summaryList.push(`${orderQuantity} X ${stockCode}`);
      });
      const summaryItem = summaryList.join(", ");

      return (
        <Grid columns="equal">
          <Grid.Column computer={13} mobile={16} tablet={14}>
            <div>
              <Icon name="sticky note outline" />
              <b>Summary</b>
            </div>
            <p>{summaryItem}</p>
          </Grid.Column>
          {showFacilities ? addToInventory : null}
        </Grid>
      );
    };

    const addToInventory = (
      <Grid.Column
        computer={3}
        mobile={16}
        tablet={2}
        style={{ margin: "auto" }}
      >
        {addedToInventory ? (
          <Button positive disabled size="small" content="Added to Inventory" />
        ) : (
          <Button
            content="Add to Inventory"
            color="black"
            disabled={status !== Invoiced}
            onClick={() => setOrderNo(orderNo)}
            size="small"
          />
        )}
      </Grid.Column>
    );

    const CostSummary = () => {
      const firstItem = _.head(orderDetails);
      const currency = firstItem.currency || "NZD";
      return (
        <Grid columns="equal">
          <Grid.Column computer={3} mobile={16} tablet={5}>
            <b>Sub total amount</b>
            {showPrice ? (
              <p>
                {currency} {subTotal.toFixed(2)}
              </p>
            ) : null}
          </Grid.Column>
          <Grid.Column computer={3} mobile={16} tablet={5}>
            <b>Tax total amount</b>
            {showPrice ? (
              <p>
                {currency} {taxTotal.toFixed(2)}
              </p>
            ) : null}
          </Grid.Column>
          <Grid.Column computer={3} mobile={16} tablet={5}>
            <b>Total amount</b>
            {showPrice ? (
              <p>
                {currency} {orderTotal.toFixed(2)}
              </p>
            ) : null}
          </Grid.Column>
        </Grid>
      );
    };

    const ProductDetails = () => {
      return (
        <Segment.Group>
          <Segment inverted color="black" className="details_header">
            <Grid columns="equal">
              <Grid.Column computer={2} mobile={16} tablet={2}>
                Stock Image
              </Grid.Column>
              <Grid.Column computer={4} mobile={16} tablet={4}>
                Description
              </Grid.Column>
              <Grid.Column computer={2} mobile={16} tablet={2}>
                Stock code
              </Grid.Column>
              <Grid.Column computer={2} mobile={16} tablet={2}>
                Sales price
              </Grid.Column>
              <Grid.Column computer={2} mobile={16} tablet={2}>
                Total price
              </Grid.Column>
              <Grid.Column computer={1} mobile={16} tablet={1}>
                Order quantity
              </Grid.Column>
              {options && (
                <Grid.Column computer={3} mobile={16} tablet={3}>
                  &nbsp;
                </Grid.Column>
              )}
            </Grid>
          </Segment>
          {orderDetails.length > 0 &&
            orderDetails.map(item => {
              const {
                stockCode,
                description,
                salesPrice,
                totalPrice,
                orderQuantity,
                currency,
                pack,
                stockImage,
                hoseImage
              } = item;
              const isMetre = pack === "Metre";
              const quantityMessage = isMetre ? pack : null;

              return (
                <Segment key={stockCode}>
                  <Grid columns="equal">
                    <Grid.Column
                      computer={2}
                      mobile={16}
                      tablet={2}
                      className="no-padding"
                    >
                      <p className="details_header_mobile">Stock Image:</p>
                      <Image
                        // style={{ width: '400px', height: '200px' }}
                        src={
                          (hoseImage
                          ? hoseImage
                          : stockImage)
                            ? hoseImage
                              ? hoseImage
                              : stockImage
                            : productDefaultImage
                        }
                        alt="product"
                        className="cart-product-image"
                        onClick={() =>
                          this.openImageModal(
                            (hoseImage
                            ? hoseImage
                            : stockImage)
                              ? hoseImage
                                ? hoseImage
                                : stockImage
                              : productDefaultImage
                          )
                        }
                      />
                    </Grid.Column>
                    <Grid.Column computer={4} mobile={16} tablet={4}>
                      <p className="details_header_mobile">Description:</p>
                      {description}
                    </Grid.Column>
                    <Grid.Column computer={2} mobile={16} tablet={2}>
                      <p className="details_header_mobile">Stock code:</p>
                      <span className="order-history-stock-code">
                        {stockCode}
                      </span>
                    </Grid.Column>
                    <Grid.Column computer={2} mobile={16} tablet={2}>
                      <p className="details_header_mobile">Sales price:</p>

                      <p>
                        {currency || "NZD"} {salesPrice}
                      </p>
                    </Grid.Column>
                    <Grid.Column computer={2} mobile={16} tablet={2}>
                      <p className="details_header_mobile">Total price:</p>

                      <p>
                        {currency || "NZD"} {totalPrice}
                      </p>
                    </Grid.Column>
                    <Grid.Column computer={1} mobile={16} tablet={1}>
                      <p className="details_header_mobile">Order quantity:</p>
                      {orderQuantity} {quantityMessage}
                    </Grid.Column>
                    {options && (
                      <Grid.Column computer={3} mobile={16} tablet={3} centered>
                        <a onClick={e => addToCartHandler(e, item)}>
                          <Button
                            fluid
                            animated="vertical"
                            size="small"
                            type="submit"
                            color="black"
                            className="add-to-cart"
                          >
                            <Button.Content hidden>
                              <Icon name="cart" />
                            </Button.Content>
                            <Button.Content visible>Add to cart</Button.Content>
                          </Button>
                        </a>
                      </Grid.Column>
                    )}
                  </Grid>
                </Segment>
              );
            })}
        </Segment.Group>
      );
    };

    const modalJSX = (
      <Modal open={imageModal} onClose={this.handleClose} basic size="large">
        <Header icon="browser" content="Product image" />
        <Modal.Content>
          <Image src={imageLink} />
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.handleClose} inverted>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );

    return (
      <Segment.Group>
        <Segment color={this.getSegmentColor(status)}>
          {/* Order Summary*/}
          <OrderHighlight />
          <CustomerOrderInformation />
          <ProductSummary />
        </Segment>

        <Accordion fluid styled className="product_detail">
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            className="title"
            onClick={this.handleClick}
          >
            <Icon name="dropdown" />
            Order details
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <ProductDetails />
            <CostSummary />
          </Accordion.Content>
        </Accordion>
        {modalJSX}
      </Segment.Group>
    );
  }
}

OrderDetails.propTypes = {
  order: PropTypes.object,
  addToCart: PropTypes.func,
  options: PropTypes.bool,
  setOrderNo: PropTypes.func
};

export default OrderDetails;
