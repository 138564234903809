import React from "react";
import MetaData from "../../components/MetaData";
import { Grid } from "semantic-ui-react";

import ProfileDetails from "../../components/ProfileDetails";
import AccountDetails from "../../components/AccountDetails";

import "./profile.css";

const Profile = () => {
  return (
    <div className="profile-section">
      <MetaData title="Profile" />
      <Grid>
        <Grid.Column width={16}>
          <ProfileDetails />
        </Grid.Column>
        <Grid.Column width={16}>
          <AccountDetails />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Profile;
