import React from "react";
import PropTypes from "prop-types";
import StockDetails from "./stockDetails";
import { Message, List } from "semantic-ui-react";
import "./stockReviewList.css";

const StockReviewList = ({
  allStock = [],
  openAddLocation,
  updateStock,
  openChangeModal,
  goToAudits
}) => {
  
  return (
    <React.Fragment>
      {allStock.length > 0 ? (
        <List celled>
          {allStock.map(stock => {
            const uniqueKey = stock.stockCode + stock.location;
            return (
              <List.Item key={uniqueKey} className="stock-review-list">
                <StockDetails
                  stock={stock}
                  updateStock={updateStock}
                  openChangeModal={openChangeModal}
                  goToAudits={goToAudits}
                />
              </List.Item>
            );
          })}
        </List>
      ) : (
        <Message
          info
          header="No records found in the selected location."
          content={<div />}
        />
      )}
    </React.Fragment>
  );
};

StockReviewList.propTypes = {
  allStock: PropTypes.array,
  openAddLocation: PropTypes.func,
  updateStock: PropTypes.func,
  openChangeModal: PropTypes.func,
  goToAudits: PropTypes.func
};

export default StockReviewList;
