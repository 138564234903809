import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import Loader from "../../components/Loader";
import "./reviewMaintenance.css";
import _ from "lodash";
import polyglot from "../../i18n";
import * as reviewMaintenanceReducer from "./reviewMaintenance.reducer";
import {
  Form,
  Grid,
  Button,
  Divider,
  Icon,
  Segment,
  List,
  Radio,
  Image
} from "semantic-ui-react";
import moment from "moment";

const answerOptions = ["red", "orange", "green"];
class ReviewMaintenance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isNote: false,
      currentPoint: {},
      noteInput: "",
      isError: false,
      errorMessage: ""
    };
  }
  componentDidMount = () => {
    this.props.actionResetForm("maintenanceForm");
    this.getUrlParam();
  };

  getUrlParam = async () => {
    const id = this.props.match.params.id;
    if (id) {
      await this.props.actionGetMaintenanceRecord(id);
      const { maintenanceRecord } = this.props;
      const { formData } = maintenanceRecord;

      await this.props.actionSetForm(formData);
      this.props.actionSetFormFields("_id", id);
    }
  };

  goToPage = () => {
    this.props.history.push("/schedule");
  };

  render() {
    const { isLoading } = this.state;
    const { maintenanceForm, maintenanceRecord } = this.props;

    const { data, maintenanceUnit, imageUrl, note } = maintenanceForm;
    const sectioned = _.groupBy(data, "section");
    const {
      assetName,
      assignedToName,
      formName,
      updatedAt
    } = maintenanceRecord;

    const title = `Maintenance: ${assetName} (${formName})`;
    const date = moment(updatedAt).format("ddd, ll");
    const assignedTo = `Completed on: ${date} by ${assignedToName}`;

    const CheckPointList = (
      <div>
        {sectioned &&
          _.map(sectioned, (point, c_key) => {
            return (
              <Segment key={c_key}>
                <h4>{c_key}</h4>
                <List key={c_key} divided verticalAlign="middle">
                  {_.map(point, item => {
                    const { field, result, note, imageUrl } = item;
                    return (
                      <List.Item key={field}>
                        <Grid>
                          <Grid.Column
                            width={4}
                            computer={4}
                            mobile={16}
                            verticalAlign="middle"
                            className="point-header"
                          >
                            {field}
                          </Grid.Column>
                          <Grid.Column
                            width={7}
                            computer={7}
                            className="check-result"
                            textAlign="center"
                            verticalAlign="middle"
                          >
                            {_.map(answerOptions, (option, o_key) => {
                              return (
                                <Radio
                                  key={o_key}
                                  label={option}
                                  disabled={true}
                                  checked={result === option}
                                  value={option}
                                  className={option}
                                />
                              );
                            })}
                          </Grid.Column>

                          <Grid width={16}>
                            {note && (
                              <Grid.Column
                                width={8}
                                mobile={16}
                                computer={8}
                                tablet={8}
                              >
                                <Form.Field style={{ display: "inline-flex" }}>
                                  <label>Note: &nbsp;</label>
                                  {note}
                                </Form.Field>
                              </Grid.Column>
                            )}

                            {imageUrl && imageUrl.length > 0 ? (
                              <Grid.Column width={8} computer={8}>
                                <b>Images:</b>
                                <Image.Group
                                  size="mini"
                                  className="issue-images"
                                >
                                  {_.map(imageUrl, image => {
                                    return (
                                      <a target="_blank" href={image.link}>
                                        <Image
                                          style={{
                                            width: "100px",
                                            height: "100px"
                                          }}
                                          inline
                                          src={image.link}
                                        />{" "}
                                      </a>
                                    );
                                  })}
                                </Image.Group>
                              </Grid.Column>
                            ) : null}
                          </Grid>
                        </Grid>
                      </List.Item>
                    );
                  })}
                </List>
              </Segment>
            );
          })}
      </div>
    );

    const showParview = (
      <Grid Columns={3}>
        <Grid.Row>
          {note && (
            <Grid.Column width={8} mobile={16} computer={8} tablet={8}>
              <Form.Field style={{ display: "inline-flex" }}>
                <label>Note: &nbsp;</label>
                {note}
              </Form.Field>
            </Grid.Column>
          )}
          {imageUrl && imageUrl.length > 0 ? (
            <Grid.Column width={8} computer={8}>
              <b>Images:</b>
              <Image.Group size="mini" className="issue-images">
                {_.map(imageUrl, image => {
                  return (
                    <a target="_blank" href={image.link}>
                      <Image
                        style={{ width: "100px", height: "100px" }}
                        inline
                        src={image.link}
                      />{" "}
                    </a>
                  );
                })}
              </Image.Group>
            </Grid.Column>
          ) : null}
        </Grid.Row>
      </Grid>
    );

    return (
      <div className="review-maintenance-form">
        <MetaData title={title} />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading,please wait</Loader>
          )}

          <Grid.Column width={16} style={{ paddingBottom: "0px" }}>
            <Grid>
              <Grid.Column width={8}>
                <h3>
                  <Icon name="clipboard" />
                  {title}
                </h3>
                <p>{assignedTo}</p>
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <Button
                  size="tiny"
                  onClick={() => this.goToPage()}
                  icon="left arrow"
                  content="Go back"
                  labelPosition="left"
                  className="button-float"
                />
              </Grid.Column>
            </Grid>
            <Divider />
          </Grid.Column>
          <Grid.Column
            width={16}
            style={{ paddingBottom: "0px" }}
            className="maintenance-component"
          >
            <Form
              className="maintenance-form-box"
              model="form.maintenanceForm"
              onSubmit={this.updateMaintenanceRecord}
            >
              <Grid>
                <Grid.Column width={16} computer={8} mobile={16}>
                  <Form.Field>
                    <label>
                      {polyglot.t("maintenanceForm.label.Kilometers")}
                    </label>
                    {maintenanceUnit}
                  </Form.Field>
                </Grid.Column>

                <Grid.Column width={16} computer={16} mobile={16}>
                  <Segment className="check-points">
                    <h4 className="header"> Check Points</h4>
                    <Grid className="form-panel">
                      <Grid.Column width={8} mobile={16} tablet={16}>
                        {CheckPointList}
                      </Grid.Column>
                      <Grid.Column width={16} mobile={16} tablet={8}>
                        {showParview}
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Grid.Column>
              </Grid>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceRecord: state.reviewMaintenance.maintenanceRecord,
    maintenanceForm: state.forms.maintenanceForm,
    response: state.reviewMaintenance.response
  };
};

const reducer = { ...reviewMaintenanceReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ReviewMaintenance)
);
