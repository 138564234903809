import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Grid,
  Divider,
  Icon,
  Image,
  Button,
  Card,
  Pagination,
  Message,
  Modal,
  Header
} from "semantic-ui-react";
import productDefaultImage from "../../assets/images/product_default.png";
import moment from "moment";
import * as myHosesReducer from "./myHoses.reducer";
import Loader from "../../components/Loader";
import * as cartReducer from "../Cart/cart.reducer";
import { showSuccessToast } from "../../utils/toastUtils";
import "./myHoses.css";

class MyHoses extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      activePage: 1,
      imageModal: false,
      imageLink: ""
    };
  }

  componentDidMount() {
    this.getAllHoses();
  }

  getAllHoses = async () => {
    this.updateState({ isLoading: true });
    await this.props.actionGetCustomerHoses(0);
    this.updateState({ isLoading: false });
  };

  updateState = state => {
    this.setState(state);
  };

  addToCartHandler = async item => {
    const { stockCode, stockDescription, length = null, pack, _id } = item;
    const hoseData = {
      stockCode: stockCode,
      quantity: 1,
      length,
      pack,
      stockDescription: stockDescription,
      hose: true,
      hoseId: _id
    };
    await this.props.actionAddHoseToCart(hoseData);
    showSuccessToast("Hose added to cart");
  };

  handlePaginationChange = async (...params) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;
    const { hoses } = this.props;
    const { totalPages } = hoses;
    if (totalPages === 1) {
      return;
    }
    this.setState({
      activePage
    });
    await this.props.actionGetCustomerHoses(activePage - 1);
  };

  goToHoseMaker = e => {
    e.preventDefault();
    this.props.history.push("/hoseMaker");
  };

  openImageModal = imageLink => {
    this.setState({ imageModal: true, imageLink: imageLink });
  };

  handleClose = () => {
    this.setState({ imageModal: false, imageLink: "" });
  };

  render() {
    const { hoses } = this.props;
    const { result: allHoses = [], totalPages } = hoses;
    const { isLoading, activePage, imageLink, imageModal } = this.state;

    const hoseDetails = (items = []) => {
      return (
        <div>
          {items.length > 0 &&
            items.map((item, i_key) => {
              const {
                stockCode,
                stockDescription,
                updatedAt,
                additionalProducts,
                imageLink
              } = item;
              const lastBought = (
                <span>
                  <Icon name="wait" />
                  {"Created at: " +
                    moment(updatedAt)
                      .subtract("days")
                      .calendar()}
                </span>
              );

              return (
                <Card key={i_key} fluid className="my-favorite-card">
                  <Card.Content>
                    <Card.Header>{stockCode}</Card.Header>
                    <Card.Description>
                      <Grid columns="equal">
                        <Grid.Column
                          computer={6}
                          mobile={8}
                          tablet={6}
                          className="no-padding"
                        >
                          <Image
                            style={{ width: '400px', height: '200px' }}
                            src={
                              imageLink ? imageLink : productDefaultImage
                            }
                            alt="product"
                            className="cart-product-image"
                            onClick={() => this.openImageModal(imageLink ? imageLink : productDefaultImage)}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={6}
                          mobile={8}
                          tablet={6}
                          style={{ margin: "auto" }}
                          className="no-right-padded"
                        >
                          <b>Stock description:</b>
                          {stockDescription}
                          {additionalProducts.length > 0 && (
                            <div>
                              <b>Additional Products :</b>{" "}
                              {additionalProducts.map((item, key) => {
                                const { stockCode } = item;
                                if (key === 0) {
                                  return stockCode;
                                } else {
                                  return "," + stockCode;
                                }
                              })}
                            </div>
                          )}
                          <div className="last-bought">{lastBought}</div>
                        </Grid.Column>
                        <Grid.Column
                          computer={4}
                          mobile={16}
                          tablet={4}
                          style={{ margin: "auto" }}
                          centered
                        >
                          <a onClick={() => this.addToCartHandler(item)}>
                            <Button
                              fluid
                              animated="vertical"
                              size="small"
                              type="submit"
                              color="black"
                              className="add-to-cart"
                            >
                              <Button.Content hidden>
                                <Icon name="cart" />
                              </Button.Content>
                              <Button.Content visible>
                                Add to cart
                              </Button.Content>
                            </Button>
                          </a>
                        </Grid.Column>
                      </Grid>
                    </Card.Description>
                  </Card.Content>
                </Card>
              );
            })}
        </div>
      );
    };

    const modalJSX = (
      <Modal open={imageModal} onClose={this.handleClose} basic size="large">
        <Header icon="browser" content="Hose image" />
        <Modal.Content>
          <Image src={imageLink} />
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.handleClose} inverted>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );

    return (
      <React.Fragment>
        <Grid className="my-hoses">
          <Grid.Column width={16}>
            <h3>
              <Icon name="unordered list" />
              My hoses
              <Divider />
            </h3>
            {allHoses.length === 0 && (
              <Message>
                <Message.Header>No hoses found</Message.Header>
                <Message.Content>
                  {" "}
                  <a onClick={this.goToHoseMaker}>
                    {" "}
                    Click here to create a new hose{" "}
                  </a>{" "}
                </Message.Content>
              </Message>
            )}
            {hoseDetails(allHoses)}
            <div>
              {allHoses.length ? (
                <Pagination
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={this.handlePaginationChange}
                  totalPages={totalPages}
                  firstItem={null}
                  lastItem={null}
                />
              ) : null}
            </div>
          </Grid.Column>
        </Grid>
        {isLoading && <Loader text="Loading saved hoses, please wait" />}
        {modalJSX}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    hoses: state.myHoses.hoses
  };
};
const reducer = { ...myHosesReducer, ...cartReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyHoses);
