import React, { useState, useEffect } from "react";
import { HISfetchData } from "./hisAPIprovider";
import { toast } from "react-toastify";
import UserCard from "./userCard";
import "./userCard.css";
import NewUserForm from "./newUserForm";

const HISusers = () => {
  const [users, setUsers] = useState([]);
  const [addNewUser, setAddNewUser] = useState(false);

  const getUsers = async () => {
    const response = await HISfetchData("/his/users");
    if (!response.ok) {
      toast.error("Error getting users");
      return;
    }
    const result = await response.json();
    setUsers(result.data);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const newUserClick = () => {
    setAddNewUser(true);
  };

  const onCloseForm = () => {
    setAddNewUser(false);
    getUsers();
  };

  const deleteUser = (userId) => {
    setUsers(users.filter((user) => user.user_id !== userId));
  };

  return (
    <div>
      <button className="new_user_btn" onClick={newUserClick}>
        Add New User
      </button>
      {users.length !== 0 &&
        users.map((user) => (
          <UserCard key={user.user_id} user={user} onDelete={deleteUser} />
        ))}
      {addNewUser && <NewUserForm closeForm={onCloseForm} />}
    </div>
  );
};

export default HISusers;
