export const login = {
  email: "",
  password: "",
  platform: "web",
};

export const createContact = {
  id: "",
  name: "",
  email: "",
  street: "",
  street2: "",
  city: "",
  state: "",
  zip: "",
  country: "",
  mobile: "",
  phone: "",
};

export const createAccount = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  mobileNumber: "",
  officePhoneNumber: "",
  company: "",
  industry: "",
  country: "",
  location: "",
  userRole: "",
  customerType: "enterprise",
  typeOfBusiness: "",
  captchaToken: "",
};

export const forgotPassword = {
  email: "",
};

export const resetPassword = {
  email: "",
  password: "",
  confirmPassword: "",
};

export const profileInfo = {
  email: "",
  firstName: "",
  lastName: "",
  mobileNumber: "",
  officePhoneNumber: "",
};

export const changePassword = {
  password: "",
  confirmPassword: "",
};
