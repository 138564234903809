import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Form, Button, Message, Icon } from "semantic-ui-react";
import MetaData from "../../components/MetaData";
import CafInformationForm from "../../components/CafInformationForm";
import CafDepartmentForm from "../../components/CafDepartmentForm";
import CafReferenceForm from "../../components/CafReferencesForm";
import * as cafReducer from "../CafForm/caf.reducer";
import Loader from "../../components/Loader";
import "./caf.css";
import { showErrorToast } from "../../utils/toastUtils";

class CafForm extends React.Component {
  constructor() {
    super();
    this.state = {
      errorFlag: false,
      errorMessage: "",
      loading: false,
      submitted: false
    };
  }


  isFormValid = () => {
    const {
      phone,
    } = this.props.forms.cafForm;
    if (!phone.length) {
      this.updateState({ errorFlag: true, errorMessage: "Phone is required" });
      return false;
    }

    return true;
  };

  submitCafFormHandler = async () => {
    const { cafForm } = this.props.forms;
    if (this.isFormValid()) {
      this.updateState({
        errorFlag: false,
        errorMessage: "",
        loading: true
      });

      await this.props.actionSubmitCafForm(cafForm);

      this.updateState({ loading: false });
      const { response } = this.props.cafForm;
      const { status } = response;
      if (status !== 200) {
        showErrorToast("Something went wrong , please try again");
        return;
      }
      this.updateState({ submitted: true });
    }
  };

  updateState = state => {
    this.setState(state);
  };

  goBack = () => {
    this.props.history.push("/profile");
  };

  render() {
    const { errorFlag, errorMessage, loading, submitted } = this.state;
    return (
      <React.Fragment>
        <MetaData title="CAF" />
        <Grid className="caf-container">
          {loading && <Loader text="Submitting data ..." />}
          {!submitted && (
            <Form
              error={errorFlag}
              model="form.profileInfo"
              onSubmit={this.submitCafFormHandler}
              className="cafForm-box"
            >
              <CafInformationForm />
              <CafDepartmentForm />
              <CafReferenceForm />

              <Message error={true} content={errorMessage} />
              <Grid.Column>
                {!loading && (
                  <Button
                    secondary
                    size="small"
                    className="button-float"
                    onSubmit={this.submitCafFormHandler}
                  >
                    Submit
                  </Button>
                )}
                {loading && (
                  <Button
                    secondary
                    size="small"
                    className="button-float"
                    loading
                  >
                    {" "}
                    Submit{" "}
                  </Button>
                )}
                <Button
                  size="small"
                  onClick={this.goBack}
                  icon
                  labelPosition="left"
                  className="button-float"
                >
                  <Icon name="left arrow" />
                  Go back
                </Button>
              </Grid.Column>
            </Form>
          )}
          {submitted && (
            <Grid.Column width={16}>
              {" "}
              <div className="success-message">
                Your application is successfully submitted.
                <div>
                  <Button
                    size="small"
                    onClick={this.goBack}
                    icon
                    labelPosition="left"
                  >
                    <Icon name="left arrow" />
                    Go back
                  </Button>
                </div>
              </div>{" "}
            </Grid.Column>
          )}
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    forms: state.forms,
    cafForm: state.cafForm
  };
};
const mapDispatchToProps = dispatch => bindActionCreators(cafReducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CafForm);
