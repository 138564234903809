import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Grid,
  Divider,
  Icon,
  Image,
  Button,
  Card,
  Pagination,
  Message,
  Modal,
  Header,
  Dropdown,
  List,
  Form
} from "semantic-ui-react";
import moment from "moment";
import * as hoseMechanicReducer from "./hoseMechanic.reducer";
import productDefaultImage from "../../assets/images/product_default.png";
import Loader from "../../components/Loader";
import { showSuccessToast } from "../../utils/toastUtils";
import { HOSE_ID_FOR_URL_PARAM } from "../../constants/appConstants";
import "./hoseMechanic.css";
import * as inventoryLocationsReducer from "../InventoryLocation/inventoryLocation.reducer";
import _ from "lodash";
class HoseMechanic extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      activePage: 1,
      isModalOpen: false,
      selectedLocation: null,
      hoseId: null,
      imageModal: false,
      imageLink: ""
    };
  }

  componentDidMount() {
    this.getAllHoses();
  }

  goToPageBack = path => {
    this.props.history.goBack();
  };


  getAllHoses = async () => {
    this.updateState({ isLoading: true });
    await this.props.actionGetCustomerHoses(0);
    await this.props.actionGetAllLocationToDropdown();
    this.updateState({ isLoading: false });
  };

  updateState = state => {
    this.setState(state);
  };

  handlePaginationChange = async (...params) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;
    const { hoses } = this.props;
    const { totalPages } = hoses;
    if (totalPages === 1) {
      return;
    }
    this.setState({
      activePage
    });
    await this.props.actionGetCustomerHoses(activePage - 1);
  };

  goToHoseMaker = (e, id) => {
    e.preventDefault();
    if (id) {
      this.props.history.push({
        pathname: "/hoseMaker",
        search: `${HOSE_ID_FOR_URL_PARAM}=${id}`
      });
    }
  };
  redirectToHoseMaker = () => {
    this.props.history.push("/hoseMaker");
  }

  addToInventoryHandler = (hoseId) => {
    this.setState({ isModalOpen: true, hoseId: hoseId });
  };

  handleClose = () => {
    this.setState({
      isModalOpen: false,
      hoseId: null,
      locations: null
    });
    this.props.actionClearReducerItem();
  };

  locationSelectHandler = async (e, data) => {
    e.preventDefault();
    this.props.actionClearReducerItem();
    const { value } = data;
    const { hoseId } = this.state;
    await this.props.actionCheckHoseItemsInInventory(hoseId, value);
    this.setState({ selectedLocation: value });
  };

  addToInventory = async () => {
    const { hoseId, selectedLocation } = this.state;
    this.setState({ isLoading: true });
    const result = await this.props.actionAddHoseItemsInInventory(
      hoseId,
      selectedLocation
    );
    const { status } = result;
    if (status === 200) {
      this.setState({ isModalOpen: false, isLoading: false });
      showSuccessToast("Hose added in to inventory ");
      this.getAllHoses();
    }
  };

  openImageModal = imageLink => {
    this.setState({ imageModal: true, imageLink: imageLink });
  };

  handleClose = () => {
    this.setState({ imageModal: false, imageLink: "" });
  };

  render() {
    const { hoses, locations, itemsNotAvailable = [] } = this.props;
    const { result: allHoses = [], totalPages } = hoses;

    const {
      isLoading,
      activePage,
      isModalOpen,
      imageLink, imageModal
    } = this.state;
    let allLocations = [];
    if (locations.length) {
      _.forEach(locations, (item, key) => {
        const { name } = item;
        allLocations.push({ key, text: name, value: name });
      });
    }

    const hoseDetails = (items = []) => {
      return (
        <div>
          {items.length > 0 &&
            items.map((item, i_key) => {
              const {
                imageLink,
                stockCode,
                stockDescription,
                updatedAt,
                additionalProducts,
                _id
              } = item;
              const lastBought = (
                <span>
                  <Icon name="wait" />
                  {"Created at: " +
                    moment(updatedAt)
                      .subtract("days")
                      .calendar()}
                </span>
              );

              return (
                <Card key={i_key} fluid className="my-favorite-card">
                  <Card.Content>
                    <Card.Header>{stockCode}</Card.Header>
                    <Card.Description>
                      <Grid columns="equal">
                        <Grid.Column
                          computer={2}
                          mobile={4}
                          tablet={2}
                          className="no-padding"
                        >
                          <Image
                            src={
                              imageLink ? imageLink : productDefaultImage
                            }
                            alt="product"
                            className="cart-product-image"
                            onClick={() => this.openImageModal(imageLink ? imageLink : productDefaultImage)}
                          />
                        </Grid.Column>
                        <Grid.Column
                          computer={10}
                          mobile={11}
                          tablet={10}
                          style={{ margin: "auto" }}
                          className="no-right-padded"
                        >
                          <b>Stock description:</b>
                          {stockDescription}
                          {additionalProducts.length > 0 && (
                            <div>
                              <b>Additional Products :</b>{" "}
                              {additionalProducts.map((item, key) => {
                                const { stockCode } = item;
                                if (key === 0) {
                                  return stockCode;
                                } else {
                                  return "," + stockCode;
                                }
                              })}
                            </div>
                          )}
                          <div className="last-bought">{lastBought}</div>
                        </Grid.Column>
                        <Grid.Column
                          computer={4}
                          mobile={16}
                          tablet={4}
                          style={{ margin: "auto" }}
                          centered
                        >
                          <Button
                            fluid
                            size="small"
                            color="black"
                            className="add-to-cart"
                            onClick={e => this.goToHoseMaker(e, _id)}
                          >
                            <Button.Content visible>
                              Inspect hose
                            </Button.Content>
                          </Button>
                          <br />
                          {/* <Button
                            fluid
                            size="small"
                            color="black"
                            loading={isLoading}
                            className="add-to-cart"
                            disabled={addedToInventory}
                            onClick={e => this.addToInventoryHandler(_id)}
                          >
                            <Button.Content visible>
                              Add to Inventory
                            </Button.Content>
                          </Button> */}
                        </Grid.Column>
                      </Grid>
                    </Card.Description>
                  </Card.Content>
                </Card>
              );
            })}
        </div>
      );
    };

    const addToInventoryModalJSX = (
      <Modal open={isModalOpen} onClose={this.handleClose} size="small">
        <Header>Add hose to inventory</Header>
        <Modal.Content>
          <Grid>
            <Grid.Column width={16}>
              {isLoading && <Loader text="Loading saved hoses, please wait" />}
              <Form>
                <Form.Field>
                  <label>Select location </label>
                  <Dropdown
                    placeholder="Location"
                    selection
                    options={allLocations}
                    onChange={this.locationSelectHandler}
                  />
                  {_.isEmpty(allLocations) ? (<span style={{ color: "red" }}>
                    No locations available, Create a new one!</span>)
                    : null}
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column width={16}>
              {itemsNotAvailable.length > 1 && (
                <div>
                  <h4>Hose items not available in inventory</h4>
                  <Divider />
                  <List divided verticalAlign="middle">
                    {itemsNotAvailable.map(hoseItem => {
                      return (
                        <List.Item>
                          <List.Content>{hoseItem}</List.Content>
                        </List.Item>
                      );
                    })}
                  </List>
                </div>
              )}
            </Grid.Column>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            loading={isLoading}
            onClick={this.addToInventory}
          >
            <Icon name="checkmark" />
            {itemsNotAvailable.length ? "Add it to inventory anyway " : "Add to inventory"}
          </Button>
          <Button secondary onClick={this.handleClose}>
            <Icon name="cancel" /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );

    const modalJSX = (
      <Modal open={imageModal} onClose={this.handleClose} basic size="large">
        <Header icon="browser" content="Hose image" />
        <Modal.Content>
          <Image src={imageLink} />
        </Modal.Content>
        <Modal.Actions>
          <Button color="green" onClick={this.handleClose} inverted>
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );

    return (
      <React.Fragment>
        <Grid className="my-hoses">
          <Grid.Row>
            <Grid.Column width={16}>
              <Grid className="my-location-header">
                <Grid.Column tablet={10} computer={10} mobile={16}>
                  <h3>
                    <Icon name="unordered list" />
              Hose Mechanic

            </h3>
                  {/*             
            {allHoses.length === 0 && (
              <Message info>
                <Message.Header>No hoses found</Message.Header>
                <Message.Content >
                  {" "}
                  <a onClick={this.redirectToHoseMaker} className="cart-go-search-link">
                    {" "}
                    Click here to create a new hose{" "}
                  </a>{" "}
                </Message.Content>
              </Message>
            )}
            {hoseDetails(allHoses)}
            <div>
              {allHoses.length ? (
                <Pagination
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={this.handlePaginationChange}
                  totalPages={totalPages}
                  firstItem={null}
                  lastItem={null}
                />
              ) : null}
            </div> */}
                </Grid.Column>
                <Grid.Column
                  textAlign="right"
                  width={6}
                  mobile={16}
                  computer={6}
                >
                  <Button
                    size="tiny"
                    onClick={this.goToPageBack}
                    icon="left arrow"
                    content="Go back"
                    labelPosition="left"
                    className="button-float"
                  />
                </Grid.Column>
              </Grid>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Column
            width={16}
            textAlign="center"
            className="no-top-padded"
          //style={{ paddingBottom: "0px" }}
          >

            <h3 >Coming soon</h3>
          </Grid.Column>

        </Grid>
        {/* {addToInventoryModalJSX}
        {isLoading && <Loader text="Loading saved hoses, please wait" />}
        {modalJSX} */}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    hoses: state.myHoses.hoses,
    locations: state.inventoryLocation.locations,
    itemsNotAvailable: state.hoseMechanic.itemsNotAvailable
  };
};
const reducer = { ...hoseMechanicReducer, ...inventoryLocationsReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HoseMechanic);
