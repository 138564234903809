import axios from 'axios';
import { CHAT_GROUP_LIST, CREATE_CHAT_GROUP, CHAT_USER_LIST, GET_ACCESS_TOKEN,GET_REFRESHED_TOKEN,DELETE_CHAT,GET_CONVERSATION_DETAILS ,UPDATE_CHAT, CHAT_USER_DETAILS,ADD_NEW_MEMBERS,REMOVE_MEMBERS,UPDATE_PARTICIPANTS,EXIT_GROUP} from '../../constants/apiLink';

export const apiGetAccessToken = async () => {
    // const url = GET_ACCESS_TOKEN + `?identity=${email}`;
    const url = GET_ACCESS_TOKEN;
    return axios.get(url).then(result => {
        return result.data
    })
}

export const apiGetConversationDetails = async id => {
    // const url = GET_ACCESS_TOKEN + "/" + id;
    const url = GET_CONVERSATION_DETAILS + "/" + id;
    return axios.get(url).then(result => {
        return result.data
    })
}


export const apiGetRefreshedToken = async () => {
    // const url = GET_ACCESS_TOKEN + `?identity=${email}`;
    const url = GET_REFRESHED_TOKEN + `?platform=web `;
    return axios.get(url).then(result => {
        return result.data
    })
}

export const apiGetConversationsList = async () => {
    //const token = await apiGetAccessToken();
    //console.log("New Token is :" + JSON.stringify(token) )
    //const token = window.localStorage.getItem("chatAccessToken")
    // const data = {
       
    //     accessToken: token
    // }
    
    return axios.get(CHAT_GROUP_LIST).then(result => {
        return result;
    })
}


export const apiGetChatUserList = async () => {
 
    return axios.get(CHAT_USER_LIST,
    //      {
    //     Authorization: `Bearer ${token}`
    // }
    ).then(result => {
        return result;
    })
}





export const apiGetChatUserDetails = async id => {

    const url = CHAT_USER_DETAILS + "/" + id;

    return axios.get(url).then(result => {
        return result;
    })
}


export const apiUpdateParticipants = async data => {
    const url = UPDATE_PARTICIPANTS;
    return axios.post(url, data).then(result => {
        return result;
    });
};
export const apiAddParticipants = async data => {
    const url = ADD_NEW_MEMBERS;
    return axios.post(url, data).then(result => {
        return result;
    });
};

export const apiRemoveParticipants = async data => {
    const url = REMOVE_MEMBERS;
    return axios.post(url, data).then(result => {
        return result;
    });
};




export const apiCreateChatGroup = async data => {
    const url = CREATE_CHAT_GROUP;
    return axios.post(url, data).then(result => {
        return result;
    });
};

//   export const apiDeselectUser = async id => {
//     const url = ASSETS + "id=" + id;

//     return axios.delete(url).then(result => {
//       return result;
//     });
//   };


export const apiUpdateChat = async data => {
    
    const url = UPDATE_CHAT;
    return axios.post(url, data).then(result => {
      return result;
    });
  };

export const apiDeleteChat = async chatId => {
    const url = DELETE_CHAT ;
   
    //const url = DELETE_CHAT + "/" + chatId;
    return axios.post(url, chatId).then(result => {
      return result;
    });
  };
export const apiExitChatgroup = async chatId => {
    const url = EXIT_GROUP ;

   
    return axios.post(url, chatId).then(result => {
      return result;
    });
  };
  

  //==================Search Result======================


 

  export const apiAutoComplete = async (searchTerm) => {
    let url = CHAT_USER_LIST + `?search=${searchTerm}`;
    // if (level) {
    //   level = _.replace(level, "&", "$and$");
    //   url = `${url}&level=${level}`;
    // }
  
    return await axios.get(url);
  };
  
  export const apiSearch = async (
    searchTerm,
  
    limit = 10
  ) => {
    let url = CHAT_USER_LIST + `?search=${searchTerm}`;
    // let filter = ``;
    // if (level) {
    //   level = _.replace(level, "&", "$and$");
    //   url = `${url}&level=${level}`;
    // }
    // if (activePage) {
    //   url = `${url}&activePage=${activePage}`;
    // }
    // if (threadType1) {
    //   filter = `&threadType1=${threadType1}`;
    // }
    // if (threadSize1) {
    //   filter = `${filter}&threadSize1=${threadSize1}`;
    // }
    // if (threadType2) {
    //   filter = `${filter}&threadType2=${threadType2}`;
    // }
    // if (threadSize2) {
    //   filter = `${filter}&threadSize2=${threadSize2}`;
    // }
    url = `${url}&limit=${limit}`;
  
    return await axios.get(url);
  };



  export const apiAutoCompleteConversation = async searchTerm => {
    let url = CHAT_GROUP_LIST + `?search=${searchTerm}`;
    // if (level) {
    //   level = _.replace(level, "&", "$and$");
    //   url = `${url}&level=${level}`;
    // }
  
    return await axios.get(url);
  };
  
  export const apiSearchConversation = async (
    searchTerm,
  
  ) => {
    let url = CHAT_GROUP_LIST + `?search=${searchTerm}`;
  
   // url = `${url}&limit=${limit}`;
  
    return await axios.get(url);
  };
