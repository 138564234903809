import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Grid,
  Button,
  Form,
  Divider,
  Icon,
  Modal,
  Header,
  Image,
  Input,
  Message
} from "semantic-ui-react";
import * as hoseMakerReducer from "./../hoseMaker.reducer";
import * as hoseMechanicReducer from "../../HoseMechanic/hoseMechanic.reducer";
import * as hoseUtil from "./../hoseMaker.util";
import HoseType from "./HoseType";
import FittingLeft from "./FittingLeft";
import FittingRight from "./FittingRight";
import AngleSelector from "./AngleInput";
import SelectedHoseInfo from "./SelectedHoseInfo";
import HoseLength from "./HoseLength";
import SpiralGuard from "./SpiralGuard";
import Loader from "./Loader";
import AdditionalProducts from "./AdditionalHoseProducts";
import HoseImages from "./HoseImages";
import Gauge from "react-radial-gauge";
import HoseConfirmModal from "./HoseConfirmModal";
import {
  TBA,
  FITTING_TERTIARY_GROUP_DISPLAY_NAME_FOR_FERRULE_CONDITION
} from "./hoseConstants";
import _ from "lodash";
import { showErrorToast } from "../../../utils/toastUtils";
import NumericInputBox from "../../../components/NumericInputBox";
import AngleHelpModal from "./AngleHelpModal";
import FerruleImage from "./FerruleImage";
import { apiGetProductDetails } from "../hoseMaker.api";
import AddToInventoryModalJSX from "./AddToInventoryCheck";
import SendToInventoryOrAssetModalJSX from "./SendToInventoryOrAssetModal/index";
import * as locationReducer from "../../InventoryLocation/inventoryLocation.reducer";

const gaugeOptions = {
  size: "150",
  dialColor: "#E2F5BC",
  progressColor: "#95C92C",
  needleBaseSize: "8",
  needleWidth: "8",
  needleSharp: "true"
};

class HoseMakerContainer extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      showModal: false,
      iAgree: false,
      closeOnDimmerClick: false,
      closeOnEscape: false,
      sendToMechanicConfirmModal: false,
      addToInventoryModal: false,
      sendToInventoryOrAssetModal: false,
      updateHoseLoader: false,
      inventoryLoc: false,
      saveHoseRecipe: false
    };
  }

  updateState = state => {
    this.setState(state);
  };

  componentWillMount = () => {
    this.updateState({ isLoading: true });
    // this.props.actionSetHoseCategories();
    this.props.actionGetAllLocation();
    this.updateState({ isLoading: false });
    const { allLocations } = this.props;
    const { locations } = allLocations;
    if (_.isEmpty(locations)) {
      this.setState({ inventoryLoc: true });
    }
  };

  componentWillUnMount = () => {
    this.props.actionSetFlowForHoseMaker("");
  };

  boarChangeHandler = value => {
    this.props.setHoseType(value);
  };

  leftFittingHandler = async value => {
    const { hoseType } = this.props;
    const { productDimensions, productFamily, stockCode } = hoseType;
    const { dashSize1 } = productDimensions;
    const { tertiaryGrp } = value;
    this.updateState({ isLoading: true });
    const { stockCode: fittingStockCode } = value;
    const result = await apiGetProductDetails(fittingStockCode);
    const { data: fittingData } = result;
    const {
      pricingPolicy,
      productDimensions: fittingProductDimensions
    } = fittingData;
    const { price } = pricingPolicy;
    const { threadType1 } = fittingProductDimensions;
    const seatTypeImage = hoseUtil.getSeatTypeImage(threadType1);
    this.props.actionSetSeatTypeImage("left", seatTypeImage);
    if (!price) {
      showErrorToast(
        "Cant add this fitting, please contact boa for this fitting"
      );
      return;
    }
    await this.props.setLeftFittingTypes(fittingData);
    const { leftFittingTypes } = this.props;
    const { tertiaryGrpDisplayName } = leftFittingTypes;
    if (
      tertiaryGrpDisplayName ===
      FITTING_TERTIARY_GROUP_DISPLAY_NAME_FOR_FERRULE_CONDITION
    ) {
      await this.props.actionGetFerruleLeft(stockCode);
    }
    const { leftFerrule = {} } = this.props;
    const { productFamily: ferruleProductFamily = "" } = leftFerrule;
    await this.props.actionSetCrimpSize(
      "left",
      dashSize1,
      productFamily,
      tertiaryGrp,
      ferruleProductFamily
    );
    this.updateState({ isLoading: false, isLcNull: false });
  };

  rightFittingHandler = async value => {
    const { hoseType } = this.props;
    const { productDimensions, productFamily, stockCode } = hoseType;
    const { dashSize1 } = productDimensions;
    const { tertiaryGrp, stockCode: fittingStockCode } = value;
    this.updateState({ isLoading: true });
    const result = await apiGetProductDetails(fittingStockCode);
    const { data: fittingData } = result;
    const {
      pricingPolicy,
      productDimensions: fittingProductDimensions
    } = fittingData;
    const { threadType1 } = fittingProductDimensions;
    const seatTypeImage = hoseUtil.getSeatTypeImage(threadType1);
    this.props.actionSetSeatTypeImage("right", seatTypeImage);
    const { price } = pricingPolicy;
    if (!price) {
      showErrorToast(
        "Cant add this fitting, please contact boa for this fitting"
      );
      return;
    }
    await this.props.setRightFittingTypes(fittingData);
    const { rightFittingTypes } = this.props;
    const { tertiaryGrpDisplayName } = rightFittingTypes;
    if (
      tertiaryGrpDisplayName ===
      FITTING_TERTIARY_GROUP_DISPLAY_NAME_FOR_FERRULE_CONDITION
    ) {
      await this.props.actionGetFerruleRight(stockCode);
    }
    const { rightFerrule = {} } = this.props;
    const { productFamily: ferruleProductFamily = "" } = rightFerrule;
    await this.props.actionSetCrimpSize(
      "right",
      dashSize1,
      productFamily,
      tertiaryGrp,
      ferruleProductFamily
    );
    this.updateState({ isLoading: false });
  };

  scrollToSearch = () => {
    window.scrollTo(0, 600);
  };

  removeFittings = type => {
    if (type === "right") {
      this.props.actionClearRightFitting();
    }
    if (type === "left") {
      this.props.actionClearLeftFitting();
    }
  };

  clearHoseType = () => {
    this.props.actionResetHose();
    window.scrollTo(0, 10);
  };

  //
  lengthChangeHandler = value => {
    const { leftFittingTypes, rightFittingTypes, showOal } = this.props;
    if (!_.isEmpty(leftFittingTypes) && !_.isEmpty(rightFittingTypes)) {
      let fixedValue = value;
      if (fixedValue !== null) {
        fixedValue = Number(value.toFixed(2));
      }
      if (showOal) {
        this.props.actionChangeHoseLength(fixedValue);
      } else {
        this.props.actionChangeHoseLengthNotOVAL(fixedValue);
      }
    }
  };

  getFittingLength = fitting => {
    const { productDimensions } = fitting;
    const { lc } = productDimensions;
    return Number(lc) ? Number(lc) : 0;
  };

  overAllLengthChangeHandler = value => {
    const { leftFittingTypes, rightFittingTypes } = this.props;
    if (!_.isEmpty(leftFittingTypes) && !_.isEmpty(rightFittingTypes)) {
      const leftLength = this.getFittingLength(leftFittingTypes);
      const rightLength = this.getFittingLength(rightFittingTypes);
      const totalFittingLength = Number((leftLength + rightLength).toFixed(2));
      const hoseLength = Number((value - totalFittingLength).toFixed(2));
      this.props.actionChangeHoseLength(hoseLength);
      this.props.actionChangeOverAllLength(value, totalFittingLength);
    }
  };

  overAllLengthChangeHandlerWhenNotOVAL = value => {
    this.props.actionChangeOverAllLength(value, 0);
  };

  leftFittingAngleChangeHandler = angle => {
    this.props.actionChangeLeftFittingAngle(angle);
  };

  rightFittingAngleChangeHandler = angle => {
    this.props.actionChangeRightFittingAngle(angle);
  };

  removeSpiralGuardHandler = () => {
    this.props.actionRemoveSpiralGuard();
  };

  cloneFittingToRight = () => {
    this.props.actionCloneToRight();
  };

  confirmHose = () => {
    const {
      hoseType,
      leftFittingTypes,
      rightFittingTypes,
      hoseLength,
      overAllLength,
      showOal
    } = this.props;

    const isValid = hoseUtil.validateHose(
      hoseType,
      leftFittingTypes,
      rightFittingTypes
    );
    if (!isValid) {
      showErrorToast("Please complete your hose first");
      return false;
    }
    if (hoseLength == 0 && overAllLength == 0) {
      showErrorToast("Please provide Hose length or Overall length.");
      return false;
    }

    if (!showOal) {
      if (hoseLength && overAllLength) {
        showErrorToast(
          "Please provide either Hose Length OR Overall length but not both."
        );
        return false;
      }
    }

    this.setState({ showModal: true });
  };

  cancelModal = () => {
    this.setState({
      showModal: false,
      isLoading: false
    });
  };

  hoseDescriptionHandler = (event, data) => {
    const { value } = data;
    this.props.actionHoseDescription(value);
  };

  checkBoxHandler = (e, data) => {
    const { checked } = data;
    this.setState({ iAgree: checked });
  };

  clearHoseHandler = () => {
    this.props.actionClearHoseData();
  };

  redirectToMyHoses = () => {
    this.props.history.push("/myHoses");
  };

  redirectToHoseMechanic = () => {
    this.props.history.push("/hoseMechanic");
  };

  hoseClickHandler = async () => {
    this.setState({ isLoading: true });
    const { hoseHandler, phase, hoseDescription, buttonText } = this.props;
    const { saveHoseRecipe = false } = this.state;
    if (_.isEmpty(hoseDescription)) {
      showErrorToast("Please enter hose description first");
      this.setState({ isLoading: false });
      return;
    }
    const { iAgree } = this.state;
    if (phase === 1 && (!iAgree || !hoseDescription)) {
      showErrorToast("Please agree to T&C and enter the product description.");
      this.setState({ isLoading: false });
      return;
    } else {
      await hoseHandler(false, buttonText, saveHoseRecipe);
      this.cancelModal();
      this.setState({ isLoading: false });
    }
  };

  hoseQuantityChange = value => {
    this.props.actionSetHoseQuantity(value);
  };

  sendToHoseMechanicHandler = async () => {
    this.setState({ isLoading: true });
    const {
      hoseHandler,
      hoseType,
      leftFittingTypes,
      rightFittingTypes,
      assetId
    } = this.props;
    const isValid = hoseUtil.validateHose(
      hoseType,
      leftFittingTypes,
      rightFittingTypes
    );
    if (!isValid) {
      showErrorToast("Please complete your hose first");
      return false;
    }
    await hoseHandler(true);
    this.sendToMechanicConfirmCancel();
    if (assetId) {
      this.props.history.push("/asset");
    }
    this.setState({ isLoading: false });
  };

  saveHoseRecipeHandler = async () => {
    this.setState({ isLoading: true });
    const {
      hoseHandler,
      hoseType,
      leftFittingTypes,
      rightFittingTypes,
      assetId
    } = this.props;
    const isValid = hoseUtil.validateHose(
      hoseType,
      leftFittingTypes,
      rightFittingTypes
    );
    if (!isValid) {
      showErrorToast("Please complete your hose first");
      return false;
    }
    await hoseHandler(false, null, true);
    this.sendToMechanicConfirmCancel();
    if (assetId) {
      this.props.history.push("/asset");
    }
    this.setState({ isLoading: false });
  };

  sendToMechanicConfirmHandler = (saveHoseRecipe = false) => {
    const { hoseType, leftFittingTypes, rightFittingTypes } = this.props;
    const isValid = hoseUtil.validateHose(
      hoseType,
      leftFittingTypes,
      rightFittingTypes
    );
    if (!isValid) {
      showErrorToast("Please complete your hose first");
      return false;
    }
    this.setState({ sendToMechanicConfirmModal: true });
  };

  saveHoseRecipeConfirmHandler = () => {
    const { hoseType, leftFittingTypes, rightFittingTypes } = this.props;
    const isValid = hoseUtil.validateHose(
      hoseType,
      leftFittingTypes,
      rightFittingTypes
    );
    if (!isValid) {
      showErrorToast("Please complete your hose first");
      return false;
    }
    this.setState({ saveHoseRecipe: true });
  };

  sendToMechanicConfirmCancel = () => {
    // this.props.actionSetFlowForHoseMaker("");
    this.setState({ sendToMechanicConfirmModal: false, saveHoseRecipe: false });
  };

  updateAndSendToInventoryModal = async () => {
    const {
      hoseType,
      leftFittingTypes,
      rightFittingTypes,
      hoseHandler
    } = this.props;
    const isValid = hoseUtil.validateHose(
      hoseType,
      leftFittingTypes,
      rightFittingTypes
    );
    if (!isValid) {
      showErrorToast("Please complete your hose first, before update");
      return false;
    }
    this.setState({ updateHoseLoader: true });
    await hoseHandler();
    this.setState({ addToInventoryModal: true, updateHoseLoader: false });
    return;
  };

  removeFerruleHandler = type => {
    this.props.actionRemoveFerrule(type);
  };

  goToHoseMechanicHandler = e => {
    e.preventDefault();
    this.props.history.push("/hoseMechanic");
  };

  handleAddToInventoryModalClose = () => {
    this.setState({ addToInventoryModal: false });
  };

  addToInventory = () => {
    this.setState({
      addToInventoryModal: false,
      sendToInventoryOrAssetModal: true
    });
    return;
  };

  cancelAddToInventoryOrAssetModal = () => {
    this.setState({ sendToInventoryOrAssetModal: false });
  };

  goToInventoryLocation = () => {
    this.props.history.push("/inventory/location");
  };

  render() {
    const {
      hoseType,
      leftFittingTypes,
      rightFittingTypes,
      hoseLength,
      rightFittingAngle,
      leftFittingAngle,
      spiralGuard,
      additionalProduct,
      totalPrice,
      makeAtBoa,
      leftCrimpSize,
      rightCrimpSize,
      overAllLength,
      buttonText,
      phase,
      showOal,
      hoseQuantity,
      leftFerrule,
      rightFerrule,
      leftSeatTypeImage,
      rightSeatTypeImage,
      editHose,
      user = {},
      assetName,
      model,
      subModel,
      position,
      assetId,
      stockCode: hoseCodeFromDb,
      hoseId,
      flow
    } = this.props;
    let { hoseDescription } = this.props;
    const { account = {} } = user;
    const { showFacilities = false } = account;
    const angleValue = (rightFittingAngle * 100) / 360;
    const {
      isLoading,
      showModal,
      closeOnEscape,
      closeOnDimmerClick,
      sendToMechanicConfirmModal,
      addToInventoryModal,
      sendToInventoryOrAssetModal,
      updateHoseLoader,
      inventoryLoc,
      saveHoseRecipe
    } = this.state;
    let hoseName = "";
    let leftThreadType = "";
    let rightThreadType = "";
    let defaultDescription = "";

    if (hoseType) {
      const { shortenedCode } = hoseType;
      hoseName = hoseName + shortenedCode;
    }

    if (!_.isEmpty(leftFittingTypes)) {
      const { shortenedCode, productDimensions } = leftFittingTypes;
      const { threadType1 } = productDimensions;
      leftThreadType = threadType1;
      hoseName = hoseName + "-" + shortenedCode;
    }

    if (!_.isEmpty(rightFittingTypes)) {
      const { shortenedCode, productDimensions } = rightFittingTypes;
      const { threadType1 } = productDimensions;
      rightThreadType = threadType1;
      // hoseName = hoseName + "_" + shortenedCode + "_" + rightFittingAngle;
      hoseName = hoseName + "-" + shortenedCode;
    }

    // if (!_.isEmpty(leftFittingTypes) && !_.isEmpty(rightFittingTypes)) {
    //   const leftLength = this.getFittingLength(leftFittingTypes);
    //   const rightLength = this.getFittingLength(rightFittingTypes);
    //   fittingLength = Number(leftLength + rightLength);
    //   overAllLengthToShow = Number(hoseLength + fittingLength);
    //   hoseName = hoseName + "_" + overAllLengthToShow;
    // }
    // if (!_.isEmpty(spiralGuard)) {
    //   const { shortenedCode } = spiralGuard;
    //   hoseName = hoseName + "_" + shortenedCode;
    // }

    let showAngleDropDown = true;

    if (!_.isEmpty(leftFittingTypes)) {
      const { productDimensions } = leftFittingTypes;
      const { orientation } = productDimensions;
      if (orientation === "STRAIGHT") {
        showAngleDropDown = false;
      }
    }
    if (!_.isEmpty(rightFittingTypes)) {
      const { productDimensions } = rightFittingTypes;
      const { orientation } = productDimensions;
      if (orientation === "STRAIGHT") {
        showAngleDropDown = false;
      }
    }

    // let length = "";
    // if (showOal) {
    //   length =
    //     overAllLength >= hoseLength
    //       ? "OAL: " + overAllLength
    //       : "HL: " + hoseLength;
    // } else {
    //   length = overAllLength >= hoseLength ? overAllLength : +hoseLength;
    // }

    const length =
      overAllLength >= hoseLength
        ? "OAL: " + overAllLength
        : "HL: " + hoseLength;

    // const length = overAllLength >= hoseLength ? "OAL " : "HL ";

    defaultDescription = hoseName + " – " + rightFittingAngle + "/" + length;
    const phase2AssetToHoseDescription = `${model}  ${subModel}  ${position} Angle : ${rightFittingAngle} Length : ${length}`;
    const addToCartConfirmModalJSX = (
      <Modal
        open={showModal}
        onClose={this.cancelModal}
        size="fullscreen"
        closeOnEscape={closeOnEscape}
        closeOnDimmerClick={closeOnDimmerClick}
        closeIcon
      >
        <Header content={"Hose Identification : " + hoseName} />
        <Modal.Content>
          <Grid>
            <Grid.Column width={8}>
              <Form>
                <Form.Field>
                  <label>Hose description</label>
                  <Input
                    label={defaultDescription}
                    type="text"
                    placeholder="Please enter hose description"
                    value={hoseDescription}
                    onChange={this.hoseDescriptionHandler}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
            {/* <Grid.Column width={2}>
              {" "}
              <p>{length}</p>
            </Grid.Column> */}

            <Grid.Column width={8}>
              <Form>
                <label>Quantity</label>
                <Form.Field>
                  <NumericInputBox
                    value={hoseQuantity}
                    onChangeHandler={value => this.hoseQuantityChange(value)}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid>
          <HoseConfirmModal
            hose={hoseType}
            left={leftFittingTypes}
            right={rightFittingTypes}
            leftAngle={leftFittingAngle}
            rightAngle={rightFittingAngle}
            hoseLength={hoseLength}
            overAllLength={overAllLength}
            additionalProducts={additionalProduct}
            hoseString={hoseName}
            hoseDescription={hoseDescription}
            makeAtBoa={makeAtBoa}
            totalPrice={totalPrice}
            checkboxHandler={this.checkBoxHandler}
            crimpLeft={rightCrimpSize}
            crimpRight={leftCrimpSize}
            phase={phase}
            leftSeatTypeImage={leftSeatTypeImage}
            rightSeatTypeImage={rightSeatTypeImage}
            leftFerrule={leftFerrule}
            rightFerrule={rightFerrule}
            toCart={showModal}
            saveHoseRecipe={saveHoseRecipe}
          />
        </Modal.Content>
        <Modal.Actions>
          {!isLoading ? (
            <Button color="green" onClick={this.hoseClickHandler}>
              <Icon name="checkmark" /> {buttonText}
            </Button>
          ) : (
            <Button color="green" loading>
              Saving hose
            </Button>
          )}
          <Button primary onClick={this.cancelModal} inverted>
            <Icon name="cancel" /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );

    const sendToHoseMechanicConfirmModalJSX = (
      <Modal
        open={sendToMechanicConfirmModal}
        onClose={this.sendToMechanicConfirmCancel}
        size="fullscreen"
        closeOnEscape={closeOnEscape}
        closeOnDimmerClick={closeOnDimmerClick}
        closeIcon
      >
        <Header content={"Hose Identification : " + hoseName} />
        <Modal.Content>
          <Grid>
            <Grid.Column width={8}>
              <Form>
                <Form.Field>
                  <label>Hose description: </label>
                  {assetId ? (
                    <Input
                      type="text"
                      label={phase2AssetToHoseDescription}
                      placeholder="Please enter hose description"
                      value={
                        flow === "editHoseForAsset" ? null : hoseDescription
                      }
                      onChange={this.hoseDescriptionHandler}
                    />
                  ) : (
                    <Input
                      type="text"
                      label={defaultDescription}
                      placeholder="Please enter hose description"
                      value={hoseDescription}
                      onChange={this.hoseDescriptionHandler}
                    />
                  )}
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form>
                <label>Quantity</label>
                <Form.Field>
                  <NumericInputBox
                    value={hoseQuantity}
                    onChangeHandler={value => this.hoseQuantityChange(value)}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid>
          <HoseConfirmModal
            hose={hoseType}
            left={leftFittingTypes}
            right={rightFittingTypes}
            leftAngle={leftFittingAngle}
            rightAngle={rightFittingAngle}
            hoseLength={hoseLength}
            overAllLength={overAllLength}
            additionalProducts={additionalProduct}
            hoseString={hoseName}
            hoseDescription={hoseDescription}
            makeAtBoa={makeAtBoa}
            totalPrice={totalPrice}
            checkboxHandler={this.checkBoxHandler}
            crimpLeft={rightCrimpSize}
            crimpRight={leftCrimpSize}
            phase={2}
            leftSeatTypeImage={leftSeatTypeImage}
            rightSeatTypeImage={rightSeatTypeImage}
            leftFerrule={leftFerrule}
            rightFerrule={rightFerrule}
            toCart={false}
          />
        </Modal.Content>
        <Modal.Actions>
          {!isLoading ? (
            <Button color="green" onClick={this.sendToHoseMechanicHandler}>
              <Icon name="checkmark" />{" "}
              {editHose
                ? "Update and send to hose mechanic"
                : "Send to hose mechanic"}
            </Button>
          ) : (
            <Button color="green" loading>
              {editHose ? "Updating" : "Saving "} hose
            </Button>
          )}
          <Button primary onClick={this.sendToMechanicConfirmCancel} inverted>
            <Icon name="cancel" /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );

    const saveHoseRecipeConfirmModalJSX = (
      <Modal
        open={saveHoseRecipe}
        onClose={this.sendToMechanicConfirmCancel}
        size="fullscreen"
        closeOnEscape={closeOnEscape}
        closeOnDimmerClick={closeOnDimmerClick}
        closeIcon
      >
        <Header content={"Hose Identification : " + hoseName} />
        <Modal.Content>
          <Grid>
            <Grid.Column width={8}>
              <Form>
                <Form.Field>
                  <label>Hose description: </label>
                  {assetId ? (
                    <Input
                      type="text"
                      label={phase2AssetToHoseDescription}
                      placeholder="Please enter hose description"
                      value={
                        flow === "editHoseForAsset" ? null : hoseDescription
                      }
                      onChange={this.hoseDescriptionHandler}
                    />
                  ) : (
                    <Input
                      type="text"
                      label={defaultDescription}
                      placeholder="Please enter hose description"
                      value={hoseDescription}
                      onChange={this.hoseDescriptionHandler}
                    />
                  )}
                </Form.Field>
              </Form>
            </Grid.Column>
            <Grid.Column width={8}>
              <Form>
                <label>Quantity</label>
                <Form.Field>
                  <NumericInputBox
                    value={hoseQuantity}
                    onChangeHandler={value => this.hoseQuantityChange(value)}
                  />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid>
          <HoseConfirmModal
            hose={hoseType}
            left={leftFittingTypes}
            right={rightFittingTypes}
            leftAngle={leftFittingAngle}
            rightAngle={rightFittingAngle}
            hoseLength={hoseLength}
            overAllLength={overAllLength}
            additionalProducts={additionalProduct}
            hoseString={hoseName}
            hoseDescription={hoseDescription}
            makeAtBoa={makeAtBoa}
            totalPrice={totalPrice}
            checkboxHandler={this.checkBoxHandler}
            crimpLeft={rightCrimpSize}
            crimpRight={leftCrimpSize}
            phase={2}
            leftSeatTypeImage={leftSeatTypeImage}
            rightSeatTypeImage={rightSeatTypeImage}
            leftFerrule={leftFerrule}
            rightFerrule={rightFerrule}
            toCart={false}
            saveHoseRecipe={saveHoseRecipe}
          />
        </Modal.Content>
        <Modal.Actions>
          {!isLoading ? (
            <Button color="green" onClick={this.saveHoseRecipeHandler}>
              <Icon name="checkmark" /> Save hose recipe to hose position
            </Button>
          ) : (
            <Button color="green" loading>
              {editHose ? "Updating" : "Saving "} hose
            </Button>
          )}
          <Button primary onClick={this.sendToMechanicConfirmCancel} inverted>
            <Icon name="cancel" /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );

    return (
      <div className="hose-maker-container">
        <Grid>
          <Grid.Column width={10}>
            <h3>
              <Icon name="wrench" /> Hose maker
            </h3>
          </Grid.Column>
          {/* <Grid.Column width={6}>
            {inventoryLoc && assetId &&
              <div style={{ textAlign: "right", color: "red" }}>
                No locations available in Inventory. <a onClick={this.goToInventoryLocation}>Add New</a>
              </div>
            }
          </Grid.Column> */}
        </Grid>
        <Divider />
        {isLoading && <Loader text="Loading, please wait.." />}
        <Grid>
          <Grid.Column width={5}>
            {addToCartConfirmModalJSX}
            {sendToHoseMechanicConfirmModalJSX}
            {saveHoseRecipeConfirmModalJSX}
            {editHose && (
              <SendToInventoryOrAssetModalJSX
                sendToInventoryOrAssetModal={sendToInventoryOrAssetModal}
                cancelAddToInventoryOrAssetModal={
                  this.cancelAddToInventoryOrAssetModal
                }
                hoseName={hoseName}
              />
            )}
            <p>Hose code: {hoseName}</p>
            {editHose && (
              <i style={{ fontSize: "12px" }}>
                {" "}
                Earlier Saved hose code: <b>{hoseCodeFromDb}</b>{" "}
              </i>
            )}
            {assetId ? (
              <p style={{ fontSize: "13px" }}>
                {" "}
                ( Asset Name: {assetName} ,Position: {position} )
              </p>
            ) : null}
          </Grid.Column>
          {editHose && (
            <Grid.Column width={10}>
              <AddToInventoryModalJSX
                isModalOpen={addToInventoryModal}
                handleClose={this.handleAddToInventoryModalClose}
                isLoading={isLoading}
                addToInventory={this.addToInventory}
                assetId={assetId}
              />
              <Grid>
                {flow === "editHoseForAsset" ? (
                  <Grid.Column width={16}>
                    <span>
                      <Button
                        floated="right"
                        onClick={this.sendToMechanicConfirmHandler}
                      >
                        Send to hose mechanic
                      </Button>

                      <Button
                        color="green"
                        floated="right"
                        onClick={this.confirmHose}
                      >
                        Order Hose from BOA
                      </Button>
                      {showFacilities && assetId && (
                        <Button
                          color="green"
                          floated="right"
                          onClick={this.saveHoseRecipeConfirmHandler}
                        >
                          Save Hose Recipe
                        </Button>
                      )}
                    </span>
                  </Grid.Column>
                ) : (
                  <Grid.Column width={16}>
                    {!updateHoseLoader ? (
                      <span>
                        <Button
                          color="yellow"
                          floated="right"
                          onClick={this.updateAndSendToInventoryModal}
                          // disabled={inventoryLoc}
                        >
                          Update hose and{" "}
                          {assetId ? "attach to asset" : "add to inventory"}
                        </Button>
                      </span>
                    ) : (
                      <Button color="yellow" floated="right" loading>
                        Updating hose please wait
                      </Button>
                    )}

                    <Button
                      secondary
                      floated="right"
                      onClick={this.goToHoseMechanicHandler}
                    >
                      Go to hose mechanic
                    </Button>
                  </Grid.Column>
                )}
              </Grid>
            </Grid.Column>
          )}
          {!editHose && (
            <Grid.Column width={11}>
              <Button secondary floated="right" onClick={this.clearHoseHandler}>
                Clear hose
              </Button>
              {showFacilities && (
                <Button
                  floated="right"
                  onClick={this.sendToMechanicConfirmHandler}
                >
                  Send to hose mechanic
                </Button>
              )}
              <Button color="green" floated="right" onClick={this.confirmHose}>
                Order Hose from BOA
              </Button>
              {showFacilities && assetId && (
                <Button
                  color="green"
                  floated="right"
                  onClick={this.saveHoseRecipeConfirmHandler}
                >
                  Save Hose Recipe
                </Button>
              )}
            </Grid.Column>
          )}
        </Grid>
        {!_.isEmpty(hoseType) && !_.isEmpty(leftFittingTypes) && (
          <Grid columns="equal">
            <Grid.Column>
              {!_.isEmpty(leftFittingTypes) && (
                <div>
                  {" "}
                  <b>Crimp : {leftCrimpSize ? leftCrimpSize + "mm" : TBA}</b>
                  <br />
                  <p>
                    <b>
                      {leftThreadType}{" "}
                      {leftSeatTypeImage && (
                        <Image src={leftSeatTypeImage} size={"tiny"} />
                      )}
                    </b>
                  </p>
                </div>
              )}
              {!_.isEmpty(leftFerrule) && (
                <FerruleImage alt="Left ferrule" ferrule={leftFerrule} />
              )}
            </Grid.Column>
            <Grid.Column>&nbsp;</Grid.Column>
            <Grid.Column>
              {!_.isEmpty(rightFittingTypes) && (
                <div>
                  <b>Crimp : {rightCrimpSize ? rightCrimpSize + "mm" : TBA}</b>
                  <br />
                  <p>
                    <b>
                      {rightThreadType}
                      {rightSeatTypeImage && (
                        <Image
                          src={rightSeatTypeImage}
                          size={"tiny"}
                          floated={"left"}
                        />
                      )}
                    </b>
                  </p>
                </div>
              )}
              {!_.isEmpty(rightFerrule) && (
                <FerruleImage alt="Right ferrule" ferrule={rightFerrule} />
              )}
            </Grid.Column>
          </Grid>
        )}
        <Grid columns="equal" className="hose-maker-box">
          <Grid.Column>
            {hoseType ? (
              <FittingLeft
                fittingHandler={this.leftFittingHandler}
                selectedTypes={leftFittingTypes}
              />
            ) : (
              <div className="fitting-message">
                <h3>2. Select left Fitting</h3>
                Click to Select Fitting
              </div>
            )}
          </Grid.Column>
          <Grid.Column width={6} className="hose-bore">
            <HoseType
              boarChangeHandler={this.boarChangeHandler}
              selectedType={hoseType}
            />
          </Grid.Column>
          <Grid.Column>
            {hoseType && !_.isEmpty(leftFittingTypes) ? (
              <FittingRight
                fittingHandler={this.rightFittingHandler}
                selectedTypes={rightFittingTypes}
              />
            ) : (
              <div className="fitting-message">
                <h3>3. Select right Fitting</h3>
                Click to Select Fitting
              </div>
            )}
          </Grid.Column>
        </Grid>
        <Grid columns={3}>
          <Grid.Column>
            {!_.isEmpty(leftFittingTypes) && (
              <div>
                <p>0 Degree</p>
                <Button onClick={this.cloneFittingToRight}>
                  Clone Fitting{" "}
                </Button>
              </div>
            )}
          </Grid.Column>
          <Grid.Column>
            {!_.isEmpty(hoseType) &&
              !_.isEmpty(leftFittingTypes) &&
              !_.isEmpty(rightFittingTypes) && (
                <div>
                  <HoseLength
                    changeHandler={this.lengthChangeHandler}
                    hoseLength={hoseLength}
                    overAllLengthChangeHandler={this.overAllLengthChangeHandler}
                    overAllLength={overAllLength}
                    showOal={showOal}
                    leftFitting={leftFittingTypes}
                    rightFitting={rightFittingTypes}
                    overAllLengthChangeHandlerWhenNotOVAL={
                      this.overAllLengthChangeHandlerWhenNotOVAL
                    }
                  />
                  <SpiralGuard />
                </div>
              )}
          </Grid.Column>
          <Grid.Column>
            {!_.isEmpty(rightFittingTypes) && showAngleDropDown && (
              <div className="angle-selector-box">
                <Gauge
                  className="gauge"
                  {...gaugeOptions}
                  currentValue={angleValue}
                />
                <br />
                <AngleHelpModal />
                <AngleSelector
                  angleChangeHandler={this.rightFittingAngleChangeHandler}
                  angle={rightFittingAngle}
                />
              </div>
            )}
            {!_.isEmpty(rightFittingTypes) && !showAngleDropDown && (
              <p style={{ float: "right" }}>0 Degree</p>
            )}
          </Grid.Column>
        </Grid>
        <HoseImages additionalProduct={additionalProduct} />
        {!_.isEmpty(hoseType) && (
          <SelectedHoseInfo
            hoseType={hoseType}
            hoseLength={hoseLength}
            rightFitting={rightFittingTypes}
            leftFitting={leftFittingTypes}
            clearHose={this.clearHoseType}
            removeFitting={this.removeFittings}
            leftAngle={leftFittingAngle}
            rightAngle={rightFittingAngle}
            spiralGuard={spiralGuard}
            removeSpiralGuard={this.removeSpiralGuardHandler}
            leftFerrule={leftFerrule}
            rightFerrule={rightFerrule}
            removeFerrule={this.removeFerruleHandler}
          />
        )}

        <AdditionalProducts />
      </div>
    );
  }
}

HoseMakerContainer.propTypes = {
  phase: PropTypes.number,
  hoseHandler: PropTypes.func,
  buttonText: PropTypes.string
};

const mapStateToProps = state => ({
  hoseType: state.hoseMaker.hoseType,
  leftFittingTypes: state.hoseMaker.leftFittingTypes,
  rightFittingTypes: state.hoseMaker.rightFittingTypes,
  hoseLength: state.hoseMaker.hoseLength,
  rightFittingAngle: state.hoseMaker.rightFittingAngle,
  leftFittingAngle: state.hoseMaker.leftFittingAngle,
  spiralGuard: state.hoseMaker.spiralGuard,
  hoseDiameters: state.hoseMaker.hoseDiameters,
  orientation: state.hoseMaker.orientation,
  measurement: state.hoseMaker.measurement,
  additionalProduct: state.hoseMaker.additionalProduct,
  makeAtBoa: state.hoseMaker.makeAtBoa,
  totalPrice: state.hoseMaker.totalPrice,
  response: state.hoseMaker.response,
  hoseDescription: state.hoseMaker.hoseDescription,
  leftCrimpSize: state.hoseMaker.leftCrimpSize,
  rightCrimpSize: state.hoseMaker.rightCrimpSize,
  overAllLength: state.hoseMaker.overAllLength,
  showOal: state.hoseMaker.showOal,
  hoseQuantity: state.hoseMaker.hoseQuantity,
  leftFerrule: state.hoseMaker.leftFerrule,
  rightFerrule: state.hoseMaker.rightFerrule,
  leftSeatTypeImage: state.hoseMaker.leftSeatTypeImage,
  rightSeatTypeImage: state.hoseMaker.rightSeatTypeImage,
  editHose: state.hoseMaker.editHose,
  user: state.auth.user,
  model: state.hoseMaker.model,
  assetName: state.hoseMaker.assetName,
  subModel: state.hoseMaker.subModel,
  position: state.hoseMaker.position,
  assetId: state.hoseMaker.assetId,
  stockCode: state.hoseMaker.stockCode,
  allLocations: state.inventoryLocation.allLocations,
  hoseId: state.hoseMaker.hoseId,
  flow: state.hoseMaker.flow
});

const reducer = {
  ...hoseMakerReducer,
  ...hoseMechanicReducer,
  ...locationReducer
};

const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HoseMakerContainer)
);
