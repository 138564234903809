import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  Grid,
  Form,
  Button,
  Dropdown,
  Message
} from "semantic-ui-react";
import _ from "lodash";

const AddToInventory = ({
  isAddInventory = false,
  closeForm,
  addToInventory,
  setLocation,
  locationId,
  locations = [],
  isLoading = false,
  isError = false
}) => {
  return (
    <Modal open={isAddInventory} onClose={closeForm} closeIcon>
      <Modal.Header>Add to Inventory</Modal.Header>
      <Modal.Content className="add-location">
        <Form
          model="forms.locationForm"
          onSubmit={addToInventory}
          error={isError}
        >
          <Grid.Column>
            <Form.Field required>
              <label>Location</label>
              <Dropdown
                placeholder="Select Location"
                fluid
                value={locationId}
                selection
                required
                onChange={setLocation}
                options={locations}
              />
              {_.isEmpty(locations) ? (<span style={{ color: "red" }}>
                No locations available, Create a new one!</span>)
                : null}
            </Form.Field>
            <Message error={true} content="Location is required" />
          </Grid.Column>
          <Grid.Column width={16} className="button-panel" textAlign="right">
            {!isLoading && (
              <Button
                secondary
                size="small"
                className="button-float"
                type="submit"
              >
                Submit
              </Button>
            )}
            {isLoading && (
              <Button secondary size="small" className="button-float" loading>
                Submit
              </Button>
            )}
            <Button size="small" onClick={closeForm} className="button-float">
              Close
            </Button>
          </Grid.Column>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

AddToInventory.propTypes = {
  isAddInventory: PropTypes.bool,
  closeForm: PropTypes.func,
  addToInventory: PropTypes.func,
  locationId: PropTypes.string,
  locations: PropTypes.array,
  setLocation: PropTypes.func,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool
};
export default AddToInventory;
