import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Control, Errors } from "react-redux-form";
import {
  Grid,
  Form,
  Dropdown,
  Radio,
  Confirm,
  Popup,
  Button
} from "semantic-ui-react";
import polyglot from "../../i18n";
import * as assetFormReducer from "../../containers/AssetForm/assetForm.reducer";
import * as locationReducer from "../../containers/AssetLocation/assetLocation.reducer";
import * as crewReducer from "../../containers/CrewManagement/crew.reducer";
import NumericInput from "react-numeric-input";
import LocationDropdown from "../LocationDropdown";
import _ from "lodash";
import moment from "moment";
import { withRouter } from "react-router-dom";
import "./assetInfo.css";
import ViewImage from "../ViewImage";
import AddImage from "../../containers/AssetForm/AddImage";
import Loader from "../../components/Loader";

class AssetInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isImageLoading: false,
      isError: false,
      isOpen: false,
      value: "",
      isErrorYear: false,
      isParentImage: false,
      isLinkedAssetRequired: true
    };
  }

  componentDidMount = async () => {
    this.props.actionGetAllCrew();
    this.props.actionGetAllLocation();
    this.props.getUserList();
    this.props.actionGetAssetsForDropdown();
  };

  setCrewName = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("crew", value);
  };

  setLocation = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("location", value);
  };

  setOperator = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("operator", value);
  };

  setKilometer = (e, data) => {
    this.props.actionSetFormField("maintenanceUnit", e);
  };

  setLinkedAssetFlag = async (e, data) => {
    const isLinkedAsset = await this.checkLinkedAsset();
    if (isLinkedAsset) {
      return;
    }
    const { value } = data;
    this.props.actionSetFormField("isLinkedAsset", value);
    if (!value) {
      this.props.actionSetFormField("linkedAsset", "");
      this.setState({
        isLinkedAssetRequired: true
      });
    } else if (value) {
      this.setState({
        isLinkedAssetRequired: false
      });
    } else {
      this.props.actionSetFormField(
        "linkedAsset",
        this.props.asset.linkedAsset
      );
    }
  };

  setLinkedAsset = (e, data) => {
    const { value } = data;
    this.props.actionSetFormField("linkedAsset", value);
  };

  checkLinkedAsset = () => {
    const { forms } = this.props;
    const { assetForm } = forms;
    if (assetForm.childAssets.length > 0) {
      this.setState({ isOpen: true });
      this.props.actionSetFormField("isLinkedAsset", false);
      return true;
    }
    return false;
  };

  textHandle = event => {
    const { target } = event;
    const { value } = target;
    let newValue = value;
    if (newValue.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  };

  yearChangeHandler = (e, data) => {
    this.setState({ isErrorYear: false });
    const year = moment().year();
    if (e > year) {
      this.setState({ isErrorYear: true });
    }
    //this.props.actionSetYear(`${ASSET_FORM}.year`, e);
    this.props.actionSetFormField("year", e);
  };

  deleteParentImage = async imageObject => {
    this.setState({ isImageLoading: true });
    const { key: imageKey } = imageObject;
    await this.props.actionDeleteAssetImage(imageKey);
    const { forms } = this.props;
    const { assetForm } = forms;
    const { assetImage } = assetForm;
    const imageArray = [...assetImage];
    _.remove(imageArray, o => {
      return o.key === imageKey;
    });
    this.props.actionSetFormField("assetImage", imageArray);
    this.setState({ isImageLoading: false });
  };

  uploadParentImage = async fileData => {
    const data = new FormData();
    data.append("file", fileData);
    await this.props.actionUploadAssetImage(data);

    const { assetImageResult, forms } = this.props;
    const { assetForm } = forms;
    const { assetImage } = assetForm;
    const imageArray = [...assetImage];

    imageArray.push(assetImageResult);
    this.props.actionSetFormField("assetImage", imageArray);
    this.closeAddParentImageModal();
  };

  closeAddParentImageModal = () => {
    this.setState({ isParentImage: false });
  };

  render() {
    const {
      allCrew,
      allLocations,
      forms,
      users,
      isEdit,
      asset,
      assetList
    } = this.props;
    const { assetForm } = forms;
    const {
      crew,
      location,
      operator,
      linkedAsset,
      isLinkedAsset,
      maintenanceUnit,
      year: formYear,
      assetImage = []
    } = assetForm;

    const {
      name,
      type,
      make,
      model,
      subModel,
      engineType,
      serialNumber,
      customerPlantNo,
      modifications,
      linkedERP,
      assetImage: existingImages = []
    } = asset;
    const {
      isErrorYear,
      isParentImage,
      isImageLoading,
      isLinkedAssetRequired
    } = this.state;

    let crewList = [];
    if (allCrew && allCrew.length) {
      _.map(allCrew, (crew, c_key) => {
        crewList.push({
          text: crew.name,
          value: crew._id
        });
      });
    }

    let usersList = [];
    if (users.length) {
      _.map(users, user => {
        const { firstName, lastName, _id } = user;
        usersList.push({
          text: `${firstName} ${lastName}`,
          value: _id
        });
      });
    }

    let allAsset = [];
    if (assetList.length) {
      _.map(assetList, user => {
        const { _id, name } = user;
        if (asset._id !== _id) {
          allAsset.push({
            text: name,
            value: _id
          });
        }
      });
    }
    let assetName = "";
    if (linkedAsset) {
      const asset = _.find(assetList, { _id: linkedAsset });
      if (asset) {
        assetName = asset.name;
      }
    }

    const assetNameText = (
      <Form.Field required>
        <label>{polyglot.t("assetForm.name")}</label>
        <Control
          type="text"
          model="forms.assetForm.name"
          placeholder={polyglot.t("assetForm.name")}
          validateOn="change"
          required
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.name"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.name")
          }}
        />
      </Form.Field>
    );

    const assetTypeText = (
      <Form.Field required>
        <label>{polyglot.t("assetForm.type")}</label>
        <Control
          type="text"
          model="forms.assetForm.type"
          placeholder={polyglot.t("assetForm.type")}
          validateOn="change"
          required
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.type"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.type")
          }}
        />
      </Form.Field>
    );

    const modificationText = (
      <Form.Field>
        <Control
          type="text"
          model="forms.assetForm.modifications"
          placeholder={polyglot.t("assetForm.modifications")}
          validateOn="change"
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.modifications"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.modifications")
          }}
        />
      </Form.Field>
    );

    const linkedERPText = (
      <Form.Field>
        <Control
          type="text"
          model="forms.assetForm.linkedERP"
          placeholder={polyglot.t("assetForm.linkedERP")}
          validateOn="change"
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.linkedERP"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.linkedERP")
          }}
        />
      </Form.Field>
    );

    const makeText = (
      <Form.Field required>
        <label>{polyglot.t("assetForm.make")}</label>
        <Control
          type="text"
          model="forms.assetForm.make"
          placeholder={polyglot.t("assetForm.make")}
          validateOn="change"
          required
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.make"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.make")
          }}
        />
      </Form.Field>
    );

    const modelText = (
      <Form.Field required>
        <label>{polyglot.t("assetForm.model")}</label>
        <Control
          type="text"
          model="forms.assetForm.model"
          placeholder={polyglot.t("assetForm.model")}
          validateOn="change"
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.model"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.model")
          }}
        />
      </Form.Field>
    );

    const subModelText = (
      <Form.Field>
        <Control
          type="text"
          model="forms.assetForm.subModel"
          placeholder={polyglot.t("assetForm.subModel")}
          validateOn="change"
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.subModel"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.subModel")
          }}
        />
      </Form.Field>
    );

    const yearText = (
      <Form.Field>
        <label>{polyglot.t("assetForm.year")}</label>
        <NumericInput
          min={1}
          max={1000}
          maxLength={4}
          className="form-control"
          validateOn="change"
          placeholder="year"
          onChange={this.yearChangeHandler}
          value={formYear}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.year"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.year")
          }}
        />
        {isErrorYear && (
          <span style={{ color: "red" }}>
            Year should not be greater than current year .
          </span>
        )}
      </Form.Field>
    );

    const engineTypeText = (
      <Form.Field>
        <Control
          type="text"
          model="forms.assetForm.engineType"
          placeholder={polyglot.t("assetForm.engineType")}
          validateOn="change"
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.engineType"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.engineType")
          }}
        />
      </Form.Field>
    );

    const serialNumberText = (
      <Form.Field>
        <Control
          type="text"
          model="forms.assetForm.serialNumber"
          placeholder={polyglot.t("assetForm.serialNumber")}
          validateOn="change"
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.serialNumber"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.serialNumber")
          }}
        />
      </Form.Field>
    );

    const customerPlantNoText = (
      <Form.Field>
        <Control
          type="text"
          model="forms.assetForm.customerPlantNo"
          placeholder={polyglot.t("assetForm.customerPlantNo")}
          validateOn="change"
          className="input-box"
        />
        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.customerPlantNo"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.customerPlantNo")
          }}
        />
      </Form.Field>
    );

    const crewNameText = (
      <Form.Field required>
        <label>{polyglot.t("assetForm.crewName")}</label>
        <Dropdown
          placeholder={polyglot.t("assetForm.crewName")}
          fluid
          value={crew}
          selection
          required
          onChange={this.setCrewName}
          options={crewList}
        />
        {_.isEmpty(crewList) ? (
          <span style={{ color: "red" }}>
            No crews available, Create a new one!
          </span>
        ) : null}
      </Form.Field>
    );

    const operatorNameText = (
      <Form.Field required>
        <label>{polyglot.t("assetForm.operator")}</label>
        <Dropdown
          placeholder="Select Operator"
          fluid
          value={operator}
          selection
          required
          onChange={this.setOperator}
          options={usersList}
        />
        {_.isEmpty(usersList) ? (
          <span style={{ color: "red" }}>
            No operators available, Create a new one!
          </span>
        ) : null}
      </Form.Field>
    );

    const locationNameText = (
      <Form.Field required>
        <label>{polyglot.t("assetForm.location")}</label>
        <LocationDropdown
          setLocation={this.setLocation}
          locationId={location}
          locations={allLocations}
        />
      </Form.Field>
    );

    const maintenanceUnitText = (
      // <Form.Field required={isLinkedAssetRequired}>
      <Form.Field required>
        <label>{polyglot.t("assetForm.kilometer")}</label>

        <NumericInput
          min={1}
          max={10000}
          maxLength={5}
          className="form-control"
          validateOn="change"
          placeholder="Kilometer/Engine Hours"
          // required={isLinkedAssetRequired}
          required
          onChange={this.setKilometer}
          value={maintenanceUnit}
        />

        <Errors
          style={{ color: "red" }}
          model="forms.assetForm.maintenanceUnit"
          show="touched"
          messages={{
            valueMissing: polyglot.t("assetForm.required.kilometer")
          }}
        />
      </Form.Field>
    );

    const assetsToLink = (
      <React.Fragment>
        <Dropdown
          placeholder="Select Asset"
          fluid
          value={linkedAsset}
          selection
          required
          onChange={this.setLinkedAsset}
          options={allAsset}
        />
        {_.isEmpty(allAsset) ? (
          <span style={{ color: "red" }}>
            No assets available, Create a new one!
          </span>
        ) : null}
      </React.Fragment>
    );

    const linkedOptions = (
      <div>
        <Form.Group inline style={{ padding: "10px", marginBottom: "0px" }}>
          <Form.Field>
            <Radio
              label={polyglot.t("assetForm.no")}
              name={polyglot.t("assetForm.no")}
              value={false}
              checked={isLinkedAsset === false}
              onChange={this.setLinkedAssetFlag}
            />
          </Form.Field>
          <Form.Field>
            <Radio
              label={polyglot.t("assetForm.yes")}
              name={polyglot.t("assetForm.yes")}
              value={true}
              checked={isLinkedAsset === true}
              onChange={this.setLinkedAssetFlag}
            />
          </Form.Field>
        </Form.Group>
      </div>
    );

    return (
      <div className="form-body">
        <Confirm
          open={this.state.isOpen}
          content="This asset is already linked with another Asset."
          onConfirm={() => this.setState({ isOpen: false })}
          onCancel={() => this.setState({ isOpen: false })}
        />
        <Grid columns="equal" className="form-input">
          <Grid.Column width={4} computer={4} mobile={16}>
            <label>
              <b>{polyglot.t("assetForm.isLinkedToAsset")}</b>
            </label>
            <Popup
              trigger={<a style={{ marginLeft: "5%" }}>?</a>}
              content="Help text"
              basic
            />
            {isEdit ? (
              linkedOptions
            ) : (
              <p> {asset.isLinkedAsset ? "Yes" : "No"} </p>
            )}
          </Grid.Column>

          {isLinkedAsset && (
            <Grid.Column width={4} computer={4} mobile={16}>
              <Form.Field required>
                <label>{polyglot.t("assetForm.LinkedAsset")}</label>
                <span>{assetsToLink}</span>
              </Form.Field>
            </Grid.Column>
          )}

          {/* {isLinkedAsset &&
          (
              <Grid.Column width={4} computer={4} mobile={16}>
                <Form.Field required>
                  <label>{polyglot.t("assetForm.LinkedAsset")}</label>
                  {assetsToLink}
                </Form.Field>
              </Grid.Column>
            )} */}

          <Grid.Column tablet={8} computer={4} mobile={16}>
            {/* <label>{polyglot.t("assetForm.name")}</label> */}
            {isEdit ? (
              assetNameText
            ) : (
              <div>
                {" "}
                <label>{polyglot.t("assetForm.name")}</label>
                <p> {name}</p>{" "}
              </div>
            )}
          </Grid.Column>

          <Grid.Column tablet={8} computer={4} mobile={16}>
            {isEdit ? (
              assetTypeText
            ) : (
              <div>
                <label>{polyglot.t("assetForm.type")}</label> <p> {type} </p>{" "}
              </div>
            )}
          </Grid.Column>

          <Grid.Column tablet={8} computer={4} mobile={16}>
            {isEdit ? (
              makeText
            ) : (
              <div>
                {" "}
                <label>{polyglot.t("assetForm.make")}</label> <p> {make} </p>
              </div>
            )}
          </Grid.Column>

          <Grid.Column tablet={8} computer={4} mobile={16}>
            {isEdit ? (
              modelText
            ) : (
              <div>
                <label>{polyglot.t("assetForm.model")}</label> <p> {model} </p>
              </div>
            )}
          </Grid.Column>

          <Grid.Column tablet={8} computer={4} mobile={16}>
            <label>{polyglot.t("assetForm.subModel")}</label>
            {isEdit ? subModelText : <p> {subModel} </p>}
          </Grid.Column>

          {/* <Grid.Column tablet={8} computer={4} mobile={16}>
            <label>{polyglot.t("assetForm.year")}</label>
            {isEdit ? yearText : <p> {year} </p>}
          </Grid.Column> */}

          <Grid.Column tablet={8} computer={4} mobile={16}>
            {isEdit ? (
              yearText
            ) : (
              <div>
                {" "}
                <label>{polyglot.t("assetForm.year")}</label>
                <p> {asset.year}</p>
              </div>
            )}
          </Grid.Column>

          <Grid.Column tablet={8} computer={4} mobile={16}>
            <label>{polyglot.t("assetForm.engineType")}</label>
            {isEdit ? engineTypeText : <p> {engineType} </p>}
          </Grid.Column>

          <Grid.Column tablet={8} computer={4} mobile={16}>
            <label>{polyglot.t("assetForm.serialNumber")}</label>
            {isEdit ? serialNumberText : <p> {serialNumber} </p>}
          </Grid.Column>

          <Grid.Column tablet={8} computer={4} mobile={16}>
            <label>{polyglot.t("assetForm.customerPlantNo")}</label>
            {isEdit ? customerPlantNoText : <p> {customerPlantNo} </p>}
          </Grid.Column>

          <Grid.Column tablet={8} computer={4} mobile={16}>
            <label>{polyglot.t("assetForm.modifications")}</label>
            {isEdit ? modificationText : <p> {modifications} </p>}
          </Grid.Column>

          <Grid.Column tablet={8} computer={4} mobile={16}>
            <label>{polyglot.t("assetForm.linkedERP")}</label>
            {isEdit ? linkedERPText : <p> {linkedERP} </p>}
          </Grid.Column>

          {/* {!asset.isLinkedAsset ? (
            <Grid.Column tablet={8} computer={4} mobile={16}>
              <label>{polyglot.t("assetForm.crewName")}</label>
              {isEdit ? (
                crewNameText
              ) : asset.crew ? (
                <p> {asset.crew.name} </p>
              ) : null}
            </Grid.Column>
          ) : null} */}

          {!asset.isLinkedAsset ? (
            <Grid.Column tablet={8} computer={4} mobile={16}>
              {isEdit ? (
                crewNameText
              ) : asset.crew ? (
                <div>
                  <label>{polyglot.t("assetForm.crewName")}</label>
                  <p> {asset.crew.name} </p>
                </div>
              ) : null}
            </Grid.Column>
          ) : null}

          {!asset.isLinkedAsset ? (
            <Grid.Column tablet={8} computer={4} mobile={16}>
              {isEdit ? (
                operatorNameText
              ) : asset.operator ? (
                <div>
                  <label>{polyglot.t("assetForm.operator")}</label>
                  <p> {asset.operator.name} </p>
                </div>
              ) : null}
            </Grid.Column>
          ) : null}

          {!asset.isLinkedAsset ? (
            <Grid.Column tablet={8} computer={4} mobile={16}>
              {isEdit ? (
                locationNameText
              ) : (
                <div>
                  {" "}
                  <label>{polyglot.t("assetForm.location")}</label>
                  <p> {asset.location} </p>
                </div>
              )}
            </Grid.Column>
          ) : null}

          <Grid.Column tablet={8} computer={4} mobile={16}>
            {isEdit ? (
              maintenanceUnitText
            ) : (
              <div>
                {" "}
                <label>{polyglot.t("assetForm.kilometer")}</label>
                <p> {asset.maintenanceUnit} </p>
              </div>
            )}
          </Grid.Column>

          <Grid.Column width={8} computer={8} mobile={16}>
            {isImageLoading && <Loader text="Loading image, please wait" />}
            <Form.Field>
              {assetImage && <label>Edit/Upload Asset Images</label>}
              {assetImage && !isEdit && <ViewImage imageUrl={existingImages} />}
              {isEdit && assetImage ? (
                <React.Fragment>
                  <ViewImage
                    imageUrl={assetImage}
                    deleteImage={this.deleteParentImage}
                  />
                  <Button
                    basic
                    color="blue"
                    content="Image"
                    size="mini"
                    type="button"
                    icon="plus"
                    textAlign="right"
                    disabled={assetImage.length >= 3}
                    onClick={() => this.setState({ isParentImage: true })}
                  />
                  <p style={{ fontStyle: "italic", fontSize: "12px" }}>
                    Note : Upto 3 images only
                  </p>
                </React.Fragment>
              ) : null}
              {assetImage.length >= 3 ? (
                <p>You can add 3 images only.</p>
              ) : null}
            </Form.Field>
          </Grid.Column>
          <Grid.Column>
            <AddImage
              isImage={isParentImage}
              closePage={this.closeModal}
              uploadImages={this.uploadParentImage}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allLocations: state.assetLocation.allLocations,
    response: state.assetForm.response,
    assetList: state.assetForm.assetList,
    assetType: state.assetForm.assetType,
    allCrew: state.crew.allCrew,
    assets: state.assets.assets,
    users: state.crew.users,
    forms: state.forms,
    assetImageResult: state.assetForm.assetImageUrl
  };
};

const reducer = { ...assetFormReducer, ...locationReducer, ...crewReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssetInfo)
);
