import _ from "lodash";
import React from "react";
import polyglot from "../../i18n";
import PropTypes from "prop-types";
import { Control, Errors } from "react-redux-form";
import { Grid, Form, Button, Segment, List } from "semantic-ui-react";

const CheckPoints = ({ manageCheckPoints, forms, addMoreValue, editCheckPointName, editName }) => {
  const { maintenanceForm, checkPointForm } = forms;
  const { data } = maintenanceForm;

  const addNewCheckPoint = () => {
    const { field, section } = checkPointForm;
    if (!field || !section) {
      return;
    }
    manageCheckPoints("add");
  };


  const sectioned = _.groupBy(data, "section");
  const CheckPointList = (
    <div>
      {sectioned &&
        _.map(sectioned, (point, c_key) => {
          return (
            <Segment key={c_key}>
              <h4 className="section">
                {c_key}
                <Button
                  floated="right"
                  basic
                  color="blue"
                  icon="plus"
                  size="mini"
                  type="button"
                  onClick={() => addMoreValue(c_key)}
                />
              </h4>
              <List divided verticalAlign="middle">
                {_.map(point, (item, item_key) => {
                  return (
                    <List.Item key={item_key + item.field}>
                      <Grid>
                        <Grid.Column width={10}>{item.field}</Grid.Column>
                        <Grid.Column width={6} textAlign="right">
                          <Button
                            basic
                            color="blue"
                            icon="pencil"
                            size="mini"
                            type="button"
                            onClick={() => editCheckPointName(item._id, item.section, item.field)}
                          />
                          <Button
                            basic
                            color="red"
                            icon="delete"
                            size="mini"
                            type="button"
                            onClick={() => manageCheckPoints("remove", item)}
                          />

                        </Grid.Column>
                      </Grid>
                    </List.Item>
                  );
                })}
              </List>
            </Segment>
          );
        })}
    </div>
  );

  const section = (
    <Form.Field required>
      <label>{polyglot.t("maintenanceForm.label.section")}</label>
      <Control
        type="text"
        model="forms.checkPointForm.section"
        placeholder={polyglot.t("maintenanceForm.label.section")}
        validateOn="change"
        className="input-box"
      />
      <Errors
        style={{ color: "red" }}
        model="forms.checkPointForm.section"
        show="touched"
        messages={{
          valueMissing: polyglot.t("maintenanceForm.label.required.section")
        }}
      />
    </Form.Field>
  );

  const name = (
    <Form.Field required>
      <label>{polyglot.t("maintenanceForm.label.name")}</label>
      <Control
        type="text"
        model="forms.checkPointForm.field"
        placeholder={polyglot.t("maintenanceForm.label.name")}
        validateOn="change"
        className="input-box"
      />
      <Errors
        style={{ color: "red" }}
        model="forms.checkPointForm.field"
        show="touched"
        messages={{
          valueMissing: polyglot.t("maintenanceForm.label.required.name")
        }}
      />
    </Form.Field>
  );

  const addButton = (
    <Button
      secondary
      content="Add"
      size="small"
      className="button-float"
      type="button"
      onClick={() => addNewCheckPoint()}
    />
  );

  return (
    <Segment className="check-points">
      <h4 className="header"> Check Points</h4>
      <Grid className="form-panel">
        <Grid.Column computer={6} tablet={6} mobile={16}>
          {section}
        </Grid.Column>
        <Grid.Column computer={6} tablet={6} mobile={16}>
          {name}
        </Grid.Column>
        <Grid.Column computer={2} tablet={4} mobile={16} className="add-button">
          {addButton}
        </Grid.Column>
        <Grid.Column width={16}>{CheckPointList}</Grid.Column>
      </Grid>
    </Segment>
  );
};
CheckPoints.propTypes = {
  manageCheckPoints: PropTypes.func,
  addMoreValue: PropTypes.func,
  forms: PropTypes.object,
  editCheckPointName: PropTypes.func,
  editName: PropTypes.bool
};

export default CheckPoints;
