import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { Grid, Form, Icon} from "semantic-ui-react";
//import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";

const DateRagePicker = ({ startDate, endDate, setStartDate, setEndDate }) => {
  startDate = startDate ? moment(startDate, "DD/MM/YYYY") : null;
  endDate = endDate ? moment(endDate, "DD/MM/YYYY") : null;
  const handleChangeStart = date => {
    const newDate = moment(date, "DD/MM/YYYY");

    setStartDate(newDate);
  };
  const handleChangeEnd = date => {
    const newDate = moment(date, "DD/MM/YYYY");
    setEndDate(newDate);
  };

  return (
    <Grid columns="equal">
      <Grid.Column computer={8} mobile={16} tablet={8}>
        <Form>
          <Form.Field>
            <label><Icon name="calendar" />From date</label>
            <DatePicker
              utcOffset={-4}
              dateFormat="DD/MM/YYYY"
              placeholderText="From date"
              selected={startDate}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              onChange={handleChangeStart}
              maxDate={endDate}
              showDisabledMonthNavigation
            />
          </Form.Field>
        </Form>
      </Grid.Column>
      <Grid.Column computer={8} mobile={16} tablet={8}>
        <Form>
          <Form.Field>
          <label><Icon name="calendar" />To date</label>
            <DatePicker
              utcOffset={-4}
              dateFormat="DD/MM/YYYY"
              placeholderText="To date"
              selected={endDate}
              onChange={handleChangeEnd}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              showDisabledMonthNavigation
            />
          </Form.Field>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default DateRagePicker;
