import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import {
  Button,
  Card,
  Dimmer,
  Form,
  Grid,
  Icon,
  Input,
  Loader,
  Modal,
} from "semantic-ui-react";
import { getFileExtension, removeFileExtension } from "../../../utils/utils";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import {
  apiDeleteAssetLocationRS23File,
  apiGetAssetLocationRS23Files,
  apiUploadAssetLocationRS23File,
} from "../../MyAssets/myAssets.api";

export default function AssetLocationRS23(props) {
  const [categories, setCategories] = useState([]);
  const [openUploadFile, setOpenUploadFile] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({});
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [deleteFileOpen, setDeleteFileOpen] = useState(false);

  // Function to handle mouse enter event
  const handleMouseEnter = (categoryId) => {
    setHoveredCard(categoryId); // Set the hovered card ID
  };

  // Function to handle mouse leave event
  const handleMouseLeave = () => {
    setHoveredCard(null); // Reset hovered card ID
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      if (fileName === "") setFileName(removeFileExtension(file.name));
      // Handle the selected file here (e.g., upload to server)
    }
  };

  const handleDownload = (file) => {
    if (!file.filePath) {
      showErrorToast("Save asset first before downloading this file");
      return false;
    }
    try {
      fetch(file.filePath)
        .then((response) => response.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = file.fileDisplayName;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
    } catch {
      showErrorToast("Something went wrong");
    }
  };

  const getRS23Files = async () => {
    setIsLoading(true);
    const data = await apiGetAssetLocationRS23Files(props.assetLocationId);
    if (data) {
      setCategories(data);
      setIsLoading(false);
      setOpenUploadFile(false);
      setFile(null);
      setFileName("");
      setSelectedFile(null);
      setDeleteFileOpen(false);
    } else {
      setIsLoading(false);
    }
  };

  const onUploadedRS23Files = async () => {
    setIsLoading(true);
    if (file) {
      const extension = "." + getFileExtension(file.name);
      const renamedFile = new File([file], fileName + extension, {
        type: file.type,
      });
      const result = await apiUploadAssetLocationRS23File(
        fileName ? renamedFile : file,
        props.assetLocationId,
        selectedCategory.categoryId
      );
      if (result) {
        showSuccessToast(
          <div>
            <i className="check icon" /> File Uploaded Successfully
          </div>
        );
        getRS23Files();
      } else {
        showErrorToast("Something went wrong");
        setIsLoading(false);
      }
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const onDeleteRS23Files = async (id) => {
    setIsLoading(true);
    const result = await apiDeleteAssetLocationRS23File(id);
    if (result) {
      showSuccessToast(
        <div>
          <i className="check icon" /> File Deleted Successfully
        </div>
      );
      getRS23Files();
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.assetLocationId) getRS23Files();
  }, [props.assetLocationId]);

  return (
    <Modal open={true} size="small" closeOnDimmerClick={false}>
      <Modal.Header>
        <div style={{ paddingTop: 10, paddingBottom: 5 }}>
          <span style={{ fontSize: 24, fontWeight: "normal" }}>
            RS23 Documents
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <Modal
          size="tiny"
          onClose={() => {
            setOpenUploadFile(false);
          }}
          onOpen={() => setOpenUploadFile(true)}
          open={openUploadFile}
          closeOnDimmerClick={false}
        >
          {isLoading ? (
            <Dimmer active inverted>
              <Loader size="large"></Loader>
            </Dimmer>
          ) : null}
          <Modal.Header>RS23 Upload File</Modal.Header>
          <Modal.Content
            style={{
              paddingTop: 10,
              paddingLeft: 30,
              paddingRight: 30,
              paddingBottom: 15,
              fontSize: 13,
            }}
          >
            <Grid style={{ margin: 0, marginTop: 10 }}>
              <Grid.Column width={16} style={{ padding: 0 }}>
                <Form>
                  <Form.Field required>
                    <label style={{ fontSize: 13, fontWeight: "bold" }}>
                      Preferred File Name
                    </label>
                    <Input
                      value={fileName}
                      style={{ width: "100%", marginTop: 3 }}
                      onChange={(e, data) => setFileName(data.value)}
                    />
                  </Form.Field>
                  <Form.Field>
                    <label
                      style={{
                        fontSize: 13,
                        fontWeight: "bold",
                      }}
                    >
                      RS23 Category
                    </label>
                    <Input
                      style={{
                        width: "100%",
                        pointerEvents: "none",
                        marginTop: 3,
                      }}
                      value={selectedCategory.categoryName}
                    />
                  </Form.Field>
                  <Form.Field style={{ marginTop: 20, marginBottom: 15 }}>
                    {file ? (
                      <Card
                        style={{
                          cursor: "default",
                          height: 44,
                          width: "100%",
                          borderRadius: 10,
                          marginBottom: "0px !important",
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3)",
                        }}
                      >
                        <Card.Content
                          style={{
                            minHeight: 60,
                            paddingLeft: 15,
                            paddingTop: 8,
                          }}
                        >
                          <Card.Description
                            style={{
                              fontSize: 11,
                              padding: 0,
                              cursor: "pointer",
                              color: "#000",
                            }}
                          >
                            <Grid>
                              <Grid.Column width={1}>
                                <Icon
                                  name="file alternate outline"
                                  style={{ fontSize: 22 }}
                                />
                              </Grid.Column>
                              <Grid.Column
                                width={13}
                                style={{
                                  paddingTop: 20,
                                  paddingLeft: 20,
                                  paddingRight: 0,
                                  overflow: "auto",
                                  wordWrap: "break-word",
                                }}
                              >
                                {file.name}
                              </Grid.Column>
                              <Grid.Column
                                width={1}
                                style={{ paddingTop: 17.5 }}
                              >
                                <Icon
                                  name="trash"
                                  style={{
                                    cursor: "pointer",
                                    color: "red",
                                    fontSize: 16,
                                  }}
                                  onClick={() => {
                                    setFile(null);
                                  }}
                                />
                              </Grid.Column>
                            </Grid>
                          </Card.Description>
                        </Card.Content>
                      </Card>
                    ) : (
                      <>
                        <Button
                          style={{
                            width: "100%",
                            backgroundColor: "#ffad00",
                            color: "#fff",
                          }}
                          size="small"
                          onClick={handleButtonClick}
                        >
                          Select file
                        </Button>
                        <input
                          ref={fileInputRef}
                          type="file"
                          hidden
                          onChange={handleFileChange}
                        />
                      </>
                    )}
                  </Form.Field>
                </Form>
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions
            style={{
              textAlign: "left",
              paddingLeft: 30,
              paddingRight: 30,
            }}
          >
            <Button
              color="youtube"
              onClick={() => {
                setFile(null);
                setFileName("");
                setOpenUploadFile(false);
              }}
              style={{ width: "47.5%", margin: 0, marginRight: 24 }}
            >
              Cancel
            </Button>
            <Button
              disabled={!file}
              style={{
                backgroundColor: "rgb(102, 204, 35)",
                color: "#fff",
                width: "47.5%",
                margin: 0,
              }}
              onClick={() => onUploadedRS23Files()}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
        <Modal size="mini" open={deleteFileOpen}>
          <div style={{ position: "absolute", bottom: 100, left: 150 }}>
            {isLoading ? (
              <Loader
                active
                className="workaround"
                size="large"
                inline="centered"
              />
            ) : null}
          </div>
          <Modal.Header>Confirmation</Modal.Header>
          <Modal.Content
            style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
          >
            <p>
              Are you sure you want to delete
              <br />
              {selectedFile?.fileDisplayName}?
            </p>
          </Modal.Content>
          <Modal.Actions>
            <Button color="youtube" onClick={() => setDeleteFileOpen(false)}>
              NO
            </Button>
            <Button
              style={{
                backgroundColor: "rgb(102, 204, 35)",
                color: "#fff",
              }}
              onClick={() => onDeleteRS23Files(selectedFile.fileId)}
            >
              YES
            </Button>
          </Modal.Actions>
        </Modal>
        {isLoading ? (
          <Dimmer active inverted>
            <Loader size="large"></Loader>
          </Dimmer>
        ) : null}
        <div
          className="job-list-scroll"
          style={{ minHeight: 450, paddingRight: 15, color: "#6d6d6d" }}
        >
          <Grid style={{ marginBottom: 20 }}>
            <Grid.Column width={4} style={{ paddingBottom: 0 }}>
              <span style={{ fontWeight: "bold" }}>Location Name:</span>
            </Grid.Column>
            <Grid.Column
              width={12}
              style={{ paddingBottom: 0, textAlign: "right", color: "#000" }}
            >
              <span>{props.assetLocationData?.locationName}</span>
            </Grid.Column>
            <Grid.Column width={4} style={{ paddingTop: 12 }}>
              <span style={{ fontWeight: "bold" }}>Address:</span>
            </Grid.Column>
            <Grid.Column
              width={12}
              style={{ paddingTop: 12, textAlign: "right", color: "#000" }}
            >
              <span>{props.assetLocationData?.locationAddress}</span>
            </Grid.Column>
          </Grid>
          {categories?.map((category) => (
            <div style={{ marginBottom: 40 }}>
              <span style={{ fontSize: 17, fontWeight: "bold" }}>
                {category.categoryName}
              </span>
              <div
                style={{
                  minHeight: 60,
                  paddingTop: 20,
                  paddingBottom: 25,
                  paddingLeft: 16,
                  width: "100%",
                  borderTop: "5px solid #66cc23",
                }}
              >
                <Grid>
                  {category.uploadedFiles?.map((data) => (
                    <Grid.Column
                      width={5}
                      style={{
                        padding: 0,
                        margin: 0,
                        paddingRight: 15,
                        marginTop: 15,
                      }}
                    >
                      <Card
                        style={{
                          cursor: "default",
                          minHeight: 60,
                          maxHeight: 60,
                          minWidth: 155,
                          borderRadius: 10,
                          marginBottom: "0px !important",
                          boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3)",
                          position: "relative", // Ensure relative positioning
                          filter:
                            hoveredCard === data.fileId
                              ? "brightness(0.3)"
                              : "brightness(1)",
                        }}
                        onMouseEnter={() => handleMouseEnter(data.fileId)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <Card.Content
                          style={{
                            minHeight: 60,
                            paddingLeft: 5,
                            paddingTop: 10,
                          }}
                        >
                          <Card.Description
                            style={{
                              fontSize: 11,
                              padding: 0,
                              cursor: "pointer",
                              color: "#000",
                            }}
                          >
                            <Grid>
                              <Grid.Column width={4}>
                                <Icon
                                  name="file alternate outline"
                                  style={{ fontSize: 28 }}
                                />
                              </Grid.Column>
                              <Grid.Column
                                width={11}
                                style={{
                                  paddingTop: 20,
                                  paddingLeft: 10,
                                  paddingRight: 0,
                                  overflow: "auto",
                                  wordWrap: "break-word",
                                }}
                              >
                                {data?.fileDisplayName}
                              </Grid.Column>
                            </Grid>
                          </Card.Description>
                        </Card.Content>
                      </Card>
                      {hoveredCard === data.fileId && (
                        <div
                          className="hidden-button"
                          onMouseEnter={() => handleMouseEnter(data.fileId)}
                          onMouseLeave={handleMouseLeave}
                        >
                          <Button
                            style={{
                              backgroundColor: "#66cc23",
                              color: "#fff",
                              marginRight: 10,
                            }}
                            size="mini"
                            onClick={() => {
                              handleDownload(data);
                            }}
                          >
                            View
                          </Button>
                          {props.userType === 4 ||
                          props.userType === 7 ||
                          props.userType === 10 ? null : (
                            <Button
                              color="youtube"
                              size="mini"
                              onClick={() => {
                                setSelectedFile(data);
                                setDeleteFileOpen(true);
                              }}
                            >
                              Delete
                            </Button>
                          )}
                        </div>
                      )}
                    </Grid.Column>
                  ))}
                  {props.userType === 4 ||
                  props.userType === 7 ||
                  props.userType === 10 ? null : (
                    <Grid.Column
                      width={5}
                      style={{
                        padding: 0,
                        margin: 0,
                        paddingRight: 15,
                        marginTop: 15,
                      }}
                    >
                      <div
                        className="file-uploader"
                        style={{ display: "inline !important" }}
                        onClick={() => {
                          setFileName("");
                          setSelectedCategory(category);
                          setOpenUploadFile(true);
                        }}
                      >
                        <label
                          className="file-upload-button2"
                          style={{ paddingTop: 11, color: "#afabab" }}
                        >
                          <Icon name="plus" size="big" />
                        </label>
                      </div>
                    </Grid.Column>
                  )}
                </Grid>
              </div>
            </div>
          ))}
        </div>
      </Modal.Content>
      <Modal.Actions
        disabled={isLoading}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <Button
          color="grey"
          size="big"
          onClick={() => {
            props.setViewRS23(false);
          }}
          style={{ width: "100%", marginLeft: 0 }}
          disabled={isLoading}
        >
          CLOSE
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
