import React from "react";
import PropTypes from "prop-types";
import FittingInfo from "./FittingInfo";

const LeftFittingInfo = ({ leftFittingInfo = {}, removeFitting, angle }) => {
  return (
    <React.Fragment>
      <FittingInfo
        fittingName="Left Fitting"
        fittingInfo={leftFittingInfo}
        type="left"
        removeFitting={removeFitting}
        angle={angle}
      />
    </React.Fragment>
  );
};

LeftFittingInfo.prototype = {
  leftFittingInfo: PropTypes.object,
  removeFitting: PropTypes.func,
  angle: PropTypes.number
};

export default LeftFittingInfo;
