import React, { useEffect, useState, useRef } from "react";
import "./hoseMap.css";
import {
  Dropdown,
  Form,
  Grid,
  Image as ImageContainer,
  Input,
  Button,
  Header,
  Icon,
  Loader,
  Card,
  Modal,
} from "semantic-ui-react";
import MetaData from "../../components/MetaData";
import rightArrow from "../../../src/assets/images/right-transparent.png";
import dtArrow from "../../../src/assets/images/dt-arrow-edit.png";
import "react-loading-skeleton/dist/skeleton.css";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import assetIcon from "../../assets/images/user-type-1.svg";
import emptyImage from "../../../src/assets/images/empty.png";
import {
  apiGetAsset,
  apiGetDTLocationsList,
  apiGetHosePositions,
} from "../MyAssets/myAssets.api";
import HoseMapImageUpload from "./imageUploader";
import Draggable from "react-draggable";
import {
  apiAddDTHoseLocation,
  apiAddHoseLocationImage,
  apiDeleteHoseEnd,
  apiDeleteHoseLocation,
  apiDeleteHoseLocationImage,
  apiGetDTImages,
  apiGetHoseEnds,
  apiHoseRecognition,
  apiSaveHoseEnds,
  apiUpdateHoseEnds,
  apiUpdateTHoseLocation,
} from "./hoseMap.api";
import LocationImageUploader from "./locationImageUploader";
import HoseMakerDT from "../HoseMakerDT";
import PairHoseEnd from "../PairHoseEnd";

const HoseMap = (props) => {
  const [selectedData, setSelectedData] = useState(null);
  const [assetType, setAssetType] = useState("");
  const [assetImage, setAssetImage] = useState("");
  const [imageLoading, setImageLoading] = useState(false);
  const [data, setData] = useState({
    id: 0,
    locationName: "",
    isPublic: false,
    companyId: null,
    assetId: 231,
    assetDbId: "fa108acb-b6fe-4dca-afab-0d71c17acb71",
    equipmentTypeId: null,
    areaId: null,
    areaName: "",
    locationX: 0.5,
    locationY: 0.5,
    createdBy: null,
    lastUpdatedBy: null,
    lastUpdatedDateTime: null,
    isDeleted: false,
    deletedBy: null,
    deletedDateTime: null,
  });
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [assetLocations, setAssetLocations] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [addHoseLocation, setAddHoseLocation] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [locationImages, setLocationImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(0);
  const [selectedImageData, setSelectedImageData] = useState(null);
  const [imageName, setImageName] = useState("");
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteOpenLocation, setDeleteOpenLocation] = useState(false);
  const [hoseEndsLoading, setHoseEndsLoading] = useState(false);
  const [selectedHoseEndId, setSelectedHoseEndId] = useState(null);
  const [selectedHoseEndData, setSelectedHoseEndData] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  //Draggable add hose location
  const parentSize = 464; // 472 - 8 for the border
  const draggableSize = 5;
  const [position, setPosition] = useState({ x: 220, y: 220 });

  // Hose Recognition Manual Drawing
  const [canStartDrawing, setCanStartDrawing] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const [endPoint, setEndPoint] = useState({ x: 0, y: 0 });
  const [drawingData, setDrawingData] = useState({
    id: 0,
    seq_id: 0,
    xmin: 0,
    ymin: 0,
    width: 0,
    height: 0,
  });
  const containerRef = useRef(null);
  const canvasRef = useRef(null);
  const imageRef = useRef(null);

  // Hose Recognition
  const [hoseRecognitions, setHoseRecognitions] = useState([]);
  const [selectedHoseEnd, setSelectedHoseEnd] = useState(null);
  const [selectedHoseEndIndex, setSelectedHoseEndIndex] = useState(null);
  const [loadingHoseRecognition, setLoadingHoseRecognition] = useState(false);

  // Hose Maker
  const [openHoseMaker, setOpenHoseMaker] = React.useState(false);

  // Pair Hose End
  const [openPairHoseEnd, setOpenPairHoseEnd] = React.useState(false);

  useEffect(() => {
    const image = locationImages[selectedImage];
    if (image) {
      getHoseEnds();
      setImageName(image.imageName ? image.imageName : "");
    } else {
      setImageName("");
    }
  }, [selectedImage]);

  const handleMouseDown = (e) => {
    if (canStartDrawing) {
      const { offsetX, offsetY } = e.nativeEvent;

      // Store the initial mouse position
      if (offsetX !== 0 && offsetY !== 0)
        setStartPoint({ x: offsetX, y: offsetY });

      // Set a flag to indicate that drawing has started but not yet confirmed by mouse movement
      setIsDrawing("pending");
    }
  };

  const handleMouseMove = (e) => {
    if (!canStartDrawing || !isDrawing) return;
    if (isDrawing === "pending") {
      // Drawing is pending and mouse is moving, confirm drawing
      setIsDrawing(true);
    }
    const { offsetX, offsetY } = e.nativeEvent;
    setEndPoint({ x: offsetX, y: offsetY });
  };

  const handleMouseUp = () => {
    if (canStartDrawing) {
      // If drawing hasn't started yet due to lack of mouse movement, cancel drawing
      if (isDrawing === "pending") {
        setIsDrawing(false);
      } else {
        // Drawing has started and mouse is released, end drawing
        setIsDrawing(false);
      }
    }
  };

  const handleMouseLeave = () => {
    if (canStartDrawing && isDrawing) {
      setIsDrawing(false);
    }
  };

  const handleStartDrawing = () => {
    setCanStartDrawing(true);
  };

  const handleCancelDrawing = () => {
    setIsDrawing(false);
    setStartPoint({ x: 0, y: 0 });
    setEndPoint({ x: 0, y: 0 });

    // Clear the drawing canvas
    const drawingCanvas = canvasRef.current;
    if (drawingCanvas) {
      const drawingContext = drawingCanvas.getContext("2d");
      drawingContext.clearRect(0, 0, drawingCanvas.width, drawingCanvas.height);
    }
  };

  const handleSaveDrawing = () => {
    let hoseEnds = [];
    let seq = 1;
    hoseEnds = hoseRecognitions?.length > 0 ? hoseRecognitions : [];
    drawingData.id = 0;
    drawingData.assetId = formData.id;
    hoseEnds.push(drawingData);
    let sortedHoseEnds = hoseEnds.map((item, i) => {
      item.pairedHoseEndData = null;
      item.sequenceId = null;
      if (!item.isRemoved) {
        item.sequenceId = seq;
        item.seq_id = seq;
        seq += 1;
      }
      return item;
    });
    setHoseRecognitions(sortedHoseEnds);
    handleCancelDrawing();
    setCanStartDrawing(false);
  };

  const drawRectangle = () => {
    if (endPoint.x === 0 || endPoint.y === 0) return false;
    if (!startPoint || !endPoint) return false;
    const context = canvasRef?.current?.getContext("2d");

    // Calculate width and height
    let width = endPoint.x - startPoint.x;
    let height = endPoint.y - startPoint.y;

    // Adjust start point if width or height are negative
    let startX = startPoint.x;
    let startY = startPoint.y;
    if (width < 0) {
      startX = endPoint.x;
      width = Math.abs(width);
    }
    if (height < 0) {
      startY = endPoint.y;
      height = Math.abs(height);
    }

    // Set canvas dimensions
    canvasRef.current.width = 354;
    canvasRef.current.height = 354;

    // Clear the canvas and redraw the image
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);

    // Draw the rectangle with red border and transparent background
    context.strokeStyle = "#FFA800";
    context.lineWidth = 3; // Adjust the border width as needed
    context.fillStyle = "rgba(255, 0, 0, 0)";
    context.fillRect(startX, startY, width, height);
    context.strokeRect(startX, startY, width, height);

    const ratioX = startX / 354;
    const ratioY = startY / 354;
    const ratioWidth = width / 354;
    const ratioHeight = height / 354;
    console.log("Ratio X:", ratioX.toFixed(2), "Ratio Y:", ratioY.toFixed(2));

    const delayDebounceFn = setTimeout(() => {
      const drawingRectangleData = {
        id: 0,
        xmin: ratioX.toFixed(2),
        ymin: ratioY.toFixed(2),
        width: ratioWidth.toFixed(2),
        height: ratioHeight.toFixed(2),
        isRemoved: false,
      };
      setDrawingData(drawingRectangleData);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  };

  // Hose Recognition Drawing End

  const handleDrag = (e, ui) => {
    const { x, y } = ui;
    const ratioX = x / (parentSize - draggableSize);
    const ratioY = y / (parentSize - draggableSize);
    setPosition({ x, y });
    handleChange("location", ui);
  };

  function handleChange(dataType, value) {
    let newState = [];
    newState.push(data);
    let hoseData = newState.map((item, i) => {
      if (i == 0) {
        if (dataType === "areaId") {
          item.areaName = getPositionName(value);
        }
        if (dataType === "location") {
          const ratioX = value.x / (parentSize - draggableSize);
          const ratioY = value.y / (parentSize - draggableSize);
          item.locationX = Number(ratioX.toFixed(2));
          item.locationY = Number(ratioY.toFixed(2));
          return item;
        }
        return { ...item, [dataType]: value };
      }

      return item;
    });

    console.log(hoseData[0]);
    setData(hoseData[0]);
  }

  function handleHoseEndChange(dataType, value, index) {
    let newState = hoseRecognitions;
    let seq = 1;
    let data = newState.map((item, i) => {
      if (i === index) {
        return { ...item, [dataType]: value };
      }
      return item;
    });
    let sortedData = data.map((item, i) => {
      item.sequenceId = null;
      if (!item.isRemoved) {
        item.sequenceId = seq;
        item.seq_id = seq;
        seq += 1;
      }
      return item;
    });
    setHoseRecognitions(sortedData);
  }

  // Hose Maker Functions

  const onCancelHoseMaker = () => {
    setOpenHoseMaker(false);
  };

  // Pair Hose End.Functions

  const onCancelPairHoseEnd = () => {
    const selectedId = localStorage.getItem("selectedAssetId");
    if (selectedId != null) {
      getDTLocationsData(selectedId);
    }
    setOpenPairHoseEnd(false);
  };

  const addBOM = (bomDetails) => {
    let newState = [];
    newState = hoseRecognitions;
    let bomData = newState.map((item, i) => {
      if (i === selectedHoseEndIndex) {
        item.hoseProductId = bomDetails.step1_Hose;
        item.hoseSku = bomDetails.step1_Barcode;
        item.hoseName = bomDetails.step1_Name;
        item.fittingProductId = bomDetails.step2_LHF;
        item.fittingSku = bomDetails.step2_Barcode;
        item.fittingName = bomDetails.step2_Name;
        item.ferruleProductId = bomDetails.step3_LFerrule;
        item.ferruleSku = bomDetails.step3_Barcode;
        item.ferruleProductName = bomDetails.step3_Name;
        item.otherItems =
          bomDetails.otherItems?.length > 0 ? bomDetails.otherItems : null;
        item.oal = bomDetails.oAL;
        return item;
      }
      return item;
    });

    setHoseRecognitions(bomData);
    setTimeout(function () {
      onUpdateHoseAssembly();
    }, 1000);
  };

  const connectHoseEnd = (hoseEndId, hoseEndAssetId) => {
    let hoseEndData = [];
    let updatedHoseEndId = 0;

    hoseRecognitions.map((item, i) => {
      if (i === selectedHoseEndIndex) {
        updatedHoseEndId = item.id;
        item.pairedHoseEnd = hoseEndId;
        item.pairedHoseEndAssetId = hoseEndAssetId;
        hoseEndData.push(item);
      }
    });
    hoseRecognitions.map((item, i) => {
      if (item.id === hoseEndId) {
        item.pairedHoseEnd = updatedHoseEndId;
        item.pairedHoseEndAssetId = hoseEndAssetId;
        hoseEndData.push(item);
      }
    });

    setTimeout(function () {
      onUpdatePairHoseEnd(hoseEndData);
    }, 1000);
  };

  const updateBOM = (bomDetails, index) => {
    let newState = [];
    newState.push(data);
    let jobData = newState.map((item, i) => {
      return item;
    });

    jobData[0].hoseMaker.map((item, i) => {
      if (index === i) {
        jobData[0].hoseMaker[i] = bomDetails;
      }
    });

    console.log(jobData[0]);
    setFormData(jobData[0]);
  };

  const getMyAssetsData = async (selectedId) => {
    setIsLoading(true);
    const result = await apiGetAsset(selectedId);
    if (result) {
      setFormData(result);
      setSelectedData(result);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const getDTLocationsData = async (selectedId) => {
    if (!formData.assetDbId) return false;
    setIsLoading(true);
    const result = await apiGetDTLocationsList(selectedId, formData.assetDbId);
    if (result) {
      result.map((location) => {
        location.assetDbId = selectedData.asset.assetDbId;
      });
      if (data.id)
        result.map((location) => {
          if (data.id === location.id) setData(location);
        });
      setAssetLocations(result);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  const getHosePositions = async () => {
    const assetImageData = localStorage.getItem("selectedAssetImage");
    setAssetImage(assetImageData);
    const hosePositions = await apiGetHosePositions(assetType);
    if (hosePositions) {
      setAssetImage(hosePositions.url);
      setLocationList(hosePositions.positions);
      setImageLoading(false);
    } else {
      setAssetImage("");
      setImageLoading(false);
    }
  };

  const getHoseEnds = async (dtImages = []) => {
    const imageId =
      dtImages.length > 0
        ? dtImages[selectedImage]?.id
        : locationImages[selectedImage]?.id;
    if (!imageId) return false;
    if (!formData.id) return false;
    setHoseRecognitions([]);
    setHoseEndsLoading(true);
    setSelectedHoseEnd(null);
    setSelectedHoseEndIndex(null);
    setSelectedHoseEndId(null);
    const hoseEnds = await apiGetHoseEnds(imageId, formData.id);
    if (hoseEnds) {
      if (Array.isArray(hoseEnds)) {
        hoseEnds.map((item, i) => {
          item.xmin = item.bboxLeftTopX;
          item.ymin = item.bboxLeftTopY;
          item.width = item.bboxWidth;
          item.height = item.bboxHeight;
          item.hoseEndBomData.otherItems = item.otherItems;
          item.isRemoved = false;
        });
        setHoseRecognitions(hoseEnds);
        setHoseEndsLoading(false);
      } else {
        hoseEnds.xmin = hoseEnds.bboxLeftTopX;
        hoseEnds.ymin = hoseEnds.bboxLeftTopY;
        hoseEnds.width = hoseEnds.bboxWidth;
        hoseEnds.height = hoseEnds.bboxHeight;
        setHoseRecognitions([hoseEnds]);
        setHoseEndsLoading(false);
      }
    } else {
      setHoseEndsLoading(false);
    }
  };

  const getPositionImage = (id) => {
    if (locationList.length > 0) {
      let position = {};
      position = locationList.find((location) => location.id === id);
      if (position) {
        return position.url ? position.url.replace(/ /g, "%20") : assetImage;
      }
    }
    return assetImage;
  };

  const getPositionName = (id) => {
    if (locationList.length > 0) {
      let position = {};
      position = locationList.find((location) => location.id === id);
      if (position) {
        return position.name ? position.name : "";
      }
    }
    return "";
  };

  const onSaveHoseLocation = async () => {
    if (data.areaId === null || data.locationName === "") {
      showErrorToast("Please complete all required fields");
      return false;
    }
    setIsLoading(true);
    try {
      let result = null;
      if (data.id === 0) {
        result = await apiAddDTHoseLocation(data);
      } else {
        result = await apiUpdateTHoseLocation(data);
      }

      if (result) {
        setData(result);
        setSelectedId(result.id);
        setSelectedImage(0);
        if (data.id === 0) {
          setIsSaved(true);
        } else {
          setAddHoseLocation(false);
        }
        const selectedId = localStorage.getItem("selectedAssetId");
        if (selectedId != null) {
          getDTLocationsData(selectedId);
        }
        showSuccessToast(
          <div>
            <i className="check icon" /> Hose Location Saved Successfully
          </div>
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const onConfirmHoseEnd = async () => {
    handleCancelDrawing();
    setCanStartDrawing(false);
    if (isSaved) {
      await onSaveHoseEnds();
    } else {
      await onUpdateHoseEnds(hoseRecognitions);
    }

    // await onSaveImage();
  };

  const onDeleteDTImage = async () => {
    setIsLoading(true);
    const imageId = locationImages[selectedImage]?.id;
    try {
      const result = await apiDeleteHoseLocationImage(imageId);
      if (result) {
        const assetId = localStorage.getItem("selectedAssetId");
        getDTLocationsData(assetId);
        getDTImages();
        showSuccessToast(
          <div>
            <i className="check icon" /> Image Deleted Successfully
          </div>
        );
        setSelectedImage(0);
        setIsLoading(false);
        setDeleteOpen(false);
        setDeleteOpenLocation(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onDeleteDTLocation = async () => {
    setIsLoading(true);
    const imageId = locationImages[selectedImage]?.id;
    try {
      const result = await apiDeleteHoseLocation(selectedId);
      if (result) {
        const assetId = localStorage.getItem("selectedAssetId");
        getDTLocationsData(assetId);
        const companyId = Number(localStorage.getItem("companyId"));
        const newData = {
          id: 0,
          locationName: "",
          isPublic: false,
          companyId: companyId,
          assetId: selectedData.asset.id,
          assetDbId: selectedData.asset.assetDbId,
          equipmentTypeId: null,
          areaId: null,
          areaName: "",
          locationX: 0.5,
          locationY: 0.5,
          createdBy: null,
          lastUpdatedBy: null,
          lastUpdatedDateTime: null,
          isDeleted: false,
          deletedBy: null,
          deletedDateTime: null,
        };
        setData(newData);
        setSelectedId(0);
        setSelectedImage(0);
        showSuccessToast(
          <div>
            <i className="check icon" /> Location Deleted Successfully
          </div>
        );
        setIsLoading(false);
        setDeleteOpen(false);
        setDeleteOpenLocation(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };

  const onSaveHoseEnds = async () => {
    setIsLoading(true);
    const assetId = localStorage.getItem("selectedAssetId");
    try {
      const imageResult = await apiAddHoseLocationImage(
        imageFile,
        assetId,
        selectedId
      );
      if (imageResult) {
        const result = await apiSaveHoseEnds(
          hoseRecognitions,
          assetId,
          selectedId,
          imageResult.id,
          imageName
        );
        if (result) {
          const selectedId = localStorage.getItem("selectedAssetId");
          if (selectedId != null) {
            getDTLocationsData(selectedId);
          }
          setTimeout(function () {
            getLatestDTImages();
            showSuccessToast(
              <div>
                <i className="check icon" /> Image and Hose Ends Confirmed
                Successfully
              </div>
            );
            setIsEdit(false);
            setIsSaved(false);
            setIsLoading(false);
          }, 1000);
        } else {
          setIsLoading(false);
          showErrorToast("Something went wrong");
        }
      } else {
        setIsLoading(false);
        showErrorToast("Something went wrong");
      }
    } catch (e) {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const onDeleteHoseEnds = async (hoseEnd) => {
    try {
      const response = await apiDeleteHoseEnd(hoseEnd.id);
      if (response) {
        return true;
      }
    } catch (error) {
      showErrorToast("Something went wrong");
    }
  };

  const onUpdateHoseEnds = async (hoseEndsData = []) => {
    setIsLoading(true);
    const imageId = locationImages[selectedImage]?.id;
    if (!imageId) return false;
    try {
      let removedHoseEnds = hoseEndsData.filter((item) => item.isRemoved);
      const deleteHoseEnds = removedHoseEnds.map((item) =>
        onDeleteHoseEnds(item)
      );
      let hoseEndsPayload = hoseEndsData.filter((item) => !item.isRemoved);
      hoseEndsPayload.map((item, i) => {
        item.sequenceId = i + 1;
      });
      await Promise.all(deleteHoseEnds);
      setTimeout(
        async function () {
          const result = await apiUpdateHoseEnds(
            hoseEndsPayload,
            selectedId,
            imageId,
            imageName
          );
          if (result) {
            const selectedId = localStorage.getItem("selectedAssetId");
            if (selectedId != null) {
              getDTLocationsData(selectedId);
            }
            getDTImages();
            getHoseEnds();
            setIsEdit(false);
            showSuccessToast(
              <div>
                <i className="check icon" /> Image and Hose Ends Updated
                Successfully
              </div>
            );
            setIsSaved(false);
            setIsLoading(false);
          } else {
            setIsLoading(false);
            showErrorToast("Something went wrong");
          }
        },
        removedHoseEnds > 0 ? 2000 : 500
      );
    } catch (e) {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const onUpdateHoseAssembly = async () => {
    setIsLoading(true);
    const assetId = localStorage.getItem("selectedAssetId");
    const imageId = locationImages[selectedImage]?.id;
    if (!imageId) return false;
    try {
      const result = await apiUpdateHoseEnds(
        hoseRecognitions,
        selectedId,
        imageId,
        imageName
      );
      if (result) {
        if (assetId != null) {
          getDTLocationsData(assetId);
        }
        getHoseEnds();
        setIsEdit(false);
        showSuccessToast(
          <div>
            <i className="check icon" /> Hose Assembly saved successfully
          </div>
        );
        setIsSaved(false);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        showErrorToast("Something went wrong");
      }
    } catch (e) {
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const onUpdatePairHoseEnd = async (hoseEndData = []) => {
    setIsLoading(true);
    const imageId = locationImages[selectedImage]?.id;
    if (!imageId) return false;
    try {
      const result = await apiUpdateHoseEnds(
        hoseEndData,
        selectedId,
        imageId,
        imageName
      );
      if (result) {
        const selectedId = localStorage.getItem("selectedAssetId");
        if (selectedId != null) {
          getDTLocationsData(selectedId);
        }
        getDTImages();
        setIsEdit(false);
        showSuccessToast(
          <div>
            <i className="check icon" /> Hose End paired successfully
          </div>
        );
        setIsSaved(false);
        setIsLoading(false);
      } else {
        getDTImages();
        setIsLoading(false);
        showErrorToast("Something went wrong");
      }
    } catch (e) {
      getDTImages();
      setIsLoading(false);
      showErrorToast("Something went wrong");
    }
  };

  const getDTImages = async () => {
    if (!formData.id) return false;
    setIsLoading(true);
    const dtImages = await apiGetDTImages(selectedId, formData.id);
    if (dtImages) {
      setLocationImages(dtImages);
      const img = new Image();
      img.src = dtImages[0]?.imageUrl;

      img.onload = () => {
        const canvas = canvasRef.current;
        if (canvas) {
          const context = canvas.getContext("2d");

          // Set canvas size based on the imageRef dimensions
          canvas.width = imageRef.current.width;
          canvas.height = imageRef.current.height;
        }
      };
      getHoseEnds(dtImages);
      if (selectedImage !== 0) {
        const image = dtImages[selectedImage];
        if (image) {
          setImageName(image.imageName ? image.imageName : "");
          setSelectedImageData(image);
        } else {
          setSelectedImageData(null);
        }
      } else {
        setImageName(dtImages[0]?.imageName);
        setSelectedImageData(dtImages[0]);
      }
      setIsLoading(false);
    } else {
      setLocationImages([]);
      setIsLoading(false);
    }
  };

  const getLatestDTImages = async () => {
    if (!formData.id) return false;
    const dtImages = await apiGetDTImages(selectedId, formData.id);
    if (dtImages) {
      setLocationImages(dtImages);
      setImageName(dtImages[dtImages.length - 1]?.imageName);
      setSelectedImage(dtImages.length - 1);
      setSelectedImageData(dtImages[dtImages.length - 1]);
      setImageFile(null);
      setImageUrl(null);
      getHoseEnds(dtImages);
    }
  };

  const addLocationImage = async (file) => {
    if (file) {
      if (file.size >= 25000000) {
        showErrorToast("File size cannot exceed 25MB.");
        setIsLoading(false);
      }
      hoseRecognitionData(file);
      setAddHoseLocation(false);
      setIsEdit(true);
      setIsSaved(true);
      setImageFile(file);
      setImageName("");
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const hoseRecognitionData = async (file) => {
    if (file) {
      setHoseRecognitions([]);
      setIsLoading(true);
      setLoadingHoseRecognition(true);
      try {
        const assetId = localStorage.getItem("selectedAssetId");
        const result = await apiHoseRecognition(file, assetId, selectedId);
        if (result) {
          setLoadingHoseRecognition(false);
          if (result.length === 0) {
            setHoseRecognitions([]);
            showErrorToast("No hose end identified");
            setIsLoading(false);
            return false;
          }
          setHoseRecognitions(result);
          setIsLoading(false);
        } else {
          setHoseRecognitions([]);
          setLoadingHoseRecognition(false);
          setIsLoading(false);
        }
      } catch {
        showErrorToast("No hose end identified");
        setLoadingHoseRecognition(false);
        setIsLoading(false);
        setHoseRecognitions([]);
      }
    }
  };

  const addNewHoseLocation = () => {
    const companyId = Number(localStorage.getItem("companyId"));
    const newData = {
      id: 0,
      locationName: "",
      isPublic: false,
      companyId: companyId,
      assetId: selectedData.asset.id,
      assetDbId: selectedData.asset.assetDbId,
      equipmentTypeId: null,
      areaId: null,
      areaName: "",
      locationX: 0.5,
      locationY: 0.5,
      createdBy: null,
      lastUpdatedBy: null,
      lastUpdatedDateTime: null,
      isDeleted: false,
      deletedBy: null,
      deletedDateTime: null,
    };
    setData(newData);
  };

  useEffect(() => {
    if (selectedId !== 0) {
      setIsEdit(false);
      getDTImages();
    }
  }, [selectedId]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      const selectedId = localStorage.getItem("selectedAssetId");
      if (selectedId != null && data.id === 0) {
        getMyAssetsData(selectedId);
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, []);

  useEffect(() => {
    if (formData) {
      const selectedId = localStorage.getItem("selectedAssetId");
      getDTLocationsData(selectedId);
    }
  }, [formData]);

  useEffect(() => {
    if (assetType) {
      getHosePositions();
    }
  }, [assetType]);

  useEffect(() => {
    if (selectedData) {
      if (assetType !== selectedData.assetDetails.equipmentTypeName) {
        if (
          selectedData.assetDetails.equipmentTypeName === null ||
          selectedData.assetDetails.equipmentTypeName === ""
        ) {
          setAssetImage("");
          setAssetType("");
        } else {
          setAssetType(
            selectedData.assetDetails.equipmentTypeName
              ? selectedData.assetDetails.equipmentTypeName
              : ""
          );
        }
      }
      if (
        selectedData.assetDetails?.otherAssetDetails !== null &&
        selectedData.assetDetails?.otherAssetDetails !== ""
      ) {
        const fullDescription =
          selectedData.assetDetails.otherAssetDetails.split(" | ");
        selectedData.makeName = fullDescription[0] ? fullDescription[0] : "";
        selectedData.equipmentTypeName = fullDescription[1]
          ? fullDescription[1]
          : "";
        selectedData.model = fullDescription[2] ? fullDescription[2] : "";
        selectedData.assetDetails.makeName = fullDescription[0]
          ? fullDescription[0]
          : "";
        selectedData.assetDetails.equipmentTypeName = fullDescription[1]
          ? fullDescription[1]
          : "";
        selectedData.assetDetails.model = fullDescription[2]
          ? fullDescription[2]
          : "";
        selectedData.makeId = 0;
      } else {
        selectedData.makeName = selectedData.assetDetails?.makeName;
        selectedData.equipmentTypeName =
          selectedData.assetDetails?.equipmentTypeName;
        selectedData.model = selectedData.assetDetails?.model;
        selectedData.equipmentTypeId =
          selectedData.assetDetails?.equipmentTypeId;
        selectedData.makeId = selectedData.assetDetails?.makeId;
      }
      const companyId = Number(localStorage.getItem("companyId"));
      const newData = {
        id: 0,
        locationName: "",
        isPublic: false,
        companyId: companyId,
        assetId: selectedData.asset.id,
        assetDbId: selectedData.asset.assetDbId,
        equipmentTypeId: null,
        areaId: null,
        areaName: "",
        locationX: 0.5,
        locationY: 0.5,
        createdBy: null,
        lastUpdatedBy: null,
        lastUpdatedDateTime: null,
        isDeleted: false,
        deletedBy: null,
        deletedDateTime: null,
      };
      setData(newData);
      const selectedFormData = {
        id: selectedData.asset.id,
        assetId: selectedData.asset.assetId,
        assetSerialNumber: selectedData.asset.assetSerialNumber,
        year: selectedData.asset.year,
        industry: selectedData.asset.industry,
        assetLocationId: selectedData.asset.assetLocationId,
        assetImageUrl: selectedData.asset.assetImageUrl,
        operator: selectedData.asset.operator,
        nonBoaServiceParts: selectedData.asset.nonBoaServiceParts,
        parentId: selectedData.asset.parentId,
        assetDbId: selectedData.asset.assetDbId,
        otherAssetDetails: selectedData.assetDetails.otherAssetDetails,
        makeName: selectedData.assetDetails.makeName
          ? selectedData.assetDetails.makeName
          : "",
        equipmentTypeName: selectedData.assetDetails.equipmentTypeName
          ? selectedData.assetDetails.equipmentTypeName
          : "",
        model: selectedData.assetDetails.model
          ? selectedData.assetDetails.model
          : "",
        assetPropUnit: selectedData.assetDetails.assetPropUnit,
        assetPropValue: selectedData.assetDetails.assetPropValue,
        equipmentTypeId: selectedData.equipmentTypeId,
        makeId: selectedData.makeId,
      };
      setFormData(selectedFormData);
    }
  }, [selectedData]);

  return (
    <React.Fragment>
      <MetaData title="My Assets" />
      <Modal
        onClose={() => setOpenHoseMaker(false)}
        onOpen={() => setOpenHoseMaker(true)}
        open={openHoseMaker}
        className="hosemaker-modal"
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <HoseMakerDT
            onCancelHoseMaker={onCancelHoseMaker}
            addBOM={addBOM}
            updateBOM={updateBOM}
            hoseMakerData={selectedHoseEndData?.hoseEndBomData}
            hoseMakerIndex={selectedHoseEndIndex}
            hoseNumber={selectedHoseEndIndex}
          />
        </Modal.Content>
      </Modal>
      <Modal
        onClose={() => setOpenPairHoseEnd(false)}
        onOpen={() => setOpenPairHoseEnd(true)}
        open={openPairHoseEnd}
        className="hosemaker-modal"
        closeOnDimmerClick={false}
      >
        <Modal.Content>
          <PairHoseEnd
            assetData={formData}
            currentLocationId={selectedId}
            selectedHoseEndId={selectedHoseEndId}
            selectedHoseEndData={selectedHoseEndData}
            pairedHoseEnd={selectedHoseEndData?.pairedHoseEndData}
            onCancelPairHoseEnd={onCancelPairHoseEnd}
            connectHoseEnd={connectHoseEnd}
          />
        </Modal.Content>
      </Modal>
      <Modal size="mini" open={deleteOpenLocation}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          {locationImages.length > 1 ? (
            <p>
              {data?.locationName} cannot be deleted because it has more than
              one hose image. <br />
              <br /> Please delete all images before attempting to delete this
              location.
            </p>
          ) : (
            <p>
              Are you sure <br />
              you want to delete {data?.locationName}?
            </p>
          )}
        </Modal.Content>
        <Modal.Actions>
          {locationImages.length > 1 ? (
            <Button onClick={() => setDeleteOpenLocation(false)}>CLOSE</Button>
          ) : (
            <>
              <Button onClick={() => setDeleteOpenLocation(false)}>NO</Button>
              <Button
                style={{
                  backgroundColor: "rgb(102, 204, 35)",
                  color: "#fff",
                }}
                onClick={() => onDeleteDTLocation()}
              >
                YES
              </Button>
            </>
          )}
        </Modal.Actions>
      </Modal>
      <Modal size="mini" open={deleteOpen}>
        <div style={{ position: "absolute", bottom: 100, left: 150 }}>
          {isLoading ? (
            <Loader
              active
              className="workaround"
              size="large"
              inline="centered"
            />
          ) : null}
        </div>
        <Modal.Content
          style={{ opacity: isLoading ? 0.2 : 1, textAlign: "center" }}
        >
          <p>
            Are you sure you want <br />
            to delete{" "}
            {locationImages.length > 0
              ? "Hose Image" + (imageName ? ` -  ${imageName}` : "")
              : " location"}
            ?
          </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setDeleteOpen(false)}>NO</Button>
          <Button
            style={{
              backgroundColor: "rgb(102, 204, 35)",
              color: "#fff",
            }}
            onClick={() => onDeleteDTImage()}
          >
            YES
          </Button>
        </Modal.Actions>
      </Modal>
      <Grid columns={3} divided className="hose-map" style={{ marginTop: -30 }}>
        <div style={{ paddingLeft: 38, paddingBottom: 15 }}>
          <Button
            style={{
              paddingLeft: 15,
              paddingRight: 15,
              minWidth: 100,
            }}
            size="mini"
            onClick={() => props.history.goBack()}
          >
            <Icon name="arrow left" /> Back to Asset Details
          </Button>
        </div>
        <Grid.Row style={{ paddingTop: 0, zIndex: 1 }}>
          <Grid.Column width={16} style={{ paddingRight: 0 }}>
            {formData?.id ? (
              <h3 style={{ marginLeft: 20 }}>
                <img
                  src={assetIcon}
                  style={{ width: 45, height: 45, marginTop: -5 }}
                />{" "}
                Hose Map - {formData?.assetId}
              </h3>
            ) : (
              <h3 style={{ marginLeft: 20 }}>
                <img
                  src={assetIcon}
                  style={{ width: 45, height: 45, marginTop: -5 }}
                />{" "}
                Hose Map
              </h3>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: 0 }} disabled={isLoading}>
          <Grid.Column
            width={5}
            className="hose-list-section"
            style={{ paddingRight: 0 }}
          >
            {/* <div style={{ position: "absolute", top: 100, left: 150 }}>
              {imageLoading ? (
                <Loader
                  active
                  className="workaround"
                  size="large"
                  inline="centered"
                />
              ) : null}
            </div> */}
            <div
              style={{
                paddingLeft: 33,
                paddingRight: 33,
              }}
            >
              <div
                style={{ textAlign: "center", paddingBottom: 5, height: 30 }}
              >
                {assetLocations.length > 0 && selectedHoseEnd === null ? (
                  <span style={{ color: "#AFABAB" }}>
                    Click location to view details
                  </span>
                ) : null}
              </div>
              {assetImage !== "" ? (
                <div
                  style={{
                    position: "relative",
                    width: 333,
                    height: 333,
                    overflow: "hidden",
                    backgroundImage: `url(${assetImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    border: "3px solid #AFABAB",
                  }}
                >
                  <svg
                    width="100%"
                    height="100%"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      overflow: "visible",
                    }}
                  >
                    {assetImage &&
                      assetLocations?.map((data) => {
                        const adjustedX = data.locationX * 100;
                        const adjustedY = data.locationY * 100;
                        if (
                          selectedHoseEnd !== null &&
                          selectedHoseEndData?.twinLocationId !== data.id &&
                          selectedHoseEndData?.pairedHoseEndData
                            ?.twinLocationId !== data.id
                        ) {
                          return null;
                        }
                        return (
                          <>
                            <circle
                              style={{
                                cursor: "pointer",
                              }}
                              cx={`${adjustedX}%`}
                              cy={`${adjustedY}%`}
                              r={
                                selectedHoseEndData?.twinLocationId ===
                                  data.id && selectedHoseEnd !== null
                                  ? 12
                                  : selectedHoseEndData?.pairedHoseEndData
                                      ?.twinLocationId
                                  ? 7
                                  : 8
                              }
                              fill="#66cc23"
                              stroke={
                                selectedId === data.id &&
                                selectedHoseEnd === null
                                  ? "rgba(66,165,246,0.5)"
                                  : ""
                              }
                              strokeWidth="8"
                              onClick={() => {
                                setImageFile(null);
                                setImageUrl(null);
                                handleCancelDrawing();
                                setHoseRecognitions([]);
                                setIsEdit(false);
                                setIsSaved(false);
                                setCanStartDrawing(false);
                                if (selectedId !== data.id) {
                                  setPosition({
                                    x:
                                      data.locationX *
                                      (parentSize - draggableSize),
                                    y:
                                      data.locationY *
                                      (parentSize - draggableSize),
                                  });
                                  setData(data);
                                  setLocationImages([]);
                                  setSelectedId(data.id);
                                  setAddHoseLocation(false);
                                  setSelectedImage(0);
                                } else {
                                  setPosition({ x: 220, y: 220 });
                                  const companyId = Number(
                                    localStorage.getItem("companyId")
                                  );
                                  const newData = {
                                    id: 0,
                                    locationName: "",
                                    isPublic: false,
                                    companyId: companyId,
                                    assetId: selectedData.asset.id,
                                    assetDbId: selectedData.asset.assetDbId,
                                    equipmentTypeId: null,
                                    areaId: null,
                                    areaName: "",
                                    locationX: 0.5,
                                    locationY: 0.5,
                                    createdBy: null,
                                    lastUpdatedBy: null,
                                    lastUpdatedDateTime: null,
                                    isDeleted: false,
                                    deletedBy: null,
                                    deletedDateTime: null,
                                  };
                                  setData(newData);
                                  setSelectedId(0);
                                  setSelectedImage(0);
                                }
                              }}
                            />
                            {data?.isPublic && (
                              <circle
                                style={{ cursor: "pointer" }}
                                cx={`${adjustedX}%`}
                                cy={`${adjustedY}%`}
                                r={
                                  selectedHoseEndData?.twinLocationId ===
                                    data.id && selectedHoseEnd !== null
                                    ? 9
                                    : selectedHoseEndData?.pairedHoseEndData
                                        ?.twinLocationId
                                    ? 5
                                    : 4.5
                                }
                                fill="#326310"
                                onClick={() => {
                                  setImageFile(null);
                                  setImageUrl(null);
                                  handleCancelDrawing();
                                  setHoseRecognitions([]);
                                  setIsEdit(false);
                                  setIsSaved(false);
                                  setCanStartDrawing(false);
                                  if (selectedId !== data.id) {
                                    setPosition({
                                      x:
                                        data.locationX *
                                        (parentSize - draggableSize),
                                      y:
                                        data.locationY *
                                        (parentSize - draggableSize),
                                    });
                                    setData(data);
                                    setLocationImages([]);
                                    setSelectedId(data.id);
                                    setAddHoseLocation(false);
                                    setSelectedImage(0);
                                    setSelectedHoseEnd(null);
                                    setSelectedHoseEndIndex(null);
                                    setSelectedHoseEndData(null);
                                    setSelectedHoseEndId(null);
                                  } else {
                                    setPosition({ x: 220, y: 220 });
                                    addNewHoseLocation();
                                    setSelectedId(0);
                                    setSelectedImage(0);
                                    setSelectedHoseEnd(null);
                                    setSelectedHoseEndIndex(null);
                                    setSelectedHoseEndData(null);
                                    setSelectedHoseEndId(null);
                                  }
                                }}
                              />
                            )}
                            {selectedId === data.id ||
                            selectedHoseEndData?.pairedHoseEndData
                              ?.twinLocationId === data.id ? (
                              <>
                                {" "}
                                <rect
                                  id="rectangle"
                                  x={`${adjustedX - 7}%`}
                                  y={`${adjustedY - 11}%`}
                                  width={35}
                                  height={17}
                                  fill="rgba(228, 228, 228, 0.5)" // Adjust the rectangle color as needed
                                  stroke="black" // Adjust the border color as needed
                                  strokeWidth={1}
                                  rx={2}
                                  ry={2}
                                />
                                <text
                                  id="text"
                                  x={`${adjustedX - 1.5}%`}
                                  y={`${adjustedY - 8}%`}
                                  dominantBaseline="middle"
                                  textAnchor="middle"
                                  fill="#000" // Adjust the text color as needed
                                >
                                  {data.configuredHoses}/{data.totalHoses}
                                </text>
                              </>
                            ) : null}
                          </>
                        );
                      })}
                  </svg>
                </div>
              ) : (
                <div
                  style={{
                    height: 333,
                    width: 333,
                    textAlign: "center",
                    paddingTop: 120,
                    color: "#979797",
                  }}
                >
                  <span>No Schematic Available</span>
                </div>
              )}
              {!addHoseLocation && selectedHoseEnd === null ? (
                <>
                  <Button
                    disabled={imageLoading}
                    className="active-status"
                    icon="add"
                    size="mini"
                    style={{
                      width: "100%",
                      fontSize: 14,
                      marginTop: 15,
                      marginBottom: 3,
                    }}
                    onClick={() => {
                      setPosition({ x: 220, y: 220 });
                      addNewHoseLocation();
                      setAddHoseLocation(true);
                      setIsSaved(false);
                      setSelectedId(0);
                      setLocationImages([]);
                      setSelectedImage(0);
                      setSelectedHoseEnd(null);
                      setSelectedHoseEndIndex(null);
                      setSelectedHoseEndData(null);
                      setSelectedHoseEndId(null);
                      setImageUrl(null);
                      setImageFile(null);
                    }}
                  >
                    Add New Hose Location
                  </Button>
                  {selectedId && !data?.isPublic ? (
                    <Grid style={{ marginTop: 0 }}>
                      <Grid.Column
                        width={8}
                        style={{ paddingRight: 5, paddingTop: 0 }}
                      >
                        <Button
                          disabled={imageLoading}
                          className="edit-status"
                          icon="add"
                          size="mini"
                          style={{
                            width: "100%",
                            fontSize: 14,
                            marginTop: 7,
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                          onClick={() => {
                            setAddHoseLocation(true);
                          }}
                        >
                          Edit Location
                        </Button>
                      </Grid.Column>
                      <Grid.Column
                        width={8}
                        style={{ paddingLeft: 5, paddingTop: 0 }}
                      >
                        <Button
                          disabled={imageLoading}
                          color="youtube"
                          icon="add"
                          size="mini"
                          style={{
                            width: "100%",
                            fontSize: 14,
                            marginTop: 7,
                            paddingLeft: 0,
                            paddingRight: 0,
                          }}
                          onClick={() => {
                            setDeleteOpenLocation(true);
                          }}
                        >
                          Delete Location
                        </Button>
                      </Grid.Column>
                    </Grid>
                  ) : null}
                </>
              ) : null}
            </div>
            {selectedId !== 0 && !isSaved ? (
              <Grid style={{ paddingTop: 15, marginRight: 0 }}>
                <Grid.Column
                  width={4}
                  style={{
                    paddingTop: "3rem",
                    paddingBottom: 0,
                    paddingRight: 0,
                  }}
                >
                  <ImageContainer
                    width={18}
                    src={rightArrow}
                    wrapped
                    style={{ marginTop: -8.5 }}
                  />
                </Grid.Column>
                <Grid.Column
                  width={12}
                  style={{
                    paddingTop: "3rem",
                    paddingBottom: 0,
                    paddingRight: 0,
                  }}
                >
                  <Header as="h5" style={{ marginLeft: -55, marginTop: -2 }}>
                    Hose Images - {data?.locationName}
                  </Header>
                </Grid.Column>
                <Grid.Column
                  width={2}
                  style={{
                    paddingBottom: 0,
                    paddingRight: 0,
                  }}
                ></Grid.Column>
                <Grid.Column
                  width={14}
                  style={{
                    padding: 0,
                    paddingTop: 10,
                  }}
                >
                  <Grid style={{ margin: 0 }}>
                    {locationImages?.map((data, index) => {
                      return (
                        <Grid.Column
                          computer={5}
                          style={{
                            padding: 0,
                            paddingLeft: 5,
                            paddingBottom: 5,
                          }}
                        >
                          <div
                            style={{
                              height: 89,
                              width: "100%",
                              minWidth: 89,
                              position: "relative",
                              marginLeft: "auto",
                              marginRight: "auto",
                              cursor: "pointer",
                              border:
                                selectedImage === index
                                  ? "solid 4px #66cc23"
                                  : "none",
                            }}
                            onClick={() => {
                              setIsEdit(false);
                              setCanStartDrawing(false);
                              handleCancelDrawing();
                              // setHoseRecognitions([]);
                              // setImageName("");
                              setSelectedImage(index);
                              setSelectedImageData(data);
                            }}
                          >
                            {data?.imageUrl ? (
                              <img
                                className="location-image"
                                src={data?.imageUrl}
                                style={{
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              />
                            ) : null}
                          </div>
                        </Grid.Column>
                      );
                    })}
                    <Grid.Column
                      computer={5}
                      style={{
                        padding: 0,
                        paddingLeft: 5,
                      }}
                    >
                      <div style={{ width: "100%", height: "100%" }}>
                        {!isLoading ? (
                          <LocationImageUploader
                            disabled={false}
                            setImage={addLocationImage}
                          />
                        ) : null}
                      </div>
                    </Grid.Column>
                  </Grid>
                </Grid.Column>
              </Grid>
            ) : null}
          </Grid.Column>
          <Grid.Column width={11} style={{ paddingRight: 0 }}>
            <div className="hose-form">
              {addHoseLocation ? (
                <div className="hose-actions">
                  {!isSaved || (isSaved && locationImages.length > 0) ? (
                    <Button
                      disabled={!data.areaId || !data.locationName}
                      className="active-status"
                      size="mini"
                      style={{ minWidth: 125, fontSize: 14 }}
                      onClick={() => onSaveHoseLocation()}
                    >
                      Confirm
                    </Button>
                  ) : null}
                  <Button
                    color="grey"
                    size="mini"
                    style={{ minWidth: 125, fontSize: 14, marginLeft: 3 }}
                    onClick={() => {
                      const selectedId =
                        localStorage.getItem("selectedAssetId");
                      if (selectedId !== null) {
                        getDTLocationsData(selectedId);
                      }
                      setAddHoseLocation(false);
                      getDTLocationsData();
                      setIsSaved(false);
                    }}
                  >
                    {isSaved ? "Close" : "Cancel"}
                  </Button>
                </div>
              ) : data?.id > 0 &&
                (locationImages.length > 0 || imageUrl) &&
                isSaved ? (
                <div className="hose-actions">
                  <Button
                    className="active-status"
                    size="mini"
                    style={{ minWidth: 125, fontSize: 14 }}
                    onClick={() => onConfirmHoseEnd()}
                  >
                    Confirm
                  </Button>
                  <Button
                    color="grey"
                    size="mini"
                    style={{ minWidth: 125, fontSize: 14 }}
                    onClick={() => {
                      setImageUrl(null);
                      setImageFile(null);
                      setHoseRecognitions([]);
                      setIsEdit(false);
                      setIsSaved(false);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              ) : data?.id > 0 && isEdit ? (
                <div className="hose-actions">
                  <Button
                    color="grey"
                    size="mini"
                    style={{ minWidth: 125, fontSize: 14 }}
                    onClick={() => {
                      getDTImages();
                      getHoseEnds();
                      setTimeout(function () {
                        setIsEdit(false);
                        setCanStartDrawing(false);
                        handleCancelDrawing();
                      }, 500);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="active-status"
                    size="mini"
                    style={{ minWidth: 125, fontSize: 14 }}
                    onClick={() => onConfirmHoseEnd()}
                  >
                    Confirm
                  </Button>
                </div>
              ) : data?.id > 0 && locationImages.length > 0 ? (
                <div className="hose-actions">
                  {locationImages.length > 0 ? (
                    <>
                      {!selectedImageData?.isPublic && (
                        <Button
                          size="mini"
                          color="youtube"
                          style={{ minWidth: 125, fontSize: 14 }}
                          onClick={() => setDeleteOpen(true)}
                        >
                          Delete Image
                        </Button>
                      )}
                      <Button
                        className="edit-status"
                        size="mini"
                        style={{ minWidth: 125, fontSize: 14, marginLeft: 3 }}
                        onClick={() => {
                          setSelectedHoseEnd(null);
                          setSelectedHoseEndIndex(null);
                          setSelectedHoseEndData(null);
                          setSelectedHoseEndId(null);
                          setIsEdit(true);
                        }}
                      >
                        Edit Hose Ends
                      </Button>
                    </>
                  ) : null}
                </div>
              ) : null}
              {addHoseLocation ||
              (data?.id > 0 && locationImages.length > 0) ? (
                <hr />
              ) : null}
              <div
                className="hose-form-content"
                style={{ paddingRight: "0px !important" }}
              >
                {addHoseLocation ? (
                  <Form size="tiny">
                    <Grid style={{ paddingTop: 15, marginRight: 0 }}>
                      {!isSaved && (
                        <>
                          <Grid.Column
                            computer={1}
                            tablet={2}
                            mobile={2}
                            style={{
                              paddingTop: "1rem",
                              paddingBottom: 0,
                              paddingRight: 0,
                            }}
                          >
                            <ImageContainer
                              width={18}
                              src={rightArrow}
                              wrapped
                              style={{ marginTop: -8.5 }}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={15}
                            tablet={14}
                            mobile={14}
                            style={{
                              paddingTop: "1rem",
                              paddingBottom: 0,
                              paddingRight: 0,
                            }}
                          >
                            <Header
                              as="h5"
                              style={{ marginLeft: -30, marginTop: -3 }}
                            >
                              {selectedId
                                ? "Edit Location"
                                : "Add New Location"}
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={1}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                              paddingRight: 0,
                            }}
                          ></Grid.Column>
                          <Grid.Column
                            width={8}
                            style={{
                              paddingTop: 0,
                              marginLeft: -30,
                            }}
                          >
                            <Grid style={{ marginTop: 0, marginBottom: 0 }}>
                              <Grid.Column width={8}>
                                <Form.Field
                                  required
                                  style={{
                                    fontSize: 12,
                                  }}
                                >
                                  <label>Hose Area</label>
                                  <Dropdown
                                    fluid
                                    search
                                    selection
                                    options={locationList}
                                    value={data.areaId}
                                    onChange={(e, data) =>
                                      handleChange("areaId", Number(data.value))
                                    }
                                    style={{
                                      width: "100%",
                                      paddingRight: 35,
                                    }}
                                  />
                                </Form.Field>
                              </Grid.Column>
                              <Grid.Column width={8}>
                                <Form.Field
                                  required
                                  style={{
                                    fontSize: 12,
                                  }}
                                >
                                  <label>Location Name</label>
                                  <Input
                                    value={data.locationName}
                                    onChange={(e, data) =>
                                      handleChange("locationName", data.value)
                                    }
                                  />
                                </Form.Field>
                              </Grid.Column>
                            </Grid>
                            {assetImage ? (
                              <div
                                style={{
                                  position: "relative",
                                  width: 472,
                                  height: 472,
                                  overflow: "hidden",
                                  backgroundImage: data?.areaId
                                    ? `url(${getPositionImage(data.areaId)})`
                                    : `url(${assetImage})`,
                                  backgroundSize: "cover",
                                  backgroundPosition: "center",
                                  backgroundRepeat: "no-repeat",
                                  border: "3px solid #66cc23",
                                }}
                              >
                                <Draggable
                                  bounds="parent"
                                  position={position}
                                  onDrag={handleDrag}
                                  defaultPosition={{
                                    x: 0,
                                    y: 0,
                                  }}
                                >
                                  <div
                                    style={{
                                      width: 5,
                                      height: 5,
                                      cursor: "pointer",
                                      aspectRatio: "1/1",
                                    }}
                                  >
                                    <div className="shape">
                                      <div className="rectangle-dt">
                                        {dtArrow ? (
                                          <img
                                            src={dtArrow}
                                            style={{
                                              width: 30,
                                              height: 30,
                                              position: "absolute",
                                              top: "8px",
                                              left: "8.5px",
                                              pointerEvents: "none",
                                            }}
                                          />
                                        ) : (
                                          <img
                                            src={emptyImage}
                                            style={{
                                              width: "100%",
                                              height: "auto",
                                              pointerEvents: "none",
                                            }}
                                          />
                                        )}
                                      </div>
                                      <div className="circle-dt">
                                        <Icon
                                          name="arrows alternate"
                                          style={{
                                            color: "#FFAD00",
                                            fontSize: 16,
                                            marginLeft: 2.75,
                                            marginTop: 0.6,
                                          }}
                                        />
                                      </div>
                                      <div className="line-dt"></div>
                                    </div>
                                  </div>
                                </Draggable>
                              </div>
                            ) : (
                              <div style={{ height: 472, width: 472 }}></div>
                            )}
                          </Grid.Column>
                          <Grid.Column
                            width={6}
                            style={{
                              paddingTop: 0,
                              paddingBottom: 0,
                            }}
                          ></Grid.Column>
                        </>
                      )}
                      {isSaved && (
                        <>
                          <Grid.Column
                            computer={1}
                            tablet={2}
                            mobile={2}
                            style={{
                              paddingTop: "1.5rem",
                              paddingBottom: 0,
                              paddingRight: 0,
                            }}
                          >
                            <ImageContainer
                              width={18}
                              src={rightArrow}
                              wrapped
                              style={{ marginTop: -8.5 }}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={15}
                            tablet={14}
                            mobile={14}
                            style={{
                              paddingTop: "1.5rem",
                              paddingBottom: 0,
                              paddingRight: 0,
                            }}
                          >
                            <Header
                              as="h5"
                              style={{ marginLeft: -30, marginTop: -3 }}
                            >
                              Add Image
                            </Header>
                          </Grid.Column>
                          <Grid.Column
                            width={16}
                            style={{ marginTop: 0, marginBottom: 30 }}
                          >
                            <div
                              onClick={() => {
                                if (!isSaved)
                                  showErrorToast("Confirm hose location first");
                              }}
                            >
                              <HoseMapImageUpload
                                disabled={!isSaved}
                                setImage={addLocationImage}
                              />
                            </div>
                          </Grid.Column>
                        </>
                      )}
                    </Grid>
                  </Form>
                ) : data.id > 0 && (locationImages.length > 0 || imageUrl) ? (
                  <Form size="tiny">
                    <Grid style={{ paddingTop: 15, marginRight: 0 }}>
                      <Grid.Column
                        width={1}
                        style={{
                          paddingTop: "1rem",
                          paddingBottom: 0,
                          paddingRight: 0,
                        }}
                      >
                        <ImageContainer
                          width={18}
                          src={rightArrow}
                          wrapped
                          style={{ marginTop: -8.5 }}
                        />
                      </Grid.Column>
                      <Grid.Column
                        width={15}
                        style={{
                          paddingTop: "1rem",
                          paddingBottom: 25,
                          paddingRight: 0,
                        }}
                      >
                        <Header
                          as="h5"
                          style={{ marginLeft: -30, marginTop: -3 }}
                        >
                          {data?.locationName}
                          {" - "}
                          {imageName
                            ? imageName
                            : "Image #" +
                              (imageUrl
                                ? locationImages.length + 1
                                : selectedImage + 1)}
                        </Header>
                      </Grid.Column>
                      <Grid.Column
                        width={7}
                        style={{
                          paddingRight: 8,
                          paddingBottom: 10,
                          paddingTop: 0,
                        }}
                      >
                        {isEdit && !selectedImageData?.isPublic ? (
                          <Input
                            placeholder="Image Name"
                            style={{ width: "100%" }}
                            value={imageName}
                            onChange={(e, data) => setImageName(data.value)}
                          />
                        ) : null}
                      </Grid.Column>
                      <Grid.Column width={7} style={{ paddingBottom: 0 }}>
                        {selectedHoseEnd === null ? (
                          <div style={{ position: "absolute" }}>
                            {hoseRecognitions.length > 0 && !isEdit ? (
                              <div style={{ marginTop: -25, marginLeft: 3 }}>
                                <span style={{ color: "#5080FD" }}>
                                  Click on hose to view hoseline details
                                </span>
                              </div>
                            ) : null}
                          </div>
                        ) : (
                          <div style={{ position: "absolute" }}>
                            <div style={{ marginTop: -40, marginLeft: 3 }}>
                              <Button
                                disabled={imageLoading}
                                className="default-status"
                                icon="add"
                                size="mini"
                                style={{
                                  width: "100%",
                                  fontSize: 14,
                                }}
                                onClick={() => {
                                  setSelectedHoseEnd(null);
                                  setSelectedHoseEndIndex(null);
                                  setSelectedHoseEndData(null);
                                  setSelectedHoseEndId(null);
                                }}
                              >
                                Back to Hose Map
                              </Button>
                            </div>
                          </div>
                        )}
                      </Grid.Column>
                      <Grid.Column
                        width={2}
                        style={{ paddingBottom: 0 }}
                      ></Grid.Column>
                      <Grid.Column
                        width={7}
                        style={{
                          paddingTop: 5,
                          paddingBottom: 0,
                        }}
                      >
                        <div
                          className="ui image"
                          style={{
                            height: 360,
                            width: 360,
                            minWidth: 360,
                            position: "relative",
                            marginLeft: "auto",
                            marginRight: "auto",
                            border: "3px solid #66CB23",
                          }}
                          ref={containerRef}
                        >
                          {(imageUrl ||
                            locationImages[selectedImage]?.imageUrl) && (
                            <>
                              <img
                                ref={imageRef}
                                src={
                                  imageUrl
                                    ? imageUrl
                                    : locationImages[selectedImage]?.imageUrl
                                }
                                className="hose-end-image"
                                style={{
                                  marginTop: "auto",
                                  marginBottom: "auto",
                                }}
                              />
                              <svg
                                width="354px"
                                height="354px"
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  overflow: "visible",
                                }}
                              >
                                {hoseRecognitions &&
                                  hoseRecognitions?.map((data, index) => {
                                    const adjustedX = data.xmin * 100;
                                    const adjustedY = data.ymin * 100;
                                    const adjustedWidth = data.width * 100;
                                    const adjustedHeight = data.height * 100;
                                    const paddingLeftForText =
                                      (50 / 100) * adjustedWidth;
                                    return (
                                      <>
                                        {data?.isRemoved ? null : (
                                          <>
                                            <rect
                                              id="rectangle"
                                              x={`${adjustedX}%`}
                                              y={`${adjustedY - 6}%`}
                                              width={`${adjustedWidth}%`}
                                              height={20}
                                              fill={
                                                data?.isRemoved
                                                  ? "#FF0000"
                                                  : selectedHoseEnd === index &&
                                                    !isEdit
                                                  ? "#66CC23"
                                                  : isEdit &&
                                                    data.id > 0 &&
                                                    selectedHoseEnd === index
                                                  ? "#66CC23"
                                                  : data.id > 0
                                                  ? "#42A5F6"
                                                  : "#FF0000"
                                              }
                                              stroke={
                                                data?.isRemoved
                                                  ? "#FF0000"
                                                  : selectedHoseEnd === index &&
                                                    !isEdit
                                                  ? "#66CC23"
                                                  : isEdit &&
                                                    data.id > 0 &&
                                                    selectedHoseEnd === index
                                                  ? "#66CC23"
                                                  : data.id > 0
                                                  ? "#42A5F6"
                                                  : "#FF0000"
                                              }
                                              strokeWidth={3}
                                              text="Test"
                                            />
                                            <text
                                              x={`${
                                                adjustedX + paddingLeftForText
                                              }%`}
                                              y={`${adjustedY - 2.5}%`}
                                              alignment-baseline="middle"
                                              text-anchor="middle"
                                              fill="white"
                                            >
                                              {data?.isRemoved
                                                ? ""
                                                : `#${data?.sequenceId}`}
                                            </text>
                                            <rect
                                              id="rectangle"
                                              x={`${adjustedX}%`}
                                              y={`${adjustedY}%`}
                                              width={`${adjustedWidth}%`}
                                              height={`${adjustedHeight}%`}
                                              fill={
                                                data?.isRemoved
                                                  ? "#FF0000"
                                                  : "rgba(255, 0, 0, 0)"
                                              }
                                              stroke={
                                                data?.isRemoved
                                                  ? "#FF0000"
                                                  : selectedHoseEnd === index &&
                                                    !isEdit
                                                  ? "#66CC23"
                                                  : isEdit &&
                                                    data.id > 0 &&
                                                    selectedHoseEnd === index
                                                  ? "#66CC23"
                                                  : data.id > 0
                                                  ? "#42A5F6"
                                                  : "#FF0000"
                                              }
                                              strokeWidth={3}
                                            />
                                          </>
                                        )}
                                      </>
                                    );
                                  })}
                              </svg>
                              <canvas
                                ref={canvasRef}
                                onMouseDown={handleMouseDown}
                                onMouseMove={handleMouseMove}
                                onMouseUp={handleMouseUp}
                                onMouseLeave={handleMouseLeave}
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  left: 0,
                                  height: 354,
                                  width: 354,
                                }}
                              />
                              {canStartDrawing && isDrawing && drawRectangle()}
                            </>
                          )}
                          {/* Buttons on top of the drawn rectangle */}
                          {!isDrawing &&
                            startPoint.x !== 0 &&
                            startPoint.y !== 0 && (
                              <div
                                style={{
                                  position: "absolute",
                                  top:
                                    startPoint.x < endPoint.x
                                      ? startPoint.y - 21
                                      : endPoint.y - 21,
                                  left:
                                    startPoint.x < endPoint.x
                                      ? startPoint.x - 25
                                      : endPoint.x - 25,
                                }}
                              >
                                <Button
                                  className="white-btn"
                                  style={{
                                    backgroundColor: "#fff",
                                    height: 20,
                                    width: 20,
                                    fontSize: 14,
                                    borderRadius: 0,
                                    padding: 0,
                                    marginRight: 3,
                                  }}
                                  onClick={handleCancelDrawing}
                                >
                                  <Icon
                                    name="close"
                                    style={{ color: "#000", paddingLeft: 8 }}
                                  />
                                </Button>
                                <Button
                                  style={{
                                    height: 20,
                                    minWidth: 22,
                                    width:
                                      startPoint.x < endPoint.x
                                        ? endPoint.x - startPoint.x + 4
                                        : startPoint.x - endPoint.x + 4,
                                    fontSize: 14,
                                    borderRadius: 0,
                                    padding: 0,
                                    backgroundColor: "#FFA800",
                                    color: "#fff",
                                  }}
                                >
                                  #
                                  {hoseRecognitions?.filter(
                                    (item) => item.isRemoved === false
                                  ).length + 1}
                                </Button>
                                <Button
                                  positive
                                  style={{
                                    height: 20,
                                    width: 20,
                                    fontSize: 14,
                                    borderRadius: 0,
                                    padding: 0,
                                  }}
                                  onClick={handleSaveDrawing}
                                >
                                  <Icon
                                    name="check"
                                    style={{ paddingLeft: 6 }}
                                  />
                                </Button>
                              </div>
                            )}
                        </div>
                        <div style={{ marginTop: 15 }}>
                          {isEdit || isSaved ? (
                            <Grid>
                              <Grid.Column
                                width={7}
                                style={{ paddingRight: 10 }}
                              >
                                {canStartDrawing ? (
                                  <Button
                                    color="grey"
                                    size="mini"
                                    style={{ minWidth: "100%", fontSize: 16 }}
                                    onClick={() => {
                                      handleCancelDrawing();
                                      setCanStartDrawing(false);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                ) : (
                                  <Button
                                    className="active-status"
                                    size="mini"
                                    style={{ minWidth: "100%", fontSize: 16 }}
                                    onClick={handleStartDrawing}
                                  >
                                    Add Hose
                                  </Button>
                                )}
                              </Grid.Column>
                              <Grid.Column width={9} style={{ paddingLeft: 0 }}>
                                <span
                                  style={{
                                    fontSize: 11,
                                    color: "#8E8E8E",
                                    lineHeight: 0,
                                  }}
                                >
                                  Click and drag on image above to create box
                                  around new hose end
                                </span>
                              </Grid.Column>
                            </Grid>
                          ) : null}
                        </div>
                      </Grid.Column>
                      <Grid.Column
                        width={8}
                        style={{
                          paddingTop: 5,
                          paddingBottom: 0,
                          paddingLeft: 20,
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: 105,
                            left: -80,
                            width: 400,
                          }}
                        >
                          {loadingHoseRecognition ? (
                            <Loader active size="large">
                              Identifying hose ends...
                            </Loader>
                          ) : null}
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            top: 145,
                            left: -20,
                            width: 400,
                          }}
                        >
                          {" "}
                          {hoseEndsLoading && !isLoading ? (
                            <Loader active size="large">
                              Loading hose ends...
                            </Loader>
                          ) : null}
                        </div>
                        {hoseRecognitions.length === 0 &&
                        !isEdit &&
                        !hoseEndsLoading ? (
                          <div
                            style={{
                              margin: 60,
                              marginTop: 130,
                              color: "#AFABAB",
                              fontSize: 20,
                            }}
                          >
                            <span>
                              No hose ends registered in the current image, edit
                              to add new.
                            </span>
                          </div>
                        ) : null}
                        {hoseRecognitions &&
                          hoseRecognitions?.map((hose, index) => {
                            return (
                              <Card
                                style={{
                                  marginTop: 0,
                                  marginBottom: "1em",
                                  width: "100%",
                                  border:
                                    isEdit && hose?.isRemoved
                                      ? "solid 1px #FF0000"
                                      : selectedHoseEnd !== null &&
                                        selectedHoseEnd === index
                                      ? "solid 3px #66cc23"
                                      : "none",
                                }}
                              >
                                <Card.Content
                                  style={{
                                    minHeight: 50,
                                    padding: 10,
                                    backgroundColor: !hose?.isRemoved
                                      ? "transparent"
                                      : "#ffd9da",
                                  }}
                                >
                                  {isSaved || isEdit ? (
                                    <Grid>
                                      <Grid.Column width={12}>
                                        <div
                                          style={{
                                            paddingLeft: "0.5rem",
                                            paddingBottom: 10,
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              fontWeight: "bolder",
                                              color: "#000",
                                            }}
                                          >
                                            {hose?.sequenceId
                                              ? `Hose End #${hose?.sequenceId}${
                                                  hose?.isPublic
                                                    ? " (Public)"
                                                    : ""
                                                }:`
                                              : "Removed"}
                                          </span>
                                        </div>
                                        <div
                                          disabled={hose?.isRemoved}
                                          style={{ paddingLeft: "0.5rem" }}
                                        >
                                          <Grid style={{ margin: 0 }}>
                                            <Grid.Column
                                              width={8}
                                              style={{ padding: 0 }}
                                            >
                                              <span style={{ fontSize: 12 }}>
                                                Hose Name:
                                              </span>
                                            </Grid.Column>
                                            <Grid.Column
                                              width={8}
                                              style={{
                                                padding: 0,
                                              }}
                                            >
                                              <Input
                                                disabled={
                                                  hose?.isRemoved ||
                                                  hose?.isPublic
                                                }
                                                placeholder="Hose Name"
                                                className="hose-input"
                                                size="tiny"
                                                style={{
                                                  width: "100%",
                                                  padding: 0,
                                                }}
                                                value={hose?.hoseEndName}
                                                onChange={(e, data) =>
                                                  handleHoseEndChange(
                                                    "hoseEndName",
                                                    data.value,
                                                    index
                                                  )
                                                }
                                              />
                                            </Grid.Column>
                                          </Grid>
                                          <Grid style={{ margin: 0 }}>
                                            <Grid.Column
                                              width={8}
                                              style={{ padding: 0 }}
                                            >
                                              <span style={{ fontSize: 12 }}>
                                                Part No.:
                                              </span>
                                            </Grid.Column>
                                            <Grid.Column
                                              width={8}
                                              style={{
                                                padding: 0,
                                              }}
                                            >
                                              <Input
                                                disabled={
                                                  hose?.isRemoved ||
                                                  hose?.isPublic
                                                }
                                                placeholder="Part Number"
                                                className="hose-input"
                                                size="tiny"
                                                style={{
                                                  width: "100%",
                                                  padding: 0,
                                                }}
                                                value={hose?.oemPartNumber}
                                                onChange={(e, data) =>
                                                  handleHoseEndChange(
                                                    "oemPartNumber",
                                                    data.value,
                                                    index
                                                  )
                                                }
                                              />
                                            </Grid.Column>
                                          </Grid>
                                        </div>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={4}
                                        style={{
                                          textAlign: "right",
                                          paddingTop: 40,
                                        }}
                                      >
                                        {!hose?.isPublic ? (
                                          <>
                                            {hose?.isRemoved ? (
                                              <Icon
                                                link
                                                name="undo"
                                                style={{ fontSize: 20 }}
                                                onClick={() =>
                                                  handleHoseEndChange(
                                                    "isRemoved",
                                                    false,
                                                    index
                                                  )
                                                }
                                              />
                                            ) : (
                                              <Icon
                                                link
                                                color="red"
                                                name="trash"
                                                style={{ fontSize: 20 }}
                                                onClick={() =>
                                                  handleHoseEndChange(
                                                    "isRemoved",
                                                    true,
                                                    index
                                                  )
                                                }
                                              />
                                            )}
                                          </>
                                        ) : null}
                                      </Grid.Column>
                                    </Grid>
                                  ) : (
                                    <Grid>
                                      <Grid.Column
                                        width={10}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          if (
                                            selectedHoseEnd !== index &&
                                            !isEdit
                                          ) {
                                            setSelectedHoseEnd(index);
                                            setSelectedHoseEndData(hose);
                                            setSelectedHoseEndId(hose?.id);
                                          }
                                        }}
                                      >
                                        <div
                                          style={{
                                            paddingLeft: "0.5rem",
                                            paddingBottom: 10,
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              fontWeight: "bolder",
                                            }}
                                          >
                                            {hose?.sequenceId
                                              ? `Hose End #${hose?.sequenceId}${
                                                  hose?.isPublic
                                                    ? " (Public)"
                                                    : ""
                                                }:`
                                              : "Removed"}
                                          </span>
                                        </div>
                                        <div
                                          style={{ paddingLeft: "0.5rem" }}
                                          disabled={hose?.isRemoved}
                                        >
                                          {!isEdit ? (
                                            <Grid style={{ margin: 0 }}>
                                              <Grid.Column
                                                width={8}
                                                style={{ padding: 0 }}
                                              >
                                                <span style={{ fontSize: 12 }}>
                                                  Hose Name:
                                                </span>
                                              </Grid.Column>
                                              <Grid.Column
                                                width={8}
                                                style={{
                                                  padding: 0,
                                                  textAlign: "right",
                                                }}
                                              >
                                                <span style={{ fontSize: 12 }}>
                                                  {hose?.hoseEndName}
                                                </span>
                                              </Grid.Column>
                                            </Grid>
                                          ) : (
                                            <Grid style={{ margin: 0 }}>
                                              <Grid.Column
                                                width={8}
                                                style={{ padding: 0 }}
                                              >
                                                <span style={{ fontSize: 12 }}>
                                                  Hose Name:
                                                </span>
                                              </Grid.Column>
                                              <Grid.Column
                                                width={8}
                                                style={{
                                                  padding: 0,
                                                  textAlign: "right",
                                                }}
                                              >
                                                <Input
                                                  disabled={
                                                    hose?.isRemoved ||
                                                    hose?.isPublic
                                                  }
                                                  placeholder="Hose Name"
                                                  className="hose-input"
                                                  size="tiny"
                                                  style={{
                                                    width: "100%",
                                                    padding: 0,
                                                  }}
                                                  value={hose?.hoseEndName}
                                                  onChange={(e, data) =>
                                                    handleHoseEndChange(
                                                      "hoseEndName",
                                                      data.value,
                                                      index
                                                    )
                                                  }
                                                />
                                              </Grid.Column>
                                            </Grid>
                                          )}
                                          {!isEdit ? (
                                            <Grid style={{ margin: 0 }}>
                                              <Grid.Column
                                                width={8}
                                                style={{ padding: 0 }}
                                              >
                                                <span style={{ fontSize: 12 }}>
                                                  Part No.:
                                                </span>
                                              </Grid.Column>
                                              <Grid.Column
                                                width={8}
                                                style={{
                                                  padding: 0,
                                                  textAlign: "right",
                                                }}
                                              >
                                                <span style={{ fontSize: 12 }}>
                                                  {hose?.oemPartNumber}
                                                </span>
                                              </Grid.Column>
                                            </Grid>
                                          ) : (
                                            <Grid style={{ margin: 0 }}>
                                              <Grid.Column
                                                width={8}
                                                style={{ padding: 0 }}
                                              >
                                                <span style={{ fontSize: 12 }}>
                                                  Part No.:
                                                </span>
                                              </Grid.Column>
                                              <Grid.Column
                                                width={8}
                                                style={{
                                                  padding: 0,
                                                  textAlign: "right",
                                                }}
                                              >
                                                <Input
                                                  disabled={
                                                    hose?.isRemoved ||
                                                    hose?.isPublic
                                                  }
                                                  placeholder="Part Number"
                                                  className="hose-input"
                                                  size="tiny"
                                                  style={{
                                                    width: "100%",
                                                    padding: 0,
                                                  }}
                                                  value={hose?.oemPartNumber}
                                                  onChange={(e, data) =>
                                                    handleHoseEndChange(
                                                      "oemPartNumber",
                                                      data.value,
                                                      index
                                                    )
                                                  }
                                                />
                                              </Grid.Column>
                                            </Grid>
                                          )}
                                        </div>
                                      </Grid.Column>
                                      <Grid.Column
                                        width={6}
                                        style={{
                                          paddingTop: 25,
                                        }}
                                      >
                                        {localStorage.getItem(
                                          "boahubBuildDigitalTwin"
                                        ) === "true" ? (
                                          <Button
                                            className={
                                              hose?.hoseEndBomData?.id
                                                ? "active-status"
                                                : "edit-status"
                                            }
                                            size="mini"
                                            style={{
                                              width: "100%",
                                              fontSize: 10,
                                              marginLeft: 3,
                                            }}
                                            onClick={() => {
                                              setSelectedHoseEndData(hose);
                                              setSelectedHoseEndIndex(index);
                                              setSelectedHoseEndId(hose?.id);
                                              setTimeout(function () {
                                                setOpenHoseMaker(true);
                                              }, 1000);
                                            }}
                                          >
                                            {hose?.hoseEndBomData?.isPublic
                                              ? "View"
                                              : hose?.hoseEndBomData?.id
                                              ? "Edit"
                                              : "Add"}{" "}
                                            BOM Details
                                          </Button>
                                        ) : null}
                                        <Button
                                          className={
                                            !hose?.pairedHoseEndData ||
                                            hose?.pairedHoseEndData === null
                                              ? "edit-status"
                                              : "active-status"
                                          }
                                          color="orange"
                                          size="mini"
                                          style={{
                                            width: "100%",
                                            fontSize: 10,
                                            marginLeft: 3,
                                            marginTop: 10,
                                          }}
                                          onClick={() => {
                                            setSelectedHoseEndData(hose);
                                            setSelectedHoseEndId(hose?.id);
                                            setSelectedHoseEndIndex(index);
                                            setTimeout(function () {
                                              setOpenPairHoseEnd(true);
                                            }, 1000);
                                          }}
                                        >
                                          {!hose?.pairedHoseEndData ||
                                          hose?.pairedHoseEndData === null
                                            ? "Pair Hose End"
                                            : "View Paired Hose End"}
                                        </Button>
                                      </Grid.Column>
                                    </Grid>
                                  )}
                                </Card.Content>
                              </Card>
                            );
                          })}
                      </Grid.Column>
                    </Grid>
                  </Form>
                ) : data.id > 0 && !isLoading && locationImages.length === 0 ? (
                  <Form size="tiny">
                    <Grid style={{ paddingTop: 15, marginRight: 0 }}>
                      <Grid.Column
                        computer={1}
                        tablet={2}
                        mobile={2}
                        style={{
                          paddingTop: "1.5rem",
                          paddingBottom: 0,
                          paddingRight: 0,
                        }}
                      >
                        <ImageContainer
                          width={18}
                          src={rightArrow}
                          wrapped
                          style={{ marginTop: -8.5 }}
                        />
                      </Grid.Column>
                      <Grid.Column
                        computer={15}
                        tablet={14}
                        mobile={14}
                        style={{
                          paddingTop: "1.5rem",
                          paddingBottom: 0,
                          paddingRight: 0,
                        }}
                      >
                        <Header
                          as="h5"
                          style={{ marginLeft: -30, marginTop: -3 }}
                        >
                          {data?.locationName}
                        </Header>
                      </Grid.Column>
                      <Grid.Column
                        width={16}
                        style={{ marginTop: 0, marginBottom: 30 }}
                      >
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: 36,
                            marginBottom: 20,
                          }}
                        >
                          <span>
                            Add the first image to {data?.locationName}
                          </span>
                        </div>
                        <div>
                          <HoseMapImageUpload setImage={addLocationImage} />
                        </div>
                      </Grid.Column>
                    </Grid>
                  </Form>
                ) : null}
              </div>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

export default HoseMap;
