import axios from "axios";

export const apiUpdateProductMinMax = (
  stockLocationId,
  productId,
  min,
  max
) => {
  // Validate data for payload
  const productMinMaxDetails = [
    { productId: productId, min: Number(min), max: Number(max) },
  ];
  const payload = {
    stockLocationId: stockLocationId,
    productMinMaxDetails: productMinMaxDetails,
  };
  return axios
    .put("/api/InventoryManagement/min-max-update-product", payload)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};
