export const tempData = {
  email: "",
  firstName: "",
  lastName: "",
  mobileNumber: "",
  officeNumber: "",
  userType: null,
  industryId: null,
  boahubShowRrp: false,
  boahubShowPurchasePrice: false,
};

export const tempCustomers = [
  {
    id: 0,
    value: "Customer 1",
    name: "Customer 1",
    text: "Customer 1",
    contacts: [
      {
        id: 0,
        name: "Contact 1",
        phoneNumber: "1234567890",
        email: "contact@email.com",
      },
      {
        id: 1,
        name: "Contact 2",
        phoneNumber: "1234567890",
        email: "contact@email.com",
      },
      {
        id: 2,
        name: "Contact 3",
        phoneNumber: "1234567890",
        email: "contact@email.com",
      },
    ],
  },
  {
    id: 1,
    value: "Customer 2",
    name: "Customer 2",
    text: "Customer 2",
    contacts: [
      {
        id: 0,
        name: "Contact 1",
        phoneNumber: "1234567890",
        email: "contact@email.com",
      },
      {
        id: 1,
        name: "Contact 2",
        phoneNumber: "1234567890",
        email: "contact@email.com",
      },
    ],
  },
  {
    id: 2,
    value: "Customer 3",
    name: "Customer 3",
    text: "Customer 3",
  },
];
