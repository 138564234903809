import { actions } from "react-redux-form";
import {
  apiAddAsset,
  apiGetAssetForDropdown,
  // apiAssetType,
  apiGetAssetTypeDetails,
  apiUploadAssetImage,
  apiDeleteAssetImage
} from "./assetForm.api";

const ASSET_SUBMIT_RESPONSE = "ASSET_SUBMIT_RESPONSE";
const ASSET_LIST = "ASSET_LIST";
const ASSET_TYPE = "ASSET_TYPE";
const UPLOAD_IMAGE = "UPLOAD_IMAGE";

const initialState = {
  isLoading: false,
  response: {},
  assetList: [],
  assetTypeDetail: {},
  assetImageUrl: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ASSET_SUBMIT_RESPONSE:
      return {
        ...state,
        response: action.payload
      };

    case ASSET_LIST:
      return {
        ...state,
        assetList: action.payload
      };
    case ASSET_TYPE:
      return {
        ...state,
        assetTypeDetail: action.payload
      };

    case UPLOAD_IMAGE:
      return {
        ...state,
        assetImageUrl: action.payload
      };

    default:
      return state;
  }
};

export const actionResetAssetFormInfo = () => {
  return dispatch => {
    dispatch(actions.reset("forms.assetForm"));
  };
};

export const actionSetAssetFormInfo = form => {
  return dispatch => {
    dispatch(actions.change("forms.assetForm", form));
  };
};

export const actionSetFormField = (name, field) => {
  return dispatch => {
    dispatch(actions.change("forms.assetForm." + name, field));
  };
};

export const actionSubmitAssetForm = data => {
  return async dispatch => {
    try {
      const result = await apiAddAsset(data);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: ASSET_SUBMIT_RESPONSE, payload: result });
      } else {
        dispatch({ type: ASSET_SUBMIT_RESPONSE, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: ASSET_SUBMIT_RESPONSE, payload: e.response });
    }
  };
};

export const actionGetAssetsForDropdown = () => {
  return async dispatch => {
    try {
      const result = await apiGetAssetForDropdown();
      dispatch({ type: ASSET_LIST, payload: result.data });
    } catch (e) {
      dispatch({ type: ASSET_LIST, payload: e });
    }
  };
};

// export const actionAssetType = () => {
//   return async dispatch => {
//     try {
//       const result = await apiAssetType();
//       dispatch({ type: ASSET_TYPE, payload: result.data });
//     } catch (e) {
//       dispatch({ type: ASSET_TYPE, payload: e });
//     }
//   };
// };

export const actionGetAssetTypeDetails = (obj, searchFor) => {
  return async dispatch => {
    try {
      const result = await apiGetAssetTypeDetails(obj, searchFor);
      dispatch({ type: ASSET_TYPE, payload: result.data });
    } catch (e) {
      dispatch({ type: ASSET_TYPE, payload: e.response });
    }
  };
};

export const actionSetQuantity = (name, field) => {
  return dispatch => {
    dispatch(actions.change("forms." + name, field));
  };
};

export const actionUploadAssetImage = fileData => {
  return async dispatch => {
    try {
      const result = await apiUploadAssetImage(fileData);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: UPLOAD_IMAGE, payload: result.data });
      } else {
        dispatch({ type: UPLOAD_IMAGE, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: UPLOAD_IMAGE, payload: e.response });
    }
  };
};

export const actionDeleteAssetImage = imageKey => {
  return async dispatch => {
    try {
      const result = await apiDeleteAssetImage(imageKey);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: UPLOAD_IMAGE, payload: result.data });
      } else {
        dispatch({ type: UPLOAD_IMAGE, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: UPLOAD_IMAGE, payload: e.response });
    }
  };
};
