import React, { useEffect, useRef, useState } from "react";
import { Grid, Loader, Button, Table, Popup } from "semantic-ui-react";
import MetaData from "../../../components/MetaData";
import _debounce from "lodash/debounce";
import { apiExportMinMax, apiImportMinMax } from "./minMax.api";
import Papa from "papaparse";
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from "../../../utils/toastUtils";

const MinMax = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isImporting, setIsImporting] = useState(false);
  const [jsonResult, setJsonResult] = useState([]);
  const [payloadData, setPayloadData] = useState([]);
  const [failedImports, setFailedImports] = useState([]);
  const [errorCount, setErrorCount] = useState(0);

  const csvRef = useRef();

  const handleFileChange = (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    if (file) {
      if (file.name.includes(props.stockLocationId)) {
        setFailedImports([]);
        Papa.parse(file, {
          complete: (result) => {
            let resultData = result.data;
            let payloadProducts = [];

            resultData.map(async (product) => {
              // Check if quantity is number
              if (!isNaN(product.Min) && !isNaN(parseFloat(product.Min))) {
                product.Min = Number(product.Min);

                // Check if uom is not Metre and prevent decimal value
                if (
                  product.UoM.toLowerCase() !== "metre" &&
                  product.Min === product.Min &&
                  product.Min % 1 !== 0
                ) {
                  product.Min = Math.trunc(product.Min);
                } else {
                  product.Min = Math.trunc(product.Min * 10) / 10;
                }
              }

              if (!isNaN(product.Max) && !isNaN(parseFloat(product.Max))) {
                product.Max = Number(product.Max);

                // Check if uom is not Metre and prevent decimal value
                if (
                  product.UoM.toLowerCase() !== "metre" &&
                  product.Max === product.Max &&
                  product.Max % 1 !== 0
                ) {
                  product.Max = Math.trunc(product.Max);
                } else {
                  product.Max = Math.trunc(product.Max * 10) / 10;
                }
              }
            });

            let filteredData = resultData.filter((obj) => {
              return (
                (obj.Min !== undefined && obj.Min !== null && obj.Min !== "") ||
                (obj.Max !== undefined && obj.Max !== null && obj.Max !== "")
              );
            });

            filteredData.map(async (product) => {
              const productData = {
                productId: Number(product["Product ID"]),
                barcode: product["Product SKU"],
                productName: product.Description,
                min: Number(product.Min),
                max: Number(product.Max),
              };
              payloadProducts.push(productData);
            });

            setJsonResult(filteredData);
            setPayloadData(payloadProducts);
          },
          header: true, // Set this to true if your CSV file has headers
        });
      } else {
        showErrorToast("Stock Location does not match");
      }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 300);
  };

  const onExportMinMax = async () => {
    setIsLoading(true);
    const result = await apiExportMinMax(props.stockLocationId);
    if (result) {
      let productList = [];
      result.map((item, i) => {
        const product = {
          "Product ID": item.productId,
          "Product SKU": item.barCode,
          Description: item.productName,
          UoM: item.uom,
          Min: item.min !== null ? item.min : "",
          Max: item.max !== null ? item.max : "",
        };
        productList.push(product);
      });
      const csvData = convertToCSV(productList);
      downloadCSV(csvData, `min-max-${props.stockLocationId}.csv`);
      setIsLoading(false);
    } else {
      showErrorToast("Something went wrong");
      setIsLoading(false);
    }
  };

  // Convert JSON to CSV format
  function convertToCSV(data) {
    const headers = Object.keys(data[0]);
    const csvRows = [headers.join(",")]; // Create header row

    // Add each row's values
    data.forEach((row) => {
      const values = headers.map((header) => {
        const escaped = ("" + row[header]).replace(/"/g, '""'); // Escape double quotes
        return `"${escaped}"`; // Wrap in double quotes
      });
      csvRows.push(values.join(","));
    });

    return csvRows.join("\n"); // Join rows with newlines
  }

  // Trigger CSV download
  function downloadCSV(csv, filename) {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", filename);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const onImportMinMax = async () => {
    // Validate all payload data for errors
    let errorCount = 0;
    jsonResult.map((data) => {
      if (hasError(data.Min)) errorCount++;
      if (hasError(data.Max)) errorCount++;
      if (hasProductIdError(data["Product ID"])) errorCount++;
    });
    if (errorCount > 0) {
      showErrorToast("Please correct all data before importing");
      return false;
    }

    setIsImporting(true);
    setFailedImports([]);
    try {
      const data = await apiImportMinMax(props.stockLocationId, payloadData);
      if (data) {
        if (data.rejectedProductsMinMaxDetails?.length > 0) {
          setFailedImports(data.rejectedProductsMinMaxDetails);
          let failedProducts = [];
          data.rejectedProductsMinMaxDetails.map((failedProduct) => {
            jsonResult.map((product) => {
              if (
                failedProduct.productId.toString() === product["Product ID"]
              ) {
                product.importReport = failedProduct.importReport;
                failedProducts.push(product);
              }
            });
          });
          setJsonResult(failedProducts);
          setPayloadData(data.rejectedProductsMinMaxDetails);
          setIsImporting(false);
          showWarningToast(
            <div>
              <i className="check icon" /> Min/Max Imported Successfully with
              Errors: Some records were not imported.
            </div>
          );
        } else {
          showSuccessToast(
            <div>
              <i className="check icon" /> Min/Max Imported Successfully
            </div>
          );
          props.setMinMaxOpen(false);
          setIsImporting(false);
          setJsonResult([]);
          setPayloadData([]);
        }
      } else {
        showErrorToast("Something went wrong");
        setIsImporting(false);
      }
    } catch {
      showErrorToast("Something went wrong");
      setIsImporting(false);
    }
  };

  // Error checker for quantity field
  function hasError(quantity) {
    let hasError = false;

    if (quantity === "") return false;
    if (isNaN(quantity)) {
      hasError = true;
    }
    if (!Number.isFinite(Number(parseFloat(quantity)))) {
      hasError = true;
    }
    if (quantity < 0) {
      hasError = true;
    }
    return hasError;
  }

  function hasProductIdError(productId) {
    if (/[^0-9]/.test(productId)) return true;
  }

  return (
    <React.Fragment>
      <MetaData title="Inventory" />
      <div style={{ position: "absolute", top: 250, left: 625 }}>
        {isLoading ? (
          <Loader
            active
            className="workaround"
            size="large"
            inline="centered"
          />
        ) : null}
      </div>
      <div disabled={isLoading} style={{ opacity: isLoading ? 0.3 : 1 }}>
        <Grid style={{ margin: 20, marginTop: 10, marginBottom: 0 }}>
          <Grid.Column
            width={16}
            style={{
              minHeight: 400,
              maxHeight: 400,
              overflowY: "auto",
            }}
          >
            {jsonResult.length > 0 ? (
              <Table sortable celled fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">
                      Product ID
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Product SKU
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">
                      Description
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">UoM</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Min</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Max</Table.HeaderCell>
                    {failedImports.length > 0 ? (
                      <Table.HeaderCell textAlign="center">
                        Remarks
                      </Table.HeaderCell>
                    ) : null}
                  </Table.Row>
                </Table.Header>
                <Table.Body style={{ fontSize: 13 }}>
                  {jsonResult.map((product) => (
                    <Table.Row key={product["Product ID"]}>
                      <Table.Cell
                        textAlign="center"
                        style={{
                          border: hasProductIdError(product["Product ID"])
                            ? "solid 2.5px red"
                            : "",
                        }}
                      >
                        {hasProductIdError(product["Product ID"]) ? (
                          <Popup
                            content={"Product ID must be numeric"}
                            trigger={<div>{product["Product ID"]}</div>}
                            position="top center"
                            inverted
                          />
                        ) : (
                          product["Product ID"]
                        )}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {product["Product SKU"]}
                      </Table.Cell>
                      <Table.Cell>{product["Description"]}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {product["UoM"]}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="center"
                        style={{
                          border: hasError(product.Min)
                            ? "solid 2.5px red"
                            : "",
                        }}
                      >
                        {/* Show popup message for data errors */}
                        {!Number.isFinite(
                          Number(parseFloat(product["Min"]))
                        ) ? (
                          <Popup
                            content={"Min must be a number"}
                            trigger={<div>{product["Min"]}</div>}
                            position="top center"
                            inverted
                          />
                        ) : isNaN(product["Min"]) ? (
                          <Popup
                            content={"Min must be a number"}
                            trigger={<div>{product["Min"]}</div>}
                            position="top center"
                            inverted
                          />
                        ) : product["Min"] < 0 ? (
                          <Popup
                            content={"Min can't be negative"}
                            trigger={<div>{product["Min"]}</div>}
                            position="top center"
                            inverted
                          />
                        ) : (
                          product["Min"]
                        )}
                      </Table.Cell>
                      <Table.Cell
                        textAlign="center"
                        style={{
                          border: hasError(product.Max)
                            ? "solid 2.5px red"
                            : "",
                        }}
                      >
                        {/* Show popup message for data errors */}
                        {!Number.isFinite(
                          Number(parseFloat(product["Max"]))
                        ) ? (
                          <Popup
                            content={"Max must be a number"}
                            trigger={<div>{product["Max"]}</div>}
                            position="top center"
                            inverted
                          />
                        ) : isNaN(product["Max"]) ? (
                          <Popup
                            content={"Max must be a number"}
                            trigger={<div>{product["Max"]}</div>}
                            position="top center"
                            inverted
                          />
                        ) : product["Max"] < 0 ? (
                          <Popup
                            content={"Max can't be negative"}
                            trigger={<div>{product["Max"]}</div>}
                            position="top center"
                            inverted
                          />
                        ) : (
                          product["Max"]
                        )}
                      </Table.Cell>
                      {failedImports.length > 0 ? (
                        <Table.Cell
                          textAlign="center"
                          style={{
                            border: "solid 2.5px red",
                          }}
                        >
                          {product.importReport
                            ? product.importReport
                            : `Product cannot be found. Product
                          Id might have been updated.`}
                        </Table.Cell>
                      ) : null}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            ) : (
              <div
                style={{
                  paddingTop: 70,
                  width: 420,
                  marginLeft: "auto",
                  marginRight: "auto",
                  fontSize: 22,
                  color: "#AFABAB",
                  lineHeight: 2.5,
                }}
              >
                <span>1. Export current Min/Max report as CSV</span>
                <br />
                <span>2. Edit the CSV file locally</span>
                <br />
                <span>3. Upload the CSV file for preview</span>
              </div>
            )}
          </Grid.Column>
          <Grid.Column width={6} style={{ paddingRight: 5 }}>
            <Button
              size="small"
              style={{ paddingLeft: 30, paddingRight: 30 }}
              onClick={() => onExportMinMax()}
            >
              EXPORT MIN/MAX CSV FILE
            </Button>
          </Grid.Column>
          <Grid.Column
            width={10}
            style={{ paddingLeft: 5, textAlign: "right" }}
          >
            <Button
              size="small"
              color="youtube"
              onClick={() => {
                setJsonResult([]);
                props.setMinMaxOpen(false);
              }}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              size="small"
              style={{
                backgroundColor: "#66CC23",
                color: "#FFF",
                marginRight: 10,
              }}
              onClick={() => csvRef.current.click()}
            >
              Load New File
            </Button>
            {/* Rerender upload file so user can upload the same file after */}
            {!isLoading ? (
              <input
                ref={csvRef}
                type="file"
                hidden
                onChange={handleFileChange}
              />
            ) : null}
            <input
              ref={csvRef}
              type="file"
              hidden
              onChange={handleFileChange}
            />
            <Button
              disabled={jsonResult.length === 0 || isLoading}
              size="small"
              style={{
                backgroundColor: "rgb(102, 204, 35)",
                color: "#fff",
                marginLeft: "auto",
              }}
              onClick={() => onImportMinMax()}
            >
              {!isImporting ? "Import" : "Importing..."}
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default MinMax;
