import MetaData from "../../components/MetaData";
import React, { useState, useEffect } from "react";
import { Grid, Icon } from "semantic-ui-react";
import { Chip } from "@mui/material";
import Arrangement from "./arrangement";
import Assembly from "./assembly";
import HisComponent from "./hisComponent";
import HISusers from "./hisUser";

const BOAHIS = () => {
  const isAdmin = localStorage.getItem("HISisAdmin");
  let features = [];
  if (isAdmin === "true") {
    features = [
      { id: 0, name: "Asset Model" },
      { id: 1, name: "Hose Assembly" },
      { id: 2, name: "Components" },
      { id: 3, name: "Edit Users" },
    ];
  } else {
    features = [
      { id: 0, name: "Asset Model" },
      { id: 1, name: "Hose Assembly" },
      { id: 2, name: "Components" },
    ];
  }

  const [selectedFeature, setSelectedFeature] = useState(0);

  const handleClick = (id) => {
    setSelectedFeature(id);
  };
  return (
    <React.Fragment>
      <MetaData title="Hydraulic Information System" />
      <Grid columns={16} divided style={{ marginTop: -30 }}>
        <Grid.Row style={{ paddingTop: 0, zIndex: 1, paddingLeft: "50px" }}>
          <h3>
            <Icon name="searchengin" /> Hydraulic Information System
          </h3>
        </Grid.Row>
        <Grid.Row>
          <div
            style={{
              fontSize: "24px",
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              paddingLeft: "50px",
            }}
          >
            Search By:{" "}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "20px",
            }}
          >
            {features.map((feature) => (
              <Chip
                key={feature.id}
                label={feature.name}
                style={{
                  paddingTop: "4px",
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "5px",
                  border: "2px solid #66CB23",
                  minWidth: "180px",
                  height: "40px",
                  alignContent: "center",
                  marginRight: "15px",
                  fontSize: "18px",
                  backgroundColor:
                    selectedFeature === feature.id ? "#66CB23" : "#FFFFFF",
                  color: selectedFeature === feature.id ? "#FFFFFF" : "#000000",
                }}
                variant="outlined"
                onClick={() => handleClick(feature.id)}
              />
            ))}
          </div>
        </Grid.Row>
        <Grid.Row>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "50px",
            }}
          >
            {selectedFeature === 0 && <Arrangement />}
            {selectedFeature === 1 && <Assembly />}
            {selectedFeature === 2 && <HisComponent />}
            {selectedFeature === 3 && <HISusers />}
          </div>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
};

export default BOAHIS;
