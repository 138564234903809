import {
  apiGetCrews,
  apiAddCrew,
  apiUpdateCrew,
  apiDeleteCrew,
  apiGetUsersByAccount,
  apiGetAllCrew
} from "./crew.api";
import { actions } from "react-redux-form";

const SET_CREWS = "SET_CREWS";
const ADD_CREW = "ADD_CREW";
const UPDATE_CREW = "UPDATE_CREW";
const DELETE_CREW = "DELETE_CREW";
const TOGGLE_FORM_FLAG = "TOGGLE_FORM_FLAG";
const SET_USERS = "SET_USERS";
const SET_CREW_FOR_DROPDOWN = "SET_CREW_FOR_DROPDOWN";

const initialState = {
  isForm: false,
  isMember: false,
  crews: [],
  users: [],
  response: {},
  allCrew: [],
  totalPages: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CREWS:
      const { totalPages, allCrews } = action.payload;
      return {
        ...state,
        crews: allCrews,
        totalPages: totalPages
      };
    case SET_CREW_FOR_DROPDOWN: {
      const { allCrews } = action.payload;
      return {
        ...state,
        allCrew: allCrews
      };
    }
    case ADD_CREW:
      return {
        ...state,
        response: action.payload
      };
    case UPDATE_CREW:
      return {
        ...state
      };
    case DELETE_CREW:
      return {
        ...state,
        response: action.payload
      };

    case TOGGLE_FORM_FLAG:
      const { isCrew, isMember } = action.payload;
      return {
        ...state,
        isForm: isCrew,
        isMember: isMember
      };
    case SET_USERS: {
      return {
        ...state,
        users: action.payload
      };
    }
    default:
      return state;
  }
};

export const actionSetMembers = members => {
  return dispatch => {
    dispatch(actions.change("forms.crewForm.members", members));
  };
};

export const actionSetCrewForm = form => {
  return dispatch => {
    dispatch(actions.change("forms.crewForm", form));
  };
};

export const actionResetForm = formName => {
  return dispatch => {
    dispatch(actions.reset("forms." + formName));
  };
};

export const actionSetMemberInfo = (field, value) => {
  return dispatch => {
    dispatch(actions.change("forms.memberForm." + field, value));
  };
};

export const actionGetCrews = (limit, pageNumber) => {
  return async dispatch => {
    try {
      const result = await apiGetCrews(limit, pageNumber);

      dispatch({ type: SET_CREWS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_CREWS, payload: e });
    }
  };
};

export const actionGetAllCrew = (list) => {
  return async dispatch => {
    try {
      const result = await apiGetAllCrew(list);

      dispatch({ type: SET_CREW_FOR_DROPDOWN, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_CREW_FOR_DROPDOWN, payload: e });
    }
  };
};

export const actionAddCrew = data => {
  return async dispatch => {
    try {
      const result = await apiAddCrew(data);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: ADD_CREW, payload: result });
      } else {
        dispatch({ type: ADD_CREW, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: ADD_CREW, payload: e.response });
    }
  };
};

export const actionUpdateCrew = data => {
  return async dispatch => {
    try {
      await apiUpdateCrew(data);
      dispatch({ type: UPDATE_CREW });
    } catch (e) {
      dispatch({ type: UPDATE_CREW, payload: e });
    }
  };
};

export const actionDeleteCrew = id => {
  return async dispatch => {
    try {
      const result = await apiDeleteCrew(id);
      const {status} = result;
      if(status === 200) {
        dispatch({ type: DELETE_CREW, payload: result });
      }
      else {      
      dispatch({ type: DELETE_CREW, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: DELETE_CREW, payload: e.response });
    }
  };
};

export const actionToggleForm = (isCrew, isMember) => {
  return async dispatch => {
    dispatch({ type: TOGGLE_FORM_FLAG, payload: { isCrew, isMember } });
  };
};

export const getUserList = () => {
  return async dispatch => {
    try {
      const result = await apiGetUsersByAccount();
      dispatch({ type: SET_USERS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_USERS, payload: [] });
    }
  };
};
