export const tempData = {
  id: 0,
  assetId: "",
  assetSerialNumber: "",
  model: "",
  otherAssetDetails: "",
  assetPropValue: "",
  year: "",
  industry: "",
  assetLocationId: null,
  assetImageUrl: "",
  operator: "",
  nonBoaServiceParts: "",
  connectedAssets: [],
  parentId: null,
  assetDbId: "",
  makeName: "",
  equipmentTypeName: "",
  assetPropUnit: "hrs",
  connectedAssetsIds: [],
};

export const tempBookData = {
  companyName: "",
  contactName: "",
  contactNumber: "",
  contactEmail: "",
  customerPurchaseOrderNumber: "",
  customerLocation: "",
  locationLat: "",
  locationLng: "",
  urgencyId: null,
  assetSerialNumber: "",
  assetId: "",
  otherAssetDetails: "",
  assetPropValue: "",
  assetPropUnit: "hrs",
  jobDesc: "",
  jobStatusId: 1,
  distanceTravelled: 0,
  hoursLabour: 0,
  parentName: "",
  saveContactDetails: false,
  companyAssetId: 0,
  uniqueId: "",
  deviceId: 0,
  customer: {
    id: 0,
    userName: "",
    companyName: "",
  },
  customerContact: {
    id: 0,
    customerId: 0,
    contactName: "",
    contactNumber: "",
    email: "",
  },
};

export const categories = [
  {
    key: 1,
    category: "Manufacturer",
  },
  {
    key: 2,
    category: "Equipment Type",
  },
  {
    key: 3,
    category: "Model Name",
  },
  {
    key: 4,
    category: "Industry",
  },
  {
    key: 5,
    category: "Location",
  },
  {
    key: 6,
    category: "Operator",
  },
];

export const rs23Categories = [
  {
    categoryId: 1,
    categoryName:
      "Design specifications, performance and operational conditions",
  },
  { categoryId: 2, categoryName: "Design documentation" },
  { categoryId: 3, categoryName: "As built schematic" },
  { categoryId: 4, categoryName: "Installation requirements" },
  {
    categoryId: 5,
    categoryName: "Hazard identification and risk assessment documents",
  },
  { categoryId: 6, categoryName: "Risk control methods" },
  {
    categoryId: 7,
    categoryName:
      "Identification of all safety-critical systems and their safety category or integrity level",
  },
  { categoryId: 8, categoryName: "Consultation records" },
  { categoryId: 9, categoryName: "Commissioning and test results" },
  { categoryId: 10, categoryName: "Operator and trade training manuals" },
  {
    categoryId: 11,
    categoryName: "Inspection, repair and replacement manuals",
  },
  { categoryId: 12, categoryName: "Troubleshooting guides" },
  {
    categoryId: 13,
    categoryName: "Maintenance records, safety inspections and test reports",
  },
  {
    categoryId: 14,
    categoryName: "Change of procedures, monitoring, audit and review reports",
  },
  {
    categoryId: 15,
    categoryName: "Reports of incidents, accidents and safety statistics",
  },
  { categoryId: 16, categoryName: "Fluid system alterations" },
  { categoryId: 17, categoryName: "Review of past incidents" },
];
