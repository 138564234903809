import React from "react";
import { Grid, Dropdown, Form, Button, Segment, Label, Icon, Image } from "semantic-ui-react";
import PropTypes from "prop-types";
import { FITTINGS_HYD, ADAPTORS_HYD } from "../../constants/appConstants";
import _ from "lodash";

const constructOptions = (data) => {
  if (_.isEmpty(data)) {
    return null;
  }
  data = _.compact(data);


  let options = [{ key: "", value: "", text: "ALL" }]
  data = data.map((value) => {
    return { key: value, value: value, text: value }
  })
  options = [...options, ...data]
  return options;
}

const constructThreadSizeOptions = (data) => {
  if (_.isEmpty(data)) {
    return null;
  }
  data = _.compact(data);
  let options = [{ key: "-1", value: "", text: "ALL" }]
  data = data.map((item) => {
    return { key: item.name, value: item.value, text: <div><Image style={{width: "75px"}}src={item.url} />{item.name}</div> }
  })
  options = [...options, ...data]
  return options;
}

class FittingsFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilters: true
    }
  }

  clearFilter() {
    this.setState({
      showFilters: false
    });
    this.props.clearThreadFilterHandler();

    setTimeout(() => {
      this.setState({
        showFilters: true
      });
    }, 1);
  }

  render() {

    const { showFilters } = this.state;
    if (!showFilters) {
      return null;
    }

    const {
      category,
      threadTypes1 = [],
      threadTypes2 = [],
      selectedThreadSize1 = [],
      selectedThreadSize2 = [],
      threadType1Handler,
      threadSize1Handler,
      threadType2Handler,
      threadSize2Handler,
      filtersApplied,
    } = this.props;

    const threadType1Options = constructOptions(threadTypes1);
    const threadType2Options = constructOptions(threadTypes2);
    const physicalThreadSizeOptions1 = constructThreadSizeOptions(selectedThreadSize1);
    const physicalThreadSizeOptions2 = constructThreadSizeOptions(selectedThreadSize2);

    const threadType1JSX = (
      <Grid columns="equal">
        <Grid.Column>
          <label>Select thread type 1</label>
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            placeHolder="Select thread type"
            selection
            onChange={(e, data) => threadType1Handler(data)}
            options={threadType1Options}
            style={{ minWidth: "150px" }}
          />
        </Grid.Column>
      </Grid>
    );

    const threadSize1JSX = (
      <Form>
        <Grid columns="equal">
          <Grid.Column computer={6}>
            <label>Thread size 1 (mm)</label>
          </Grid.Column>
          <Grid.Column computer={10}>
            <Dropdown
              placeHolder="Select thread type"
              selection
              onChange={(e, data) => threadSize1Handler(data)}
              options={physicalThreadSizeOptions1}
              style={{ minWidth: "220px" }}
              ref={this.threadType1}
            />
          </Grid.Column>
        </Grid>
      </Form>
    );
    const threadType2JSX = (
      <Grid columns="equal">
        <Grid.Column >
          <label>Select thread type 2</label>
        </Grid.Column>
        <Grid.Column >
          <Dropdown
            placeHolder="Select thread type"
            selection
            onChange={(e, data) => threadType2Handler(data)}
            options={threadType2Options}
            style={{ minWidth: "150px" }}
          />
        </Grid.Column>
      </Grid>
    );

    const filtersAppliedJSX = () => {
      const { threadType1, threadSize1, threadType2, threadSize2 } = filtersApplied;

      return <div>
        {(threadType1 || threadSize1) && <Label>
          <Icon name='filter' />Thread 1  <Label.Detail> {threadType1} {threadSize1 && <span> {threadSize1} mm</span>} </Label.Detail>
        </Label >}
        {(threadType2 || threadSize2) && <Label>
          <Icon name='filter' />Thread 2  <Label.Detail> {threadType2} {threadSize2 && <span> {threadSize2} mm</span>} </Label.Detail>
        </Label >}
      </div>
    }

    const threadSize2JSX = (
      <Form>
        <Grid>
          <Grid.Column computer={6}>
            <label>Thread size 2 (mm)</label>
          </Grid.Column>
          <Grid.Column computer={10}>
            <Dropdown
              placeHolder="Select thread type"
              selection
              onChange={(e, data) => threadSize2Handler(data)}
              options={physicalThreadSizeOptions2}
              style={{ minWidth: "220px" }}
            />
          </Grid.Column>
        </Grid>
      </Form>
    );

    if (![ADAPTORS_HYD, FITTINGS_HYD].includes(category)) {
      return null;
    }

    return (
      <Segment>
        <Grid >
          <Grid.Column mobile={16} tablet={8} computer={8}>{threadType1JSX}</Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>{threadSize1JSX}</Grid.Column>
        </Grid>
        {category === ADAPTORS_HYD && (
          <Grid>
            <Grid.Column mobile={16} tablet={8} computer={8}>{threadType2JSX}</Grid.Column>
            <Grid.Column mobile={16} tablet={8} computer={8}>{threadSize2JSX}</Grid.Column>
          </Grid>
        )}
        <Grid>
          <Grid.Column mobile={16} tablet={12} computer={12}>
            {filtersAppliedJSX()}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={4} computer={4}>
            <Button compact onClick={() => { this.clearFilter() }}> Clear Thread Filters</Button>
          </Grid.Column>

        </Grid>
      </Segment>
    );
  }
};

FittingsFilter.propTypes = {
  category: PropTypes.string,
  threadTypes1: PropTypes.array,
  threadTypes2: PropTypes.array,
  selectedThreadSize1: PropTypes.number,
  selectedThreadSize2: PropTypes.number,
  threadType1Handler: PropTypes.func,
  threadSize1Handler: PropTypes.func,
  threadType2Handler: PropTypes.func,
  threadSize2Handler: PropTypes.func
};

export default FittingsFilter;
