import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import { Control, Errors } from "react-redux-form";
import * as stockReducer from "./stock.reducer";
import MetaData from "../../components/MetaData";
import NumericInput from "react-numeric-input";
import StockReviewList from "../../components/StockReviewList";
import LocationDropdown from "../../components/LocationDropdown";
import {
  Grid,
  Divider,
  Icon,
  Button,
  Pagination,
  Modal,
  Form,
  Confirm
} from "semantic-ui-react";
import * as inventoryLocation from "../InventoryLocation/inventoryLocation.reducer";
//import { Model } from "mongoose";
import polyglot from "../../i18n";
import _ from "lodash";

const paginationLimit = 50;
const STOCK_CODE = "stockCode";
const STOCK_FORM = "stockForm";
class StockReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activePage: 1,
      location: "",
      isError: false,
      errorMessage: "",
      isOpenModal: false,
      isOpenForm: false,
      isConformForm: false,
      isCheckValue: false
    };
  }

  componentWillMount = async () => {
    await this.props.actionClearReducerItems();
  };
  componentDidMount = async () => {
    this.setState({ isLoading: true });
    await this.props.actionGetAllLocationToDropdown();
    const { locations, locationName } = this.props;
    this.setState({ isLoading: false });
    if (locations.length && locationName.length) {
      this.getStock(locationName, 0);
      return;
    }

    if (locations.length) {
      this.props.actionLocationName(locations[0].name);
      this.getStock(locations[0].name, 0);
    }
  };

  getStock = async (location, pageNumber) => {
    this.setState({ isLoading: true });
    this.setState({ location });
    await this.props.actionGetStock(location, paginationLimit, pageNumber);
    this.setState({ isLoading: false });
  };

  goBack = () => {
    this.props.history.push("/inventory");
  };

  setLocation = async (e, data) => {
    const { value } = data;
    this.props.actionLocationName(value);
    await this.getStock(value);
  };

  updateStock = async () => {
    const { forms } = this.props;
    const { stockForm } = forms;
    this.setState({ isOpenForm: false, isConformForm: false });
    await this.props.actionUpdateStock(stockForm);
    const { locationName } = this.props;
    // this.setState({isConformForm:})

    await this.getStock(locationName);
  };

  changeQuantity = () => {
    const { forms } = this.props;
    const { stockForm } = forms;
    if (stockForm.notes === undefined) {
      this.setState({ isConformForm: false });
    } else {
      this.setState({ isConformForm: true });
    }
  };

  closeForm = () => {
    this.setState({ isOpenForm: false, isConformForm: false });
  };

  updateState = (isError, errorMessage) => {
    this.setState({ isError, errorMessage, isLoading: false });
  };

  openChangeModal = data => {
    this.props.actionSetInitialForm(data);
    this.setState({ isOpenForm: true });
  };

  goToAudits = stockCode => {
    this.props.history.push({
      pathname: "/audit",
      search: `?${STOCK_CODE}=${stockCode}`
    });
  };

  handlePaginationChange = (...params) => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    const options = params[1];
    const { activePage } = options;
    const { totalPages } = this.props;
    const { location } = this.state;
    if (totalPages === 1) {
      return;
    }

    this.setState({
      activePage
    });
    this.getStock(location, activePage - 1);
  };

  textHandle = event => {
    // var newValue = event.target.value;
    const { target } = event;
    const { value } = target;
    let newValue = value;
    if (newValue.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  };

  quantityChangeHandler = (e, data) => {
    this.setState({ isCheckValue: false });
    if (e === 0) {
      this.setState({ isCheckValue: true });
    }
    this.props.actionSetQuantity(`${STOCK_FORM}.quantity`, e);
  };

  render() {
    const {
      isLoading,
      activePage,
      isError,
      errorMessage,
      isConformForm,
      isCheckValue
    } = this.state;
    const { allStock, locationName, locations } = this.props;
    const { totalPages, inventories } = allStock;
    const locationDropdown = (
      <React.Fragment>
        <LocationDropdown
          setLocation={this.setLocation}
          locationId={locationName}
          locations={locations}
        />
        {_.isEmpty(locations) ? (
          <span style={{ color: "red" }}>
            No locations available, Create a new one!
          </span>
        ) : null}
      </React.Fragment>
    );

    const AddModal = (
      <Modal
        size={"small"}
        open={this.state.isOpenForm}
        onClose={() => this.closeForm()}
      >
        <Modal.Header>Add Quantity</Modal.Header>
        <Modal.Content>
          <Form
            model="forms.stockForm"
            error={isError}
            onSubmit={this.changeQuantity}
          >
            <Grid>
              <Grid.Column>
                <Form.Field required>
                  <label>{polyglot.t("stockForm.quantity")}</label>

                  <NumericInput
                    type="number"
                    min={1}
                    max={10000}
                    maxLength={5}
                    className="form-control"
                    validateOn="change"
                    placeholder="Quantity"
                    required
                    onChange={this.quantityChangeHandler}
                    onKeyPress={event => this.textHandle(event)}
                  />
                  <Errors
                    style={{ color: "red" }}
                    model="forms.stockForm.quantity"
                    show="touched"
                    messages={{
                      valueMissing: polyglot.t("stockForm.required.quantity")
                    }}
                  />
                  {isCheckValue && (
                    <span style={{ color: "red" }}>Quantity can not be 0</span>
                  )}
                </Form.Field>
                <Form.Field required>
                  <label>Notes</label>
                  <Control.textarea
                    model="forms.stockForm.notes"
                    placeholder="Notes"
                    validateOn="change"
                    rows={3}
                    required
                    className="input-box"
                    onKeyPress={event => this.textHandle(event)}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
            <Grid>
              <Grid.Column
                width={16}
                computer={16}
                mobile={16}
                style={{ textAlign: "right" }}
              >
                <Button
                  size="small"
                  onClick={this.closeForm}
                  content=" Close"
                />
                {!isLoading && (
                  <Button
                    secondary
                    size="small"
                    content="Submit"
                    type="submit"
                  />
                )}
                {isLoading && (
                  <Button secondary size="small" content="Submit" loading />
                )}
              </Grid.Column>
            </Grid>
          </Form>
        </Modal.Content>
      </Modal>
    );

    return (
      <div className="my-inventory">
        <Confirm
          open={isConformForm}
          content="Do you want to change quantity?"
          onCancel={() => this.setState({ isConformForm: false })}
          onConfirm={this.updateStock}
        />
        <MetaData title="Stock Review" />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading,please wait</Loader>
          )}

          <Grid.Column width={16}>
            <Grid>
              <Grid.Column computer={10} mobile={16} width={10}>
                <h3>
                  <Icon name="table" />
                  Stock Review
                </h3>
              </Grid.Column>
              <Grid.Column
                textAlign="right"
                tablet={8}
                mobile={16}
                computer={6}
              >
                <Button
                  size="tiny"
                  onClick={this.goBack}
                  icon="left arrow"
                  content="Go back"
                  labelPosition="left"
                  className="button-float"
                />
              </Grid.Column>
            </Grid>
            <Divider />
          </Grid.Column>
          <Grid.Column
            tablet={8}
            mobile={16}
            computer={6}
            style={{ padding: "0px 15px" }}
          >
            {locationDropdown}
          </Grid.Column>
          <Grid.Column width={16}>
            <StockReviewList
              allStock={inventories}
              updateStock={this.updateStock}
              openChangeModal={this.openChangeModal}
              isError={isError}
              errorMessage={errorMessage}
              goToAudits={this.goToAudits}
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <div>
              {!_.isEmpty(inventories) ? (
                <Pagination
                  activePage={activePage}
                  boundaryRange={1}
                  onPageChange={this.handlePaginationChange}
                  totalPages={totalPages}
                  firstItem={null}
                  lastItem={null}
                />
              ) : null}
            </div>
          </Grid.Column>
        </Grid>
        <Grid.Column width={16} mobile={16} tablet={8}>
          {AddModal}
        </Grid.Column>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allStock: state.stock.allStock,
    locationName: state.stock.locationName,
    locations: state.inventoryLocation.locations,
    isModalOpen: state.inventory.isModalOpen,
    forms: state.forms
  };
};

const reducer = { ...stockReducer, ...inventoryLocation };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(StockReview)
);
