import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as hoseMakerReducer from "./hoseMaker.reducer";
import "./HoseMakerContainer/hoseMaker.css";
import * as hoseUtil from "./hoseMaker.util";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import * as cartReducer from "../Cart/cart.reducer";
import HoseMakerContainer from "./HoseMakerContainer/index";
import MetaData from "../../components/MetaData";
import {
  ASSET_ID_FOR_URL_PARAM,
  HOSE_ID_FOR_URL_PARAM,
  ASSET_NAME_FOR_URL_PARAM,
  ASSET_MODEL_FOR_URL_PARAM,
  ASSET_SUB_MODEL_FOR_URL_PARAM,
  ASSET_POSITION_FOR_URL_PARAM,
  ASSET_POSITION_ID_FOR_URL_PARAM,
  ASSET_MAKE_FOR_URL_PARAM
} from "../../constants/appConstants";
import * as hoseMechanicReducer from "../HoseMechanic/hoseMechanic.reducer";
import _ from "lodash";
import SmallerScreenDisplayMessage from "./SmallerScreenDisplayMessage";
class HoseMaker extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      showModal: false,
      iAgree: false,
      closeOnDimmerClick: false,
      closeOnEscape: false,
      hoseImageData: null,
      phase: 1,
      width: 0,
      height: 0
    };
  }

  updateState = state => {
    this.setState(state);
  };

  componentWillMount = async () => {
    const params = this.getParams();
    const { assetId, position, hoseId } = params;
    this.props.actionSetPathParams(params);
    if (hoseId) {
      await this.setHose(hoseId);
      this.setState({ phase: 2 });
    }
    if (assetId && position) {
      this.props.actionSetPathParams(params);
      this.setState({ phase: 2 });
    }
    if (assetId && position && hoseId) {
      await this.setHose(hoseId);
      this.props.actionSetPathParams(params);
      this.props.actionSetFlowForHoseMaker("editHoseForAsset");
      this.setState({ phase: 2 });
    }
    this.updateDimensions();
  };

  getParams = () => {
    let url = document.location.href;
    url = new URL(url);
    const { searchParams } = url;
    const assetId = searchParams.get(ASSET_ID_FOR_URL_PARAM);
    const position = searchParams.get(ASSET_POSITION_FOR_URL_PARAM);
    const hoseId = searchParams.get(HOSE_ID_FOR_URL_PARAM);
    const model = searchParams.get(ASSET_MODEL_FOR_URL_PARAM);
    const subModel = searchParams.get(ASSET_SUB_MODEL_FOR_URL_PARAM);
    const assetName = searchParams.get(ASSET_NAME_FOR_URL_PARAM);
    const positionId = searchParams.get(ASSET_POSITION_ID_FOR_URL_PARAM);
    const make = searchParams.get(ASSET_MAKE_FOR_URL_PARAM);
    return {
      assetId,
      position,
      hoseId,
      model,
      subModel,
      assetName,
      positionId,
      make
    };
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  componentDidMount = () => {
    window.addEventListener("resize", this.updateDimensions);
  };

  componentWillUnmount = () => {
    const { hoseId, assetId } = this.props;
    if (assetId || hoseId) {
      this.clearHoseHandler();
    }
    window.removeEventListener("resize", this.updateDimensions);
  };

  setHose = async hoseId => {
    this.setState({ isLoading: true });
    const hoseData = await this.props.actionGetHoseDataFromServer(hoseId);
    if (_.isEmpty(hoseData)) {
      showErrorToast("Hose not found");
      await this.clearHoseHandler();
      this.props.history.push("/hoseMaker");
      return;
    }
    this.setState({ isLoading: false });
  };

  addHoseToCartHandler = async (
    toHoseMechanic = false,
    buttonText = "",
    saveHoseRecipe = false
  ) => {
    const {
      hoseType,
      leftFittingTypes,
      rightFittingTypes,
      spiralGuard,
      additionalProduct,
      hoseLength,
      rightFittingAngle,
      leftFittingAngle,
      makeAtBoa,
      totalPrice,
      hoseDescription,
      leftCrimpSize,
      rightCrimpSize,
      hoseQuantity,
      rightFerrule,
      leftFerrule,
      editHose,
      leftSeatTypeImage,
      rightSeatTypeImage,
      makeAtBoaCharges,
      overAllLength,
      uniqueCode,
      hoseId,
      showOal
    } = this.props;
    const isValid = hoseUtil.validateHose(
      hoseType,
      leftFittingTypes,
      rightFittingTypes
    );
    if (!isValid) {
      showErrorToast("Please complete your hose first");
      return false;
    }
    this.setState({ isLoading: true });
    const hoseObjectToSend = hoseUtil.hoseObjectToSend(
      hoseType,
      leftFittingTypes,
      rightFittingTypes,
      hoseLength,
      rightFittingAngle,
      leftFittingAngle,
      hoseDescription,
      makeAtBoa,
      totalPrice,
      spiralGuard,
      additionalProduct,
      leftCrimpSize,
      rightCrimpSize,
      leftFerrule,
      rightFerrule,
      leftSeatTypeImage,
      rightSeatTypeImage,
      makeAtBoaCharges,
      editHose,
      uniqueCode,
      hoseId,
      overAllLength,
      showOal
    );
    const { stockDescription } = hoseObjectToSend;
    if (toHoseMechanic) {
      const param = this.getParams();
      const {
        assetId,
        position,
        assetName,
        model,
        subModel,
        positionId,
        make
      } = param;
      const length = overAllLength ? overAllLength : hoseLength;
      hoseObjectToSend.toHoseMechanic = true;
      hoseObjectToSend.assetId = assetId;
      hoseObjectToSend.position = position;
      hoseObjectToSend.assetName = assetName;
      hoseObjectToSend.model = model;
      hoseObjectToSend.subModel = subModel;
      hoseObjectToSend.positionId = positionId;
      if (assetId) {
        hoseObjectToSend.stockDescription = this.phaseTwoHoseDescriptionForAsset(
          model,
          subModel,
          position,
          rightFittingAngle,
          length,
          hoseDescription
        );
      }
      hoseObjectToSend.quantity = hoseQuantity;
      await this.props.actionSaveToHoseMechanic(hoseObjectToSend);
      const { saveHoseToHoseMechanicResponse } = this.props;
      const { stockCode, uniqueCode, hoseId } = saveHoseToHoseMechanicResponse;
      if (assetId) {
        const saveHoseRecipeInDb = {
          hoseId,
          hoseUniqueCode: uniqueCode,
          positionId,
          model,
          make
        };
        await this.props.actionSaveHoseRecipe(saveHoseRecipeInDb);
      }
      showSuccessToast("Hose saved and sent to hose mechanic : " + stockCode);
      return;
    }
    if (editHose && !saveHoseRecipe && buttonText !== "Add to cart") {
      // added for replace hose flow, on click of order hose from boa
      //
      // update hose code
      const param = this.getParams();
      const { hoseId } = param;
      hoseObjectToSend._id = hoseId;
      const { model, subModel, position, assetId } = this.props;
      const length = overAllLength ? overAllLength : hoseLength;
      if (assetId) {
        hoseObjectToSend.stockDescription = this.phaseTwoHoseDescriptionForAsset(
          model,
          subModel,
          position,
          rightFittingAngle,
          length
        );
      }
      hoseObjectToSend.quantity = hoseQuantity;
      await this.props.actionUpdateHoseOfHoseMechanic(hoseObjectToSend);
      await this.setHose(hoseId);
      showSuccessToast("Hose updated successfully");
      this.setState({ isLoading: false });
      return;
    }
    if (saveHoseRecipe) {
      const param = this.getParams();
      const {
        assetId,
        position,
        assetName,
        model,
        subModel,
        positionId,
        make
      } = param;
      const length = overAllLength ? overAllLength : hoseLength;
      hoseObjectToSend.toHoseMechanic = false;
      hoseObjectToSend.assetId = assetId;
      hoseObjectToSend.position = position;
      hoseObjectToSend.assetName = assetName;
      hoseObjectToSend.model = model;
      hoseObjectToSend.subModel = subModel;
      if (assetId) {
        hoseObjectToSend.stockDescription = this.phaseTwoHoseDescriptionForAsset(
          model,
          subModel,
          position,
          rightFittingAngle,
          length,
          hoseDescription
        );
      }
      hoseObjectToSend.quantity = hoseQuantity;
      await this.props.actionSaveToHoseMechanic(hoseObjectToSend);
      const { saveHoseToHoseMechanicResponse: response } = this.props;
      const {
        stockCode,
        uniqueCode: savedUniqueCode,
        hoseId: savedHoseId
      } = response;
      const attachHoseToAssetData = {
        assetId,
        location: "",
        position,
        quantity: hoseQuantity,
        stockCode,
        description: hoseDescription,
        hoseId: savedHoseId,
        hoseUniqueCode: savedUniqueCode,
        positionId: positionId
      };

      const attachResult = await this.props.actionAttachHoseToAsset(
        attachHoseToAssetData
      );
      const saveHoseRecipeInDb = {
        hoseId,
        hoseUniqueCode: uniqueCode,
        positionId,
        model,
        make
      };
      await this.props.actionSaveHoseRecipe(saveHoseRecipeInDb);
      const { status } = attachResult;
      this.setState({ isLoading: false });
      if (status === 200) {
        showSuccessToast("Hose attached to asset successfully");
        this.props.history.push(`assetDetails/${assetId}`);
      } else {
        showErrorToast("Something went wrong , please try again");
      }

      return;
    } else {
      await this.props.actionSaveHoseToDB(hoseObjectToSend);
      const { response } = this.props;
      const { hoseId, stockCode: savedHoseStockCode } = response;
      const addToCartObject = {
        quantity: hoseQuantity,
        length: 1,
        pack: "Each",
        hose: true,
        stockCode: savedHoseStockCode,
        stockDescription: stockDescription,
        hoseId
      };
      // add to cart call
      await this.props.actionAddHoseToCart(addToCartObject);
      showSuccessToast("Hose added to cart");
      this.setState({ isLoading: false, showModal: false });
      this.clearHoseHandler();
    }
  };

  clearHoseHandler = async () => {
    await this.props.actionClearHoseData();
  };

  phaseTwoHoseDescriptionForAsset = (
    model,
    subModel,
    position,
    rightFittingAngle,
    length,
    hoseDescription
  ) => {
    let phase2AssetToHoseDescription = "";
    if (model) {
      phase2AssetToHoseDescription = `${phase2AssetToHoseDescription} ${model}`;
    }
    if (subModel) {
      phase2AssetToHoseDescription = `${phase2AssetToHoseDescription} ${subModel}`;
    }
    if (position) {
      phase2AssetToHoseDescription = `${phase2AssetToHoseDescription} ${position}`;
    }
    return `${phase2AssetToHoseDescription} Angle : ${rightFittingAngle} Length : ${length} ${hoseDescription}`;
  };

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  componentDidMount = () => {
    window.addEventListener("resize", this.updateDimensions);
  };
  // componentWillUnmount = () => {
  //   window.removeEventListener("resize", this.updateDimensions);
  // };

  render() {
    const { phase, width } = this.state;
    return (
      <React.Fragment>
        <MetaData title="Hose maker" />
        {width > 786 ? (
          <HoseMakerContainer
            phase={phase}
            hoseHandler={this.addHoseToCartHandler}
            buttonText={"Add to cart"}
          />
        ) : (
          <SmallerScreenDisplayMessage />
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  hoseType: state.hoseMaker.hoseType,
  leftFittingTypes: state.hoseMaker.leftFittingTypes,
  rightFittingTypes: state.hoseMaker.rightFittingTypes,
  hoseLength: state.hoseMaker.hoseLength,
  rightFittingAngle: state.hoseMaker.rightFittingAngle,
  leftFittingAngle: state.hoseMaker.leftFittingAngle,
  spiralGuard: state.hoseMaker.spiralGuard,
  hoseDiameters: state.hoseMaker.hoseDiameters,
  orientation: state.hoseMaker.orientation,
  measurement: state.hoseMaker.measurement,
  additionalProduct: state.hoseMaker.additionalProduct,
  makeAtBoa: state.hoseMaker.makeAtBoa,
  totalPrice: state.hoseMaker.totalPrice,
  response: state.hoseMaker.response,
  hoseDescription: state.hoseMaker.hoseDescription,
  leftCrimpSize: state.hoseMaker.leftCrimpSize,
  rightCrimpSize: state.hoseMaker.rightCrimpSize,
  overAllLength: state.hoseMaker.overAllLength,
  hoseQuantity: state.hoseMaker.hoseQuantity,
  leftFerrule: state.hoseMaker.leftFerrule,
  rightFerrule: state.hoseMaker.rightFerrule,
  editHose: state.hoseMaker.editHose,
  leftSeatTypeImage: state.hoseMaker.leftSeatTypeImage,
  rightSeatTypeImage: state.hoseMaker.rightSeatTypeImage,
  makeAtBoaCharges: state.hoseMaker.charges,
  saveHoseToHoseMechanicResponse:
    state.hoseMechanic.saveHoseToHoseMechanicResponse,
  model: state.hoseMaker.model,
  subModel: state.hoseMaker.subModel,
  assetName: state.hoseMaker.assetName,
  assetId: state.hoseMaker.assetId,
  position: state.hoseMaker.position,
  hoseId: state.hoseMaker.hoseId,
  uniqueCode: state.hoseMaker.uniqueCode,
  hoseId: state.hoseMaker.hoseId,
  showOal: state.hoseMaker.showOal
});

const reducer = { ...hoseMakerReducer, ...cartReducer, ...hoseMechanicReducer };

const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HoseMaker);
