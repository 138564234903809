import React from "react";
import NumericInput from "react-numeric-input";
import PropTypes from "prop-types";

const NumericInputBox = ({
  min = 1,
  max = 100000,
  value = 1,
  onChangeHandler,
  onKeyDownHandler,
  onBlurHandler,
  precision = 0,
}) => {
  return (
    <NumericInput
      onChange={onChangeHandler}
      onKeyDown={onKeyDownHandler}
      onBlur={onBlurHandler}
      name="quantity"
      min={min}
      max={max}
      className="form-control numeric-input"
      precision={precision}
      step={1}
      value={value}
      strict
    />
  );
};

NumericInputBox.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  value: PropTypes.number,
  onChangeHandler: PropTypes.func,
  onKeyDownHandler: PropTypes.func,
  onBlurHandler: PropTypes.func,
};

export default NumericInputBox;
