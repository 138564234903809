import React from "react";
import PropTypes from "prop-types";
import { Label, Button } from "semantic-ui-react";
import "./addressDetails.css";
import { countries, states } from "../../constants/appConstants";

const AddressDetails = ({
  makePrimary,
  address,
  deleteAddressHandler,
  index,
  options = false,
  addHandler,
  cancelHandler,
}) => {
  if (!address) {
    return null;
  }
  const {
    street = "",
    street2 = "",
    state = "",
    postalCode = "",
    country = "",
    city = "",
    isPrimary = false,
    suburb = "",
    salesforceId,
  } = address;

  const optionsJXS = (
    <div className="address-options">
      {!isPrimary && (
        <span className="make-default" onClick={() => makePrimary(address.id)}>
          {" "}
          Make default{" "}
        </span>
      )}
      <span
        className="delete"
        onClick={() => deleteAddressHandler(index, salesforceId)}
      >
        {" "}
        Delete{" "}
      </span>
    </div>
  );

  const addJSX = (
    <Button.Group style={{ width: "100%" }}>
      <Button
        onClick={() => {
          cancelHandler();
        }}
      >
        Cancel
      </Button>
      <Button.Or />
      <Button
        positive
        onClick={() => {
          addHandler(address, countries, states);
        }}
      >
        Save
      </Button>
    </Button.Group>
  );

  const DefaultAddressRibon = () => {
    return (
      <Label color="green" ribbon>
        Default Address
      </Label>
    );
  };

  return (
    <div className="address-detail-card">
      {isPrimary && <DefaultAddressRibon />}
      <div className="content">
        <div>
          {street} {street2}
        </div>
        {suburb && <div>{suburb}</div>}
        <div>
          {city + ", "} {city !== state ? state : city}
        </div>
        <div> {country} </div>
        <div> {postalCode} </div>
      </div>
      <div className="options">{options && optionsJXS}</div>
      {addHandler && addJSX}
    </div>
  );
};

AddressDetails.propTypes = {
  index: PropTypes.number,
  options: PropTypes.bool,
  makePrimary: PropTypes.func,
  address: PropTypes.object,
  deleteAddressHandler: PropTypes.func,
  addHandler: PropTypes.func,
  cancelHandler: PropTypes.func,
  editContactInfo: PropTypes.func,
};

export default AddressDetails;
