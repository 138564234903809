import React from "react";
import { Grid } from "semantic-ui-react";
import NotFound from "./notFound";
import ContentCard from "./contentCard";
import "./contentCardList.css";

const ContentViewList = ({ selectedTopic, subTopics }) => {
  return (
    <Grid.Row>
      {subTopics.length === 1 && subTopics[0].contents[0].contentId === null
        ? NotFound()
        : selectedTopic && (
            <div style={{ paddingLeft: "50px", width: "100%" }}>
              {subTopics.map((subTopic) => (
                <div key={subTopic.subtopicId} style={{ marginBottom: "40px" }}>
                  {subTopic.subtopicId && (
                    <p className="sub_topic_header">{subTopic.subtopicName}</p>
                  )}
                  {subTopic.contents.length === 1 &&
                  subTopic.contents[0].contentId === null ? (
                    NotFound()
                  ) : (
                    <Grid columns={4}>
                      {subTopic.contents.map((content) => (
                        <Grid.Column key={content.contentId}>
                          <ContentCard content={content} />
                        </Grid.Column>
                      ))}
                    </Grid>
                  )}
                </div>
              ))}
            </div>
          )}
    </Grid.Row>
  );
};

export default ContentViewList;
