import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Control, Errors } from "react-redux-form";
import NumericInput from "react-numeric-input";
import Loader from "../../components/Loader";
import * as inventoryReducer from "./inventory.reducer";
import LocationDropdown from "../../components/LocationDropdown";
import { Grid, Divider, Form, Button, Message } from "semantic-ui-react";

const CONSUME_STOCK = "consumeStockForm";

class ConsumeStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      stockId: "",
      inventoryType: "",
      location: "",
      value: ""
    };
  }

  setLocation = (e, data) => {
    const { value } = data;
    this.props.actionInventorySetFormField(`${CONSUME_STOCK}.location`, value);
  };

  consumeStock = async e => {
    const { forms } = this.props;
    const { consumeStockForm } = forms;
    this.setState({ isLoading: true });
    await this.props.actionConsumeStock(consumeStockForm);
    this.setState({ isLoading: false });
  };

  textHandle = event => {
    // var newValue = event.target.value;
    const { target } = event;
    const { value } = target;
    let newValue = value;
    if (newValue.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  };

  quantityChangeHandler = (e, data) => {
    this.props.actionSetQuantity(`${CONSUME_STOCK}.quantity`, e);
  };

  render() {
    const { isLoading } = this.state;
    const { forms, stock, isError, errorMessage } = this.props;
    const { location } = forms.consumeStockForm;
    const { locationQuantity } = stock;

    const fromLocations = [];

    Object.keys(locationQuantity).forEach(function(key) {
      if (locationQuantity[key] != null && locationQuantity[key] > -1) {
        fromLocations.push({ name: key, quantity: locationQuantity[key] });
      }
    });

    const locationDetail = (
      <Form.Field required>
        <label>From</label>
        <LocationDropdown
          setLocation={this.setLocation}
          locationId={location}
          locations={fromLocations}
        />
      </Form.Field>
    );

    const quantity = (
      <div>
        <Grid columns="equal">
          <Grid.Column width={16}>
            <Form.Field>
              <label>Quantity</label>

              <NumericInput
                min={1}
                max={10000}
                maxLength={5}
                className="form-control"
                placeholder="Quantity"
                required
                onChange={this.quantityChangeHandler}
              />
              <Errors
                style={{ color: "red" }}
                model="forms.consumeStockForm.quantity"
                show="touched"
                messages={{
                  valueMissing: "quantity is required",
                  rangeUnderflow: "Please enter valid quantity"
                }}
              />
            </Form.Field>
          </Grid.Column>
        </Grid>
      </div>
    );

    const notes = (
      <Form.Field required>
        <label>Notes</label>
        <Control.textarea
          model="forms.consumeStockForm.notes"
          placeholder="Notes"
          validateOn="change"
          rows={3}
          required
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.consumeStockForm.notes"
          show="touched"
          messages={{
            valueMissing: "Notes is required"
          }}
        />
      </Form.Field>
    );

    return (
      <div>
        {isLoading && (
          <Loader title="Loading, please wait">Loading,please wait</Loader>
        )}
        <Form
          error={isError}
          model="form.consumeStockForm"
          onSubmit={this.props.consumeStock}
          style={{ width: "100%" }}
        >
          <Grid columns="equal">
            <Grid.Column width={8} computer={8} mobile={16}>
              {locationDetail}
            </Grid.Column>

            <Grid.Column width={8} computer={8} mobile={16}>
              {quantity}
            </Grid.Column>

            <Grid.Column width={16} computer={16} mobile={16}>
              {notes}
            </Grid.Column>

            <Grid.Column width={16} computer={16} mobile={16}>
              <Divider />
            </Grid.Column>
          </Grid>
          <Message error={true} content={errorMessage} />
          <Grid.Column
            width={16}
            computer={16}
            mobile={16}
            style={{ textAlign: "right" }}
          >
            <Button
              size="small"
              onClick={this.props.closeForm}
              content="Close"
            />
            {!isLoading && (
              <Button secondary size="small" content="Submit" type="submit" />
            )}
            {isLoading && (
              <Button secondary size="small" content="Submit" loading />
            )}
          </Grid.Column>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allInventory: state.inventory.allInventory,
    stock: state.inventory.stock,
    response: state.inventory.response,
    forms: state.forms
  };
};

const reducer = { ...inventoryReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ConsumeStock)
);
