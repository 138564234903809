export const AUTH = "/api/auth/";
export const LOGIN = "logIn";
export const LOGOUT = "logout";
export const SIGN_UP = "register";
export const VERIFY = "verify-user";
export const FORGOT_PASSWORD = "forgot-password";
export const RESET_PASSWORD = "reset-password";
export const CREATE_PASSWORD = "create-password";
export const RESEND_ACCOUNT_VERIFICATION_EMAIL =
  "resendAccountVerificationEmail";
export const VERIFY_NEW = "verifyNewEmail";
export const HEALTH = "/health";

export const USER = "/api/user/";
export const INFO = "info";
export const PROFILE_INFO = "info";
export const UPDATE_USER_INFO = "update-userinfo";

export const CHANGE_PASSWORD = "change-password";
export const RESEND_VERIFY_NEW_EMAIL_LINK = "resendNewEmailVerificationLink";
export const CAF = "cafForm";
export const USER_LIST = "list";

export const ACCOUNT = "account/";
export const STATISTICS = "statistics";
export const INTERESTED_IN_BOA_EMAIL = "interestedInBoaEmail";
export const DETAILS = "details";
export const PRICE_MARGIN = "priceMargin";

export const ORDER = "/api/SalesOrder";
export const CREDIT = "place/credit";
export const INITIATE_PAYMENT_GATEWAY = "initiate/paymentGateway";
export const PLACE_PAYMENT_GATEWAY = "place/paymentGateway";
export const HISTORY = "history";
export const MOST_ORDERED = "mostOrdered";

export const CART = "api/Cart";

export const PRODUCTS = "/api/Products/";
export const CATEGORIES = "categories";
export const SEARCH = "search";
export const AUTOCOMPLETE = "autoComplete";

export const HOSE_MAKER = "hoseMaker";
export const HOSE_CATEGORIES = "categories";
export const HOSE_ALL_SPIRAL_GUARDS = "getAllSpiralGuards";

export const HOSE_PRODUCT_DETAILS = "getProductDetails";

export const PRICING_POLICY = "pricingPolicy";
export const GET_POLICY = "getPolicy";
export const GET_HOSE_DIAMETERS = "getHoseDiameter";
export const SEARCH_HOSE = "searchHose";
export const SEARCH_FITTER = "getFitter";

export const ASSET_LOCATION = "assetLocation";

export const CREW = "asset/crew";

export const ASSETS = "asset/";

export const GET_ASSET_TYPE_DETAILS = "getAssetTypeDetails/";
export const ASSET_TYPE = "assetType";

export const SET_DEFAULT_DELIVERY_ADDRESS = "set-default-address/";
export const DELIVERY_ADDRESS_ROUTE = "deliveryAddress";
export const PICKUP_ADDRESS = "pickUpAddress";
export const RELATED_CONTACTS = "/api/Contacts";

export const INVENTORY = "inventory/";
export const ADD_FORM_HISTORY = "addFromHistory";
export const TRANSFER_LOCATION = "transferLocation";
export const SELL_STOCK = "sellStock";
export const CONSUME_STOCK = "consumeStock";
export const AUDIT_TRAIL = "auditTrail";
export const REVIEW_STOCK = "reviewStock";
export const GET_REVIEW_STOCK = "reviewStock";
export const ASSIGN_STOCK = "assignStock";
export const TYPE_LIST = "type";

export const INVENTORY_LOCATION = "inventoryLocation/";

export const MAINTENANCE_FORM = "maintenanceForm/";
export const CHECK_POINT = "checkPoint";
export const ALL = "all";

export const SCHEDULE = "schedule/";

export const FITTING_AUTO_COMPLETE = "autoCompleteFittings";
export const GET_FITTING_MEASUREMENT = "getFittingMeasurementByOrientation";
export const HOSE_ADDITIONAL_PRODUCT_AUTO_COMPLETE =
  "additionalProductAutoCompleter";
export const HOSE_ADDITIONAL_PRODUCT_SEARCH = "additionalProductSearch";
export const MAKE_AT_BOA_CHARGES = "makeAtBoaCharges";
export const SAVE_HOSE = "saveHose";
export const SAVE_HOSE_IMAGE = "saveHoseImage";

export const HOSE_AUTO_COMPLETE = "hoseAutocomplete";
export const HOSE_SEARCH_BY_AUTO_COMPLETE_VALUE = "searchHoseByInput";
export const GET_CRIMP_SIZE = "getCrimpSize";
export const MY_HOSES = "myHoses";

export const GET_HOSE_DATA = "getHoseDetails";
export const GET_FITTING_ORIENTATIONS = "getDistinctOrientations";
export const GET_HOSE_MECHANIC_HOSES = "getHosesForHoseMechanic";
export const CHECK_HOSE_ITEMS = "checkHoseItems";
export const GET_FERRULE = "getFerruleForHose";
export const ADD_HOSE_ITEMS = "addHoseItems";
export const HOSES_FOR_DROPDOWN = "hosesForDropdown";
export const FERRULE_AUTO_COMPLETE = "autoCompleteFerrules";
export const ATTACH_HOSE = "attachHose";
export const TOTAL_SAVING = "total/saving";

export const HOSE_MECHANIC = "hoseMechanic";
export const SAVE_TO_HOSE_MECHANIC = "saveToHoseMechanic";
export const GET_HOSE_MECHANIC_HOSE_DATA = "getHoseMechanicHoseDetails";
export const UPDATE_HOSE_MECHANIC_HOSE = "updateHoseMechanicHose";
export const UPDATE_HOSE_CONFIRMATION_DATA = "updateHoseConfirmData";
export const UPDATE_HOSE_CONFIRMATION_IMAGES = "uploadImageTos3";

export const SCHEDULE_COUNT = "counts";

export const CHAT_GROUP_LIST = "chat/conversations";
export const CREATE_CHAT_GROUP = "chat/createChat";
export const UPDATE_CHAT = "chat/updateChat";
export const CHAT_USER_LIST = "chat/user/list";
export const CHAT_USER_DETAILS = "chat/user";
export const GET_ACCESS_TOKEN = "chat/createAccessToken";
export const GET_REFRESHED_TOKEN = "chat/token/refresh";
export const DELETE_CHAT = "chat/remove";
export const GET_CONVERSATION_DETAILS = "chat/conversations";

export const ADD_NEW_MEMBERS = "chat/addParticipants";
export const REMOVE_MEMBERS = "chat/removeParticipants";
export const UPDATE_PARTICIPANTS = "chat/conversations/updateParticipants";
export const EXIT_GROUP = "chat/conversations/leave";
