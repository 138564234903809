import "./maintenanceForm.css";
import polyglot from "../../i18n";
import { connect } from "react-redux";
import CheckPoints from "./CheckPoints";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import { Control, Errors } from "react-redux-form";
import { getErrorMessage } from "../../utils/error.utils";
import { showSuccessToast } from "../../utils/toastUtils";
import * as maintenanceReducer from "./maintenanceForm.reducer";
import {
  Grid,
  Icon,
  Divider,
  Button,
  Form,
  Message,
  Dropdown,
  Confirm,
  Modal
} from "semantic-ui-react";
import _ from "lodash";

class MaintenanceForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isError: false,
      errorMessage: "",
      formRole: "",
      isConform: false,
      value: "",
      selectedFile: null,
      templateName: "",
      editName: false,
      oldValue: "",
      isErrorCheckPoint: false
    };
  }

  componentDidMount = async () => {
    this.props.actionResetForm("maintenanceForm");
    this.props.actionResetForm("checkPointForm");
    await this.props.actionGetTemplateNames();
    this.getUrlParam();
  };

  getUrlParam = async () => {
    const id = this.props.match.params.id;
    if (!id) {
      return this.setState({ formRole: "add" });
    }

    await this.props.actionGetSingleForm(id);
    const { singleForm } = this.props;
    this.setState({ formRole: "update" });
    this.props.actionSetForm(singleForm);
  };

  goBack = () => this.props.history.push("/maintenanceForm");

  submitMaintenanceForm = async () => {
    const { formRole } = this.state;
    if (formRole === "update") {
      this.updateForm();
    } else {
      this.addForm();
    }
  };

  addForm = async () => {
    const { maintenanceForm } = this.props.forms;
    if (maintenanceForm.data.length <= 0) {
      return this.updateState(true, "Check points can not be blank");
    }

    await this.props.actionAddForm(maintenanceForm);
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      showSuccessToast("Form added");
    } else {
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
    this.goBack();
  };

  updateForm = async () => {
    const { maintenanceForm } = this.props.forms;
    if (maintenanceForm.data.length <= 0) {
      return this.updateState(true, "Check points can not be blank");
    }
    await this.props.actionUpdateForm(maintenanceForm);
    const { response } = this.props;
    const { status, data } = response;
    if (status === 200) {
      showSuccessToast("Form updated");
    } else {
      const { code } = data;
      let message = await getErrorMessage(code);
      return this.updateState(true, message);
    }
    this.goBack();
  };

  updateState = (isError, errorMessage) =>
    this.setState({ isError, errorMessage });

  manageCheckPoints = async (type, value) => {
    const { maintenanceForm } = this.props.forms;
    const { data } = maintenanceForm;
    let all_points = [...data];
    if (type === "add") {
      this.addNewPoint(all_points);
    } else if (type === "remove") {
      this.removePoint(all_points, value);
    }
  };

  editCheckPointName = async (_id, section, value) => {
    await this.props.actionSetCheckPointFormField("fieldname", value);
    await this.props.actionSetCheckPointFormField("section", section);
    await this.props.actionSetCheckPointFormField("_id", _id);
    this.setState({ editName: true, oldValue: value });
  };

  setEditedCheckpointName = () => {
    this.setState({
      isErrorCheckPoint: false,
      errorMessage: "",
      isError: false
    });
    const { forms } = this.props;
    const { maintenanceForm, checkPointForm } = forms;
    const checkPointFormData = { ...checkPointForm };
    const { fieldname, _id } = checkPointFormData;
    if (!fieldname) {
      this.setState({
        isErrorCheckPoint: true,
        isError: true,
        errorMessage: "Name is required"
      });
      return;
    }

    const maintenanceFormData = { ...maintenanceForm };
    const { data } = maintenanceFormData;
    const checkList = [];
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id === _id) {
        let item = { ...data[i] };
        item.field = fieldname;
        checkList.push(item);
      } else {
        checkList.push({ ...data[i] });
      }
    }
    this.props.actionSetEditedCheckpointName(checkList);
    this.setState({ editName: false });
  };

  addNewPoint = async all_points => {
    const { checkPointForm } = this.props.forms;
    const existingFields = _.filter(all_points, value => {
      return value.field === checkPointForm.field;
    });
    if (existingFields.length > 0) {
      let message = await getErrorMessage(1124);
      return this.updateState(true, message);
    } else {
      all_points.push(checkPointForm);
      this.props.actionSetCheckPoints(all_points);
      this.props.actionResetForm("checkPointForm");
    }
  };

  removePoint = (all_points, item) => {
    _.remove(all_points, o => {
      return o.field === item.field && o.section === item.section;
    });
    this.props.actionSetCheckPoints(all_points);
  };

  showConformMessage = (e, { value }) => {
    if (value) {
      this.setState({ templateName: value, isConform: true });
    }
  };

  closeConformBox = () => {
    this.setState({ templateName: "", isConform: false });
  };

  setTemplate = async () => {
    const { templateName } = this.state;
    this.setState({ value: templateName });
    await this.props.actionGetAllCheckPoints(templateName);
    const { checkList } = this.props;
    this.props.actionSetCheckPoints(checkList);
    this.closeConformBox();
  };

  addMoreValue = section => {
    this.props.actionSetCheckPointForm(section);
    this.props.actionSetFormFocus("field");
    document.body.scrollTop = document.documentElement.scrollTop = 400;
  };

  textHandle = event => {
    const { target } = event;
    const { value } = target;
    let newValue = value;
    if (newValue.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  };

  closeForm = () => {
    this.setState({ editName: false, isError: false, errorMessage: "" });
  };

  render() {
    const {
      isLoading,
      isError,
      errorMessage,
      value,
      formRole,
      isConform,
      editName,
      isErrorCheckPoint
    } = this.state;
    const { forms, response } = this.props;
    const { _id, templateName } = response;
    let templateNames = [];
    if (templateName) {
      templateNames.push({ key: "no", value: "", text: "Select Template" });
      _.map(templateName, name => {
        templateNames.push({
          key: _id,
          text: name,
          value: name
        });
      });
    }

    const templates = (
      <Dropdown
        placeholder="Select Template"
        onChange={this.showConformMessage}
        selection
        value={value}
        options={templateNames}
      />
    );

    const name = (
      <Form.Field required>
        <label>{polyglot.t("maintenanceForm.label.formName")}</label>
        <Control
          type="text"
          model="forms.maintenanceForm.name"
          placeholder={polyglot.t("maintenanceForm.label.formName")}
          validateOn="change"
          required
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.maintenanceForm.name"
          show="touched"
          messages={{
            valueMissing: polyglot.t("maintenanceForm.label.required.formName")
          }}
        />
      </Form.Field>
    );

    const header = (
      <Grid>
        <Grid.Column width={8} computer={8} mobile={16}>
          {name}
        </Grid.Column>
      </Grid>
    );

    const editCheckPointName = (
      <Modal open={editName} onClose={this.closeForm} closeIcon>
        <Modal.Header>Edit Checkpoint name</Modal.Header>
        <Modal.Content className="add-location">
          <Form
            model="forms.checkPointForm"
            error={isError}
            onSubmit={this.setEditedCheckpointName}
          >
            <Grid.Column>
              <Form.Field>
                <label>Name</label>
                <Control
                  type="text"
                  model="forms.checkPointForm.fieldname"
                  placeholder={polyglot.t("maintenanceForm.label.name")}
                  validateOn="change"
                  className="input-box"
                />
                <Errors
                  style={{ color: "red" }}
                  model="forms.checkPointForm.fieldname"
                  show="touched"
                  messages={{
                    valueMissing: polyglot.t(
                      "maintenanceForm.label.required.name"
                    )
                  }}
                />

                {isErrorCheckPoint && (
                  <Message error={isErrorCheckPoint} content={errorMessage} />
                )}
              </Form.Field>
            </Grid.Column>
            <Grid.Column width={16} className="button-panel" textAlign="right">
              <Button
                size="small"
                onClick={this.closeForm}
                className="button-float"
              >
                Close
              </Button>
              {!isLoading ? (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  type="submit"
                  content="Submit"
                />
              ) : (
                <Button
                  secondary
                  size="small"
                  className="button-float"
                  content="Submit"
                  loading
                />
              )}
            </Grid.Column>
          </Form>
        </Modal.Content>
      </Modal>
    );

    return (
      <div className="my-maintenance-form">
        <Confirm
          open={isConform}
          content="Do you want to change template ?"
          onCancel={this.closeConformBox}
          onConfirm={this.setTemplate}
        />
        <MetaData title="Maintenance Form" />
        <Grid>
          {isLoading && (
            <Loader title="Loading, please wait">Loading,please wait</Loader>
          )}
          <Grid.Row>
            <Grid.Column width={16}>
              <h3>
                <Icon name="wpforms" />
                {formRole === "add" ? "Add" : "Edit"} maintenance form
                <Divider />
              </h3>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className="maintenance-component ">
          <Grid className="maintenance-form-box">
            {formRole === "add" ? (
              <Grid.Column
                width={16}
                mobile={16}
                computer={16}
                textAlign="right"
              >
                {templates}
              </Grid.Column>
            ) : null}
            <Form
              className="maintenance-form"
              error={isError}
              model="form.maintenanceForm"
              onSubmit={this.submitMaintenanceForm}
            >
              <Grid columns="equal" width={16} computer={16} mobile={16}>
                <Grid.Column width={16}>{header}</Grid.Column>

                <Grid.Column width={16}>
                  <CheckPoints
                    manageCheckPoints={this.manageCheckPoints}
                    forms={forms}
                    addMoreValue={this.addMoreValue}
                    editCheckPointName={this.editCheckPointName}
                    editName={editName}
                  />
                </Grid.Column>
                <Grid.Column width={16}>
                  <Divider />
                </Grid.Column>
              </Grid>
              <Message error={true} content={errorMessage} />
              <Grid.Column
                width={16}
                computer={16}
                mobile={16}
                style={{ textAlign: "right" }}
              >
                <Button
                  size="small"
                  onClick={this.goBack}
                  icon="left arrow"
                  content="Go back"
                  labelPosition="left"
                  className="button-float"
                />
                {!isLoading ? (
                  <Button
                    secondary
                    size="small"
                    className="button-float"
                    content="Submit"
                  />
                ) : (
                  <Button
                    secondary
                    size="small"
                    className="button-float"
                    content="Submit"
                    loading
                  />
                )}
              </Grid.Column>
            </Form>
          </Grid>
          <Grid>{editCheckPointName}</Grid>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    singleForm: state.maintenance.singleForm,
    response: state.maintenance.response,
    checkList: state.maintenance.checkList,
    forms: state.forms
  };
};

const reducer = { ...maintenanceReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(MaintenanceForm)
);
