import axios from "axios";
import { refreshToken } from "../containers/Auth/auth.api";
const appKey = process.env.REACT_APP_API_KEY;
const sessionID = localStorage.getItem("sessionID");

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common["XAPIKey"] = appKey;

axios.defaults.headers.common["Authorization"] = "Bearer " + sessionID;

export const addSessionToken = (sessionID) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + sessionID;
};

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const url = document.location.href;
    const isErrorPage = url.indexOf("error") > -1;

    if (!error || !error.response) {
      console.log("Server error redirecting to error page.");

      if (isErrorPage) return;
      //window.location = "/error";
      return;
    }

    if (
      error.response.data.statusCode >= 400 &&
      error.response.data.statusCode <= 500 &&
      error.response.data.description
    ) {
      return Promise.reject(error);
    } else if (error.response.status === 401) {
      console.log("Unauthorize api");
      console.log(error.response);
      refreshToken();

      if (
        error.response.data === "Unauthorized" ||
        error.response.status === 401
      ) {
        localStorage.clear();
        window.location = "/";
      } else {
        // if (error.response.data.msg === "Email not found") {
        return Promise.reject(error);
        // }
      }
      return error;
    } else if (error.response.status === 500) {
      //redirect to 400 page for bad request
      if (isErrorPage) return;
      // window.location = "/error";
    } else {
      return Promise.reject(error);
    }
  }
);
