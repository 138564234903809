import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as authReducer from "../auth.reducer";
import { Control, Errors } from "react-redux-form";
import polyglot from "../../../i18n";
import { withRouter } from "react-router-dom";
import Loader from "../../../components/Loader";
import { Form, Button, Message, Grid, Icon } from "semantic-ui-react";
import "./resetPassword.css";
import appleIcon from "../../../assets/images/apple-icon.png";
import googleIcon from "../../../assets/images/google-icon.png";
import { apiGetUserInfoByToken } from "../auth.api";

class CreatePassword extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      hidden: true,
    };
  }

  componentDidMount() {
    let params = this.props.match.params;
    // this.props.actionVerifyResetPasswordCode(params.code, params.email);
    this.props.setEmailToResetForm({
      email: params.email,
      password: "",
      confirmPassword: "",
    });
  }

  resetPassword = async (e) => {
    this.setState({ loading: true });

    this.comparePassword();
    let data = {
      email: this.props.forms.resetPasswordForm.email,
      password: this.props.forms.resetPasswordForm.password,
      token: this.props.match.params.code,
      confirmPassword: this.props.forms.resetPasswordForm.confirmPassword,
    };
    if (data.password !== data.confirmPassword) {
      this.setState({
        errorFlag: true,
        loading: false,
      });
      return;
    }
    if (data.password.length < 6) {
      this.setState({
        errorFlag: true,
      });
      e.preventDefault;
      return;
    }
    await this.props.actionResetPassword(data);
    this.setState({ loading: false });
  };

  goHome = () => {
    this.props.history.push("/");
  };
  showPassword = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  comparePassword = (val) => {
    return this.props.forms.resetPasswordForm.password !== val;
  };

  render() {
    let responseJSX = null;
    let loader = "";
    const { verified } = this.props;
    const { status, response } = this.props.response;
    const { email } = this.props.match.params;

    const longEnough = (val) => {
      const passwordRegx = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{6,})"
      );
      if (!passwordRegx.test(val)) {
        return true;
      }
      return false;
    };
    // if (!verified) {
    //   responseJSX = <Message>Invalid or Expired link</Message>;
    // }
    if (response) {
      responseJSX = (
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {status === 200 && (
            <div className="ui">
              <div>
                <p
                  className="login-title"
                  style={{ fontSize: 24, fontWeight: "bold" }}
                >
                  CONGRATULATIONS
                </p>
              </div>
              <div>
                <p
                  className="login-title"
                  style={{ fontSize: 24, fontWeight: "bold" }}
                >
                  Create your password
                </p>
              </div>
            </div>
          )}
          {response.status === 400 && (
            <Message style={{ color: "red" }}>
              {response.data.errors.Password.toString()}
            </Message>
          )}
          {response.status === 404 && (
            <Message style={{ color: "red" }}>
              {polyglot.t("resetPassword.errorMessage")}
            </Message>
          )}
        </div>
      );
    } else if (status) {
      responseJSX = (
        <div
          style={{
            textAlign: "center",
            marginTop: "10px",
            fontSize: "18px",
            fontWeight: "bold",
          }}
        >
          {status === 200 && (
            <div className="ui">
              <div>
                <p
                  className="login-title"
                  style={{ fontSize: 24, fontWeight: "bold" }}
                >
                  CONGRATULATIONS
                </p>
              </div>
              <div>
                <p
                  className="login-title"
                  style={{ fontSize: 20, lineHeight: 2 }}
                >
                  Your BOAhub user has been created. <br />
                  Lets get started!
                </p>
              </div>
              <div class="app-stores">
                <a
                  href="https://apps.apple.com/nz/app/boahub/id1439925780"
                  target="_blank"
                >
                  <img
                    data-pagespeed-url-hash="2388004890"
                    src={appleIcon}
                    onerror="this.onerror=null;pagespeed.lazyLoadImages.loadIfVisibleAndMaybeBeacon(this);"
                    class="icon"
                    style={{ width: "70%", marginBottom: 20, marginTop: 20 }}
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.mobileapp.boa"
                  target="_blank"
                >
                  <img
                    data-pagespeed-url-hash="1381415003"
                    src={googleIcon}
                    onerror="this.onerror=null;pagespeed.lazyLoadImages.loadIfVisibleAndMaybeBeacon(this);"
                    class="icon"
                    style={{ width: "70%" }}
                  />
                </a>
                <div style={{ marginTop: 30 }}>
                  <a
                    href="https://www.boahub.co/"
                    target="_blank"
                    style={{ textDecoration: "underline", color: "#66cc23" }}
                  >
                    boahub.co
                  </a>
                </div>
              </div>
            </div>
          )}
          {status === 404 &&
            status ===
              400(
                <Message style={{ color: "red" }}>
                  {polyglot.t("resetPassword.errorMessage")}
                </Message>
              )}
        </div>
      );
    }

    if (this.state.loading) {
      loader = <Loader text="Loading, please wait" />;
    }
    let formCode = (
      <div className="ui">
        <p className="login-title" style={{ fontSize: 24, fontWeight: "bold" }}>
          Create your password
        </p>
        <Form
          error={this.state.errorflag}
          className="ui form"
          model="form.resetPasswordForm"
          onSubmit={this.resetPassword}
          style={{ paddingTop: 30, opacity: this.state.loading ? 0.2 : 1 }}
        >
          <Form.Field style={{ marginBottom: 30 }}>
            <label>Email Address:</label>
            <label style={{ fontSize: 19 }}>{email}</label>
          </Form.Field>
          <Form.Field required>
            <label>Password NEW</label>
            <h6 className="reset-password-note">(Min 6 characters)</h6>
            <Grid style={{ marginTop: -35, marginLeft: 0 }}>
              <Grid.Column width={14} style={{ paddingLeft: 0 }}>
                <Control
                  type={this.state.hidden ? "password" : "text"}
                  model="forms.resetPasswordForm.password"
                  placeholder={polyglot.t("resetPassword.password")}
                  required
                  validateOn="blur"
                  className="input-box-icon"
                  minLength={6}
                  validators={{
                    // longEnough: val => !longEnough(val)
                    length: (val) => val.length > 5,
                  }}
                />
              </Grid.Column>

              <Grid.Column
                width={2}
                style={{ marginLeft: "-30px", marginTop: "1px" }}
              >
                <Button basic icon onClick={this.showPassword} type="button">
                  {this.state.hidden ? (
                    <Icon name="eye" />
                  ) : (
                    <Icon name="eye slash" />
                  )}
                </Button>
              </Grid.Column>
            </Grid>

            <Errors
              style={{ color: "red" }}
              model="forms.resetPasswordForm.password"
              show="touched"
              messages={{
                valueMissing: <p>Password is required</p>,
                longEnough: (
                  <p className="password-criteria-message">
                    {polyglot.t("createAccountPage.passwordCriteria")}
                  </p>
                ),
                length: <p>Min 6 characters required</p>,
              }}
            />
            <Grid.Column width={16} mobile={16} computer={16}>
              <div style={{ fontSize: "13px", marginTop: 15 }}>
                Tips on Strong Passwords : Using a strong password helps protect
                your data. Try creating a password that you will remember but
                will be hard for others to guess by incorporating a mix of upper
                and lower case letters and special characters."
              </div>
            </Grid.Column>
          </Form.Field>
          <Form.Field required>
            <label>Password CONFIRM</label>
            <Control
              type="password"
              model="forms.resetPasswordForm.confirmPassword"
              placeholder={polyglot.t("resetPassword.confirmPassword")}
              required
              validateOn="change"
              className="input-box-icon"
              validators={{
                comparePassword: (val) => !this.comparePassword(val),
              }}
            />
            <Errors
              style={{ color: "red" }}
              model="forms.resetPasswordForm.confirmPassword"
              show="touched"
              messages={{
                valueMissing: "Confirm password is required",
                comparePassword: "Password do not match",
              }}
            />
          </Form.Field>
          <Message error={true} content={this.state.errorMessage} />
          <div className="button-container" style={{ marginTop: 30 }}>
            <Button size="small" secondary type="submit">
              {polyglot.t("resetPassword.submit")}
            </Button>
          </div>
        </Form>
      </div>
    );

    return (
      <Grid className="reset-password-box">
        <Grid.Row>
          <div className="ui centered container">
            {loader}
            <div className="reset-password-container">
              {status !== 200 && formCode}
              {responseJSX}
            </div>
          </div>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    forms: state.forms,
    response: state.auth.response,
    resetPassword: state.auth.resetPassword,
    verified: state.auth.verified,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(authReducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreatePassword)
);
