export const transferStatusList = [
  {
    key: 0,
    value: "In Progress",
    text: "In Progress",
  },
  {
    key: 1,
    value: "Completed",
    text: "Completed",
  },
  {
    key: 99,
    value: "Deleted",
    text: "Deleted",
  },
];

export const transferStatusListOperator = [
  {
    key: 0,
    value: "In Progress",
    text: "In Progress",
  },
  {
    key: 1,
    value: "Completed",
    text: "Completed",
  },
];
