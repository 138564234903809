import React, { useState } from "react";
import "./hisComponent.css";
import { toast } from "react-toastify";
import { HISfetchData } from "./hisAPIprovider";

const components = {
  assembly_details: "Hose Assembly",
  adapter: "Adapter",
  bulk_armor_guard: "Bulk Armor Guard",
  bulk_hose: "Bulk Hose",
  clamp: "Clamp",
  coupling: "Coupling",
  coupling_seal: "Coupling Seal",
  nut_and_cap: "Nut and Cap",
  plug_and_flange_protector: "Plug and Flange Protector",
  quick_disconnect: "Quick Disconnect",
  seal_kit: "Seal Kit",
  sleeve: "Sleeve",
  split_flance: "Split Flange",
};

const HisComponent = () => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [partNo, setPartNo] = useState("");
  const [componentData, setComponentData] = useState(null);

  const onSelect = (key) => {
    setSelectedCategory(key);
    setPartNo("");
    setComponentData(null);
  };

  const handleChange = (e) => {
    setComponentData(null);
    setPartNo(e.target.value);
  };

  const handleClick = async () => {
    let response;
    if (selectedCategory !== "assembly_details") {
      response = await HISfetchData("/his/component_detail", {
        category: selectedCategory,
        part_no: partNo,
      });
    } else {
      response = await fetchData("/his/assembly_details", {
        assembly_id: partNo,
      });
    }

    if (!response.ok) {
      toast.error("The component details could not be found");
      return;
    }
    const data = await response.json();
    setComponentData(data.data);
  };

  return (
    <div>
      <div className="search_area">
        <div>
          <div className="category_label">Category</div>
          <div>
            <select onChange={(e) => onSelect(e.target.value)}>
              <option value="">====== SELECT ======</option>
              {Object.entries(components).map(([key, value]) => (
                <option value={key}>{value}</option>
              ))}
            </select>
          </div>
        </div>
        <div style={{ marginLeft: "30px" }}>
          <div className="category_label">Part No</div>
          <div>
            <input
              type="text"
              className="part_no_input"
              value={partNo}
              onChange={handleChange}
            />
          </div>
        </div>
        <div
          style={{
            marginLeft: "20px",
            marginBottom: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
          }}
        >
          <button className="component_search_button" onClick={handleClick}>
            Search
          </button>
        </div>
      </div>
      {componentData !== null && (
        <div>
          <div className="h_divider"></div>
          <h2 className="component_title">Component Details - {partNo}</h2>
          <table
            className="component_table"
            style={{
              borderCollapse: "collapse",
              width: "80%",
              border: "1px solid gray",
            }}
          >
            {Object.entries(componentData).map(
              ([key, value]) =>
                value !== "" &&
                value !== "None" && (
                  <tr
                    className="detail_data_row"
                    style={{ margin: 0, padding: 0 }}
                  >
                    <td
                      style={{
                        border: "1px solid gray",
                        width: "50%",
                        padding: "5px",
                        paddingTop: "12px",
                      }}
                    >
                      <strong>{key}</strong>
                    </td>
                    <td
                      style={{
                        border: "1px solid gray",
                        width: "50%",
                        padding: "5px",
                        paddingTop: "12px",
                      }}
                    >
                      {value}
                    </td>
                  </tr>
                )
            )}
          </table>
        </div>
      )}
    </div>
  );
};

export default HisComponent;
