import polyglot from "../../i18n";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { Control, Errors } from "react-redux-form";
import * as inventoryReducer from "./inventory.reducer";
import LocationDropdown from "../../components/LocationDropdown";
import NumericInput from "react-numeric-input";
import Loader from "../../components/Loader";
import { Grid, Divider, Form, Button, Message } from "semantic-ui-react";

const SELL_STOCK = "sellStockForm";

class SellStock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      stockId: "",
      inventoryType: "",
      location: "",
      value: ""
    };
  }

  setLocation = (e, data) => {
    const { value } = data;
    this.props.actionInventorySetFormField(`${SELL_STOCK}.location`, value);
  };

  sellStock = async () => {
    const { forms } = this.props;
    const { sellStockForm } = forms;
    await this.props.actionSellStock(sellStockForm);
  };

  textHandle = event => {
    // var newValue = event.target.value;
    const { target } = event;
    const { value } = target;
    let newValue = value;
    if (newValue.length === 0 && event.which === 32) {
      event.preventDefault();
    }
  };

  quantityChangeHandler = (e, data) => {
    if (e) {
      this.props.actionSetQuantity(`${SELL_STOCK}.quantity`, e);
      const { stock, account } = this.props;
      const { price } = stock;
      const newPrice = Number(price * e);
      const { priceMargin } = account;
      if (priceMargin && price) {
        const newMargin = Number((newPrice * priceMargin) / 100);
        const withMargin = Number(newPrice + newMargin).toFixed(2);
        return this.props.actionSetQuantity(`${SELL_STOCK}.price`, withMargin);
      }
      this.props.actionSetQuantity(`${SELL_STOCK}.price`, newPrice);
    }
  };

  priceChangeHandler = e => {
    this.props.actionSetQuantity(`${SELL_STOCK}.price`, e);
  };

  render() {
    const { isLoading } = this.state;
    const { forms, stock, account } = this.props;
    const { location, quantity, price } = forms.sellStockForm;
    const { locationQuantity } = stock;
    const { priceMargin } = account;
    const { isError, errorMessage } = this.props;

    const fromLocations = [];

    Object.keys(locationQuantity).forEach(function(key) {
      if (locationQuantity[key] != null && locationQuantity[key] > -1) {
        fromLocations.push({ name: key, quantity: locationQuantity[key] });
      }
    });

    const from = (
      <Form.Field required>
        <label>{polyglot.t("stockForm.location")}</label>
        <LocationDropdown
          setLocation={this.setLocation}
          locationId={location}
          locations={fromLocations}
        />
      </Form.Field>
    );

    const quantityJSX = (
      <div>
        <Grid columns="equal">
          <Grid.Column width={16} className="quantity-label no-left-padded">
            <Form.Field required>
              <label>Quantity</label>

              <NumericInput
                min={1}
                max={10000}
                maxLength={5}
                className="form-control"
                precision={0}
                validateOn="change"
                placeholder="Quantity"
                model="forms.sellStockForm.quantity"
                required
                onChange={this.quantityChangeHandler}
                value={quantity}
              />

              <Errors
                style={{ color: "red" }}
                model="forms.sellStockForm.quantity"
                show="touched"
                messages={{
                  valueMissing: "quantity is required",
                  rangeUnderflow: "Please enter valid quantity"
                }}
              />
            </Form.Field>
          </Grid.Column>
        </Grid>
      </div>
    );

    const priceCheck = (
      <Form.Field required>
        <label>
          Price(Each):
          {priceMargin
            ? `Actual price plus margin ${priceMargin}%`
            : "No margin price"}
        </label>

        <NumericInput
          min={1}
          max={10000}
          maxLength={5}
          className="form-control"
          precision={0}
          validateOn="change"
          placeholder="Price"
          required
          name="quantity"
          onChange={this.priceChangeHandler}
          value={price}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.sellStockForm.price"
          show="touched"
          messages={{
            valueMissing: "Price is required"
          }}
        />
      </Form.Field>
    );

    const soldTo = (
      <Form.Field required>
        <label>Sell To</label>
        <Control
          type="text"
          model="forms.sellStockForm.soldTo"
          placeholder="Sold To"
          validateOn="change"
          required
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.sellStockForm.soldTo"
          show="touched"
          messages={{
            valueMissing: "Sold To is required"
          }}
        />
      </Form.Field>
    );

    const notes = (
      <Form.Field required>
        <label>Notes</label>
        <Control.textarea
          model="forms.sellStockForm.notes"
          placeholder="Notes"
          validateOn="change"
          rows={3}
          required
          className="input-box"
          onKeyPress={event => this.textHandle(event)}
        />
        <Errors
          style={{ color: "red" }}
          model="forms.sellStockForm.notes"
          show="touched"
          messages={{
            valueMissing: "Notes is required"
          }}
        />
      </Form.Field>
    );

    return (
      <div>
        {isLoading && (
          <Loader title="Loading, please wait">Loading,please wait</Loader>
        )}
        <Form
          error={isError}
          model="form.sellStockForm"
          onSubmit={this.props.sellStock}
          style={{ width: "100%" }}
        >
          <Grid columns="equal">
            <Grid.Column width={8} computer={8} mobile={16}>
              {from}
            </Grid.Column>

            <Grid.Column width={8} computer={8} mobile={16}>
              {quantityJSX}
            </Grid.Column>

            <Grid.Column width={8} computer={8} mobile={16}>
              {priceCheck}
            </Grid.Column>

            <Grid.Column width={8} computer={8} mobile={16}>
              {soldTo}
            </Grid.Column>

            <Grid.Column width={16} computer={16} mobile={16}>
              {notes}
            </Grid.Column>

            <Grid.Column width={16} computer={16} mobile={16}>
              <Divider />
            </Grid.Column>
          </Grid>
          <Message error={true} content={errorMessage} />
          <Grid.Column
            width={16}
            computer={16}
            mobile={16}
            style={{ textAlign: "right" }}
          >
            <Button
              size="small"
              onClick={this.props.closeForm}
              content="Close"
            />
            {!isLoading && (
              <Button secondary size="small" content="Submit" type="submit" />
            )}
            {isLoading && (
              <Button secondary size="small" content="Submit" loading />
            )}
          </Grid.Column>
        </Form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    allInventory: state.inventory.allInventory,
    stock: state.inventory.stock,
    account: state.auth.user.account,
    forms: state.forms,
    response: state.inventory.response
  };
};

const reducer = { ...inventoryReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(SellStock)
);
