import React, { Component } from 'react'

export default class KitchenSink extends Component {

    componentwillMount() {

    }

    render () { 
        return <div> 
                To be used to test components


            </div>
    }
}