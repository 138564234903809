import React, { useEffect, useState, useRef } from "react";
import MetaData from "../../../components/MetaData";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as authReducer from "../auth.reducer";
import polyglot from "../../../i18n";
import {
  Card,
  Grid,
  Form,
  Button,
  Message,
  Dropdown,
  Checkbox,
  Icon,
  Modal,
} from "semantic-ui-react";
import { Control, Errors } from "react-redux-form";
import Loader from "../../../components/Loader";
import "./createAccount.css";
import { ReCaptcha } from "react-recaptcha-google";
import CustomInput from "../../../components/CustomInput";
import {
  SITE_KEY,
  industries,
  countries,
  TERMS_CONDITION_LINK,
  APP_NAME,
} from "../../../constants/appConstants";
import boaLogo from "../../../assets/images/boa_icon.png";
import logo from "../../../assets/images/logo.png";
import userType1 from "../../../assets/images/user-type-1.png";
import userType2 from "../../../assets/images/user-type-2.png";
import { FadeIn, SlideInUp } from "react-animated-components";
import { useWindowSize } from "../../../utils/utils";
import OtpInput from "react-otp-input";
import { apiResendOTP, apiVerifyAccount } from "../auth.api";
import { showErrorToast, showSuccessToast } from "../../../utils/toastUtils";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { addSessionToken } from "../../../utils/axios.utils";

const CreateAccount = (props) => {
  const [state, setState] = useState({
    errorFlag: false,
    errorMessage: "",
    success: false,
    customerRoleErrorFlag: false,
    industryErrorFlag: false,
    countryErrorFlag: false,
    mobileErrorFlag: false,
    officePhoneNumberErrorFlag: false,
    mobileErrorMessage: "",
    officePhoneNumberErrorMessage: "",
    captchaReady: false,
    iAgree: false,
    hidden: true,
    userType: null,
  });
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [otpVerifying, setOtpVerifying] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [token, setToken] = useState("");
  const [value, setValue] = useState();
  const [defaultCountryCode, setDefaultCountryCode] = useState("NZ");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const size = useWindowSize();
  const captchaDemo = useRef();

  useEffect(() => {
    if (recaptchaToken !== "") {
      verifyCallback(recaptchaToken);
    }
  }, [recaptchaToken]);

  useEffect(() => {
    if (size.width <= 600) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [size]);

  useEffect(() => {
    const { response } = props.auth;

    if (response) {
      if (response.status === 424) {
        updateState({
          ...state,
          errorFlag: true,
          errorMessage: "Something went wrong, please try it again",
        });
      }
      if (response.status === 409) {
        updateState({
          ...state,
          errorFlag: true,
          errorMessage:
            "User with this Email already exist. Try Logging in or contact system admin",
        });
      }
      if (response.status === 403) {
        updateState({
          ...state,
          errorFlag: true,
          errorMessage: "Forbidden",
        });
      }
      if (response.status === 412) {
        updateState({
          ...state,
          errorFlag: true,
          errorMessage:
            "This company name is already registered with BOA, please select other company name or in case of queries get in touch with BOA. ",
        });
      }
      if (response.status === 200) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        if (response.data) {
          setToken(response.data.token);
          addSessionToken(response.data.token);
          setShowOtp(true);
        }
        updateState({
          ...state,
          success: true,
        });
      }
    }
  }, [props.auth]);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    props.actionResetState("forms.createAccountForm");
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  useEffect(() => {
    if (state.userType !== null) {
      isCaptchaReady();
    }
  }, [state.userType]);

  useEffect(() => {
    if (otp.length === 6) {
      setOtpVerifying(true);
      verifyAccount();
    }
  }, [otp]);

  useEffect(() => {
    const form = props.forms.createAccountForm;
    if (form.country === "0") setDefaultCountryCode("NZ");
    if (form.country === "170") setDefaultCountryCode("NZ");
    if (form.country === "13") setDefaultCountryCode("AU");
    if (form.country === "233") setDefaultCountryCode("US");
  }, [props.forms.createAccountForm]);

  const resendOTP = async () => {
    try {
      const payload = {
        token: token,
      };
      setOtpVerifying(true);
      const account = await apiResendOTP(payload);
      if (account) {
        showSuccessToast(
          <div>
            <i className="check icon" /> Verification sent successfully
          </div>
        );
        setTimeout(() => {
          setOtpVerifying(false);
        }, 1000);
      } else {
        setOtpVerifying(false);
        showErrorToast("Something went wrong");
      }
    } catch {
      setOtpVerifying(false);
      showErrorToast("Something went wrong");
    }
  };

  const verifyAccount = async () => {
    try {
      const payload = {
        code: otp,
        token: token,
      };
      const account = await apiVerifyAccount(payload);
      if (account) {
        if (state.userType === 3) {
          setTimeout(() => {
            setIsModalOpen(true);
            setOtpVerifying(false);
          }, 1000);
        } else {
          const { createAccountForm } = props.forms;
          await props.actionlogIn(createAccountForm);
        }
      } else {
        setOtpVerifying(false);
        setOtp("");
        showErrorToast("Invalid verification code");
      }
    } catch {
      setOtpVerifying(false);
      setOtp("");
      showErrorToast("Something went wrong");
    }
  };

  const isCaptchaReady = () => {
    // Temp work around
    setTimeout(() => {
      if (window.grecaptcha) {
        setState({ ...state, captchaReady: true });
      }
    }, 1000);
  };

  const iAgreeCheckBoxHandler = (e, data) => {
    const { checked } = data;
    updateState({ ...state, iAgree: checked });
  };

  const isFormValid = () => {
    const { mobileErrorFlag } = state;
    const { mobileNumber } = props.forms.createAccountForm;
    if (mobileErrorFlag) {
      return false;
    }
    if (!mobileNumber.length) {
      return false;
    }
    return true;
  };

  const isCountryValid = () => {
    const { country } = props.forms.createAccountForm;
    if (!country.length) {
      return false;
    }
    return true;
  };

  const isIndustryValid = () => {
    const { industry } = props.forms.createAccountForm;
    if (!industry.length) {
      return false;
    }
    return true;
  };

  const passwordValidation = () => {
    const { createAccountForm } = props.forms;
    const { confirmPassword, password } = createAccountForm;
    if (!password && !confirmPassword) {
      return false;
    }
    if (password !== confirmPassword) {
      return false;
    }
    return true;
  };

  const passwordPolicyValidation = () => {
    const { createAccountForm } = props.forms;
    const { confirmPassword, password } = createAccountForm;

    const regularExpression =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+[{\]}|;:'",.<>/?]).{6,}$/;
    if (!regularExpression.test(password)) {
      return false;
    }
    return true;
  };

  const SingUp = async () => {
    setState({ ...state, errorFlag: false, errorMessage: "" });
    const { createAccountForm } = props.forms;
    const properEmail = createAccountForm.email.trim().toLowerCase();
    let createAccount = Object.assign(
      {},
      {
        parentName: createAccountForm.companyName,
        email: properEmail,
        firstName: createAccountForm.firstName,
        lastName: createAccountForm.lastName,
        countryId: +createAccountForm.country,
        industryId: +createAccountForm.industry,
        mobileNumber: createAccountForm.mobileNumber,
        officeNumber: createAccountForm.officePhoneNumber,
        userPassword: createAccountForm.password,
        confirmPassword: createAccountForm.confirmPassword,
        userType: state.userType === 8 ? 8 : null,
        accountType: state.userType === 8 ? 3 : null,
      }
    );

    if (state.userType !== 8) {
      createAccount = Object.assign(
        {},
        {
          parentName: createAccountForm.companyName,
          email: properEmail,
          firstName: createAccountForm.firstName,
          lastName: createAccountForm.lastName,
          countryId: +createAccountForm.country,
          industryId: +createAccountForm.industry,
          mobileNumber: createAccountForm.mobileNumber,
          officeNumber: createAccountForm.officePhoneNumber,
          userPassword: createAccountForm.password,
          confirmPassword: createAccountForm.confirmPassword,
        }
      );
    }

    // Convert Country to NZ if Other option is chosen
    if (createAccountForm.country === "0") createAccount.countryId = 170;

    const { iAgree } = state;
    const validFlag = isFormValid();
    const validCountryFlag = isCountryValid();
    const validIndustryFlag = isIndustryValid();

    if (!passwordValidation()) {
      updateState({
        ...state,
        errorFlag: true,
        errorMessage: "Password and Confirm password do not match",
      });
      return;
    }

    if (!passwordPolicyValidation()) {
      updateState({
        ...state,
        errorFlag: true,
        errorMessage:
          "Your password needs to include at least one number and one symbol",
      });
      return;
    }

    if (!validFlag) {
      updateState({
        ...state,
        errorFlag: true,
        errorMessage: "Please insert your mobile number",
      });
      return;
    }

    if (!validIndustryFlag) {
      updateState({
        ...state,
        errorFlag: true,
        errorMessage: "Industry is required",
      });
      return;
    }

    if (!validCountryFlag) {
      updateState({
        ...state,
        errorFlag: true,
        errorMessage: "Country is required",
      });
      return;
    }
    if (window._.isEmpty(createAccountForm.captchaToken)) {
      updateState({
        ...state,
        errorFlag: true,
        errorMessage: "Please check the captcha option",
      });
      return;
    }
    if (!iAgree) {
      updateState({
        ...state,
        errorFlag: true,
        errorMessage: "Please check the i agree check box to create account",
      });
      return;
    }
    setLoading(true);

    await props.actionSignUp(createAccount);

    setLoading(false);
  };

  const cancel = () => {
    props.actionResetState("forms.createAccountForm");
    props.history.push("/");
  };

  const customerRoleChangeHandler = (e, data) => {
    updateState({ ...state, customerRoleErrorFlag: false });
    const { value } = data;
    if (window._.isEmpty(value)) {
      updateState({ ...state, customerRoleErrorFlag: true });
    }

    const formValue = {
      ...props.forms.createAccountForm,
      userRole: value,
    };
    props.actionSetFormValue(formValue);
  };

  const industryChangeHandler = (e, data) => {
    updateState({ ...state, industryErrorFlag: false });
    const { value } = data;
    if (window._.isEmpty(value)) {
      updateState({ ...state, industryErrorFlag: true });
    }

    const formValue = {
      ...props.forms.createAccountForm,
      industry: value,
    };
    props.actionSetFormValue(formValue);
  };

  const countryChangeHandler = (e, data) => {
    setDefaultCountryCode("");
    updateState({ ...state, countryErrorFlag: false });
    const { value } = data;

    if (window._.isEmpty(value)) {
      updateState({ ...state, countryErrorFlag: true });
    }

    const formValue = {
      ...props.forms.createAccountForm,
      country: value,
    };
    props.actionSetFormValue(formValue);
  };

  const onChangeMobileHandler = (value) => {
    updateState({ ...state, mobileErrorFlag: false });
    if (window._.isEmpty(value)) {
      updateState({
        ...state,
        mobileErrorFlag: true,
        mobileErrorMessage: polyglot.t("createAccountPage.mobileRequried"),
      });
    }
    const formValue = {
      ...props.forms.createAccountForm,
      mobileNumber: value,
    };
    props.actionSetFormValue(formValue);
  };

  const onChangeOfficePhoneNumberHandler = (event) => {
    updateState({ ...state, officePhoneNumberErrorFlag: false });
    let value = event.target.value;
    // if ((value.length && value.length < 6) || value.length > 12) {
    //   updateState({
    //     ...state,
    //     officePhoneNumberErrorFlag: true,
    //     officePhoneNumberErrorMessage: polyglot.t(
    //       "createAccountPage.validOfficePhoneNumber"
    //     ),
    //   });
    // }
    const formValue = {
      ...props.forms.createAccountForm,
      officePhoneNumber: value,
    };
    props.actionSetFormValue(formValue);
  };

  const onLoadRecaptcha = () => {
    if (captchaDemo) {
      // captchaDemo.current.reset();
      // captchaDemo.current.execute();
    }
  };

  const verifyCallback = (recaptchaToken) => {
    if (recaptchaToken !== "") {
      const formValue = {
        ...props.forms.createAccountForm,
        captchaToken: recaptchaToken,
      };
      props.actionSetFormValue(formValue);
    }
  };

  const updateState = (data) => {
    setState(data);
  };

  const setFormValues = (data) => {
    const form = { ...props.forms.createAccountForm, data };
    props.actionSetFormValue(form);
  };

  const goHome = () => {
    props.history.push("/");
  };

  const showPassword = () => {
    setState({ ...state, hidden: !state.hidden });
  };

  const { customerType } = props.forms.createAccountForm;
  let {
    customerRoleErrorFlag,
    industryErrorFlag,
    countryErrorFlag,
    mobileErrorFlag,
    officePhoneNumberErrorFlag,
    mobileErrorMessage,
    officePhoneNumberErrorMessage,
    iAgree,
  } = state;

  const comparePassword = (val) => {
    return props.forms.createAccountForm.password !== val;
  };
  const longEnough = (val) => {
    const passwordRegx = new RegExp(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$!%^&*()_+{}\[\]:;<>,.?~\\-])[A-Za-z\d@#$!%^&*()_+{}\[\]:;<>,.?~\\-]{6,}$/
    );
    if (!passwordRegx.test(val)) {
      return true;
    }
    return false;
  };

  const handleOtpChange = (value) => {
    setOtp(value);
  };

  if (!isLoading) {
    return (
      <React.Fragment>
        <MetaData title="Create Account" />
        {/* <section
          className="feature-heading"
          style={{
            marginTop: -130,
            marginBottom: 20,
          }}
        >
          <div className="text-bg">
            <h1 className="text">BOAhub</h1>
          </div>
          <div className="content-max-width">
            <img
              src="https://boahydraulics.com/cache/images/8/6/0/0/2/x8600204319ed0c59c1e1bdc799de9d29daf49fef.png.pagespeed.ic.sXyEw1xKus.webp"
              data-pagespeed-url-hash="1459856290"
              onload="pagespeed.CriticalImages.checkImageForCriticality(this);"
            />
          </div>
        </section> */}
        <Modal
          dimmer="blurring"
          size="tiny"
          open={isModalOpen}
          onClose={() => props.history.push("/")}
        >
          <Modal.Content>
            <div style={{ fontSize: 22, fontWeight: "bold", marginBottom: 25 }}>
              <p>Successful Registration</p>
            </div>
            <div style={{ marginBottom: 25 }}>
              <p>Thank you for signing up to BOAHUB.</p>
            </div>
            <div style={{ marginBottom: 10 }}>
              <p>
                A BOA representative will setup your account and you will
                receive a further email notification once your account is live.
              </p>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              positive
              onClick={() =>
                setTimeout(() => {
                  props.history.push("/");
                }, 1000)
              }
            >
              OK
            </Button>
          </Modal.Actions>
        </Modal>
        <div style={{ minHeight: 500 }}>
          {state.userType !== null && !showOtp ? (
            <div>
              <FadeIn fade durationMs={600}>
                <Grid className="ui container create-account">
                  <Grid.Row>
                    {loading && <Loader text="Signing up ..." />}
                    <Grid.Column>
                      <div style={{ textAlign: "center", paddingTop: 10 }}>
                        <h2 style={{ color: "#66CC22" }}>SIGNUP</h2>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row className="create-account-form">
                    <Grid.Column>
                      <Form
                        error={state.errorFlag}
                        model="forms.createAccountForm"
                        onSubmit={SingUp}
                      >
                        <Grid columns="equal">
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <Form.Field required>
                              <label>
                                {polyglot.t("createAccountPage.companyName")}
                              </label>
                              <Control
                                type="text"
                                model="forms.createAccountForm.companyName"
                                placeholder={polyglot.t(
                                  "createAccountPage.companyName"
                                )}
                                validateOn="change"
                                required
                                className="input-box"
                                autoComplete="true"
                              />
                              <Errors
                                style={{ color: "red" }}
                                model="forms.createAccountForm.companyName"
                                show="touched"
                                messages={{
                                  valueMissing: "Company name is required",
                                }}
                              />
                            </Form.Field>
                          </Grid.Column>
                        </Grid>
                        <Grid>
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <Form.Field required>
                              <label>
                                {polyglot.t("createAccountPage.industry")}
                              </label>
                              <Dropdown
                                placeholder={polyglot.t(
                                  "createAccountPage.industry"
                                )}
                                selection
                                options={industries}
                                onChange={industryChangeHandler}
                              />
                              {industryErrorFlag && (
                                <span className="error-message">
                                  Industry name is required
                                </span>
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <Form.Field>
                              <label>Company Ph Number</label>
                              <CustomInput
                                placeholder="Company Ph Number"
                                options={{ phone: true, phoneRegionCode: "NZ" }}
                                onChangeHandler={
                                  onChangeOfficePhoneNumberHandler
                                }
                              />
                              {officePhoneNumberErrorFlag && (
                                <span className="error-message">
                                  {officePhoneNumberErrorMessage}
                                </span>
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <Form.Field required>
                              <label>
                                {polyglot.t("createAccountPage.country")}
                              </label>
                              <Dropdown
                                placeholder={polyglot.t(
                                  "createAccountPage.country"
                                )}
                                selection
                                options={countries}
                                onChange={countryChangeHandler}
                              />
                              {countryErrorFlag && (
                                <span className="error-message">
                                  Country name is required
                                </span>
                              )}
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <Form.Field required>
                              <label>
                                {polyglot.t("createAccountPage.firstName")}
                              </label>
                              <Control.text
                                model="forms.createAccountForm.firstName"
                                placeholder={polyglot.t(
                                  "createAccountPage.firstName"
                                )}
                                required
                                validateOn="blur"
                                className="input-box"
                                autoComplete="true"
                              />
                              <Errors
                                style={{ color: "red" }}
                                model="forms.createAccountForm.firstName"
                                show="touched"
                                messages={{
                                  valueMissing: "First name is required",
                                }}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <Form.Field required>
                              <label>
                                {polyglot.t("createAccountPage.lastName")}
                              </label>
                              <Control.text
                                model="forms.createAccountForm.lastName"
                                placeholder={polyglot.t(
                                  "createAccountPage.lastName"
                                )}
                                required
                                validateOn="blur"
                                className="input-box"
                                autoComplete="true"
                              />
                              <Errors
                                style={{ color: "red" }}
                                model="forms.createAccountForm.lastName"
                                show="touched"
                                messages={{
                                  valueMissing: "Last name is required",
                                }}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <Form.Field required>
                              <label>
                                {polyglot.t("createAccountPage.mobileNumber")}
                              </label>
                              {defaultCountryCode === "" && (
                                <PhoneInput
                                  international
                                  defaultCountry="NZ"
                                  countryCallingCodeEditable={false}
                                  placeholder={polyglot.t(
                                    "createAccountPage.mobileNumber"
                                  )}
                                  value={value}
                                  onChange={onChangeMobileHandler}
                                />
                              )}
                              {defaultCountryCode !== "" && (
                                <PhoneInput
                                  international
                                  defaultCountry={defaultCountryCode}
                                  countryCallingCodeEditable={false}
                                  placeholder={polyglot.t(
                                    "createAccountPage.mobileNumber"
                                  )}
                                  value={value}
                                  onChange={onChangeMobileHandler}
                                />
                              )}
                              {mobileErrorFlag && (
                                <span className="error-message">
                                  {mobileErrorMessage}
                                </span>
                              )}
                            </Form.Field>
                          </Grid.Column>
                        </Grid>
                        <Grid>
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <Form.Field required>
                              <label>
                                {polyglot.t("createAccountPage.email")}
                              </label>
                              <Control
                                type="email"
                                model="forms.createAccountForm.email"
                                placeholder={polyglot.t(
                                  "createAccountPage.email"
                                )}
                                validateOn="change"
                                required
                                className="input-box"
                                autoComplete="true"
                              />
                              <Errors
                                style={{ color: "red" }}
                                model="forms.createAccountForm.email"
                                show="touched"
                                messages={{
                                  valueMissing: "Email is required",
                                  typeMismatch: "Invalid email address",
                                }}
                              />
                            </Form.Field>
                          </Grid.Column>
                        </Grid>
                        <Grid columns="equal">
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <Form.Field required>
                              <label>
                                {polyglot.t("createAccountPage.password")}
                              </label>
                              <Grid>
                                <Grid.Column
                                  width={14}
                                  style={{ paddingRight: 0 }}
                                >
                                  <Control
                                    type={state.hidden ? "password" : "text"}
                                    model="forms.createAccountForm.password"
                                    placeholder={polyglot.t(
                                      "createAccountPage.password"
                                    )}
                                    required
                                    validateOn="blur"
                                    className="input-box-icon"
                                    autoComplete="true"
                                    minLength={6}
                                    validators={{
                                      longEnough: (val) => !longEnough(val),
                                      length: (val) => val.length > 5,
                                    }}
                                  />
                                </Grid.Column>
                                <Grid.Column
                                  width={2}
                                  style={{
                                    paddingLeft: 10,
                                    marginLeft: "-2px",
                                    marginTop: "1px",
                                  }}
                                >
                                  <Button
                                    basic
                                    icon
                                    onClick={showPassword}
                                    type="button"
                                  >
                                    {state.hidden ? (
                                      <Icon name="eye" />
                                    ) : (
                                      <Icon name="eye slash" />
                                    )}
                                  </Button>
                                </Grid.Column>
                              </Grid>
                              <Errors
                                style={{ color: "red" }}
                                model="forms.createAccountForm.password"
                                show="touched"
                                messages={{
                                  valueMissing: <p>Password is required</p>,
                                  longEnough: (
                                    <p>
                                      {polyglot.t(
                                        "createAccountPage.passwordCriteria3"
                                      )}
                                    </p>
                                  ),
                                }}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <h6 className="validationNote">(Min 6 characters)</h6>
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <Form.Field required>
                              <label>
                                {polyglot.t(
                                  "createAccountPage.confirmPassword"
                                )}
                              </label>
                              <Control
                                type="password"
                                model="forms.createAccountForm.confirmPassword"
                                placeholder={polyglot.t(
                                  "createAccountPage.confirmPassword"
                                )}
                                required
                                validateOn="change"
                                className="input-box-icon"
                                autoComplete="true"
                                validators={{
                                  comparePassword: (val) =>
                                    !comparePassword(val),
                                }}
                              />
                              <Errors
                                style={{ color: "red" }}
                                model="forms.createAccountForm.confirmPassword"
                                show="touched"
                                messages={{
                                  valueMissing: "Confirm password is required",
                                  comparePassword: "Password do not match!",
                                }}
                              />
                            </Form.Field>
                          </Grid.Column>
                        </Grid>
                        <Grid>
                          <Grid.Column width={16} style={{ paddingBottom: 0 }}>
                            <div style={{ fontSize: "13px" }}>
                              Password must be at least 6 characters and must
                              contain at least one uppercase letter, <br />
                              one lowercase letter, one number, one special
                              character and shouldn't contain any spaces.
                            </div>
                          </Grid.Column>
                        </Grid>
                        <Grid>
                          <Grid.Column mobile={16} computer={8}>
                            {state.captchaReady ? (
                              <ReCaptcha
                                ref={captchaDemo}
                                size="normal"
                                render="explicit"
                                sitekey={SITE_KEY}
                                onloadCallback={onLoadRecaptcha}
                                // verifyCallback={verifyCallback}
                                verifyCallback={setRecaptchaToken}
                              />
                            ) : null}
                          </Grid.Column>
                        </Grid>
                        <Grid width={16}>
                          <Grid.Column>
                            <Message color={iAgree === true ? "green" : ""}>
                              <Message.Content>
                                <Checkbox
                                  label={"I agree"}
                                  onChange={iAgreeCheckBoxHandler}
                                />
                                &nbsp;to all{" "}
                                <a href={TERMS_CONDITION_LINK} target="_blank">
                                  terms and conditions
                                </a>
                              </Message.Content>
                            </Message>
                          </Grid.Column>
                        </Grid>
                        <Message error={true} content={state.errorMessage} />
                        <Grid>
                          <Grid.Row>
                            {!loading && (
                              <Button
                                color="black"
                                className="verify-customer-button"
                                type="submit"
                              >
                                {polyglot.t("createAccountPage.submit")}
                              </Button>
                            )}
                            {loading && (
                              <Button
                                className="verify-customer-button"
                                loading
                                secondary
                              >
                                Loading
                              </Button>
                            )}
                          </Grid.Row>
                        </Grid>
                        <Grid>
                          <Grid.Row>
                            Already have a account?&nbsp;
                            <a href="" onClick={goHome}>
                              {" "}
                              Login
                            </a>
                          </Grid.Row>
                        </Grid>
                        <Errors model="forms.createAccountForm" />
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </FadeIn>
              <div></div>
            </div>
          ) : showOtp && token !== "" ? (
            <div>
              <FadeIn fade durationMs={600}>
                <Grid style={{ marginTop: 50 }}>
                  <Grid.Column
                    width={16}
                    style={{
                      textAlign: "center",
                      padding: "30px 0px",
                      paddingBottom: 120,
                    }}
                  >
                    <Card
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: 600,
                        boxShadow:
                          "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                      }}
                    >
                      <Card.Content style={{ padding: 0 }}>
                        <div
                          style={{
                            textAlign: "center",
                            margin: "auto",
                            paddingTop: 40,
                          }}
                        >
                          <img
                            src={logo}
                            style={{ width: 200, height: 98.08 }}
                          />
                        </div>
                        <div
                          style={{
                            padding: "40px 120px",
                            opacity: otpVerifying ? 0.2 : 1,
                          }}
                        >
                          <span
                            style={{
                              fontSize: isMobile ? "3vw" : 17,
                              fontWeight: "bold",
                            }}
                          >
                            Thank you for signing up to the BOAhub, a
                            verification code will be sent to you.
                          </span>
                        </div>
                        <div
                          style={{
                            marginBottom: 10,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {otpVerifying ? (
                            <Loader
                              size="large"
                              active={otpVerifying}
                              text=""
                            ></Loader>
                          ) : null}
                          <OtpInput
                            containerStyle={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              opacity: otpVerifying ? 0.2 : 1,
                            }}
                            inputStyle={{
                              width: 60,
                              height: 60,
                              margin: "0px 10px",
                              fontSize: "26",
                              borderRadius: "4px",
                              border: "1px solid rgba(0, 0, 0, 0.3)",
                            }}
                            numInputs={6}
                            errorStyle="error"
                            separator={<span></span>}
                            shouldAutoFocus
                            value={otp}
                            onChange={handleOtpChange}
                          />
                        </div>
                        <div
                          style={{
                            padding: "40px 120px",
                            opacity: otpVerifying ? 0.2 : 1,
                          }}
                        >
                          <span
                            style={{
                              fontSize: isMobile ? "3vw" : 17,
                              fontWeight: "bold",
                            }}
                          >
                            Didn't get the code?{" "}
                          </span>
                          <a
                            style={{
                              fontSize: isMobile ? "3vw" : 17,
                              fontWeight: "bold",
                            }}
                            onClick={() => resendOTP()}
                          >
                            Resend
                          </a>
                        </div>
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid>
              </FadeIn>
            </div>
          ) : (
            <FadeIn fade durationMs={600}>
              <div>
                <section className="body-container" style={{ minHeight: 0 }}>
                  <div className="heading-content flex content-max-width">
                    <div
                      className="heading-public"
                      style={{ textAlign: "center", margin: "auto" }}
                    >
                      <img
                        src={boaLogo}
                        style={{ width: 200, height: 98.08 }}
                      />
                      <br />
                      <br />
                      <span>
                        THE WORLD'S FASTEST <br /> HYDRAULIC HOSE REPAIR
                      </span>
                    </div>
                  </div>
                </section>
                <Grid style={{ marginTop: 50, marginBottom: 50 }}>
                  <Grid.Column width={8} style={{ textAlign: "center" }}>
                    <Card
                      style={{
                        cursor: "pointer",
                        border: "1px solid #66CC22",
                        marginLeft: "auto",
                        maxWidth: 230,
                        width: "100%",
                      }}
                      onClick={() => setState({ ...state, userType: 8 })}
                    >
                      <Card.Content
                        style={{
                          padding: "5px 15px",
                          backgroundColor: "#66CC22",
                          borderRadius: "0 !important",
                        }}
                      >
                        <span
                          style={{
                            fontSize: isMobile ? "3vw" : 17,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          Own hydraulic <br />
                          machinery?
                        </span>
                      </Card.Content>
                      <Card.Content
                        style={{
                          minHeight: 150,
                          maxHeight: 150,
                          paddingTop: 30,
                          paddingLeft: 20,
                        }}
                      >
                        <img
                          src={userType1}
                          style={{
                            width: isMobile ? "30vw" : 180,
                            height: "auto",
                            marginTop: isMobile ? "-8vw" : -50,
                          }}
                        />
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                  <Grid.Column width={8} style={{ textAlign: "center" }}>
                    <Card
                      style={{
                        cursor: "pointer",
                        border: "1px solid #66CC22",
                        marginRight: "auto",
                        maxWidth: 230,
                        width: "100%",
                      }}
                      onClick={() => setState({ ...state, userType: 3 })}
                    >
                      <Card.Content
                        style={{
                          padding: "5px 15px",
                          backgroundColor: "#66CC22",
                        }}
                      >
                        <span
                          style={{
                            fontSize: isMobile ? "3vw" : 17,
                            fontWeight: "bold",
                            color: "#fff",
                          }}
                        >
                          Own a <br /> BOA Set-Up ?
                        </span>
                      </Card.Content>
                      <Card.Content style={{ minHeight: 150, paddingTop: 30 }}>
                        <img
                          src={userType2}
                          style={{
                            width: isMobile ? "30vw" : 180,
                            height: "auto",
                          }}
                        />
                      </Card.Content>
                    </Card>
                  </Grid.Column>
                </Grid>
              </div>
            </FadeIn>
          )}
        </div>
      </React.Fragment>
    );
  } else {
    return <div style={{ minHeight: 500 }}></div>;
  }
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    forms: state.forms,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(authReducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateAccount)
);
