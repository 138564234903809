import axios from "axios";

export const apiGetBOApods = (location, pageSize) => {
  const data = {
    myLatitude: location.lat,
    myLongitude: location.lng,
    maxDistance: 1000000,
    PageNo: 1,
    Limit: pageSize,
  };
  return axios.get("/api/BOApod/fetch", { params: data }).then((result) => {
    if (result) {
      if (result.data) {
        result.data.records.sort((a, b) => {
          return a.position.distance - b.position.distance;
        });
        result.data.records.map((item) => {
          item.name = `${item.driverName.split(" ")[0]} - ${item.companyName}`;
        });
        return result.data;
      }
    }
    return false;
  });
};

export const apiGetSelectedPod = (location, pod) => {
  const data = {
    myLatitude: location.lat,
    myLongitude: location.lng,
    uniqueId: pod.uniqueId,
    deviceId: pod.id,
  };
  return axios.get("/api/BOApod/details", { params: data }).then((result) => {
    if (result) {
      if (result.data) {
        return result.data;
      }
    }
    return false;
  });
};
