import _ from "lodash";
import "./footer.css";
import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import logoImage from "../../assets/images/logo.png";
import boaYT from "../../assets/images/boa-yt.svg";
import boaIG from "../../assets/images/boa-ig.svg";
import boaLinkedIn from "../../assets/images/boa-linkedin.svg";
import boaFB from "../../assets/images/boa-fb.png";
import boaTiktok from "../../assets/images/boa-tiktok.svg";
import {
  FOOTER_PAGES_PATH,
  brands,
  importantLink,
} from "../../constants/appConstants";
import { useWindowSize } from "../../utils/utils";

const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();

  const [countryCode, setCountryCode] = useState("");
  const [currentWidth, setCurrentWidth] = useState(1300);

  const size = useWindowSize();

  useEffect(() => {
    const currentCountryCode = localStorage.getItem("countryCode");
    setCountryCode(currentCountryCode);
  }, [countryCode]);

  useEffect(() => {
    if (size) {
      setCurrentWidth(window.innerWidth);
    }
  }, [size]);

  const goToPage = (name) => {
    const url = `${FOOTER_PAGES_PATH}${name}`;
    window.open(url, "_blank");
  };
  const contact = (
    <img
      alt="BOA"
      src={logoImage}
      className="boa-logo-menu"
      style={{ top: 0 }}
    />
  );

  const contact1 = (
    <span>
      <h3>
        <a
          href="https://boahydraulics.com/contact-us"
          target="_blank"
          style={{
            fontSize: 20,
            fontWeight: "bolder",
            lineHeight: "1.28571429em",
            fontWeight: 700,
          }}
        >
          Get in Touch
        </a>
      </h3>
      <a href={countryCode != "AU" ? "tel:0800 20 20 20" : "tel:1800 40 30 40"}>
        {countryCode != "AU" ? "0800 20 20 20" : "1800 40 30 40"}
      </a>
      <br />
      <a href="mailto:sales@boahydraulics.com">sales@boahydraulics.com</a>
      <br />
      <br />
      <a className="current-country">
        {countryCode != "AU" ? "BOA New Zealand" : "BOA Australia"}
      </a>
    </span>
  );

  const contact2 = (
    <span>
      <h3>{countryCode != "AU" ? "Auckland" : "Caboolture"}</h3>
      <a>
        {countryCode != "AU"
          ? "7 David McCathie Place,"
          : "47 Evans Drive, Caboolture,"}
      </a>
      <br />
      <a>
        {countryCode != "AU"
          ? "Silverdale, 0932, New Zealand"
          : "QLD, 4501, Australia"}
      </a>
    </span>
  );

  const contact3 = (
    <span>
      <h3>{countryCode != "AU" ? "Caboolture" : "Auckland"}</h3>
      <a>
        {countryCode != "AU"
          ? "47 Evans Drive, Caboolture,"
          : "7 David McCathie Place,"}
      </a>
      <br />
      <a>
        {countryCode != "AU"
          ? "QLD, 4501, Australia"
          : "Silverdale, 0932, New Zealand"}
      </a>
    </span>
  );

  const brand = (
    <span>
      <h3>Resources</h3>
      <a
        href="https://boahydraulics.com/people-training-and-support"
        target="_blank"
      >
        People, Training and Support
      </a>
      <br />
      <a href="https://boahydraulics.com/resources" target="_blank">
        Media
      </a>
      <br />
      <a href="https://boahydraulics.com/catalogue" target="_blank">
        Catalogue
      </a>
      <br />
      <a href="https://boahydraulics.com/boahub-faq" target="_blank">
        BOAhub FAQ's and Troubleshooting
      </a>
    </span>
  );

  const important = (
    <span>
      <h3>About BOA Hydraulics</h3>
      <a href="https://boahydraulics.com/our-values" target="_blank">
        Our Values
      </a>
      <br />
      <a href="https://boahydraulics.com/faqs" target="_blank">
        FAQs
      </a>
      <br />
      <a href="https://boahydraulics.com/terms-and-conditions" target="_blank">
        Terms & Conditions
      </a>
    </span>
  );

  return (
    <div className="public_footer">
      {window.location.href.includes("callback") ? null : (
        <>
          <Grid columns={currentWidth > 1025 ? 6 : 1} className="footer-body">
            <Grid.Column style={{ height: 110, padding: 0 }}>
              {contact}
            </Grid.Column>

            <Grid.Column
              style={{ height: "100%", padding: 0, marginBottom: 30 }}
            >
              {contact1}
            </Grid.Column>

            <Grid.Column
              style={{ height: "100%", padding: 0, marginBottom: 30 }}
            >
              {contact2}
            </Grid.Column>

            <Grid.Column
              style={{ height: "100%", padding: 0, marginBottom: 30 }}
            >
              {contact3}
            </Grid.Column>

            <Grid.Column
              className="redirect-link"
              style={{ height: "100%", padding: 0, marginBottom: 30 }}
            >
              {brand}
            </Grid.Column>

            <Grid.Column
              className="redirect-link"
              style={{ height: "100%", padding: 0 }}
            >
              {important}
            </Grid.Column>
          </Grid>
          <div data-v-50e1e017="" className="footer-legal">
            <div data-v-50e1e017="" className="content-max-width">
              <p data-v-50e1e017="" className="copyright">
                <span data-v-50e1e017="" className="block">
                  ©
                </span>{" "}
                {year} Copyright BOA Hydraulics
              </p>
              <div data-v-50e1e017="" className="attribution">
                <a
                  data-v-50e1e017=""
                  href="https://www.tiktok.com/@boa.hydraulics"
                  target="_blank"
                  className="icon"
                >
                  <img data-v-50e1e017="" src={boaTiktok} />
                </a>
                <a
                  data-v-50e1e017=""
                  href="https://www.facebook.com/BOAhydraulics/"
                  target="_blank"
                  className="icon"
                >
                  <img data-v-50e1e017="" src={boaFB} />
                </a>{" "}
                <a
                  data-v-50e1e017=""
                  href="https://www.linkedin.com/company/boa-new-zealand"
                  target="_blank"
                  className="icon"
                >
                  <img data-v-50e1e017="" src={boaLinkedIn} />
                </a>{" "}
                <a
                  data-v-50e1e017=""
                  href="https://www.instagram.com/boa.hydraulics"
                  target="_blank"
                  className="icon"
                >
                  <img data-v-50e1e017="" src={boaIG} />
                </a>{" "}
                <a
                  data-v-50e1e017=""
                  href="https://www.youtube.com/channel/UCStoGxvvVIficDt5NQPfF_A"
                  target="_blank"
                  className="icon"
                >
                  <img data-v-50e1e017="" src={boaYT} />
                </a>
              </div>
            </div>
          </div>
          {/* <Grid style={{ margin: 0 }}>
        <Grid.Row className="footer-copyright">
          <p>© {year} Copyright BOA Hydraulics</p>
        </Grid.Row>
      </Grid> */}
        </>
      )}
    </div>
  );
};
export default Footer;
