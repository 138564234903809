import React from "react";
import { Grid , Card, Divider} from "semantic-ui-react";
import PropTypes from "prop-types";

const HoseCategories = ({ onClickHandler, selected = "", categories = [] }) => {
  return (
    <div className="hose-categories-box">
      <Grid>
        <Grid.Column width={16} mobile={16}>
        <h4>2. Select hose group<Divider/></h4>
        <Card.Group itemsPerRow={4}>
            {categories.map((hoseCat, key) => {
              return (
                <Card
                  key={key}
                  onClick={e => onClickHandler(e, hoseCat)}
                  color={hoseCat === selected ? "green" : ""}
                  description={hoseCat}
                />
              );
            })}
            </Card.Group>
        </Grid.Column>
      </Grid>
    </div>
  );
};

HoseCategories.prototype = {
  selected: PropTypes.string,
  onClickHandler: PropTypes.func,
  categories: PropTypes.array
};

export default HoseCategories;
