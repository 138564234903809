import React from "react";
import _ from "lodash";
import axios from "axios";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import addToCart from "./AddtoCart";
import { Grid, Image, Button, Icon, Form, Label } from "semantic-ui-react";
import * as cartReducer from "../Cart/cart.reducer";
import ItemProperties from "./ItemProperties";
import FamilyBreadCrumb from "./FamilyBreadCrumb";
import productDefaultImage from "../../assets/images/product_default.png";
import NumericInput from "react-numeric-input";
import PricingPolicy from "./PricingPolicy";
import BuyByCoil from "../../components/BuyByCoil";
import { PRICE_ZERO_TEXT } from "../../constants/appConstants";
import * as searchReducer from "../Search/search.reducer";
import moment from "moment";
// const BOX_DISCOUNT = 5;

class Description extends React.Component {
  constructor() {
    super();
    this.state = {
      quantity: 1,
      length: 1,
      errorFlag: false,
      isShowMore: false,
      boxSize: [],
      discountObj: {},
      discountLoader: false,
      isLoading: false,
    };
  }

  componentDidMount() {
    const { productDimensions } = this.props.item;
    const { fullCoilSizeMetre } = productDimensions;
    let boxSizeArray = [];
    if (fullCoilSizeMetre) {
      if (fullCoilSizeMetre.toString().includes(",")) {
        boxSizeArray = fullCoilSizeMetre.toString.split(",");
      } else {
        boxSizeArray.push(fullCoilSizeMetre.toString());
      }

      // const boxSizeArray = fullCoilSizeMetre.split(",");
      this.setState({ boxSize: boxSizeArray[0] }, () => {
        const { tertiaryGrpDisplayName } = this.props;
        if (tertiaryGrpDisplayName == "Spiral Guard") {
          this.setState(
            {
              quantity: fullCoilSizeMetre,
            },
            () => {
              this.getBoxPrice(this.state.quantity);
            }
          );
        } else {
          this.setState({ quantity: 1 }, () => {
            this.getBoxPrice(this.state.quantity);
          });
        }
      });
    }
  }

  onShowMore = (item) => {
    this.setState({ isShowMore: true });

    this.callSearchApi(item);
  };

  callSearchApi = async (item) => {
    this.setState({ isLoading: true });

    const result = await this.props.actionSearchProductDetail(item);

    // const { data } = result;

    // const active = {
    //   module,
    // };
    // this.computeOpenFilterItems(secondaryGrpDisplayName, active);
    this.setState({
      isLoading: false,
    });
  };

  onShowLess = () => {
    this.setState({ isShowMore: false });
  };

  addToCartSubmitHandler = (e) => {
    e.preventDefault();
    const { quantity, length } = this.state;
    this.addCartHandler(quantity, length);
  };

  addBoxRollHandler = (quantity) => {
    this.addCartHandler(quantity);
  };

  selectBoxCoilSizeHandler = (size) => {
    const { quantity } = this.state;
    if (quantity >= size) {
      const div = Number((quantity / size).toFixed(0));
      this.setState({ quantity: (div + 1) * size, boxSize: size }, () => {
        this.getBoxPrice(this.state.quantity);
      });
    } else {
      this.setState({ quantity: size, recentQuantity: size }, () => {
        this.getBoxPrice(this.state.quantity);
      });
    }
  };
  selectBoxCoilSizeHandlerWithStatus = (size, status) => {
    const { quantity } = this.state;
    if (quantity == Number(size - 1)) {
      this.setState({ quantity: Number(size), recentQuantity: size }, () => {
        this.getBoxPrice(this.state.quantity);
      });
    } else if (status === 2) {
      const div = Number((quantity / size).toFixed(0));
      this.setState({ quantity: (div + 1) * size, boxSize: size }, () => {
        this.getBoxPrice(this.state.quantity);
      });
    } else if (status === 1) {
      const div = Number((quantity / size).toFixed(0));
      this.setState({ quantity: (div - 1) * size, boxSize: size }, () => {
        this.getBoxPrice(this.state.quantity);
      });
    } else {
      this.setState({ quantity: size, recentQuantity: size }, () => {
        this.getBoxPrice(this.state.quantity);
      });
    }
  };

  beforeQuantityIsSet = (status) => {
    const { productDimensions } = this.props.item;
    if (!productDimensions) {
      return "";
    }
    const { fullCoilSizeMetre } = productDimensions;
    let allSizes = [];
    if (!fullCoilSizeMetre) {
      return "";
    }
    if (_.isEqual(fullCoilSizeMetre, "0")) {
      return "";
    }
    if (fullCoilSizeMetre.toString().includes(",")) {
      allSizes = fullCoilSizeMetre.toString().split(",");
    } else {
      allSizes.push(fullCoilSizeMetre.toString());
    }

    this.selectBoxCoilSizeHandlerWithStatus(allSizes[0], status);
  };

  apiGetBoxSizePriceDiscount = async (data) => {
    let url = "/pricingPolicy" + "/getBoxPriceDiscount";
    return axios.post(url, data).then((result) => {
      return result.data;
    });
  };

  getBoxPrice = async (quantity) => {
    // const { discountLoader } = this.state;
    const { item } = this.props;
    const {
      stockCode,
      priceGrp,
      price,
      currency,
      pricingPolicy,
      productDimensions,
    } = item;
    const { fullCoilSizeMetre } = productDimensions;
    const data = {
      stockCode,
      priceGrp,
      quantity,
      price,
      currency,
      pricingPolicy,
      fullCoilSizeMetre,
    };
    if (fullCoilSizeMetre != undefined) {
      this.setState({ discountLoader: true });
      const result = await this.apiGetBoxSizePriceDiscount(data);
      this.setState({ discountObj: result });
      this.setState({ discountLoader: false });
    }
    //await this.props.actionGetBoxSizePriceDiscount(data);
  };

  addCartHandler = (quantity, length = 1) => {
    const {
      stockCode,
      stockDescription,
      pack,
      pricingPolicy,
      id,
      taxId,
      productUom,
      boahubFullCarton,
    } = this.props.item;
    const { email } = this.props.userInfo;
    const { cart } = this.props;
    const { price, currencyId } = pricingPolicy;
    let data = {
      stockCode,
      quantity: Number(quantity),
      length: length,
      pack,
      stockDescription,
      price,
      currencyId,
      id,
      email,
      taxId,
      productUom,
      refId: null,
    };
    this.props.actionAddToCart(data);
    const isPresent = _.filter(cart, (item) => {
      return stockCode === item.barcode;
    });

    let newQuantity = Number(this.state.quantity);

    if (boahubFullCarton > 1)
      newQuantity =
        Number(this.state.quantity) < Number(boahubFullCarton)
          ? Number(quantity)
          : Number(this.state.quantity) + Number(quantity);

    addToCart({ stockCode, isPresent });
    this.setState({
      quantity: newQuantity,
    });
  };

  quantityChangeHandler = (e, data) => {
    const { tertiaryGrpDisplayName } = this.props;
    let status = 0;
    if (this.state.quantity > data) {
      status = 1; //decresed
    } else if (this.state.quantity < data) {
      status = 2; // incresed
    }
    this.setState({ quantity: data }, () => {
      if (tertiaryGrpDisplayName == "Spiral Guard") {
        this.beforeQuantityIsSet(status);
      } else {
        this.getBoxPrice(data);
      }
    });
  };
  isShowMoreButton = (properties, images) => {
    properties = _.pickBy(properties, _.identity);
    if (properties.length < 2) {
      return false;
    }

    if (!images) {
      return false;
    }

    return true;
  };

  render() {
    const {
      item,
      options = true,
      fullBoxCoilPercent,
      userInfo,
      // tertiaryGrpDisplayName
      isHoseMaker,
      onProductClick,
    } = this.props;
    const { account } = userInfo;
    const { errorFlag, quantity, boxSize, discountLoader } = this.state;

    const {
      stockImage1,
      stockImage2,
      stockImage3,
      pack,
      pricingPolicy,
      productDimensions,
    } = item;
    const { fullCoilSizeMetre } = productDimensions;
    const { policyName, price } = pricingPolicy;
    const {
      totalDiscount,
      currency,
      price: priceFromBoxPrice,
    } = this.state.discountObj;

    let boxCoilPolicyInfoJSX = "";
    if (_.isEmpty(policyName) && !_.isEmpty(fullCoilSizeMetre)) {
      boxCoilPolicyInfoJSX = (
        // <Label color="red" size="tiny" tag>
        <p style={{ color: "red" }}>
          {pack === "Metre" ? "Coil" : "Box"} size policy - available for this
          product with extra discount
          {/* </Label> */}
        </p>
      );
    }

    const Quantity = ({ pack }) => {
      // const isEach = pack == "Each";
      const isMeter = pack === "Metre" || pack === "Feet";

      const quantityMessage = isMeter ? pack : "Quantity";

      return (
        <div className="quantity-form">
          <Grid columns="equal">
            <Grid.Column
              width={16}
              style={{ marginTop: 0, paddingTop: 0, marginBottom: 5 }}
              className="quantity-label no-left-padded"
            >
              <Form.Field>
                <label htmlFor="quantity">{quantityMessage}</label>

                <NumericInput
                  onChange={this.quantityChangeHandler}
                  name="quantity"
                  min={1}
                  max={10000}
                  maxLength={6}
                  className="form-control"
                  precision={0}
                  step={1}
                  value={quantity}
                />
              </Form.Field>
            </Grid.Column>
          </Grid>
        </div>
      );
    };

    // const calculateAvailableDiscount = () => {
    //   // const { price, currency } = pricingPolicy;
    //   // const isEligible = quantity % boxSize;
    //   // let percentage = BOX_DISCOUNT;
    //   // if (fullBoxCoilPercent != null) {
    //   //   percentage = fullBoxCoilPercent;
    //   // }
    //   // if (policyName) {
    //   //   return "";
    //   // }
    //   // if (!isEligible) {
    //   //   const discount = Number(
    //   //     (((price * quantity) / 100) * percentage).toFixed(2)
    //   //   );

    //   return (

    //   );
    //   // }
    //   return "";
    // };

    return (
      <Grid>
        <Grid.Row className="product-info-grid">
          <Grid.Column
            width={16}
            only="mobile tablet"
            className="add-card-column no-padding"
          >
            <Form
              error={errorFlag}
              model=""
              onSubmit={this.addToCartSubmitHandler}
            >
              <Grid>
                <Grid.Column width={8} className="no-padding">
                  <Quantity pack={pack} />
                </Grid.Column>
                {price ? (
                  <Grid.Column width={8} className="no-padding ">
                    <Button
                      fluid
                      animated="vertical"
                      size="small"
                      type="submit"
                      color="black"
                      className="cart-button"
                    >
                      <Button.Content hidden>
                        <Icon name="cart" />
                      </Button.Content>
                      <Button.Content visible>Add to cart</Button.Content>
                    </Button>
                  </Grid.Column>
                ) : (
                  PRICE_ZERO_TEXT
                )}
              </Grid>
            </Form>
          </Grid.Column>
          <Grid.Column
            width={4}
            computer={4}
            mobile={4}
            className="product-image-col no-padding"
          >
            <Image
              size={"small"}
              src={
                stockImage1
                  ? `data:image/jpeg;base64,${stockImage1}`
                  : productDefaultImage
              }
            />
          </Grid.Column>
          <Grid.Column width={12} className="product-info-grid">
            <Grid>
              {!isHoseMaker && (
                <Grid.Column
                  computer={12}
                  mobile={16}
                  tablet={16}
                  className="no-padding"
                >
                  <div style={{ minHeight: "80px" }}>
                    <PricingPolicy {...pricingPolicy} type="search" />
                    <FamilyBreadCrumb {...item} />
                    {options && (
                      <BuyByCoil
                        {...productDimensions}
                        product={item}
                        addBoxSize={this.selectBoxCoilSizeHandler}
                        pack={pack}
                      />
                    )}
                    {options && boxCoilPolicyInfoJSX}
                  </div>
                  <ItemProperties
                    {...{
                      ...item,
                      isShowMore: this.state.isShowMore,
                      onShowMore: this.onShowMore,
                      onShowLess: this.onShowLess,
                      stockImage2: stockImage2,
                      isShowMoreButton: this.isShowMoreButton(
                        item.productDimensions,
                        stockImage2
                      ),
                    }}
                  />
                </Grid.Column>
              )}
              {options && !isHoseMaker && (
                <Grid.Column
                  width={4}
                  only="computer"
                  className="add-card-column"
                >
                  {/* {price ? ( */}
                  <Form
                    error={errorFlag}
                    model=""
                    style={{ textAlign: "center" }}
                  >
                    <Quantity pack={pack} />
                    {item.boahubFullCarton > 0 ? (
                      <div>
                        <label style={{ fontSize: 12 }}>
                          {item.productUom === 1 ? "Box" : "Roll"} size:{" "}
                          {item.boahubFullCarton}
                        </label>
                        <Button
                          fluid
                          size="small"
                          style={{ marginBottom: 8 }}
                          onClick={() =>
                            this.addBoxRollHandler(item.boahubFullCarton)
                          }
                        >
                          <Button.Content visible>
                            ADD {item.boahubFullCarton}
                          </Button.Content>
                        </Button>
                      </div>
                    ) : (
                      <div style={{ marginBottom: 10 }}></div>
                    )}
                    <Button
                      fluid
                      animated="vertical"
                      size="small"
                      color="black"
                      onClick={this.addToCartSubmitHandler}
                    >
                      <Button.Content hidden>
                        <Icon name="cart" />
                      </Button.Content>
                      <Button.Content visible>Add to cart</Button.Content>
                    </Button>
                  </Form>
                  {/* ) : (
                    PRICE_ZERO_TEXT
                  )} */}
                  {discountLoader && "Please wait calculating the discount!"}

                  {priceFromBoxPrice === price &&
                    boxSize > 0 &&
                    quantity % boxSize === 0 && (
                      <div>
                        <h4 className="box-saving-text">
                          You will save {currency} {totalDiscount}
                        </h4>
                      </div>
                    )}
                </Grid.Column>
              )}
              {isHoseMaker && (
                <>
                  <Grid.Column
                    computer={12}
                    mobile={16}
                    tablet={16}
                  ></Grid.Column>
                  <Grid.Column
                    width={4}
                    only="computer"
                    className="add-card-column"
                  >
                    <Button
                      fluid
                      animated="vertical"
                      size="small"
                      type="submit"
                      color="black"
                      onClick={() => onProductClick(item)}
                      style={{
                        paddingLeft: 0,
                        paddingRight: 0,
                      }}
                    >
                      Select
                    </Button>
                  </Grid.Column>
                </>
              )}
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    accountDetails: state.search.accountDetails,
    boxPrice: state.search.boxPrice,
    userInfo: state.auth.user,
    // tertiaryGrpDisplayName: state.search.tertiaryGrpDisplayName
  };
};

const reducer = { ...cartReducer, ...searchReducer };

const mapDispatchToProps = (dispatch) => bindActionCreators(reducer, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Description);
