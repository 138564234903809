const HISapiProvider = {
  username: "boahub",
  password: "BOAhub@2024",
  baseUrl: "https://ml.boahub.co/api",
  // baseUrl: "http://localhost:8000/api",
};

export const HISfetchData = async (endpoint, params) => {
  const baseUrl = HISapiProvider.baseUrl;
  const queryParam = new URLSearchParams(params).toString();
  const url = queryParam
    ? `${baseUrl}${endpoint}?${queryParam}`
    : `${baseUrl}${endpoint}`;
  const encoded_url = url.replace(" ", "%20");
  const response = await fetch(encoded_url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(
        `${HISapiProvider.username}:${HISapiProvider.password}`
      )}`,
    },
  });
  return response;
};

export const HISpostData = async (endpoint, data) => {
  const baseUrl = HISapiProvider.baseUrl;
  const url = `${baseUrl}${endpoint}`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(
        `${HISapiProvider.username}:${HISapiProvider.password}`
      )}`,
    },
    body: JSON.stringify(data),
  });
  return response;
};

export const HISputData = async (endpoint, data) => {
  const baseUrl = HISapiProvider.baseUrl;
  const url = `${baseUrl}${endpoint}`;
  const response = await fetch(url, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(
        `${HISapiProvider.username}:${HISapiProvider.password}`
      )}`,
    },
    body: JSON.stringify(data),
  });
  return response;
};

export const HISdeleteData = async (endpoint) => {
  const baseUrl = HISapiProvider.baseUrl;
  const url = `${baseUrl}${endpoint}`;
  const response = await fetch(url, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(
        `${HISapiProvider.username}:${HISapiProvider.password}`
      )}`,
    },
  });
  return response;
};

export const HISfetchDataCallback = async (endpoint, params, callbackFun) => {
  const baseUrl = HISapiProvider.baseUrl;
  console.log(baseUrl);
  const queryParam = new URLSearchParams(params).toString();
  const url = queryParam
    ? `${baseUrl}${endpoint}?${queryParam}`
    : `${baseUrl}${endpoint}`;
  const encoded_url = url.replace(" ", "%20");
  const response = await fetch(encoded_url, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic ${btoa(
        `${HISapiProvider.username}:${HISapiProvider.password}`
      )}`,
    },
  });

  if (response.ok) {
    const data = await response.json();
    callbackFun(data);
  } else {
    console.log("Error fetching data");
  }
};
