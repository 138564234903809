import React, { useState, useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Control, Errors } from "react-redux-form";
import {
  Grid,
  Divider,
  Icon,
  Form,
  Button,
  Segment,
  Dropdown,
} from "semantic-ui-react";
import * as authReducer from "../../containers/Auth/auth.reducer";
import polyglot from "../../i18n";
import CustomInput from "../CustomInput";
import Loader from "../Loader";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";
import { withRouter } from "react-router-dom";
import certificateIcon from "../../assets/images/certificate.svg";
import certificateUploadIcon from "../../assets/images/certificate-upload.svg";

import "./profileDetails.css";
import {
  SITE_KEY,
  industries,
  countries,
  customerRoles,
  TERMS_CONDITION_LINK,
  APP_NAME,
} from "../../constants/appConstants";
import { bindActionCreators } from "redux";
import {
  apiGetMyUsersList,
  apiGetStockLocationList,
} from "../../containers/Settings/settings.api";
import { apiGetUserCurrentStockLocation } from "../../containers/Cart/cart.api";
import TrainingCertificate from "../../containers/Settings/TrainingCertificate";

const ProfileDetails = ({
  auth,
  forms,
  editProfileFlag,
  actionSetEditProfileFlag,
  actionSetProfileInfoForm,
  actionUpdateProfileInfo,
  actionGetProfileData,
  history,
}) => {
  const [state, setState] = useState({
    initialLoad: false,
    loading: false,
    errorFlag: false,
    mobileErrorFlag: false,
    mobileErrorMessage: "",
    officeNumberErrorFlag: false,
    countryErrorFlag: false,
    officeNumberErrorMessage: "",
    loadingText: "Please wait",
  });
  const [stocklocationList, setStocklocationList] = useState([]);
  const [stockLocationId, setStockLocationId] = useState();
  const [stockLocationName, setStockLocationName] = useState("");

  // RS23 Training Certificate
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [viewTrainingCertificate, setViewTrainingCertificate] = useState(false);

  const getMyUsersData = async () => {
    setIsLoading(true);
    const { user } = auth;
    const result = await apiGetMyUsersList();
    if (result) {
      const delayDebounceFn = setTimeout(() => {
        const currentUserData = result.find(
          (userData) => userData?.email === user.email
        );
        if (currentUserData) {
          setUserData(currentUserData);
        }
        setIsLoading(false);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  };

  useEffect(() => {
    const { user } = auth;
    if (user.id) {
      const delayDebounceFn = setTimeout(() => {
        getMyUsersData();
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [auth]);

  useEffect(() => {
    const { user } = auth;
    if (user && user.id && stocklocationList.length > 0) {
      let stockLocation = stocklocationList.filter(
        (obj) => obj.id === user.boahubLocationId
      );

      if (stockLocation.length > 0) {
        setStockLocationName(stockLocation[0].text);
      }
    }
  }, [auth, stocklocationList]);

  useEffect(() => {
    if (forms.profileInfoForm.boahubLocationId) {
      let stockLocation = stocklocationList.filter(
        (obj) => obj.id === forms.profileInfoForm.boahubLocationId
      );

      if (stockLocation.length > 0) {
        setStockLocationName(stockLocation[0].text);
      }
    }
  }, [forms.profileInfoForm]);

  const getStockLocationsData = async () => {
    const result = await apiGetStockLocationList();
    if (result) {
      setStocklocationList(result);
    } else {
      setStocklocationList([]);
    }
  };

  const editProfileInfo = () => {
    setForm();
    actionSetEditProfileFlag(true);
  };

  const cancelEdit = () => {
    unSetForm();
    actionSetEditProfileFlag(false);
  };

  const setForm = () => {
    const { user } = auth;
    const formData = getFormData(user);
    actionSetProfileInfoForm(formData);
  };

  const unSetForm = () => {
    const data = getFormData();
    actionSetProfileInfoForm(data);
  };

  const getFormData = (user) => {
    if (!user) {
      return {};
    }
    const {
      id,
      firstName,
      lastName,
      mobileNumber,
      email,
      officeNumber: officePhoneNumber,
      type,
      street,
      street2,
      suburb,
      state,
      zip,
      country,
      boahubLocationId,
    } = user;
    const { id: stateId } = state;
    const formData = {
      id: id,
      firstName: firstName ? firstName : "",
      lastName: lastName ? lastName : "",
      mobileNumber: mobileNumber ? mobileNumber : "",
      email: email,
      officePhoneNumber: officePhoneNumber ? officePhoneNumber : "",
      type: type ? type : "",
      street: street ? street : "",
      street2: street2 ? street2 : "",
      suburb: suburb ? suburb : "",
      stateId: stateId,
      countryId: country && country.id,
      zip: zip,
      boahubLocationId: boahubLocationId,
    };
    return formData;
  };

  const updateProfileHandler = async () => {
    const { profileInfoForm } = forms;

    updateState({ loading: true, loadingText: "Updating data" });

    let profileInfo = Object.assign(
      {},
      {
        firstName: profileInfoForm.firstName,
        lastName: profileInfoForm.lastName,
        mobileNumber: profileInfoForm.mobileNumber,
        officeNumber: profileInfoForm.officePhoneNumber,
        id: profileInfoForm.id,
        type: profileInfoForm.type,
        street: profileInfoForm.street,
        street2: profileInfoForm.street2,
        suburb: profileInfoForm.suburb,
        stateId: profileInfoForm.stateId,
        zip: profileInfoForm.zip,
        boahubLocationId: profileInfoForm.boahubLocationId,
      }
    );

    await actionUpdateProfileInfo(profileInfo);
    await getProfileData();
    await getCurrentStockLocation();

    const { response } = auth;
    actionSetEditProfileFlag(false);
    if (response) {
      if (response === 409 || response === "409") {
        return showErrorToast(
          "Email already in use. Please choose a new email address."
        );
      }
    }

    return showSuccessToast(
      <div>
        <Icon name="user" /> Profile updated{" "}
      </div>
    );
  };

  const getProfileData = async () => {
    updateState({ loading: true });
    await actionGetProfileData();
    const { response } = auth;
    updateState({ loading: false });
  };

  const getCurrentStockLocation = async () => {
    const currentStockLocation = await apiGetUserCurrentStockLocation();
    if (currentStockLocation.id)
      window.localStorage.setItem("boahubLocationId", currentStockLocation.id);
    window.localStorage.setItem(
      "stockLocationName",
      currentStockLocation.stockLocationName
    );
  };

  const updateState = (data) => {
    setState((prevState) => ({ ...prevState, ...data }));
  };

  const onChangeMobileHandler = (event) => {
    updateState({ mobileErrorFlag: false });
    let value = event.target.value;
    if (_.isEmpty(value)) {
      updateState({
        mobileErrorFlag: true,
        mobileErrorMessage: polyglot.t("createAccountPage.mobileRequried"),
      });
    }
    if ((value.length && value.length < 6) || value.length > 13) {
      updateState({
        mobileErrorFlag: true,
        mobileErrorMessage: polyglot.t("createAccountPage.validMobileNumber"),
      });
    }
    const formValue = {
      ...forms.profileInfoForm,
      mobileNumber: value,
    };
    actionSetProfileInfoForm(formValue);
  };

  const onChangeOfficePhoneHandler = (event) => {
    updateState({ officeNumberErrorFlag: false });
    let value = event.target.value;
    if ((value.length && value.length < 6) || value.length > 12) {
      updateState({
        officeNumberErrorFlag: true,
        officeNumberErrorMessage: polyglot.t(
          "createAccountPage.validOfficePhoneNumber"
        ),
      });
    }
    const formValue = {
      ...forms.profileInfoForm,
      officePhoneNumber: value,
    };
    actionSetProfileInfoForm(formValue);
  };

  const goTochangePassword = (event) => {
    event.preventDefault();
    history.push("/profile/changePassword");
  };

  const goBack = (e) => {
    e.preventDefault();
    history.push("/home");
  };

  const resendVerificationEmail = async () => {
    updateState({ loading: true, loadingText: "Sending mail" });
    await actionResendVerifyNewEmailLink();
    updateState({ loading: false });
    const { response } = auth;
    if (response.status !== 200) {
      return showErrorToast("Something went wrong please try again");
    }

    return showSuccessToast(
      <div>
        <Icon name="mail" /> Delivery Address changed{" "}
      </div>
    );
  };

  const countryChangeHandler = (e, data) => {
    updateState({ countryErrorFlag: false });
    const { value } = data;
    if (_.isEmpty(value)) {
      updateState({ countryErrorFlag: true });
    }

    const formValue = {
      ...forms.profileInfoForm,
      countryId: value,
    };
    actionSetProfileInfoForm(formValue);
  };

  const stockLocationChangeHandler = (e, data) => {
    const { value } = data;

    const formValue = {
      ...forms.profileInfoForm,
      boahubLocationId: value,
    };
    actionSetProfileInfoForm(formValue);
  };

  const getUserInfo = async () => {
    await actionGetProfileData();
  };

  useEffect(() => {
    getStockLocationsData();
    getUserInfo();
  }, []);

  const { user, accountInfo } = auth;

  const {
    id,
    firstName = "",
    lastName = "",
    mobileNumber,
    officeNumber: officePhoneNumber,
    newEmail,
    verifiedNewMail,
    email,
    userRole,
    customerType,
    country,
    boahubLocationId,
    loading = true,
  } = user;

  // const {
  //   errorFlag,
  //   mobileErrorFlag,
  //   mobileErrorMessage,
  //   officeNumberErrorMessage,
  //   officeNumberErrorFlag,
  //   loadingText,
  //   countryErrorFlag,
  //   initialLoad,
  // } = state;

  const firstNameForm = (
    <Form.Field required>
      <Control
        type="text"
        model="forms.profileInfoForm.firstName"
        placeholder={polyglot.t("profileInfo.firstName")}
        required
        validateOn="change"
        className="input-box"
      />
      <Errors
        style={{ color: "red" }}
        model="forms.profileInfoForm.firstName"
        show="touched"
        messages={{
          valueMissing: "First name is required",
        }}
      />
    </Form.Field>
  );

  const lastNameForm = (
    <Form.Field required>
      <Control
        type="text"
        model="forms.profileInfoForm.lastName"
        placeholder={polyglot.t("profileInfo.lastName")}
        required
        validateOn="change"
        className="input-box"
      />
      <Errors
        style={{ color: "red" }}
        model="forms.profileInfoForm.lastName"
        show="touched"
        messages={{
          valueMissing: "Last Name is required",
        }}
      />
    </Form.Field>
  );

  const emailForm = (
    <Form.Field>
      <Control
        type="email"
        model="forms.profileInfoForm.email"
        placeholder={polyglot.t("profileInfo.email")}
        required
        validateOn="change"
        className="input-box"
        disabled
      />
      <Errors
        style={{ color: "red" }}
        model="forms.profileInfoForm.email"
        show="touched"
        messages={{
          valueMissing: "Email is required",
          typeMismatch: "Invalid email address",
        }}
      />
    </Form.Field>
  );

  const mobileForm = (
    <Form.Field required>
      <CustomInput
        placeholder={polyglot.t("createAccountPage.mobileNumber")}
        options={{ phone: true, phoneRegionCode: "NZ" }}
        onChangeHandler={onChangeMobileHandler}
        defaultValue={forms.profileInfoForm.mobileNumber}
      />
      {state.mobileErrorFlag && (
        <span className="error-message">{state.mobileErrorMessage}</span>
      )}
    </Form.Field>
  );

  const officeNumberForm = (
    <Form.Field required>
      <CustomInput
        placeholder={polyglot.t("createAccountPage.officePhoneNumber")}
        options={{ phone: true, phoneRegionCode: "NZ" }}
        onChangeHandler={onChangeOfficePhoneHandler}
        defaultValue={forms.profileInfoForm.officePhoneNumber}
      />
      {state.officeNumberErrorFlag && (
        <span className="error-message">{state.officeNumberErrorMessage}</span>
      )}
    </Form.Field>
  );

  const countryForm = (
    <Form.Field>
      <Dropdown
        disabled
        placeholder={polyglot.t("createAccountPage.country")}
        selection
        options={countries}
        onChange={countryChangeHandler}
        value={forms.profileInfoForm.countryId + ""}
      />
      {state.countryErrorFlag && (
        <span className="error-message">Country name is required</span>
      )}
    </Form.Field>
  );

  const stockLocationForm = (
    <Form.Field>
      <Dropdown
        search
        disabled={!editProfileFlag}
        selection
        options={stocklocationList}
        onChange={stockLocationChangeHandler}
        value={forms.profileInfoForm.boahubLocationId}
      />
    </Form.Field>
  );

  const hiddenForm = (
    <div>
      <Form.Field>
        <Control
          type="hidden"
          model="forms.profileInfoForm.id"
          className="input-box"
        />
      </Form.Field>
      <Form.Field>
        <Control
          type="hidden"
          model="forms.profileInfoForm.type"
          className="input-box"
        />
      </Form.Field>
      <Form.Field>
        <Control
          type="hidden"
          model="forms.profileInfoForm.street"
          className="input-box"
        />
      </Form.Field>
      <Form.Field>
        <Control
          type="hidden"
          model="forms.profileInfoForm.street2"
          className="input-box"
        />
      </Form.Field>
      <Form.Field>
        <Control
          type="hidden"
          model="forms.profileInfoForm.suburb"
          className="input-box"
        />
      </Form.Field>
      <Form.Field>
        <Control
          type="hidden"
          model="forms.profileInfoForm.stateId"
          className="input-box"
        />
      </Form.Field>
      <Form.Field>
        <Control
          type="hidden"
          model="forms.profileInfoForm.zip"
          className="input-box"
        />
      </Form.Field>
    </div>
  );

  return (
    <div className="profile-details">
      <div className="title">
        <h3>Personal Information</h3>
        <div className="changePassword">
          <a onClick={goTochangePassword}>Change password </a>
        </div>
      </div>
      {viewTrainingCertificate && (
        <TrainingCertificate
          isLoading={isLoading}
          userData={userData}
          userId={userData.id}
          setViewTrainingCertificate={setViewTrainingCertificate}
          getMyUsersData={getMyUsersData}
        />
      )}
      <Divider />
      {(isLoading || state.loading || !id) && (
        <Loader text={state.loadingText} />
      )}
      <Form
        error={state.errorFlag}
        className="ui form"
        model="form.profileInfo"
        onSubmit={updateProfileHandler}
      >
        <Grid>
          <Grid.Column width={8} computer={8} mobile={16}>
            {!editProfileFlag && (
              <div>
                <label className="profile-field-key">Name:</label>
                <p>{firstName + " " + lastName}</p>
              </div>
            )}
            {editProfileFlag && (
              <Grid width={8} computer={8} mobile={16}>
                <Grid.Column
                  width={8}
                  computer={8}
                  mobile={16}
                  className="no-padding"
                >
                  <label className="profile-field-key">First name:</label>
                  {editProfileFlag && firstNameForm}
                </Grid.Column>
                <Grid.Column
                  width={8}
                  computer={8}
                  mobile={16}
                  className="no-padding"
                >
                  <label className="profile-field-key">Last name:</label>
                  {editProfileFlag && lastNameForm}
                </Grid.Column>
              </Grid>
            )}
          </Grid.Column>

          <Grid.Column width={8} computer={8} mobile={16}>
            <label className="profile-field-key"> Email:</label>
            {!editProfileFlag && <p>{email}</p>}
            {editProfileFlag && emailForm}
          </Grid.Column>

          <Grid.Column width={8} computer={8} mobile={16}>
            <label className="profile-field-key"> Office number:</label>
            {!editProfileFlag && <p>{officePhoneNumber}</p>}
            {editProfileFlag && officeNumberForm}
          </Grid.Column>

          <Grid.Column width={8} computer={8} mobile={16}>
            <label className="profile-field-key"> Mobile number:</label>
            {!editProfileFlag && <p>{mobileNumber}</p>}
            {editProfileFlag && mobileForm}
          </Grid.Column>

          <Grid.Column width={8} computer={8} mobile={16}>
            <label className="profile-field-key"> Country:</label>
            {!editProfileFlag && <p>{country && country.name}</p>}
            {editProfileFlag && countryForm}
          </Grid.Column>

          <Grid.Column width={8} computer={8} mobile={16}>
            <label className="profile-field-key"> Stock Location:</label>
            {!editProfileFlag ? <p>{stockLocationName}</p> : stockLocationForm}
          </Grid.Column>

          {newEmail && !verifiedNewMail && (
            <Grid.Column computer={16} mobile={16}>
              <Segment>
                <div style={{ textAlign: "center" }}>
                  Email{" "}
                  <b>
                    <i>{newEmail}</i>
                  </b>{" "}
                  is not verified yet,{" "}
                  <a onClick={resendVerificationEmail}>click </a>
                  to resend verification email.
                  <p>
                    Unless you verify your new email account, you will continue
                    to use the old email.
                  </p>
                </div>
              </Segment>
            </Grid.Column>
          )}
          <Grid.Column width={8} computer={16} mobile={16}>
            {hiddenForm}
          </Grid.Column>
          <Grid.Column
            computer={8}
            mobile={8}
            className="button-block"
            floated="left"
          >
            {userData ? (
              <div
                style={{
                  color: userData?.rs23TrainingCertificate?.id
                    ? "#66cc23"
                    : "#FFAD00",
                  cursor: "pointer",
                  overflow: "visible",
                }}
                onClick={() => {
                  setViewTrainingCertificate(true);
                }}
              >
                <img
                  style={{
                    width: 40,
                    marginRight: 5,
                    marginTop: -3.5,
                  }}
                  src={
                    userData?.rs23TrainingCertificate?.id
                      ? certificateIcon
                      : certificateUploadIcon
                  }
                />
                {userData?.rs23TrainingCertificate?.id ? "View" : "Upload"}{" "}
                training certificate
              </div>
            ) : null}
          </Grid.Column>
          <Grid.Column
            computer={8}
            mobile={8}
            className="button-block"
            floated="right"
          >
            {!editProfileFlag && (
              <div>
                <Button
                  disabled={state.loading || !id}
                  secondary
                  size="small"
                  onClick={editProfileInfo}
                  className="button-float"
                >
                  Edit
                </Button>
                <Button
                  size="small"
                  onClick={goBack}
                  icon
                  labelPosition="left"
                  className="button-float"
                >
                  <Icon name="left arrow" />
                  Go back
                </Button>
              </div>
            )}
            {editProfileFlag && (
              <span>
                <Button
                  disabled={state.loading}
                  secondary
                  size="small"
                  onSubmit={updateProfileHandler}
                  className="button-float"
                >
                  Update
                </Button>
                <Button
                  size="small"
                  onClick={cancelEdit}
                  className="button-float"
                >
                  Cancel
                </Button>
              </span>
            )}
          </Grid.Column>
        </Grid>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    forms: state.forms,
    editProfileFlag: state.auth.editProfileFlag,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(authReducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileDetails)
);
