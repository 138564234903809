import React from 'react';
import { Modal, Form, Header, Image, Button, Input, Icon, List, Message } from 'semantic-ui-react'
import { Control, Errors } from "react-redux-form";
import _ from "lodash";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { apiGetAccessToken } from './chat.api';
import * as chatReducer from "./chat.reducer";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";

const polyglot = window.polyglot;


class AddGroupModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            something: '',
            showModal: false,
            channelIcon: null,
            file: null,
            chatTitle: '',
            identity: 'nnn@mailinator.com',
            errorFlag: false,
            errorMessage: "",
            accessToken: '',
            videofile:null,
            isLoading:false

        }
    }
    closeModal = () => {
        this.setState({ showModal: false })
    }
    openModal = () => {
        this.setState({ showModal: true })
    }

   

    
    getBase64 = (file) => {
        return new Promise((resolve) => {
          let fileInfo;
          let baseURL = "";
          // Make new FileReader
          let reader = new FileReader();
    
          // Convert the file to base64 text
          reader.readAsDataURL(file);
    
          // on reader load somthing...
          reader.onload = () => {
            // Make a fileInfo Object
            //console.log("Called", reader);
            baseURL = reader.result;
            //console.log(baseURL);
            resolve(baseURL);
          };
         // console.log(fileInfo);
        });
      };

   
      onChangeImage = (e) => {
        console.log(e.target.files[0]);
        let { file } = this.state;
    
        file = e.target.files[0];
    
        this.getBase64(file)
          .then((result) => {
            file["base64"] = result;
            console.log("File Is", file);
            this.setState({
              base64URL: result,
              file
            },()=>{
                const formValue = {
                    ...this.props.forms.chatForm,
                    channelIcon: this.state.base64URL
                };
                this.props.actionSetFormValue(formValue);
            });
          })
          .catch((err) => {
            console.log(err);
          });
    
        this.setState({
          file: e.target.files[0]
        });

        
        this.setState({isLoading:false})
      };




    resetFile = (event) => {
        event.preventDefault();
        this.setState({ file: null });
    }


  
    //=============================================================
   
    handleCreateGroup = async () => {
     
        this.setState({ isLoading: true });
        const { chatForm } = this.props.forms;
      

        await this.props.actionCreateChatGroup(chatForm);
        const { response } = this.props;
        console.log("Chat group creation status :" + JSON.stringify(response))
        if (response) {
          if (response.status === 424) {
            this.updateState({
              errorFlag: true,
              errorMessage: "Something went wrong, please try it again"
            });
          }
          if (response.status === 409) {
            this.updateState({
              errorFlag: true,
              errorMessage:
                "Chat group with this Name already exist. Try with another name"
            });
          }
          
          if (response.status === 403) {
            this.updateState({
              errorFlag: true,
              errorMessage: "Forbidden"
            });
          }
     
        this.setState({ isLoading: false });
        if (response.status === 200) {
          
            showSuccessToast(`New conversation created successfully!!`);
        }
      
        }
        this.props.actionGetConversationsList();

        this.props.actionResetForm("chatForm")
        this.closeModal();

    };



    render() {
        const {

            showModal,
            isLoading
        } = this.state


        const { selectedUsers, onDeleteUser, forms } = this.props;
        return (
            <div>
                <Button fluid onClick={this.openModal} className="next_btn" disabled={!selectedUsers.length > 0}>Next</Button>
                <Modal className="custom_modal" closeIcon size='tiny' onClose={this.closeModal} open={showModal} >
                    <Modal.Header>New Group</Modal.Header>
                    <Modal.Content >
                        <Modal.Description>
                            <div className="fileupload_wrap">
                                <div className="fileupload_input">
                                    <div className="upload_img"></div>
                                    <Button className="button_uplaod" ><i className="boa ba-camera3"></i> </Button>
                                    <input
                                     type="file" 
                                     onChange={this.onChangeImage} accept="image/*" />
                                  
                                </div>
                                <div className="fileupload_label">
                                    <h4>Upload Group Image</h4>
                                    <p>Upload valid image. Only PNG and JPEG are allowed.</p>
                                </div>
                                <div style={{ width: "100px", height: "100px", border: "2px solid grey", borderRadius: "50px" }}>
                                    <Image size='medium' src={forms.chatForm.channelIcon} style={{ width: "100px", height: "100px", border: "2px solid grey", borderRadius: "50px" }} />
                                   
                                </div>
                            </div>
                            <div className="custom-form mt-2 mb-2">
                                <Form
                                    error={this.state.errorFlag}
                                    className="ui form"
                                    model="forms.chatForm"
                                >
                                
                                    <Form.Field required>
                                        <label>{polyglot.t("chatForm.chatTitle")}</label>
                                        <Control
                                            type="text"
                                            model="forms.chatForm.chatTitle"
                                            placeholder={polyglot.t("chatForm.chatTitle")}
                                            required
                                            validateOn="change"
                                            className="input-box"
                                        />
                                        <Errors
                                            style={{ color: "red" }}
                                            model="forms.chatForm.chatTitle"
                                            show="touched"
                                            messages={{
                                                valueMissing: "Group subject is required",
                                                typeMismatch: "Invalid Group subject"
                                            }}
                                        />
                                    </Form.Field>
                                 


                                </Form>
                            </div>
                            <div className="filter_options">
                                {_.map(selectedUsers, users => {
                                    return <List key={users.id}>
                                        <List.Item className="chips_item">
                                            <div className="chip_uesr_info">
                                                <div className="chip_user_img">PV</div>
                                                <div className="chip_user_name">{users.name}</div>
                                            </div>
                                            <div className="chip_action">
                                                <a href="#" className="close_btn"> <i className="boa ba-close_circle" onClick={() => onDeleteUser(users.value)}></i></a>
                                            </div>
                                        </List.Item>
                                    </List>
                                })}


                            </div>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions className="modal_footer">
                        <Button basic color='black' onClick={this.closeModal}>Back </Button>
                        <Button color='green'  loading={isLoading} onClick={this.handleCreateGroup} disabled={!forms.chatForm.chatTitle} >Create Group </Button>
                    </Modal.Actions>
                </Modal>
            </div>
        )
    }
}


const reducer = { ...chatReducer };


const mapStateToProps = state => {
    return {
        chatGroups: state.chatGroups,
        response: state.chatGroups.response,
        forms: state.forms


    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(reducer, dispatch);

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(AddGroupModal)
);