import React from "react";
import { Grid } from 'semantic-ui-react';


const ServerErrorPage = () => (
    <Grid>
        <Grid.Row verticalAlign="middle" >
            <div style={{ margin: "0px auto", minHeight: '220px', textAlign: 'center' }}>
                <h1>Oops 500 !</h1>
                <h2>Something went wrong</h2>

                <p>
                    Please refresh the screen and try again or get in touch with BOA if the problem persists.
                </p>
                <br />
                <a href="/"> Go Back </a>
            </div>
        </Grid.Row>
    </Grid>
);

export default ServerErrorPage;

