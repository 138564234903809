// XeroIntegrationPopup.js
import React, { useEffect, useState } from "react";
import {
  exchangeCodeForToken,
  getXeroAuthUrl,
} from "../../services/xeroService";
import { ReactComponent as XeroConnectButton } from "../../assets/images/buttons/connect-white.svg";
import { ReactComponent as XeroDisconnectButton } from "../../assets/images/buttons/disconnect-white.svg";
import "./xeroAuth.css";
import {
  apiDisconnectXero,
  apiGenerateXeroToken,
} from "../../containers/Settings/settings.api";
import { showSuccessToast } from "../../utils/toastUtils";

const XeroIntegrationPopup = ({ onIntegrationComplete, xeroAuthUrl }) => {
  const [isXeroConnected, setIsXeroConnected] = useState(false);
  const [xeroTenantName, setXeroTenantName] = useState("");
  const [xeroLoading, setXeroLoading] = useState(false);

  useEffect(() => {
    const isXeroConnectedData = localStorage.getItem("isXeroConnected");
    setIsXeroConnected(isXeroConnectedData === "true" ? true : false);
    const xeroTenantNameData = localStorage.getItem("xeroTenantName");
    setXeroTenantName(xeroTenantNameData);
  }, []);

  const handleXeroAuth = () => {
    setXeroLoading(true);
    const width = 600;
    const height = 600;

    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 2;

    const url = getXeroAuthUrl();
    const popup = window.open(
      url,
      "_blank",
      `width=${width}, height=${height}, left=${left}, top=${top}`
    );

    const handlePopupClose = async () => {
      try {
        // Check if the popup is closed and the code is available in the URL
        if (popup.closed) {
          const code = new URLSearchParams(popup.location.search).get("code");
          const state = new URLSearchParams(popup.location.search).get("state");
          console.log(popup.location);
          console.log(code);
          console.log(state);
          if (code && state) generateXeroTokenData(code, state);
        }
      } catch (error) {
        console.error("Error handling Xero callback:", error);
      }
    };

    // Add an event listener to detect when the popup is closed
    const interval = setInterval(() => {
      if (popup.closed) {
        clearInterval(interval);
        handlePopupClose();
        setXeroLoading(false);
      }
    }, 500);
  };

  const handleXeroDisconnect = async () => {
    setXeroLoading(true);
    const result = await apiDisconnectXero();
    if (result) {
      window.localStorage.setItem("isXeroConnected", false);
      setIsXeroConnected(false);
      setXeroLoading(false);
      showSuccessToast(
        <div>
          <i className="check icon" /> Disconnected to Xero successfully!
        </div>
      );
    }
  };

  const generateXeroTokenData = async (code, state) => {
    setXeroLoading(true);
    const result = await apiGenerateXeroToken(code, state);
    if (result) {
      window.localStorage.setItem("isXeroConnected", true);
      setIsXeroConnected(true);
      setXeroLoading(false);
      showSuccessToast(
        <div>
          <i className="check icon" /> Connected to Xero successfully!
        </div>
      );
    }
  };

  return (
    <div>
      {isXeroConnected && (
        <>
          <div>
            <span style={{ fontSize: 16 }}>Connected Xero Organization</span>
          </div>
          <div
            style={{
              marginBottom: "1rem",
              fontSize: 20,
              fontWeight: "bold",
              paddingTop: 5,
            }}
          >
            <span>{localStorage.getItem("xeroTenantName")}</span>
          </div>
        </>
      )}
      <div disabled={xeroLoading}>
        <button
          onClick={!isXeroConnected ? handleXeroAuth : handleXeroDisconnect}
          className="svg-button"
        >
          {isXeroConnected ? <XeroDisconnectButton /> : <XeroConnectButton />}
        </button>
      </div>
    </div>
  );
};

export default XeroIntegrationPopup;
