import {
  apiGetSchedules,
  apiUpdateSchedule,
  apiGetAssets,
  apiGetScheduleCounts
} from "./schedule.api";

const UPDATE_SCHEDULE = "UPDATE_SCHEDULE";
const SET_SCHEDULES = "SET_SCHEDULES";
const SET_ASSET = "SET_ASSET";
const IS_HISTORY_SCHEDULE = "IS_HISTORY_SCHEDULE";
const SET_COUNTS = "SET_COUNTS";

const initialState = {
  schedules: {},
  assets: [],
  isHistory: "upcoming",
  counts: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SCHEDULE:
      return { ...state };

    case SET_SCHEDULES:
      return {
        ...state,
        schedules: action.payload
      };

    case SET_ASSET:
      return {
        ...state,
        assets: action.payload
      };

    case IS_HISTORY_SCHEDULE:
      return {
        ...state,
        isHistory: action.payload
      };
    case SET_COUNTS: {
      return {
        ...state,
        counts: action.payload
      };
    }
    default:
      return { ...state };
  }
};

export const actionGetSchedules = (
  limit,
  pageNumber,
  isHistory,
  fromDate,
  toDate,
  assignTo,
  assetId
) => {
  return async dispatch => {
    try {
      const result = await apiGetSchedules(
        limit,
        pageNumber,
        isHistory,
        fromDate,
        toDate,
        assignTo,
        assetId
      );
      dispatch({ type: SET_SCHEDULES, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_SCHEDULES, payload: e });
    }
  };
};

export const actionUpdateSchedule = data => {
  return async dispatch => {
    try {
      const result = await apiUpdateSchedule(data);
      dispatch({ type: UPDATE_SCHEDULE, payload: result.data });
    } catch (e) {
      dispatch({ type: UPDATE_SCHEDULE, payload: e });
    }
  };
};

export const actionGetAssets = () => {
  return async dispatch => {
    try {
      const result = await apiGetAssets();
      dispatch({ type: SET_ASSET, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_ASSET, payload: e });
    }
  };
};

export const toggleHistory = flag => {
  return dispatch => {
    dispatch({ type: IS_HISTORY_SCHEDULE, payload: flag });
  };
};

export const actionGetScheduleCount = (fromDate, toDate, assignTo, assetId) => {
  return async dispatch => {
    try {
      const result = await apiGetScheduleCounts(
        fromDate,
        toDate,
        assignTo,
        assetId
      );
      dispatch({ type: SET_COUNTS, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_COUNTS, payload: e });
    }
  };
};
