import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as authReducer from "../../containers/Auth/auth.reducer";
import { Grid, Divider, Icon, Input, Button, Popup } from "semantic-ui-react";
import AddressDetails from "../AddressDetails";
import Loader from "../Loader";
import CreditInformation from "../CreditInformation";
import { showSuccessToast } from "../../utils/toastUtils";
import "./accountDetails.css";

class AccountDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      editMargin: false,
      pricePercent: 0,
    };
  }

  componentWillMount = async () => {
    // await this.props.actionGetAccountDetails();
    const { priceMargin } = this.props.auth.accountInfo;

    if (priceMargin) {
      this.setState({ pricePercent: priceMargin });
    }
  };

  cancelEdit = () => {
    this.props.actionSetEditAccountFlag(false);
  };

  updateState = (data) => {
    this.setState(data);
  };

  cancelEdit = () => {
    this.unSetForm();
    this.props.actionSetEditProfileFlag(false);
  };

  setForm = () => {
    const { user } = this.props.auth;
    const formData = this.getFormData(user);
    this.props.actionSetProfileInfoForm(formData);
  };

  unSetForm = () => {
    const data = this.getFormData();
    this.props.actionSetProfileInfoForm(data);
  };

  boaCreditHandler = (e) => {
    e.preventDefault();
    this.props.history.push("/profile/creditApplicationForm");
  };

  getDeliveryAddress = (deliveryAddress) => {
    if (window._.isEmpty(deliveryAddress)) {
      return null;
    }

    const addressComponent = deliveryAddress.map((value, key) => {
      let customClass = "";
      customClass = value.isPrimary
        ? "address-column primary"
        : "address-column";
      return (
        <Grid.Column
          width={4}
          computer={5}
          mobile={16}
          className={customClass}
          key={key}
        >
          <AddressDetails index={key} address={value} options={false} />
        </Grid.Column>
      );
    });

    return addressComponent;
  };

  editAddress = (e) => {
    e.preventDefault();
    this.props.history.push("/profile/deliveryAddress");
  };

  changePricePercent = (e, data) => {
    const { value } = data;
    this.setState({ pricePercent: value });
  };
  savePriceMargin = async () => {
    const { pricePercent } = this.state;
    const data_to_update = {
      priceMargin: pricePercent,
    };
    await this.props.actionUpdatePriceMargin(data_to_update);
    showSuccessToast("Updated Inventory sell price margin.");
    this.setState({ editMargin: false });
    this.props.actionGetProfileData();
    this.componentWillMount();
  };
  editMarginPrice = (e) => {
    e.preventDefault();
    this.setState({ editMargin: true });
  };

  render() {
    const {
      name,
      creditLimit,
      loading = true,
      cafStatus,
      cafSubmitted,
      priceMargin,
      company,
      street,
      street2,
      state,
      suburb,
      zip,
      country,
      city = "",
      contactsAndAddresses,
      parent,
      defaultContactAddressId,
    } = this.props.auth.accountInfo;
    const { pricePercent, editMargin } = this.state;

    const postalAddress = {
      placeName: street,
      street: street2,
      state: state && state.name,
      suburb: suburb,
      postalCode: zip,
      country: country && country.name,
      city: city,
    };

    let deliveryAddress = [];
    if (contactsAndAddresses) {
      contactsAndAddresses.map((item) => {
        deliveryAddress.push({
          deliveryAddressId: item.id,
          name: item.name,
          placeName: item.placeName,
          street: item.street,
          street2: item.street2,
          state: item.state && item.state.name,
          suburb: item.suburb,
          postalCode: item.zip,
          country: item.country && item.country.name,
          city: item.city,
          isPrimary: item.id === defaultContactAddressId,
          salesforceId: null,
        });
      });
    }

    const { customerType, account = {} } = this.props.user;
    const { showFacilities } = account;

    // const show = customerType === "enterprise" ? true : false;
    let show =
      customerType === "enterprise" || customerType === "retail" ? true : false;

    if (typeof customerType === "undefined") show = true;

    const deliverAddressComponent = this.getDeliveryAddress(deliveryAddress);

    const companyName = (
      <span>
        <label className="account-field-key">Company name</label>
        <p>{parent && parent.name}</p>
      </span>
    );

    const billingAddress = (
      <span>
        <label className="account-field-key">Billing address</label>
        <AddressDetails address={postalAddress} />
      </span>
    );
    const creditPopup = <CreditInformation />;

    const BOACredit = (
      <span>
        <label className="account-field-key">
          <span>
            {" "}
            Credit limit {!creditLimit && !cafSubmitted && creditPopup}{" "}
          </span>
        </label>
        {creditLimit !== 0 && <div>{creditLimit}</div>}
        {!creditLimit && !cafSubmitted && (
          <div>
            <a className="apply-boa-credit" onClick={this.boaCreditHandler}>
              <Icon name="wordpress forms" />
              Apply for BOA Credit
            </a>
          </div>
        )}
        {cafSubmitted && !cafStatus && (
          <div className="boa-credit-status">
            <Icon loading name="sync " />
            Credit application Status is pending
          </div>
        )}
      </span>
    );

    const deliveryAddressDetail = (
      <div>
        <label className="account-field-key">Delivery address </label>
        <a onClick={this.editAddress} className="linkToPage">
          Add / Delete
        </a>
      </div>
    );

    const manageMarginPrice = (
      <div>
        {editMargin ? (
          <div>
            <Input
              type="number"
              size="small"
              min={0}
              placeholder="Quantity"
              onChange={this.changePricePercent}
              value={pricePercent}
            />
            <Button
              icon="save"
              size="small"
              basic
              onClick={this.savePriceMargin}
              style={{
                backgroundColor: "#66cc23",
                color: "#fff",
              }}
            />
            <Button
              icon="delete"
              size="small"
              basic
              onClick={() => this.setState({ editMargin: false })}
              color="red"
            />
          </div>
        ) : (
          <div>
            {priceMargin ? (
              <p>{priceMargin} %</p>
            ) : (
              <em> Margin not configured yet </em>
            )}
          </div>
        )}
      </div>
    );

    return (
      <div className="account-details">
        <h3>Account information</h3>
        <Divider />
        {loading && <Loader text="Please wait" />}
        <Grid>
          {show && (
            <React.Fragment>
              <Grid.Column width={8} computer={8} mobile={16}>
                {companyName}
              </Grid.Column>

              <Grid.Column width={8} computer={8} mobile={16}>
                {billingAddress}
              </Grid.Column>

              {/* <Grid.Column width={8} computer={8} mobile={16}>
                {BOACredit}
              </Grid.Column>
              <Grid.Column width={8} computer={8} mobile={16}>
                {showFacilities ? (
                  <div>
                    <label className="account-field-key">
                      Inventory price margin
                    </label>
                    <Popup
                      trigger={<Icon name="question" color="blue" />}
                      content="Inventory price margin is the margin you would like to make on top of your cost when on-selling inventory"
                      basic
                    />
                    <a onClick={this.editMarginPrice} className="linkToPage">
                      Change
                    </a>
                    {manageMarginPrice}
                  </div>
                ) : null}
              </Grid.Column> */}
            </React.Fragment>
          )}
          <Grid.Column width={16} computer={8} mobile={16}>
            {deliveryAddressDetail}
          </Grid.Column>
        </Grid>
        <Grid columns="equal" className="address-grid">
          {deliverAddressComponent}
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    forms: state.forms,
    user: state.auth.user,
    editAccountFlag: state.auth.editAccountFlag,
  };
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(authReducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
);
