import React from "react";
import PropTypes from "prop-types";
import AuditDetails from "./AuditDetail";
import { Message, List } from "semantic-ui-react";

const AuditList = ({ audits = [] }) => {
  return (
    <React.Fragment>
      <List celled>
        {audits.length > 0 ? (
          audits.map((audit, key) => {
            return (
              <List.Item  key={key}>
                <AuditDetails audit={audit} />
              </List.Item>
            );
          })
        ) : (
          <Message
            info
            header="Sorry, No Records found!"
            content={
              <div>
                {/* <span className="cart-go-search-link" onClick={goToAddCrew}>
                Click here to add new crew.
              </span> */}
              </div>
            }
          />
        )}
      </List>
    </React.Fragment>
  );
};

AuditList.propTypes = {
  audits: PropTypes.array
};

export default AuditList;
