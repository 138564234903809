import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import { Grid, Image } from "semantic-ui-react";

const UploadImage = ({ selectedFile, onSelectFile }) => {
  return (
    <Grid>
      <Grid.Column width={8} mobile={16} computer={8} textAlign="center">
        <Dropzone
          accept="image/jpeg, image/png"
          multiple={false}
          // maxSize={5000000}
          onDrop={onSelectFile}
        >
          <div className="dropzone">
            <p>Drop image here / Click here to upload image</p>
          </div>
        </Dropzone>
      </Grid.Column>
      <Grid.Column width={8} mobile={16} computer={8}>
        {selectedFile ? (
          <Image src={selectedFile.preview} size="small" />
        ) : null}
      </Grid.Column>
    </Grid>
  );
};

export default UploadImage;

UploadImage.propTypes = {
  onSelectFile: PropTypes.func,
  selectedFile: PropTypes.object
};
