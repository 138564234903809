import _ from "lodash";
// import "./assetList.css";
import React from "react";
import PropTypes from "prop-types";

// import { Message, List, Icon } from "semantic-ui-react";

const PickUpAddress = () => {
  return (
    <React.Fragment>
      <p>hi</p>
    </React.Fragment>
  );
};

export default PickUpAddress;
