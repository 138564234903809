// HoseAssembly.js
import React, { useState } from "react";
import DetailedAssembly from "./detailAssembly";
import "./hoseAssembly.css";
import { toast } from "react-toastify";
import { HISfetchData } from "./hisAPIprovider";

const HoseAssembly = ({ hose_assembly }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [details, setDetails] = useState(null);

  const handleButtonClick = async () => {
    // Replace this URL with the actual API endpoint
    const response = await HISfetchData("/his/assembly_details", {
      assembly_id: hose_assembly.assembly_id,
    });
    if (!response.ok) {
      toast.error("Failed to get the assembly details");
      return;
    }
    const data = await response.json();
    setDetails(data.data);
    setShowPopup(true);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <div className="assembly_part">
      <div className="data_row">
        <p>Assembly Part No:</p> <p>{hose_assembly.assembly_id}</p>
      </div>
      <div className="data_row">
        <p>Bulk Part No:</p> <p>{hose_assembly.bulk_part_no}</p>
      </div>
      <div className="data_row">
        <p>Hose Type:</p> <p>{hose_assembly.hose_type}</p>
      </div>
      <div className="data_row">
        <p>Hose Inside Diameter:</p> <p>{hose_assembly.hose_inside_diam} MM</p>
      </div>
      <div className="data_row">
        <p>Hose Cut Length:</p> <p>{hose_assembly.hose_cut_length} MM</p>
      </div>
      <div className="data_row">
        <p>Qty:</p> <p>{hose_assembly.qty}</p>
      </div>
      <button className="detail_btn" onClick={handleButtonClick}>
        View Assembly Details
      </button>

      {showPopup && details && (
        <DetailedAssembly
          title="Assembly Details"
          details={details}
          onClose={handleClosePopup}
        />
      )}
    </div>
  );
};

export default HoseAssembly;
