export const crew = {
  _id: "",
  name: "",
  members: []
};

export const member = {
  value: "",
  isPortalUser: true
};
