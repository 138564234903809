import axios from "axios";
import { INVENTORY_LOCATION, ALL } from "../../constants/apiLink";

export const apiGetLocations = async () => {
  const url = INVENTORY_LOCATION;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiAddLocation = async data => {
  const url = INVENTORY_LOCATION;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiDeleteLocation = async name => {
  const url = INVENTORY_LOCATION + "/" + name;

  return axios.delete(url).then(result => {
    return result;
  });
};

export const apiGetLocationsToDropdown = async () => {
  const url = INVENTORY_LOCATION + ALL;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiUpdateLocation = async (_id, data) => {
  const url = INVENTORY_LOCATION + "/" + _id;
  return axios.put(url, data).then(result => {
    return result;
  });
};
