import React from "react";
import DatePicker from "react-datepicker";
//import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "react-datepicker/dist/react-datepicker.css";

const Datepicker = ({ givenDate, minDate = null, maxDate = null, setDate }) => {
  const handleChange = date => {
    setDate(date);
  };

  return (
    <DatePicker
      placeholderText="Click to select a date"
      selected={givenDate}
      onChange={handleChange}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};
export default Datepicker;
