import React from "react";
import { Menu, Button } from "semantic-ui-react";
import polyglot from "../../i18n";
import "../../index.css";

class TopMenu extends React.Component {
  state = { activeItem: "", countryCode: "" };

  componentDidMount = () => {
    const currentCountryCode = localStorage.getItem("countryCode");
    this.setState({ countryCode: currentCountryCode });
  };

  handleItemClick = (e, { name }) => this.setState({ activeItem: name });

  render() {
    const { countryCode } = this.state;

    return (
      <header className="main-menu-public">
        <Menu inverted className="profile-menu">
          <Menu.Menu
            position="right"
            className="user-menu-public"
            style={{ position: "absolute", right: 0, top: -40 }}
          >
            <Menu.Item as="span" className="top-menu">
              EXISTING BOAhub USER?
            </Menu.Item>
            <Menu.Item>
              <Button
                content="SIGN IN HERE"
                style={{
                  borderRadius: 30,
                  backgroundColor: "#66cc23",
                  color: "#fff",
                }}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </header>
    );
  }
}

export default TopMenu;
