import React from "react";
import { Grid, Segment } from "semantic-ui-react";
import PropTypes from "prop-types";
import _ from "lodash";

const FittingInfo = ({
  fittingName,
  fittingInfo = {},
  type = "",
  removeFitting,
  angle
}) => {
  const { stockCode = "" } = fittingInfo;

  if (_.isEmpty(fittingInfo)) {
    return "";
  }

  return (
    <React.Fragment>
      <Segment className="fitter-info">
        <Grid columns="equal">
          <Grid.Column>{fittingName}</Grid.Column>
          <Grid.Column>{stockCode} </Grid.Column>
          <Grid.Column>Angle: {angle} Degree</Grid.Column>
          <Grid.Column>
            <div className="remove-fitter" onClick={e => removeFitting(type)}>
              x remove
            </div>
          </Grid.Column>
        </Grid>
      </Segment>
    </React.Fragment>
  );
};

FittingInfo.propTypes = {
  fittingName: PropTypes.string,
  fittingInfo: PropTypes.object,
  type: PropTypes.string,
  removeFitting: PropTypes.func,
  angle: PropTypes.number
};

export default FittingInfo;
