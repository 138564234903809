import React from "react";
import { Grid, Image, Card, Label } from "semantic-ui-react";
import productDefaultImage from "../../assets/images/product_default.png";
import placeHolderImage from "../../assets/images/placeholderImage.png";
import _ from "lodash";
import Loader from "../../components/Loader";

const SearchProductPlaceholder = () => {
  const narrowFilters = ["Loading_1", "Loading_2", "Loading_3"];
  const searchResults = [
    { stockDescription: "", stockCode: "" },
    { stockDescription: "", stockCode: "" },
    { stockDescription: "", stockCode: "" },
    { stockDescription: "", stockCode: "" },
    { stockDescription: "", stockCode: "" }
  ];
  return (
    <React.Fragment>
      <Grid className="search-placeHolder">
        <Grid.Column>
          <div className="filter-box">
            <h4> Narrow your search</h4>
            <Grid columns="equal">
              {narrowFilters.map((filter, key) => {
                return (
                  <Grid.Column
                    width={5}
                    computer={5}
                    mobile={16}
                    key={filter}
                    className="filter-content"
                  >
                    <Loader title="Loading, please wait">Loading</Loader>
                    <Grid>
                      <Grid.Column width={5} className="filter-content-image">
                        <Image src={productDefaultImage} />
                      </Grid.Column>
                      <Grid.Column width={11}>
                        <Image alt="placeholder" src={placeHolderImage} />
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                );
              })}
            </Grid>
          </div>
          {_.map(searchResults, (item, key) => {
            return (
              <Card
                link
                style={{ width: "100%" }}
                key={key}
                className="product-card"
              >
                <Card.Content textAlign="left">
                  <Card.Header>
                    <Label color="green" ribbon="right">
                      {/* <Icon loading name="spinner" /> */}
                    </Label>
                    <p className="product-header">
                      {/* <Icon loading name="spinner" /> */}
                    </p>
                  </Card.Header>
                  <Card.Description className="card-description">
                    <Grid>
                      <Loader title="Loading, please wait">Loading</Loader>
                      <Grid.Column width={4}>
                        <Image src={productDefaultImage} />
                      </Grid.Column>
                      <Grid.Column width={12}>
                        <Image alt="placeholder" src={placeHolderImage} />
                      </Grid.Column>
                    </Grid>
                  </Card.Description>
                </Card.Content>
              </Card>
            );
          })}
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};

export default SearchProductPlaceholder;
