import React, { useState } from "react";
import "./FeedbackForm.css";
import { Button, Form, TextArea, Modal } from "semantic-ui-react";
import { showErrorToast, showSuccessToast } from "../../utils/toastUtils";
import { apiSendFeedback } from "./SendFeedback.api";

const FeedbackForm = ({ closeForm }) => {
  const [formData, setFormData] = useState({ feedback: "" });
  const [isEmpty, setIsEmpty] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    if (value.trim() !== "") {
      setIsEmpty(false);
    }
  };

  const onSubmitFeedback = async () => {
    if (formData.feedback.trim() === "") {
      setIsEmpty(true);
    } else {
      try {
        const result = await apiSendFeedback(formData);
        console.log(result.data);
        closeForm();
        showSuccessToast("We've received your feedback. Thank you!");
      } catch (error) {
        closeForm();
        showErrorToast("Failed to send feedback. Please try again.");
      }
    }
  };

  return (
    <Modal size="small" open={true} onClose={closeForm}>
      <Modal.Header>SEND YOUR FEEDBACK</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field error={isEmpty}>
            <TextArea
              name="feedback"
              placeholder="Your feedback here..."
              onChange={handleChange}
              rows={6}
              style={{ borderColor: isEmpty ? "red" : undefined }}
            />
          </Form.Field>

          <div className="button-row">
            <Button
              style={{
                backgroundColor: "#FF1414",
                color: "#fff",
                flex: 1,
                marginRight: "10px",
              }}
              onClick={closeForm}
            >
              CLOSE
            </Button>
            <Button
              style={{
                backgroundColor: "rgb(102, 204, 35)",
                color: "#fff",
                flex: 1,
              }}
              onClick={onSubmitFeedback}
            >
              SEND
            </Button>
          </div>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export default FeedbackForm;
