import { actions } from "react-redux-form";
import { apiSubmitCafForm } from "./caf.api";

export const CAF_SUBMIT_RESPONSE = "CAF_SUBMIT_RESPONSE";

const initialState = {
  isLoading: false,
  response: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAF_SUBMIT_RESPONSE:
      return {
        ...state,
        response: action.payload
      };
    default:
      return state;
  }
};

export const actionSetCafFormInfo = form => {
  return dispatch => {
    dispatch(actions.change("forms.cafForm", form));
  };
};

export const actionSetCafFormValue = data => {
  return dispatch => {
    dispatch(actions.change("forms.cafForm", data));
  };
};

export const actionSetAddressCafFormValue = value =>{
  return dispatch => {
    dispatch(actions.change("forms.cafForm.physicalAddress",value))
  }
}


export const actionSubmitCafForm = (data) => {
  return async dispatch => {
    try {
      const result = await apiSubmitCafForm(data);
      dispatch({ type: CAF_SUBMIT_RESPONSE, payload: result });
    } catch (e) {
      dispatch({ type: CAF_SUBMIT_RESPONSE, payload: e });
    }
  }
}