import axios from "axios";
import {
  INVENTORY,
  TRANSFER_LOCATION,
  SELL_STOCK,
  CONSUME_STOCK,
  ASSIGN_STOCK,
  SEARCH,
  TYPE_LIST
} from "../../constants/apiLink";

export const apiGetInventories = async (
  limit,
  pageNumber,
  stockId,
  type,
  location,
  searchText
) => {
  let url = INVENTORY;
  if (limit) {
    url = url + "?limit=" + limit;
  }
  if (pageNumber) {
    url = url + "&pageNumber=" + pageNumber;
  }

  if (stockId) {
    url = url + "&stockId=" + stockId;
  } else if (searchText) {
    url = url + "&searchText=" + searchText;
  }

  if (type) {
    url = url + "&type=" + type;
  }

  if (location) {
    url = url + "&location=" + location;
  }

  return axios.get(url).then(result => {
    return result;
  });
};

export const apiAddInventory = async data => {
  const url = INVENTORY;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiUpdateInventory = async id => {
  const url = INVENTORY + "id=" + id;

  return axios.delete(url).then(result => {
    return result;
  });
};

export const apiTransferLocation = async data => {
  const url = INVENTORY + TRANSFER_LOCATION;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiSellStock = async data => {
  const url = INVENTORY + SELL_STOCK;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiConsumeStock = async data => {
  const url = INVENTORY + CONSUME_STOCK;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiAttachStock = async data => {
  const url = INVENTORY + ASSIGN_STOCK;
  return axios.post(url, data).then(result => {
    return result;
  });
};

export const apiSearchStock = async text => {
  const url = INVENTORY + SEARCH + "?searchText=" + text;
  return axios.get(url).then(result => {
    return result;
  });
};

export const apiGetTypes = async text => {
  const url = INVENTORY + TYPE_LIST;
  return axios.get(url).then(result => {
    return result;
  });
};
