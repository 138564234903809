import axios from "axios";
import { padZero } from "../../utils/utils";
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

export const apiGetOrderHistoryList = (search, technician) => {
  const data = {
    textSearch: search,
    technicianEmail: technician === "All" ? "" : technician,
  };
  return axios
    .get(`/api/SalesOrder/order-history-odoo`, { params: data })
    .then((result) => {
      if (result) {
        if (result.data) {
          result.data.map((data) => {
            if (data.dateOrder !== null) {
              var dateObject = new Date(data.dateOrder);

              // Format the date
              var formattedDate = `${padZero(dateObject.getDate())}/${padZero(
                dateObject.getMonth() + 1
              )}/${dateObject.getFullYear().toString().slice(2)}`;
              var hours = dateObject.getHours() % 12 || 12; // Convert to 12-hour format
              var formattedTime = `${padZero(hours)}:${padZero(
                dateObject.getMinutes()
              )} ${dateObject.getHours() < 12 ? "AM" : "PM"}`;
              // Combine date and time
              data.formattedDate = `${formattedDate}, ${formattedTime}`;
            }
          });
          return result.data;
        }
      }
      return false;
    });
};

export const apiGetOrderDetails = (id) => {
  const data = {
    orderId: id,
  };
  return axios
    .get(`/api/SalesOrder/order-details-odoo`, { params: data })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiGetStockProducts = (id, textSearch = "", pageNumber = 1) => {
  const data = {
    stockLocationId: id,
    PageNo: pageNumber,
    SearchParam: textSearch,
    Limit: 10,
  };
  return axios
    .get(`/api/InventoryManagement/get-products`, { params: data })
    .then((result) => {
      if (result && result.data) {
        const data = result.data;
        data.records = JSON.parse(data.records);
        console.log(data.records);
        data.records.map((item) => {
          item.quantity = 1;
        });
        return data;
      }
      return false;
    });
};

export const apiGetProductById = (id) => {
  return axios.get(`/api/Products/${id}`).then((result) => {
    if (result) {
      if (result.data) {
        const item = result.data;
        const data = {
          id: item.id,
          pack: item.uom && item.uom.name,
          stockCode: item.barcode,
          stockDescription: item.name,
          pricingPolicy: {
            price: item.listPrice,
            currencyId: item.currency.id,
          },
          productUom: item.uom.id,
          taxId: item.taxId,
          name: item.name,
          barcode: item.barcode,
          image128: item.image128,
          uom: item.uom,
        };
        return data;
      }
    }
    return false;
  });
};

export const apiGetStockTransfers = (status) => {
  const data = {
    status: status,
    timeZone: timeZone,
  };
  return axios
    .get(`/api/InventoryManagement/stock-transfers`, { params: data })
    .then((result) => {
      if (result && result.data) {
        const data = result.data;
        data.map((item) => {
          item.fromStockLocationId = item.moveFrom;
          item.toStockLocationId = item.moveTo;
          item.transferReason = item.moveReason;
        });
        return data;
      }
      return false;
    });
};

export const apiGetStockTransferDetails = (id) => {
  const data = {
    stockMoveId: id,
  };
  return axios
    .get(`/api/InventoryManagement/stock-transfer-details`, { params: data })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};

export const apiCreateStockTransfer = (formData, products, isComplete) => {
  let data = formData;
  if (isComplete) data.isCompleted = true;
  if (data.isCompleted === null) data.isCompleted = false;
  data.transferDetails = products;
  data.transferDetails.map((item) => {
    item.productId = item.productId;
    item.qty = item.moveQty;
  });
  return axios
    .post("/api/InventoryManagement/stock-transfer", data)
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiUpdateStockTransfer = (formData, products, isComplete) => {
  let data = formData;
  // Validate formData for payload
  if (isComplete) data.isCompleted = true;
  if (data.isCompleted === null) data.isCompleted = false;
  let productList = [];
  products.map((item) => {
    let product = {};
    product.productId = item.productId;
    product.qty = item.moveQty;
    productList.push(product);
  });
  const payload = {
    fromStockLocationId: data.fromStockLocationId,
    toStockLocationId: data.toStockLocationId,
    transferReason: data.transferReason,
    isCompleted: data.isCompleted,
    transferDetails: productList,
  };

  return axios
    .put(
      `/api/InventoryManagement/update-stock-transfer?transferId=${formData.id}`,
      payload
    )
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500 || data.statusCode === 403) return false;
        return error.response.data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiDeleteStockTransfer = async (data) => {
  const payload = {
    transferId: data.id,
  };
  return axios
    .delete(`/api/InventoryManagement/delete-stock-transfer`, {
      params: payload,
    })
    .then((result) => {
      if (result && result.status) {
        if (result.status === 200 || result.status === 204) return true;
      }
      return false;
    })
    .catch((error) => {
      // Handle error response
      if (error.response.data) {
        const data = error.response.data;
        if (data.statusCode === 500) return false;
        return data;
      } else {
        console.log("Error:", error.message);
      }
      return false;
    });
};

export const apiGetAllStockLocationList = () => {
  return axios.get("/api/boapod/all-stock-locations").then((result) => {
    if (result) {
      if (result.data) {
        result.data.stockLocations.sort((a, b) =>
          a.stockLocationName.localeCompare(b.stockLocationName)
        );
        result.data.stockLocations.map((item) => {
          item.text = item.stockLocationName;
          item.value = item.id;
        });
        return result.data.stockLocations;
      }
    }
    return false;
  });
};

export const apiGetStockLocationReport = (id) => {
  const data = {
    stockLocationId: id,
  };
  return axios
    .get(`/api/InventoryManagement/stock-location-report`, {
      params: data,
    })
    .then((result) => {
      if (result && result.data) {
        return result.data;
      }
      return false;
    });
};
