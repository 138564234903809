import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import * as hoseMakerReducer from "../../hoseMaker.reducer";
import {
  Grid,
  Search,
  Button,
  Message,
  Pagination,
  Card,
  Label
} from "semantic-ui-react";
import _ from "lodash";
import ResultRenderer from "../SelectFitterByFilters/ResultRenderer";
import { showSuccessToast, showErrorToast } from "../../../../utils/toastUtils";
import HoseDescription from "../HoseSearch/HoseDescription";
import AdditionalProductNarrowSearch from "./AdditionalProductSearchNarrowResult";
import * as searchUtils from "../../hoseMaker.util";
import FittingsFilter from "../../../Search/FittingsFilter";
import Filter from "../../../Search/Filter";
const FILTER_PARAM_FILTER = "filter";
const FILTER_PARAM_VALUE = "value";
class AdditionalProductSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      level: null,
      value: "",
      filter: null,
      searchMessage: "",
      activePage: 1,
      dataSource: null
    };
  }

  componentDidMount() {
    this.props.actionResetSearchResult();
    this.props.actionSetThreadType1(undefined);
    this.props.actionSetThreadSize1(undefined);
    this.props.actionSetThreadType2(undefined);
    this.props.actionSetThreadSize2(undefined);
    this.emptySearch();
  }

  emptySearch = async () => {
    const { categoryValue } = this.props;
    this.setState({ isLoading: true });
    await this.props.actionSearchProducts(" ", categoryValue, 1);
    this.setState({ isLoading: false });
  };

  addParamsToUrl = ({ value, filter, activePage }) => {
    value = !value ? "" : value;
    filter = !filter ? "" : _.replace(filter, "&", "$and$");
    this.props.history.push({
      pathname: "/additionalProductSearch",
      search: `?${FILTER_PARAM_FILTER}=${filter}&${FILTER_PARAM_VALUE}=${value}`
    });
  };

  callSearchApi = async (value, filter, activePage, pushToHistory = true) => {
    if (!filter) {
      filter = this.props.categoryValue;
    }
    // if (pushToHistory) {
    //   this.addParamsToUrl({ value, filter, activePage });
    // }

    this.setState({ isLoading: true, activePage });
    const { threadType1, threadSize1, threadType2, threadSize2 } = this.props;
    const result = await this.props.actionSearchProducts(
      value,
      filter,
      activePage,
      threadType1,
      threadSize1,
      threadType2,
      threadSize2
    );
    const { data } = result;
    const { secondaryGrpDisplayName, tertiaryGrpDisplayName } = data;
    const module = tertiaryGrpDisplayName
      ? tertiaryGrpDisplayName
      : secondaryGrpDisplayName;

    // const active = {
    //   module
    // };
    // this.computeOpenFilterItems(secondaryGrpDisplayName, active);
    this.setState({
      isLoading: false
    });
  };

  computeOpenFilterItems = (secondaryGrp, active) => {
    let { dataSource } = this.state;

    for (let index = 0; index < dataSource.children.length; index++) {
      const element = dataSource.children[index];
      if (element.module === secondaryGrp) {
        element.collapsed = false;
      } else {
        element.collapsed = true;
      }
    }

    this.setState({
      dataSource,
      active
    });
  };

  handleSearchChange = async (e, { value }) => {
    e.preventDefault();
    this.setState({ isLoading: true, value, searchMessage: null });
    const { categoryValue } = this.props;
    await this.props.actionAdditionalProductAutoComplete(value, categoryValue);
    this.setState({ isLoading: false });
  };

  handleSearchButtonClick = async e => {
    e.preventDefault();
    const { value } = this.state;
    this.setState({ isLoading: true });

    if (_.isEmpty(value)) {
      this.setState({
        searchMessage: "Please enter product details to search",
        isLoading: false
      });

      return;
    }

    this.onSubmit(e);
    this.setState({
      searchMessage: null,
      isLoading: false
    });
  };

  onSubmit = e => {
    if (e) {
      e.preventDefault();
    }
    const { value } = this.state;
    const { categoryValue } = this.props;
    this.callSearchApi(value, categoryValue, 1);
  };
  handleResultSelect = (e, { result }) => {
    const value = result.stockCode;
    this.setState({ value });
    const { categoryValue } = this.props;
    this.callSearchApi(value, categoryValue, 1);
  };

  clearFilters = e => {
    e.preventDefault();
    this.setState({
      activePage: 1,
      totalPages: 1,
      value: "",
      filter: ""
    });
    this.props.actionSetThreadType1(undefined);
    this.props.actionSetThreadSize1(undefined);
    this.props.actionSetThreadType2(undefined);
    this.props.actionSetThreadSize2(undefined);
    this.props.actionResetSearchResult();

    this.emptySearch();
    return;
  };

  onNarrowFilterClickHandler = value => {
    // value = !value ? "" : _.replace(value, "&", "$and$");
    this.setState({ filter: value });
    this.callSearchApi("", value, 1);
  };

  handlePaginationChange = (e, params) => {
    e.preventDefault();
    const { searchResult } = this.props;
    const { totalPages } = searchResult;
    if (totalPages === 1) {
      return;
    }
    const options = params;
    const { activePage } = options;
    const { value, filter } = this.state;
    this.setState({
      activePage
    });
    this.callSearchApi(value, filter, activePage);
  };

  selectProductHandler = (e, data) => {
    const { pricingPolicy } = data;
    const { price } = pricingPolicy;
    if (!price) {
      showErrorToast(
        "Not able to add this product to the Hose, please contact BOA for this product",
        5000
      );
    } else {
      this.props.actionAddAdditionalProducts(data);
      const { productFamilyDisplayName = "Product" } = data;
      showSuccessToast(`${productFamilyDisplayName} added to the hose`);
    }
  };

  threadType1Handler = async data => {
    const { value } = data;
    const { filter } = this.state;
    await this.props.actionSetThreadType1(value);
    this.callSearchApi(this.state.value, filter, 1);
  };

  threadSize1Handler = async data => {
    const { value } = data;
    const { filter } = this.state;
    await this.props.actionSetThreadSize1(value);
    this.callSearchApi(this.state.value, filter, 1);
  };

  threadType2Handler = async data => {
    const { value } = data;
    const { filter } = this.state;
    await this.props.actionSetThreadType2(value);
    this.callSearchApi(this.state.value, filter, 1);
  };

  threadSize2Handler = async data => {
    const { filter } = this.state;
    const { value } = data;
    await this.props.actionSetThreadSize2(value);
    this.callSearchApi(this.state.value, filter, 1);
  };

  clearThreadFilterHandler = async () => {
    await this.props.actionSetThreadType1(undefined);
    await this.props.actionSetThreadSize1(undefined);
    await this.props.actionSetThreadType2(undefined);
    await this.props.actionSetThreadSize2(undefined);

    const { filter } = this.state;
    this.callSearchApi(this.state.value, filter, 1);
  };

  render() {
    const {
      autoCompleteResult,
      threadType1,
      threadSize1,
      threadType2,
      threadSize2
    } = this.props;
    const { isLoading, searchMessage, activePage, value, filter } = this.state;
    const {
      result = [],
      totalPages = 1,
      narrowFilters = [],
      secondaryGrpDisplayName = undefined,
      tertiaryGrpDisplayName = undefined,
      productFamilyDisplayName = undefined,
      threadFilters = {}
    } = this.props.searchResult;

    const {
      physicalThreadSize1,
      threadTypeFilter1,
      threadTypeFilter2,
      physicalThreadSize2
    } = threadFilters;
    const searchResults = result;
    const searchJSX = (
      <div>
        <form onSubmit={this.onSubmit}>
          <Grid>
            <Grid.Column tablet={12} computer={12} mobile={16}>
              <Search
                className="search-box"
                loading={isLoading}
                placeholder="Search by code or description"
                resultRenderer={ResultRenderer}
                onResultSelect={this.handleResultSelect}
                onSearchChange={_.debounce(this.handleSearchChange, 500, {
                  leading: true
                })}
                noResultsMessage="No direct matches found."
                results={autoCompleteResult}
                value={value}
                {...this.props}
              />
            </Grid.Column>
            <Grid.Column tablet={8} computer={2} mobile={8}>
              {isLoading ? (
                <Button loading>Search</Button>
              ) : (
                <Button
                  color="green"
                  onClick={_.debounce(this.handleSearchButtonClick, 500, {
                    leading: true
                  })}
                >
                  Search
                </Button>
              )}
            </Grid.Column>

            <Grid.Column tablet={8} computer={2} mobile={8}>
              <Button type="button" onClick={e => this.clearFilters(e)}>
                Clear
              </Button>
            </Grid.Column>
          </Grid>
        </form>
        {searchMessage && (
          <Message info>
            <Message.Header>{searchMessage}</Message.Header>
          </Message>
        )}
      </div>
    );

    const resultJSX = (
      <div>
        {!isLoading &&
          searchResults.length !== 0 &&
          _.map(searchResults, (item, key) => {
            const { stockDescription, stockCode, price, currency } = item;
            return (
              <Card
                link
                style={{ width: "100%" }}
                key={stockCode + key}
                className="product-card"
              >
                <Card.Content textAlign="left">
                  <Card.Header>
                    <Label color="green" ribbon="right">
                      {currency || "NZD "} {searchUtils.precise_round(price, 2)}
                    </Label>
                    <p className="product-header">
                      <span className="product-header-stock">{stockCode} </span>{" "}
                      {stockDescription}
                    </p>
                  </Card.Header>
                  <Card.Description className="card-description">
                    <HoseDescription
                      hose={item}
                      addToHose={this.selectProductHandler}
                    />
                  </Card.Description>
                </Card.Content>
              </Card>
            );
          })}

        {searchResults.length ? (
          <Pagination
            activePage={activePage}
            boundaryRange={1}
            onPageChange={(e, data) => this.handlePaginationChange(e, data)}
            size="mini"
            totalPages={totalPages}
            ellipsisItem={true}
            firstItem={null}
            lastItem={null}
          />
        ) : null}

        {searchResults.length === 0 && (filter || value) && (
          <Message info>
            <Message.Header>
              No Products found. Try widening your search criteria or get in
              touch with BOA for Assistance.
            </Message.Header>
          </Message>
        )}
      </div>
    );

    return (
      <React.Fragment>
        <Grid className="search-modal">
          <Grid.Column width={16}>
            {/* <AdditionalProductCategories onClickHandler={this.getCategory} /> */}
            {searchJSX}
          </Grid.Column>
          <Grid.Column width={16}>
            {
              <FittingsFilter
                category={secondaryGrpDisplayName}
                threadTypes1={threadTypeFilter1}
                threadTypes2={threadTypeFilter2}
                selectedThreadSize1={physicalThreadSize1}
                selectedThreadSize2={physicalThreadSize2}
                threadType1Handler={this.threadType1Handler}
                threadType2Handler={this.threadType2Handler}
                threadSize1Handler={this.threadSize1Handler}
                threadSize2Handler={this.threadSize2Handler}
                clearThreadFilterHandler={this.clearThreadFilterHandler}
                filtersApplied={{
                  threadType1,
                  threadSize1,
                  threadType2,
                  threadSize2
                }}
              />
            }
          </Grid.Column>
          <Grid.Column width={16}>
            {/* Show narrow down filter*/}
            {!isLoading && narrowFilters.length ? (
              <AdditionalProductNarrowSearch
                narrowFilters={narrowFilters}
                onClickHandler={this.onNarrowFilterClickHandler}
              />
            ) : null}
          </Grid.Column>
          <Grid.Column width={16}>{resultJSX}</Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  autoCompleteResult: state.hoseMaker.autoCompleteResult,
  searchResult: state.hoseMaker.searchResult,
  threadType1: state.hoseMaker.threadType1,
  threadType2: state.hoseMaker.threadType2,
  threadSize1: state.hoseMaker.threadSize1,
  threadSize2: state.hoseMaker.threadSize2
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(hoseMakerReducer, dispatch);

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AdditionalProductSearch)
);
