

import { apiGetCustomerHoses } from "./myHoses.api";

const SET_MY_HOSES = "GET_MY_HOSES";


const initialState = {
    hoses: {}
};


export default (state = initialState, action) => {

    switch (action.type) {
        case SET_MY_HOSES: {
            return {
                ...state,
                hoses: action.payload
            }
        }
        default: {
            return state
        }
    }
};



export const actionGetCustomerHoses = (activePage) => {
    return async dispatch => {
        try {
            const result = await apiGetCustomerHoses(activePage);
            dispatch({ type: SET_MY_HOSES, payload:result.data });
        } catch (e) {
            dispatch({ type: SET_MY_HOSES, payload: e });
        };
    };
};