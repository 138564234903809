import React from "react";
import { Grid } from "semantic-ui-react";
import Fitting from "./Fitting";
import PropTypes from "prop-types";

const FittingLeft = ({ fittingHandler, selectedTypes = {} }) => {
  this.fittingChangeHandler = (value, type) => {
    fittingHandler(value);
  };

  return (
    <div>
      <Grid columns="equal">
        <Grid.Column className="left-fitting-box">
          <Fitting
            key="1"
            fittingChangeHandler={this.fittingChangeHandler}
            side={"left"}
            type={"first"}
            fittingValue={selectedTypes}
          />
        </Grid.Column>
      </Grid>
    </div>
  );
};

FittingLeft.propType = {
  fittingHandler: PropTypes.func,
  selectedTypes: PropTypes.object
};

export default FittingLeft;
