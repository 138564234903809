import React from "react";
import { Loader } from "semantic-ui-react";
import PropTypes from "prop-types";
const UiLoader = ({ text = "Loading" }) => {
  return <Loader active className="workaround" content={text} />;
};

UiLoader.propTypes = {
  text: PropTypes.string,
};

export default UiLoader;
