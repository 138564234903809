import {
  apiGetConversationsList,
  apiCreateChatGroup,
  apiGetChatUserList,
  apiDeleteChat,
  apiGetConversationDetails,
  apiUpdateChat,
  apiGetChatUserDetails,
  apiAddParticipants,
  apiRemoveParticipants,
  apiUpdateParticipants,
  apiSearch,
  apiAutoComplete,
  apiExitChatgroup,
  apiAutoCompleteConversation,
  apiSearchConversation,
} from "./chat.api";
import { actions } from "react-redux-form";
import { showSuccessToast, showErrorToast } from "../../utils/toastUtils";

export const GET_CHAT_GROUP_LIST = "GET_CHAT_GROUP_LIST";
export const CREATE_CHAT_GROUP = "CREATE_CHAT_GROUP";
export const GET_CHAT_USER_LIST = "GET_CHAT_USER_LIST";
export const DELETE_CONVERSATION = "DELETE_CONVERSATION";
export const GET_CONVERSATION_DETAILS = "GET_CONVERSATION_DETAILS";
export const UPDATE_CONVERSATION = "UPDATE_CONVERSATION";
export const GET_CHAT_USER_DETAILS = "GET_CHAT_USER_DETAILS";
export const ADD_PARTICIPANTS = "ADD_PARTICIPANTS";
export const REMOVE_PARTICIPANTS = "REMOVE_PARTICIPANTS";
export const UPDATE_PARTICIPANTS = "UPDATE_PARTICIPANTS";
export const SET_SEARCH_RESULT = "SET_SEARCH_RESULT";
export const SET_AUTO_COMPLETE = "SET_AUTO_COMPLETE";
export const SET_SEARCH_CONVERSATION_RESULT = "SET_SEARCH_CONVERSATION_RESULT";
export const SET_AUTO_CONVERSATION_COMPLETE = "SET_AUTO_CONVERSATION_COMPLETE";
export const CLEAR_SEARCH = "CLEAR_SEARCH";
export const CLEAR_CONVERSATION_SEARCH = "CLEAR_CONVERSATION_SEARCH";
export const EXIT_CONVERSATION = "EXIT_CONVERSATION";

const initialState = {
  allGroups: [],
  response: {},
  chatuserList: [],
  conversationDetails: [],
  userDetails: [],
  autoCompleteResult: [],
  autoCompleteConversationResult: [],
  searchResult: {},
  searchConversationResult: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CHAT_GROUP_LIST: {
      return {
        ...state,
        allGroups: action.payload,
      };
    }
    case GET_CONVERSATION_DETAILS: {
      return {
        ...state,
        conversationDetails: action.payload,
      };
    }
    case GET_CHAT_USER_LIST: {
      return {
        ...state,
        chatuserList: action.payload,
      };
    }
    case GET_CHAT_USER_DETAILS: {
      return {
        ...state,
        userDetails: action.payload,
      };
    }
    case CREATE_CHAT_GROUP: {
      return {
        ...state,
        response: action.payload,
      };
    }
    // case ADD_PARTICIPANTS: {
    //     return{
    //         ...state,
    //         response:action.payload
    //     }
    // }
    // case REMOVE_PARTICIPANTS: {
    //     return{
    //         ...state,
    //         response:action.payload
    //     }
    // }
    case UPDATE_PARTICIPANTS: {
      return {
        ...state,
        response: action.payload,
      };
    }
    case EXIT_CONVERSATION: {
      return {
        ...state,
        response: action.payload,
      };
    }

    case SET_SEARCH_RESULT:
      return {
        ...state,
        searchResult: action.payload,
      };
    case SET_AUTO_COMPLETE: {
      return {
        ...state,
        autoCompleteResult: action.payload,
      };
    }
    case SET_SEARCH_CONVERSATION_RESULT:
      return {
        ...state,
        searchConversationResult: action.payload,
      };
    case SET_AUTO_CONVERSATION_COMPLETE: {
      return {
        ...state,
        autoCompleteConversationResult: action.payload,
      };
    }
    case CLEAR_CONVERSATION_SEARCH: {
      return {
        ...state,

        autoCompleteConversationResult: [],
        searchConversationResult: {},
      };
    }
    case CLEAR_SEARCH: {
      return {
        ...state,
        autoCompleteResult: [],
        searchResult: {},
      };
    }

    default:
      return state;
  }
};

export const actionResetForm = (name) => {
  return (dispatch) => {
    dispatch(actions.reset("forms." + name));
  };
};

// export const actionSetFormField = (name, field) => {
//   return dispatch => {
//     dispatch(actions.change("forms.assetForm." + name, field));
//   };
// };

export const actionSetFormField = (name, field) => {
  return (dispatch) => {
    dispatch(actions.change("forms.chatForm." + name, field));
  };
};

export const actionSetFormValue = (data) => {
  return (dispatch) => {
    dispatch(actions.change("forms.chatForm", data));
  };
};

export const actionOmitFormField = (field) => {
  return (dispatch) => {
    dispatch(actions.omit("forms.scheduleForm", field));
  };
};

//   export const actionSetAttachToAssetField = (name, field) => {
//     return dispatch => {
//       dispatch(actions.change("forms.attachToAssetForm." + name, field));
//     };
//   };

export const actionGetConversationsList = () => {
  return async (dispatch) => {
    try {
      let result = await apiGetConversationsList();
      const { data } = result;
      dispatch({ type: GET_CHAT_GROUP_LIST, payload: data });
    } catch (e) {
      dispatch({ type: GET_CHAT_GROUP_LIST, payload: e });
    }
  };
};
export const getChatUserList = () => {
  return async (dispatch) => {
    try {
      let result = await apiGetChatUserList();
      const { data } = result;
      dispatch({ type: GET_CHAT_USER_LIST, payload: data });
    } catch (e) {
      dispatch({ type: GET_CHAT_USER_LIST, payload: e });
    }
  };
};
export const getChatUserDetails = (id) => {
  return async (dispatch) => {
    try {
      let result = await apiGetChatUserDetails(id);
      const { data } = result;
      dispatch({ type: GET_CHAT_USER_DETAILS, payload: data });
    } catch (e) {
      dispatch({ type: GET_CHAT_USER_DETAILS, payload: e });
    }
  };
};

export const getConversationDetails = (chatId) => {
  return async (dispatch) => {
    try {
      let result = await apiGetConversationDetails(chatId);

      dispatch({ type: GET_CONVERSATION_DETAILS, payload: result });
    } catch (e) {
      dispatch({ type: GET_CONVERSATION_DETAILS, payload: e });
    }
  };
};

export const actionUpdateParticipants = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiUpdateParticipants(data);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: UPDATE_PARTICIPANTS, payload: result });
      } else {
        dispatch({ type: UPDATE_PARTICIPANTS, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: UPDATE_PARTICIPANTS, payload: e.response });
    }
  };
};
export const actionAddParticipants = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiAddParticipants(data);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: ADD_PARTICIPANTS, payload: result });
      } else {
        dispatch({ type: ADD_PARTICIPANTS, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: ADD_PARTICIPANTS, payload: e.response });
    }
  };
};

export const actionRemoveParticipants = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiRemoveParticipants(data);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: REMOVE_PARTICIPANTS, payload: result });
      } else {
        dispatch({ type: REMOVE_PARTICIPANTS, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: REMOVE_PARTICIPANTS, payload: e.response });
    }
  };
};

export const actionCreateChatGroup = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiCreateChatGroup(data);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: CREATE_CHAT_GROUP, payload: result });
      } else {
        dispatch({ type: CREATE_CHAT_GROUP, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: CREATE_CHAT_GROUP, payload: e.response });
    }
  };
};

export const actionUpdateChatGroup = (data) => {
  return async (dispatch) => {
    try {
      const result = await apiUpdateChat(data);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: UPDATE_CONVERSATION, payload: result });
        showSuccessToast("Details updated successfully!!!");
      } else {
        dispatch({ type: UPDATE_CONVERSATION, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: UPDATE_CONVERSATION, payload: e.response });
    }
  };
};

export const actionDeleteChat = (chatId) => {
  return async (dispatch) => {
    try {
      const result = await apiDeleteChat(chatId);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: DELETE_CONVERSATION, payload: result });
        showSuccessToast("Chat group deleted successfully!!!");
      } else {
        dispatch({ type: DELETE_CONVERSATION, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: DELETE_CONVERSATION, payload: e });
    }
  };
};
export const actionExitChatgroup = (chatId) => {
  return async (dispatch) => {
    try {
      const result = await apiExitChatgroup(chatId);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: EXIT_CONVERSATION, payload: result });
        showSuccessToast("Exit from group successfully!!!");
      } else {
        dispatch({ type: EXIT_CONVERSATION, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: EXIT_CONVERSATION, payload: e });
    }
  };
};

// export const editGroupInfo = (field, value) => {
//   return dispatch => {
//     dispatch(actions.change("forms.chatForm." + field, value));
//   };
// };

//============================Search User=========================

export const actionSearchUsers = (searchTerm) => {
  return async (dispatch) => {
    try {
      const result = await apiSearch(searchTerm);
      dispatch({ type: SET_SEARCH_RESULT, payload: result.data });
      return result;
    } catch (e) {
      dispatch({ type: SET_SEARCH_RESULT, payload: e.msg });
    }
  };
};

export const actionAutoComplete = (searchTerm, level) => {
  return async (dispatch) => {
    try {
      const result = await apiAutoComplete(searchTerm, level);
      dispatch({ type: SET_AUTO_COMPLETE, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_AUTO_COMPLETE, payload: e.msg });
    }
  };
};

export const actionClearSearch = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_SEARCH, payload: null });
  };
};

export const actionClearConversationSearch = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_CONVERSATION_SEARCH, payload: null });
  };
};

export const actionSearchConversation = (searchTerm) => {
  return async (dispatch) => {
    try {
      const result = await apiSearchConversation(searchTerm);
      dispatch({ type: SET_SEARCH_CONVERSATION_RESULT, payload: result.data });
      return result;
    } catch (e) {
      dispatch({ type: SET_SEARCH_CONVERSATION_RESULT, payload: e.msg });
    }
  };
};

export const actionConversationAutoComplete = (searchTerm) => {
  return async (dispatch) => {
    try {
      const result = await apiAutoCompleteConversation(searchTerm);
      dispatch({ type: SET_AUTO_CONVERSATION_COMPLETE, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_AUTO_CONVERSATION_COMPLETE, payload: e.msg });
    }
  };
};
