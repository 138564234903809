import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Grid, Button } from "semantic-ui-react";
import _ from "lodash";

const ALL_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

const WEEK_DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri"]


const getDays = (days) => {
  days = _.orderBy(days);

  days = _.map(days, day => {
    return ALL_DAYS[day]
  })

  if (_.isEqual(ALL_DAYS, days)) {
    return "Every day ";
  }

  if (_.isEqual(WEEK_DAYS, days)) {
    return "Every weekday ";
  }

  return `Every ${days.join(" ")} of the week `;
}
const ScheduleDetail = ({ schedule = {}, deleteSchedule }) => {
  const {
    name,
    formName,
    occurrence,
    data,
    dueDate = moment(),
    kilometer,
    note
  } = schedule;

  let startDate = null,
    endDate = null;
  if (occurrence === "series") {
    startDate = moment(data.startDate).format("LL");
    endDate = moment(data.endDate).format("LL");
  } else {
    startDate = moment(dueDate).format("LL");
  }
  const days = data ? data.days : [];

  return (
    <Grid>
      <Grid.Column
        computer={12}
        mobile={16}
        tablet={12}
        style={{ lineHeight: "1.6em" }}
      >
        <div>
          <b>Form: &nbsp;</b>{formName}
          <span>({occurrence === "series" ? "Repeated maintenance" : "One time maintenance"})</span>
        </div>
        <div>
          <b>Note: &nbsp;</b>{note}
        </div>
        {occurrence === "series" ? (
          <div>
            <b>Schedule: </b>
            {getDays(days)}
            from &nbsp;
            <b>{startDate}</b>
            &nbsp;
            to &nbsp;
            <b>{endDate}</b>
          </div>
        ) : (
            <div>
              <b>Due date: &nbsp;</b>
              {startDate}
              &nbsp;
            {kilometer && (
                <span>
                  <b>or</b> <span>{kilometer}</span> kilometers. &nbsp;
              </span>
              )}
            </div>
          )}
      </Grid.Column>
      <Grid.Column
        computer={4}
        mobile={16}
        tablet={4}
        verticalAlign="middle"
        textAlign="right"
      >
        <Button
          content="Delete"
          icon="delete"
          color="red"
          size="mini"
          basic
          onClick={() => deleteSchedule(name)}
        />
      </Grid.Column>
    </Grid>
  );
};

ScheduleDetail.propTypes = {
  schedule: PropTypes.object,
  deleteSchedule: PropTypes.func
};

export default ScheduleDetail;
