import React from "react";
import PropTypes from "prop-types";
import ScheduleDetail from "./Details";
import { Message, List } from "semantic-ui-react";

const ScheduleList = ({ schedules = [], deleteSchedule }) => {
  return (
    <React.Fragment>
      {schedules.length > 0 ? (
        <List celled relaxed>
          {schedules.map((schedule, s_key) => {
            return (
              <List.Item key={s_key}>
                <ScheduleDetail
                  key={s_key}
                  schedule={schedule}
                  deleteSchedule={deleteSchedule}
                />
              </List.Item>
            );
          })}
        </List>
      ) : (
        <Message info header="No Schedule maintenances found" />
      )}
    </React.Fragment>
  );
};

ScheduleList.propTypes = {
  schedules: PropTypes.array,
  deleteSchedule: PropTypes.func
};

export default ScheduleList;
