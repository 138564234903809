// TopicChoice.js
import React, { useEffect, useState } from "react";
import { Chip } from "@mui/material";
import { Grid } from "semantic-ui-react";

const TopicChoice = ({ currentTopic, topics, onSubTopicsChange }) => {
  const [selectedTopic, setSelectedTopic] = useState(currentTopic);
  const [subTopics, setSubTopics] = useState(
    topics.find((topic) => topic.topicId === selectedTopic)?.subtopics || []
  );

  // Update parent component whenever subTopics changes
  useEffect(() => {
    onSubTopicsChange(subTopics);
  }, [subTopics, onSubTopicsChange]);

  const handleClick = (TopicId) => {
    setSelectedTopic(TopicId);
    const selected = topics.find((topic) => topic.topicId === TopicId);
    setSubTopics(selected ? selected.subtopics : []);
  };

  return (
    <Grid.Row>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          paddingLeft: "50px",
        }}
      >
        <h3 style={{ margin: 0 }}>Topics:</h3>
        <div style={{ width: "20px" }}></div>
        {topics.map((topic) => (
          <Chip
            key={topic.topicId}
            label={topic.topicName}
            style={{
              paddingTop: "4px",
              display: "flex",
              alignItems: "center",
              borderRadius: "5px",
              border: "2px solid #66CB23",
              minWidth: "120px",
              height: "40px",
              alignContent: "center",
              marginRight: "15px",
              fontSize: "16px",
              backgroundColor:
                selectedTopic === topic.topicId ? "#66CB23" : "#FFFFFF",
              color: selectedTopic === topic.topicId ? "#FFFFFF" : "#000000",
            }}
            variant="outlined"
            onClick={() => handleClick(topic.topicId)}
          />
        ))}
      </div>
    </Grid.Row>
  );
};

export default TopicChoice;
