import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Dropdown } from "semantic-ui-react";

const LocationDropdown = ({ locations = [], locationId = "", setLocation }) => {
  const locationList = [];
  if (locations && locations.length) {
    _.map(locations, (location, l_key) => {
      const { name } = location;
      locationList.push({
        key: l_key,
        text: name,
        value: name
      });
    });
  }

  return (
    <Dropdown
      placeholder="Select Location"
      fluid
      value={locationId}
      selection
      required
      onChange={setLocation}
      options={locationList}
    />
  );
};

LocationDropdown.propTypes = {
  locations: PropTypes.array,
  locationId: PropTypes.string,
  setLocation: PropTypes.func
};

export default LocationDropdown;
