import React from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Grid, Search } from "semantic-ui-react";
import PropTypes from "prop-types";
import * as hoseMakerReducer from "../../hoseMaker.reducer";
import ResultRenderer from "./../SelectFitterByFilters/ResultRenderer";
import _ from "lodash";
import "./searchFitter.css";

class SearchFitterById extends React.Component {
  constructor() {
    super();
    this.state = {
      id: "",
      value: "",
      isLoading: false
    };
  }

  handleResultSelect = (e, { result }) => {
      const value = result.stockCode;
      this.setState({ value });
      this.props.selectedFitting(result);
  };

  handleSearchChange = async (e, { value }) => {
    e.preventDefault();
    const { hoseType } = this.props;
    const { productDimensions, stockCode } = hoseType;
    const { dashSize1 } = productDimensions;
    this.setState({ isLoading: true });
    this.setState({ value, searchMessage: null });
    await this.props.actionFittingAutoComplete(value, dashSize1, stockCode);
    this.setState({ isLoading: false });
  };

  render() {
    const { value, isLoading } = this.state;
    const { autoCompleteResult } = this.props;
    return (
      <React.Fragment>
        <Grid>
          <Grid.Column width={16}>
            <Search
              className="search-box"
              loading={isLoading}
              placeholder="Search hose fitting by Id"
              resultRenderer={ResultRenderer}
              onResultSelect={this.handleResultSelect}
              onSearchChange={_.debounce(this.handleSearchChange, 500, {
                leading: true
              })}
              noResultsMessage="No direct matches found."
              results={autoCompleteResult}
              value={value}
              {...this.props}
            />
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

SearchFitterById.propTypes = {
  selectedFittingId: PropTypes.func
};

const mapStateToProps = state => ({
  autoCompleteResult: state.hoseMaker.autoCompleteResult,
  hoseType: state.hoseMaker.hoseType
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(hoseMakerReducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchFitterById);
