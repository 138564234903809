import { apiGetAllMostOrdered } from "./myFavorites.api";

const SET_MOST_ORDERED = "SET_MOST_ORDERED";
const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY";

const initialState = {
  mostOrdered: [],
  activeIndex: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MOST_ORDERED: {
      return {
        ...state,
        mostOrdered: action.payload
      };
    }
    case SET_ACTIVE_CATEGORY: {
      return {
        ...state,
        activeIndex: action.payload
      };
    }
    default:
      return state;
  }
};

export const actionSetMostOrdered = () => {
  return async dispatch => {
    try {
      const result = await apiGetAllMostOrdered();
      dispatch({ type: SET_MOST_ORDERED, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_MOST_ORDERED, payload: e });
    }
  };
};

export const toggleActiveCategory = index => {
  return async dispatch => {
    dispatch({ type: SET_ACTIVE_CATEGORY, payload: index });
  };
};
