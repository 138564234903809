import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Modal } from "semantic-ui-react";
import hoseImage from "./assets/images/hose/Hose_body1.png";
import HoseSearch from "./HoseSearch";
import * as hoseMakerReducer from "../hoseMaker.reducer";

class HoseType extends React.Component {
  constructor() {
    super();
    this.state = {
      openModal: false
    };
  }

  modalOpenHandler = () => {
    this.setState({ openModal: true });
  };
  modalCloseHandler = () => {
    this.props.actionResetHoseCategories();
    this.setState({ openModal: false });
  };

  render() {
    const { selectedType, boarChangeHandler } = this.props;
    const { openModal } = this.state;


    let hoseJSX = (
      <span>
        {selectedType ? (
          <img
            alt="Hose"
            src={hoseImage}
            className="bore-image"
          />
        ) : (
            <div className="select-bore-text">
              <h3>1. Select hose</h3>  
              Click to select hose size and type
            </div>
        )}
      </span>
    );
    this.onBoarChangeHandler = data => {
      boarChangeHandler(data);
      this.setState({ openModal: false });
    };

    const hoseModelJSX = (
      <Modal
        open={openModal}
        closeIcon
        size={"fullscreen"}
        onClose={this.modalCloseHandler}
      >
        <Modal.Header>Select a Hose</Modal.Header>
        <Modal.Content>
          <HoseSearch boarChangeHandler={this.onBoarChangeHandler} />
        </Modal.Content>
      </Modal>
    );

    return (
      <React.Fragment>
        <div onClick={this.modalOpenHandler}>
          {hoseJSX}
          {hoseModelJSX}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.hoseMaker.categories
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(hoseMakerReducer, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HoseType);
