import React from "react";
import PropTypes from "prop-types";
import { Form, Grid } from "semantic-ui-react";
import ImageUploadInput from "../../../../containers/HoseMaker/HoseMakerContainer/SendToInventoryOrAssetModal/ImageUploadInput";
import NumericInput from "react-numeric-input";

const fittingInput = ({
  type,
  inputChangeHandler,
  inputValue,
  crimpValue,
  onSelectFile,
  selectedFile
}) => {
  return (
    <Grid>
      <Grid.Column width={8}>
        <Form>
          <Form.Field>
            <Grid>
              <Grid.Column>
                <b>
                  {type} fitting crimp (system specified : {crimpValue} ){" "}
                </b>
                <span style={{ color: "red" }}>*</span>
                <br />
                <NumericInput
                  value={inputValue}
                  // step={0.01}
                  // precision={2}
                  // max={100}
                  // maxLength={5}
                  // min={0.00}
                  min={0}
                  max={100}
                  step={1}
                  precision={2}
                  size={5}
                  strict
                  onChange={inputChangeHandler}
                />
              </Grid.Column>
            </Grid>
          </Form.Field>
        </Form>
      </Grid.Column>
      <Grid.Column width={8}>
        <Form>
          <Form.Field>
            <label>
              Attach Image of Vernier Calliper (**Image size limit is 5mb)
            </label>
            <ImageUploadInput
              onSelectFile={imageData => onSelectFile(imageData, type)}
              selectedFile={selectedFile}
            />
          </Form.Field>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

fittingInput.propTypes = {
  type: PropTypes.string,
  inputChangeHandler: PropTypes.func,
  inputValue: PropTypes.string,
  crimpValue: PropTypes.string,
  onSelectFile: PropTypes.func,
  selectedFile: PropTypes.object
};

export default fittingInput;
