import {
  apiGetMaintenanceRecord,
  apiUpdateMaintenanceRecord,
  apiUploadImage,
  apiDeleteImage
} from "./reviewMaintenance.api";
import { actions } from "react-redux-form";

const SET_MAINTENANCE_FORM = "SET_MAINTENANCE_FORM";
const UPDATE_MAINTENANCE_FORM = "UPDATE_MAINTENANCE_FORM";
const UPLOAD_IMAGE = "UPLOAD_IMAGE";
const SET_CHECK_POINT_KEY = "SET_CHECK_POINT_KEY";

const initialState = {
  maintenanceRecord: {},
  response: {},
  checkPointKey: null,
  imageUrl: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_MAINTENANCE_FORM:
      return {
        ...state,
        maintenanceRecord: action.payload
      };

    case UPLOAD_IMAGE:
      return {
        ...state,
        imageUrl: action.payload
      };

    case UPDATE_MAINTENANCE_FORM:
      return {
        ...state,
        response: action.payload
      };

    case SET_CHECK_POINT_KEY:
      return {
        ...state,
        checkPointKey: action.payload
      };

    default:
      return { ...state };
  }
};

export const actionSetCheckPoints = checkPoints => {
  return dispatch => {
    dispatch(actions.change("forms.maintenanceForm.data", checkPoints));
  };
};

export const actionSetForm = form => {
  return dispatch => {
    dispatch(actions.change("forms.maintenanceForm", form));
  };
};

export const actionSetFormFields = (field, value) => {
  return dispatch => {
    dispatch(actions.change("forms.maintenanceForm." + field, value));
  };
};

export const actionResetForm = formName => {
  return dispatch => {
    dispatch(actions.reset("forms." + formName));
  };
};

export const actionGetMaintenanceRecord = id => {
  return async dispatch => {
    try {
      const result = await apiGetMaintenanceRecord(id);
      dispatch({ type: SET_MAINTENANCE_FORM, payload: result.data });
    } catch (e) {
      dispatch({ type: SET_MAINTENANCE_FORM, payload: e });
    }
  };
};

export const actionUpdateMaintenanceRecord = formData => {
  return async dispatch => {
    try {
      const result = await apiUpdateMaintenanceRecord(formData);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: UPDATE_MAINTENANCE_FORM, payload: result });
      } else {
        dispatch({ type: UPDATE_MAINTENANCE_FORM, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: UPDATE_MAINTENANCE_FORM, payload: e.response });
    }
  };
};

export const actionUploadImage = fileData => {
  return async dispatch => {
    try {
      const result = await apiUploadImage(fileData);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: UPLOAD_IMAGE, payload: result.data });
      } else {
        dispatch({ type: UPLOAD_IMAGE, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: UPLOAD_IMAGE, payload: e.response });
    }
  };
};

export const setCheckpointKey = key => {
  return async dispatch => {
    dispatch({ type: SET_CHECK_POINT_KEY, payload: key });
  };
};

export const deleteImage = imageKey => {
  return async dispatch => {
    try {
      const result = await apiDeleteImage(imageKey);
      const { status } = result;
      if (status === 200) {
        dispatch({ type: UPLOAD_IMAGE, payload: result.data });
      } else {
        dispatch({ type: UPLOAD_IMAGE, payload: result.response });
      }
    } catch (e) {
      dispatch({ type: UPLOAD_IMAGE, payload: e.response });
    }
  };
};
