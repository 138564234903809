import React from "react";
import { Icon, Label } from "semantic-ui-react";
import _ from "lodash";
import PropTypes from "prop-types";

const Filter = ({
  applyFilter,
  secondaryGrpDisplayName,
  tertiaryGrpDisplayName,
  productFamilyDisplayName,
  activeNode,
  categories,
  isLoading,
}) => {
  secondaryGrpDisplayName = _.replace(secondaryGrpDisplayName, "$and$", "&");
  tertiaryGrpDisplayName = _.replace(tertiaryGrpDisplayName, "$and$", "&");
  productFamilyDisplayName = _.replace(productFamilyDisplayName, "$and$", "&");

  const showFilter =
    secondaryGrpDisplayName ||
    tertiaryGrpDisplayName ||
    productFamilyDisplayName;

  if (!activeNode) return null;

  const filterItems = (arr, query) => {
    return arr.filter(
      (el) => el.module.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  };

  let items = [];
  let values = activeNode.value.split(",");
  if (values.length === 1) items.push({ text: activeNode.module, node: "" });
  else if (values.length === 2) {
    const level21 = filterItems(categories, values[0].trim());

    items.push({ text: values[0], node: "" });
    items.push({ text: activeNode.module, node: level21 });
  } else if (values.length === 3) {
    const level31 = filterItems(categories, values[0].trim());
    const level32 = filterItems(level31[0].children, values[1].trim());

    items.push({ text: values[0], node: "" });
    items.push({ text: level32[0].module, node: level31 });
    items.push({ text: activeNode.module, node: level32 });
  } else if (values.length === 4) {
    const level41 = filterItems(categories, values[0].trim());
    const level42 = filterItems(level41[0].children, values[1].trim());
    const level43 = filterItems(level42[0].children, values[2].trim());

    items.push({ text: values[0], node: "" });
    items.push({ text: level42[0].module, node: level41 });
    items.push({ text: level43[0].module, node: level42 });
    items.push({ text: activeNode.module, node: level43 });
  }

  return (
    <div className="filter-container">
      <b className="filter-label-text">Filtering by </b>

      {activeNode.value !== "" &&
        _.map(items, (item) => {
          return (
            <Label
              as="p"
              tag
              style={{ backgroundColor: "#66cc23", color: "#fff" }}
              className="filter-label"
            >
              {item.text}
              <Icon
                disabled={isLoading}
                style={{
                  cursor: isLoading ? "not-allowed" : "pointer",
                  pointerEvents: isLoading ? "none" : "",
                }}
                name="cancel"
                className="filter-cancel-icon"
                onClick={() => applyFilter(item.node[0])}
              />
            </Label>
          );
        })}
    </div>
  );
};

Filter.propTypes = {
  applyFilter: PropTypes.func,
  secondaryGrp: PropTypes.string,
  tertiaryGrp: PropTypes.string,
  productFamily: PropTypes.string,
  activeNode: PropTypes.object,
};

export default Filter;
