import React, { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Grid,
  Image as ImageContainer,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { showErrorToast } from "../../utils/toastUtils";
import "./myAssets.css";

const baseStyle = {
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#979fa8",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#979fa8",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const activeStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

export default function JobImageUploader(props) {
  const MAX_IMAGES = 4;
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    onDropAccepted,
  } = useDropzone({
    accept: ".jpg, .jpeg, .png",
    onDrop: (acceptedFiles) => {
      const imageListCount = 4 - imagesUploaded.length;

      if (acceptedFiles.length > imageListCount) {
        showErrorToast("Maximum of 4 images only");
      } else {
        if (imagesUploaded.length + acceptedFiles.length <= MAX_IMAGES) {
          setHasUploaded(true);
          setImagesUploaded([...imagesUploaded, ...acceptedFiles]);
        }
      }
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  const [isLoading, setIsLoading] = useState(false);
  const [hasUploaded, setHasUploaded] = useState(false);
  const [image1, setImage1] = useState("");
  const [image2, setImage2] = useState("");
  const [image3, setImage3] = useState("");
  const [image4, setImage4] = useState("");

  const removeImage = (index) => {
    setHasUploaded(true);
    setImage1("");
    setImage2("");
    setImage3("");
    setImage4("");
    let newState = [];
    newState.push(imagesUploaded);

    let imageData = newState.map((item, i) => {
      if (i == 0) {
        item.splice(index, 1);
      }
      return item;
    });

    setImagesUploaded([...imageData[0]]);
  };

  function base64ToFile(base64, filename) {
    var arr = base64.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  async function compressBase64Image(base64String, desiredSizeInKb) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);

        let quality = 1; // initial quality
        let compressedBase64 = canvas.toDataURL("image/jpeg", quality);
        let fileSizeInKb = Math.round(compressedBase64.length / 1024);

        while (fileSizeInKb > desiredSizeInKb && quality > 0) {
          quality -= 0.1;
          if (quality > 0) {
            compressedBase64 = canvas.toDataURL("image/jpeg", quality);
            fileSizeInKb = Math.round(compressedBase64.length / 1024);
          }
        }

        if (fileSizeInKb > desiredSizeInKb) {
          const canvas2 = document.createElement("canvas");
          const ctx2 = canvas2.getContext("2d");
          const aspectRatio = img.width / img.height;
          const newWidth = img.width / 2;
          const newHeight = newWidth / aspectRatio;
          canvas2.width = newWidth;
          canvas2.height = newHeight;
          ctx2.drawImage(img, 0, 0, newWidth, newHeight);

          let quality2 = 1; // initial quality
          compressedBase64 = canvas2.toDataURL("image/jpeg", quality2);

          while (fileSizeInKb > desiredSizeInKb && quality2 > 0) {
            quality2 -= 0.1;
            if (quality2 > 0) {
              compressedBase64 = canvas2.toDataURL("image/jpeg", quality2);
              fileSizeInKb = Math.round(compressedBase64.length / 1024);
              console.log("Image file size: " + fileSizeInKb);
            }
          }
        }

        resolve(compressedBase64);
      };
      img.onerror = reject;
      img.src = base64String;
    });
  }

  function getBase64(file, index) {
    if (typeof file === "string" || file instanceof String) {
      file = base64ToFile(`data:image/jpeg;base64,${file}`, "image.jpeg");
    }
    setIsLoading(true);
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      compressBase64Image(reader.result, 50).then((compressedBase64Image) => {
        var compressedBase64EncodedImage = compressedBase64Image.split(",")[1];
        if (index === 0) setImage1(compressedBase64EncodedImage);
        if (index === 1) setImage2(compressedBase64EncodedImage);
        if (index === 2) setImage3(compressedBase64EncodedImage);
        if (index === 3) setImage4(compressedBase64EncodedImage);
      });
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
    setTimeout(function () {
      setIsLoading(false);
    }, 300);
  }

  useEffect(() => {
    if (hasUploaded) {
      props.setImages(imagesUploaded);
      imagesUploaded.map((file, index) => {
        getBase64(file, index);
      });
    }
  }, [JSON.stringify(imagesUploaded)]);

  return (
    <div>
      {isLoading ? (
        <Dimmer active inverted>
          <Loader size="large">Loading images...</Loader>
        </Dimmer>
      ) : (
        <Grid
          style={{
            marginBottom: 15,
            paddingLeft: 30,
            paddingRight: 30,
            paddingTop: "1.5rem",
          }}
        >
          <Grid.Column
            width={4}
            style={{
              padding: 0,
              paddingLeft: 7,
              paddingRight: 7,
            }}
          >
            {image1 != "" && image1 != null ? (
              <div
                style={{
                  backgroundColor: "#cfd2d7",
                  height: "130px",
                  width: "130px",
                  position: "relative",
                }}
              >
                <img
                  className="bomImage"
                  style={{ marginTop: "auto", marginBottom: "auto" }}
                  src={`data:image/jpeg;base64,${image1}`}
                />
                <Button
                  circular
                  icon="settings"
                  style={{
                    position: "absolute",
                    right: -17,
                    top: -12,
                    cursor: "pointer",
                    padding: "4px 9px 7px 9px",
                  }}
                  onClick={() => removeImage(0)}
                >
                  x
                </Button>
              </div>
            ) : null}
          </Grid.Column>
          <Grid.Column
            width={4}
            style={{
              padding: 0,
              paddingLeft: 7,
              paddingRight: 7,
            }}
          >
            {image2 != "" && image2 != null ? (
              <div
                style={{
                  backgroundColor: "#cfd2d7",
                  height: "130px",
                  width: "130px",
                  position: "relative",
                }}
              >
                <img
                  className="bomImage"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  src={`data:image/jpeg;base64,${image2}`}
                />
                <Button
                  circular
                  icon="settings"
                  style={{
                    position: "absolute",
                    right: -17,
                    top: -12,
                    cursor: "pointer",
                    padding: "4px 9px 7px 9px",
                  }}
                  onClick={() => removeImage(1)}
                >
                  x
                </Button>
              </div>
            ) : null}
          </Grid.Column>
          <Grid.Column
            width={4}
            style={{
              padding: 0,
              paddingLeft: 7,
              paddingRight: 7,
            }}
          >
            {image3 != "" && image3 != null ? (
              <div
                style={{
                  backgroundColor: "#cfd2d7",
                  height: "130px",
                  width: "130px",
                  position: "relative",
                }}
              >
                <img
                  className="bomImage"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  src={`data:image/jpeg;base64,${image3}`}
                />
                <Button
                  circular
                  icon="settings"
                  style={{
                    position: "absolute",
                    right: -17,
                    top: -12,
                    cursor: "pointer",
                    padding: "4px 9px 7px 9px",
                  }}
                  onClick={() => removeImage(2)}
                >
                  x
                </Button>
              </div>
            ) : null}
          </Grid.Column>
          <Grid.Column
            width={4}
            style={{
              padding: 0,
              paddingLeft: 7,
              paddingRight: 7,
            }}
          >
            {image4 != "" && image4 != null ? (
              <div
                style={{
                  backgroundColor: "#cfd2d7",
                  height: "130px",
                  width: "130px",
                  position: "relative",
                }}
              >
                <img
                  className="bomImage"
                  style={{ marginLeft: "auto", marginRight: "auto" }}
                  src={`data:image/jpeg;base64,${image4}`}
                />
                <Button
                  circular
                  icon="settings"
                  style={{
                    position: "absolute",
                    right: -17,
                    top: -12,
                    cursor: "pointer",
                    padding: "4px 9px 7px 9px",
                  }}
                  onClick={() => removeImage(3)}
                >
                  x
                </Button>
              </div>
            ) : null}
          </Grid.Column>
        </Grid>
      )}

      <div {...getRootProps({ style })}>
        <input {...getInputProps()} />
        <div style={{ textAlign: "center" }}>
          <p>Drag and Drop image</p>
        </div>
        <div style={{ textAlign: "center", marginTop: 0 }}>
          <p>or</p>
        </div>
        <div style={{ textAlign: "center", marginTop: 2 }}>
          <Button color="blue" style={{ width: 150, fontSize: 14 }}>
            Browse
          </Button>
        </div>
      </div>
    </div>
  );
}
