
import axios from "axios";
import * as API  from "../../constants/apiLink";

export const apiGetCustomerHoses = (activePage) => {
    const url = API.HOSE_MAKER + "/" + API.MY_HOSES + `?activePage=${activePage}`;
    return axios.get(url).then(data => {
        return data;
    })
};
