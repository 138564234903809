import "./assetDetail.css";
import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import Loader from "../../components/Loader";
import { withRouter } from "react-router-dom";
import MetaData from "../../components/MetaData";
import AssetInfo from "../../components/AssetInfo";
import { getErrorMessage } from "../../utils/error.utils";
import ScheduleForm from "../../components/AddScheduleForm";
import ScheduleList from "../../components/ScheduleListForAsset";
import { showSuccessToast } from "../../utils/toastUtils";
import * as assetDetailReducer from "../AssetDetails/assetDetail.reducer";
import {
  Grid,
  Icon,
  Button,
  Accordion,
  Form,
  Message,
  Modal,
  Confirm,
  Divider
} from "semantic-ui-react";
import HosesAndAttachment from "./HosesAndAttachment";
import { ASSET_ID_FOR_URL_PARAM } from "../../constants/appConstants";

const ErrorMessage = "Linked asset name is required";
class AssetDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      activeIndex: 0,
      isError: false,
      errorMessage: "",
      isEdit: false,
      isDeleteMaintenance: false,
      isSchedule: false,
      scheduleId: "",
      errorMessageflag: false
    };
  }

  componentWillUnmount = () => {
    // this.props.actionCleatAssetDetailsReducer();
  }

  updateState = (isError, errorMessage) => {
    this.setState({ isError, errorMessage });
  };
  componentDidMount = () => {
    this.getInitialList();
  };

  getInitialList = () => {
    let params = this.props.match.params;
    this.props.actionGetSingleAsset(params.id);
    this.props.actionGetSchedulesByAsset(params.id);
  };

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({ activeIndex: newIndex });
  };

  goToPage = path => this.props.history.push(`/${path}`);

  editAsset = (asset, isEdit) => {
    let data_to_set = { ...asset };
    data_to_set.crew = asset.crew._id;

    if (data_to_set.operator) {
      data_to_set.operator = asset.operator._id;
    }
    this.props.actionResetForm("assetForm");
    this.setState({ isEdit });
    this.props.actionSetAssetFormInfo(data_to_set);
  };

  closeEditForm = () => {
    this.props.actionResetForm("assetForm");
    this.setState({ isEdit: false, isError: false, errorMessage: "" });
  };

  updateAsset = async () => {
    const { assetForm } = this.props;
    const { isLinkedAsset, linkedAsset, maintenanceUnit } = assetForm;
    if (isLinkedAsset) {
      if (!linkedAsset) {
        return this.setState({
          isError: true,
          errorMessage: ErrorMessage
        });
      }
    }

    if (maintenanceUnit === 0) {
      this.setState({
        isError: true,
        errorMessage: "Kilometer/Engine Hours can not be 0"
      });
      return;
    } else {
      this.setState({
        isError: false,
        errorMessage: ""
      });
    }
    const { childAssets, ...data_to_update } = assetForm;
    this.setState({ isLoading: true });
    await this.props.actionUpdateAsset(data_to_update);
    const { response } = this.props;
    const { status } = response;
    this.setState({ isLoading: false });
    if (status === 200) {
      this.getInitialList();
      showSuccessToast(
        <div>
          <Icon name="zip" />
          Asset updated
        </div>
      );
      this.closeEditForm();
    } else {
      const { data } = response;
      const { code } = data;
      let message = await getErrorMessage(code);
      this.updateState(true, message);
    }
    this.setState({ isLoading: false });
    this.clearErrorMessage();
  };
  updateState = (isError, errorMessage) => {
    this.setState({ isError, errorMessage });
  };

  deleteAsset = async () => {
    this.setState({ isLoading: true });
    const { asset } = this.props;
    const { _id } = asset;

    await this.props.actionDeleteAsset(_id);
    const { response } = this.props;
    const { status } = response;
    if (status === 200) {
      showSuccessToast(
        <div>
          <Icon name="zip" />
          Asset deleted
        </div>
      );
      this.setState({ isDeleteAsset: false });
      this.props.history.push("/asset");
    }
    this.setState({ isLoading: false });
    this.clearErrorMessage();
  };

  addScheduleForm = () => {
    let params = this.props.match.params;
    this.props.actionResetForm("scheduleForm");
    this.props.actionSetFormField("asset", params.id);
    this.setState({ isSchedule: true });
  };

  closeScheduleForm = () => {
    this.setState({ isSchedule: false });
    this.getInitialList();
  };

  deleteSchedule = async () => {
    this.setState({ isLoading: true });
    const { scheduleId } = this.state;
    await this.props.actionDeleteSchedule(scheduleId);
    const { response } = this.props;
    const { status } = response;
    if (status === 200) {
      this.getInitialList();
      showSuccessToast(
        <div>
          <Icon name="time" /> Scheduled Maintenance has been deleted.
        </div>
      );
    }
    this.setState({ isDeleteMaintenance: false, scheduleId: "" });
    this.setState({ isLoading: false });
  };

  conformDeleteBox = scheduleId => {
    this.setState({ isDeleteMaintenance: true, scheduleId });
  };

  closeDeleteBox = () =>
    this.setState({ isDeleteMaintenance: false, name: "" });

  conformDeleteAsset = () => {
    this.setState({ isDeleteAsset: true });
  };

  goToCompleteschedule = () => {
    const { asset } = this.props;
    const { _id } = asset;
    this.props.history.push({
      pathname: "/schedule",
      state: { flag: true, assetId: _id },
      search: `?${ASSET_ID_FOR_URL_PARAM}=${_id}`
    });
  };

  clearErrorMessage = () => {
    this.setState({
      errorMessageflag: false
    });
  };

  render() {
    const {
      isLoading,
      activeIndex,
      isError,
      errorMessage,
      isEdit,
      isSchedule,
      isDeleteMaintenance,
      isDeleteAsset
    } = this.state;
    const { asset, schedules } = this.props;
    const { name: assetName } = asset;
    const ActionButton = (
      <Button
        secondary
        size="mini"
        content="Edit"
        onClick={() => this.editAsset(asset, true)}
        className="button-float"
      />
    );

    const formButton = (
      <span>
        <Button
          size="mini"
          onClick={() => this.closeEditForm()}
          className="button-float"
          content="Cancel"
          type="button"
        />

        {isLoading ? (
          <Button
            secondary
            size="mini"
            content="Update"
            className="button-float"
            loading
          />
        ) : (
          <Button
            secondary
            size="mini"
            content="Update"
            className="button-float"
            type="submit"
          />
        )}
      </span>
    );

    const assetInformation = (
      <div className="my-asset-info-form">
        <Grid className="asset-component">
          <Form
            error={isError}
            model="form.profileInfo"
            onSubmit={this.updateAsset}
            className="asset-form-box"
          >
            <Grid.Column width={16}>
              <AssetInfo asset={asset} isEdit={isEdit} />
            </Grid.Column>
            <Message
              error={true}
              content={errorMessage}
              style={{ padding: "0px" }}
            />
            {isEdit && (
              <Grid width={13} textAlign="right" style={{ paddingTop: "22px" }}>
                <Grid.Column>{formButton}</Grid.Column>
              </Grid>
            )}
          </Form>

          <Grid.Column
            width={13}
            textAlign="right"
            style={{ paddingTop: "22px" }}
          >
            {!isEdit && ActionButton}
          </Grid.Column>
        </Grid>
      </div>
    );

    const ScheduleMaintenance = (
      <React.Fragment>
        <div style={{ textAlign: "right", margin: "1em" }}>
          <Button
            color="black"
            size="mini"
            onClick={this.goToCompleteschedule}
            icon="wordpress forms"
            content="View completed maintenances"
            className="asset-Button"
          />
          <Button
            size="mini"
            content="Add"
            onClick={this.addScheduleForm}
            secondary
          />
          <Modal open={isSchedule} onClose={this.closeScheduleForm}>
            <Modal.Header>New scheduled Maintenance</Modal.Header>
            <Modal.Content>
              <ScheduleForm closeModal={this.closeScheduleForm} />
            </Modal.Content>
          </Modal>
        </div>
        <div>
          <ScheduleList
            schedules={schedules}
            deleteSchedule={this.conformDeleteBox}
          />
        </div>
      </React.Fragment>
    );

    const displayAssetName = assetName ? `${assetName}` : "Asset Details";

    return (
      <div className="asset-detail-body">
        <MetaData title={displayAssetName} />
        <Confirm
          open={isDeleteMaintenance}
          content="Do you want to delete this schedule?"
          //onCancel={() => this.setState({ isDeleteSchedule: false })}
          onCancel={this.closeDeleteBox}
          onConfirm={this.deleteSchedule}
        />
        {isLoading && (
          <Loader title="Loading, please wait">Loading,please wait</Loader>
        )}
        <Grid>
          <Grid.Column width={16}>
            <Grid.Column computer={10} mobile={16} width={16}>
              <Grid>
                <Grid.Column width={8}>
                  <h3>
                    <Icon name="zip" />
                    {displayAssetName}
                  </h3>
                </Grid.Column>
                <Grid.Column width={8} mobile={16} textAlign="right">
                  <Button
                    size="tiny"
                    onClick={() => this.goToPage("asset")}
                    icon="left arrow"
                    content="Go back"
                    labelPosition="left"
                    className="button-float"
                  />
                  <Button
                    secondary
                    size="small"
                    content="Delete"
                    onClick={this.conformDeleteAsset}
                    className="button-float"
                  />
                </Grid.Column>
              </Grid>
              <Divider />
            </Grid.Column>

            <Confirm
              open={isDeleteAsset}
              content="Are you sure you want to delete this Asset. All the linked assets and upcoming schedule maintenances for this Asset will be deleted."
              onCancel={() => this.setState({ isDeleteAsset: false })}
              onConfirm={this.deleteAsset}
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <Accordion fluid styled>
              <Accordion.Title
                active={activeIndex === 0}
                index={0}
                onClick={this.handleClick}
                content="Asset Details"
              />
              <Accordion.Content active={activeIndex === 0}>
                {assetInformation}
              </Accordion.Content>
              <Accordion.Title
                active={activeIndex === 1}
                index={1}
                onClick={this.handleClick}
                content="Hoses and Attachments"
              />
              <Accordion.Content active={activeIndex === 1}>
                {activeIndex === 1 && <HosesAndAttachment />}
              </Accordion.Content>
              <Accordion.Title
                active={activeIndex === 2}
                index={2}
                onClick={this.handleClick}
                content="Scheduled Maintenances"
              />
              <Accordion.Content active={activeIndex === 2}>
                {ScheduleMaintenance}
              </Accordion.Content>
            </Accordion>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    schedules: state.assetDetail.schedules,
    response: state.assetDetail.response,
    asset: state.assetDetail.asset,
    assetForm: state.forms.assetForm
  };
};

const reducer = { ...assetDetailReducer };
const mapDispatchToProps = dispatch => bindActionCreators(reducer, dispatch);

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AssetDetail)
);
