import React from "react";
import { Grid, Divider, Image, Icon } from "semantic-ui-react";
import PropTypes from "prop-types";
import UiLoader from "../../components/Loader";
import { CATEGORIES_IMAGES } from "../../constants/appConstants";

const DefaultGrid = ({
  categories = [],
  onClickHandler,
  isLoadingCategories,
}) => {
  const redirectClickHandler = (e, module) => {
    e.preventDefault();
    if (e.ctrlKey) {
      e.stopPropagation();
      window.open("/search?filter=" + module + "&value=");
    }
  };
  return (
    <React.Fragment>
      <h3>
        <Icon name="list layout" />
        Categories
      </h3>
      <Divider />
      <Grid className="default-container">
        {categories.map((value, key) => {
          const image = CATEGORIES_IMAGES[value.module];
          return (
            <Grid.Column
              key={key}
              className="default-grid-column"
              width={5}
              computer={5}
              mobile={15}
              onClick={() => onClickHandler(value)}
            >
              <Grid
                href={"/search?filter=" + value.module + "&value="}
                onClick={(event) => redirectClickHandler(event, value.module)}
              >
                <Grid.Column width={5} className="filter-content-image">
                  <Image
                    src={
                      value.image128
                        ? `data:image/jpeg;base64,${value.image128}`
                        : image
                    }
                  />
                </Grid.Column>
                <Grid.Column width={11}>{value.module}</Grid.Column>
              </Grid>
            </Grid.Column>
          );
        })}
      </Grid>
      {isLoadingCategories && categories.length === 0 && (
        <div>
          <Grid>
            <Grid.Column>
              <UiLoader text="Loading Categories ,please wait" />
            </Grid.Column>
          </Grid>
        </div>
      )}
    </React.Fragment>
  );
};

DefaultGrid.propTypes = {
  categories: PropTypes.array,
  onClickHandler: PropTypes.func,
  isLoadingCategories: PropTypes.bool,
};

export default DefaultGrid;
